/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { Bookmark, LabelValue } from '@ws1c/intelligence-models';

/**
 * BookmarksActions
 *
 * @export
 * @class BookmarksActions
 */
export class BookmarksActions {
  public static readonly CATEGORY = '[BOOKMARKS]';

  public static readonly ADD_BOOKMARK = `${BookmarksActions.CATEGORY} ADD_BOOKMARK`;
  public static readonly ADD_BOOKMARK_SUCCESS = `${BookmarksActions.CATEGORY} ADD_BOOKMARK_SUCCESS`;

  public static readonly GET_BOOKMARKS = `${BookmarksActions.CATEGORY} GET_BOOKMARKS`;
  public static readonly GET_BOOKMARKS_SUCCESS = `${BookmarksActions.CATEGORY} GET_BOOKMARKS_SUCCESS`;

  public static readonly REMOVE_BOOKMARK = `${BookmarksActions.CATEGORY} REMOVE_BOOKMARK`;
  public static readonly REMOVE_BOOKMARK_SUCCESS = `${BookmarksActions.CATEGORY} REMOVE_BOOKMARK_SUCCESS`;

  public static readonly REMOVE_BOOKMARKS_FROM_CACHE = `${BookmarksActions.CATEGORY} REMOVE_BOOKMARKS_FROM_CACHE`;

  public static readonly SET_SELECTED_BOOKMARK_FILTER_OPTION = `${BookmarksActions.CATEGORY} SET_SELECTED_BOOKMARK_FILTER_OPTION`;

  public static readonly TOGGLE_BOOKMARK = `${BookmarksActions.CATEGORY} TOGGLE_BOOKMARK`;
  public static readonly TOGGLE_BOOKMARK_FILTER = `${BookmarksActions.CATEGORY} TOGGLE_BOOKMARK_FILTER`;

  public static readonly UPDATE_BOOKMARK = `${BookmarksActions.CATEGORY} UPDATE_BOOKMARK`;

  public static addBookmark = createAction(BookmarksActions.ADD_BOOKMARK, props<{ bookmark: Bookmark }>());

  public static addBookmarkSuccess = createAction(BookmarksActions.ADD_BOOKMARK_SUCCESS, props<{ bookmark: Bookmark }>());

  public static getBookmarks = createAction(BookmarksActions.GET_BOOKMARKS);

  public static getBookmarksSuccess = createAction(BookmarksActions.GET_BOOKMARKS_SUCCESS, props<{ bookmarks: Bookmark[] }>());

  public static removeBookmark = createAction(BookmarksActions.REMOVE_BOOKMARK, props<{ bookmark: Bookmark }>());

  public static removeBookmarkSuccess = createAction(BookmarksActions.REMOVE_BOOKMARK_SUCCESS, props<{ resourceId: string }>());

  public static removeBookmarksFromCache = createAction(BookmarksActions.REMOVE_BOOKMARKS_FROM_CACHE, props<{ resourceIds: string[] }>());

  public static setSelectedBookmarkFilterOption = createAction(
    BookmarksActions.SET_SELECTED_BOOKMARK_FILTER_OPTION,
    props<{ selectedBookmarkFilterOption: LabelValue }>(),
  );

  public static toggleBookmark = createAction(
    BookmarksActions.TOGGLE_BOOKMARK,
    props<{
      bookmark: Bookmark;
      isAddBookmark: boolean;
    }>(),
  );

  public static toggleBookmarkFilter = createAction(BookmarksActions.TOGGLE_BOOKMARK_FILTER, props<{ isBookmarkFilterEnabled: boolean }>());

  public static updateBookmark = createAction(BookmarksActions.UPDATE_BOOKMARK, props<{ bookmark: Bookmark }>());
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, ServiceLink } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { getFirstActiveIntegrationInstance } from '@dpa-shared-merlot/store/integration/integration-selector-helpers';
import { FeatureControl } from '@ws1c/intelligence-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { OrgSelectors } from '@ws1c/intelligence-core/store/org';
import {
  UserPreferenceAssetsSelectors,
  UserPreferenceCommonSelectors,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core/store/user-preference';
import {
  AddOnType,
  AvailableSKUService,
  FeatureControls,
  FeatureSetup,
  FeatureSetupId,
  FeatureSetupType,
  IntegratedService,
  IntegratedServicesResponse,
  IntegratedServiceType,
  Integration,
  LOAD_STATE,
  ServiceMode,
  TenantType,
} from '@ws1c/intelligence-models';
import { IntegratedServicesState } from './integrated-services.store';

/**
 * Holds mapping from feature setup to its corresponding feature control key
 * @type {Partial<Record<FeatureSetupId, FeatureControl>>}
 */
const FEATURE_SETUP_TO_FEATURE_CONTROL_MAP: Partial<Record<FeatureSetupId, FeatureControl>> = {
  [FeatureSetupId.CSP_FEDERATION_USERS_SETUP]: FeatureControl.CSP_FEDERATION_SETUP_ENABLED,
};

/**
 * IntegratedServicesSelectors
 *
 * @export
 * @class IntegratedServicesSelectors
 */
export class IntegratedServicesSelectors {
  /**
   * selectIntegratedServicesState
   * @static
   * @type {(state: CoreAppState) => IntegratedServicesState}
   * @memberof IntegratedServicesSelectors
   */
  public static selectIntegratedServicesState = (state: CoreAppState): IntegratedServicesState => {
    return state.integratedServicesState;
  };

  /**
   * integratedServicesData
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedServicesResponse>}
   * @memberof IntegratedServicesSelectors
   */
  public static integratedServicesData: MemoizedSelector<CoreAppState, IntegratedServicesResponse> = createSelector(
    IntegratedServicesSelectors.selectIntegratedServicesState,
    (state: IntegratedServicesState) => state.integratedServicesData,
  );

  /**
   * integratedServices
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static integratedServices: MemoizedSelector<CoreAppState, IntegratedService[]> = createSelector(
    IntegratedServicesSelectors.integratedServicesData,
    (integratedServicesData: IntegratedServicesResponse) => integratedServicesData.services,
  );

  /**
   * gettingStartedServices
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static gettingStartedServices: MemoizedSelector<CoreAppState, IntegratedService[]> = createSelector(
    IntegratedServicesSelectors.integratedServices,
    (integratedServices: IntegratedService[]) =>
      integratedServices.filter((integratedService: IntegratedService) => integratedService.showInGettingStarted),
  );

  /**
   * homePageServices
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static homePageServices: MemoizedSelector<CoreAppState, IntegratedService[]> = createSelector(
    IntegratedServicesSelectors.integratedServices,
    (integratedServices: IntegratedService[]) =>
      integratedServices.filter((integratedService: IntegratedService) => integratedService.showInHomeScreen),
  );

  /**
   * homePageServicesMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, IntegratedService[]>>}
   * @memberof IntegratedServicesSelectors
   */
  public static homePageServicesMap: MemoizedSelector<CoreAppState, Map<string, IntegratedService[]>> = createSelector(
    IntegratedServicesSelectors.homePageServices,
    (homePageServices: IntegratedService[]) => {
      const result: Map<string, IntegratedService[]> = new Map<string, IntegratedService[]>();
      homePageServices.forEach((homePageService: IntegratedService) => {
        const serviceType: string = homePageService.type;
        if (!result.has(serviceType)) {
          result.set(serviceType, []);
        }
        result.set(serviceType, [...result.get(serviceType), homePageService]);
      });
      return result;
    },
  );

  /**
   * gettingStartedServicesList
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, IntegratedService[]>>}
   * @memberof IntegratedServicesSelectors
   */
  public static gettingStartedServicesList: MemoizedSelector<CoreAppState, Map<string, IntegratedService[]>> = createSelector(
    IntegratedServicesSelectors.gettingStartedServices,
    (gettingStartedServices: IntegratedService[]) => {
      const result: Map<string, IntegratedService[]> = new Map<string, IntegratedService[]>();
      gettingStartedServices.forEach((gettingStartedService: IntegratedService) => {
        const serviceType: string = gettingStartedService.type;
        if (!result.has(serviceType)) {
          result.set(serviceType, []);
        }
        result.set(serviceType, [...result.get(serviceType), gettingStartedService]);
      });
      return result;
    },
  );

  /**
   * availableSKUServices
   * @static
   * @type {MemoizedSelector<CoreAppState, AvailableSKUService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static availableSKUServices: MemoizedSelector<CoreAppState, AvailableSKUService[]> = createSelector(
    IntegratedServicesSelectors.integratedServicesData,
    (integratedServicesData: IntegratedServicesResponse) => integratedServicesData.availableSKUServices,
  );

  /**
   * basicFeatures
   * @static
   * @type {MemoizedSelector<CoreAppState, FeatureSetup[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static basicFeatures: MemoizedSelector<CoreAppState, FeatureSetup[]> = createSelector(
    IntegratedServicesSelectors.integratedServicesData,
    (integratedServicesData: IntegratedServicesResponse) => integratedServicesData?.basicFeatures,
  );

  /**
   * betaBasicFeatures
   * @static
   * @type {MemoizedSelector<CoreAppState, FeatureSetup[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static betaBasicFeatures: MemoizedSelector<CoreAppState, FeatureSetup[]> = createSelector(
    IntegratedServicesSelectors.basicFeatures,
    (basicFeatures: FeatureSetup[]) => basicFeatures.filter((featureSetup: FeatureSetup) => featureSetup.beta),
  );

  /**
   * betaFeaturesEnabledStatusMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, boolean>>}
   * @memberof IntegratedServicesSelectors
   */
  public static betaFeaturesEnabledStatusMap: MemoizedSelector<CoreAppState, Map<string, boolean>> = createSelector(
    IntegratedServicesSelectors.betaBasicFeatures,
    UserPreferenceCommonSelectors.getFeatureControls,
    (betaBasicFeatures: FeatureSetup[], featureControls: FeatureControls) => {
      const betaFeaturesEnabledStatusMap: Map<string, boolean> = new Map<string, boolean>();
      betaBasicFeatures.forEach((betaFeature: FeatureSetup) => {
        const featureId: string = betaFeature.id;
        betaFeaturesEnabledStatusMap.set(featureId, featureControls[FEATURE_SETUP_TO_FEATURE_CONTROL_MAP[featureId]]);
      });
      return betaFeaturesEnabledStatusMap;
    },
  );

  /**
   * entitledServicesDocUrls
   * @static
   * @type {MemoizedSelector<CoreAppState, Partial<Record<IntegratedServiceType | AddOnType, string>>>}
   * @memberof IntegratedServicesSelectors
   */
  public static entitledServicesDocUrls: MemoizedSelector<CoreAppState, Partial<Record<IntegratedServiceType | AddOnType, string>>> =
    createSelector(
      UserPreferenceAssetsSelectors.getWorkspaceOneIntelligenceDocUrl,
      UserPreferenceAssetsSelectors.getHorizonCloudOverviewUrl,
      UserPreferenceAssetsSelectors.intelligenceHubDocUrl,
      UserPreferenceAssetsSelectors.workspaceOneAccessDocUrl,
      UserPreferenceAssetsSelectors.workspaceOneUemDocUrl,
      UserPreferenceAssetsSelectors.solutionsDeemDesktopTelemetryLink,
      (
        intelligenceDocUrl: string,
        horizonCloudOverviewUrl: string,
        intelligenceHubDocUrl: string,
        workspaceOneAccessDocUrl: string,
        workspaceOneUemDocUrl: string,
        deemDocUrl: string,
      ) => ({
        [IntegratedServiceType.INTELLIGENCE]: intelligenceDocUrl,
        [IntegratedServiceType.HORIZON]: horizonCloudOverviewUrl,
        [IntegratedServiceType.HUB_SERVICES]: intelligenceHubDocUrl,
        [IntegratedServiceType.WS1_ACCESS]: workspaceOneAccessDocUrl,
        [IntegratedServiceType.WS1_UEM]: workspaceOneUemDocUrl,
        [AddOnType.INTELLIGENCE_DEEM]: deemDocUrl,
      }),
    );

  /**
   * integratedServicesLoadState
   * @static
   * @type {MemoizedSelector<CoreAppState, LOAD_STATE>}
   * @memberof IntegratedServicesSelectors
   */
  public static integratedServicesLoadState: MemoizedSelector<CoreAppState, LOAD_STATE> = createSelector(
    IntegratedServicesSelectors.selectIntegratedServicesState,
    (state: IntegratedServicesState) => state.integratedServicesLoadState,
  );

  /**
   * integratedServiceTypeDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<IntegratedServiceType, Integration>>}
   * @memberof IntegratedServicesSelectors
   */
  public static integratedServiceTypeDetails: MemoizedSelector<CoreAppState, Record<IntegratedServiceType, Integration>> = createSelector(
    IntegratedServicesSelectors.selectIntegratedServicesState,
    (state: IntegratedServicesState) => state.integratedServiceTypeDetails,
  );

  /**
   * allowedIntegratedServiceToIntegrationMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Partial<Record<IntegratedServiceType, Integration>>>}
   * @memberof IntegratedServicesSelectors
   */
  public static allowedIntegratedServiceToIntegrationMap: MemoizedSelector<
    CoreAppState,
    Partial<Record<IntegratedServiceType, Integration>>
  > = createSelector(
    IntegratedServicesSelectors.homePageServicesMap,
    IntegratedServicesSelectors.integratedServiceTypeDetails,
    (homePageServicesMap: Map<string, IntegratedService[]>, integratedServiceTypeDetails: Record<IntegratedServiceType, Integration>) => {
      return Object.keys(integratedServiceTypeDetails)
        .filter((integratedServiceType: IntegratedServiceType) => {
          return homePageServicesMap.has(integratedServiceType);
        })
        .reduce(
          (
            integratedServiceToIntegrationMap: Partial<Record<IntegratedServiceType, Integration>>,
            integratedServiceType: IntegratedServiceType,
          ) => {
            integratedServiceToIntegrationMap[integratedServiceType] = integratedServiceTypeDetails[integratedServiceType];
            return integratedServiceToIntegrationMap;
          },
          {},
        );
    },
  );

  /**
   * entitledServices
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static entitledServices: MemoizedSelector<CoreAppState, IntegratedService[]> = createSelector(
    IntegratedServicesSelectors.integratedServiceTypeDetails,
    IntegratedServicesSelectors.homePageServicesMap,
    UserPreferenceSelectors.getActiveIntegrationInstances,
    IntegratedServicesSelectors.entitledServicesDocUrls,
    UserPreferenceFeatureControlsSelectors.isWorkspaceOneRbacEnabled,
    OrgSelectors.isWorkspaceOneCloudOrg,
    (
      integratedServiceTypeDetails: Record<string, string>,
      homePageServicesMap: Map<string, IntegratedService[]>,
      integrations: GenericObject,
      entitledServicesDocUrls: Record<IntegratedServiceType, string>,
      isWorkspaceOneRbacEnabled: boolean,
      isWorkspaceOneCloudOrg: boolean,
    ) => {
      const entitledServices: IntegratedService[] = [];
      Object.keys(integratedServiceTypeDetails).forEach((integratedServiceType: string) => {
        const integrationType: string = integratedServiceTypeDetails[integratedServiceType];
        const homePageService: IntegratedService = homePageServicesMap.get(integratedServiceType)?.[0];
        const activeIntegration: GenericObject = getFirstActiveIntegrationInstance(integrations[integrationType], integrationType);
        // add activeIntegration if its not ws1c org (navigation from uem case) or when ws1c rbac FF is false
        if (homePageService || ((!isWorkspaceOneCloudOrg || !isWorkspaceOneRbacEnabled) && activeIntegration)) {
          const entitledService: IntegratedService =
            homePageService ||
            new IntegratedService({
              description: `CURRENT_USER.${integratedServiceType}_DESCRIPTION`,
              docUrl: entitledServicesDocUrls[integratedServiceType],
              title: `CURRENT_USER.${integratedServiceType}_CONSOLE`,
              shortTitle: `CURRENT_USER.${integratedServiceType}_SHORT_TITLE_CONSOLE`,
              type: integratedServiceType,
              url: activeIntegration.admin_console_url || activeIntegration.tenant_url,
              urlType: FeatureSetupType.EXTERNAL,
              tenantType: TenantType.PRODUCTION,
              eligibleForInProductTrial: false,
            });
          entitledServices.push(entitledService);
        }
      });
      return entitledServices;
    },
  );

  /**
   * entitledServicesAndAddOns
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static entitledServicesAndAddOns: MemoizedSelector<CoreAppState, IntegratedService[]> = createSelector(
    IntegratedServicesSelectors.homePageServicesMap,
    IntegratedServicesSelectors.entitledServices,
    (homePageServicesMap: Map<string, IntegratedService[]>, entitledServices: IntegratedService[]) => {
      const entitledAddOns: IntegratedService[] = [];
      Object.keys(AddOnType).forEach((addOnType: string) => {
        const homePageService: IntegratedService = homePageServicesMap.get(addOnType)?.[0];
        if (homePageService) {
          entitledAddOns.push(homePageService);
        }
      });
      return entitledServices.concat(entitledAddOns);
    },
  );

  /**
   * availableServices
   * @static
   * @type {MemoizedSelector<CoreAppState, AvailableSKUService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static availableServices: MemoizedSelector<CoreAppState, AvailableSKUService[]> = createSelector(
    IntegratedServicesSelectors.entitledServices,
    IntegratedServicesSelectors.availableSKUServices,
    (entitledServices: IntegratedService[], availableSKUServices: AvailableSKUService[]) => {
      const entitledServiceTypes: string[] = entitledServices.map((entitledService: IntegratedService) => entitledService.type);
      return availableSKUServices.filter(
        (availableSKUService: AvailableSKUService) => !entitledServiceTypes.includes(availableSKUService.associatedServiceType),
      );
    },
  );

  /**
   * allServices
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static allServices: MemoizedSelector<CoreAppState, IntegratedService[]> = createSelector(
    IntegratedServicesSelectors.entitledServicesAndAddOns,
    IntegratedServicesSelectors.availableServices,
    (entitledServicesAndAddOns: IntegratedService[], availableServices: AvailableSKUService[]) => {
      const integratedServicesList: IntegratedService[] = availableServices.map(
        (availableService: AvailableSKUService) =>
          new IntegratedService({ ...availableService, type: availableService.associatedServiceType }),
      );
      return integratedServicesList.concat(entitledServicesAndAddOns);
    },
  );

  /**
   * availableServiceModes
   * @static
   * @type {MemoizedSelector<CoreAppState, ServiceMode[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static availableServiceModes: MemoizedSelector<CoreAppState, ServiceMode[]> = createSelector(
    IntegratedServicesSelectors.entitledServicesAndAddOns,
    IntegratedServicesSelectors.availableServices,
    (entitledServicesAndAddOns: IntegratedService[], availableServices: AvailableSKUService[]) => {
      const serviceModes: ServiceMode[] = [];
      if (entitledServicesAndAddOns.length) {
        serviceModes.push(ServiceMode.ENTITLED_SERVICES);
      }
      if (availableServices.length) {
        serviceModes.push(ServiceMode.ALL_SERVICES);
      }
      return serviceModes;
    },
  );

  /**
   * activeServiceMode
   * @static
   * @type {MemoizedSelector<CoreAppState, ServiceMode>}
   * @memberof IntegratedServicesSelectors
   */
  public static activeServiceMode: MemoizedSelector<CoreAppState, ServiceMode> = createSelector(
    IntegratedServicesSelectors.selectIntegratedServicesState,
    IntegratedServicesSelectors.availableServiceModes,
    (state: IntegratedServicesState, availableServiceModes: ServiceMode[]) => {
      return typeof state.activeServiceMode === 'number' ? state.activeServiceMode : availableServiceModes[0];
    },
  );

  /**
   * isLoadingSKUServices
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegratedServicesSelectors
   */
  public static isLoadingSKUServices: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegratedServicesSelectors.selectIntegratedServicesState,
    (state: IntegratedServicesState) => state.isLoadingSKUServices,
  );

  /**
   * isLoadingServices
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegratedServicesSelectors
   */
  public static isLoadingServices: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isLoadingUserPreference,
    IntegratedServicesSelectors.isLoadingSKUServices,
    (isLoadingUserPreference: boolean, isLoadingSKUServices: boolean) => isLoadingUserPreference || isLoadingSKUServices,
  );

  /**
   * isServiceLoaded
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegratedServicesSelectors
   */
  public static isServiceLoaded: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.loadState,
    IntegratedServicesSelectors.integratedServicesLoadState,
    (userPreferenceLoadState: LOAD_STATE, integratedServiceLoadState: LOAD_STATE) => {
      return userPreferenceLoadState === LOAD_STATE.SUCCESS && integratedServiceLoadState === LOAD_STATE.SUCCESS;
    },
  );

  /**
   * workspaceOneAccessServiceUrl
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof IntegratedServicesSelectors
   */
  public static workspaceOneAccessServiceUrl: MemoizedSelector<CoreAppState, string> = createSelector(
    IntegratedServicesSelectors.entitledServices,
    (entitledServices: IntegratedService[]) => {
      if (!entitledServices || !entitledServices.length) {
        return '';
      }
      const workspaceOneAccessService: IntegratedService = entitledServices.find(
        (entitledService: IntegratedService) => entitledService.type === IntegratedServiceType.WS1_ACCESS,
      );
      return workspaceOneAccessService ? workspaceOneAccessService.url : '';
    },
  );

  /**
   * externalEntitledServices
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static externalEntitledServices: MemoizedSelector<CoreAppState, IntegratedService[]> = createSelector(
    IntegratedServicesSelectors.entitledServices,
    (integratedServices: IntegratedService[]) => {
      return integratedServices.filter((integratedService: IntegratedService) => integratedService.url);
    },
  );

  /**
   * uemIntegratedService
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedService>}
   * @memberof IntegratedServicesSelectors
   */
  public static uemIntegratedService: MemoizedSelector<CoreAppState, IntegratedService> = createSelector(
    IntegratedServicesSelectors.externalEntitledServices,
    (integratedServices: IntegratedService[]) => {
      return integratedServices.find((integratedService: IntegratedService) => integratedService.type === IntegratedServiceType.WS1_UEM);
    },
  );

  /**
   * servicesByActiveMode
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegratedService[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static servicesByActiveMode: MemoizedSelector<CoreAppState, IntegratedService[]> = createSelector(
    IntegratedServicesSelectors.activeServiceMode,
    IntegratedServicesSelectors.entitledServicesAndAddOns,
    IntegratedServicesSelectors.allServices,
    (activeServiceMode: ServiceMode, entitledServicesAndAddOns: IntegratedService[], allServices: IntegratedService[]) => {
      return activeServiceMode === ServiceMode.ENTITLED_SERVICES ? entitledServicesAndAddOns : allServices;
    },
  );

  /**
   * getAvailableServiceLinks
   * @static
   * @type {MemoizedSelector<CoreAppState, ServiceLink[]>}
   * @memberof IntegratedServicesSelectors
   */
  public static getAvailableServiceLinks: MemoizedSelector<CoreAppState, ServiceLink[]> = createSelector(
    IntegratedServicesSelectors.externalEntitledServices,
    UserPreferenceSelectors.applicationNameKey,
    IntegratedServicesSelectors.homePageServicesMap,
    (integratedServices: IntegratedService[], appName: string, homePageServicesMap: Map<string, IntegratedService[]>) => {
      const allIntegratedServices: IntegratedService[] = [];
      integratedServices.forEach((service: IntegratedService) => {
        allIntegratedServices.push(...(homePageServicesMap?.get(service.type) ?? [service]));
      });
      return [
        {
          title: appName,
          type: IntegratedServiceType.INTELLIGENCE,
          isActiveService: true,
          iconName: 'dpa-ws1-intelligence-white',
        },
        ...allIntegratedServices.map((service: IntegratedService) => ({
          ...service,
          iconName: service.type ? `dpa-${service.type.toLowerCase().replace('_', '-')}-white` : undefined,
        })),
      ];
    },
  );

  /**
   * isAccessIntegrationServiceAvailable
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegratedServicesSelectors
   */
  public static isAccessIntegrationServiceAvailable: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegratedServicesSelectors.integratedServices,
    (integratedServices: IntegratedService[]) =>
      integratedServices.some((integratedService: IntegratedService) => integratedService.type === IntegratedServiceType.WS1_ACCESS),
  );

  /**
   * isTrialAvailableByServiceType
   * @static
   * @type {(serviceType: IntegratedServiceType | AddOnType) => MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegratedServicesSelectors
   */
  public static isTrialAvailableByServiceType = (serviceType: IntegratedServiceType | AddOnType): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(IntegratedServicesSelectors.allServices, (allServices: IntegratedService[]) => {
      const serviceInstance: IntegratedService = allServices.find((service: IntegratedService) => service.type === serviceType);
      return !!serviceInstance?.eligibleForInProductTrial;
    });

  /**
   * isIntelligenceOrExperienceManagementTrialAvailable
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegratedServicesSelectors
   */
  public static isIntelligenceOrExperienceManagementTrialAvailable: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegratedServicesSelectors.isTrialAvailableByServiceType(IntegratedServiceType.INTELLIGENCE),
    IntegratedServicesSelectors.isTrialAvailableByServiceType(AddOnType.INTELLIGENCE_DEEM),
    (isIntelligenceTrialAvailable: boolean, isExperienceManagementTrialAvailable: boolean) => {
      return isIntelligenceTrialAvailable || isExperienceManagementTrialAvailable;
    },
  );
}

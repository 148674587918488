/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { NgxChart } from '@ws1c/intelligence-models';

/**
 * BaseChartComponent
 * @export
 * @class BaseChartComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-base-chart',
  template: '',
})
export abstract class BaseChartComponent implements OnChanges {
  @Input() public activeSeries: Set<string> = new Set<string>();
  public chart: any;
  public ngxChart: NgxChart;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.activeSeries && changes.activeSeries.currentValue && this.chart) {
      this.chart.activeEntries = [];
      this.activeSeries.forEach((attributeName: string) => {
        this.ngxChart.colorizedEntriesByAttributeName[attributeName].forEach((entry: any) => {
          this.chart.activeEntries.push(entry);
        });
      });
      this.chart.update();
    }
  }
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { AccessType, AccountByUserDescriptor, Directory, LOAD_STATE, UserAdminAccount } from '@ws1c/intelligence-models';
import { UserAdminAccountActions } from './user-admin-account.actions';
import { initialUserAdminAccountState, UserAdminAccountState } from './user-admin-account.state';

/**
 * _reducer
 * @type {ActionReducer<UserAdminAccountState, Action>}
 */
const _reducer: ActionReducer<UserAdminAccountState, Action> = createReducer(
  initialUserAdminAccountState,

  on(UserAdminAccountActions.searchUserAdminAccounts, (state: UserAdminAccountState): UserAdminAccountState => {
    return {
      ...state,
      isUserSearchLoading: true,
      userSearchResponse: [],
    };
  }),

  on(
    UserAdminAccountActions.searchUserAdminAccountsSuccess,
    (state: UserAdminAccountState, props: { userAdminAccounts: UserAdminAccount[] }): UserAdminAccountState => {
      return {
        ...state,
        isUserSearchLoading: false,
        userSearchResponse: props.userAdminAccounts,
      };
    },
  ),

  on(
    UserAdminAccountActions.addAccountDetailsToUserSuccess,
    (
      state: UserAdminAccountState,
      props: {
        userAdminAccounts: UserAdminAccount[];
        accountByUserDescriptor: AccountByUserDescriptor;
      },
    ): UserAdminAccountState => {
      const userSearchResponse = props.userAdminAccounts.map((userAdminAccount: UserAdminAccount) => {
        const userAdmin = new UserAdminAccount({
          ...userAdminAccount,
          accessLevel: AccessType.READ,
        });
        const account = props.accountByUserDescriptor[userAdminAccount.id];
        if (account) {
          userAdmin.id = account.id;
          userAdmin.dataAccessPolicies = account.dataAccessPolicies;
          userAdmin.roles = account.roles;
        }
        return userAdmin;
      });
      return {
        ...state,
        isUserSearchLoading: false,
        userSearchResponse,
      };
    },
  ),

  on(UserAdminAccountActions.setOwnerAccountId, (state: UserAdminAccountState, props: { accountId: string }): UserAdminAccountState => {
    return {
      ...state,
      currentOwnerAccountId: props.accountId,
    };
  }),

  on(
    UserAdminAccountActions.getAzureDirectory,
    (state: UserAdminAccountState): UserAdminAccountState => ({
      ...state,
      loadingAzure: true,
    }),
  ),

  on(
    UserAdminAccountActions.getAzureDirectorySuccess,
    (state: UserAdminAccountState, { azureDirectory }: { azureDirectory: Directory }): UserAdminAccountState => ({
      ...state,
      loadingAzure: false,
      azureDirectory,
    }),
  ),

  on(
    UserAdminAccountActions.getAzureDirectoryFailure,
    (state: UserAdminAccountState): UserAdminAccountState => ({
      ...state,
      loadingAzure: false,
      azureDirectory: undefined,
    }),
  ),

  on(
    UserAdminAccountActions.getCspAccountRequestSuccess,
    (
      state: UserAdminAccountState,
      { cspAccountRequestDetails }: ReturnType<typeof UserAdminAccountActions.getCspAccountRequestSuccess>,
    ): UserAdminAccountState => ({
      ...state,
      cspAccountRequestDetails,
    }),
  ),

  on(
    UserAdminAccountActions.getCspAccountRequestFailure,
    (state: UserAdminAccountState): UserAdminAccountState => ({
      ...state,
      cspAccountRequestDetails: undefined,
    }),
  ),

  on(
    UserAdminAccountActions.submitRequestForCspAccount,
    (state: UserAdminAccountState): UserAdminAccountState => ({
      ...state,
      cspAccountRequestSubmitState: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    UserAdminAccountActions.submitRequestForCspAccountSuccess,
    (state: UserAdminAccountState): UserAdminAccountState => ({
      ...state,
      cspAccountRequestDetails: undefined,
      cspAccountRequestSubmitState: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    UserAdminAccountActions.submitRequestForCspAccountFailure,
    (state: UserAdminAccountState): UserAdminAccountState => ({
      ...state,
      cspAccountRequestSubmitState: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    UserAdminAccountActions.setCspAccountWithEmailExistenceState,
    (
      state: UserAdminAccountState,
      { isCspAccountWithEmailAlreadyExists }: ReturnType<typeof UserAdminAccountActions.setCspAccountWithEmailExistenceState>,
    ): UserAdminAccountState => ({
      ...state,
      isCspAccountWithEmailAlreadyExists,
    }),
  ),
);

/**
 * Creates and returns UserAdminAccountState
 * @export
 * @param {UserAdminAccountState} state - Holds state object of type UserAdminAccountState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {UserAdminAccountState}
 */
export function userAdminAccountState(state: UserAdminAccountState, action: Action): UserAdminAccountState {
  return _reducer(state, action);
}

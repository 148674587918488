/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { connectorCommonReducer } from '@ws1c/intelligence-core/store/connector-common/connector-common.reducer';
import { ConnectorCommonState } from '@ws1c/intelligence-core/store/connector-common/connector-common.state';
import { alertBannerState } from './alert-banner/alert-banner.reducer';
import { AlertBannerState } from './alert-banner/alert-banner.state';
import { automationCommonState } from './automation-common/automation-common.reducer';
import { AutomationCommonState } from './automation-common/automation-common.store';
import { bookmarksState } from './bookmarks/bookmarks.reducer';
import { BookmarksState } from './bookmarks/bookmarks.store';
import { connectionCommonState } from './connection-common/connection-common.reducer';
import { ConnectionCommonState } from './connection-common/connection-common.store';
import { dashboardState } from './dashboard/dashboard.reducer';
import { DashboardState } from './dashboard/dashboard.state';
import { deemCommonState } from './deem-common/deem-common.reducer';
import { DeemCommonState } from './deem-common/deem-common.store';
import { deviceProfileState } from './device-profile/device-profile.reducer';
import { DeviceProfileState } from './device-profile/device-profile.store';
import { integratedServicesState } from './integrated-services/integrated-services.reducer';
import { IntegratedServicesState } from './integrated-services/integrated-services.store';
import { integrationMetaState } from './integration-meta/integration-meta.reducer';
import { IntegrationMetaState } from './integration-meta/integration-meta.state';
import { marketplaceState } from './marketplace/marketplace.reducer';
import { MarketplaceState } from './marketplace/marketplace.state';
import { navigationState } from './navigation/navigation.reducer';
import { NavigationState } from './navigation/navigation.state';
import { navigationMenuState } from './navigation-menu/navigation-menu.reducer';
import { NavigationMenuState } from './navigation-menu/navigation-menu.store';
import { orgState } from './org/org.reducer';
import { OrgState } from './org/org.state';
import { previewCommonState } from './preview-common/preview-common.reducer';
import { PreviewCommonState } from './preview-common/preview-common.store';
import { quickFilterState } from './quick-filter/quick-filter.reducer';
import { QuickFilterState } from './quick-filter/quick-filter.store';
import { rbacState } from './rbac/rbac.reducer';
import { RbacState } from './rbac/rbac.state';
import { reportCommonState } from './report-common/report-common.reducer';
import { ReportCommonState } from './report-common/report-common.store';
import { solutionSetupState } from './solution-setup/solution-setup.reducer';
import { SolutionSetupState } from './solution-setup/solution-setup.state';
import { templateCommonState } from './template-common/template-common.reducer';
import { TemplateCommonState } from './template-common/template-common.store';
import { trialServicesState } from './trial-services/trial-services.reducer';
import { TrialServicesState } from './trial-services/trial-services.store';
import { userAdminAccountState } from './user-admin-account/user-admin-account.reducer';
import { UserAdminAccountState } from './user-admin-account/user-admin-account.state';
import { userPreferenceState } from './user-preference/user-preference.reducer';
import { UserPreferenceState } from './user-preference/user-preference.state';

/**
 * @export
 * @interface CoreAppState
 */
export interface CoreAppState {
  alertBannerState: AlertBannerState;
  automationCommonState: AutomationCommonState;
  bookmarksState: BookmarksState;
  connectionCommonState: ConnectionCommonState;
  connectorCommonState: ConnectorCommonState;
  dashboardState: DashboardState;
  deemCommonState: DeemCommonState;
  deviceProfileState: DeviceProfileState;
  integratedServicesState: IntegratedServicesState;
  integrationMetaState: IntegrationMetaState;
  marketplaceState: MarketplaceState;
  navigationState: NavigationState;
  navigationMenuState: NavigationMenuState;
  orgState: OrgState;
  previewCommonState: PreviewCommonState;
  quickFilterState: QuickFilterState;
  rbacState: RbacState;
  reportCommonState: ReportCommonState;
  router: RouterReducerState<any>;
  solutionSetupState: SolutionSetupState;
  templateCommonState: TemplateCommonState;
  trialServicesState: TrialServicesState;
  userAdminAccountState: UserAdminAccountState;
  userPreferenceState: UserPreferenceState;
}

/**
 * Core state reducer map
 * @type {ActionReducerMap<CoreAppState>}
 */
export const coreAppReducer: ActionReducerMap<CoreAppState> = {
  alertBannerState,
  automationCommonState,
  bookmarksState,
  connectionCommonState,
  connectorCommonState: connectorCommonReducer,
  dashboardState,
  deemCommonState,
  deviceProfileState,
  integratedServicesState,
  integrationMetaState,
  marketplaceState,
  navigationState,
  navigationMenuState,
  orgState,
  previewCommonState,
  quickFilterState,
  rbacState,
  reportCommonState,
  router: routerReducer,
  solutionSetupState,
  templateCommonState,
  trialServicesState,
  userAdminAccountState,
  userPreferenceState,
};

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import {
  AfterViewChecked,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { AreaChartComponent, AreaChartStackedComponent, dpaDebounce, LineChartComponent } from '@dpa/ui-common';

import { ChartMarkersComponent } from './markers-component/chart-markers.component';
import { Marker, MarkerContext } from './types';

/**
 * ChartMarkersDirective
 * @export
 * @class ChartMarkersDirective
 * @implements {OnChanges}
 * @implements {AfterViewChecked}
 * @implements {OnDestroy}
 */
@Directive({
  selector: '[dpaChartMarkers]',
})
export class ChartMarkersDirective implements OnChanges, AfterViewChecked, OnDestroy {
  @Input() public dpaChartMarkers?: Marker[] = [];
  @Input() public dpaChartMarkerTooltipTemplate?: TemplateRef<any>;
  @Output() public onMarkerClick?: EventEmitter<MarkerContext> = new EventEmitter();

  private ngxChart: AreaChartComponent | LineChartComponent;
  private targetNode: Element;
  private chartMarkersComponentRef: ComponentRef<ChartMarkersComponent>;

  /**
   * constructor
   * @param {LineChartComponent} lineChart
   * @param {AreaChartComponent} areaChart
   * @param {AreaChartStackedComponent} areaChartStacked
   * @param {ElementRef} elRef
   * @param {ComponentFactoryResolver} cfr
   * @param {Injector} injector
   * @param {NgZone} ngZone
   * @memberof ChartMarkersDirective
   */
  constructor(
    @Host() @Optional() private lineChart: LineChartComponent,
    @Host() @Optional() private areaChart: AreaChartComponent,
    @Host() @Optional() private areaChartStacked: AreaChartStackedComponent,
    private elRef: ElementRef,
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    private ngZone: NgZone,
  ) {
    if (!(this.lineChart || this.areaChart || this.areaChartStacked)) {
      return;
    }
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof ChartMarkersDirective
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.dpaChartMarkers?.currentValue) {
      this.processMarkers();
    }
  }

  /**
   * ngAfterViewChecked
   * @memberof ChartMarkersDirective
   */
  public ngAfterViewChecked() {
    this.setComponentData();
    this.updateMarkers();
  }

  /**
   * ngOnDestroy
   * @memberof ChartMarkersDirective
   */
  public ngOnDestroy() {
    this.chartMarkersComponentRef?.destroy();
  }

  /**
   * onMouseclick
   * @memberof ChartMarkersDirective
   */
  @HostListener('click', ['$event'])
  public onMouseclick(): void {
    const instance: ChartMarkersComponent = this.chartMarkersComponentRef?.instance;
    if (instance) {
      this.onMarkerClick.emit(instance.activeMarker);
    }
  }

  /**
   * setComponentData
   * @memberof ChartMarkersDirective
   */
  private setComponentData() {
    if (!this.chartMarkersComponentRef) {
      return;
    }

    const instance: ChartMarkersComponent = this.chartMarkersComponentRef.instance;

    instance.setInputs({
      markers: this.dpaChartMarkers,
      tooltipTemplate: this.dpaChartMarkerTooltipTemplate,
      ngxChart: this.ngxChart,
      targetNode: this.targetNode,
    });
  }

  /**
   * updateMarkers
   * @memberof ChartMarkersDirective
   */
  @dpaDebounce(50)
  private updateMarkers() {
    if (!this.chartMarkersComponentRef) {
      return;
    }
    const instance: ChartMarkersComponent = this.chartMarkersComponentRef.instance;
    this.ngZone.runOutsideAngular(() => {
      instance.redraw();
    });
  }

  /**
   * processMarkers
   * @memberof ChartMarkersDirective
   */
  @dpaDebounce(50)
  private processMarkers() {
    if (!this.chartMarkersComponentRef) {
      const factory = this.cfr.resolveComponentFactory(ChartMarkersComponent);
      this.chartMarkersComponentRef = factory.create(this.injector);
    }

    if (!this.targetNode) {
      if (this.lineChart) {
        this.targetNode = this.elRef.nativeElement.querySelector('svg g.line-chart > g:last-child');
        this.ngxChart = this.lineChart;
      } else if (this.areaChart) {
        this.targetNode = this.elRef.nativeElement.querySelector('svg g.area-chart > g:last-child');
        this.ngxChart = this.areaChart;
      }
    }

    this.setComponentData();
    this.updateMarkers();
  }
}

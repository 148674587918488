/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { MetaForm, metaFormConverter } from '@ws1c/intelligence-models';

/**
 * ServiceConfigTemplateIndex
 * @export
 * @interface ServiceConfigTemplateIndex
 */
export interface ServiceConfigTemplateIndex {
  [key: string]: ServiceConfigTemplate;
}

/**
 * ServiceConfigTemplate
 * @export
 * @class ServiceConfigTemplate
 */
@Serializable
export class ServiceConfigTemplate {
  @JsonProperty('service_type')
  public serviceType: string = undefined;

  @JsonProperty('service_subtype')
  public serviceSubtype: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'service_config_metadata', customConverter: metaFormConverter })
  public metaForm: MetaForm = undefined;

  /**
   * Getter for composite key from serviceType and serviceSubtype
   *
   * @readonly
   * @type {string}
   * @memberof ServiceConfigTemplate
   */
  public get compositeServiceType(): string {
    return `${this.serviceType}_${this.serviceSubtype}`;
  }

  /**
   * Creates an instance of ServiceConfigTemplate
   * @param {Array<Partial<ServiceConfigTemplate>>} args
   */
  constructor(...args: Array<Partial<ServiceConfigTemplate>>) {
    Object.assign(this, ...args);
  }
}

/**
 * ServiceConfigTemplateResponse
 * @export
 * @class ServiceConfigTemplateResponse
 */
@Serializable
export class ServiceConfigTemplateResponse {
  @JsonProperty({ name: 'data', cls: ServiceConfigTemplate })
  public data: ServiceConfigTemplate[] = [];
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { size } from 'lodash-es';

import { SolutionSettingMetricDetails, SolutionSettingMetricKey } from '@ws1c/intelligence-models';

/**
 * mergeSolutionSettingMetrics
 * @export
 * @param {SolutionSettingMetricDetails[]} currentSettingMetrics
 * @param {SolutionSettingMetricDetails[]} updatingSettingMetrics
 * @returns {SolutionSettingMetricDetails[]}
 */
export function mergeSolutionSettingMetrics(
  currentSettingMetrics: SolutionSettingMetricDetails[],
  updatingSettingMetrics: SolutionSettingMetricDetails[],
): SolutionSettingMetricDetails[] {
  if (size(currentSettingMetrics) <= 1 || size(updatingSettingMetrics) !== 1) {
    return updatingSettingMetrics;
  }
  const currentMetricKey: SolutionSettingMetricKey = currentSettingMetrics[0].metricKey;
  const updatingMetricKey: SolutionSettingMetricKey = updatingSettingMetrics[0].metricKey;
  const isPartialUpdate: boolean =
    currentMetricKey.solutionSetting === updatingMetricKey.solutionSetting && currentMetricKey.metricName === updatingMetricKey.metricName;
  const updatedActiveSettingMetrics: SolutionSettingMetricDetails[] = [
    ...currentSettingMetrics.filter(
      (item: SolutionSettingMetricDetails) => item.metricKey.platform !== updatingSettingMetrics[0].metricKey.platform,
    ),
    ...updatingSettingMetrics,
  ];
  if (isPartialUpdate) {
    return updatedActiveSettingMetrics;
  }
  return updatingSettingMetrics;
}

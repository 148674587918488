<div class="clr-row">
  <div class="type-ahead-container clr-col-4">
    <dpa-type-ahead
      *ngIf="!readonly; else readonlyDisplay"
      [selectedItem]="selectedSamplingFrequency"
      [customFormatter]="typeAheadFormatter"
      [labelFormatter]="typeAheadFormatter"
      [searchableItems]="samplingFrequencyOptions"
      (selectedItemChange)="onSamplingFrequencyOptionChange($event)"
    ></dpa-type-ahead>
    <ng-template #readonlyDisplay>
      <div>{{ selectedSamplingFrequency?.label }}</div>
    </ng-template>
  </div>
</div>

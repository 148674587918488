/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, GenericResponse, HttpService } from '@ws1c/intelligence-common';
import { Comment, CommentsRequest, CommentsResponse, ResourceType } from '@ws1c/intelligence-models';

/**
 * CommentService
 * @export
 * @class CommentService
 */
@Injectable({
  providedIn: 'root',
})
export class CommentService {
  /**
   * Creates an instance of CommentService.
   * @param {HttpService} httpService
   * @memberof CommentService
   */
  constructor(private httpService: HttpService) {}

  /**
   * getComments$
   * @param {string} id
   * @param {string} resourceType
   * @param {CommentsRequest} request
   * @returns {Observable<CommentsResponse>}
   * @memberof CommentService
   */
  public getComments$(id: string, resourceType: ResourceType, request: CommentsRequest): Observable<CommentsResponse> {
    return this.httpService.post(Endpoint.COMMENTS_SEARCH(resourceType, id), request).pipe(
      map((response: GenericResponse) => deserialize(CommentsResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * addComment$
   * @param {Comment} comment
   * @param {ResourceType} resourceType
   * @returns {Observable<Comment>}
   * @memberof CommentService
   */
  public addComment$(comment: Comment, resourceType: ResourceType): Observable<Comment> {
    return this.httpService.post(Endpoint.COMMENTS_BASE(resourceType, comment.resourceId), comment).pipe(
      map((response: GenericResponse) => deserialize(Comment, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteComment$
   * @param {Comment} comment
   * @param {ResourceType} resourceType
   * @returns {Observable<boolean>}
   * @memberof CommentService
   */
  public deleteComment$(comment: Comment, resourceType: ResourceType): Observable<boolean> {
    return this.httpService.delete(Endpoint.UPDATE_COMMENT(resourceType, comment.resourceId, comment.id)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateComment$
   * @param {Comment} comment
   * @param {ResourceType} resourceType
   * @returns {Observable<boolean>}
   * @memberof CommentService
   */
  public updateComment$(comment: Comment, resourceType: ResourceType): Observable<boolean> {
    return this.httpService.put(Endpoint.UPDATE_COMMENT(resourceType, comment.resourceId, comment.id), comment).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { type } from '@ws1c/intelligence-common/utils/type';
import {
  AggregationAttributesResponse,
  Category,
  CategoryColumns,
  Column,
  FilterAttributeConfig,
  PrecomputedAggregation,
  SuggestionFilterBy,
  SuggestionSearch,
  SuggestionSearchItem,
  Tag,
} from '@ws1c/intelligence-models';

/**
 * IntegrationMetaActions
 * @export
 * @class IntegrationMetaActions
 */
export class IntegrationMetaActions {
  public static readonly CATEGORY = '[INTEGRATION_META]';

  public static loadColumns = createAction(
    type(`${IntegrationMetaActions.CATEGORY} LOAD_COLUMNS`),
    props<{
      categoryId: string;
      isCrossCategory?: boolean;
      setActiveCategory?: boolean;
    }>(),
  );

  public static loadColumnsSuccess = createAction(
    type(`${IntegrationMetaActions.CATEGORY} LOAD_COLUMNS_SUCCESS`),
    props<{
      columns: Column[];
      category: Category;
    }>(),
  );

  public static loadColumnsForMultiCategory = createAction(
    type(`${IntegrationMetaActions.CATEGORY} LOAD_COLUMNS_FOR_MULTI_CATEGORY`),
    props<{
      entitiesByIntegration: GenericObject;
      activeCategories: Category[];
    }>(),
  );

  public static loadColumnsForMultiCategorySuccess = createAction(
    type(`${IntegrationMetaActions.CATEGORY} LOAD_COLUMNS_FOR_MULTI_CATEGORY_SUCCESS`),
    props<{
      categoryColumns: CategoryColumns;
    }>(),
  );

  public static loadColumnsQuietly = createAction(
    type(`${IntegrationMetaActions.CATEGORY} LOAD_COLUMNS_QUIETLY`),
    props<{
      categoryId: string;
      isCrossCategory?: boolean;
    }>(),
  );

  public static searchFilterValuesSuccess = createAction(
    type(`${IntegrationMetaActions.CATEGORY} SEARCH_FILTER_VALUES_SUCCESS`),
    props<{
      suggestionValues: SuggestionSearchItem[];
      suggestionSearch: SuggestionSearch;
    }>(),
  );

  public static searchFilterValuesFailure = createAction(
    type(`${IntegrationMetaActions.CATEGORY} SEARCH_FILTER_VALUES_FAILURE`),
    props<{
      suggestionSearch: SuggestionSearch;
    }>(),
  );

  public static searchFilterValues = createAction(
    type(`${IntegrationMetaActions.CATEGORY} SEARCH_FILTER_VALUES_V2`),
    props<{
      suggestionSearch: SuggestionSearch;
    }>(),
  );

  public static getAvailableFilterTags = createAction(
    type(`${IntegrationMetaActions.CATEGORY} GET_AVAILABLE_FILTER_TAGS`),
    props<{
      attributes: FilterAttributeConfig[];
      suggestionFilterBys: SuggestionFilterBy[];
    }>(),
  );

  public static getAvailableFilterTagsSuccess = createAction(
    type(`${IntegrationMetaActions.CATEGORY} GET_AVAILABLE_FILTER_TAGS_SUCCESS`),
    props<{
      filterTags: Tag[];
    }>(),
  );

  public static cleanAvailableFilterTags = createAction(type(`${IntegrationMetaActions.CATEGORY} CLEAN_AVAILABLE_FILTER_TAGS`));

  public static searchAvailableColumns = createAction(
    type(`${IntegrationMetaActions.CATEGORY} SEARCH_AVAILABLE_COLUMNS`),
    props<{
      query: string;
      selected: string;
    }>(),
  );

  public static resetColumnsByGroupSearchString = createAction(
    type(`${IntegrationMetaActions.CATEGORY} RESET_COLUMNS_BY_GROUP_SEARCH_STRING`),
  );

  public static toggleColumnFilter = createAction(
    type(`${IntegrationMetaActions.CATEGORY} TOGGLE_COLUMN_FILTER`),
    props<{
      name: string;
    }>(),
  );

  public static resetColumnFilters = createAction(type(`${IntegrationMetaActions.CATEGORY} RESET_COLUMN_FILTERS`));

  public static loadCategoriesSuccess = createAction(
    type(`${IntegrationMetaActions.CATEGORY} LOAD_CATEGORIES_SUCCESS`),
    props<{
      categories: Category[];
    }>(),
  );

  public static loadCategoriesFailure = createAction(type(`${IntegrationMetaActions.CATEGORY} LOAD_CATEGORIES_FAILURE`));

  public static getPrecomputedAggregations = createAction(
    type(`${IntegrationMetaActions.CATEGORY} GET_PRECOMPUTED_AGGREGATIONS`),
    props<{
      categoryId: string;
      setActiveCategory?: boolean;
    }>(),
  );

  public static getPrecomputedAggregationsSuccess = createAction(
    type(`${IntegrationMetaActions.CATEGORY} GET_PRECOMPUTED_AGGREGATIONS_SUCCESS`),
    props<{
      categoryId: string;
      precomputedAggregations: PrecomputedAggregation[];
    }>(),
  );

  public static getPrecomputedAggregationsFailure = createAction(
    type(`${IntegrationMetaActions.CATEGORY} GET_PRECOMPUTED_AGGREGATIONS_FAILURE`),
  );

  public static getAggregationAttributes = createAction(
    type(`${IntegrationMetaActions.CATEGORY} GET_AGGREGATION_ATTRIBUTES`),
    props<{
      category?: Category;
      id: string;
    }>(),
  );

  public static getAggregationAttributesSuccess = createAction(
    type(`${IntegrationMetaActions.CATEGORY} GET_AGGREGATION_ATTRIBUTES_SUCCESS`),
    props<{
      category?: Category;
      aggregationAttributesResponse: AggregationAttributesResponse;
    }>(),
  );

  public static getAggregationAttributesFailure = createAction(
    type(`${IntegrationMetaActions.CATEGORY} GET_AGGREGATION_ATTRIBUTES_FAILURE`),
  );

  public static clearAttributes = createAction(type(`${IntegrationMetaActions.CATEGORY} CLEAR_ATTRIBUTES`));

  public static clearActiveCategories = createAction(type(`${IntegrationMetaActions.CATEGORY} CLEAR_ACTIVE_CATEGORIES`));
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSyntheticURLMonitoringConfigState
 * @enum {string}
 * @export
 */
export enum DeemSyntheticURLMonitoringConfigState {
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
  PUBLISHED = 'PUBLISHED',
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSettingsUxScoreRowIndex
 * @export
 * @enum {number}
 */
export enum DeemSettingsUxScoreRowIndex {
  DESKTOP_APP_EXPERIENCE_SCORE,
  MOBILE_APP_EXPERIENCE_SCORE,
  DEVICE_HEALTH_EXPERIENCE_SCORE,
  ORGANIZATION_EXPERIENCE_SCORE,
  VIRTUAL_SESSION,
  VIRTUAL_EXPERIENCE,
  HORIZON_APP,
  HORIZON_INFRASTRUCTURE,
}

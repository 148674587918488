/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * ScoreAnalysisSortBy
 * @export
 * @enum {string}
 */
export enum ScoreAnalysisSortBy {
  HIGHEST_POOR_SCORE = 'Highest Poor Score',
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges } from '@angular/core';

import { DeemUxScoreThreshold, ThresholdDatatype, ThresholdLevel } from '@ws1c/intelligence-models';

/**
 * ThresholdRangePillComponent
 * @export
 * @class ThresholdRangePillComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-threshold-range-pill',
  templateUrl: 'threshold-range-pill.component.html',
  styleUrls: ['threshold-range-pill.component.scss'],
})
export class ThresholdRangePillComponent implements OnChanges {
  @Input() public original: any;
  @Input() public isNA?: boolean = false;
  @Input() public threshold: DeemUxScoreThreshold;
  public pill: string = 'na';

  /**
   * ngOnChanges
   * @memberof ThresholdRangePillComponent
   */
  public ngOnChanges() {
    if (!this.threshold) {
      return;
    }
    let value = this.original;
    if (this.threshold.type === ThresholdDatatype.TIME) {
      value = this.original / 1000;
    }
    if (!Number.isFinite(value)) {
      return;
    }
    if (this.threshold.isReversed) {
      if (value > this.threshold.values.GOOD) {
        this.pill = ThresholdLevel.GOOD.toLowerCase();
      } else if (value >= this.threshold.values.NEUTRAL && value <= this.threshold.values.GOOD) {
        this.pill = ThresholdLevel.NEUTRAL.toLowerCase();
      } else {
        this.pill = ThresholdLevel.POOR.toLowerCase();
      }
    } else {
      if (value < this.threshold.values.NEUTRAL) {
        this.pill = ThresholdLevel.GOOD.toLowerCase();
      } else if (value >= this.threshold.values.NEUTRAL && value <= this.threshold.values.BAD) {
        this.pill = ThresholdLevel.NEUTRAL.toLowerCase();
      } else {
        this.pill = ThresholdLevel.POOR.toLowerCase();
      }
    }
  }
}

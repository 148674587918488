/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { MARKETPLACE_PREVIEW_FIRST_SELECTED_REQUEST_SIZE, MARKETPLACE_PREVIEW_REQUEST_SIZE } from '@ws1c/intelligence-core/const';
import { MarketplaceResource, MarketplaceSearchRequest, MarketplaceSearchResponse } from '@ws1c/intelligence-models';

/**
 * getResourcePreviewRequest
 * @param {MarketplaceResource} resource
 * @param {MarketplaceSearchRequest} request
 * @param {MarketplaceSearchResponse} response
 * @returns {MarketplaceSearchRequest}
 */
export function getResourcePreviewRequest(
  resource: MarketplaceResource,
  request: MarketplaceSearchRequest,
  response: MarketplaceSearchResponse,
): MarketplaceSearchRequest {
  const resourceIndex = response.searchResults.results.findIndex(
    (resultResource: MarketplaceResource) => resultResource.id === resource.id,
  );
  const from = Math.max(request.from + resourceIndex - 1, 0);
  return new MarketplaceSearchRequest({
    ...request,
    size: resourceIndex > 0 ? MARKETPLACE_PREVIEW_REQUEST_SIZE : MARKETPLACE_PREVIEW_FIRST_SELECTED_REQUEST_SIZE,
    from,
  });
}

/**
 * getNextResourcePreviewRequest
 * @param {MarketplaceSearchRequest} request
 * @returns {MarketplaceSearchRequest}
 */
export function getNextResourcePreviewRequest(request: MarketplaceSearchRequest): MarketplaceSearchRequest {
  return new MarketplaceSearchRequest({
    ...request,
    size: MARKETPLACE_PREVIEW_REQUEST_SIZE,
    from: request.from || request.size === MARKETPLACE_PREVIEW_REQUEST_SIZE ? request.from + 1 : request.from,
  });
}

/**
 * getPrevResourcePreviewRequest
 * @param {MarketplaceSearchRequest} request
 * @returns {MarketplaceSearchRequest}
 */
export function getPrevResourcePreviewRequest(request: MarketplaceSearchRequest): MarketplaceSearchRequest {
  return new MarketplaceSearchRequest({
    ...request,
    size: request.from ? MARKETPLACE_PREVIEW_REQUEST_SIZE : MARKETPLACE_PREVIEW_FIRST_SELECTED_REQUEST_SIZE,
    from: Math.max(request.from - 1, 0),
  });
}

/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { GroupedBaseChartComponent } from '@ws1c/dashboard-common/chart/grouped-chart/grouped-base-chart.component';

/**
 * GroupedHorizontalChartComponent
 * @export
 * @class GroupedHorizontalChartComponent
 * @extends {GroupedBaseChartComponent}
 */
@Component({
  selector: 'dpa-grouped-horizontal-chart',
  templateUrl: 'grouped-horizontal-chart.component.html',
  styleUrls: ['grouped-horizontal-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedHorizontalChartComponent extends GroupedBaseChartComponent {}

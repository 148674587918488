/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { filter as _filter, keyBy, mapValues, set, size, sortBy } from 'lodash-es';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import {
  AggregationAttributesResponse,
  AggregationColumn,
  Category,
  CategoryIndex,
  Column,
  COLUMN_FILTERS,
  COLUMN_NAMES,
  ColumnIndex,
  ColumnToggleFilter,
  EntityColumns,
  Integration,
  IntegrationCategories,
  PrecomputedAggregation,
  SuggestionSearch,
  Tag,
  TEMPLATE_TYPE,
} from '@ws1c/intelligence-models';
import {
  getCategoryLabelWithIntegration,
  getColumnsByCategory,
  getColumnToggleFilterMap,
  getCommonColumns,
  getFilterColumns,
  getGroupByColumns,
  getTableColumns,
  groupCategoriesByIntegration,
  groupColumnsByCategory,
  selectResult,
} from './integration-meta-selector-helpers';
import { IntegrationMetaState } from './integration-meta.state';

/**
 * IntegrationMetaSelectors
 *
 * @export
 * @class IntegrationMetaSelectors
 */
export class IntegrationMetaSelectors {
  public static selectIntegrationMetaState = (state: CoreAppState) => state.integrationMetaState;

  /**
   * getAvailableCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, Category[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAvailableCategories: MemoizedSelector<CoreAppState, Category[]> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.categories ?? [],
  );

  /**
   * getAirwatchCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Category>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAirwatchCategory: MemoizedSelector<CoreAppState, Category> = createSelector(
    IntegrationMetaSelectors.getAvailableCategories,
    (categories: Category[]) => {
      return categories.find((category: Category) => category.integration.name === Integration.AIRWATCH);
    },
  );

  /**
   * getCategoriesByIntegrationByName
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getCategoriesByIntegrationByName: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    IntegrationMetaSelectors.getAvailableCategories,
    (categories: Category[]) => {
      const categoriesByIntegrationByName = {};
      categories?.forEach((category: Category) => {
        set(categoriesByIntegrationByName, [category.integration.name, category.name], category);
      });
      return categoriesByIntegrationByName;
    },
  );

  /**
   * getAvailableCategoriesState
   * @static
   * @type {MemoizedSelector<CoreAppState, Category[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAvailableCategoriesState: MemoizedSelector<CoreAppState, Category[]> = createSelector(
    IntegrationMetaSelectors.getAvailableCategories,
    (categories: Category[]) => {
      const categoriesWithLabel = _filter(categories, (category: Category) => {
        return !!category?.label;
      });
      return sortBy(categoriesWithLabel, [(cat: Category) => cat.integration.name, (cat: Category) => cat.name]);
    },
  );

  /**
   * getTemplateIntegrationCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getTemplateIntegrationCategories = (templateType: TEMPLATE_TYPE): MemoizedSelector<CoreAppState, IntegrationCategories[]> =>
    createSelector(IntegrationMetaSelectors.getAvailableCategoriesState, (categories: Category[]) => {
      return groupCategoriesByIntegration(categories.filter((category: Category) => category.isTemplateTypeSupported(templateType)));
    });

  /**
   * getTemplateIntegrationCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getTemplateIntegrationLabelsByName = (
    templateType: TEMPLATE_TYPE,
  ): MemoizedSelector<CoreAppState, Record<string, string>> =>
    createSelector(
      IntegrationMetaSelectors.getTemplateIntegrationCategories(templateType),
      (integrationCategoriesArray: IntegrationCategories[]) => {
        return integrationCategoriesArray.reduce(
          (accum: GenericObject, integrationCategories: IntegrationCategories) => ({
            ...accum,
            [integrationCategories.integration.name]: integrationCategories.integration.label,
          }),
          {},
        );
      },
    );

  /**
   * getWidgetsHistoricalIntegrationCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getWidgetsHistoricalIntegrationCategories: MemoizedSelector<CoreAppState, IntegrationCategories[]> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    (categories: Category[]) => {
      return groupCategoriesByIntegration(
        categories.filter((category: Category) => category.isTemplateTypeSupported(TEMPLATE_TYPE.WIDGETS) && category.isHistorical),
      );
    },
  );

  /**
   * getCategoriesByCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, CategoryIndex>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getCategoriesByCategoryId: MemoizedSelector<CoreAppState, CategoryIndex> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    (categories: Category[]) => {
      return (keyBy(categories, 'categoryId') as CategoryIndex) || {};
    },
  );

  /**
   * getAvailableCategoryIds
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAvailableCategoryIds: MemoizedSelector<CoreAppState, string[]> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    (categories: Category[]) => categories?.map((category: Category) => category.categoryId) || [],
  );

  /**
   * getActiveCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Category>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getActiveCategory: MemoizedSelector<CoreAppState, Category> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.activeCategories[0],
  );

  /**
   * getActiveCategoryLabel
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof IntegrationMetaSelectors
   */
  public static getActiveCategoryLabel: MemoizedSelector<CoreAppState, string> = createSelector(
    IntegrationMetaSelectors.getActiveCategory,
    getCategoryLabelWithIntegration,
  );

  /**
   * getActiveCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, Category[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getActiveCategories: MemoizedSelector<CoreAppState, Category[]> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.activeCategories,
  );

  /**
   * getColumnsByCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<Category, Column[]>>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getColumnsByCategory: MemoizedSelector<CoreAppState, Map<Category, Column[]>> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.columnsByCategory,
  );

  /**
   * getAllColumnsByCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Column[]>>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAllColumnsByCategoryId: MemoizedSelector<CoreAppState, Record<string, Column[]>> = createSelector(
    IntegrationMetaSelectors.getColumnsByCategory,
    (columnsByCategory: Map<Category, Column[]>) => {
      const columnsByCategoryId = {};
      columnsByCategory.forEach((columns: Column[], category: Category) => {
        columnsByCategoryId[category.categoryId] = columns;
      });
      return columnsByCategoryId;
    },
  );

  /**
   * getColumnsByCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Column[]>>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getColumnsByCategoryId: MemoizedSelector<CoreAppState, Record<string, Column[]>> = createSelector(
    IntegrationMetaSelectors.getColumnsByCategory,
    (columnsByCategory: Map<Category, Column[]>) => {
      const columnsByCategoryId = {};
      columnsByCategory.forEach((columns: Column[], category: Category) => {
        columnsByCategoryId[category.categoryId] = columns.filter((column: Column) => !column.isHiddenInFilter);
      });
      return columnsByCategoryId;
    },
  );

  /**
   * getColumnsByNameByCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Record<string, Column>>>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getColumnsByNameByCategoryId: MemoizedSelector<CoreAppState, Record<string, Record<string, Column>>> = createSelector(
    IntegrationMetaSelectors.getColumnsByCategoryId,
    (columnsByCategory: Record<string, Column[]>) => {
      return mapValues(columnsByCategory, (columns: Column[]) => keyBy(columns, COLUMN_NAMES.byName.attributeName));
    },
  );

  /**
   * getAllColumnsByActiveCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Column[]>>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAllColumnsByActiveCategories: MemoizedSelector<CoreAppState, Record<string, Column[]>> = createSelector(
    IntegrationMetaSelectors.getActiveCategories,
    IntegrationMetaSelectors.getColumnsByCategory,
    (categories: Category[], columnsByCategory: Map<Category, Column[]>) => {
      const columnsByActiveCategory = {};
      categories.forEach((category: Category) => {
        if (columnsByCategory.get(category)) {
          columnsByActiveCategory[category.categoryId] = columnsByCategory.get(category);
        }
      });
      return columnsByActiveCategory;
    },
  );

  /**
   * getSupportedTrendModesByCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, string[]>>}
   * @memberof IntegrationMetaSelectors
   */
  public static getSupportedTrendModesByCategoryId: MemoizedSelector<CoreAppState, Record<string, string[]>> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    (categories: Category[]) => {
      const supportedTrendModesByCategoryId = {};
      categories.forEach((category: Category) => {
        supportedTrendModesByCategoryId[category?.categoryId] = category?.supportedTrendModes;
      });
      return supportedTrendModesByCategoryId;
    },
  );

  /**
   * isColumnsLoadedForActiveCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf IntegrationMetaSelectors
   */
  public static isColumnsLoadedForActiveCategory: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.getAllColumnsByActiveCategories,
    (columnsByActiveCategory: Record<string, Column[]>) => size(columnsByActiveCategory) > 0,
  );

  /**
   * getAllColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAllColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getAllColumnsByActiveCategories,
    (columnsByActiveCategory: GenericObject) => {
      if (size(columnsByActiveCategory) === 1) {
        return columnsByActiveCategory[Object.keys(columnsByActiveCategory)[0]];
      } else if (size(columnsByActiveCategory) > 1) {
        return getCommonColumns(columnsByActiveCategory);
      }
      return [];
    },
  );

  /**
   * getTableColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getTableColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getAllColumns,
    getTableColumns,
  );

  /**
   * getFilterColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getFilterColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getAllColumns,
    getFilterColumns,
  );

  /**
   * getColumnsByGroupSearchString
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getColumnsByGroupSearchString: MemoizedSelector<CoreAppState, string> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.columnsByGroupSearchString,
  );

  /**
   * getColumnFilterValues
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, boolean>>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getColumnFilterValues: MemoizedSelector<CoreAppState, Record<string, boolean>> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.filterValues,
  );

  /**
   * getFilterTags
   * @static
   * @type {MemoizedSelector<CoreAppState, Tag[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getFilterTags: MemoizedSelector<CoreAppState, Tag[]> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.filterTags,
  );

  /**
   * columnFilterNormalizedEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf IntegrationMetaSelectors
   */
  public static columnFilterNormalizedEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.getColumnFilterValues,
    (filterValues: Record<string, boolean>) => filterValues[COLUMN_FILTERS.NORMALIZED],
  );

  /**
   * getTableColumnsByGroups
   * @static
   * @type {MemoizedSelector<CoreAppState, EntityColumns[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getTableColumnsByGroups: MemoizedSelector<CoreAppState, EntityColumns[]> = createSelector(
    IntegrationMetaSelectors.getTableColumns,
    IntegrationMetaSelectors.getColumnsByGroupSearchString,
    IntegrationMetaSelectors.getColumnFilterValues,
    IntegrationMetaSelectors.getActiveCategory,
    getColumnsByCategory,
  );

  /**
   * getTableColumnsByGroupsUnfiltered - This doesn't filter the columns based on columnsByGroupSearchString
   * @static
   * @type {MemoizedSelector<CoreAppState, EntityColumns[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getTableColumnsByGroupsUnfiltered: MemoizedSelector<CoreAppState, EntityColumns[]> = createSelector(
    IntegrationMetaSelectors.getTableColumns,
    IntegrationMetaSelectors.getColumnFilterValues,
    IntegrationMetaSelectors.getActiveCategory,
    (columns: Column[], filterValues: Record<string, boolean>, activeCategory: Category) => {
      return getColumnsByCategory(columns, '', filterValues, activeCategory); // filter argument set to ''
    },
  );

  /**
   * getFilterKeysByGroups - like getColumnsByCategory$ but without hidden columns
   * @static
   * @type {MemoizedSelector<CoreAppState, EntityColumns[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getFilterKeysByGroups: MemoizedSelector<CoreAppState, EntityColumns[]> = createSelector(
    IntegrationMetaSelectors.getFilterColumns,
    IntegrationMetaSelectors.getColumnsByGroupSearchString,
    IntegrationMetaSelectors.getColumnFilterValues,
    IntegrationMetaSelectors.getActiveCategory,
    getColumnsByCategory,
  );

  /**
   * getCrossCategoryColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Column[]>>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getCrossCategoryColumns: MemoizedSelector<CoreAppState, Record<string, Column[]>> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.crossCategoryColumns,
  );

  /**
   * getAllActiveCrossCategoryColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAllActiveCrossCategoryColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getActiveCategory,
    IntegrationMetaSelectors.getCrossCategoryColumns,
    (category: Category, crossCategoryColumns: Record<string, Column[]>) => (category ? crossCategoryColumns[category.categoryId] : []),
  );

  /**
   * getFilterActiveCrossCategoryColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getFilterActiveCrossCategoryColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getActiveCategory,
    IntegrationMetaSelectors.getCrossCategoryColumns,
    (category: Category, crossCategoryColumns: Record<string, Column[]>) => {
      if (!category || !crossCategoryColumns) {
        return [];
      }
      return getFilterColumns(crossCategoryColumns[category.categoryId]);
    },
  );

  /**
   * isCrossCategoryColumnsLoadedForActiveCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegrationMetaSelectors
   */
  public static isCrossCategoryColumnsLoadedForActiveCategory: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.getAllActiveCrossCategoryColumns,
    (columns: Column[]) => size(columns) > 0,
  );

  /**
   * getCrossCategoryColumnsByName - gets columns for current active category indexed by name
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getCrossCategoryColumnsByName: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    IntegrationMetaSelectors.getAllActiveCrossCategoryColumns,
    (allColumns: Column[]) => keyBy(allColumns, COLUMN_NAMES.byName.attributeName),
  );

  /**
   * getTableActiveCrossCategoryColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getTableActiveCrossCategoryColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getAllActiveCrossCategoryColumns,
    getTableColumns,
  );

  /**
   * getAllActiveCrossCategoryColumnsSortedByName
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getAllActiveCrossCategoryColumnsSortedByName: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getAllActiveCrossCategoryColumns,
    (allColumns: Column[]) => sortBy(allColumns, COLUMN_NAMES.byName.label),
  );

  /**
   * getVisibleActiveCrossCategoryColumnsSortedByName
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getVisibleActiveCrossCategoryColumnsSortedByName: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getAllActiveCrossCategoryColumnsSortedByName,
    getFilterColumns,
  );

  /**
   * getActiveCrossCategoryColumnsByEntityForTable - get columns for current active category
   * @static
   * @type {MemoizedSelector<CoreAppState, EntityColumns[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getActiveCrossCategoryColumnsByEntityForTable: MemoizedSelector<CoreAppState, EntityColumns[]> = createSelector(
    IntegrationMetaSelectors.getTableActiveCrossCategoryColumns,
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    IntegrationMetaSelectors.getColumnsByGroupSearchString,
    IntegrationMetaSelectors.getColumnFilterValues,
    IntegrationMetaSelectors.getActiveCategory,
    groupColumnsByCategory,
  );

  /**
   * getActiveCrossCategoryColumnsByEntityForFilter - get columns for current active category
   * @static
   * @type {MemoizedSelector<CoreAppState, EntityColumns[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getActiveCrossCategoryColumnsByEntityForFilter: MemoizedSelector<CoreAppState, EntityColumns[]> = createSelector(
    IntegrationMetaSelectors.getFilterActiveCrossCategoryColumns,
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    IntegrationMetaSelectors.getColumnsByGroupSearchString,
    IntegrationMetaSelectors.getColumnFilterValues,
    IntegrationMetaSelectors.getActiveCategory,
    groupColumnsByCategory,
  );

  /**
   * getAllColumnsByName - gets columns for current active category indexed by name
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAllColumnsByName: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    IntegrationMetaSelectors.getAllColumns,
    (allColumns: Column[]) => keyBy(allColumns, COLUMN_NAMES.byName.attributeName),
  );

  /**
   * isCrossCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegrationMetaSelectors
   */
  public static isCrossCategory: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.isCrossCategory,
  );

  /**
   * getFilterColumnsByName
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getFilterColumnsByName: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    IntegrationMetaSelectors.isCrossCategory,
    IntegrationMetaSelectors.getAllColumns,
    IntegrationMetaSelectors.getAllActiveCrossCategoryColumns,
    (isCrossCategory: boolean, columns: Column[], crossCategoryColumns: Column[]) => {
      const filterColumns = isCrossCategory ? getFilterColumns(crossCategoryColumns) : getFilterColumns(columns);
      return keyBy(filterColumns, COLUMN_NAMES.byName.attributeName);
    },
  );

  /**
   * getActiveColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getActiveColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.isCrossCategory,
    IntegrationMetaSelectors.getAllColumns,
    IntegrationMetaSelectors.getAllActiveCrossCategoryColumns,
    (isCrossCategory: boolean, columns: Column[], crossCategoryColumns: Column[]) => {
      return isCrossCategory ? crossCategoryColumns : columns;
    },
  );

  /**
   * getGroupByColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getCrossCategoryGroupByColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getAllActiveCrossCategoryColumns,
    getGroupByColumns,
  );

  /**
   * getColumnsByName
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @memberof IntegrationMetaSelectors
   */
  public static getColumnsByName: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    IntegrationMetaSelectors.isCrossCategory,
    IntegrationMetaSelectors.getAllColumnsByName,
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    selectResult,
  );

  public static getColumnsByNameForConditions: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    IntegrationMetaSelectors.getColumnsByName,
    (columnsByName: ColumnIndex) => {
      const conditionsColumns = Object.values(columnsByName).filter((column: Column) => !column.isHiddenInConditions);
      return keyBy(conditionsColumns, COLUMN_NAMES.byName.attributeName);
    },
  );

  /**
   * getGroupByColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getGroupByColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getColumnsByName,
    (columnsByName: ColumnIndex) => getGroupByColumns(Object.values(columnsByName)),
  );

  /**
   * getRawAndNormalizedMapping
   * @type {MemoizedSelector<CoreAppState, Record<string, ColumnToggleFilter>>}
   * @memberof IntegrationMetaSelectors
   */
  public static getRawAndNormalizedMapping: MemoizedSelector<CoreAppState, Record<string, ColumnToggleFilter>> = createSelector(
    IntegrationMetaSelectors.getColumnsByName,
    getColumnToggleFilterMap,
  );

  /**
   * getAllColumnsByNameForGroupBy - gets columns for current active category indexed by name and filtered for groupby
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getAllColumnsByNameForGroupBy: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    IntegrationMetaSelectors.isCrossCategory,
    IntegrationMetaSelectors.getCrossCategoryGroupByColumns,
    IntegrationMetaSelectors.getGroupByColumns,
    (isCrossCategory: boolean, crossCategoryColumns: Column[], allColumns: Column[]) => {
      return keyBy(isCrossCategory ? crossCategoryColumns : allColumns, COLUMN_NAMES.byName.attributeName);
    },
  );

  /**
   * getColumnsForFilter - This is used in the filters
   * @static
   * @type {MemoizedSelector<CoreAppState, EntityColumns[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getColumnsForFilter: MemoizedSelector<CoreAppState, EntityColumns[]> = createSelector(
    IntegrationMetaSelectors.isCrossCategory,
    IntegrationMetaSelectors.getFilterKeysByGroups,
    IntegrationMetaSelectors.getActiveCrossCategoryColumnsByEntityForFilter,
    selectResult,
  );

  /**
   * getTableColumnsByEntity - This is used while adding columns to the preview table
   * @static
   * @type {MemoizedSelector<CoreAppState, EntityColumns[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getTableColumnsByEntity: MemoizedSelector<CoreAppState, EntityColumns[]> = createSelector(
    IntegrationMetaSelectors.isCrossCategory,
    IntegrationMetaSelectors.getTableColumnsByGroups,
    IntegrationMetaSelectors.getActiveCrossCategoryColumnsByEntityForTable,
    selectResult,
  );

  /**
   * getTableColumnsByEntityUnfiltered - This is used for available columns in the column selector
   * @static
   * @type {MemoizedSelector<CoreAppState, EntityColumns[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static getTableColumnsByEntityUnfiltered: MemoizedSelector<CoreAppState, EntityColumns[]> = createSelector(
    IntegrationMetaSelectors.isCrossCategory,
    IntegrationMetaSelectors.getTableColumnsByGroupsUnfiltered,
    IntegrationMetaSelectors.getActiveCrossCategoryColumnsByEntityForTable,
    selectResult,
  );

  /**
   * getAllColumnsSortedByName
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getAllColumnsSortedByName: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getActiveColumns,
    (allColumns: Column[]) => sortBy(allColumns, COLUMN_NAMES.byName.label),
  );

  /**
   * getVisibleColumnsSortedByName
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getVisibleColumnsSortedByName: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getAllColumnsSortedByName,
    getFilterColumns,
  );

  /**
   * columnsByCategoryId
   * @static
   * @param {MemoizedSelector<GenericObject, boolean>} crossCategorySelector
   * @returns {MemoizedSelector<GenericObject, Record<string, Column[]>>}
   */
  public static columnsByCategoryId = (
    crossCategorySelector: MemoizedSelector<GenericObject, boolean>,
  ): MemoizedSelector<GenericObject, Record<string, Column[]>> =>
    createSelector(
      IntegrationMetaSelectors.getColumnsByCategory,
      IntegrationMetaSelectors.getCrossCategoryColumns,
      crossCategorySelector,
      (columnsByCategory: Map<Category, Column[]>, crossCategoryColumns: Record<string, Column[]>, isCrossCategory: boolean) => {
        const columnsByCategoryId = {};
        if (isCrossCategory) {
          Object.keys(crossCategoryColumns).forEach((categoryId: string) => {
            columnsByCategoryId[categoryId] = crossCategoryColumns[categoryId]?.filter((column: Column) => !column.isHiddenInFilter);
          });
        } else {
          columnsByCategory.forEach((columns: Column[], category: Category) => {
            columnsByCategoryId[category.categoryId] = columns.filter((column: Column) => !column.isHiddenInFilter);
          });
        }
        return columnsByCategoryId;
      },
    );

  /**
   * categoriesByCategoryId
   * @static
   * @param {MemoizedSelector<GenericObject, boolean>} crossCategorySelector
   * @returns {MemoizedSelector<GenericObject, Record<string, Record<string, Column>>>}
   * @memberof IntegrationMetaSelectors
   */
  public static categoriesByCategoryId = (
    crossCategorySelector: MemoizedSelector<GenericObject, boolean>,
  ): MemoizedSelector<GenericObject, Record<string, Record<string, Column>>> => {
    const columnsByCategoryIdSelector = IntegrationMetaSelectors.columnsByCategoryId(crossCategorySelector);
    return createSelector(columnsByCategoryIdSelector, (columnsByCategoryId: Record<string, Column[]>) => {
      return mapValues(columnsByCategoryId, (columns: Column[]) => keyBy(columns, COLUMN_NAMES.byName.attributeName));
    });
  };

  /**
   * getSuggestionValuesBySuggestionSearch
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<SuggestionSearch, GenericObject[]>>}
   * @memberof IntegrationMetaSelectors
   */
  public static getSuggestionValuesBySuggestionSearch: MemoizedSelector<CoreAppState, Map<SuggestionSearch, GenericObject[]>> =
    createSelector(
      IntegrationMetaSelectors.selectIntegrationMetaState,
      (state: IntegrationMetaState) => state.suggestionValuesBySuggestionSearch,
    );

  /**
   * getSuggestionLoadingBySuggestionSearch
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<SuggestionSearch, boolean>>}
   * @memberof IntegrationMetaSelectors
   */
  public static getSuggestionLoadingBySuggestionSearch: MemoizedSelector<CoreAppState, Map<SuggestionSearch, boolean>> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.suggestionLoadingBySuggestionSearch,
  );

  /**
   * isLoadingSuggestionSearch
   * @static
   * @param {SuggestionSearch} suggestionSearch
   * @returns {MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegrationMetaSelectors
   */
  public static isLoadingSuggestionSearch = (suggestionSearch: SuggestionSearch): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      IntegrationMetaSelectors.getSuggestionLoadingBySuggestionSearch,
      (suggestionLoadingBySuggestionSearch: Map<SuggestionSearch, boolean>) => suggestionLoadingBySuggestionSearch.get(suggestionSearch),
    );

  /**
   * getColumnsRequestCountByCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, number>>}
   * @memberof IntegrationMetaSelectors
   */
  public static getColumnsRequestCountByCategory: MemoizedSelector<CoreAppState, Record<string, number>> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.columnsRequestCountByCategory,
  );

  /**
   * loadingPrecomputedAggregations
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof IntegrationMetaSelectors
   */
  public static loadingPrecomputedAggregations: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.loadingPrecomputedAggregations,
  );

  /**
   * precomputedAggregationsByCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, PrecomputedAggregation[]>>}
   * @memberof IntegrationMetaSelectors
   */
  public static precomputedAggregationsByCategory: MemoizedSelector<CoreAppState, Record<string, PrecomputedAggregation[]>> =
    createSelector(
      IntegrationMetaSelectors.selectIntegrationMetaState,
      (state: IntegrationMetaState) => state.precomputedAggregationsByCategory,
    );

  /**
   * precomputedAggregations
   * @static
   * @type {MemoizedSelector<CoreAppState, PrecomputedAggregation[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static precomputedAggregations: MemoizedSelector<CoreAppState, PrecomputedAggregation[]> = createSelector(
    IntegrationMetaSelectors.precomputedAggregationsByCategory,
    IntegrationMetaSelectors.getActiveCategory,
    (precomputedAggregationsByCategory: Record<string, PrecomputedAggregation[]>, category: Category) => {
      return precomputedAggregationsByCategory[category.categoryId] ?? [];
    },
  );

  /**
   * aggregationColumnsByCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, AggregationColumn[]>>}
   * @memberof IntegrationMetaSelectors
   */
  public static aggregationColumnsByCategory: MemoizedSelector<CoreAppState, Record<string, AggregationColumn[]>> = createSelector(
    IntegrationMetaSelectors.selectIntegrationMetaState,
    (state: IntegrationMetaState) => state.aggregationColumnsByCategory,
  );

  /**
   * aggregationColumnsForActiveCategory
   * This is used in SNAPSHOT_PERIODICAL trend mode
   * @static
   * @type {MemoizedSelector<CoreAppState, AggregationColumn[]>}
   * @memberOf IntegrationMetaSelectors
   */
  public static aggregationColumnsForActiveCategory: MemoizedSelector<CoreAppState, AggregationColumn[]> = createSelector(
    IntegrationMetaSelectors.getActiveCategories,
    IntegrationMetaSelectors.aggregationColumnsByCategory,
    (categories: Category[], columnsByCategory: Record<string, AggregationColumn[]>) => {
      return columnsByCategory[categories?.[0]?.categoryId] ?? [];
    },
  );

  /**
   * aggregationColumnsForActiveCategoryByName
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, AggregationColumn>>}
   * @memberof IntegrationMetaSelectors
   */
  public static aggregationColumnsForActiveCategoryByName: MemoizedSelector<CoreAppState, Record<string, AggregationColumn>> =
    createSelector(IntegrationMetaSelectors.aggregationColumnsForActiveCategory, (columns: AggregationColumn[]) =>
      keyBy(columns, COLUMN_NAMES.byName.attributeName),
    );

  /**
   * getAggregationColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex | Record<string, AggregationColumn>>}
   * @memberof IntegrationMetaSelectors
   */
  public static getAggregationColumns: MemoizedSelector<CoreAppState, ColumnIndex | Record<string, AggregationColumn>> = createSelector(
    IntegrationMetaSelectors.getColumnsByName,
    IntegrationMetaSelectors.aggregationColumnsForActiveCategoryByName,
    (columnsByName: ColumnIndex, aggregationColumnsByName: Record<string, AggregationColumn>) => {
      return size(aggregationColumnsByName) > 0 ? aggregationColumnsByName : columnsByName;
    },
  );

  /**
   * getPrecomputedAggregationAttributeById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, AggregationAttributesResponse>>}
   * @memberof IntegrationMetaSelectors
   */
  public static getPrecomputedAggregationAttributeById: MemoizedSelector<CoreAppState, Record<string, AggregationAttributesResponse>> =
    createSelector(
      IntegrationMetaSelectors.selectIntegrationMetaState,
      (state: IntegrationMetaState) => state.precomputedAggregationAttributeById,
    );
}

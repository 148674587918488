/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { gql } from 'apollo-angular';

export const SYSTEM_LIMITS_USAGE_SUMMARY = gql`
  query ServiceLimitOrgUsage($orgId: UUID!) {
    serviceLimitOrgUsage(org_id: $orgId) {
      org_summaries {
        feature_tag
        count
        max
      }
    }
  }
`;

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';

/**
 * RouterSelectors
 *
 * @export
 * @class RouterSelectors
 */
export class RouterSelectors {
  /**
   * selectRouterFeatureState$
   *
   * @type {MemoizedSelector<CoreAppState, RouterReducerState<any>>}
   * @returns {RouterReducerState<any>}
   * @memberof RouterSelectors
   */
  public static selectRouterFeatureState$ = createFeatureSelector<CoreAppState, RouterReducerState<any>>('router');

  /**
   * currentUrl$
   *
   * @type {MemoizedSelector<CoreAppState, string>}
   * @returns {string}
   */
  public static currentUrl$ = getRouterSelectors(RouterSelectors.selectRouterFeatureState$).selectUrl;

  /**
   * @type {MemoizedSelector<CoreAppState, Params>}
   * @returns {Params}
   */
  public static currentQueryParam$ = getRouterSelectors(RouterSelectors.selectRouterFeatureState$).selectQueryParams;
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChronoUnit, PagedRequest, TrendSpan } from '@dpa/ui-common';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import {
  Column,
  CustomReport,
  CustomReportPreviewSearchResponse,
  ReportConfig,
  ReportConfigType,
  ReportDateRange,
  ReportTemplate,
  ReportType,
  TrendMode,
  WidgetDetailDefinition,
  WizardDialogMode,
} from '@ws1c/intelligence-models';
import { ReportCommonActions } from './report-common.actions';
import { initialReportCommonState, ReportCommonState } from './report-common.store';

const _reducer: ActionReducer<ReportCommonState, Action> = createReducer(
  initialReportCommonState,
  on(
    ReportCommonActions.createReportFromWidgetDetails,
    (
      state: ReportCommonState,
      { detail, columns, trend, returnPath }: ReturnType<typeof ReportCommonActions.createReportFromWidgetDetails>,
    ): ReportCommonState => ({
      ...state,
      reportWidgetDetail: new WidgetDetailDefinition({
        ...detail,
        detailTableAttrs: columns?.map((col) => col.attributeName),
      }),
      reportWidgetTrend: trend,
      reportWidgetReturnPath: returnPath,
      reportWizardMode: WizardDialogMode.CREATE_FROM_OTHER_SOURCE,
    }),
  ),
  on(
    ReportCommonActions.setReportWizardMode,
    (state: ReportCommonState, { wizardDialogMode }: ReturnType<typeof ReportCommonActions.setReportWizardMode>): ReportCommonState => ({
      ...state,
      reportWizardMode: wizardDialogMode,
    }),
  ),
  on(
    ReportCommonActions.resetReportWidget,
    (state: ReportCommonState): ReportCommonState => ({
      ...state,
      reportWidgetDetail: undefined,
      reportWidgetTrend: undefined,
      reportWidgetReturnPath: undefined,
      reportWizardMode: undefined,
    }),
  ),

  on(
    ReportCommonActions.updateFilterValidity,
    (state: ReportCommonState, { isValidFilter }: ReturnType<typeof ReportCommonActions.updateFilterValidity>): ReportCommonState => ({
      ...state,
      isValidFilter,
    }),
  ),
  on(
    ReportCommonActions.loadPreview,
    (state: ReportCommonState, { pagedRequest }: ReturnType<typeof ReportCommonActions.loadPreview>): ReportCommonState => ({
      ...state,
      loadingPreview: true,
      refreshedAt: undefined,
      previewPageRequest: pagedRequest || new PagedRequest(),
    }),
  ),
  on(
    ReportCommonActions.loadPreviewSuccess,
    (state: ReportCommonState, { searchResponse }: { searchResponse: CustomReportPreviewSearchResponse }): ReportCommonState => ({
      ...state,
      loadingPreview: false,
      refreshedAt: Date.now(),
      preview: new CustomReportPreviewSearchResponse({
        ...searchResponse,
        valid: true,
      }),
    }),
  ),
  on(
    ReportCommonActions.loadPreviewFailure,
    (state: ReportCommonState, { isTimedout }: { isTimedout: boolean }): ReportCommonState => ({
      ...state,
      loadingPreview: false,
      preview: new CustomReportPreviewSearchResponse({
        total: 0,
        valid: false,
        isTimedout,
        results: [],
      }),
    }),
  ),
  on(
    ReportCommonActions.setReportColumns,
    (state: ReportCommonState, { columns }: ReturnType<typeof ReportCommonActions.setReportColumns>): ReportCommonState => {
      let customReportDetails;
      const columnNames = columns.map((column: Column) => column?.attributeName);
      if (columns) {
        customReportDetails = new CustomReport({
          ...state.customReportDetails,
          columnNames,
        });
      } else {
        customReportDetails = state.customReportDetails;
      }
      return {
        ...state,
        customReportDetails,
      };
    },
  ),

  on(
    ReportCommonActions.updateReportSettings,
    (state: ReportCommonState, settings: ReturnType<typeof ReportCommonActions.updateReportSettings>): ReportCommonState => {
      const customReportDetails = new CustomReport({
        ...state.customReportDetails,
        ...settings,
      });
      if (customReportDetails.reportType === ReportType.SNAPSHOT) {
        customReportDetails.dateRange = undefined;
      }
      return {
        ...state,
        customReportDetails,
      };
    },
  ),
  on(
    ReportCommonActions.updateReportFilter,
    (state: ReportCommonState, { queryBuilder }: ReturnType<typeof ReportCommonActions.updateReportFilter>): ReportCommonState => {
      return {
        ...state,
        customReportDetails: new CustomReport({
          ...state.customReportDetails,
          filter: queryBuilder.getQueryString(),
          ruleGroup: queryBuilder.group,
          ruleNames: queryBuilder.group.ruleNames,
        }),
      };
    },
  ),
  on(
    ReportCommonActions.toggleUseFriendlyColumnLabelsForCSV,
    (
      state: ReportCommonState,
      { useFriendlyColumnLabelsForCSV }: ReturnType<typeof ReportCommonActions.toggleUseFriendlyColumnLabelsForCSV>,
    ): ReportCommonState => ({
      ...state,
      customReportDetails: new CustomReport({
        ...state.customReportDetails,
        reportConfig: new ReportConfig({
          ...state.customReportDetails.reportConfig,
          useFriendlyColumnLabelsForCSV,
        }),
      }),
    }),
  ),

  on(
    ReportCommonActions.resetCustomReport,
    (state: ReportCommonState): ReportCommonState => ({
      ...state,
      customReportDetails: undefined,
      selectedTemplate: undefined,
      preview: null,
      loadingPreview: false,
      refreshedAt: undefined,
    }),
  ),

  on(
    ReportCommonActions.setCustomReportDetails,
    (state: ReportCommonState, { report }: ReturnType<typeof ReportCommonActions.setCustomReportDetails>): ReportCommonState => ({
      ...state,
      customReportDetails: report,
      preview: null,
      refreshedAt: undefined,
    }),
  ),

  on(
    ReportCommonActions.selectReportTemplate,
    (state: ReportCommonState, { template }: ReturnType<typeof ReportCommonActions.selectReportTemplate>): ReportCommonState => {
      let reportTemplate;
      // Some templates don't have filter and filterCondition data
      if (template) {
        reportTemplate = new ReportTemplate(template);
        reportTemplate.filter = reportTemplate.filter || '';
        reportTemplate.filterCondition = reportTemplate.filterCondition || {};
        // Default Date range is 28 days
        if (!reportTemplate.dateRange && reportTemplate.reportType === TrendMode.HISTORICAL) {
          reportTemplate.dateRange = new ReportDateRange({
            trendSpan: new TrendSpan({
              duration: 28,
              unit: ChronoUnit[ChronoUnit.DAYS],
            }),
          });
        }
        if (!reportTemplate.isPredefinedReportTemplate) {
          reportTemplate.reportConfig = new ReportConfig({
            ...reportTemplate.reportConfig,
            reportConfigType: ReportConfigType.GENERIC_REPORT_CONFIG,
          });
        }
      }
      const defaultCustomReportDetails = reportTemplate
        ? new CustomReport({
            ...reportTemplate,
            id: null,
            name: state.customReportDetails?.name || reportTemplate.name,
            description: state.customReportDetails?.description || reportTemplate.description,
            sourceTemplateId: reportTemplate.id,
          })
        : null;
      return {
        ...state,
        selectedCategoryId: reportTemplate ? reportTemplate.categoryId : '',
        selectedTemplate: reportTemplate,
        customReportDetails: defaultCustomReportDetails,
        defaultCustomReportDetails,
        preview: null,
        refreshedAt: undefined,
      };
    },
  ),

  on(
    ReportCommonActions.initCustomReportSidePanel,
    (state: ReportCommonState, { columns }: ReturnType<typeof ReportCommonActions.initCustomReportSidePanel>): ReportCommonState => {
      let customReportDetails;
      const columnNames = columns.map((column: Column) => column.attributeName);
      if (columns) {
        customReportDetails = new CustomReport({
          ...state.defaultCustomReportDetails,
          columnNames,
        });
      } else {
        customReportDetails = state.defaultCustomReportDetails;
      }
      return {
        ...state,
        customReportDetails,
        preview: null,
        refreshedAt: undefined,
      };
    },
  ),
);

/**
 * Creates and returns reportCommonState
 * @export
 * @param {ReportCommonState} state - Holds state object of type ReportCommonState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {ReportCommonState}
 */
export function reportCommonState(state: ReportCommonState, action: Action): ReportCommonState {
  return _reducer(state, action);
}

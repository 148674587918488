/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { SortOn } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models';

/**
 * DeemIncidentAuditLogConfig
 * @export
 * @class DeemIncidentAuditLogConfig
 */
export class DeemIncidentAuditLogConfig {
  public static readonly requestFields = [
    COLUMN_NAMES.byName.search_fields_difference,
    COLUMN_NAMES.byName.actor_id,
    COLUMN_NAMES.byName.actor_display_name,
    COLUMN_NAMES.byName.created_at,
  ];

  public static readonly sortOns = [
    new SortOn({
      by: COLUMN_NAMES.byName.created_at,
      reverse: false,
    }),
  ];

  /**
   * getRequestFilter
   * @static
   * @param {string} incidentId
   * @returns {string}
   * @memberof DeemIncidentAuditLogConfig
   */
  public static getRequestFilter(incidentId: string): string {
    return `object_type IN ( 'INCIDENT' )  AND  object_id IN ( '${incidentId}' )`;
  }
}

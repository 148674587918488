/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, GenericResponse, HttpService } from '@ws1c/intelligence-common';

/**
 * RbacService
 *
 * @export
 * @class RbacService
 */
@Injectable({
  providedIn: 'root',
})
export class RbacService {
  /**
   * Creates an instance of RbacService.
   * @param {HttpService} http
   * @memberof RbacService
   */
  constructor(private http: HttpService) {}

  /**
   * requestAccess
   * @returns {Observable<number>}
   * @memberof RbacService
   */
  public requestAccess(): Observable<number> {
    return this.http.post(Endpoint.REQUEST_ACCESS, {}).pipe(
      map((response: GenericResponse<number>) => response.data),
      catchError(requestErrorHandler),
    );
  }
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Bookmark, LabelValue } from '@ws1c/intelligence-models';

/**
 * State for Bookmarks
 *
 * @export
 * @interface
 */
export interface BookmarksState {
  bookmarks: Bookmark[];
  isBookmarkFilterEnabled: boolean;
  selectedBookmarkFilterOption: LabelValue;
}

/**
 * Defines initial state for Bookmarks
 *
 * @export
 * @type {BookmarksState}
 */
export const initialBookmarksState: BookmarksState = {
  bookmarks: [],
  isBookmarkFilterEnabled: false,
  selectedBookmarkFilterOption: null,
};

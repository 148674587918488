/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BarHorizontalComponent } from '@dpa/ui-common';

import { BaseChartComponent } from '@ws1c/dashboard-common/chart/base-chart.component';
import { AggregationWidgetChartType, ChartDrilldownEvent, NgxChart, NgxSingleData } from '@ws1c/intelligence-models';

/**
 * HorizontalChartComponent
 *
 * @export
 * @class HorizontalChartComponent
 * @extends {BaseChartComponent}
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-horizontal-chart',
  templateUrl: 'horizontal-chart.component.html',
  styleUrls: ['horizontal-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalChartComponent extends BaseChartComponent implements AfterViewInit, OnChanges {
  @Input() public ngxChart: NgxChart;
  @Input() public showDetailsLink?: boolean = false;
  @Input() public isPercentage?: boolean = false;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public showXAxis?: boolean = true;
  @Input() public showYAxis?: boolean = true;
  @Input() public showXAxisLabel?: boolean = true;
  @Input() public showYAxisLabel?: boolean = true;
  @Input() public showDataLabel?: boolean = true;
  @Input() public xAxisLabel?: string;
  @Input() public yAxisLabel?: string;
  @Input() public rotateXAxisTicks?: boolean = false;
  @Input() public maxXAxisTickLength: number = 16;
  @Input() public yScaleMax: number;
  @Input() public chartType: AggregationWidgetChartType;

  @Output() public onViewDetails = new EventEmitter<ChartDrilldownEvent>();

  @ViewChild('chart') public chart: BarHorizontalComponent;

  public data: NgxSingleData[];

  public readonly AggregationWidgetChartType = AggregationWidgetChartType;

  /**
   * ngAfterViewInit
   *
   * @memberof HorizontalChartComponent
   */
  public ngAfterViewInit() {
    // Workaround for data label overflow must manually trigger update as per:
    // https://github.com/swimlane/ngx-charts/issues/474#issuecomment-333860410
    this.data = [...this.ngxChart.chartData];
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof HorizontalChartComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    // make sure upon chartData changes
    if (changes.ngxChart && changes.ngxChart.previousValue && changes.ngxChart.previousValue.chartData) {
      this.data = [...this.ngxChart.chartData];
    }
  }

  /**
   * onSelect
   * @param {any} ngxSelectEvent
   * @memberof HorizontalChartComponent
   */
  public onSelect(ngxSelectEvent: any) {
    if (this.showDetailsLink) {
      const drilldownEvent: ChartDrilldownEvent = this.ngxChart.drilldownEventBuilder.getEvent(ngxSelectEvent);
      this.onDrilldown(drilldownEvent);
    }
  }

  /**
   * onDrilldown
   * @param {ChartDrilldownEvent} drilldownEvent
   * @memberof HorizontalChartComponent
   */
  public onDrilldown(drilldownEvent: ChartDrilldownEvent) {
    this.onViewDetails.emit(drilldownEvent);
  }

  /**
   * onResize
   *
   * @memberof LineChartComponent
   */
  public onResize() {
    if (!this.chart) {
      return;
    }
    this.chart.update();
  }
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import {
  AggregationFunction,
  COLUMN_NAMES,
  CounterDefinition,
  Entity,
  Insight,
  QueryBuilder,
  StandardWidgetSubtype,
  TrendDefinition,
  TrendDefinitionIndex,
  TrendMode,
} from '@ws1c/intelligence-models';

/**
 * InsightDashboardConfig
 * @export
 * @class InsightDashboardConfig
 */
export class InsightDashboardConfig {
  /**
   * getInsightCountTrendDefinitionIndex
   * @static
   * @param {Insight} insight
   * @returns {TrendDefinitionIndex}
   * @memberof InsightDashboardConfig
   */
  public static getInsightCountTrendDefinitionIndex(insight: Insight): TrendDefinitionIndex {
    const rules = insight.getFilterRules();
    const filter = QueryBuilder.queryStringFromFilterRules(rules);
    return {
      [StandardWidgetSubtype.INSIGHT_COUNTS]: new TrendDefinition({
        trendMode: TrendMode[TrendMode.HISTORICAL],
        counterDefinitions: [
          new CounterDefinition({
            aggregateAttribute: insight.observationValue.key,
            aggregationFunction: AggregationFunction.MAX,
          }),
          new CounterDefinition({
            aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_insights_lower_bound,
            aggregationFunction: AggregationFunction.MAX,
          }),
          new CounterDefinition({
            aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_insights_upper_bound,
            aggregationFunction: AggregationFunction.MAX,
          }),
        ],
        dateRange: insight.getDashboardTrendDateRange(),
        entitiesByIntegration: {
          internal: [Entity.INSIGHTS],
        },
        filter,
        cardinality: 30,
        accumulate: false,
      }),
    };
  }
}

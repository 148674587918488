/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, EndpointV2, HttpService } from '@ws1c/intelligence-common';
import { ReportTrackingSearchRequest, ReportTrackingSearchResponse } from '@ws1c/intelligence-models';

/**
 * Provide access to Report Activities/Audit Log Information
 * This is READ ONLY information
 * @link: ReportMetaController.java
 * @link ReportTrackingController.java
 * @link ReportTrackingController#getReportTrackings
 * @link ReportTrackingController#getReportTrackingsForReport
 * @export
 * @class ReportTrackingService
 */
@Injectable({
  providedIn: 'root',
})
export class ReportTrackingService {
  /**
   * Creates an instance of ReportTrackingService.
   * @param {HttpService} http
   * @memberof ReportTrackingService
   */
  constructor(private http: HttpService) {}

  /**
   * getReportTrackingsForReport
   * @param {ReportTrackingSearchRequest} searchRequest
   * @returns {Observable<ReportTrackingSearchResponse>}
   * @memberof ReportTrackingService
   */
  public getReportTrackingsForReport(searchRequest: ReportTrackingSearchRequest): Observable<ReportTrackingSearchResponse | any[]> {
    return this.http.post(Endpoint.DOWNLOAD_DETAILS_FOR_REPORT(searchRequest.reportId), searchRequest).pipe(
      map((response: any) => deserialize(ReportTrackingSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getReportTrackingsForSchedule
   * @param {ReportTrackingSearchRequest} searchRequest
   * @returns {Observable<ReportTrackingSearchResponse>}
   * @memberof ReportTrackingService
   */
  public getReportTrackingsForSchedule(searchRequest: ReportTrackingSearchRequest): Observable<ReportTrackingSearchResponse | any[]> {
    return this.http.post(Endpoint.DOWNLOAD_DETAILS_FOR_SCHEDULE(searchRequest.scheduleId), searchRequest).pipe(
      map((response: any) => deserialize(ReportTrackingSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteReportDownload
   * @param {string} id
   * @returns {Observable<boolean>}
   * @memberof ReportTrackingService
   */
  public deleteReportDownload(id: string): Observable<boolean> {
    return this.http.delete(Endpoint.REPORTS_TRACKING_ID(id)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * bulkDeleteReportDownloads
   * @param {string[]} identifiers
   * @returns {Observable<boolean>}
   * @memberof ReportTrackingService
   */
  public bulkDeleteReportDownloads(identifiers: string[]): Observable<boolean> {
    return this.http
      .delete(Endpoint.REPORTS_TRACKING, {
        body: { identifiers },
      })
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * generateReportDownload
   * @param {string} id
   * @returns {Observable<boolean>}
   * @memberof ReportTrackingService
   */
  public generateReportDownload(id: string): Observable<boolean> {
    return this.http.post(EndpointV2.REPORT_ID_GENERATE(id), {}).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { gql } from 'apollo-angular';

export const SYSTEM_LIMITS_SEARCH = gql`
  query SystemLimitsSearch($pagedSearchRequestInput: PagedSearchRequestInput!, $summaryTag: ServiceLimitSummaryFeatureTag!) {
    serviceLimitSummariesPagedSearch(input: { paged_search_request_input: $pagedSearchRequestInput, summary_tag: $summaryTag }) {
      paged_response {
        offset
        page_size
        total_count
      }
      account_summaries {
        account {
          display_name
          id
          active
          email
          roles {
            name
            label
          }
        }
        count
        max
        override_type
      }
    }
  }
`;

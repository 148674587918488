/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import {
  COLUMN_NAMES,
  Connector,
  ConnectorConfigStatus,
  GenericSearchRequest,
  ManagedConnector,
  SearchTerm,
} from '@ws1c/intelligence-models';
import { ConnectorCommonState } from './connector-common.state';

/**
 * getConnectorsSearchRequest
 *
 * @param {ConnectorCommonState} state
 * @returns {GenericSearchRequest}
 */
export function getConnectorsSearchRequest(state: ConnectorCommonState): GenericSearchRequest {
  const searchTerms: SearchTerm[] = [];

  if (state.connectorsSearchQuery) {
    searchTerms.push(
      new SearchTerm({
        value: state.connectorsSearchQuery,
        fields: [COLUMN_NAMES.byName.name, COLUMN_NAMES.byName.description],
      }),
    );
  }

  if (state.connectorsQuickFilter) {
    searchTerms.push(
      new SearchTerm({
        value: state.connectorsQuickFilter,
        fields: [COLUMN_NAMES.byName.status],
      }),
    );
  }

  return new GenericSearchRequest({
    ...state.connectorsSearchRequest,
    searchTerms,
  });
}

/**
 * mergeConnectorsByName
 * @param {ManagedConnector[]} managedConnectors
 * @param {Connector[]} importedConnectors
 * @param {string[]} connectorNames
 * @returns {Connector[]}
 */
export function mergeConnectorsByName(
  managedConnectors: ManagedConnector[],
  importedConnectors: Connector[],
  connectorNames: string[],
): Connector[] {
  const filteredImportedConnectors = importedConnectors?.filter(
    (connector: Connector) => connectorNames.includes(connector.name) && connector.managed,
  );
  const filteredManagedConnectors = managedConnectors?.filter((connector: ManagedConnector) => connectorNames.includes(connector.name));
  const map = new Map();
  filteredManagedConnectors?.forEach((managedConnector: ManagedConnector) =>
    map.set(managedConnector.name, new Connector({ ...managedConnector, status: ConnectorConfigStatus.NOT_AUTHORIZED })),
  );
  filteredImportedConnectors?.forEach((importedConnector: Connector) =>
    map.set(
      importedConnector.name,
      new Connector({ ...importedConnector, status: importedConnector.status ?? ConnectorConfigStatus.NOT_AUTHORIZED }),
    ),
  );
  return Array.from(map.values());
}

export const helpers = {
  getConnectorsSearchRequest,
  mergeConnectorsByName,
};

/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { StandardWidgetSubtype } from '@ws1c/intelligence-models';
import { ZoomNetworkTab } from './zoom-network-tab.enum';

/**
 * DeviceProfileZoomConfig
 * @export
 * @class DeviceProfileZoomConfig
 */
export class DeviceProfileZoomConfig {
  public static readonly zoomNetworkTabToInputWidgetSubtypeMap: Record<ZoomNetworkTab, StandardWidgetSubtype> = {
    [ZoomNetworkTab.BITRATE]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_AVG_BITRATE_INPUT_CHART,
    [ZoomNetworkTab.JITTER]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_AVG_JITTER_INPUT_CHART,
    [ZoomNetworkTab.LATENCY]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_AVG_LATENCY_INPUT_CHART,
    [ZoomNetworkTab.AVG_PACKET_LOSS]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_AVG_PACKET_LOSS_INPUT_CHART,
    [ZoomNetworkTab.MAX_PACKET_LOSS]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_MAX_PACKET_LOSS_INPUT_CHART,
  };

  public static readonly zoomNetworkTabToOutputWidgetSubtypeMap: Record<ZoomNetworkTab, StandardWidgetSubtype> = {
    [ZoomNetworkTab.BITRATE]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_AVG_BITRATE_OUTPUT_CHART,
    [ZoomNetworkTab.JITTER]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_AVG_JITTER_OUTPUT_CHART,
    [ZoomNetworkTab.LATENCY]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_AVG_LATENCY_OUTPUT_CHART,
    [ZoomNetworkTab.AVG_PACKET_LOSS]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_AVG_PACKET_LOSS_OUTPUT_CHART,
    [ZoomNetworkTab.MAX_PACKET_LOSS]: StandardWidgetSubtype.DEVICES_PROFILE_ZOOM_MAX_PACKET_LOSS_OUTPUT_CHART,
  };

  public static readonly networkTabYAxisLabelMap: Record<ZoomNetworkTab, string> = {
    [ZoomNetworkTab.BITRATE]: 'STANDARD_DASHBOARD.DEVICES_DETAILS.AVG_BITRATE',
    [ZoomNetworkTab.JITTER]: 'STANDARD_DASHBOARD.DEVICES_DETAILS.AVG_JITTER',
    [ZoomNetworkTab.LATENCY]: 'STANDARD_DASHBOARD.DEVICES_DETAILS.AVG_LATENCY',
    [ZoomNetworkTab.AVG_PACKET_LOSS]: 'STANDARD_DASHBOARD.DEVICES_DETAILS.AVG_PACKET_LOSS',
    [ZoomNetworkTab.MAX_PACKET_LOSS]: 'STANDARD_DASHBOARD.DEVICES_DETAILS.MAX_PACKET_LOSS',
  };
}

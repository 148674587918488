/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSyntheticURLMonitoringMethodType
 * @enum {string}
 * @export
 */
export enum DeemSyntheticURLMonitoringMethodType {
  GET = 'GET',
  HEAD = 'HEAD',
}

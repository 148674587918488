/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { Bookmark, LabelValue } from '@ws1c/intelligence-models';
import { BookmarksActions } from './bookmarks.actions';
import { BookmarksState, initialBookmarksState } from './bookmarks.store';

/**
 * _reducer
 * @type {ActionReducer<BookmarksState, Action>}
 */
const _reducer: ActionReducer<BookmarksState, Action> = createReducer(
  initialBookmarksState,

  on(BookmarksActions.addBookmarkSuccess, (state: BookmarksState, props: { bookmark: Bookmark }) => {
    const bookmarks: Bookmark[] = [...state.bookmarks, props.bookmark];
    return Object.assign({}, state, {
      ...state,
      bookmarks,
    } as BookmarksState);
  }),

  on(BookmarksActions.getBookmarksSuccess, (state: BookmarksState, props: { bookmarks: Bookmark[] }) => {
    const bookmarks: Bookmark[] = [...props.bookmarks];
    return Object.assign({}, state, {
      ...state,
      bookmarks,
    } as BookmarksState);
  }),

  on(BookmarksActions.removeBookmarkSuccess, (state: BookmarksState, props: { resourceId: string }) => {
    const bookmarks: Bookmark[] = state.bookmarks.filter((bookmark: Bookmark) => bookmark.resourceId !== props.resourceId);
    return Object.assign({}, state, {
      ...state,
      bookmarks,
    } as BookmarksState);
  }),

  on(BookmarksActions.removeBookmarksFromCache, (state: BookmarksState, props: { resourceIds: string[] }) => {
    const bookmarks: Bookmark[] = state.bookmarks.filter((bookmark: Bookmark) => !props.resourceIds.includes(bookmark.resourceId));
    return Object.assign({}, state, {
      ...state,
      bookmarks,
    } as BookmarksState);
  }),

  on(BookmarksActions.setSelectedBookmarkFilterOption, (state: BookmarksState, props: { selectedBookmarkFilterOption: LabelValue }) => {
    const selectedBookmarkFilterOption: LabelValue = props.selectedBookmarkFilterOption;
    return Object.assign({}, state, {
      ...state,
      selectedBookmarkFilterOption,
    } as BookmarksState);
  }),

  on(BookmarksActions.toggleBookmarkFilter, (state: BookmarksState, props: { isBookmarkFilterEnabled: boolean }) => {
    return Object.assign({}, state, {
      ...state,
      isBookmarkFilterEnabled: props.isBookmarkFilterEnabled,
    } as BookmarksState);
  }),

  on(BookmarksActions.updateBookmark, (state: BookmarksState, props: { bookmark: Bookmark }) => {
    const bookmarks: Bookmark[] = [...state.bookmarks];
    const index: number = bookmarks.findIndex((bookmark: Bookmark) => bookmark.resourceId === props.bookmark.resourceId);
    bookmarks[index] = new Bookmark({
      ...props.bookmark,
    });
    return Object.assign({}, state, {
      ...state,
      bookmarks,
    } as BookmarksState);
  }),
);

/**
 * Creates and returns BookmarksState
 * @export
 * @param {BookmarksState} state - Holds state object of type BookmarksState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {BookmarksState}
 */
export function bookmarksState(state: BookmarksState, action: Action): BookmarksState {
  return _reducer(state, action);
}

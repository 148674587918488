/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

import {
  COLUMN_NAMES,
  Device,
  Insight,
  InsightsConfig,
  InsightType,
  NgxTrendResultFlattener,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
} from '@ws1c/intelligence-models';
import { InsightImpactConfig } from './insight-impact.config';

/**
 * InsightImpactedDevicesConfig
 * @export
 * @class InsightImpactedDevicesConfig
 */
export class InsightImpactedDevicesConfig {
  public static readonly impactedDevicesCountSubtypeMap: Record<InsightType, StandardWidgetSubtype> = {
    [InsightType.APP_CRASHES]: StandardWidgetSubtype.INSIGHT_IMPACT_APPS_IMPACTED_DEVICES_COUNT,
    [InsightType.APP_HANGS]: StandardWidgetSubtype.INSIGHT_IMPACT_APPS_IMPACTED_DEVICES_COUNT,
    [InsightType.APP_INSTALL_FAILED]: StandardWidgetSubtype.INSIGHT_IMPACT_APP_INSTALL_FAILED_IMPACTED_DEVICES_COUNT,
    [InsightType.APP_INSTALL_PENDING]: StandardWidgetSubtype.INSIGHT_IMPACT_APP_INSTALL_PENDING_IMPACTED_DEVICES_COUNT,
    [InsightType.APP_USAGE]: StandardWidgetSubtype.INSIGHT_IMPACT_APPS_IMPACTED_DEVICES_COUNT,
    [InsightType.APTELIGENT_CRASH_ANDROID]: StandardWidgetSubtype.INSIGHT_IMPACT_APTELIGENT_ANDROID_IMPACTED_DEVICES_COUNT,
    [InsightType.APTELIGENT_CRASH_IOS]: StandardWidgetSubtype.INSIGHT_IMPACT_APTELIGENT_IOS_IMPACTED_DEVICES_COUNT,
    [InsightType.APTELIGENT_NET_ERROR]: StandardWidgetSubtype.INSIGHT_IMPACT_APTELIGENT_NET_ERROR_IMPACTED_DEVICES_COUNT,
    [InsightType.OS_CRASHES]: StandardWidgetSubtype.INSIGHT_IMPACT_OS_CRASHES_IMPACTED_DEVICES_COUNT,
    [InsightType.CPU_UTILIZATION]: StandardWidgetSubtype.INSIGHT_IMPACT_CPU_IMPACTED_DEVICES_COUNT,
    [InsightType.BOOT_DURATION]: StandardWidgetSubtype.INSIGHT_IMPACT_DURATION_IMPACTED_DEVICES_COUNT,
    [InsightType.FAILED_SSO_LOGIN]: StandardWidgetSubtype.INSIGHT_IMPACT_FAILED_SSO_LOGIN_IMPACTED_DEVICES_COUNT,
    [InsightType.HORIZON_SESSION_LOGON_DURATION]: StandardWidgetSubtype.INSIGHT_IMPACT_HORIZON_LOGON_IMPACTED_DEVICES_COUNT,
    [InsightType.SHUTDOWN_DURATION]: StandardWidgetSubtype.INSIGHT_IMPACT_DURATION_IMPACTED_DEVICES_COUNT,
  };

  public static readonly totalDevicesCountSubtypeMap: Record<InsightType, StandardWidgetSubtype> = {
    [InsightType.APP_CRASHES]: StandardWidgetSubtype.INSIGHT_IMPACT_APPS_TOTAL_DEVICES_COUNT,
    [InsightType.APP_HANGS]: StandardWidgetSubtype.INSIGHT_IMPACT_APPS_TOTAL_DEVICES_COUNT,
    [InsightType.APP_INSTALL_FAILED]: StandardWidgetSubtype.INSIGHT_IMPACT_APP_INSTALL_FAILED_TOTAL_DEVICES_COUNT_TREND,
    [InsightType.APP_INSTALL_PENDING]: StandardWidgetSubtype.INSIGHT_IMPACT_APP_INSTALL_PENDING_TOTAL_DEVICES_COUNT_TREND,
    [InsightType.APP_USAGE]: StandardWidgetSubtype.INSIGHT_IMPACT_APPS_TOTAL_DEVICES_COUNT,
    [InsightType.APTELIGENT_CRASH_ANDROID]: StandardWidgetSubtype.INSIGHT_IMPACT_APTELIGENT_ANDROID_TOTAL_DEVICES_COUNT,
    [InsightType.APTELIGENT_CRASH_IOS]: StandardWidgetSubtype.INSIGHT_IMPACT_APTELIGENT_IOS_TOTAL_DEVICES_COUNT,
    [InsightType.APTELIGENT_NET_ERROR]: StandardWidgetSubtype.INSIGHT_IMPACT_APTELIGENT_NET_ERROR_TOTAL_DEVICES_COUNT,
    [InsightType.OS_CRASHES]: StandardWidgetSubtype.INSIGHT_IMPACT_OS_CRASHES_TOTAL_DEVICES_COUNT,
    [InsightType.CPU_UTILIZATION]: StandardWidgetSubtype.INSIGHT_IMPACT_CPU_TOTAL_DEVICES_COUNT,
    [InsightType.BOOT_DURATION]: StandardWidgetSubtype.INSIGHT_IMPACT_DURATION_TOTAL_DEVICES_COUNT,
    [InsightType.FAILED_SSO_LOGIN]: StandardWidgetSubtype.INSIGHT_IMPACT_FAILED_SSO_LOGIN_TOTAL_DEVICES_COUNT,
    [InsightType.HORIZON_SESSION_LOGON_DURATION]: StandardWidgetSubtype.INSIGHT_IMPACT_HORIZON_LOGON_TOTAL_DEVICES_COUNT,
    [InsightType.SHUTDOWN_DURATION]: StandardWidgetSubtype.INSIGHT_IMPACT_DURATION_TOTAL_DEVICES_COUNT,
  };

  public static readonly impactedDevicesTableSubtypeMap: Record<InsightType, StandardWidgetSubtype> = {
    [InsightType.APP_CRASHES]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_APPS_TABLE,
    [InsightType.APP_HANGS]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_APPS_TABLE,
    [InsightType.APP_INSTALL_FAILED]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_APP_INSTALL_FAILED_TABLE,
    [InsightType.APP_INSTALL_PENDING]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_APP_INSTALL_PENDING_TABLE,
    [InsightType.APP_USAGE]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_APPS_TABLE,
    [InsightType.APTELIGENT_CRASH_ANDROID]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_APTELIGENT_ANDROID_TABLE,
    [InsightType.APTELIGENT_CRASH_IOS]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_APTELIGENT_IOS_TABLE,
    [InsightType.APTELIGENT_NET_ERROR]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_APTELIGENT_NET_ERROR_TABLE,
    [InsightType.OS_CRASHES]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_OS_CRASHES_TABLE,
    [InsightType.CPU_UTILIZATION]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_CPU_UTILIZATION_TABLE,
    [InsightType.BOOT_DURATION]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_DURATION_TABLE,
    [InsightType.FAILED_SSO_LOGIN]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_FAILED_SSO_LOGIN_TABLE,
    [InsightType.HORIZON_SESSION_LOGON_DURATION]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_HORIZON_SESSION_TABLE,
    [InsightType.SHUTDOWN_DURATION]: StandardWidgetSubtype.INSIGHT_IMPACTED_DEVICES_DURATION_TABLE,
  };

  /**
   * getInsightImpactParameters
   * @static
   * @param {Insight} insight
   * @returns {Record<string, any>}
   * @memberof InsightImpactedDevicesConfig
   */
  public static getInsightImpactParameters(insight: Insight): Record<string, any> {
    const platform = insight.platform;
    const eventName = InsightsConfig.insightsPropertiesMap[insight.insightType].eventName;
    switch (insight.insightType) {
      case InsightType.APTELIGENT_CRASH_ANDROID:
      case InsightType.APTELIGENT_CRASH_IOS:
      case InsightType.APTELIGENT_NET_ERROR:
      case InsightType.APP_INSTALL_FAILED:
      case InsightType.APP_INSTALL_PENDING:
        return {
          [COLUMN_NAMES.byName._application_name]: insight.observationName,
        };
      case InsightType.APP_CRASHES:
      case InsightType.APP_HANGS:
      case InsightType.APP_USAGE:
        return {
          [COLUMN_NAMES.byName._device_platform]: platform,
          [COLUMN_NAMES.byName.event_name]: eventName,
          [COLUMN_NAMES.byName._application_name]: insight.observationName,
        };
      case InsightType.OS_CRASHES:
        return {
          [COLUMN_NAMES.byName._device_platform]: platform,
          [COLUMN_NAMES.byName.event_name]: eventName,
        };
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
        return {
          [COLUMN_NAMES.byName.duration_millis]: insight.observationValue.value,
          [COLUMN_NAMES.byName._device_platform]: platform,
          [COLUMN_NAMES.byName.event_name]: eventName,
        };
      case InsightType.CPU_UTILIZATION:
        return {
          [COLUMN_NAMES.byName._device_platform]: platform,
          [COLUMN_NAMES.byName.event_name]: eventName,
          [COLUMN_NAMES.byName.processor_total_processor_totaltime]: insight.observationValue.value,
        };
      case InsightType.HORIZON_SESSION_LOGON_DURATION:
        return {
          [COLUMN_NAMES.byName.logon_time_in_second]: insight.observationValue.value,
        };
    }
  }

  /**
   * getInsightImpactDevicesCountRequest
   * @static
   * @param {Insight} insight
   * @returns {StandardDashboardRequest}
   * @memberof InsightImpactedDevicesConfig
   */
  public static getInsightImpactDevicesCountRequest(insight: Insight): StandardDashboardRequest {
    const trendDateRange = InsightImpactConfig.getTrendDateRange(insight);
    const requestParams = this.getInsightImpactParameters(insight);
    switch (insight.insightType) {
      case InsightType.APP_INSTALL_FAILED:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_APP_INSTALL_FAILED_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APP_INSTALL_PENDING:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_APP_INSTALL_PENDING_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APP_CRASHES:
      case InsightType.APP_HANGS:
      case InsightType.APP_USAGE:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_APPS_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APTELIGENT_CRASH_ANDROID:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_APTELIGENT_ANDROID_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APTELIGENT_CRASH_IOS:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_APTELIGENT_IOS_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APTELIGENT_NET_ERROR:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_APTELIGENT_NET_ERROR_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.FAILED_SSO_LOGIN:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_FAILED_SSO_LOGIN_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
        );
      case InsightType.HORIZON_SESSION_LOGON_DURATION:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_HORIZON_LOGON_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.OS_CRASHES:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_OS_CRASHES_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_DURATION_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.CPU_UTILIZATION:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACT_CPU_DEVICES_COUNT,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
    }
  }

  /**
   * getInsightImpactedDevicesTableRequest
   * @static
   * @param {Insight} insight
   * @returns {StandardDashboardRequest}
   * @memberof InsightImpactedDevicesConfig
   */
  public static getInsightImpactedDevicesTableRequest(insight: Insight): StandardDashboardRequest {
    const trendDateRange = InsightImpactConfig.getTrendDateRange(insight);
    const requestParams = this.getInsightImpactParameters(insight);
    switch (insight.insightType) {
      case InsightType.APTELIGENT_CRASH_ANDROID:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_APTELIGENT_ANDROID,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APTELIGENT_CRASH_IOS:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_APTELIGENT_IOS,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APTELIGENT_NET_ERROR:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_APTELIGENT_NET_ERROR,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APP_INSTALL_FAILED:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_APP_INSTALL_FAILED,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APP_INSTALL_PENDING:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_APP_INSTALL_PENDING,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.APP_CRASHES:
      case InsightType.APP_HANGS:
      case InsightType.APP_USAGE:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_APPS,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.FAILED_SSO_LOGIN:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_FAILED_SSO_LOGIN,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.OS_CRASHES:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_OS_CRASHES,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_DURATION,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.CPU_UTILIZATION:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_CPU_UTILIZATION,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
      case InsightType.HORIZON_SESSION_LOGON_DURATION:
        return new StandardDashboardRequest(
          StandardDashboardType.INSIGHT_IMPACTED_DEVICES_HORIZON_SESSION,
          new RuleGroup(),
          trendDateRange,
          requestParams,
        );
    }
  }

  /**
   * getImpactedDevice
   * @static
   * @param {InsightType} insightType
   * @param {GenericObject} dataItem
   * @returns {Device}
   * @memberof InsightImpactedDevicesConfig
   */
  public static getImpactedDevice(insightType: InsightType, dataItem: GenericObject): Device {
    switch (insightType) {
      case InsightType.APTELIGENT_CRASH_ANDROID:
        return new Device({
          name: dataItem[COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_device_friendly_name],
          integration: dataItem[COLUMN_NAMES.byName.integration],
          id: dataItem[COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_device_uuid],
        });
      case InsightType.APTELIGENT_CRASH_IOS:
        return new Device({
          name: dataItem[COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_device_friendly_name],
          integration: dataItem[COLUMN_NAMES.byName.integration],
          id: dataItem[COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_device_uuid],
        });
      case InsightType.APTELIGENT_NET_ERROR:
        return new Device({
          name: dataItem[COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_device_friendly_name],
          integration: dataItem[COLUMN_NAMES.byName.integration],
          id: dataItem[COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_device_uuid],
        });
      case InsightType.APP_INSTALL_FAILED:
      case InsightType.APP_INSTALL_PENDING:
        return new Device({
          name: dataItem[COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_device_friendly_name],
          integration: dataItem[COLUMN_NAMES.byName.integration],
          id: dataItem[COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_device_guid],
        });
      case InsightType.APP_CRASHES:
      case InsightType.APP_HANGS:
      case InsightType.APP_USAGE:
        return new Device({
          name: dataItem[COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_name],
          integration: dataItem[COLUMN_NAMES.byName.integration],
          id: dataItem[COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_airwatch_device_guid],
        });
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
      case InsightType.OS_CRASHES:
        return new Device({
          name: dataItem[COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_device_name],
          integration: dataItem[COLUMN_NAMES.byName.integration],
          id: dataItem[COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_airwatch_device_guid],
        });
      case InsightType.CPU_UTILIZATION:
        return new Device({
          name: dataItem[COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_device_name],
          integration: dataItem[COLUMN_NAMES.byName.integration],
          id: dataItem[COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_airwatch_device_guid],
        });
    }
  }

  /**
   * getCellTemplateColumn
   * @static
   * @param {InsightType} insightType
   * @returns {string}
   * @memberof InsightImpactedDevicesConfig
   */
  public static getCellTemplateColumn(insightType: InsightType): string {
    switch (insightType) {
      case InsightType.APTELIGENT_CRASH_ANDROID:
        return COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_device_uuid;
      case InsightType.APTELIGENT_CRASH_IOS:
        return COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_device_friendly_name;
      case InsightType.APTELIGENT_NET_ERROR:
        return COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_device_uuid;
      case InsightType.APP_INSTALL_FAILED:
      case InsightType.APP_INSTALL_PENDING:
        return COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_device_guid;
      case InsightType.APP_CRASHES:
      case InsightType.APP_HANGS:
      case InsightType.APP_USAGE:
        return COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_name;
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
      case InsightType.OS_CRASHES:
        return COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_device_name;
      case InsightType.CPU_UTILIZATION:
        return COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_device_name;
    }
  }

  /**
   * getVisibleColumns
   * @static
   * @param {InsightType} insightType
   * @returns {string[]}
   * @memberof InsightImpactedDevicesConfig
   */
  public static getVisibleColumns(insightType: InsightType): string[] {
    switch (insightType) {
      case InsightType.APTELIGENT_CRASH_ANDROID:
        return [COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_device_uuid];
      case InsightType.APTELIGENT_CRASH_IOS:
        return [
          COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_device_friendly_name,
          COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_device_uuid,
        ];
      case InsightType.APTELIGENT_NET_ERROR:
        return [COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_device_uuid];
      case InsightType.APP_CRASHES:
      case InsightType.APP_HANGS:
      case InsightType.APP_USAGE:
        return [
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_name,
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_airwatch_device_guid,
        ];
      case InsightType.APP_INSTALL_FAILED:
      case InsightType.APP_INSTALL_PENDING:
        return [COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_device_guid, NgxTrendResultFlattener.getCounterKey(0)];
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
      case InsightType.OS_CRASHES:
        return [
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_device_name,
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_airwatch_device_guid,
        ];
      case InsightType.CPU_UTILIZATION:
        return [
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_os_version,
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_device_name,
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_airwatch_device_guid,
        ];
      case InsightType.FAILED_SSO_LOGIN:
        return [COLUMN_NAMES.byFullyQualifiedName.idm_login_values_authmethods, NgxTrendResultFlattener.getCounterKey(0)];
      case InsightType.HORIZON_SESSION_LOGON_DURATION:
        return [
          COLUMN_NAMES.byFullyQualifiedName.horizon_session_historical_pool,
          COLUMN_NAMES.byFullyQualifiedName.horizon_session_historical_session_id,
        ];
    }
  }
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { FeatureControls, IntelOrg, LOAD_STATE, Org, UI, UserPreference } from '@ws1c/intelligence-models';
import { UserPreferenceState } from './user-preference.state';

/**
 * UserPreferenceCommonSelectors
 *
 * @export
 * @class UserPreferenceCommonSelectors
 */
export class UserPreferenceCommonSelectors {
  /**
   * selectUserPreferenceState
   * @static
   * @type {(state: CoreAppState) => UserPreferenceState}
   * @memberof IntegrationSelectors
   */
  public static selectUserPreferenceState: Selector<CoreAppState, UserPreferenceState> = (state: CoreAppState): UserPreferenceState => {
    return state.userPreferenceState;
  };

  /**
   * userPreference
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, UserPreference>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static getUserPreference: MemoizedSelector<CoreAppState, UserPreference> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state?.userPreference,
  );

  /**
   * userOrgs
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, IntelOrg[]>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static userOrgs: MemoizedSelector<CoreAppState, IntelOrg[]> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state?.userOrgs,
  );

  /**
   * getOrgInfo
   * @static
   * @type {MemoizedSelector<CoreAppState, Org>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static getOrgInfo: MemoizedSelector<CoreAppState, Org> = createSelector(
    UserPreferenceCommonSelectors.getUserPreference,
    (userPreference: UserPreference) => userPreference?.org,
  );

  /**
   * getUiInfo
   * @static
   * @type {MemoizedSelector<CoreAppState, UI>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static getUiInfo: MemoizedSelector<CoreAppState, UI> = createSelector(
    UserPreferenceCommonSelectors.getUserPreference,
    (userPreference: UserPreference) => {
      if (!userPreference) {
        return;
      }
      return userPreference.ui;
    },
  );

  /**
   * getAsset
   * @static
   * @param {string} assetKey
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static getAsset = (assetKey: string): MemoizedSelector<CoreAppState, string> =>
    createSelector(UserPreferenceCommonSelectors.getUiInfo, (ui: UI) => {
      if (!ui) {
        return;
      }
      return ui.assets[assetKey];
    });

  /**
   * getLimit
   * @static
   * @param {string} limitKey
   * @type {(limitKey: string) => MemoizedSelector<CoreAppState, any>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static getLimit: (limitKey: string) => MemoizedSelector<CoreAppState, any> = (
    limitKey: string,
  ): MemoizedSelector<CoreAppState, any> =>
    createSelector(UserPreferenceCommonSelectors.getUiInfo, (ui: UI) => {
      if (!ui) {
        return;
      }
      return ui.limits[limitKey];
    });

  /**
   * getSystemObjectId
   * @static
   * @param {string} key
   * @type  {(key: string) => MemoizedSelector<CoreAppState, string>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static getSystemObjectId = (key: string): MemoizedSelector<CoreAppState, string> =>
    createSelector(UserPreferenceCommonSelectors.getUiInfo, (ui: UI) => {
      if (!ui) {
        return;
      }
      return ui.systemObjectIds?.[key];
    });

  /**
   * getFeatureControls
   * @static
   * @type {MemoizedSelector<CoreAppState, FeatureControls>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static getFeatureControls: MemoizedSelector<CoreAppState, FeatureControls> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (orgInfo: Org) => {
      return orgInfo?.featureControls ?? {};
    },
  );

  /**
   * isFeatureEnabled
   * @static
   * @param {string} featureKey
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static isFeatureEnabled = (featureKey: string): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(UserPreferenceCommonSelectors.getFeatureControls, (featureControls: FeatureControls) => {
      if (!featureControls) {
        return;
      }

      return !!featureControls[featureKey];
    });

  /**
   * anyFeaturesEnabled
   * @static
   * @param {string[]} features
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static anyFeaturesEnabled = (features: string[]): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(UserPreferenceCommonSelectors.getFeatureControls, (featureControls: FeatureControls) =>
      features.some((integrationItem: string) => !!featureControls[integrationItem]),
    );

  /**
   * loadState
   * @static
   * @type {MemoizedSelector<CoreAppState, LOAD_STATE>}
   * @memberOf UserPreferenceCommonSelectors
   */
  public static loadState: MemoizedSelector<CoreAppState, LOAD_STATE> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.loadState,
  );
}

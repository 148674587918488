/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import {
  DeemSyntheticURLMonitoringAction,
  DeemSyntheticURLMonitoringConfigIPVersion,
  DeemSyntheticURLMonitoringConfigState,
  DeemSyntheticURLMonitoringMethodType,
  DeemSyntheticURLMonitoringPlatform,
  DeemSyntheticURLMonitoringTestType,
} from '@ws1c/deem-solution/models/deem-synthetic-url-monitoring';
import { AppConstants } from '@ws1c/intelligence-models';

/**
 * DeemSyntheticUrlMonitoringTestConfig
 * @export
 * @class DeemSyntheticUrlMonitoringTestConfig
 */
export class DeemSyntheticUrlMonitoringTestConfig {
  public static readonly IP_VERSIONS: DeemSyntheticURLMonitoringConfigIPVersion[] = [
    DeemSyntheticURLMonitoringConfigIPVersion.IPV4,
    DeemSyntheticURLMonitoringConfigIPVersion.IPV6,
    DeemSyntheticURLMonitoringConfigIPVersion.Auto,
  ];
  public static readonly PLATFORMS: DeemSyntheticURLMonitoringPlatform[] = Object.values(DeemSyntheticURLMonitoringPlatform);
  public static readonly TEST_TYPES: DeemSyntheticURLMonitoringTestType[] = Object.values(DeemSyntheticURLMonitoringTestType);
  public static readonly TEST_TYPES_LABEL: Record<DeemSyntheticURLMonitoringTestType, string> = {
    [DeemSyntheticURLMonitoringTestType.HTTPS]: 'HTTP/HTTPS',
  };
  public static readonly TEST_METHODS: DeemSyntheticURLMonitoringMethodType[] = [
    DeemSyntheticURLMonitoringMethodType.GET,
    DeemSyntheticURLMonitoringMethodType.HEAD,
  ];
  public static readonly TEST_FREQUENCY_SECONDS: number[] = [
    AppConstants.SECONDS_PER_30_MINUTES,
    AppConstants.SECONDS_PER_HOUR,
    AppConstants.SECONDS_PER_4_HOURS,
    AppConstants.SECONDS_PER_12_HOURS,
    AppConstants.SECONDS_PER_DAY,
  ];
  public static readonly TEST_STATES_FOR_ACTIONS: Record<DeemSyntheticURLMonitoringAction, DeemSyntheticURLMonitoringConfigState[]> = {
    [DeemSyntheticURLMonitoringAction.DEACTIVATE]: [DeemSyntheticURLMonitoringConfigState.PUBLISHED],
    [DeemSyntheticURLMonitoringAction.DELETE]: [DeemSyntheticURLMonitoringConfigState.DRAFT],
    [DeemSyntheticURLMonitoringAction.DISCARD]: [],
    [DeemSyntheticURLMonitoringAction.EDIT]: [
      DeemSyntheticURLMonitoringConfigState.DRAFT,
      DeemSyntheticURLMonitoringConfigState.INACTIVE,
      DeemSyntheticURLMonitoringConfigState.PUBLISHED,
    ],
    [DeemSyntheticURLMonitoringAction.PUBLISH]: [
      DeemSyntheticURLMonitoringConfigState.DRAFT,
      DeemSyntheticURLMonitoringConfigState.INACTIVE,
    ],
  };
  public static readonly STATE_CLASS_MAP: Record<DeemSyntheticURLMonitoringConfigState, string> = {
    [DeemSyntheticURLMonitoringConfigState.PUBLISHED]: 'text-success',
    [DeemSyntheticURLMonitoringConfigState.DRAFT]: '',
    [DeemSyntheticURLMonitoringConfigState.INACTIVE]: '',
  };
}

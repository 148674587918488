/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, EndpointV2, HttpService } from '@ws1c/intelligence-common';
import {
  CustomReport,
  CustomReportPreviewSearchResponse,
  CustomReportSearchRequest,
  CustomReportSearchResponse,
  ReportRetentionDetails,
  ReportShare,
  ScheduledReport,
  ShareAccess,
  ShareView,
  SingleRunReportRequest,
  TransferOwnershipRequest,
  UserAccess,
} from '@ws1c/intelligence-models';

/**
 * Support basic CRUD actions for custom reports
 * @link: ReportController.java
 * @export
 * @class CustomReportService
 */
@Injectable({
  providedIn: 'root',
})
export class CustomReportService {
  /**
   * Creates an instance of CustomReportService.
   * @param {HttpService} http
   * @memberof CustomReportService
   */
  constructor(private http: HttpService) {}

  /**
   * getCustomReports
   *
   * @param {CustomReportSearchRequest} searchRequest
   * @returns {Observable<CustomReportSearchResponse>}
   * @memberof CustomReportService
   */
  public getCustomReports(searchRequest: CustomReportSearchRequest): Observable<CustomReportSearchResponse> {
    return this.http.post(EndpointV2.REPORTS_SEARCH, searchRequest).pipe(
      map((response: any) => deserialize(CustomReportSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Create New Report
   * @param {CustomReport} reportData
   * @returns {Observable<CustomReport>}
   * @memberof CustomReportService
   */
  public createReport(reportData: CustomReport): Observable<CustomReport> {
    return this.http.post(EndpointV2.REPORTS, reportData).pipe(
      map((response: GenericObject) => deserialize(CustomReport, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getReportDetails
   *
   * @param {string} id
   * @returns {Observable<CustomReport>}
   * @memberof CustomReportService
   */
  public getReportDetails(id: string): Observable<CustomReport> {
    return this.http.get(EndpointV2.REPORT_ID(id)).pipe(
      map((response: any) => deserialize(CustomReport, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Edit Current Report
   * @param {CustomReport} reportData
   * @returns {Observable<CustomReport>}
   * @memberof CustomReportService
   */
  public editReport(reportData: CustomReport): Observable<CustomReport> {
    return this.http.put(EndpointV2.REPORT_ID(reportData.id), reportData).pipe(
      map((response: GenericObject) => deserialize(CustomReport, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteReport
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof CustomReportService
   */
  public deleteReport(id: string): Observable<any> {
    return this.http.delete(EndpointV2.REPORT_ID(id)).pipe(
      map((response: any) => response),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteReports
   * @param {string[]} identifiers
   * @returns {Observable<boolean>}
   * @memberof CustomReportService
   */
  public deleteReports(identifiers: string[]): Observable<boolean> {
    return this.http.delete(EndpointV2.REPORTS, { body: { identifiers } }).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * previewReport
   * @param {CustomReportSearchRequest} searchRequest
   * @returns {Observable<CustomReportPreviewSearchResponse>}
   * @memberof CustomReportService
   */
  public previewReport(searchRequest: CustomReportSearchRequest): Observable<CustomReportPreviewSearchResponse> {
    return this.http.post(EndpointV2.REPORTS_PREVIEW, searchRequest).pipe(
      map((response: any) => deserialize(CustomReportPreviewSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * previewReportById
   * @param {CustomReportSearchRequest} searchRequest
   * @returns {Observable<CustomReportPreviewSearchResponse>}
   * @memberof CustomReportService
   */
  public previewReportById(searchRequest: CustomReportSearchRequest): Observable<CustomReportPreviewSearchResponse> {
    return this.http.post(EndpointV2.REPORT_ID_PREVIEW(searchRequest.reportId), searchRequest).pipe(
      map((response: any) => deserialize(CustomReportPreviewSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * scheduleCustomReport
   *
   * @param {ScheduledReport} scheduleData
   * @returns {Observable<ScheduledReport>}
   * @memberof CustomReportService
   */
  public scheduleCustomReport(scheduleData: ScheduledReport): Observable<ScheduledReport> {
    return this.http.post(Endpoint.REPORTS_SCHEDULES, scheduleData).pipe(
      map((response: any) => deserialize(ScheduledReport, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * shareCustomReport
   *
   * @param {*} shareReportData
   * @returns {Observable<boolean>}
   * @memberof CustomReportService
   */
  public shareCustomReport(shareReportData: any): Observable<boolean> {
    return this.http.post(Endpoint.REPORT_RECIPIENTS(shareReportData.reportId), { recipients: shareReportData.reportRecipients }).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getReportRecipients
   *
   * @param {string} reportId
   * @returns {Observable<ReportShare>}
   * @memberof CustomReportService
   */
  public getReportRecipients(reportId: string): Observable<ReportShare> {
    return this.http.get(Endpoint.REPORT_RECIPIENTS(reportId)).pipe(
      map((response: any) => deserialize(ReportShare, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * runCustomReport
   *
   * @param {SingleRunReportRequest} runReportData
   * @returns {Observable<any>}
   * @memberof CustomReportService
   */
  public runCustomReport(runReportData: SingleRunReportRequest): Observable<any> {
    return this.http.post(Endpoint.REPORT_RUN(runReportData.reportId), {}).pipe(
      map((response: any) => response),
      catchError(requestErrorHandler),
    );
  }

  /**
   * startLinkSharing
   *
   * @param {string} shareType
   * @param {string} reportId
   * @returns {Observable<ShareView>}
   * @memberof CustomReportService
   */
  public startLinkSharing(shareType: string, reportId: string): Observable<ShareView> {
    return this.http.post(Endpoint.SHARE_ID(shareType, reportId), {}).pipe(
      map((response: any) => deserialize(ShareView, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * stopLinkSharing
   *
   * @param {string} shareType
   * @param {string} reportId
   * @returns {Observable<any>}
   * @memberof CustomReportService
   */
  public stopLinkSharing(shareType: string, reportId: string): Observable<any> {
    return this.http.delete(Endpoint.SHARE_ID(shareType, reportId)).pipe(
      map((response: any) => response),
      catchError(requestErrorHandler),
    );
  }

  /**
   * internalShareCustomReport
   *
   * @param {string} reportId
   * @param {UserAccess} userShareDetails
   * @returns {Observable<boolean>}
   * @memberof CustomReportService
   */
  public internalShareCustomReport(reportId: string, userShareDetails: UserAccess[]): Observable<boolean> {
    return this.http.put(Endpoint.REPORT_INTERNAL_SHARE(reportId), userShareDetails).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getReportInternalShareAccounts
   * @param {string} reportId
   * @returns {Observable<UserAccess[]>}
   * @memberof CustomReportService
   */
  public getReportInternalShareAccounts(reportId: string): Observable<UserAccess[]> {
    return this.http.get(Endpoint.REPORT_INTERNAL_SHARE_ACCOUNTS(reportId)).pipe(
      map((response: GenericObject) => deserialize(ShareAccess, response.data).details),
      catchError(requestErrorHandler),
    );
  }

  /**
   * transferOwnershipForCustomReports
   * @param {TransferOwnershipRequest} transferOwnershipRequest
   * @returns {Observable<any>}
   * @memberof CustomReportService
   */
  public transferOwnershipForCustomReports(transferOwnershipRequest: TransferOwnershipRequest): Observable<any> {
    return this.http.put(Endpoint.REPORTS_TRANSFER_OWNERSHIP, transferOwnershipRequest).pipe(catchError(requestErrorHandler));
  }

  /**
   * getReportRetentionDetails
   * @returns {Observable<ReportRetentionDetails>}
   * @memberof CustomReportService
   */
  public getReportRetentionDetails(): Observable<ReportRetentionDetails> {
    return this.http.get(Endpoint.ORG_REPORT_RETENTION).pipe(
      map((response: GenericObject) => deserialize(ReportRetentionDetails, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateReportRetention
   * @param {ReportRetentionDetails} reportRetentionDetails
   * @returns {Observable<boolean>}
   * @memberof CustomReportService
   */
  public updateReportRetention(reportRetentionDetails: ReportRetentionDetails): Observable<boolean> {
    return this.http.put(Endpoint.ORG_REPORT_RETENTION, reportRetentionDetails).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}

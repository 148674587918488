/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { each } from 'lodash-es';

import { NameLabel, Tag, Template } from '@ws1c/intelligence-models';

/**
 * filterTags
 * @param {Tag[]} tags
 * @param {Template[]} templates
 * @returns {Tag[]}
 * @export
 */
export function filterTags(tags: Tag[], templates: Template[]): Tag[] {
  const allTags = [];
  each(templates, (template: Template) => {
    allTags.push(...template.templateTags);
  });
  const reportTags = [];
  tags.forEach((tag: Tag) => {
    const reportSubTags = [];
    // Add subtags
    tag.subtags.forEach((subtag: NameLabel) => {
      if (allTags.includes(`${tag.name}_${subtag.name}`)) {
        reportSubTags.push(subtag);
      }
    });
    // Add the tag if at least one subtag is present
    if (reportSubTags.length > 0) {
      reportTags.push(
        Object.assign(new Tag(), tag, {
          subtags: reportSubTags,
        }),
      );
    }
  });
  return reportTags;
}

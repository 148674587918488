/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';
import { keyBy, mapValues } from 'lodash-es';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { IntegratedServicesSelectors } from '@ws1c/intelligence-core/store/integrated-services';
import {
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
  UserPreferenceTrialInfoSelectors,
} from '@ws1c/intelligence-core/store/user-preference';
import {
  COLUMN_NAMES,
  IntegratedService,
  TrialActivationDetail,
  TrialBanner,
  TrialDetail,
  TrialStatus,
  TrialUserContactDetails,
} from '@ws1c/intelligence-models';
import { TrialServicesState } from './trial-services.store';

/**
 * TrialServicesSelectors
 * @export
 * @class TrialServicesSelectors
 */
export class TrialServicesSelectors {
  public static selectTrialServicesState = (state: CoreAppState) => state.trialServicesState;

  /**
   * isLoadingTrialDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TrialServicesSelectors
   */
  public static isLoadingTrialDetails: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TrialServicesSelectors.selectTrialServicesState,
    (state: TrialServicesState) => {
      return state.isLoadingTrialDetails;
    },
  );

  /**
   * getStartTrialTriggerStatusByServiceType
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, boolean>>}
   * @memberof TrialServicesSelectors
   */
  public static getStartTrialTriggerStatusByServiceType: MemoizedSelector<CoreAppState, Record<string, boolean>> = createSelector(
    TrialServicesSelectors.selectTrialServicesState,
    (state: TrialServicesState) => {
      return state.startTrialTriggerStatusByServiceType;
    },
  );

  /**
   * getTrialDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, TrialDetail[]>}
   * @memberof TrialServicesSelectors
   */
  public static getTrialDetails: MemoizedSelector<CoreAppState, TrialDetail[]> = createSelector(
    TrialServicesSelectors.selectTrialServicesState,
    IntegratedServicesSelectors.homePageServicesMap,
    UserPreferenceFeatureControlsSelectors.isInProductTrialEnabled,
    UserPreferenceSelectors.hasPremiumLicense,
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerInfo,
    (
      state: TrialServicesState,
      homePageServicesMap: Map<string, IntegratedService[]>,
      isInProductTrialEnabled: boolean,
      hasPremiumLicense: boolean,
      trialBannerInfo: TrialBanner,
    ) => {
      return state.trialDetails?.map((trialDetail: TrialDetail) => {
        const homePageService: IntegratedService = homePageServicesMap.get(trialDetail.integratedServiceType)?.[0];
        if (homePageService) {
          trialDetail.url = homePageService.url;
          trialDetail.urlType = homePageService.urlType;
          trialDetail.isEntitled = homePageService.isIntelligenceService
            ? homePageService.isIntelligencePremium(isInProductTrialEnabled, hasPremiumLicense, trialBannerInfo?.showBanner)
            : homePageService.isNonIntelligenceServiceEntitled(isInProductTrialEnabled);
        }
        return trialDetail;
      });
    },
  );

  /**
   * isSomeTrialServiceNotEntitled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TrialServicesSelectors
   */
  public static isSomeTrialServiceNotEntitled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TrialServicesSelectors.getTrialDetails,
    (trialDetails: TrialDetail[]) => {
      return trialDetails?.some((trialDetail: TrialDetail) => !trialDetail.isEntitled);
    },
  );

  /**
   * showActiveTrialsSection
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TrialServicesSelectors
   */
  public static showActiveTrialsSection: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isInProductTrialEnabled,
    TrialServicesSelectors.getTrialDetails,
    TrialServicesSelectors.isSomeTrialServiceNotEntitled,
    (isInProductTrialEnabled: boolean, trialDetails: TrialDetail[], isSomeTrialServiceNotEntitled: boolean) =>
      isInProductTrialEnabled && trialDetails?.length > 0 && isSomeTrialServiceNotEntitled,
  );

  /**
   * showTrialActivationInfoBanner
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TrialServicesSelectors
   */
  public static showTrialActivationInfoBanner: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TrialServicesSelectors.getTrialDetails,
    (trialDetails: TrialDetail[]) => {
      return trialDetails?.some((trialDetail: TrialDetail) => trialDetail.status === TrialStatus.REQUESTED);
    },
  );

  /**
   * getServiceTitlesByTrialStatusMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<TrialStatus, string[]>>}
   * @memberof TrialServicesSelectors
   */
  public static getServiceTitlesByTrialStatusMap: MemoizedSelector<CoreAppState, Map<TrialStatus, string[]>> = createSelector(
    TrialServicesSelectors.getTrialDetails,
    (trialDetails: TrialDetail[]) => {
      const result: Map<TrialStatus, string[]> = new Map<TrialStatus, string[]>();
      trialDetails?.forEach((trialDetail: TrialDetail) => {
        const serviceTrialStatus: TrialStatus = trialDetail.status;
        if (!result.has(serviceTrialStatus)) {
          result.set(serviceTrialStatus, []);
        }
        result.set(serviceTrialStatus, [...result.get(serviceTrialStatus), trialDetail.title]);
      });
      return result;
    },
  );

  /**
   * getServiceTitleByServiceTypeMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, string>>}
   * @memberof TrialServicesSelectors
   */
  public static getServiceTitleByServiceTypeMap: MemoizedSelector<CoreAppState, Map<string, string>> = createSelector(
    TrialServicesSelectors.getTrialDetails,
    (trialDetails: TrialDetail[]) => {
      const result: Map<string, string> = new Map<string, string>();
      trialDetails?.forEach((trialDetail: TrialDetail) => {
        const integratedServiceType: string = trialDetail.integratedServiceType;
        result.set(integratedServiceType, trialDetail.title);
      });
      return result;
    },
  );

  /**
   * getTitlesOfServicesUnderTrialRequestedState
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof TrialServicesSelectors
   */
  public static getTitlesOfServicesUnderTrialRequestedState: MemoizedSelector<CoreAppState, string> = createSelector(
    TrialServicesSelectors.getServiceTitlesByTrialStatusMap,
    (serviceTitlesByTrialStatusMap: Map<TrialStatus, string[]>) => {
      return serviceTitlesByTrialStatusMap.get(TrialStatus.REQUESTED).join(', ');
    },
  );

  /**
   * getTitlesOfServicesUnderTrialFailedState
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof TrialServicesSelectors
   */
  public static getTitlesOfServicesUnderTrialFailedState: MemoizedSelector<CoreAppState, string> = createSelector(
    TrialServicesSelectors.getServiceTitlesByTrialStatusMap,
    (serviceTitlesByTrialStatusMap: Map<TrialStatus, string[]>) => {
      return serviceTitlesByTrialStatusMap.get(TrialStatus.FAILED).join(', ');
    },
  );

  /**
   * getTrialStatusByServiceTypeMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, TrialStatus>>}
   * @memberof TrialServicesSelectors
   */
  public static getTrialStatusByServiceTypeMap: MemoizedSelector<CoreAppState, Map<string, TrialStatus>> = createSelector(
    TrialServicesSelectors.getTrialDetails,
    (trialDetails: TrialDetail[]) => {
      const result: Map<string, TrialStatus> = new Map<string, TrialStatus>();
      trialDetails?.forEach((trialDetail: TrialDetail) => {
        const integratedServiceType: string = trialDetail.integratedServiceType;
        result.set(integratedServiceType, trialDetail.status);
      });
      return result;
    },
  );

  /**
   * getServiceEntitledStatusByServiceType
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, boolean>>}
   * @memberof TrialServicesSelectors
   */
  public static getServiceEntitledStatusByServiceType: MemoizedSelector<CoreAppState, Record<string, boolean>> = createSelector(
    TrialServicesSelectors.getTrialDetails,
    (trialDetails: TrialDetail[]) => {
      return mapValues(keyBy(trialDetails, COLUMN_NAMES.byName.integratedServiceType), COLUMN_NAMES.byName.isEntitled);
    },
  );

  /**
   * getTrialActivationDetail
   * @static
   * @type {MemoizedSelector<CoreAppState, TrialActivationDetail>}
   * @memberof TrialServicesSelectors
   */
  public static getTrialActivationDetail: MemoizedSelector<CoreAppState, TrialActivationDetail> = createSelector(
    TrialServicesSelectors.selectTrialServicesState,
    (state: TrialServicesState) => state.trialActivationDetail,
  );

  /**
   * showTrialErrorMessage
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TrialServicesSelectors
   */
  public static showTrialErrorMessage: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TrialServicesSelectors.getTrialDetails,
    (trialDetails: TrialDetail[]) => {
      return trialDetails?.some((trialDetail: TrialDetail) => trialDetail.status === TrialStatus.FAILED);
    },
  );

  /**
   * getTrialUserContactDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, TrialUserContactDetails>}
   * @memberof TrialServicesSelectors
   */
  public static getTrialUserContactDetails: MemoizedSelector<CoreAppState, TrialUserContactDetails> = createSelector(
    TrialServicesSelectors.selectTrialServicesState,
    (state: TrialServicesState) => state.trialUserContactDetails,
  );

  /**
   * isTrialServicesModalOpen
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TrialServicesSelectors
   */
  public static isTrialServicesModalOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TrialServicesSelectors.selectTrialServicesState,
    (state: TrialServicesState) => state.isTrialServicesModalOpen,
  );

  /**
   * showTrialUserContactDetailsForm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TrialServicesSelectors
   */
  public static showTrialUserContactDetailsForm: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TrialServicesSelectors.selectTrialServicesState,
    (state: TrialServicesState) => state.showTrialUserContactDetailsForm,
  );

  /**
   * isFetchingTrialUserContactDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TrialServicesSelectors
   */
  public static isFetchingTrialUserContactDetails: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TrialServicesSelectors.selectTrialServicesState,
    (state: TrialServicesState) => state.isFetchingTrialUserContactDetails,
  );

  /**
   * isTrialUserContactDetailsAccepted
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TrialServicesSelectors
   */
  public static isTrialUserContactDetailsAccepted: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TrialServicesSelectors.selectTrialServicesState,
    (state: TrialServicesState) => state.isTrialUserContactDetailsAccepted,
  );
}

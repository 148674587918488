/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models';
import { DeemSyntheticURLMonitoringMethodType } from './deem-synthetic-url-monitoring-method-type.enum';
import { DeemSyntheticURLMonitoringTestType } from './deem-synthetic-url-monitoring-test-type.enum';

/**
 * DeemSyntheticURLMonitoringProtocolConfig
 * @export
 * @class DeemSyntheticURLMonitoringProtocolConfig
 */
@Serializable
export class DeemSyntheticURLMonitoringProtocolConfig {
  @JsonProperty({ name: 'type', customConverter: enumConverterFactory(DeemSyntheticURLMonitoringTestType) })
  public type: DeemSyntheticURLMonitoringTestType = undefined;

  @JsonProperty('frequency_seconds')
  public frequencySeconds: number = undefined;

  @JsonProperty('timeout_seconds')
  public timeoutSeconds: number = undefined;

  @JsonProperty({ name: 'method', customConverter: enumConverterFactory(DeemSyntheticURLMonitoringMethodType) })
  public method: DeemSyntheticURLMonitoringMethodType = undefined;

  /**
   * Creates an instance of DeemSyntheticURLMonitoringProtocolConfig.
   * @param {Array<Partial<DeemSyntheticURLMonitoringProtocolConfig>>} args
   * @memberof DeemSyntheticURLMonitoringProtocolConfig
   */
  constructor(...args: Array<Partial<DeemSyntheticURLMonitoringProtocolConfig>>) {
    Object.assign(this, ...args);
  }
}

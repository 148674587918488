/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import type { BarVerticalComponent } from '@dpa/ui-common';

import { BaseChartComponent } from '@ws1c/dashboard-common/chart/base-chart.component';
import {
  AggregationWidgetChartType,
  ChartDrilldownEvent,
  DashboardConfig,
  NgxChart,
  NgxSingleData,
  NgxTrendResultFlattener,
  WidgetSequence,
} from '@ws1c/intelligence-models';

/**
 * VerticalChartComponent
 *
 * @export
 * @class VerticalChartComponent
 * @extends {BaseChartComponent}
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-vertical-chart',
  templateUrl: 'vertical-chart.component.html',
  styleUrls: ['vertical-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalChartComponent extends BaseChartComponent implements AfterViewInit, OnChanges {
  @Input() public ngxChart: NgxChart;
  @Input() public overlayChart: NgxChart;
  @Input() public showDetailsLink?: boolean = false;
  @Input() public isPercentage?: boolean = false;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public seriesTooltip?: TemplateRef<any>;
  @Input() public yAxisLabel?: string;
  @Input() public rotateXAxisTicks?: boolean = false;
  @Input() public showXAxis?: boolean = true;
  @Input() public showYAxis?: boolean = true;
  @Input() public showXAxisLabel?: boolean = true;
  @Input() public showDataLabel?: boolean = true;
  @Input() public yScaleMax?: number;
  @Input() public focussedSequence?: WidgetSequence;
  @Input() public overlayTooltipTemplate: TemplateRef<any>;
  @Input() public chartType: AggregationWidgetChartType;
  @Output() public onViewDetails = new EventEmitter<ChartDrilldownEvent>();

  @ViewChild('chart') public chart: BarVerticalComponent;

  public data: NgxSingleData[];
  public overlayData: NgxSingleData[];
  public maxXAxisTickLength: number = DashboardConfig.MAX_X_AXIS_TICK_LENGTH;

  public readonly AggregationWidgetChartType = AggregationWidgetChartType;

  /**
   * ngAfterViewInit
   *
   * @memberof VerticalChartComponent
   */
  public ngAfterViewInit() {
    // Workaround for data label overflow must manually trigger update as per:
    // https://github.com/swimlane/ngx-charts/issues/474#issuecomment-333860410
    this.data = [...this.ngxChart.chartData];
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof VerticalChartComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    // make sure upon chartData changes
    if (changes.ngxChart && changes.ngxChart.previousValue && changes.ngxChart.previousValue.chartData) {
      this.data = [...this.ngxChart.chartData];
    }
    if (changes.overlayChart?.currentValue) {
      this.overlayData = this.overlayChart.chartData ? [...this.overlayChart.chartData] : [];
    }
    this.maxXAxisTickLength = DashboardConfig.MAX_X_AXIS_TICK_LENGTH;
    if (this.ngxChart.groupBys.length === 1 && this.ngxChart.groupBys[0] === NgxTrendResultFlattener.FAKE_GROUP_BY_KEY) {
      this.maxXAxisTickLength = DashboardConfig.MAX_X_AXIS_TICK_LENGTH_NO_GROUPS;
    }
  }

  /**
   * onSelect
   *
   * @param {any} ngxSelectEvent
   * @memberof VerticalChartComponent
   */
  public onSelect(ngxSelectEvent: any) {
    if (this.showDetailsLink) {
      const drilldownEvent: ChartDrilldownEvent = this.ngxChart.drilldownEventBuilder.getEvent(ngxSelectEvent);
      this.onDrilldown(drilldownEvent);
    }
  }

  /**
   * onDrilldown
   * @param {ChartDrilldownEvent} drilldownEvent
   * @memberof VerticalChartComponent
   */
  public onDrilldown(drilldownEvent: ChartDrilldownEvent) {
    this.onViewDetails.emit(drilldownEvent);
  }

  /**
   * onOverlayDrilldown
   * @param {any} ngxSelectEvent
   * @memberof VerticalChartComponent
   */
  public onOverlayDrilldown(ngxSelectEvent: any) {
    if (!this.showDetailsLink) {
      return;
    }
    const drilldownEvent: ChartDrilldownEvent = this.overlayChart.drilldownEventBuilder.getEvent(ngxSelectEvent);
    this.onViewDetails.emit(drilldownEvent);
  }

  /**
   * onResize
   *
   * @memberof VerticalChartComponent
   */
  public onResize() {
    if (!this.chart) {
      return;
    }
    this.chart.update();
  }
}

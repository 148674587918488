/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { gql } from 'apollo-angular';

export const CREATE_AUTOMATION = gql`
  mutation CreateAutomation($automationInput: AutomationInput!) {
    createAutomation(input: $automationInput) {
      id
      name
      description
      source_object_id
      source_object_type
      workflow {
        actions
      }
    }
  }
`;

export const UPDATE_AUTOMATION = gql`
  mutation UpdateAutomation($automationInput: AutomationInput!) {
    updateAutomation(input: $automationInput) {
      id
      name
      description
    }
  }
`;

export const GET_AUTOMATION_BY_ID = gql`
  query GetAutomation($id: UUID) {
    automation(input: $id) {
      id
      org_id
      source_object_id
      source_object_type
      name
      description
      integration
      target_entity
      evaluation_type
      condition
      active
      repeat_trigger
      created_at
      created_by
      last_run_details {
        created_at
        status
      }
      modified_at
      modified_by
      status_change_message
      schedule
      created_by_details {
        id
        display_name
      }
      modified_by_details {
        id
        display_name
      }
      workflow {
        actions
      }
    }
  }
`;

export const DELETE_AUTOMATION = gql`
  mutation DeleteAutomation($ruleId: UUID!) {
    deleteAutomation(rule_id: $ruleId) {
      response
    }
  }
`;

export const SEARCH_AUTOMATION = gql`
  query SearchAutomation($searchInput: PagedSearchRequestInput!) {
    searchAutomationSummaries(input: $searchInput) {
      automation_summaries {
        id
        source_object_id
        source_object_type
        name
        description
        integration
        target_entity
        evaluation_type
        active
        created_at
        created_by_details {
          id
          display_name
        }
        modified_at
        modified_by_details {
          id
          display_name
        }
        repeat_trigger
        last_run_details {
          created_at
          status
        }
        status_change_message
        icon_links
      }
      paged_response {
        page_size
        offset
        total_count
      }
    }
  }
`;

export const COPY_AUTOMATION = gql`
  mutation CopyAutomation($ruleId: UUID!, $copyName: String!) {
    copyAutomation(rule_id: $ruleId, copy_name: $copyName) {
      id
      name
    }
  }
`;

export const DISABLE_AUTOMATION = gql`
  mutation DisableAutomation($ruleId: UUID!) {
    disableAutomation(rule_id: $ruleId) {
      __typename
    }
  }
`;

export const ENABLE_AUTOMATION = gql`
  mutation EnableAutomation($workflowInput: WorkflowInput!, $ruleId: UUID!) {
    enableAutomation(input: $workflowInput, rule_id: $ruleId) {
      id
      name
      description
    }
  }
`;

/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { FeatureControl } from '@ws1c/intelligence-common/constants/feature-control.enum';
import { FeatureControls } from '@ws1c/intelligence-models';

/**
 * filterEnabledIntegrationTypes
 * @param {FeatureControls} featureControls
 * @param {string[]} integrations
 * @returns {string[]}
 */
export function filterEnabledIntegrationTypes(featureControls: FeatureControls, integrations: string[]): string[] {
  return integrations.filter((integration: string) => {
    const featureKey = integration.toUpperCase() + '_ENABLED';
    const feature: string = FeatureControl[featureKey];
    return featureControls[feature];
  });
}

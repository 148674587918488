/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Tag } from '@ws1c/intelligence-models';

/**
 * QuickFilterState
 * @export
 * @interface QuickFilterState
 */
export interface QuickFilterState {
  tags: Tag[];
}

/**
 * Defines initial state for QuickFilterState
 * @export
 * @type {QuickFilterState}
 */
export const initialQuickFilterState: QuickFilterState = {
  tags: [],
};

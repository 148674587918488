/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, WebError } from '@dpa/ui-common';

import {
  Automation,
  AutomationAction,
  AutomationDialogMode,
  AutomationQuota,
  AutomationSearchRequest,
  AutomationSearchResponse,
  AutomationSourceObject,
  AutomationTestConnectionResult,
  AutomationWizardMode,
  ConnectionTestMode,
  Connector,
  ConnectorAction,
  ConnectorsAllActionsResponse,
  CustomReportPreviewSearchResponse,
  MetaForm,
  NameValue,
  ROUTE_NAMES,
} from '@ws1c/intelligence-models';

/**
 * AutomationCommonState
 *
 * @export
 * @interface AutomationCommonState
 */
export interface AutomationCommonState {
  automation: Automation;
  automationPageUrl: string;
  automationPreviewModel: Automation;
  automationQuotas: AutomationQuota[];
  automationWizardMode: AutomationWizardMode;
  automationWizardModel: Automation;
  automationStateParams: GenericObject;
  automationDialogMode: AutomationDialogMode;
  automationDialogModel: Automation;
  cancelWizardReturnPath: string;
  loadingActions: boolean;
  connectorsActionsResponse: ConnectorsAllActionsResponse;
  testableConnectorAction: ConnectorAction;
  testableConnector: Connector;
  successWizardReturnPath: string;
  detailsAutomation: Automation;
  error: WebError;
  fromDetailView: boolean;
  isSubPage: boolean;
  loading: boolean;
  loadingMore: boolean;
  previewData: CustomReportPreviewSearchResponse;
  previewDataLoading: boolean;
  previewDataLastRefreshedAt: number;
  request: AutomationSearchRequest;
  response: AutomationSearchResponse;
  sourceObject: AutomationSourceObject;
  testAttributes: Set<string>;
  testableAutomationAction: AutomationAction;
  testableMetaForm: MetaForm;
  testConnectionStatus: ConnectionTestMode;
  testConnectionResult: AutomationTestConnectionResult;
  testDynamicValues: CustomReportPreviewSearchResponse;
  testDynamicValuesLoading: boolean;
  lookupMap: Record<string, NameValue[]>;
  lookupMapError: WebError;
  lookupMapLoading: boolean;
  currentActionConnectorId: string;
  conditionNameIndex: number;
  groupNameIndex: number;
}

/**
 * Defines initial state for AutomationCommon
 *
 * @export
 * @type {AutomationCommonState}
 */
export const initialAutomationCommonState: AutomationCommonState = {
  automation: new Automation(),
  automationPageUrl: `/${ROUTE_NAMES.AUTOMATION.AUTOMATION_WORKFLOW}`,
  automationPreviewModel: undefined,
  automationQuotas: [],
  automationWizardMode: AutomationWizardMode.CLOSE,
  automationWizardModel: undefined,
  automationStateParams: null,
  automationDialogMode: AutomationDialogMode.CLOSE,
  automationDialogModel: undefined,
  loadingActions: false,
  connectorsActionsResponse: undefined,
  testableConnectorAction: undefined,
  testableConnector: undefined,
  cancelWizardReturnPath: undefined,
  successWizardReturnPath: undefined,
  detailsAutomation: undefined,
  error: new WebError(),
  fromDetailView: false,
  isSubPage: false,
  loading: false,
  loadingMore: false,
  previewData: undefined,
  previewDataLoading: false,
  previewDataLastRefreshedAt: undefined,
  request: new AutomationSearchRequest(),
  response: new AutomationSearchResponse(),
  sourceObject: undefined,
  testAttributes: new Set<string>(),
  testableAutomationAction: undefined,
  testableMetaForm: new MetaForm(),
  testConnectionStatus: ConnectionTestMode.IDLE,
  testConnectionResult: undefined,
  testDynamicValues: undefined,
  testDynamicValuesLoading: false,
  lookupMap: {},
  lookupMapError: undefined,
  lookupMapLoading: false,
  currentActionConnectorId: undefined,
  conditionNameIndex: 0,
  groupNameIndex: 0,
};

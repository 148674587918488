/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';

import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { DASHBOARD_COMMON_CHART_COMPONENTS, DASHBOARD_COMMON_CHART_DIRECTIVES } from './chart';
import { DASHBOARD_COMMON_PROVIDERS } from './services';
import { DASHBOARD_COMMON_SHARED_COMPONENTS } from './shared';

/**
 * DashboardCommonModule
 * @export
 * @class DashboardCommonModule
 */
@NgModule({
  declarations: [...DASHBOARD_COMMON_CHART_COMPONENTS, ...DASHBOARD_COMMON_CHART_DIRECTIVES, ...DASHBOARD_COMMON_SHARED_COMPONENTS],
  imports: [IntelligenceCommonModule],
  providers: [...DASHBOARD_COMMON_PROVIDERS],
  exports: [...DASHBOARD_COMMON_CHART_COMPONENTS, ...DASHBOARD_COMMON_CHART_DIRECTIVES, ...DASHBOARD_COMMON_SHARED_COMPONENTS],
})
export class DashboardCommonModule {}

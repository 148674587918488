/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { MarketplaceSearchRequest, MarketplaceSearchResponse } from '@ws1c/intelligence-models';

/**
 * MarketplaceService
 * @export
 * @class MarketplaceService
 */
@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  /**
   * constructor
   * @param {HttpService} httpService
   * @memberof MarketplaceService
   */
  constructor(private httpService: HttpService) {}

  /**
   * searchMarketplace
   * @param {MarketplaceSearchRequest} request
   * @returns {Observable<MarketplaceSearchResponse>}
   * @memberof MarketplaceService
   */
  public searchMarketplace(request: MarketplaceSearchRequest): Observable<MarketplaceSearchResponse> {
    return this.httpService.post(Endpoint.MARKETPLACE_SEARCH, request).pipe(
      map((response: GenericObject) => deserialize(MarketplaceSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }
}

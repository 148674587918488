/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { Bookmark } from '@ws1c/intelligence-models';

/**
 * Provides REST interface for bookmarks
 *
 * @export
 * @class BookmarksService
 */
@Injectable({
  providedIn: 'root',
})
export class BookmarksService {
  /**
   * Creates an instance of BookmarksService
   * @param {HttpService} httpService - Instance of HttpService
   * @memberof BookmarksService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Add bookmark
   * @param {Bookmark} bookmark - holds new bookmark details
   * @returns {Observable<Bookmark>} - Returns observable of bookmarks list
   * @memberof BookmarksService
   */
  public addBookmark(bookmark: Bookmark): Observable<Bookmark> {
    return this.httpService.post(Endpoint.SHORTCUT, bookmark).pipe(
      map((response: any) => deserialize(Bookmark, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Retrieves bookmarks list
   * @returns {Observable<Bookmark[]>} - Returns observable of bookmarks list
   * @memberof BookmarksService
   */
  public getBookmarks(): Observable<Bookmark[]> {
    return this.httpService.get(Endpoint.SHORTCUT).pipe(
      map((response: any) => response.data.map((shortcutData) => deserialize(Bookmark, shortcutData))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Remove bookmark
   * @param {Bookmark} bookmark - holds bookmark to be deleted
   * @returns {Observable<boolean>} - Returns observable of boolean
   * @memberof BookmarksService
   */
  public removeBookmark(bookmark: Bookmark): Observable<boolean> {
    return this.httpService.delete(Endpoint.SHORTCUT_CATEGORY_BY_ID(bookmark.category, bookmark.resourceId)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSyntheticURLMonitoringPlatform
 * @enum {string}
 * @export
 */
export enum DeemSyntheticURLMonitoringPlatform {
  WINDOWS = 'WINDOWS',
}

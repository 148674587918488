/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { FuzzyItem, FuzzyService, FuzzySubgroup, FuzzySubgroupConfig, GenericObject } from '@dpa/ui-common';
import { each, groupBy, isEmpty, keyBy, map, size, sortBy, uniqBy } from 'lodash-es';

import {
  Category,
  CategoryIndex,
  Column,
  COLUMN_FILTERS,
  ColumnIndex,
  ColumnToggleFilter,
  EntityColumns,
  getUniqueId,
  Integration,
  IntegrationCategories,
  LookupVariable,
} from '@ws1c/intelligence-models';

/**
 * getCategoryLabelWithIntegration
 *
 * @export
 * @param {Category} category
 * @returns {string}
 */
export function getCategoryLabelWithIntegration(category: Category): string {
  return category ? category.fullLabel : '';
}

/**
 * getCommonColumns
 * @param {GenericObject} columnsByCategory
 * @returns {Column[]}
 */
export function getCommonColumns(columnsByCategory: GenericObject): Column[] {
  const totalCategories = size(columnsByCategory);

  // Form a single array containing all columns
  let allColumns = [];
  each(columnsByCategory, (columns: Column[]) => {
    allColumns = [...allColumns, ...columns];
  });
  if (isEmpty(allColumns)) {
    return [];
  }
  // Get the columns count
  const columnsByNameCount = {};
  allColumns.forEach((column: Column) => {
    // use attributeName in regardless of V1/V2
    if (columnsByNameCount[column.attributeName]) {
      columnsByNameCount[column.attributeName] += 1;
    } else {
      columnsByNameCount[column.attributeName] = 1;
    }
  });
  // Return only the columns with count same as the totaCategories
  return columnsByCategory[Object.keys(columnsByCategory)[0]].filter(
    (column: Column) => columnsByNameCount[column.attributeName] === totalCategories,
  );
}

/**
 * Pick all the column groups from columns definition and put columns into classification > category
 * @export
 * @param {Column[]} columns
 * @param {string} [filter='']
 * @param {Record<string, boolean>} filterValues
 * @param {Category} activeCategory
 * @param {Record<string, Category>} categoriesByCategoryId
 * @returns {EntityColumns[]}
 */
export function getColumnsByCategory(
  columns: Column[],
  filter: string = '',
  filterValues: Record<string, boolean>,
  activeCategory: Category,
  categoriesByCategoryId?: Record<string, Category>,
): EntityColumns[] {
  const OTHER_CLASSIFICATION = 'OTHER';
  const classifications = {};
  const fuzzyService = new FuzzyService();
  let fuzzyResult: FuzzyItem[] = [];
  let allColumns = [];
  if (filter && filter.length > 0) {
    fuzzyResult = fuzzyService.filterSort(filter, columns, (item: Column) => item.label || '');
    allColumns = fuzzyResult.map(
      (fuzzyItem: FuzzyItem) =>
        new Column({
          ...fuzzyItem.original,
          styledString: fuzzyItem.styledString,
        }),
    );
  } else {
    allColumns = sortBy(columns, 'label');
  }

  allColumns = applyColumnFilters(allColumns, filterValues);
  allColumns.forEach((col: Column) => {
    const classification = col.classification;
    // classification is part of V2 API. V1 API has classications (with 's') due to which there is an error here
    if (!classification) {
      return [];
    }
    if (!classifications[classification.name]) {
      classifications[classification.name] = new EntityColumns({
        ...classification,
      });
    }
    classifications[classification.name].columns.push(col);
  });
  const otherClassification = classifications[OTHER_CLASSIFICATION];
  delete classifications[OTHER_CLASSIFICATION]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
  const customOrders: EntityColumns[] = sortBy(classifications, 'name');
  if (otherClassification) {
    customOrders.push(otherClassification);
  }
  return isEmpty(customOrders) && categoriesByCategoryId
    ? groupColumnsByCategory(columns, categoriesByCategoryId, filter, filterValues, activeCategory)
    : sortForBaseCategory(activeCategory, customOrders).entityColumnsList;
}

/**
 * getFilterColumns
 * @param {Column[]} columns
 * @returns {Column[]}
 */
export function getFilterColumns(columns: Column[] = []): Column[] {
  return columns.filter((column: Column) => !column.isHiddenInFilter);
}

/**
 * getTableColumns
 * @param {Column[]} columns
 * @returns {Column[]}
 */
export function getTableColumns(columns: Column[] = []): Column[] {
  return columns.filter((column: Column) => !column.isHiddenInColumnSelector);
}

/**
 * getGroupByColumns
 * @param {Column[]} columns
 * @returns {Column[]}
 */
export function getGroupByColumns(columns: Column[] = []): Column[] {
  return columns.filter((column: Column) => !column.isHiddenInGroupBy);
}

/**
 * filterColumnsByAttributeNames
 * @param {Column} columns
 * @param {string[]} allowedColumnNames
 * @returns {Column[]}
 */
export function filterColumnsByAttributeNames(columns: Column[], allowedColumnNames: string[]) {
  if (!allowedColumnNames) {
    return columns;
  }
  const availableNamesIndex = keyBy(allowedColumnNames);
  return columns.filter((column: Column) => availableNamesIndex[column.attributeName]);
}

/**
 * filterColumnsByNames
 * @param {Column} columns
 * @param {string[]} allowedColumnNames
 * @returns {Column[]}
 */
export function filterColumnsByNames(columns: Column[], allowedColumnNames: string[]) {
  if (isEmpty(columns)) {
    return [];
  }
  if (!allowedColumnNames) {
    return columns;
  }
  const availableNamesIndex = keyBy(allowedColumnNames);
  return uniqBy(
    columns.filter((column: Column) => availableNamesIndex[column.name]),
    'name',
  );
}

/**
 * applyColumnFilters
 * @param {Column[]} columns
 * @param {Record<string, boolean>} filterValues
 * @returns {Column[]}
 */
export function applyColumnFilters(columns: Column[], filterValues: Record<string, boolean>): Column[] {
  if (filterValues[COLUMN_FILTERS.NORMALIZED]) {
    return columns.filter((column: Column) => column.isNormalized);
  }
  return columns;
}

/**
 * columnsToLookupVariables
 * @param {Column[]} columns
 * @returns {LookupVariable[]}
 */
export function columnsToLookupVariables(columns: Column[]): LookupVariable[] {
  return columns.map(columnToLookupVariable);
}

/**
 * columnToLookupVariable
 * @param {Column} column
 * @returns {LookupVariable}
 */
export function columnToLookupVariable(column: Column): LookupVariable {
  return {
    name: `\${${column.attributeName}}`,
    label: column.label,
    description: column.description,
  };
}

/**
 * Group categories by integration type
 * @export
 * @param {Category[]} categories
 * @returns {IntegrationCategories[]}
 */
export function groupCategoriesByIntegration(categories: Category[]): IntegrationCategories[] {
  const groupedCategories = groupBy(categories, (category: Category) => category.integration.name);
  return map(
    groupedCategories,
    (integrationCategories: Category[]) =>
      ({
        integration: integrationCategories[0].integration,
        categories: integrationCategories,
      }) as IntegrationCategories,
  );
}

/**
 * returns Subgroup Config
 * @export
 * @param {IntegrationCategories[]} integrationCategories
 * @returns {FuzzySubgroupConfig}
 */
export function getSubgroupConfig(integrationCategories: IntegrationCategories[]): FuzzySubgroupConfig {
  return {
    getKey: (searchItem: any) => searchItem.integration,
    subgroups: map(integrationCategories, (category: IntegrationCategories) => {
      return {
        key: category.integration.name,
        title: category.integration.label,
      } as FuzzySubgroup;
    }),
  } as FuzzySubgroupConfig;
}

/**
 * getAppSearchSubgroupConfig
 * @export
 * @param {IntegrationCategories[]} integrationCategories
 * @param {boolean} isEmployeeExperienceAppDetailsEnabled
 * @returns {FuzzySubgroupConfig}
 */
export function getAppSearchSubgroupConfig(
  integrationCategories: IntegrationCategories[],
  isEmployeeExperienceAppDetailsEnabled: boolean = true,
): FuzzySubgroupConfig {
  const subgroups = getSubgroupConfig(integrationCategories).subgroups;
  return {
    getKey: (searchItem: any) => searchItem.integration,
    subgroups: subgroups.filter((subgroup: FuzzySubgroup) => {
      return isEmployeeExperienceAppDetailsEnabled || subgroup.key !== Integration.EMPLOYEE_EXPERIENCE;
    }),
  } as FuzzySubgroupConfig;
}

/**
 * Pick all the column groups from columns definition and put columns into category
 * @export
 * @param {Column[]} columns
 * @param {CategoryIndex} categoriesById
 * @param {string} [filter='']
 * @param {Record<string, boolean>} filterValues
 * @param {Category} activeCategory
 * @returns {EntityColumns[]}
 */
export function groupColumnsByCategory(
  columns: Column[],
  categoriesById: CategoryIndex,
  filter: string = '',
  filterValues: Record<string, boolean>,
  activeCategory: Category,
): EntityColumns[] {
  const columnsByEntities: Record<string, EntityColumns> = {};
  const fuzzyService = new FuzzyService();
  let fuzzyResult: FuzzyItem[] = [];
  let allColumns = [];

  if (filter && filter.length > 0) {
    fuzzyResult = fuzzyService.filterSort(filter, columns, (item) => item.label || '');
    allColumns = fuzzyResult.map(
      (fuzzyItem: FuzzyItem) =>
        new Column({
          ...fuzzyItem.original,
          styledString: fuzzyItem.styledString,
        }),
    );
  } else {
    allColumns = sortBy(columns, 'label');
  }

  allColumns = applyColumnFilters(allColumns, filterValues);
  allColumns.forEach((col: Column) => {
    const category = categoriesById[getUniqueId(col.integration, col.entity)];
    if (category && !columnsByEntities[col.entity]) {
      columnsByEntities[col.entity] = new EntityColumns({
        name: category.name,
        label: category.label,
      });
    }
    if (columnsByEntities[col.entity]) {
      columnsByEntities[col.entity].columns.push(col);
    }
  });
  const entityColumnsArray = sortBy(Object.values(columnsByEntities), 'name');
  return sortForBaseCategory(activeCategory, entityColumnsArray).entityColumnsList;
}

/**
 * selectResult
 * @export
 * @param {boolean} isCrossCategory
 * @param {T} singleCategoryResults
 * @param {T} crossCategoryResults
 * @returns {T}
 */
export function selectResult<T>(isCrossCategory: boolean, singleCategoryResults: T, crossCategoryResults: T): T {
  return isCrossCategory ? crossCategoryResults : singleCategoryResults;
}

/**
 * checkIfCategoryHasNoColumns - returns activeCategory if it does not yet have columns
 * @param {Category} activeCategory
 * @param {Map<Category, Column[]>} columnsByCategory
 * @returns {Category}
 */
export function checkIfCategoryHasNoColumns(activeCategory: Category, columnsByCategory: Map<Category, Column[]>) {
  if (activeCategory && !columnsByCategory.has(activeCategory)) {
    return activeCategory;
  }
}

/**
 * sortForBaseCategory
 * @export
 * @param {Category} baseCategory
 * @param {EntityColumns[]} entityColumnsList
 * @returns {EntityColumns[]}
 */
export function sortForBaseCategory(
  baseCategory: Category,
  entityColumnsList: EntityColumns[],
): { baseCategoryResultsFound: boolean; entityColumnsList: EntityColumns[] } {
  const label = baseCategory?.label?.toUpperCase();
  const name = baseCategory?.name?.toUpperCase();
  let baseCategoryResultsFound = false;
  const baseEntityColumn = entityColumnsList.find((entityColumns: EntityColumns) => {
    return (
      entityColumns.name === name ||
      entityColumns?.label?.toUpperCase() === label ||
      entityColumns?.columns?.[0]?.entityLabel?.toUpperCase() === label
    );
  });
  if (baseEntityColumn) {
    entityColumnsList = entityColumnsList.filter((entityColumns: EntityColumns) => entityColumns.name !== baseEntityColumn.name);
    entityColumnsList.unshift(baseEntityColumn);
    baseCategoryResultsFound = true;
  }
  return { baseCategoryResultsFound, entityColumnsList };
}

/**
 * getColumnToggleFilterMap
 * @export
 * @param {ColumnIndex} allColumnIndex
 * @returns {Record<string, ColumnToggleFilter>}
 */
export function getColumnToggleFilterMap(allColumnIndex: ColumnIndex): Record<string, ColumnToggleFilter> {
  const columnToggleFilterMap: Record<string, ColumnToggleFilter> = {};
  const allColumns: Column[] = Object.values(allColumnIndex);
  allColumns.forEach((column: Column) => {
    if (column.rawAttributeName && !allColumnIndex[column.rawAttributeName]?.isNormalized) {
      columnToggleFilterMap[column.rawAttributeName] = {
        normalizedColumn: column,
        rawDataColumn: allColumnIndex[column.rawAttributeName],
      };
    }
  });
  return columnToggleFilterMap;
}

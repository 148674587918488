/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { each, every, findKey, isEqual, mapKeys } from 'lodash-es';

import { ACCORDION_WIZARD_CONFIG_STATE, AccordionWizardConfig } from '@ws1c/intelligence-common';
import { LOAD_STATE, SolutionConfig, SolutionConfigType, SolutionStepGroup } from '@ws1c/intelligence-models';
import { SolutionSetupActions } from './solution-setup.actions';
import { initialSolutionSetupState, SolutionSetupState } from './solution-setup.state';

const _reducer: ActionReducer<SolutionSetupState> = createReducer(
  initialSolutionSetupState,

  on(
    SolutionSetupActions.loadSolutionStepConfig,
    (
      state: SolutionSetupState,
      { solutionConfigType }: ReturnType<typeof SolutionSetupActions.loadSolutionStepConfig>,
    ): SolutionSetupState => ({
      ...state,
      setupConfigLoadStatusByConfigType: {
        ...state.setupConfigLoadStatusByConfigType,
        [solutionConfigType]: LOAD_STATE.IN_FLIGHT,
      },
    }),
  ),

  on(
    SolutionSetupActions.loadSolutionStepConfigFailure,
    (
      state: SolutionSetupState,
      { solutionConfigType }: ReturnType<typeof SolutionSetupActions.loadSolutionStepConfigFailure>,
    ): SolutionSetupState => ({
      ...state,
      setupConfigLoadStatusByConfigType: {
        ...state.setupConfigLoadStatusByConfigType,
        [solutionConfigType]: LOAD_STATE.FAILURE,
      },
    }),
  ),

  on(
    SolutionSetupActions.setAccordionWizardConfig,
    (state: SolutionSetupState, props: ReturnType<typeof SolutionSetupActions.setAccordionWizardConfig>): SolutionSetupState => ({
      ...state,
      accordionWizardConfigsById: {
        ...state.accordionWizardConfigsById,
        [props.accordionWizardId]: props.accordionWizardConfig,
      },
    }),
  ),

  on(
    SolutionSetupActions.loadSolutionStepConfigSuccess,
    (state: SolutionSetupState, props: ReturnType<typeof SolutionSetupActions.loadSolutionStepConfigSuccess>): SolutionSetupState => {
      const { type, config } = props.solutionStepConfig ?? {};
      const stepNamesByGroupName = SolutionConfig.stepNamesByGroupNameBySolutionConfigType[type];
      const wizardConfigsByName = {};
      each(config, (stepGroup: Record<string, ACCORDION_WIZARD_CONFIG_STATE>, stepGroupName: string) => {
        if (every(stepGroup, (stepState: string) => stepState === ACCORDION_WIZARD_CONFIG_STATE.NOT_STARTED)) {
          return;
        }
        const stepNames = stepNamesByGroupName?.[stepGroupName];
        if (!stepNames) {
          return;
        }
        const wizardConfig = new AccordionWizardConfig({
          openStepIndices: new Set<number>([0]),
          enabledStepIndices: new Set<number>([0]),
          finishedStepIndices: new Set<number>(),
          stepCount: stepNames.length,
        });
        // check for CVE review_settings step, set only windows required step
        if (type === SolutionConfigType.CVE && stepGroupName === SolutionStepGroup.REVIEW_SETTINGS) {
          wizardConfig.stepCount = 1;
        }
        each(stepGroup, (stepState: ACCORDION_WIZARD_CONFIG_STATE, stepName: string) => {
          const index = stepNames.indexOf(stepName);
          if (index === -1) {
            return;
          }
          if (stepState === ACCORDION_WIZARD_CONFIG_STATE.COMPLETED) {
            wizardConfig.finishedStepIndices.add(index);
            wizardConfig.enabledStepIndices.add(index);
            wizardConfig.enabledStepIndices.add(index + 1);
            wizardConfig.openStepIndices.delete(index);
            wizardConfig.openStepIndices.add(index + 1);
          }
        });
        wizardConfigsByName[stepGroupName] = wizardConfig;
      });

      const wizardConfigsById = mapKeys(wizardConfigsByName, (wizardConfig: AccordionWizardConfig, stepName: string) => {
        return findKey(SolutionConfig.stepGroupAndSolutionConfigTypeByWizardId, (path: string[]) => {
          return isEqual(path, [type, stepName]);
        });
      });

      return {
        ...state,
        accordionWizardConfigsById: {
          ...state.accordionWizardConfigsById,
          ...wizardConfigsById,
        },
        userTouchDetailsByConfigType: {
          ...state.userTouchDetailsByConfigType,
          [type]: props.solutionStepConfig.getUserTouchDetails(),
        },
        setupConfigLoadStatusByConfigType: {
          ...state.setupConfigLoadStatusByConfigType,
          [type]: LOAD_STATE.SUCCESS,
        },
      };
    },
  ),
);

/**
 * solutionSetupState
 * @param {SolutionSetupState} state
 * @param {Action} action
 * @returns {SolutionSetupState}
 */
export function solutionSetupState(state: SolutionSetupState, action: Action): SolutionSetupState {
  return _reducer(state, action);
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { LOAD_STATE } from '@ws1c/intelligence-models';

import { PreviewCommonActions } from './preview-common.actions';
import { initialPreviewCommonState, PreviewCommonState } from './preview-common.store';

const _reducer: ActionReducer<PreviewCommonState, Action> = createReducer(
  initialPreviewCommonState,

  on(
    PreviewCommonActions.loadWidgetPreview,
    (state: PreviewCommonState): PreviewCommonState => ({
      ...state,
      widgetPreviewLoadStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    PreviewCommonActions.loadWidgetPreviewSuccess,
    (
      state: PreviewCommonState,
      { widgetPreview }: ReturnType<typeof PreviewCommonActions.loadWidgetPreviewSuccess>,
    ): PreviewCommonState => ({
      ...state,
      widgetPreview,
      widgetPreviewLoadStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    PreviewCommonActions.loadWidgetPreviewFailure,
    (state: PreviewCommonState): PreviewCommonState => ({
      ...state,
      widgetPreviewLoadStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    PreviewCommonActions.clearWidgetPreview,
    (state: PreviewCommonState): PreviewCommonState => ({
      ...state,
      widgetPreview: undefined,
      widgetPreviewLoadStatus: LOAD_STATE.NONE,
    }),
  ),

  on(
    PreviewCommonActions.loadReportPreview,
    (state: PreviewCommonState, { template, request }: ReturnType<typeof PreviewCommonActions.loadReportPreview>): PreviewCommonState => ({
      ...state,
      reportPreviewLoadStatus: LOAD_STATE.IN_FLIGHT,
      reportPreviewColumnNames: request?.fields ?? template?.columnNames ?? [],
    }),
  ),

  on(
    PreviewCommonActions.loadReportPreviewSuccess,
    (
      state: PreviewCommonState,
      { reportPreview }: ReturnType<typeof PreviewCommonActions.loadReportPreviewSuccess>,
    ): PreviewCommonState => ({
      ...state,
      reportPreview,
      reportPreviewLoadStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    PreviewCommonActions.loadReportPreviewFailure,
    (state: PreviewCommonState): PreviewCommonState => ({
      ...state,
      reportPreviewLoadStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    PreviewCommonActions.clearReportPreview,
    (state: PreviewCommonState): PreviewCommonState => ({
      ...state,
      reportPreview: undefined,
      reportPreviewColumnNames: [],
      reportPreviewLoadStatus: LOAD_STATE.NONE,
    }),
  ),

  on(
    PreviewCommonActions.loadAutomationPreview,
    (state: PreviewCommonState): PreviewCommonState => ({
      ...state,
      automationPreviewLoadStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    PreviewCommonActions.loadAutomationPreviewSuccess,
    (
      state: PreviewCommonState,
      { automationPreview }: ReturnType<typeof PreviewCommonActions.loadAutomationPreviewSuccess>,
    ): PreviewCommonState => ({
      ...state,
      automationPreview,
      automationPreviewLoadStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    PreviewCommonActions.loadAutomationPreviewFailure,
    (state: PreviewCommonState): PreviewCommonState => ({
      ...state,
      automationPreviewLoadStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    PreviewCommonActions.clearAutomationPreview,
    (state: PreviewCommonState): PreviewCommonState => ({
      ...state,
      automationPreview: undefined,
      automationPreviewLoadStatus: LOAD_STATE.NONE,
    }),
  ),

  on(
    PreviewCommonActions.setReportPreviewColumnNames,
    (
      state: PreviewCommonState,
      { columnNames }: ReturnType<typeof PreviewCommonActions.setReportPreviewColumnNames>,
    ): PreviewCommonState => ({
      ...state,
      reportPreviewColumnNames: columnNames,
    }),
  ),
);

/**
 * Creates and returns previewCommonState
 * @export
 * @param {PreviewCommonState} state - Holds state object of type PreviewCommonState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {PreviewCommonState}
 */
export function previewCommonState(state: PreviewCommonState, action: Action): PreviewCommonState {
  return _reducer(state, action);
}

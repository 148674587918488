/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter, enumConverterFactory, UserDetails } from '@ws1c/intelligence-models';
import { DeemSyntheticURLMonitoringConfigIPVersion } from './deem-synthetic-url-monitoring-config-ip-version.enum';
import { DeemSyntheticURLMonitoringConfigState } from './deem-synthetic-url-monitoring-config-state.enum';
import { DeemSyntheticURLMonitoringConfigType } from './deem-synthetic-url-monitoring-config-type.enum';
import { DeemSyntheticURLMonitoringPlatform } from './deem-synthetic-url-monitoring-platform.enum';
import { DeemSyntheticURLMonitoringProtocolConfig } from './deem-synthetic-url-monitoring-protocol-config.model';
import { DeemSyntheticUrlMonitoringSmartGroup } from './deem-synthetic-url-monitoring-smart-group.model';
import { DeemSyntheticURLMonitoringTestType } from './deem-synthetic-url-monitoring-test-type.enum';

/**
 * DeemSyntheticURLMonitoringConfig
 * @export
 * @class DeemSyntheticURLMonitoringConfig
 */
@Serializable
export class DeemSyntheticURLMonitoringConfig {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'state', customConverter: enumConverterFactory(DeemSyntheticURLMonitoringConfigState) })
  public state: DeemSyntheticURLMonitoringConfigState = undefined;

  @JsonProperty({ name: 'config_type', customConverter: enumConverterFactory(DeemSyntheticURLMonitoringConfigType) })
  public configType: DeemSyntheticURLMonitoringConfigType = DeemSyntheticURLMonitoringConfigType.SYNTHETIC_URL_MONITORING;

  @JsonProperty('address')
  public address: string = undefined;

  @JsonProperty({ name: 'platform', customConverter: enumConverterFactory(DeemSyntheticURLMonitoringPlatform) })
  public platform: DeemSyntheticURLMonitoringPlatform = undefined;

  @JsonProperty({ name: 'ip_version', customConverter: enumConverterFactory(DeemSyntheticURLMonitoringConfigIPVersion) })
  public ipVersion: DeemSyntheticURLMonitoringConfigIPVersion = undefined;

  @JsonProperty({ name: 'assignment_groups', cls: DeemSyntheticUrlMonitoringSmartGroup })
  public assignmentGroups: DeemSyntheticUrlMonitoringSmartGroup[] = undefined;

  @JsonProperty({ name: 'test', cls: DeemSyntheticURLMonitoringProtocolConfig })
  public test: DeemSyntheticURLMonitoringProtocolConfig = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'test_type', customConverter: enumConverterFactory(DeemSyntheticURLMonitoringTestType) })
  public testType: DeemSyntheticURLMonitoringTestType = undefined;

  @JsonProperty('profile_id')
  public profileId: number = 0;

  /**
   * Creates an instance of DeemSyntheticURLMonitoringConfig.
   * @param {Array<Partial<DeemSyntheticURLMonitoringConfig>>} args
   * @memberof DeemSyntheticURLMonitoringConfig
   */
  constructor(...args: Array<Partial<DeemSyntheticURLMonitoringConfig>>) {
    Object.assign(this, ...args);
  }
}

/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import {
  BarHorizontal2DComponent,
  BarHorizontalStackedComponent,
  BarVertical2DComponent,
  BarVerticalStackedComponent,
  BaseChartComponent,
  DataItem,
} from '@dpa/ui-common';

import { ChartDrilldownEvent, NgxChart, NgxSingleData } from '@ws1c/intelligence-models';

/**
 * GroupedBaseChartComponent
 * @export
 * @class GroupedBaseChartComponent
 * @extends {BaseChartComponent}
 * @implements {OnChanges}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'dpa-grouped-base-chart',
  template: '<div></div>',
})
export class GroupedBaseChartComponent extends BaseChartComponent implements OnChanges, AfterViewInit {
  @Input() public ngxChart: NgxChart;
  @Input() public showDetailsLink?: boolean = false;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public xAxisLabel: string;
  @Input() public yAxisLabel?: string;
  @Input() public yScaleMax?: number;
  @Input() public showXAxis?: boolean = true;
  @Input() public showYAxis?: boolean = true;
  @Input() public showDataLabel?: boolean = true;
  @Input() public maxXAxisTickLength?: number;
  @Input() public showXAxisLabel?: boolean = true;
  @Input() public showYAxisLabel?: boolean = true;
  @Input() public rotateXAxisTicks?: boolean = false;
  @Input() public isStacked?: boolean = true;
  @Input() public legend: boolean = false;
  @Input() public gradient: boolean;
  @Input() public xAxis: boolean;
  @Input() public yAxis: boolean;
  @Input() public trimXAxisTicks: boolean = true;
  @Input() public trimYAxisTicks: boolean = true;
  @Input() public xAxisTickFormatting: any;
  @Input() public yAxisTickFormatting: any;
  @Input() public dataLabelFormatting: any;

  @Output() public viewDetails = new EventEmitter<ChartDrilldownEvent>();

  @ViewChild('chart') public chart:
    | BarVertical2DComponent
    | BarVerticalStackedComponent
    | BarHorizontal2DComponent
    | BarHorizontalStackedComponent;

  public data: NgxSingleData[];

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof GroupedBaseChartComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    // make sure upon chartData changes
    if (changes?.ngxChart?.previousValue?.chartData) {
      this.data = [...this.ngxChart.chartData];
    }
  }

  /**
   * ngAfterViewInit
   * @memberof GroupedBaseChartComponent
   */
  public ngAfterViewInit() {
    if (!this.ngxChart) {
      return;
    }
    // Workaround for data label overflow must manually trigger update as per:
    // https://github.com/swimlane/ngx-charts/issues/474#issuecomment-333860410
    this.data = [...this.ngxChart.chartData];
  }

  /**
   * onSelect
   * @param {DataItem} ngxSelectEvent
   * @memberof GroupedBaseChartComponent
   */
  public onSelect(ngxSelectEvent: DataItem) {
    if (this.showDetailsLink) {
      const drilldownEvent: ChartDrilldownEvent = this.ngxChart.drilldownEventBuilder.getEvent(ngxSelectEvent);
      this.viewDetails.emit(drilldownEvent);
    }
  }

  /**
   * onResize
   * @memberof GroupedBaseChartComponent
   */
  public onResize() {
    this.chart?.update();
  }
}

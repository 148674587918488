/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSyntheticURLMonitoringConfigIPVersion
 * @enum {string}
 * @export
 */
export enum DeemSyntheticURLMonitoringConfigIPVersion {
  IPV4 = 'IPV4',
  IPV6 = 'IPV6',
  Auto = 'Auto',
}

/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';

import { I18NService } from '@ws1c/intelligence-common/services';

/**
 * RiskScoreTooltipComponent
 * @export
 * @class RiskScoreTooltipComponent
 */
@Component({
  selector: 'dpa-risk-score-tooltip',
  templateUrl: 'risk-score-tooltip.component.html',
  styleUrls: ['risk-score-tooltip.component.scss'],
})
export class RiskScoreTooltipComponent implements OnInit {
  @Input() public scoreType: string;
  @Input() public scoreName?: string;
  public scoreDefinition: string;

  /**
   * constructor
   * @param {I18NService} i18NService
   * @memberof RiskScoreTooltipComponent
   */
  constructor(private i18NService: I18NService) {}

  /**
   * ngOnInit
   * @memberof RiskScoreTooltipComponent
   */
  public ngOnInit() {
    /*
      STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DEFINITION.APPCOLLECTOR
      STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DEFINITION.COMPDOWNLOAD
      STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DEFINITION.LAGGUPDATE
      STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DEFINITION.RAREAPPCOLLECTOR
      STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DEFINITION.RISKYSETTINGS
      STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DEFINITION.UNUSUALAPP
    */
    const i18nKey = 'STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DEFINITION.' + this.scoreType.toUpperCase();
    const translation = this.i18NService.translate(i18nKey);
    if (translation !== i18nKey) {
      this.scoreDefinition = translation;
    }
  }
}

/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { LineChartComponent } from '@dpa/ui-common';

import { BaseChartComponent } from '@ws1c/dashboard-common/chart/base-chart.component';
import { Marker, MarkerContext } from '@ws1c/dashboard-common/chart/marker/types';
import { ChartDrilldownEvent, NameValue, NgxChart, WidgetSequence } from '@ws1c/intelligence-models';

/**
 * UnstackedLineChartComponent
 * @export
 * @class UnstackedLineChartComponent
 * @extends {BaseChartComponent}
 */
@Component({
  selector: 'dpa-unstacked-line-chart',
  templateUrl: 'unstacked-line-chart.component.html',
  styleUrls: ['unstacked-line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnstackedLineChartComponent extends BaseChartComponent {
  @Input() public ngxChart: NgxChart;
  @Input() public overlayChart: NgxChart;
  @Input() public showDetailsLink?: boolean = false;
  @Input() public isPercentage?: boolean = false;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public seriesTooltip?: TemplateRef<any>;
  @Input() public yAxisLabel?: string;
  @Input() public isSpark?: boolean = false;
  @Input() public rotateXAxisTicks?: boolean = false;
  @Input() public showXAxis?: boolean = true;
  @Input() public showYAxis?: boolean = true;
  @Input() public referenceLines?: Array<NameValue<number>>;
  @Input() public showRefLines?: boolean = false;
  @Input() public showRefLabels?: boolean = true;
  @Input() public showTimeline?: boolean = false;
  @Input() public markers?: Marker[];
  @Input() public markerTooltipTemplate: TemplateRef<any>;
  @Input() public yScaleMax?: number;
  @Input() public yScaleMin?: number;
  @Input() public isRanged?: boolean = false;
  @Input() public trimXAxisTicks?: boolean = true;
  @Input() public yAxisTickFormatting?: any;
  @Input() public yAxisTicks?: any[];
  @Input() public focussedSequence: WidgetSequence;
  @Input() public overlayTooltipTemplate: TemplateRef<any>;
  @Output() public onViewDetails = new EventEmitter<ChartDrilldownEvent>();
  @Output() public onMarkerClick: EventEmitter<MarkerContext> = new EventEmitter<MarkerContext>();

  @ViewChild(LineChartComponent, { static: true }) public chart: LineChartComponent;

  /**
   * onSelect
   * @param {any} ngxSelectEvent
   * @memberof UnstackedLineChartComponent
   */
  public onSelect(ngxSelectEvent: any) {
    if (this.showDetailsLink) {
      const drilldownEvent: ChartDrilldownEvent = this.ngxChart.drilldownEventBuilder.getEvent(ngxSelectEvent);
      this.onViewDetails.emit(drilldownEvent);
    }
  }

  /**
   * onResize
   * @memberof UnstackedLineChartComponent
   */
  public onResize() {
    if (!this.chart) {
      return;
    }
    this.chart.update();
  }

  /**
   * handleMarkerClick
   * @param {MarkerContext} event
   * @memberof UnstackedLineChartComponent
   */
  public handleMarkerClick(event: MarkerContext) {
    this.onMarkerClick.emit(event);
  }
}

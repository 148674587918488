/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, PagedRequest, SortOn } from '@dpa/ui-common';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { isUndefined, keyBy, orderBy, some } from 'lodash-es';

import { AppLoadsSelectors } from '@dpa-shared-merlot/store/app-loads/app-loads.selectors';
import { AppsDashboardSelectors } from '@dpa-shared-merlot/store/dashboard/apps/apps-dashboard.selectors';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { getOffsetCounterData } from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import { DashboardState } from '@ws1c/intelligence-core/store/dashboard/dashboard.state';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import { UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store/user-preference/user-preference-feature-controls.selectors';
import {
  AppCrashGroupRequest,
  AppCrashGroupUsersRequest,
  AppErrorCrashUploadsTableRequest,
  AppErrorType,
  AppHeGroupRequest,
  Attribute,
  Category,
  CategoryIndex,
  Column,
  ComparisonQueryResponse,
  DashboardConfig,
  Entity,
  getUniqueId,
  Integration,
  LodashSortOrder,
  PreviewReportContentRequest,
  RuleGroup,
  StandardDashboardRequest,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
  UserFlow,
  UserFlowMetric,
  UserFlowMetricsByName,
} from '@ws1c/intelligence-models';
import * as appDeploymentDashboardSelectorHelpers from './app-deployment-dashboard-selector-helpers';
import { AppsDashboardState } from './apps-dashboard.state';

export const appDashboardHelpers = {
  ...appDeploymentDashboardSelectorHelpers,
  getDefaultedTrendDate,
  getOffsetCounterData,
};

/**
 * AppDeploymentDashboardSelectors
 * @exports
 * @class AppDeploymentDashboardSelectors
 */
export class AppDeploymentDashboardSelectors {
  /**
   * getAppDashboardTrendDateRangeSamplingFrequencyTooBig
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppDashboardTrendDateRangeSamplingFrequencyTooBig: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (trendDateRange: TrendDateRange) => {
      return appDashboardHelpers.getAppDashboardTrendDateRangeSamplingFrequencyTooBig(trendDateRange);
    },
  );

  /**
   * getAppDashboardTrendDefinitionOverridesByStandardWidgetSubtype
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppDashboardTrendDefinitionOverridesByStandardWidgetSubtype: MemoizedSelector<MerlotState, GenericObject> =
    createSelector(
      AppsDashboardSelectors.getAppDashboardTrendDateRange,
      AppDeploymentDashboardSelectors.getAppDashboardTrendDateRangeSamplingFrequencyTooBig,
      (trendDateRange: TrendDateRange, isTooBig: boolean) => {
        return appDashboardHelpers.getAppDashboardTrendDefinitionOverridesByStandardWidgetSubtype(trendDateRange, isTooBig);
      },
    );

  /**
   * getIdmAppDashboardTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getIdmAppDashboardTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => {
      return appDashboardHelpers.getDefaultedTrendDate(state.idmAppDashboardTrendDateRange);
    },
  );

  /**
   * isMemoryDiagnosticAvailableState
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static isMemoryDiagnosticAvailableState: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.getAppErrorDetailType,
    (appErrorType: AppErrorType) => appErrorType !== AppErrorType.HANDLED_EXCEPTION,
  );

  /**
   * getStandardAppDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getStandardAppDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedAppName,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (
      appId: string,
      appName: string,
      apteligentId: string,
      appPlatform: string,
      appDashboardFilterRule: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
    ) =>
      appDashboardHelpers.buildStandardAppDashboardRequest(
        appId,
        appName,
        apteligentId,
        appPlatform,
        appDashboardFilterRule,
        appDashboardTrendDateRange,
      ),
  );

  /**
   * getStandardAppExperienceDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getStandardAppExperienceDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppName,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    AppLoadsSelectors.getSelectedAppPlatform,
    (
      appId: string,
      apteligentId: string,
      appName: string,
      appDashboardFilterRuleGroup: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
      platform: string,
    ) =>
      appDashboardHelpers.buildStandardAppExperienceDashboardRequest(
        appId,
        apteligentId,
        appName,
        appDashboardFilterRuleGroup,
        appDashboardTrendDateRange,
        platform,
      ),
  );

  /**
   * getStandardAppErrorDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getStandardAppErrorDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppLoadsSelectors.getAppErrorCrashId,
    AppLoadsSelectors.getAppErrorDetailType,
    AppLoadsSelectors.getAppErrorHeParams,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (
      appId: string,
      apteligentId: string,
      platform: string,
      crashGroupId: string,
      appErrorType: AppErrorType,
      heParams: any,
      appDashboardFilterRuleGroup: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
    ) =>
      appDashboardHelpers.buildStandardAppErrorDashboardRequest(
        appId,
        apteligentId,
        platform,
        crashGroupId,
        appErrorType,
        heParams,
        appDashboardFilterRuleGroup,
        appDashboardTrendDateRange,
      ),
  );

  /**
   * getStandardAppSummaryDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getStandardAppSummaryDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (appDashboardFilterRuleGroup: RuleGroup, appDashboardTrendDateRange: TrendDateRange) =>
      appDashboardHelpers.buildStandardAppSummaryDashboardRequest(appDashboardFilterRuleGroup, appDashboardTrendDateRange),
  );

  /**
   * selectUserFlowsSubFilterRuleGroup
   * @static
   * @param {MerlotState} state
   * @type {Selector<MerlotState, RuleGroup>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static selectUserFlowsSubFilterRuleGroup: Selector<MerlotState, RuleGroup> = (state: MerlotState) =>
    state.dashboardState.userFlowsSubFilterRuleGroup;

  /**
   * getUserFlowsSubFilterRuleGroup
   * @static
   * @param {MerlotState} state
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsSubFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.userFlowsSubFilterRuleGroup || new RuleGroup(),
  );

  /**
   * getStandardAppUserFlowsDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getStandardAppUserFlowsDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppDeploymentDashboardSelectors.getUserFlowsSubFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (
      appId: string,
      apteligentId: string,
      appPlatform: string,
      appDashboardFilterRuleGroup: RuleGroup,
      userFlowsSubFilterRuleGroup: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
    ) =>
      appDashboardHelpers.buildStandardAppUserFlowsDashboardRequest(
        appId,
        apteligentId,
        appPlatform,
        appDashboardFilterRuleGroup,
        userFlowsSubFilterRuleGroup,
        appDashboardTrendDateRange,
      ),
  );

  /**
   * getUserFlowsCategory
   * @static
   * @type {MemoizedSelector<MerlotState, Category>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsCategory: MemoizedSelector<MerlotState, Category> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    (availableCategories: Category[]) => {
      const targetCategoryId = getUniqueId(Integration.APTELIGENT, Entity.USER_FLOW);
      return availableCategories.find((category: Category) => category.categoryId === targetCategoryId);
    },
  );

  /**
   * getUserFlowsSubFilterColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsSubFilterColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    IntegrationMetaSelectors.getColumnsByCategory,
    AppDeploymentDashboardSelectors.getUserFlowsCategory,
    (columnsByCategory: Map<Category, Column[]>, userFlowCategory: Category) => {
      return appDashboardHelpers.getUserFlowsSubFilterColumns(columnsByCategory, userFlowCategory);
    },
  );

  /**
   * getUserFlowsAllOccurencesByState
   * @static
   * @type {MemoizedSelector<MerlotState, Trend>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsAllOccurencesByState: MemoizedSelector<MerlotState, Trend> = DashboardSelectors.getStandardDashboardByType(
    StandardWidgetSubtype.USER_FLOWS_ALL_OCCURRENCES_BY_STATE_HISTOGRAM,
  );

  /**
   * getUserFlowsCardAverageTimes
   * @static
   * @type {MemoizedSelector<MerlotState, Trend>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsCardAverageTimes: MemoizedSelector<MerlotState, Trend> = DashboardSelectors.getStandardDashboardByType(
    StandardWidgetSubtype.USER_FLOWS_OCCURRENCES_BY_NAME_AVERAGE_ACTIVE_TIME,
  );

  /**
   * getUserFlowsCards
   * @static
   * @type {MemoizedSelector<MerlotState, UserFlow[]>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsCards: MemoizedSelector<MerlotState, UserFlow[]> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getUserFlowsCards(trendsByWidgetSubtype);
    },
  );

  /**
   * getUserFlowsCardsWithAverageTimes
   * @static
   * @type {MemoizedSelector<MerlotState, UserFlow[]>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsCardsWithAverageTimes: MemoizedSelector<MerlotState, UserFlow[]> = createSelector(
    AppDeploymentDashboardSelectors.getUserFlowsCards,
    AppDeploymentDashboardSelectors.getUserFlowsCardAverageTimes,
    (userFlows: UserFlow[], userFlowsAverageTimeTrend: Trend) => {
      return appDashboardHelpers.getUserFlowsCardsWithAverageTimes(userFlows, userFlowsAverageTimeTrend);
    },
  );

  /**
   * getAppHeGroupRequest
   * @static
   * @type {MemoizedSelector<MerlotState, AppHeGroupRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppHeGroupRequest: MemoizedSelector<MerlotState, AppHeGroupRequest> = createSelector(
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getAppDashboardModifiedFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (apteligentId: string, appPlatform: string, appDashboardFilterRuleGroup: RuleGroup, appDashboardTrendDateRange: TrendDateRange) => {
      return appDashboardHelpers.buildAppHeGroupRequest(apteligentId, appPlatform, appDashboardFilterRuleGroup, appDashboardTrendDateRange);
    },
  );

  /**
   * getAppCrashGroupRequest
   * @static
   * @type {MemoizedSelector<MerlotState, AppCrashGroupRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppCrashGroupRequest: MemoizedSelector<MerlotState, AppCrashGroupRequest> = createSelector(
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getAppDashboardModifiedFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (apteligentId: string, appPlatform: string, appDashboardFilterRuleGroup: RuleGroup, appDashboardTrendDateRange: TrendDateRange) => {
      return appDashboardHelpers.buildAppCrashGroupRequest(
        apteligentId,
        appPlatform,
        appDashboardFilterRuleGroup,
        appDashboardTrendDateRange,
      );
    },
  );

  /**
   * getStandardPluginExceptionDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getStandardPluginExceptionDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (
      appId: string,
      apteligentId: string,
      appPlatform: string,
      appDashboardFilterRuleGroup: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
    ) => {
      return appDashboardHelpers.buildStandardPluginExceptionDashboardRequest$(
        appId,
        apteligentId,
        appPlatform,
        appDashboardFilterRuleGroup,
        appDashboardTrendDateRange,
      );
    },
  );

  /**
   * getStandardHandledExceptionDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getStandardHandledExceptionDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (
      appId: string,
      apteligentId: string,
      appPlatform: string,
      appDashboardFilterRuleGroup: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
    ) => {
      return appDashboardHelpers.buildStandardHandledExceptionDashboardRequest$(
        appId,
        apteligentId,
        appPlatform,
        appDashboardFilterRuleGroup,
        appDashboardTrendDateRange,
      );
    },
  );

  /**
   * getAppErrorCrashUploadsTableRequest
   * @static
   * @type {MemoizedSelector<MerlotState, AppErrorCrashUploadsTableRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppErrorCrashUploadsTableRequest: MemoizedSelector<MerlotState, AppErrorCrashUploadsTableRequest> = createSelector(
    AppLoadsSelectors.getSelectedApteligentId,
    AppsDashboardSelectors.getAppErrorCrashUploadsFilterString,
    AppsDashboardSelectors.getAppErrorCrashUploadsTablePagedRequest,
    AppsDashboardSelectors.getAppErrorCrashUploadsTableSortOns,
    (appId: string, filterString: string, pagedRequest: PagedRequest, sortOns: SortOn[]) => {
      return appDashboardHelpers.getAppErrorCrashUploadsTableRequest(appId, filterString, pagedRequest, sortOns);
    },
  );

  /**
   * getAppErrorBreadcrumbTableColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppErrorBreadcrumbTableColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    IntegrationMetaSelectors.getColumnsByCategory,
    AppLoadsSelectors.getAppErrorDetailsGroupEntity,
    UserPreferenceFeatureControlsSelectors.isWidgetJoinEnabled,
    IntegrationMetaSelectors.getCrossCategoryColumns,
    (
      categoryIndex: CategoryIndex,
      columnsByCategory: Map<Category, Column[]>,
      entity: string,
      isWidgetJoinEnabled: boolean,
      crossCategoryColumns: Record<string, Column[]>,
    ) => {
      const userFlowCategoryId = getUniqueId(Integration.APTELIGENT, Entity.USER_FLOW);
      const userFlowCategory = categoryIndex[userFlowCategoryId];
      if (!userFlowCategory) {
        return;
      }
      const userFlowColumns = isWidgetJoinEnabled ? crossCategoryColumns[userFlowCategoryId] : columnsByCategory.get(userFlowCategory);
      if (!userFlowColumns) {
        return undefined;
      }
      const userFlowColumnsByName = keyBy(userFlowColumns, 'name');
      // GROUPED_CRASH_ANDROID is not part for v1/meta call.
      // This is a hack to get around that and match the attribute name for displaying table
      return DashboardConfig.appErrorBreadcrumbColumnNames.map((columnName: string) => {
        const column = userFlowColumnsByName[columnName];
        return new Column({
          ...column,
          attribute: new Attribute({
            fullyQualifiedName: `${Integration.APTELIGENT}.${entity}.${column.name}`,
          }),
        });
      });
    },
  );

  /**
   * getUserFlowsCardsSorted
   * @static
   * @type {MemoizedSelector<MerlotState, UserFlow[]>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsCardsSorted: MemoizedSelector<MerlotState, UserFlow[]> = createSelector(
    AppDeploymentDashboardSelectors.getUserFlowsCardsWithAverageTimes,
    AppsDashboardSelectors.getUserFlowsCardsSortOn,
    (userFlows: UserFlow[], sortOn: SortOn) => {
      return orderBy(userFlows, sortOn.by, sortOn.reverse ? LodashSortOrder.DESC : LodashSortOrder.ASC);
    },
  );

  /**
   * getAppError
   * @static
   * @type {MemoizedSelector<MerlotState, any>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppError: MemoizedSelector<MerlotState, any> = createSelector(
    AppLoadsSelectors.getAppErrorDetailType,
    AppLoadsSelectors.getAppErrorHeParams,
    (appErrorType: AppErrorType, heParams: any) => {
      return {
        appErrorType,
        heParams,
      };
    },
  );

  /**
   * getAppErrorBreadcrumbRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PreviewReportContentRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppErrorBreadcrumbRequest: MemoizedSelector<MerlotState, PreviewReportContentRequest> = createSelector(
    AppDeploymentDashboardSelectors.getAppError,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    AppsDashboardSelectors.getAppDashboardModifiedFilterRuleGroup,
    AppsDashboardSelectors.getBreadcrumbTablePagedRequest,
    AppsDashboardSelectors.getBreadcrumbSortOn,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppLoadsSelectors.getAppErrorCrashId,
    (
      { appErrorType, heParams },
      trendDateRange: TrendDateRange,
      ruleGroup: RuleGroup,
      pagedRequest: PagedRequest,
      sortOns: SortOn[],
      apteligentId: string,
      platform: string,
      crashGroupId: string,
    ) => {
      return appDashboardHelpers.getAppErrorBreadcrumbRequest(
        appErrorType,
        heParams,
        trendDateRange,
        ruleGroup,
        pagedRequest,
        sortOns,
        apteligentId,
        platform,
        crashGroupId,
      );
    },
  );

  /**
   * getAppCrashUserRequest
   * @static
   * @type {MemoizedSelector<MerlotState, AppCrashGroupUsersRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppCrashUserRequest: MemoizedSelector<MerlotState, AppCrashGroupUsersRequest> = createSelector(
    AppLoadsSelectors.getAppErrorDetailType,
    AppLoadsSelectors.getSelectedApteligentId,
    AppsDashboardSelectors.getAppDashboardModifiedFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppLoadsSelectors.getAppErrorCrashId,
    AppLoadsSelectors.getAppErrorDetailsGroupEntity,
    (
      appErrorType: AppErrorType,
      apteligentId: string,
      ruleGroup: RuleGroup,
      trendDateRange: TrendDateRange,
      platform: string,
      crashGroupId: string,
      entity: string,
    ) => {
      return appDashboardHelpers.getAppCrashUserRequest(
        appErrorType,
        apteligentId,
        ruleGroup,
        trendDateRange,
        platform,
        crashGroupId,
        entity,
      );
    },
  );

  /**
   * getAppErrorStacktracePreviewRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PreviewReportContentRequest>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getAppErrorStacktracePreviewRequest: MemoizedSelector<MerlotState, PreviewReportContentRequest> = createSelector(
    AppLoadsSelectors.getAppErrorDetailType,
    AppLoadsSelectors.getAppErrorHeParams,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getAppErrorCrashId,
    AppsDashboardSelectors.getAppDashboardModifiedFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppLoadsSelectors.getAppErrorDetailsGroupEntity,
    (
      appErrorType: AppErrorType,
      heParams: any,
      apteligentId: string,
      crashGroupId: string,
      ruleGroup: RuleGroup,
      trendDateRange: TrendDateRange,
      platform: string,
      entity: string,
    ) => {
      return appDashboardHelpers.getAppErrorStacktracePreviewRequest(
        appErrorType,
        heParams,
        apteligentId,
        crashGroupId,
        ruleGroup,
        trendDateRange,
        platform,
        entity,
      );
    },
  );

  /**
   * getIdpTrendCounter
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getIdpTrendCounter: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        null,
        StandardWidgetSubtype.APP_SUMMARY_DEPLOYED_IDP_ENABLED_APPS,
        null,
      );
    },
  );

  /**
   * getDesktopTrendCounter
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getDesktopTrendCounter: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        null,
        StandardWidgetSubtype.APP_SUMMARY_DEPLOYED_DESKTOP_NATIVE_APPS,
        null,
      );
    },
  );

  /**
   * getMobileTrendCounter
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getMobileTrendCounter: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        null,
        StandardWidgetSubtype.APP_SUMMARY_DEPLOYED_MOBILE_NATIVE_APPS,
        null,
      );
    },
  );

  /**
   * getApteligentAppLoadsCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getApteligentAppLoadsCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        StandardWidgetSubtype.APTELIGENT_APP_LOADS_LAST_28_DAYS_OFFSET_1,
        StandardWidgetSubtype.APTELIGENT_APP_LOADS_LAST_28_DAYS,
      );
    },
  );

  /**
   * getApteligentMauCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getApteligentMauCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        StandardWidgetSubtype.APTELIGENT_APP_UNIQUE_USERS_LAST_28_DAYS_OFFSET_1,
        StandardWidgetSubtype.APTELIGENT_APP_UNIQUE_USERS_LAST_28_DAYS,
      );
    },
  );

  /**
   * getApteligentDauCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getApteligentDauCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        StandardWidgetSubtype.APTELIGENT_APP_UNIQUE_USERS_LAST_24_HOURS_OFFSET_1,
        StandardWidgetSubtype.APTELIGENT_APP_UNIQUE_USERS_LAST_24_HOURS,
      );
    },
  );

  /**
   * getUserFlowsCrumbListsIndex
   * @static
   * @param {MerlotState} state
   * @type {Selector<MerlotState, any>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsCrumbListsIndex: MemoizedSelector<MerlotState, any> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.userFlowsCrumbListsIndex,
  );

  /**
   * getUserFlowsDetailsMetricsBySubtype
   * @static
   * @type {MemoizedSelector<MerlotState, any>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsDetailsMetricsBySubtype: MemoizedSelector<MerlotState, any> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getUserFlowsDetailsMetricsBySubtype(trendsByWidgetSubtype);
    },
  );

  /**
   * getUserFlowsAllOccurencesMetrics
   * @static
   * @type {MemoizedSelector<MerlotState, UserFlowMetricsByName>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsAllOccurencesMetrics: MemoizedSelector<MerlotState, UserFlowMetricsByName> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getUserFlowsAllOccurencesMetrics(trendsByWidgetSubtype);
    },
  );

  /**
   * getUserFlowsOccurencesSuccessMetric
   * @static
   * @type {MemoizedSelector<MerlotState, UserFlowMetric>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsOccurencesSuccessMetric: MemoizedSelector<MerlotState, UserFlowMetric> = createSelector(
    AppDeploymentDashboardSelectors.getUserFlowsAllOccurencesMetrics,
    (userFlowMetricsByName: UserFlowMetricsByName) => userFlowMetricsByName?.[UserFlowMetric.SUCCESS],
  );

  /**
   * getUserFlowsOccurencesFailureMetric
   * @static
   * @type {MemoizedSelector<MerlotState, UserFlowMetric>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsOccurencesFailureMetric: MemoizedSelector<MerlotState, UserFlowMetric> = createSelector(
    AppDeploymentDashboardSelectors.getUserFlowsAllOccurencesMetrics,
    (userFlowMetricsByName: UserFlowMetricsByName) => userFlowMetricsByName?.[UserFlowMetric.FAILURE],
  );

  /**
   * getUserFlowsOccurencesMetric
   * @static
   * @type {MemoizedSelector<MerlotState, UserFlowMetric>}
   * @memberof AppDeploymentDashboardSelectors
   */
  public static getUserFlowsOccurencesMetric: MemoizedSelector<MerlotState, UserFlowMetric> = createSelector(
    AppDeploymentDashboardSelectors.getUserFlowsOccurencesSuccessMetric,
    AppDeploymentDashboardSelectors.getUserFlowsOccurencesFailureMetric,
    (successMetric: UserFlowMetric, failureMetric: UserFlowMetric) => {
      if (some([successMetric, failureMetric], isUndefined)) {
        return undefined;
      }
      return Object.assign(new UserFlowMetric(), {
        count: successMetric.count + failureMetric.count,
        previousCount: successMetric.previousCount + failureMetric.previousCount,
      });
    },
  );
}

<div
  (resized)="onResize()"
  class="chart-container"
>
  <ngx-charts-bubble-chart
    #chart
    [scheme]="ngxChart.defaultColorScheme"
    [results]="ngxChart.bubbleData"
    [legend]="false"
    [xAxisLabel]="ngxChart.labels.secondGroupBy"
    [yAxisLabel]="yAxisLabel"
    [xAxis]="true"
    [yAxis]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="!!yAxisLabel"
    [customColors]="ngxChart.customColors"
    [animations]="true"
    [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
    [xAxisTickFormatting]="ngxChart.dateFormatterAxis"
    [roundDomains]="true"
    [maxRadius]="maxRadius"
    [minRadius]="minRadius"
    [autoScale]="true"
    (select)="onSelect($event)"
  >
    <ng-template
      #tooltipTemplate
      let-model="model"
    >
      <ng-template
        *ngIf="model"
        [ngTemplateOutlet]="stackedTooltip"
        [ngTemplateOutletContext]="{
          ngxModel: model,
          tooltip: ngxChart.getTooltip(model, 'Devices')
        }"
      ></ng-template>
    </ng-template>
    <ng-template
      #seriesTooltipTemplate
      let-model="model"
    >
      <ng-template
        *ngIf="model"
        [ngTemplateOutlet]="seriesTooltip"
        [ngTemplateOutletContext]="{ usingFakeGroupBy: ngxChart.usingFakeGroupBy, tooltipItems: model }"
      ></ng-template>
    </ng-template>
  </ngx-charts-bubble-chart>
</div>

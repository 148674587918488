/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { DateTimeFormat, GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import moment from 'moment';

import { AppConfig } from '@ws1c/intelligence-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { AcceptTrial, ALERT_BANNER_TYPE, Org, TrialBanner, TrialBannerState, UIPreference } from '@ws1c/intelligence-models';
import { UserPreferenceCommonSelectors } from './user-preference-common.selectors';
import { UserPreferenceUIPreferenceSelectors } from './user-preference-ui-preference.selectors';
import { UserPreferenceState } from './user-preference.state';

/**
 * UserPreferenceTrialInfoSelectors
 *
 * @export
 * @class UserPreferenceTrialInfoSelectors
 */
export class UserPreferenceTrialInfoSelectors {
  /**
   * trialUserContactDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, AcceptTrial>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static trialUserContactDetails: MemoizedSelector<CoreAppState, AcceptTrial> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state?.trialUserContactDetails,
  );

  /**
   * isFetchingTrialUserContactDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isFetchingTrialUserContactDetails: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state?.isFetchingTrialUserContactDetails,
  );

  /**
   * getOrgTrialBannerState
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, TrialBannerState>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static getOrgTrialBannerState: MemoizedSelector<CoreAppState, TrialBannerState> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state?.trialBannerState,
  );

  /**
   * getOrgTrialBannerInfo
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, TrialBanner>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static getOrgTrialBannerInfo: MemoizedSelector<CoreAppState, TrialBanner> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org?.trialBanner,
  );

  /**
   * getTrialBannerAlertType
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static getTrialBannerAlertType: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerState,
    (trialBanner: TrialBannerState) => {
      if ([TrialBannerState.EXPIRED, TrialBannerState.ALMOST_EXPIRED].includes(trialBanner)) {
        return ALERT_BANNER_TYPE.DANGER;
      }
      return ALERT_BANNER_TYPE.INFO;
    },
  );

  /**
   * isTrialBannerStarted
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialBannerStarted: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerState,
    (trialBannerState: TrialBannerState) => TrialBannerState.STARTED === trialBannerState,
  );

  /**
   * isTrialBannerExpired
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialBannerExpired: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerState,
    (trialBannerState: TrialBannerState) => trialBannerState === TrialBannerState.EXPIRED,
  );

  /**
   * isTrialBannerDeclined
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialBannerDeclined: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerState,
    (trialBannerState: TrialBannerState) => {
      return [TrialBannerState.DECLINED, TrialBannerState.DECLINED_DISMISSED].includes(trialBannerState);
    },
  );

  /**
   * isTrialBannerCountingDown
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialBannerCountingDown: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerState,
    (trialBannerState: TrialBannerState) => {
      return [TrialBannerState.COUNTDOWN, TrialBannerState.ALMOST_EXPIRED].includes(trialBannerState);
    },
  );

  /**
   * isTrialBannerAlmostExpired
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialBannerAlmostExpired: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerState,
    (trialBannerState: TrialBannerState) => trialBannerState === TrialBannerState.ALMOST_EXPIRED,
  );

  /**
   * isTrialNotActive
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialNotActive: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerInfo,
    (trialBanner: TrialBanner) => trialBanner?.showBanner && !trialBanner.active,
  );

  /**
   * isTrialApplicable
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialApplicable: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerInfo,
    (trialBanner: TrialBanner) => trialBanner?.showBanner,
  );

  /**
   * isTrialApplicableAndActiveOrExpired
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialApplicableAndActiveOrExpired: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerInfo,
    (trialBanner: TrialBanner) => trialBanner?.showBanner && (trialBanner?.active || trialBanner?.expired),
  );

  /**
   * isTrialExpired
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialExpired: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerInfo,
    (trialBanner: TrialBanner) => trialBanner?.showBanner && trialBanner.expired,
  );

  /**
   * getTrialDuration
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static getTrialDuration: MemoizedSelector<CoreAppState, number> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerInfo,
    (trialBanner: TrialBanner) => {
      const startDateMillis: number = trialBanner?.startDate;
      const expirationDateMillis: number = trialBanner?.expiration;
      return startDateMillis && expirationDateMillis
        ? moment(expirationDateMillis).diff(moment(startDateMillis), 'days')
        : AppConfig.TRIAL_DAYS;
    },
  );

  /**
   * getExpiresDays
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static getExpiresDays: MemoizedSelector<CoreAppState, number> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerInfo,
    (trialBanner: TrialBanner) => {
      const expirationDateMillis: number = trialBanner?.expiration;
      return expirationDateMillis ? moment(expirationDateMillis).diff(moment(), 'days') : null;
    },
  );

  /**
   * isTrialExpiredBannerHidden
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialExpiredBannerHidden: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (uiPreferences: GenericObject) => uiPreferences[UIPreference.EXPIRED_BANNER_HIDDEN] ?? false,
  );

  /**
   * getTrialBannerSnoozeStartTime
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static getTrialBannerSnoozeStartTime: MemoizedSelector<CoreAppState, number> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (uiPreferences: GenericObject) => uiPreferences[UIPreference.TRIAL_BANNER_SNOOZE_START_TIME],
  );

  /**
   * isTrialBannerHidden
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceTrialInfoSelectors
   */
  public static isTrialBannerHidden: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceTrialInfoSelectors.getOrgTrialBannerState,
    UserPreferenceTrialInfoSelectors.isTrialExpiredBannerHidden,
    UserPreferenceTrialInfoSelectors.getTrialBannerSnoozeStartTime,
    (trialBannerState: TrialBannerState, isTrialExpiredBannerHidden: boolean, trialBannerSnoozeStartTime: number) => {
      if (trialBannerState === TrialBannerState.EXPIRED) {
        return isTrialExpiredBannerHidden;
      }
      if ([TrialBannerState.HIDDEN, TrialBannerState.DECLINED_DISMISSED].includes(trialBannerState)) {
        return true;
      }
      // Check trialBannerSnoozeStartTime only if the state is not EXPIRED, HIDDEN & DECLINED_DISMISSED
      if (trialBannerSnoozeStartTime) {
        const snoozedHoursCount = moment().diff(moment(trialBannerSnoozeStartTime), DateTimeFormat.HOURS);
        return snoozedHoursCount < AppConfig.TRIAL_BANNER_SNOOZE_TIME_IN_HOURS;
      }
      return false;
    },
  );
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Color, HeatMapComponent } from '@dpa/ui-common';

import { BaseChartComponent } from '@ws1c/dashboard-common/chart/base-chart.component';
import { ChartDrilldownEvent, DashboardConfig, NgxChart } from '@ws1c/intelligence-models';

/**
 * HeatMapChartComponent
 * @export
 * @class HeatMapChartComponent
 * @extends {BaseChartComponent}
 */
@Component({
  selector: 'dpa-heat-map-chart',
  templateUrl: 'heat-map-chart.component.html',
  styleUrls: ['heat-map-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeatMapChartComponent extends BaseChartComponent {
  @Input() public ngxChart: NgxChart;
  @Input() public showDetailsLink?: boolean = false;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public seriesTooltip?: TemplateRef<any>;
  @Input() public yAxisLabel?: string;
  @Input() public showXAxis?: boolean = true;
  @Input() public showYAxis?: boolean = true;
  @Output() public onViewDetails = new EventEmitter<ChartDrilldownEvent>();

  @ViewChild(HeatMapComponent, { static: true }) public chart: HeatMapComponent;

  public readonly colorScheme: Color = {
    domain: DashboardConfig.HEAT_MAP_COLORS,
  } as Color;

  /**
   * onSelect
   * @param {any} ngxSelectEvent
   * @memberof HeatMapChartComponent
   */
  public onSelect(ngxSelectEvent: any) {
    if (this.showDetailsLink) {
      const drilldownEvent: ChartDrilldownEvent = this.ngxChart.drilldownEventBuilder.getEvent(ngxSelectEvent);
      this.onViewDetails.emit(drilldownEvent);
    }
  }

  /**
   * onResize
   * @memberof HeatMapChartComponent
   */
  public onResize() {
    if (!this.chart) {
      return;
    }
    this.chart.update();
  }
}

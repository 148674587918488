/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { AlertBannerTarget } from '@ws1c/intelligence-models';
import { AlertBanner, AlertBannerState } from './alert-banner.state';

/**
 * AlertBannerSelectors
 *
 * @export
 * @class AlertBannerSelectors
 */
export class AlertBannerSelectors {
  /**
   * getAlertBannerState
   *
   * @param {CoreAppState} state
   * @returns {AlertBannerState}
   * @memberof AlertBannerSelectors
   */
  public static getAlertBannerState = (state: CoreAppState): AlertBannerState => state.alertBannerState;

  /**
   * getAlertBannerStateByTarget: Returns a function that returns the AlertBanner for the given target
   *
   * @static
   * @param {AlertBannerTarget} props
   * @type {(props: AlertBannerTarget) => MemoizedSelector<CoreAppState, AlertBanner>}
   * @memberof AlertBannerSelectors
   */
  public static getAlertBannerStateByTarget: (props: AlertBannerTarget) => MemoizedSelector<CoreAppState, AlertBanner> = (
    props: AlertBannerTarget,
  ): MemoizedSelector<CoreAppState, AlertBanner> => {
    return createSelector(AlertBannerSelectors.getAlertBannerState, (state: AlertBannerState) => state?.[props]);
  };
}

/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { OrgSettings, OrgTreeNode } from '@ws1c/intelligence-models';

/**
 * Provides REST Interface for All Org APIs
 *
 * @export
 * @class OrgService
 */
@Injectable({
  providedIn: 'root',
})
export class OrgService {
  /**
   * Creates an instance of OrgService.
   * @param {HttpService} httpService
   * @memberof OrgService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Creates and initializes org preferences.
   * @param {OrgSettings} preferences - Holds initial org preferences which needs to be created
   * @memberof OrgService
   * @returns {Observable<boolean>} - Returns observable of boolean
   */
  public createOrgSettings(preferences: OrgSettings): Observable<boolean> {
    return this.httpService.put(Endpoint.ORG_PREFERENCES, preferences).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get org hierachy of current org.
   *
   * @returns {Observable<OrgHierachySearchResponse>}
   * @memberof OrgService
   */
  public getLocationGroupHierachy(): Observable<OrgTreeNode | any[]> {
    return this.httpService.get(Endpoint.ORG_HIERACHY).pipe(
      map((response: any) => deserialize(OrgTreeNode, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Retrieves org specific preferences
   * @returns {Observable<OrgSettings>} - Returns observable of OrgSettings
   * @memberof OrgService
   */
  public getOrgSettings(): Observable<OrgSettings> {
    return this.httpService.get(Endpoint.ORG_PREFERENCES).pipe(
      map((response: any) => deserialize(OrgSettings, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Updates org preferences
   * @param {OrgSettings} preferences - Holds org preferences which needs to be updated
   * @returns {Observable<boolean>} - Returns observable of boolean
   * @memberof OrgService
   */
  public updateOrgSettings(preferences: OrgSettings): Observable<boolean> {
    return this.httpService.patch(Endpoint.ORG_PREFERENCES, preferences).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * enable org feature
   * @param {string} orgFeature
   * @param {boolean} sendEmail
   * @returns {Observable<boolean>}
   * @memberof OrgService
   */
  public enableOrgFeature(orgFeature: string, sendEmail: boolean): Observable<boolean> {
    return this.httpService.put(Endpoint.ENABLE_ORG_FEATURE(orgFeature, sendEmail), {}).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * disable org feature
   * @param {string} orgFeature
   * @returns {Observable<boolean>}
   * @memberof OrgService
   */
  public disableOrgFeature(orgFeature: string): Observable<boolean> {
    return this.httpService.put(Endpoint.DISABLE_ORG_FEATURE(orgFeature), {}).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}

<ng-template #tooltipTrigger>
  <ng-content></ng-content>
</ng-template>
<ng-template #scoreTemplate>
  <p class="large-tooltip mt-0">
    {{ scoreName || scoreType }}
    <br />
    <br />
    {{ scoreDefinition }}
  </p>
</ng-template>
<ng-container *ngIf="scoreDefinition; else noTooltip">
  <dpa-tooltip [tooltipTemplate]="scoreTemplate">
    <ng-container *ngTemplateOutlet="tooltipTrigger"></ng-container>
  </dpa-tooltip>
</ng-container>
<ng-template #noTooltip>
  <ng-container *ngTemplateOutlet="tooltipTrigger"></ng-container>
</ng-template>

/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { OptInFeatureFlag, OrgDisabledConfig, OrgPreference, OrgSettings, OrgTreeNode } from '@ws1c/intelligence-models';

/**
 * Actions related Org Information
 * @export
 * @class OrgActions
 */
export class OrgActions {
  public static readonly CATEGORY = '[ORG]';

  // Preferences
  public static abortOnboardingStep = createAction(
    `${OrgActions.CATEGORY} ABORT_ONBOARDING_STEP`,
    props<{ orgPreference: OrgPreference }>(),
  );

  public static abortOnboardingStepSuccess = createAction(`${OrgActions.CATEGORY} ABORT_ONBOARDING_STEP_SUCCESS`);

  public static completeOnboardingStep = createAction(
    `${OrgActions.CATEGORY} COMPLETE_ONBOARDING_STEP`,
    props<{ orgPreference: OrgPreference }>(),
  );

  public static goToNextOnboardingStep = createAction(`${OrgActions.CATEGORY} GO_TO_NEXT_ONBOARDING_STEP`);

  public static completeOnboardingStepSuccess = createAction(`${OrgActions.CATEGORY} COMPLETE_ONBOARDING_STEP_SUCCESS`);

  public static createOrgSettings = createAction(`${OrgActions.CATEGORY} CREATE_ORG_SETTINGS`, props<{ orgSettings: OrgSettings }>());

  public static getOrgSettings = createAction(`${OrgActions.CATEGORY} GET_ORG_SETTINGS`);
  public static getOrgSettingsSuccess = createAction(
    `${OrgActions.CATEGORY} GET_ORG_SETTINGS_SUCCESS`,
    props<{ orgSettings: OrgSettings }>(),
  );
  public static getOrgSettingsFailure = createAction(`${OrgActions.CATEGORY} GET_ORG_SETTINGS_FAILURE`, props<{ error: WebError }>());

  public static orgPreferencesChangeSuccess = createAction(
    `${OrgActions.CATEGORY} ORG_PREFERENCES_CHANGE_SUCCESS`,
    props<{ preferenceKeys: OrgPreference[] }>(),
  );
  public static orgPreferencesChangeFailure = createAction(
    `${OrgActions.CATEGORY} ORG_PREFERENCES_CHANGE_FAILURE`,
    props<{ preferenceKeys: string[]; error: WebError }>(),
  );

  public static updateOrgSettings = createAction(`${OrgActions.CATEGORY} UPDATE_ORG_SETTINGS`, props<{ orgPreferences: GenericObject }>());

  // Hierarchy
  public static loadOrgHierarchy = createAction(`${OrgActions.CATEGORY} LOAD_ORG_HIERARCHY`);
  public static loadOrgHierarchySuccess = createAction(
    `${OrgActions.CATEGORY} LOAD_ORG_HIERARCHY_SUCCESS`,
    props<{ orgTreeNode: OrgTreeNode }>(),
  );
  public static loadOrgHierarchyFailure = createAction(`${OrgActions.CATEGORY} LOAD_ORG_HIERARCHY_FAILURE`, props<{ error: WebError }>());

  public static toggleServiceOptInModal = createAction(`${OrgActions.CATEGORY} TOGGLE_SERVICE_OPT_IN_MODAL`, props<{ isOpen: boolean }>());

  public static openConfirmEnableFeatureModal = createAction(
    `${OrgActions.CATEGORY} OPEN_CONFIRM_ENABLE_FEATURE_MODAL`,
    props<{ feature: OptInFeatureFlag }>(),
  );

  public static closeConfirmEnableFeatureModal = createAction(`${OrgActions.CATEGORY} CLOSE_CONFIRM_ENABLE_FEATURE_MODAL`);

  public static enableFeature = createAction(`${OrgActions.CATEGORY} ENABLE_FEATURE`, props<{ sendEmail?: boolean }>());

  public static enableFeatureSuccess = createAction(`${OrgActions.CATEGORY} ENABLE_FEATURE_SUCCESS`);

  public static enableFeatureFailure = createAction(`${OrgActions.CATEGORY} ENABLE_FEATURE_FAILURE`);

  public static openConfirmDisableFeatureModal = createAction(
    `${OrgActions.CATEGORY} OPEN_CONFIRM_DISABLE_FEATURE_MODAL`,
    props<{ feature: OptInFeatureFlag }>(),
  );

  public static closeConfirmDisableFeatureModal = createAction(`${OrgActions.CATEGORY} CLOSE_CONFIRM_DISABLE_FEATURE_MODAL`);

  public static disableFeature = createAction(`${OrgActions.CATEGORY} DISABLE_FEATURE`);

  public static disableFeatureSuccess = createAction(`${OrgActions.CATEGORY} DISABLE_FEATURE_SUCCESS`);

  public static disableFeatureFailure = createAction(`${OrgActions.CATEGORY} DISABLE_FEATURE_FAILURE`);

  public static disableFeatureFromIntegrationViewSuccess = createAction(
    `${OrgActions.CATEGORY} DISABLE_FEATURE_FROM_INTEGRATION_VIEW_SUCCESS`,
  );

  public static performManualOptInOfServices = createAction(`${OrgActions.CATEGORY} PERFORM_MANUAL_OPT_IN_OF_SERVICES`);
  public static performManualOptInOfServicesComplete = createAction(`${OrgActions.CATEGORY} PERFORM_MANUAL_OPT_IN_OF_SERVICES_COMPLETE`);

  public static setCurrentFeature = createAction(`${OrgActions.CATEGORY} SET_CURRENT_FEATURE`, props<{ feature: OptInFeatureFlag }>());

  public static setOrgDisabledConfig = createAction(
    `${OrgActions.CATEGORY} SET_ORG_DISABLED_CONFIG`,
    props<{ orgDisabledConfig: OrgDisabledConfig }>(),
  );
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EndpointV2, filterInvalidTrendResults, HttpService, replaceTemplateParams } from '@ws1c/intelligence-common';
import {
  Automation,
  AutomationTemplate,
  CustomReportPreviewSearchResponse,
  PreviewReportContentRequest,
  Trend,
  TrendDefinition,
} from '@ws1c/intelligence-models';

/**
 * PreviewCommonService
 *
 * @export
 * @class PreviewCommonService
 */
@Injectable({
  providedIn: 'root',
})
export class PreviewCommonService {
  /**
   * @param {HttpService} httpService
   * @memberof PreviewCommonService
   */
  constructor(private httpService: HttpService) {}

  /**
   * loadWidgetPreview
   * @param {TrendDefinition} trendDefinition
   * @returns {Observable<Trend>}
   * @memberof PreviewCommonService
   */
  public loadWidgetPreview(trendDefinition: TrendDefinition): Observable<Trend> {
    TrendDefinition.replaceIntegrationEntity(trendDefinition);
    return this.httpService.post(EndpointV2.WIDGET_TREND_PREVIEW_V2, trendDefinition).pipe(
      map((response: GenericObject) => {
        const trend = deserialize(Trend, response.data);
        trend.trendResults = filterInvalidTrendResults(trend.trendResults);
        return trend;
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * loadReportPreview
   * @param {PreviewReportContentRequest} request
   * @param {boolean} isSnapshotData
   * @returns {Observable<CustomReportPreviewSearchResponse>}
   * @memberof PreviewCommonService
   */
  public loadReportPreview(request: PreviewReportContentRequest, isSnapshotData: boolean): Observable<CustomReportPreviewSearchResponse> {
    const endpoint = isSnapshotData ? EndpointV2.REPORTS_PREVIEW : EndpointV2.CONTENT_SEARCH;
    return this.httpService.post(endpoint, request).pipe(
      map((response: any) => deserialize(CustomReportPreviewSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * loadAutomationPreview
   * @param {AutomationTemplate} template
   * @returns {Observable<Automation>}
   * @memberof PreviewCommonService
   */
  public loadAutomationPreview(template: AutomationTemplate): Observable<Automation> {
    const { name, integration, targetEntity, params }: AutomationTemplate = template;
    return this.httpService.get(EndpointV2.AUTOMATION_TEMPLATE_DETAILS(integration, targetEntity, name)).pipe(
      map((response: GenericObject) => {
        let automation = response.data;
        if (params) {
          automation = replaceTemplateParams(automation, params);
        }
        return deserialize(Automation, automation);
      }),
      catchError(requestErrorHandler),
    );
  }
}

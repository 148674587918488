/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, RuleGroupOperator, SortOn, TrendSpan } from '@dpa/ui-common';
import { isUndefined, keyBy, mapValues, uniq, values } from 'lodash-es';

import { AppCrashThread } from '@dpa-shared-merlot/model';
import {
  App,
  AppCrashGroup,
  AppCrashGroupRequest,
  AppCrashGroupUsersRequest,
  AppErrorCrashUploadsTableRequest,
  AppErrorType,
  AppHeGroupRequest,
  AppPlatform,
  ApteligentCrumbListLocator,
  Category,
  ChronoUnit,
  Column,
  COLUMN_NAMES,
  CustomReportPreviewSearchResponse,
  DashboardConfig,
  Entity,
  FilterRule,
  getFQN,
  getFQNForColumns,
  getFQNFromMaskedName,
  getFQNFunction,
  Integration,
  PreviewReportContentRequest,
  QueryBuilder,
  RollingWindow,
  RuleGroup,
  SearchTerm,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
  TrendDefinition,
  TrendMode,
  TrendResult,
  UserFlow,
  UserFlowMetric,
} from '@ws1c/intelligence-models';

/**
 * Export helpers to support spy on functions
 */
export const helpers = {
  getAppDashboardTrendDateRangeSamplingFrequencyTooBig,
  getAppDashboardTrendDefinitionOverridesByStandardWidgetSubtype,
  buildStandardAppDashboardRequest,
  buildStandardAppExperienceDashboardRequest,
  buildStandardAppErrorDashboardRequest,
  buildStandardAppSummaryDashboardRequest,
  buildStandardAppUserFlowsDashboardRequest,
  getUserFlowsCardsWithAverageTimes,
  getUserFlowsCards,
  getUserFlowsDetailsMetricsBySubtype,
  buildMetricWithPrevious,
  getUserFlowsAllOccurencesMetrics,
  getUserFlowsSubFilterColumns,
  getAppErrorPreviewFilterStr,
  getDefaultNameAndReason,
  getAppErrorStacktracePreviewRequest,
  getAppErrorThreads,
  getAppErrorBreadcrumbRequest,
  getAppErrorCrashUploadsTableRequest,
  buildStandardHandledExceptionDashboardRequest$,
  buildStandardPluginExceptionDashboardRequest$,
  buildAppCrashGroupRequest,
  buildAppHeGroupRequest,
  getAppCrashUserRequest,
  getUserFlowsCrumbListMultiRequest,
  getNetAndCustomEventRequests,
  getHandledExceptionCrumbListRequest,
  getPluginExceptionCrumbListRequest,
  getCrashCrumbListRequests,
  getDownloadAppErrorStacktraceRequest,
  getAppErrorDetailsGroupEntity,
  getStandardDashboardTypeByAppErrorType,
};

/**
 * getAppDashboardTrendDateRangeSamplingFrequencyTooBig
 * @param {TrendDateRange} trendDateRange
 * @returns {boolean}
 */
export function getAppDashboardTrendDateRangeSamplingFrequencyTooBig(trendDateRange: TrendDateRange): boolean {
  const smallestRollingWindowSize: number = Object.assign(new TrendSpan(), {
    unit: ChronoUnit[ChronoUnit.DAYS],
    duration: 1,
  }).getUnitInMillis();
  return trendDateRange.getSamplingFrequencyInMillis() > smallestRollingWindowSize;
}

/**
 * getAppDashboardTrendDefinitionOverridesByStandardWidgetSubtype
 * @param {TrendDateRange} trendDateRange
 * @param {boolean} isSamplingFrequencyTooBig
 * @returns {any}
 */
export function getAppDashboardTrendDefinitionOverridesByStandardWidgetSubtype(
  trendDateRange: TrendDateRange,
  isSamplingFrequencyTooBig: boolean,
) {
  if (isSamplingFrequencyTooBig) {
    return {
      APTELIGENT_APP_ROLLING_DAILY_ACTIVE_USERS: {
        dateRange: Object.assign(new TrendDateRange(), {
          trendSpan: Object.assign(new TrendSpan(), {
            unit: ChronoUnit[ChronoUnit.DAYS],
            duration: 28,
          }),
          rollingWindow: Object.assign(new RollingWindow(), {
            rollingWindowInterval: Object.assign(new TrendSpan(), {
              unit: ChronoUnit[ChronoUnit.DAYS],
              duration: 1,
            }),
            rollingWindowSize: Object.assign(new TrendSpan(), {
              unit: ChronoUnit[ChronoUnit.DAYS],
              duration: 1,
            }),
          }),
        }),
      } as TrendDefinition,
      APTELIGENT_APP_ROLLING_MONTHLY_ACTIVE_USERS: {
        dateRange: Object.assign(new TrendDateRange(), {
          trendSpan: Object.assign(new TrendSpan(), {
            unit: ChronoUnit[ChronoUnit.DAYS],
            duration: 28,
          }),
          rollingWindow: Object.assign(new RollingWindow(), {
            rollingWindowInterval: Object.assign(new TrendSpan(), {
              unit: ChronoUnit[ChronoUnit.DAYS],
              duration: 1,
            }),
            rollingWindowSize: Object.assign(new TrendSpan(), {
              unit: ChronoUnit[ChronoUnit.DAYS],
              duration: 28,
            }),
          }),
        }),
      } as TrendDefinition,
    };
  } else {
    return {
      APTELIGENT_APP_ROLLING_DAILY_ACTIVE_USERS: {
        dateRange: Object.assign(new TrendDateRange(), {
          ...trendDateRange,
          samplingFrequency: undefined,
          rollingWindow: Object.assign(new RollingWindow(), {
            rollingWindowInterval: trendDateRange.samplingFrequency,
            rollingWindowSize: Object.assign(new TrendSpan(), {
              unit: ChronoUnit[ChronoUnit.DAYS],
              duration: 1,
            }),
          }),
        }),
      } as TrendDefinition,
      APTELIGENT_APP_ROLLING_MONTHLY_ACTIVE_USERS: {
        dateRange: Object.assign(new TrendDateRange(), {
          ...trendDateRange,
          samplingFrequency: undefined,
          rollingWindow: Object.assign(new RollingWindow(), {
            rollingWindowInterval: trendDateRange.samplingFrequency,
            rollingWindowSize: Object.assign(new TrendSpan(), {
              unit: ChronoUnit[ChronoUnit.DAYS],
              duration: 28,
            }),
          }),
        }),
      } as TrendDefinition,
    };
  }
}

/**
 * buildStandardAppDashboardRequest
 * @param {string} appId
 * @param {string} appName
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardAppDashboardRequest(
  appId: string,
  appName: string,
  apteligentId: string,
  appPlatform: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  let appFieldName = COLUMN_NAMES.byName.app_package_id;
  let appFieldValue = appId;
  if (appPlatform === AppPlatform.WINDOWS_DESKTOP) {
    appFieldName = COLUMN_NAMES.byName.app_name;
    appFieldValue = appName;
  }
  return new StandardDashboardRequest(StandardDashboardType.APP_DEPLOYMENT, appDashboardFilterRuleGroup, appDashboardTrendDateRange, {
    app_field_name: appFieldName,
    app_field_value: appFieldValue,
    _device_platform: appPlatform,
    apteligent_id: apteligentId,
  });
}

/**
 * buildStandardAppExperienceDashboardRequest
 * @param {string} appId
 * @param {string} apteligentId
 * @param {string} appName
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @param {string} platform
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardAppExperienceDashboardRequest(
  appId: string,
  apteligentId: string,
  appName: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
  platform: string,
): StandardDashboardRequest {
  return new StandardDashboardRequest(
    platform === AppPlatform.ANDROID ? StandardDashboardType.APP_EXPERIENCE_ANDROID : StandardDashboardType.APP_EXPERIENCE_IOS,
    appDashboardFilterRuleGroup,
    appDashboardTrendDateRange,
    {
      [COLUMN_NAMES.byName._application_name]: appName,
      [COLUMN_NAMES.byName.app_id]: apteligentId || appId,
    },
  );
}

/**
 * buildStandardAppErrorDashboardRequest
 * @param {string} appId
 * @param {string} apteligentId
 * @param {string} platform
 * @param {string} crashGroupId
 * @param {AppErrorType} appErrorType
 * @param {any} heParams
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardAppErrorDashboardRequest(
  appId: string,
  apteligentId: string,
  platform: string,
  crashGroupId: string,
  appErrorType: AppErrorType,
  heParams: any,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  if (appErrorType === AppErrorType.CRASH) {
    return new StandardDashboardRequest(
      platform === AppPlatform.ANDROID ? StandardDashboardType.APP_ERROR_ANDROID : StandardDashboardType.APP_ERROR_IOS,
      appDashboardFilterRuleGroup,
      appDashboardTrendDateRange,
      {
        app_package_id: appId,
        _device_platform: platform,
        apteligent_id: apteligentId,
        crash_bucket_id: crashGroupId,
        threshold_attribute: DashboardConfig.crashThresholdsByPlatform[platform],
      },
    );
  } else {
    const filterStr = getAppErrorPreviewFilterStr(
      appErrorType,
      heParams,
      apteligentId,
      platform,
      getAppErrorDetailsGroupEntity(appErrorType, platform),
      crashGroupId,
    );

    // using a single "additional parameter" field, filterStr can contain the "IS NULL" operator
    return new StandardDashboardRequest(
      getStandardDashboardTypeByAppErrorType(appErrorType, platform),
      appDashboardFilterRuleGroup,
      appDashboardTrendDateRange,
      { base_filter: filterStr },
    );
  }
}

/**
 * getStandardDashboardTypeByAppErrorType
 * @export
 * @param {AppErrorType} appErrorType
 * @param {string} platform
 * @returns {StandardDashboardType}
 */
export function getStandardDashboardTypeByAppErrorType(appErrorType: AppErrorType, platform: string): StandardDashboardType {
  if (appErrorType !== AppErrorType[AppErrorType.HANDLED_EXCEPTION]) {
    return StandardDashboardType.APP_PLUGIN_EXCEPTION;
  }
  if (platform === AppPlatform.ANDROID) {
    return StandardDashboardType.APP_HANDLED_EXCEPTION_ANDROID;
  }
  return StandardDashboardType.APP_HANDLED_EXCEPTION_IOS;
}

/**
 * buildStandardAppSummaryDashboardRequest
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardAppSummaryDashboardRequest(
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  return new StandardDashboardRequest(StandardDashboardType.APP_SUMMARY, appDashboardFilterRuleGroup, appDashboardTrendDateRange);
}

/**
 * buildStandardAppUserFlowsDashboardRequest
 * @param {string} appId
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {userFlowsSubFilterRuleGroup} userFlowsSubFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardAppUserFlowsDashboardRequest(
  appId: string,
  apteligentId: string,
  appPlatform: string,
  appDashboardFilterRuleGroup: RuleGroup,
  userFlowsSubFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  const filterRules = [...(appDashboardFilterRuleGroup?.rules ?? []), ...(userFlowsSubFilterRuleGroup?.rules ?? [])].filter(
    (filterRule: FilterRule) => !filterRule.isMissingFields(),
  );
  return new StandardDashboardRequest(StandardDashboardType.USER_FLOWS, new RuleGroup(filterRules), appDashboardTrendDateRange, {
    app_package_id: appId,
    _device_platform: appPlatform,
    apteligent_id: apteligentId,
  });
}

/**
 * getUserFlowsCardsWithAverageTimes
 * @param {UserFlow[]} userFlows
 * @param {Trend} userFlowsAverageTimeTrend
 * @returns {UserFlow}
 */
export function getUserFlowsCardsWithAverageTimes(userFlows: UserFlow[], userFlowsAverageTimeTrend: Trend): UserFlow[] {
  // Issue where API can return a trendResult with no bucketing attributes
  const validAverageTimeTrendResults = userFlowsAverageTimeTrend?.trendResults?.filter((trendResult: TrendResult) =>
    Boolean(trendResult.bucketingAttributes.length),
  );

  const trendResultsByUserFlowName = keyBy(
    validAverageTimeTrendResults,
    (trendResult: TrendResult) => trendResult.bucketingAttributes[0].value,
  );
  const averageTimesByUserFlowName = mapValues(
    trendResultsByUserFlowName,
    (trendResult: TrendResult) => trendResult.counters[0].result.value,
  );
  return userFlows.map((userFlow: UserFlow) => {
    return Object.assign(new UserFlow(), userFlow, { averageTime: averageTimesByUserFlowName[userFlow.name] });
  });
}

/**
 * getUserFlowsCards
 * @param {Map<string, Trend>} trendsByWidgetSubtype
 * @returns {UserFlow}
 */
export function getUserFlowsCards(trendsByWidgetSubtype: Map<string, Trend>): UserFlow[] {
  const trend: Trend = trendsByWidgetSubtype.get(StandardWidgetSubtype[StandardWidgetSubtype.USER_FLOWS_OCCURRENCES_BY_NAME]);
  if (!trend) {
    return [];
  }

  const userFlowsByName: any = {};
  trend.trendResults?.forEach((trendResult: TrendResult) => {
    // Issue where API can return a trendResult with no bucketing attributes
    if (!trendResult.bucketingAttributes[0]) {
      return;
    }
    const userFlowName = trendResult.bucketingAttributes[0].value;
    const isSuccess = trendResult.bucketingAttributes[1].value === 'Success';
    const counterValue = trendResult.counters[0].result.value || 0;

    if (!userFlowsByName[userFlowName]) {
      userFlowsByName[userFlowName] = Object.assign(new UserFlow(), { name: userFlowName });
    }
    if (isSuccess) {
      userFlowsByName[userFlowName].successCount = counterValue;
    } else {
      userFlowsByName[userFlowName].failureCount = counterValue;
    }
  });

  return values(userFlowsByName).map((originalUserFlow: UserFlow) => {
    const userFlow = Object.assign(new UserFlow(), {
      ...originalUserFlow,
      successCount: originalUserFlow.successCount || 0,
      failureCount: originalUserFlow.failureCount || 0,
    });
    userFlow.totalCount = userFlow.successCount + userFlow.failureCount;
    userFlow.successRatio = userFlow.totalCount ? userFlow.successCount / userFlow.totalCount : 0;
    return userFlow;
  });
}

/**
 * getUserFlowsDetailsMetricsBySubtype
 * @param {Map<string, Trend>} trendsByWidgetSubtype
 * @returns {any}
 */
export function getUserFlowsDetailsMetricsBySubtype(trendsByWidgetSubtype: Map<string, Trend>): any {
  return {
    ...buildMetricWithPrevious(
      trendsByWidgetSubtype,
      StandardWidgetSubtype.USER_FLOWS_OCCURRENCES_BY_NAME_COUNT,
      StandardWidgetSubtype.USER_FLOWS_OCCURRENCES_BY_NAME_COUNT_PREVIOUS_PERIOD,
    ),
    ...buildMetricWithPrevious(
      trendsByWidgetSubtype,
      StandardWidgetSubtype.USER_FLOWS_CRASHES_BY_NAME_COUNT,
      StandardWidgetSubtype.USER_FLOWS_CRASHES_BY_NAME_COUNT_PREVIOUS_PERIOD,
    ),
    ...buildMetricWithPrevious(
      trendsByWidgetSubtype,
      StandardWidgetSubtype.USER_FLOWS_TIMEOUTS_BY_NAME_COUNT,
      StandardWidgetSubtype.USER_FLOWS_TIMEOUTS_BY_NAME_COUNT_PREVIOUS_PERIOD,
    ),
    ...buildMetricWithPrevious(
      trendsByWidgetSubtype,
      StandardWidgetSubtype.USER_FLOWS_FAILURES_BY_NAME_COUNT,
      StandardWidgetSubtype.USER_FLOWS_FAILURES_BY_NAME_COUNT_PREVIOUS_PERIOD,
    ),
    ...buildMetricWithPrevious(
      trendsByWidgetSubtype,
      StandardWidgetSubtype.USER_FLOWS_SUCCESS_BY_NAME_COUNT,
      StandardWidgetSubtype.USER_FLOWS_SUCCESS_BY_NAME_COUNT_PREVIOUS_PERIOD,
    ),
    ...buildMetricWithPrevious(
      trendsByWidgetSubtype,
      StandardWidgetSubtype.USER_FLOWS_ACTIVE_USERS_COUNT_BY_NAME,
      StandardWidgetSubtype.USER_FLOWS_ACTIVE_USERS_COUNT_BY_NAME_PREVIOUS_PERIOD,
    ),
  };
}

/**
 * buildMetricWithPrevious
 * @param {Map<string, Trend>} trendsByWidgetSubtype
 * @param {StandardWidgetSubtype} subtype
 * @param {StandardWidgetSubtype} subtypePrevious
 * @returns {any}
 */
export function buildMetricWithPrevious(
  trendsByWidgetSubtype: Map<string, Trend>,
  subtype: StandardWidgetSubtype,
  subtypePrevious: StandardWidgetSubtype,
): any {
  const trend = trendsByWidgetSubtype.get(StandardWidgetSubtype[subtype]);
  const previousTrend = trendsByWidgetSubtype.get(StandardWidgetSubtype[subtypePrevious]);

  if (!trend || !previousTrend) {
    return {};
  }

  const doesCounterExist = Boolean(trend.trendResults?.[0]);
  const counterValue = doesCounterExist ? trend.trendResults?.[0].counters[0].result.value : undefined;

  const doesPreviousCounterExist = Boolean(previousTrend.trendResults?.[0]);
  const previousCounterValue = doesPreviousCounterExist ? previousTrend.trendResults?.[0].counters[0].result.value : undefined;

  return {
    [subtype]: {
      value: counterValue,
      previousValue: previousCounterValue,
    },
  };
}

/**
 * getUserFlowsAllOccurencesMetrics
 * @param {Map<string, Trend>} trendsByWidgetSubtype
 * @returns {any}
 */
export function getUserFlowsAllOccurencesMetrics(trendsByWidgetSubtype: Map<string, Trend>): any {
  const totalCountTrend = trendsByWidgetSubtype.get(StandardWidgetSubtype[StandardWidgetSubtype.USER_FLOWS_OCCURRENCES_TOTAL_COUNT]);

  const totalCountTrendOffset = trendsByWidgetSubtype.get(
    StandardWidgetSubtype[StandardWidgetSubtype.USER_FLOWS_OCCURRENCES_TOTAL_COUNT_PREVIOUS_PERIOD],
  );

  if (!totalCountTrend || !totalCountTrendOffset) {
    return;
  }
  const trendOffsetIndex = totalCountTrend?.trendResults?.length;
  return (totalCountTrend.trendResults || [])
    .concat(totalCountTrendOffset.trendResults)
    .reduce((ratiosByStateName: any, trendResult: TrendResult, index: number) => {
      if (trendResult?.bucketingAttributes[0]) {
        const stateName = trendResult.bucketingAttributes[0].value;
        if (!ratiosByStateName[stateName]) {
          ratiosByStateName[stateName] = Object.assign(new UserFlowMetric(), {
            name: stateName,
          });
        }
        const propName = index < trendOffsetIndex ? 'count' : 'previousCount';
        ratiosByStateName[stateName][propName] = trendResult.counters[0].result.value;
      }
      return ratiosByStateName;
    }, {});
}

/**
 * getUserFlowsSubFilterColumns
 * @param {Map<Category, Column[]>} columnsByCategory
 * @param {Category} userFlowCategory
 * @returns {Column[]}
 */
export function getUserFlowsSubFilterColumns(columnsByCategory: Map<Category, Column[]>, userFlowCategory: Category): Column[] {
  const userFlowColumns = columnsByCategory.get(userFlowCategory) || [];
  const userFlowSubFilterColumnNames = new Set([
    COLUMN_NAMES.byName.device_model,
    COLUMN_NAMES.byName.system_version,
    COLUMN_NAMES.byName.user_name,
  ]);
  const userFlowSubFilterColumns = userFlowColumns.filter((column: Column) => {
    return userFlowSubFilterColumnNames.has(column.name);
  });
  return userFlowSubFilterColumns;
}

/**
 * getAppErrorPreviewFilterStr
 * @export
 * @param {AppErrorType} appErrorType
 * @param {*} heParams
 * @param {string} apteligentId
 * @param {string} platform
 * @param {string} entity
 * @param {string} [crashGroupId='']
 * @returns {*}  {string}
 */
export function getAppErrorPreviewFilterStr(
  appErrorType: AppErrorType,
  heParams: any,
  apteligentId: string,
  platform: string,
  entity: string,
  crashGroupId: string = '',
): string {
  if (!platform) {
    return;
  }
  const thresholdAttribute = DashboardConfig.crashThresholdsFqnByPlatform[entity];
  const getFqn = getFQNFunction(Integration.APTELIGENT, entity);
  if (appErrorType === AppErrorType.CRASH) {
    return QueryBuilder.queryStringFromKeyValue({
      [getFqn(COLUMN_NAMES.byName.app_id)]: apteligentId,
      [thresholdAttribute]: crashGroupId,
    });
  }

  if (!heParams) {
    return '';
  }
  const baseFilterStr = QueryBuilder.queryStringFromKeyValue({
    [getFqn(COLUMN_NAMES.byName.app_id)]: apteligentId,
    [getFqn(COLUMN_NAMES.byName._error_name)]: heParams.errorName,
  });

  // If errorReason is missing, use "IS NULL" filter string
  const errorReasonStr = isUndefined(heParams.errorReason)
    ? `${getFqn(COLUMN_NAMES.byName.error_reason)} IS NULL`
    : // errorReason workaround for ESC-23500
      // Uses "STARTS WITH" operator since control characters weren't trimmed
      `${getFqn(COLUMN_NAMES.byName.error_reason)} STARTS WITH '${heParams.errorReason.replace(/'/g, "\\'")}'`;

  const platformStr = `${getFqn(COLUMN_NAMES.byName._device_platform)} = '${platform}'`;

  return [errorReasonStr, baseFilterStr, platformStr].join(' AND ');
}

/**
 * getDefaultNameAndReason
 * @param  {string} entity
 * @param  {string} targetEntity
 * @param  {string[]} columns
 * @returns {string[]}
 */
export function getDefaultNameAndReason(entity: string, targetEntity: string, columns: string[]): string[] {
  return entity === targetEntity ? columns : [];
}

/**
 * getAppErrorStacktracePreviewRequest
 * @param {AppErrorType} appErrorType
 * @param {any} heParams
 * @param {string} apteligentId
 * @param {string} crashGroupId
 * @param {RuleGroup} ruleGroup
 * @param {TrendDateRange} trendDateRange
 * @param {string} platform
 * @param {string} entity
 * @returns {PreviewReportContentRequest}
 */
export function getAppErrorStacktracePreviewRequest(
  appErrorType: AppErrorType,
  heParams: any,
  apteligentId: string,
  crashGroupId: string,
  ruleGroup: RuleGroup,
  trendDateRange: TrendDateRange,
  platform: string,
  entity: string,
): PreviewReportContentRequest {
  const { startDateMillis, endDateMillis } = trendDateRange.getStartEndMillis();
  const filterStr = getAppErrorPreviewFilterStr(appErrorType, heParams, apteligentId, platform, entity, crashGroupId);

  const filterRuleQueryString = new QueryBuilder(ruleGroup).getQueryString();
  const combinedFilterStr = [filterStr, ...(filterRuleQueryString ? [filterRuleQueryString] : [])].join(' AND ');
  if (!appErrorType || !platform) {
    return;
  }
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.APTELIGENT]: [entity],
    },
    filter: getFQNFromMaskedName(Integration.APTELIGENT, entity, combinedFilterStr),
    offset: 0,
    pageSize: 1,
    fields: getFQNForColumns(Integration.APTELIGENT, entity, DashboardConfig.appDashboardColumnNames[entity]),
    sortOns: [
      Object.assign(new SortOn(), {
        by: getFQN(Integration.APTELIGENT, entity, COLUMN_NAMES.byName.event_timestamp),
        reverse: true,
      }),
    ],
    trendMode: TrendMode.HISTORICAL,
    startDateMillis,
    endDateMillis,
  });
}

/**
 * getDownloadAppErrorStacktraceRequest
 * @param {App} app
 * @param {AppCrashGroup} appCrashGroup
 * @param {PreviewReportContentRequest} previewRequest
 * @param {string} platform
 * @returns {PreviewReportContentRequest}
 */
export function getDownloadAppErrorStacktraceRequest(
  app: App,
  appCrashGroup: AppCrashGroup,
  previewRequest: PreviewReportContentRequest,
  platform: string,
): PreviewReportContentRequest {
  if (!appCrashGroup?.eventType || !platform) {
    return;
  }
  const entitiesByIntegration = {
    [Integration.APTELIGENT]: [appCrashGroup.eventType],
  };
  const fields = getFQNForColumns(
    Integration.APTELIGENT,
    appCrashGroup.eventType,
    DashboardConfig.appDashboardColumnNames[appCrashGroup.eventType],
  );
  const crashBucketField = DashboardConfig.crashThresholdsFqnByPlatform[appCrashGroup.eventType];
  const appIdField = getFQN(Integration.APTELIGENT, appCrashGroup.eventType, COLUMN_NAMES.byName.app_id);

  const appIdRule = new FilterRule({
    attribute: appIdField,
    condition: '=',
    data: app.apteligentAppId,
  });
  const crashBucketRule = new FilterRule({
    attribute: crashBucketField,
    condition: '=',
    data: appCrashGroup.bucketId,
  });
  const filter = QueryBuilder.queryStringFromFilterRules([appIdRule, crashBucketRule]);
  return new PreviewReportContentRequest({
    ...previewRequest,
    entitiesByIntegration,
    fields,
    filter,
  });
}

/**
 * getAppCrashUserRequest
 * @param  {AppErrorType} appErrorType
 * @param  {string} apteligentId
 * @param  {RuleGroup} ruleGroup
 * @param  {TrendDateRange} trendDateRange
 * @param  {string} platform
 * @param  {string} crashGroupId
 * @param  {string} entity
 * @returns {AppCrashGroupUsersRequest}
 */
export function getAppCrashUserRequest(
  appErrorType: AppErrorType,
  apteligentId: string,
  ruleGroup: RuleGroup,
  trendDateRange: TrendDateRange,
  platform: string,
  crashGroupId: string,
  entity: string,
): AppCrashGroupUsersRequest {
  if (appErrorType !== AppErrorType.CRASH) {
    return;
  }
  const filterStr = getAppErrorPreviewFilterStr(appErrorType, {}, apteligentId, platform, entity, crashGroupId);
  const filterRuleQueryString = new QueryBuilder(ruleGroup).getQueryString();
  const combinedFilterStr = [filterStr, ...(filterRuleQueryString ? [filterRuleQueryString] : [])].join(' AND ');
  return new AppCrashGroupUsersRequest({
    entitiesByIntegration: {
      [Integration.APTELIGENT]: [entity],
    },
    filter: getFQNFromMaskedName(Integration.APTELIGENT, entity, combinedFilterStr),
    sortOns: [
      new SortOn({
        by: COLUMN_NAMES.byName.event_timestamp,
        reverse: true,
      }),
    ],
    trendMode: TrendMode.HISTORICAL,
    ...trendDateRange.getStartEndMillis(),
  });
}

/**
 * getAppErrorThreads
 * @param {CustomReportPreviewSearchResponse} previewResponse
 * @param {AppErrorType} appErrorType
 * @param {string} platform
 * @returns {AppCrashThread[]}
 */
export function getAppErrorThreads(
  previewResponse: CustomReportPreviewSearchResponse,
  appErrorType: AppErrorType,
  platform: string,
): AppCrashThread[] {
  const entity = getAppErrorDetailsGroupEntity(appErrorType, platform);
  const fqn = getFQNFunction(Integration.APTELIGENT, entity);
  return previewResponse
    ? previewResponse.results.map((result: any) => {
        // backend can return either a list of strings or a big text field that needs to be split
        const unsplitLines = result[fqn(COLUMN_NAMES.byName.error_stacktrace_txt)] || result[fqn(COLUMN_NAMES.byName.error_trace)];
        const lines = unsplitLines ? unsplitLines.split('\n') : result[fqn(COLUMN_NAMES.byName.stacktrace_lines)];
        return new AppCrashThread({
          // INTEL-28940
          name: result[fqn(COLUMN_NAMES.byName._error_name)] || result[fqn(COLUMN_NAMES.byName.error_type)],
          reason: result[fqn(COLUMN_NAMES.byName.error_reason)] || result[fqn(COLUMN_NAMES.byName.error_code)],
          appVersion: result[fqn(COLUMN_NAMES.byName._app_version)],
          partiallySymbolicated: result[fqn(COLUMN_NAMES.byName.partially_symbolicated)],
          missingSymbolicationFiles: result[fqn(COLUMN_NAMES.byName.missing_symbolication_files)],
          lines,
        });
      })
    : undefined;
}

/**
 * getAppErrorBreadcrumbRequest
 * @param {AppErrorType} appErrorType
 * @param {any} heParams
 * @param {TrendDateRange} trendDateRange
 * @param {RuleGroup} ruleGroup
 * @param {PagedRequest} pagedRequest
 * @param {SortOn[]} sortOns
 * @param {string} apteligentId
 * @param {string} platform
 * @param {string} crashGroupId
 * @returns {PreviewReportContentRequest}
 */
export function getAppErrorBreadcrumbRequest(
  appErrorType: AppErrorType,
  heParams: any,
  trendDateRange: TrendDateRange,
  ruleGroup: RuleGroup,
  pagedRequest: PagedRequest,
  sortOns: SortOn[],
  apteligentId: string,
  platform: string,
  crashGroupId: string,
): PreviewReportContentRequest {
  if (!platform) {
    return;
  }
  const entity = getAppErrorDetailsGroupEntity(appErrorType, platform);
  const filterStr = getAppErrorPreviewFilterStr(appErrorType, heParams, apteligentId, platform, entity, crashGroupId);
  const filterRuleQueryString = new QueryBuilder(ruleGroup).getQueryString();
  const combinedFilterStr = [filterStr, ...(filterRuleQueryString ? [filterRuleQueryString] : [])].join(' AND ');

  const uniqueColumnNames = uniq([
    ...getFQNForColumns(Integration.APTELIGENT, entity, DashboardConfig.appErrorBreadcrumbColumnNames),
    ...getFQNForColumns(Integration.APTELIGENT, entity, DashboardConfig.appErrorBreadcrumbAdditionalColumnNames),
  ]);
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.APTELIGENT]: [entity],
    },
    integration: Integration.APTELIGENT,
    entity,
    filter: getFQNFromMaskedName(Integration.APTELIGENT, entity, combinedFilterStr),
    fields: uniqueColumnNames,
    trendMode: TrendMode.HISTORICAL,
    ...trendDateRange.getStartEndMillis(),
    offset: pagedRequest.from,
    pageSize: pagedRequest.size,
    sortOns,
  });
}

/**
 * getAppErrorCrashUploadsTableRequest
 * @param {string} appId
 * @param {string} filterString
 * @param {PagedRequest} pagedRequest
 * @param {SortOn[]} sortOns
 * @returns {AppErrorCrashUploadsTableRequest}
 */
export function getAppErrorCrashUploadsTableRequest(
  appId: string,
  filterString: string,
  pagedRequest: PagedRequest,
  sortOns: SortOn[],
): AppErrorCrashUploadsTableRequest {
  return new AppErrorCrashUploadsTableRequest({
    searchTerms: [
      ...(filterString
        ? [
            Object.assign(new SearchTerm(), {
              value: filterString,
              fields: [COLUMN_NAMES.byName.app_version],
            }),
          ]
        : []),
      Object.assign(new SearchTerm(), {
        value: appId,
        fields: [COLUMN_NAMES.byName.apteligent_app_id],
      }),
    ],
    from: 0,
    size: 10,
    ...pagedRequest,
    sortOns: sortOns || [
      Object.assign(new SortOn(), {
        by: COLUMN_NAMES.byName.created_at,
        reverse: true,
      }),
    ],
  });
}

/**
 * buildStandardHandledExceptionDashboardRequest$
 * @param {string} appId
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardHandledExceptionDashboardRequest$(
  appId: string,
  apteligentId: string,
  appPlatform: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  appDashboardFilterRuleGroup.rules = appDashboardFilterRuleGroup?.rules.filter((filterRule: FilterRule) => !filterRule.isMissingFields());
  return new StandardDashboardRequest(
    appPlatform === AppPlatform.ANDROID
      ? StandardDashboardType.APP_HANDLED_EXCEPTIONS_ANDROID
      : StandardDashboardType.APP_HANDLED_EXCEPTIONS_IOS,
    appDashboardFilterRuleGroup,
    appDashboardTrendDateRange,
    {
      app_package_id: appId,
      _device_platform: appPlatform,
      apteligent_id: apteligentId,
    },
  );
}

/**
 * buildStandardPluginExceptionDashboardRequest$
 * @param {string} appId
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardPluginExceptionDashboardRequest$(
  appId: string,
  apteligentId: string,
  appPlatform: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  appDashboardFilterRuleGroup.rules = appDashboardFilterRuleGroup?.rules.filter((filterRule: FilterRule) => !filterRule.isMissingFields());
  return new StandardDashboardRequest(
    StandardDashboardType.APP_PLUGIN_EXCEPTIONS,
    appDashboardFilterRuleGroup,
    appDashboardTrendDateRange,
    {
      app_package_id: appId,
      _device_platform: appPlatform,
      apteligent_id: apteligentId,
    },
  );
}

/**
 * buildAppCrashGroupRequest
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {AppCrashGroupRequest}
 */
export function buildAppCrashGroupRequest(
  apteligentId: string,
  appPlatform: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): AppCrashGroupRequest {
  const entity = appPlatform === AppPlatform.ANDROID ? Entity.GROUPED_CRASH_ANDROID : Entity.GROUPED_CRASH_IOS;
  const appIdFilterRule = Object.assign(new FilterRule(), {
    attribute: getFQN(Integration.APTELIGENT, entity, COLUMN_NAMES.byName.app_id),
    condition: '=',
    data: apteligentId,
  });
  const filterStr = QueryBuilder.queryStringFromFilterRules([
    ...((appDashboardFilterRuleGroup?.rules as FilterRule[]) ?? []),
    appIdFilterRule,
  ]);
  return Object.assign(new AppCrashGroupRequest(), {
    filter: getFQNFromMaskedName(Integration.APTELIGENT, entity, filterStr),
    entitiesByIntegration: {
      [Integration.APTELIGENT]: [entity],
    },
    ...appDashboardTrendDateRange.getStartEndMillis(),
  });
}

/**
 * buildAppHeGroupRequest
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {AppHeGroupRequest}
 */
export function buildAppHeGroupRequest(
  apteligentId: string,
  appPlatform: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): AppHeGroupRequest {
  const entity = appPlatform === AppPlatform.ANDROID ? Entity.HANDLED_EXCEPTION_ANDROID : Entity.HANDLED_EXCEPTION_IOS;
  const appIdFilterRule = Object.assign(new FilterRule(), {
    attribute: getFQN(Integration.APTELIGENT, entity, COLUMN_NAMES.byName.app_id),
    condition: '=',
    data: apteligentId,
  });
  const filterStr = QueryBuilder.queryStringFromFilterRules([
    ...((appDashboardFilterRuleGroup?.rules as FilterRule[]) ?? []),
    appIdFilterRule,
  ]);
  return Object.assign(new AppHeGroupRequest(), {
    filter: getFQNFromMaskedName(Integration.APTELIGENT, entity, filterStr),
    entitiesByIntegration: {
      [Integration.APTELIGENT]: [entity],
    },
    ...appDashboardTrendDateRange.getStartEndMillis(),
  });
}

/**
 * getUserFlowsCrumbListMultiRequest
 * @param {ApteligentCrumbListLocator} apteligentCrumbListLocator
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @returns {PreviewReportContentRequest[]}
 */
export function getUserFlowsCrumbListMultiRequest(
  apteligentCrumbListLocator: ApteligentCrumbListLocator,
  appDashboardFilterRuleGroup: RuleGroup,
): PreviewReportContentRequest[] {
  // The _device_platform field is missing from handled_exception entities
  // This converts it to the normalized AppPlatform value
  // Uppercase is needed because some older apteligent SDK versions seem to send lowercase platform values
  const apteligentPlatform = apteligentCrumbListLocator.platform && apteligentCrumbListLocator.platform.toUpperCase();
  const platform = DashboardConfig.appPlatformByApteligentPlatform[apteligentPlatform];
  const deviceFilterStr = QueryBuilder.queryStringFromFilterRules([
    ...((appDashboardFilterRuleGroup?.rules as FilterRule[]) ?? []),
    ...FilterRule.listFromKeyValue({
      [getFQN(DashboardConfig.Integration, DashboardConfig.Entity, COLUMN_NAMES.byName.device_uuid)]: apteligentCrumbListLocator.deviceUuid,
      [getFQN(DashboardConfig.Integration, DashboardConfig.Entity, COLUMN_NAMES.byName.app_id)]: apteligentCrumbListLocator.appId,
    }),
  ]);
  const mostRecentSortOn = Object.assign(new SortOn(), {
    by: getFQN(DashboardConfig.Integration, DashboardConfig.Entity, COLUMN_NAMES.byName.event_timestamp),
    reverse: true,
  });

  return [
    ...helpers.getNetAndCustomEventRequests(apteligentCrumbListLocator, deviceFilterStr, mostRecentSortOn),
    helpers.getHandledExceptionCrumbListRequest(apteligentCrumbListLocator, deviceFilterStr, mostRecentSortOn, platform),
    helpers.getPluginExceptionCrumbListRequest(apteligentCrumbListLocator, deviceFilterStr, mostRecentSortOn, platform),
    ...helpers.getCrashCrumbListRequests(apteligentCrumbListLocator, deviceFilterStr, mostRecentSortOn, platform),
  ];
}

/**
 * getNetAndCustomEventRequests
 * @param {ApteligentCrumbListLocator} apteligentCrumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @returns {PreviewReportContentRequest[]}
 */
export function getNetAndCustomEventRequests(
  apteligentCrumbListLocator: ApteligentCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
): PreviewReportContentRequest[] {
  const netEventSortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.APTELIGENT, Entity.NET_EVENT, mostRecentSortOn.by),
  });
  const netErrorSortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.APTELIGENT, Entity.NET_ERROR, mostRecentSortOn.by),
  });
  const customEventSortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.APTELIGENT, Entity.CUSTOM_EVENT, mostRecentSortOn.by),
  });
  const extraFilterBuilder = new QueryBuilder();
  extraFilterBuilder.group.operator = RuleGroupOperator.OR;
  extraFilterBuilder.group.rules = [
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_custom_event_userflow_id,
      condition: FilterRule.FILTER_CONDITION.isNull,
      data: [],
    }),
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_custom_event_userflow_id,
      condition: FilterRule.FILTER_CONDITION.equals,
      data: apteligentCrumbListLocator.eventId,
    }),
  ];
  const customEventIdFilter = `${getFQNFromMaskedName(
    Integration.APTELIGENT,
    Entity.CUSTOM_EVENT,
    deviceFilterStr,
  )} AND (${extraFilterBuilder.getQueryString()})`;
  return [
    new PreviewReportContentRequest({
      entitiesByIntegration: {
        [Integration.APTELIGENT]: [Entity.NET_EVENT],
      },
      startDateMillis: apteligentCrumbListLocator.startTime,
      endDateMillis: apteligentCrumbListLocator.endTime,
      filter: getFQNFromMaskedName(Integration.APTELIGENT, Entity.NET_EVENT, deviceFilterStr),
      fields: [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_url,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_url_host,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_http_method,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_latency,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_http_status_code,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_bytes_received,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_bytes_sent,
      ],
      pageSize: 50,
      offset: 0,
      sortOns: [netEventSortOn],
    }),
    new PreviewReportContentRequest({
      entitiesByIntegration: {
        [Integration.APTELIGENT]: [Entity.NET_ERROR],
      },
      startDateMillis: apteligentCrumbListLocator.startTime,
      endDateMillis: apteligentCrumbListLocator.endTime,
      filter: getFQNFromMaskedName(Integration.APTELIGENT, Entity.NET_ERROR, deviceFilterStr),
      fields: [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_url,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_url_host,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_http_method,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_net_error_msg,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_bytes_sent,
      ],
      pageSize: 50,
      offset: 0,
      sortOns: [netErrorSortOn],
    }),
    new PreviewReportContentRequest({
      entitiesByIntegration: {
        [Integration.APTELIGENT]: [Entity.CUSTOM_EVENT],
      },
      startDateMillis: apteligentCrumbListLocator.startTime,
      endDateMillis: apteligentCrumbListLocator.endTime,
      filter: customEventIdFilter,
      fields: [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_custom_event_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_custom_event_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_custom_event_custom_event_type,
      ],
      pageSize: 50,
      offset: 0,
      sortOns: [customEventSortOn],
    }),
  ];
}

/**
 * getHandledExceptionCrumbListRequest
 * @param {ApteligentCrumbListLocator} apteligentCrumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @param {string} platform
 * @returns {PreviewReportContentRequest}
 */
export function getHandledExceptionCrumbListRequest(
  apteligentCrumbListLocator: ApteligentCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
  platform: string,
): PreviewReportContentRequest {
  const entitiesByPlatform = {
    [AppPlatform.ANDROID]: Entity.HANDLED_EXCEPTION_ANDROID,
    [AppPlatform.APPLE_IOS]: Entity.HANDLED_EXCEPTION_IOS,
  };
  const fieldsByPlatform = {
    [AppPlatform.ANDROID]: [
      COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_error_name,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_error_reason,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_event_timestamp,
    ],
    [AppPlatform.APPLE_IOS]: [
      COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_error_name,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_error_reason,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_event_timestamp,
    ],
  };
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.APTELIGENT, entitiesByPlatform[platform], mostRecentSortOn.by),
  });
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.APTELIGENT]: [entitiesByPlatform[platform]],
    },
    startDateMillis: apteligentCrumbListLocator.startTime,
    endDateMillis: apteligentCrumbListLocator.endTime,
    filter: getFQNFromMaskedName(Integration.APTELIGENT, entitiesByPlatform[platform], deviceFilterStr),
    fields: fieldsByPlatform[platform] || [],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getPluginExceptionCrumbListRequest
 * @param {ApteligentCrumbListLocator} apteligentCrumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @param {string} platform
 * @returns {PreviewReportContentRequest}
 */
export function getPluginExceptionCrumbListRequest(
  apteligentCrumbListLocator: ApteligentCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
  platform: string,
): PreviewReportContentRequest {
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.APTELIGENT, Entity.PLUGIN_EXCEPTION, mostRecentSortOn.by),
  });
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.APTELIGENT]: [Entity.PLUGIN_EXCEPTION],
    },
    startDateMillis: apteligentCrumbListLocator.startTime,
    endDateMillis: apteligentCrumbListLocator.endTime,
    filter: `${getFQNFromMaskedName(Integration.APTELIGENT, Entity.PLUGIN_EXCEPTION, deviceFilterStr)} AND ${
      COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_device_platform
    } = '${platform}'`,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_error_name,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_error_reason,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_event_timestamp,
    ],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getCrashCrumbListRequests
 * @param {ApteligentCrumbListLocator} apteligentCrumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @param {string} platform
 * @returns {PreviewReportContentRequest[]}
 */
export function getCrashCrumbListRequests(
  apteligentCrumbListLocator: ApteligentCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
  platform: string,
): PreviewReportContentRequest[] {
  const entitiesByPlatform = {
    [AppPlatform.ANDROID]: Entity.CRASH_ANDROID,
    [AppPlatform.APPLE_IOS]: Entity.CRASH_IOS,
  };
  const groupedEntitiesByPlatform = {
    [AppPlatform.ANDROID]: Entity.GROUPED_CRASH_ANDROID,
    [AppPlatform.APPLE_IOS]: Entity.GROUPED_CRASH_IOS,
  };
  const fieldsByPlatform = {
    [AppPlatform.ANDROID]: [
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_name,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_event_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_error_reason,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_device_uuid,
    ],
    [AppPlatform.APPLE_IOS]: [
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_name,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_event_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_reason,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_type,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_code,
      COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_device_uuid,
    ],
  };
  const groupedFieldsByPlatform = {
    [AppPlatform.ANDROID]: [COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_android_threshold],
    [AppPlatform.APPLE_IOS]: [COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_threshold],
  };
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.APTELIGENT, entitiesByPlatform[platform], mostRecentSortOn.by),
  });
  const groupedSortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.APTELIGENT, groupedEntitiesByPlatform[platform], mostRecentSortOn.by),
  });

  return [
    new PreviewReportContentRequest({
      entitiesByIntegration: {
        [Integration.APTELIGENT]: [entitiesByPlatform[platform]],
      },
      startDateMillis: apteligentCrumbListLocator.startTime,
      endDateMillis: apteligentCrumbListLocator.endTime,
      filter: getFQNFromMaskedName(Integration.APTELIGENT, entitiesByPlatform[platform], deviceFilterStr),
      fields: fieldsByPlatform[platform],
      pageSize: 1,
      offset: 0,
      sortOns: [sortOn],
    }),
    new PreviewReportContentRequest({
      entitiesByIntegration: {
        [Integration.APTELIGENT]: [groupedEntitiesByPlatform[platform]],
      },
      startDateMillis: apteligentCrumbListLocator.startTime,
      endDateMillis: apteligentCrumbListLocator.endTime,
      filter: getFQNFromMaskedName(Integration.APTELIGENT, groupedEntitiesByPlatform[platform], deviceFilterStr),
      fields: groupedFieldsByPlatform[platform],
      pageSize: 1,
      offset: 0,
      sortOns: [groupedSortOn],
    }),
  ];
}

/**
 * getAppErrorDetailsGroupEntity
 * @export
 * @param {AppErrorType} appErrorType
 * @param {string} platform
 * @returns {string}
 */
export function getAppErrorDetailsGroupEntity(appErrorType: AppErrorType, platform: string): string {
  switch (appErrorType) {
    case AppErrorType.CRASH:
      return platform === AppPlatform.ANDROID ? Entity.GROUPED_CRASH_ANDROID : Entity.GROUPED_CRASH_IOS;
    case AppErrorType.HANDLED_EXCEPTION:
      return platform === AppPlatform.ANDROID ? Entity.HANDLED_EXCEPTION_ANDROID : Entity.HANDLED_EXCEPTION_IOS;
    case AppErrorType.PLUGIN_EXCEPTION:
      return Entity.PLUGIN_EXCEPTION;
  }
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest } from '@dpa/ui-common';

import {
  CustomReport,
  CustomReportPreviewSearchResponse,
  ReportTemplate,
  Trend,
  WidgetDetailDefinition,
  WizardDialogMode,
} from '@ws1c/intelligence-models';

/**
 * ReportCommonState
 *
 * @export
 * @interface ReportCommonState
 */
export interface ReportCommonState {
  reportWidgetDetail: WidgetDetailDefinition;
  reportWidgetTrend: Trend;
  reportWidgetReturnPath: string;
  reportWizardMode: WizardDialogMode;
  isValidFilter: boolean;
  loadingPreview: boolean;
  refreshedAt: number;
  previewPageRequest: PagedRequest;
  preview: CustomReportPreviewSearchResponse;
  customReportDetails: CustomReport;
  selectedCategoryId: string;
  selectedTemplate: ReportTemplate;
  defaultCustomReportDetails: CustomReport;
}

/**
 * Defines initial state for ReportCommon
 *
 * @export
 * @type {ReportCommonState}
 */
export const initialReportCommonState: ReportCommonState = {
  reportWidgetDetail: undefined,
  reportWidgetTrend: undefined,
  reportWidgetReturnPath: undefined,
  reportWizardMode: undefined,
  isValidFilter: false,
  loadingPreview: false,
  customReportDetails: undefined,
  preview: new CustomReportPreviewSearchResponse(),
  previewPageRequest: undefined,
  refreshedAt: undefined,
  selectedCategoryId: undefined,
  selectedTemplate: undefined,
  defaultCustomReportDetails: null,
};

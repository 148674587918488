/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { each, get, mapValues, set } from 'lodash-es';

import { ACCORDION_WIZARD_CONFIG_STATE, AccordionWizardConfig } from '@ws1c/intelligence-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { QuickFilterSelectors } from '@ws1c/intelligence-core/store/quick-filter';
import { UserPreferenceFeatureControlsSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import {
  DeemThresholdSection,
  deepClone,
  Solution,
  SOLUTION_ACCORDION_WIZARD_IDS,
  SolutionConfig,
  SolutionConfigType,
  SolutionSetupCard,
  SolutionSetupCardType,
  SolutionStepConfig,
  SolutionSubmodule,
  Tag,
  UserTouchDetails,
} from '@ws1c/intelligence-models';
import { filterDeemSettingTags, filterThresholdSections } from './solution-setup-selector-helpers';
import { SolutionSetupState } from './solution-setup.state';

/**
 * SolutionSetupSelectors
 * @export
 * @class SolutionSetupSelectors
 */
export class SolutionSetupSelectors {
  /**
   * getSolutionSetupState
   * @static
   * @param {CoreAppState} state
   * @returns {SolutionSetupState}
   * @memberof SolutionSetupSelectors
   */
  public static getSolutionSetupState = (state: CoreAppState): SolutionSetupState => state.solutionSetupState;

  /**
   * getAccordionWizardConfigsById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, AccordionWizardConfig>>}
   * @returns {Record<string, AccordionWizardConfig>}
   * @memberof SolutionSetupSelectors
   */
  public static getAccordionWizardConfigsById: MemoizedSelector<CoreAppState, Record<string, AccordionWizardConfig>> = createSelector(
    SolutionSetupSelectors.getSolutionSetupState,
    (solutionSetupState: SolutionSetupState) => solutionSetupState.accordionWizardConfigsById,
  );

  /**
   * isSolutionSetupComplete
   * @static
   * @param {string} solution
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isSolutionSetupComplete = (solution: string): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      SolutionSetupSelectors.getAccordionWizardConfigsById,
      (accordionWizardConfigsById: Record<string, AccordionWizardConfig>) => {
        return SOLUTION_ACCORDION_WIZARD_IDS[solution]?.every((accordionWizardId: string) => {
          return accordionWizardConfigsById?.[accordionWizardId]?.isFinished;
        });
      },
    );

  /**
   * isSetupCompleteBySubmodule
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, boolean>>}
   * @memberof SolutionSetupSelectors
   */
  public static isSetupCompleteBySubmodule: MemoizedSelector<CoreAppState, Record<string, boolean>> = createSelector(
    SolutionSetupSelectors.getAccordionWizardConfigsById,
    (accordionWizardConfigsById: Record<string, AccordionWizardConfig>) => {
      return mapValues(SolutionConfig.accordionIdBySubmodule, (accordionId: string) => accordionWizardConfigsById[accordionId]?.isFinished);
    },
  );

  /**
   * isSetupInProgressBySubmodule
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, boolean>>}
   * @memberof SolutionSetupSelectors
   */
  public static isSetupInProgressBySubmodule: MemoizedSelector<CoreAppState, Record<string, boolean>> = createSelector(
    SolutionSetupSelectors.getAccordionWizardConfigsById,
    (accordionWizardConfigsById: Record<string, AccordionWizardConfig>) => {
      return mapValues(
        SolutionConfig.accordionIdBySubmodule,
        (accordionId: string) => accordionWizardConfigsById[accordionId]?.isInProgress,
      );
    },
  );

  /**
   * isSubmoduleSetupComplete
   * @static
   * @param {string} submodule
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isSubmoduleSetupComplete = (submodule: string): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      SolutionSetupSelectors.isSetupCompleteBySubmodule,
      (isSetupCompleteBySubmodule: Record<string, boolean>) => isSetupCompleteBySubmodule[submodule],
    );

  /**
   * isSubmoduleSetupInProgress
   * @static
   * @param {string} submodule
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isSubmoduleSetupInProgress = (submodule: string): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      SolutionSetupSelectors.isSetupInProgressBySubmodule,
      (isSetupInProgressBySubmodule: Record<string, boolean>) => isSetupInProgressBySubmodule[submodule],
    );

  /**
   * isAnySubmoduleSetupComplete
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isAnySubmoduleSetupComplete: MemoizedSelector<CoreAppState, boolean> = createSelector(
    SolutionSetupSelectors.isSetupCompleteBySubmodule,
    (isSetupCompleteBySubmodule: Record<string, boolean>) => Object.values(isSetupCompleteBySubmodule).some((isReady: boolean) => isReady),
  );

  /**
   * isSolutionEnabled
   * @static
   * @param {Solution} solution
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isSolutionEnabled = (solution: Solution): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      UserPreferenceFeatureControlsSelectors.isSolutionsDeemPhysicalEnabled,
      UserPreferenceFeatureControlsSelectors.isSolutionsDeemVirtualEnabled,
      UserPreferenceFeatureControlsSelectors.isSolutionsCveEnabled,
      (isSolutionsDeemPhysicalEnabled: boolean, isSolutionsDeemVirtualEnabled: boolean, isSolutionsCveEnabled: boolean) => {
        switch (solution) {
          case Solution.DEEM: {
            return isSolutionsDeemPhysicalEnabled || isSolutionsDeemVirtualEnabled;
          }
          case Solution.CVE: {
            return isSolutionsCveEnabled;
          }
        }
        return false;
      },
    );

  /**
   * isDeemPhysicalReady
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isDeemPhysicalReady: MemoizedSelector<CoreAppState, boolean> = createSelector(
    SolutionSetupSelectors.isSubmoduleSetupComplete(SolutionSubmodule.DESKTOP_EXPERIENCE_MANAGEMENT),
    SolutionSetupSelectors.isSubmoduleSetupComplete(SolutionSubmodule.MOBILE_EXPERIENCE_MANAGEMENT),
    UserPreferenceFeatureControlsSelectors.isSolutionsDeemPhysicalEnabled,
    (isDesktopSubmoduleSetupComplete: boolean, isMobileSubmoduleSetupComplete: boolean, isDeemPhysicalEnabled: boolean) =>
      (isDesktopSubmoduleSetupComplete || isMobileSubmoduleSetupComplete) && isDeemPhysicalEnabled,
  );

  /**
   * isDeemVirtualReady
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isDeemVirtualReady: MemoizedSelector<CoreAppState, boolean> = createSelector(
    SolutionSetupSelectors.isSubmoduleSetupComplete(SolutionSubmodule.HORIZON_CLOUD_SERVICES),
    UserPreferenceFeatureControlsSelectors.isSolutionsDeemVirtualEnabled,
    (isSubmoduleSetupComplete: boolean, isDeemVirtualEnabled: boolean) => isSubmoduleSetupComplete && isDeemVirtualEnabled,
  );

  /**
   * isAllDeemSolutionsReady
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isAllDeemSolutionsReady: MemoizedSelector<CoreAppState, boolean> = createSelector(
    SolutionSetupSelectors.isDeemPhysicalReady,
    SolutionSetupSelectors.isDeemVirtualReady,
    (isDeemPhysicalEnabled: boolean, isDeemVirtualEnabled: boolean) => isDeemPhysicalEnabled && isDeemVirtualEnabled,
  );

  /**
   * isAnyDeemSolutionsReady
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isAnyDeemSolutionsReady: MemoizedSelector<CoreAppState, boolean> = createSelector(
    SolutionSetupSelectors.isDeemPhysicalReady,
    SolutionSetupSelectors.isDeemVirtualReady,
    (isDeemPhysicalEnabled: boolean, isDeemVirtualEnabled: boolean) => isDeemPhysicalEnabled || isDeemVirtualEnabled,
  );

  /**
   * isCveReady
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof SolutionSetupSelectors
   */
  public static isCveReady: MemoizedSelector<CoreAppState, boolean> = createSelector(
    SolutionSetupSelectors.isSolutionSetupComplete(Solution.CVE),
    UserPreferenceFeatureControlsSelectors.isSolutionsCveEnabled,
    (isCveReady: boolean, isCveEnabled: boolean) => isCveReady && isCveEnabled,
  );

  /**
   * getUserTouchDetailsBySolutionConfigType
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, UserTouchDetails>>}
   * @returns {Record<string, UserTouchDetails>}
   * @memberof SolutionSetupSelectors
   */
  public static getUserTouchDetailsBySolutionConfigType: MemoizedSelector<CoreAppState, Record<string, UserTouchDetails>> = createSelector(
    SolutionSetupSelectors.getSolutionSetupState,
    (solutionSetupState: SolutionSetupState) => solutionSetupState.userTouchDetailsByConfigType,
  );

  /**
   * getRemainingTimeForSolutionDataGeneration
   *
   * @static
   * @param {SolutionConfigType} subType
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof SolutionSetupSelectors
   */
  public static getRemainingTimeForSolutionDataGeneration = (subType: SolutionConfigType): MemoizedSelector<CoreAppState, number> =>
    createSelector(SolutionSetupSelectors.getUserTouchDetailsBySolutionConfigType, (userTouchDetails: Record<string, UserTouchDetails>) => {
      const dataGenerationTimeBySolution: Record<string, number> = SolutionConfig.dataGenerationTimeBySolutionV2;
      if (userTouchDetails[subType]?.modifiedAt) {
        const pastTime = Date.now() - userTouchDetails[subType]?.modifiedAt;
        const leftTime = dataGenerationTimeBySolution[subType] - pastTime;
        return leftTime >= 0 ? leftTime : 0;
      }
      return dataGenerationTimeBySolution[subType];
    });

  /**
   * getStepNamesByGroupNameBySolution
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @returns {GenericObject}
   * @memberof SolutionSetupSelectors
   */
  public static getStepNamesByGroupNameBySolution: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    UserPreferenceSelectors.getEnabledCveSolutionPlatforms,
    (enabledCvePlatforms: string[]) => {
      // toggle cve platform steps by feature flag
      const stepNames = deepClone(SolutionConfig.stepNamesByGroupNameBySolutionConfigType);
      stepNames.cve.review_settings = enabledCvePlatforms.map((platform: string) => `sla_${platform}`);
      return stepNames;
    },
  );

  /**
   * getSolutionStepConfigBySolution
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, SolutionStepConfig>>}
   * @returns {Record<string, SolutionStepConfig>}
   * @memberof SolutionSetupSelectors
   */
  public static getSolutionStepConfigBySolution: MemoizedSelector<CoreAppState, Record<string, SolutionStepConfig>> = createSelector(
    SolutionSetupSelectors.getAccordionWizardConfigsById,
    SolutionSetupSelectors.getStepNamesByGroupNameBySolution,
    (accordionWizardConfigsById: Record<string, AccordionWizardConfig>, stepNamesByGroupNameBySolution: GenericObject) => {
      const config = {} as GenericObject;
      each(accordionWizardConfigsById, (accordionWizardConfig: AccordionWizardConfig, accordionWizardId: string) => {
        const groupNameAndSolution = SolutionConfig.stepGroupAndSolutionConfigTypeByWizardId[accordionWizardId];
        const finishedStepIndices = Array.from(accordionWizardConfig.finishedStepIndices);
        finishedStepIndices.forEach((index: number) => {
          const stepName = get(stepNamesByGroupNameBySolution, groupNameAndSolution)[index];
          set(config, [...groupNameAndSolution, stepName], ACCORDION_WIZARD_CONFIG_STATE.COMPLETED);
        });
      });
      return mapValues(config, (solutionStepConfig: SolutionStepConfig, solutionConfigType: SolutionConfigType) => {
        return new SolutionStepConfig({
          type: solutionConfigType,
          config: solutionStepConfig,
        });
      });
    },
  );

  /**
   * getDeemSettingQuickFilterTags
   * @static
   * @type {MemoizedSelector<CoreAppState, Tag[]>}
   * @memberof SolutionSetupSelectors
   */
  public static getDeemSettingQuickFilterTags: MemoizedSelector<CoreAppState, Tag[]> = createSelector(
    QuickFilterSelectors.getTags,
    SolutionSetupSelectors.isDeemPhysicalReady,
    SolutionSetupSelectors.isDeemVirtualReady,
    (tags: Tag[], isDeemPhysicalEnabled: boolean, isDeemVirtualEnabled: boolean) =>
      filterDeemSettingTags(isDeemPhysicalEnabled, isDeemVirtualEnabled, tags),
  );

  /**
   * getThresholdSections
   * @static
   * @param {DeemThresholdSection[]} sections
   * @type {MemoizedSelector<CoreAppState, DeemThresholdSection[]>}
   * @memberof SolutionSetupSelectors
   */
  public static getThresholdSections = (sections: DeemThresholdSection[]): MemoizedSelector<CoreAppState, DeemThresholdSection[]> =>
    createSelector(
      SolutionSetupSelectors.isDeemPhysicalReady,
      SolutionSetupSelectors.isDeemVirtualReady,
      (isDeemPhysicalEnabled: boolean, isDeemVirtualEnabled: boolean) =>
        filterThresholdSections(isDeemPhysicalEnabled, isDeemVirtualEnabled, sections),
    );

  /**
   * getVisibleSetupCards
   * @static
   * @param {Solution} solution
   * @memberof SolutionSetupSelectors
   */
  public static getVisibleSetupCards: MemoizedSelector<CoreAppState, SolutionSetupCard[]> = createSelector(
    SolutionSetupSelectors.isSetupCompleteBySubmodule,
    SolutionSetupSelectors.isSetupInProgressBySubmodule,
    SolutionSetupSelectors.isSolutionEnabled(Solution.DEEM),
    (
      isSetupCompleteBySubmodule: Record<string, boolean>,
      isSetupInProgressBySubmodule: Record<string, boolean>,
      isSolutionEnabled: boolean,
    ) => {
      const cards = [];
      for (const submoduleType in SolutionSubmodule) {
        const card = SolutionConfig.getDeemSetupCard(
          SolutionSetupCardType[submoduleType],
          isSetupCompleteBySubmodule[SolutionSubmodule[submoduleType]],
          isSetupInProgressBySubmodule[SolutionSubmodule[submoduleType]],
          isSolutionEnabled,
        );
        cards.push(card);
      }
      return cards;
    },
  );
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { TrialActivationDetail, TrialDetail, TrialUserContactDetails } from '@ws1c/intelligence-models';

/**
 * TrialServicesActions
 *
 * @export
 * @class TrialServicesActions
 */
export class TrialServicesActions {
  public static readonly CATEGORY = '[TRIAL_SERVICES]';

  private static type = (actionName: string): string => `${TrialServicesActions.CATEGORY} ${actionName}`;

  public static getTrialDetails = createAction(TrialServicesActions.type('GET_TRIAL_DETAILS'));

  public static getTrialDetailsSuccess = createAction(
    TrialServicesActions.type('GET_TRIAL_DETAILS_SUCCESS'),
    props<{ trialDetails: TrialDetail[] }>(),
  );

  public static getTrialDetailsFailure = createAction(TrialServicesActions.type('GET_TRIAL_DETAILS_FAILURE'));

  public static refreshTrialStatus = createAction(TrialServicesActions.type('REFRESH_TRIAL_STATUS'));

  public static getTrialDetailsForServiceType = createAction(
    TrialServicesActions.type('GET_TRIAL_DETAILS_FOR_SERVICE_TYPE'),
    props<{ serviceType: string }>(),
  );

  public static getTrialDetailsForServiceTypeSuccess = createAction(
    TrialServicesActions.type('GET_TRIAL_DETAILS_FOR_SERVICE_TYPE_SUCCESS'),
    props<{ trialDetails: TrialDetail[]; serviceType: string }>(),
  );

  public static getTrialDetailsForServiceTypeFailure = createAction(
    TrialServicesActions.type('GET_TRIAL_DETAILS_FOR_SERVICE_TYPE_FAILURE'),
    props<{ serviceType: string }>(),
  );

  public static startTrialV2 = createAction(TrialServicesActions.type('START_TRIAL_V2'), props<{ serviceType: string }>());

  public static startTrialV2Success = createAction(
    TrialServicesActions.type('START_TRIAL_V2_SUCCESS'),
    props<{ trialActivationDetail: TrialActivationDetail }>(),
  );

  public static startTrialV2Failure = createAction(TrialServicesActions.type('START_TRIAL_V2_FAILURE'), props<{ serviceType: string }>());

  public static getTrialUserContactDetailsV2 = createAction(TrialServicesActions.type('GET_TRIAL_USER_CONTACT_DETAILS_V2'));

  public static getTrialUserContactDetailsV2Success = createAction(
    `${TrialServicesActions.CATEGORY} GET_TRIAL_USER_CONTACT_DETAILS_V2_SUCCESS`,
    props<{ trialUserContactDetails: TrialUserContactDetails }>(),
  );

  public static getTrialUserContactDetailsV2Failure = createAction(TrialServicesActions.type('GET_TRIAL_USER_CONTACT_DETAILS_V2_FAILURE'));

  public static readonly setTrialServicesModalOpenState = createAction(
    TrialServicesActions.type('SET_TRIAL_SERVICES_MODAL_OPEN_STATE'),
    props<{ isOpen: boolean }>(),
  );

  public static readonly showTrialUserContactDetailsForm = createAction(
    TrialServicesActions.type('SHOW_TRIAL_USER_CONTACT_DETAILS_FORM'),
    props<{ showTrialUserContactDetailsForm: boolean }>(),
  );

  public static readonly acceptTrialUserContactDetails = createAction(
    TrialServicesActions.type('ACCEPT_TRIAL_USER_CONTACT_DETAILS'),
    props<{ isAccepted: boolean }>(),
  );

  public static collectTrialUserDetailsOrStartTrialFromWorkspace = createAction(
    TrialServicesActions.type('COLLECT_TRIAL_USER_DETAILS_OR_START_TRIAL_FROM_WORKSPACE'),
    props<{ serviceType: string }>(),
  );

  public static openTrialServicesModalAndCollectUserContactDetails = createAction(
    TrialServicesActions.type('OPEN_TRIAL_SERVICES_MODAL_AND_COLLECT_USER_CONTACT_DETAILS'),
  );
}

/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, RuleGroupOperator, SortOn } from '@dpa/ui-common';

import {
  Category,
  Column,
  COLUMN_NAMES,
  DashboardConfig,
  Entity,
  FilterRule,
  getFQN,
  Integration,
  NetEventsLogTabName,
  NetworkInsightsSearchRequest,
  PreviewReportContentRequest,
  QueryBuilder,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  TrendDateRange,
  TrendMode,
} from '@ws1c/intelligence-models';

/**
 * buildStandardAppNetworkInsightsDashboardRequest
 * @param {string} appId
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {string} hostName
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @param {RuleGroup} networkInsightsSubFilterRuleGroup
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardAppNetworkInsightsDashboardRequest(
  appId: string,
  apteligentId: string,
  appPlatform: string,
  hostName: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
  networkInsightsSubFilterRuleGroup: RuleGroup,
): StandardDashboardRequest {
  const hostNameFilterRule = new FilterRule({
    attribute: getFQN(DashboardConfig.Integration, DashboardConfig.Entity, COLUMN_NAMES.byName._url_host),
    condition: '=',
    data: [hostName],
  });
  const filterRules: FilterRule[] = [
    ...(hostName ? [hostNameFilterRule] : []),
    ...((appDashboardFilterRuleGroup?.rules as FilterRule[]) ?? []),
    ...((networkInsightsSubFilterRuleGroup?.rules as FilterRule[]) ?? []),
  ].filter((filterRule: FilterRule) => !filterRule.isMissingFields());
  return new StandardDashboardRequest(StandardDashboardType.NETWORK_INSIGHTS, new RuleGroup(filterRules), appDashboardTrendDateRange, {
    app_package_id: appId,
    _device_platform: appPlatform,
    apteligent_id: apteligentId,
  });
}

/**
 * getNetworkInsightsSubFilterColumns
 * @param {string} networkInsightsHost
 * @param {Map<Category, Column[]>} columnsByCategory
 * @param {Category} networkInsightsCategory
 * @returns {Column[]}
 */
export function getNetworkInsightsSubFilterColumns(
  networkInsightsHost: string,
  columnsByCategory: Map<Category, Column[]>,
  networkInsightsCategory: Category,
): Column[] {
  const networkInsightsColumns = columnsByCategory.get(networkInsightsCategory) || [];
  const networkInsightsSubFilterColumnNames = networkInsightsHost
    ? new Set([
        COLUMN_NAMES.byName.system_version,
        COLUMN_NAMES.byName.carrier,
        COLUMN_NAMES.byName._device_friendly_name,
        COLUMN_NAMES.byName.http_method,
      ])
    : new Set([COLUMN_NAMES.byName._url_domain, COLUMN_NAMES.byName._url_scheme, COLUMN_NAMES.byName._url_host]);

  const networkInsightsSubFilterColumns = networkInsightsColumns.filter((column: Column) => {
    return networkInsightsSubFilterColumnNames.has(column.name);
  });
  return networkInsightsSubFilterColumns;
}

/**
 * getNetworkInsightsTrendDefinitionOverridesByStandardWidgetSubtype
 * @param  {string} groupBy
 * @returns {any}
 */
export function getNetworkInsightsTrendDefinitionOverridesByStandardWidgetSubtype(groupBy: string): any {
  if (!groupBy) {
    return {};
  }
  const subTypesToOverride = [
    StandardWidgetSubtype.NETWORK_INSIGHTS_AVG_LATENCY,
    StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_RECEIVED,
    StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_SENT,
    StandardWidgetSubtype.NETWORK_INSIGHTS_NET_ERRORS,
    StandardWidgetSubtype.NETWORK_INSIGHTS_NET_EVENT_ERROR,
    StandardWidgetSubtype.NETWORK_INSIGHTS_NET_EVENT,
  ];

  const overrides = {};
  subTypesToOverride.forEach((subtype: StandardWidgetSubtype) => {
    overrides[StandardWidgetSubtype[subtype]] = {
      bucketingAttributes: [groupBy],
    };
  });
  return overrides;
}

/**
 * getNetworkInsightsTableSearchRequest
 * @param {string} apteligentIdFilterString
 * @param {TrendDateRange} trendDateRange
 * @returns {NetworkInsightsSearchRequest}
 */
export function getNetworkInsightsTableSearchRequest(
  apteligentIdFilterString: string,
  trendDateRange: TrendDateRange,
): NetworkInsightsSearchRequest {
  return Object.assign(new NetworkInsightsSearchRequest(), {
    filter: apteligentIdFilterString,
    ...trendDateRange.getStartEndMillis(),
  });
}

/**
 * getNetworkInsightsLogSearchRequest
 * @param {string} selectedTabName
 * @param {TrendDateRange} trendDateRange
 * @param {string} apteligentId
 * @param {string} networkInsightsHost
 * @param {string[]} columnNamesForSearch
 * @param {SortOn[]} sortOns
 * @param {PagedRequest} pagedRequest
 * @param {string[]} searchableColumnNames
 * @param {string} searchQuery
 * @returns {PreviewReportContentRequest}
 */
export function getNetworkInsightsLogSearchRequest(
  selectedTabName: string,
  trendDateRange: TrendDateRange,
  apteligentId: string,
  networkInsightsHost: string,
  columnNamesForSearch: string[],
  sortOns: SortOn[],
  pagedRequest: PagedRequest,
  searchableColumnNames: string[],
  searchQuery: string,
): PreviewReportContentRequest {
  const entitiesByIntegrationByTabName = {
    [NetEventsLogTabName.NET_EVENT]: { [Integration.APTELIGENT]: [Entity.NET_EVENT] },
    [NetEventsLogTabName.NET_ERROR]: { [Integration.APTELIGENT]: [Entity.NET_ERROR] },
  };
  const queryBuilder = new QueryBuilder();
  queryBuilder.group.rules = [
    new FilterRule({
      attribute: COLUMN_NAMES.byName.app_id,
      condition: '=',
      data: [apteligentId],
    }),
    new FilterRule({
      attribute: COLUMN_NAMES.byName._url_host,
      condition: '=',
      data: [networkInsightsHost],
    }),
  ];

  const searchQueryBuilder = new QueryBuilder();
  searchQueryBuilder.group.operator = RuleGroupOperator.OR;
  searchQueryBuilder.group.rules = searchableColumnNames.map((columnName: string) => {
    return new FilterRule({
      attribute: columnName,
      condition: 'CONTAINS',
      data: [searchQuery],
    });
  });

  const filterString = searchQuery
    ? `${queryBuilder.getQueryString()} AND (${searchQueryBuilder.getQueryString()})`
    : queryBuilder.getQueryString();

  return Object.assign(new PreviewReportContentRequest(), {
    entitiesByIntegration: entitiesByIntegrationByTabName[selectedTabName],
    trendMode: TrendMode.HISTORICAL,
    filter: filterString,
    offset: pagedRequest.from,
    pageSize: pagedRequest.size,
    fields: columnNamesForSearch,
    sortOns,
    ...trendDateRange.getStartEndMillis(),
  });
}

/**
 * getNetworkInsightsSearchableColumnNames
 * @param  {string}   networkInsightsLogSelectedTabName
 * @returns {string[]}
 */
export function getNetworkInsightsSearchableColumnNames(networkInsightsLogSelectedTabName: string): string[] {
  const stringColumnsByTabName = {
    [NetEventsLogTabName.NET_EVENT]: [
      COLUMN_NAMES.byName._url,
      COLUMN_NAMES.byName._http_status_message,
      COLUMN_NAMES.byName._app_version,
      COLUMN_NAMES.byName.system_version,
      COLUMN_NAMES.byName.carrier,
      COLUMN_NAMES.byName.device_model,
    ],
    [NetEventsLogTabName.NET_ERROR]: [
      COLUMN_NAMES.byName._url,
      COLUMN_NAMES.byName._net_error_msg,
      COLUMN_NAMES.byName._app_version,
      COLUMN_NAMES.byName.system_version,
      COLUMN_NAMES.byName.carrier,
      COLUMN_NAMES.byName.device_model,
    ],
  };
  return stringColumnsByTabName[networkInsightsLogSelectedTabName] || [];
}

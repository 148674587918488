/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { LOAD_STATE, Template } from '@ws1c/intelligence-models';

import { TemplateCommonActions } from './template-common.actions';
import { initialTemplateCommonState, TemplateCommonState } from './template-common.store';

const _reducer: ActionReducer<TemplateCommonState, Action> = createReducer(
  initialTemplateCommonState,

  on(
    TemplateCommonActions.setTags,
    (state: TemplateCommonState, { tags }: ReturnType<typeof TemplateCommonActions.setTags>): TemplateCommonState => ({
      ...state,
      tags,
    }),
  ),

  on(
    TemplateCommonActions.loadTemplates,
    (state: TemplateCommonState, { templateType }: ReturnType<typeof TemplateCommonActions.loadTemplates>): TemplateCommonState => ({
      ...state,
      templateLoadStatusByType: {
        ...state.templateLoadStatusByType,
        [templateType]: LOAD_STATE.IN_FLIGHT,
      },
    }),
  ),

  on(
    TemplateCommonActions.loadTemplatesSuccess,
    (
      state: TemplateCommonState,
      { templateType, templates }: ReturnType<typeof TemplateCommonActions.loadTemplatesSuccess>,
    ): TemplateCommonState => ({
      ...state,
      templatesByType: {
        ...state.templatesByType,
        [templateType]: templates,
      },
      templateLoadStatusByType: {
        ...state.templateLoadStatusByType,
        [templateType]: LOAD_STATE.SUCCESS,
      },
    }),
  ),

  on(
    TemplateCommonActions.loadTemplatesFailure,
    (state: TemplateCommonState, { templateType }: ReturnType<typeof TemplateCommonActions.loadTemplatesFailure>): TemplateCommonState => ({
      ...state,
      templateLoadStatusByType: {
        ...state.templateLoadStatusByType,
        [templateType]: LOAD_STATE.FAILURE,
      },
    }),
  ),

  on(
    TemplateCommonActions.toggleTemplateBookmark,
    (state: TemplateCommonState): TemplateCommonState => ({
      ...state,
      bookmarkSaveStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    TemplateCommonActions.toggleTemplateBookmarkSuccess,
    (
      state: TemplateCommonState,
      { templateType, template }: ReturnType<typeof TemplateCommonActions.toggleTemplateBookmarkSuccess>,
    ): TemplateCommonState => {
      const newTemplates: Template[] = state.templatesByType[templateType]?.map((currentTemplate: Template) =>
        currentTemplate.id === template.id ? template : currentTemplate,
      );
      return {
        ...state,
        templatesByType: {
          ...state.templatesByType,
          [templateType]: newTemplates,
        },
        bookmarkSaveStatus: LOAD_STATE.SUCCESS,
      };
    },
  ),

  on(TemplateCommonActions.loadTemplateByName, (state: TemplateCommonState): TemplateCommonState => {
    return {
      ...state,
      templateLoadStatus: LOAD_STATE.IN_FLIGHT,
    };
  }),

  on(
    TemplateCommonActions.loadTemplateByNameSuccess,
    (state: TemplateCommonState, { template }: ReturnType<typeof TemplateCommonActions.loadTemplateByNameSuccess>): TemplateCommonState => {
      return {
        ...state,
        templateLoadStatus: LOAD_STATE.SUCCESS,
        templateByMarketplaceResourceId: {
          ...state.templateByMarketplaceResourceId,
          [template?.marketplaceResourceId]: template,
        },
      };
    },
  ),

  on(TemplateCommonActions.loadTemplateByNameFailure, (state: TemplateCommonState): TemplateCommonState => {
    return {
      ...state,
      templateLoadStatus: LOAD_STATE.FAILURE,
    };
  }),

  on(
    TemplateCommonActions.toggleTemplateBookmarkFailure,
    (state: TemplateCommonState): TemplateCommonState => ({
      ...state,
      bookmarkSaveStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    TemplateCommonActions.updateDeploymentConfirmationModalStatus,
    (
      state: TemplateCommonState,
      { status }: ReturnType<typeof TemplateCommonActions.updateDeploymentConfirmationModalStatus>,
    ): TemplateCommonState => ({
      ...state,
      isDeployConfirmationModalOpen: status,
    }),
  ),

  on(
    TemplateCommonActions.deployTemplate,
    (state: TemplateCommonState): TemplateCommonState => ({
      ...state,
      deploymentStatus: LOAD_STATE.IN_FLIGHT,
      isDeployConfirmationModalOpen: true,
    }),
  ),

  on(
    TemplateCommonActions.deployTemplateSuccess,
    (state: TemplateCommonState): TemplateCommonState => ({
      ...state,
      deploymentStatus: LOAD_STATE.SUCCESS,
      isDeployConfirmationModalOpen: false,
    }),
  ),

  on(
    TemplateCommonActions.deployTemplateFailure,
    (state: TemplateCommonState): TemplateCommonState => ({
      ...state,
      deploymentStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    TemplateCommonActions.loadTemplateDeployValidations,
    (state: TemplateCommonState): TemplateCommonState => ({
      ...state,
      deploymentValidationLoadingStatus: LOAD_STATE.IN_FLIGHT,
      isDeployConfirmationModalOpen: true,
    }),
  ),

  on(
    TemplateCommonActions.loadTemplateDeployValidationsSuccess,
    (
      state: TemplateCommonState,
      { validationMessages }: ReturnType<typeof TemplateCommonActions.loadTemplateDeployValidationsSuccess>,
    ): TemplateCommonState => ({
      ...state,
      deploymentValidationLoadingStatus: LOAD_STATE.SUCCESS,
      deploymentValidationMessages: validationMessages,
    }),
  ),

  on(
    TemplateCommonActions.loadTemplateDeployValidationsFailure,
    (state: TemplateCommonState): TemplateCommonState => ({
      ...state,
      deploymentValidationLoadingStatus: LOAD_STATE.FAILURE,
      isDeployConfirmationModalOpen: false,
    }),
  ),
);

/**
 * Creates and returns TemplateCommonState
 * @export
 * @param {TemplateCommonState} state - Holds state object of type TemplateCommon
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {TemplateCommonState}
 */
export function templateCommonState(state: TemplateCommonState, action: Action): TemplateCommonState {
  return _reducer(state, action);
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { FeatureControl, Features, UserScopes } from '@ws1c/intelligence-common';
import { FeatureOperator, NavigationMenu, NavigationMenuItem, ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * NAVIGATION_MENU_CONFIG
 * @export
 * @type {NavigationMenu}
 */
export const NAVIGATION_MENU_CONFIG: NavigationMenu = {
  [NavigationMenuItem.INTEGRATIONS]: [
    {
      iconName: 'data-cluster',
      labelKey: 'NAVIGATION.DATA_SOURCES',
      route: `/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS}`,
      scopes: [
        UserScopes.SETTINGS_READONLY,
        UserScopes.IAM_INTEGRATION_READONLY,
        UserScopes.PARTNER_READONLY,
        UserScopes.EVENT_FORWARDER_READONLY,
        UserScopes.INTEGRATION_REGISTRATION_READONLY,
      ],
    },
    {
      featureControlKey: FeatureControl.AUTOMATION_ENABLED,
      iconClass: 'integration-icon',
      labelKey: 'NAVIGATION.WORKFLOW_CONNECTORS',
      route: `/${ROUTE_NAMES.INTEGRATIONS.OUTBOUND_CONNECTORS}`,
      scopes: [UserScopes.WORKFLOW_READONLY],
    },
  ],
  [NavigationMenuItem.INTEGRATIONS_CMS]: [
    {
      iconName: 'data-cluster',
      labelKey: 'NAVIGATION.DATA_SOURCES',
      route: `/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS}`,
      scopes: [
        UserScopes.SETTINGS_READONLY,
        UserScopes.IAM_INTEGRATION_READONLY,
        UserScopes.PARTNER_READONLY,
        UserScopes.EVENT_FORWARDER_READONLY,
        UserScopes.INTEGRATION_REGISTRATION_READONLY,
      ],
    },
    {
      featureControlKey: FeatureControl.AUTOMATION_ENABLED,
      iconClass: 'integration-icon',
      labelKey: 'NAVIGATION.WORKFLOW_CONNECTORS',
      route: `/${ROUTE_NAMES.INTEGRATIONS.WORKFLOW_CONNECTORS}`,
      scopes: [UserScopes.WORKFLOW_READONLY],
    },
  ],
  [NavigationMenuItem.SETTINGS]: [
    {
      featureControlKey: FeatureControl.DATA_ACCESS_POLICY_ENABLED,
      iconName: 'data-cluster',
      labelKey: 'NAVIGATION.DATA_ACCESS_POLICY',
      route: `/${ROUTE_NAMES.SETTINGS.DATA_ACCESS_POLICY}`,
      scopes: [UserScopes.DATA_ACCESS_POLICY],
    },
    {
      featureControlKey: FeatureControl.SERVICE_ACCOUNTS_ENABLED,
      iconName: 'key',
      labelKey: 'NAVIGATION.SERVICE_ACCOUNTS',
      route: `/${ROUTE_NAMES.SETTINGS.SERVICE_ACCOUNT}`,
      scopes: [UserScopes.OAUTH_READONLY],
    },
    {
      featureControlKey: FeatureControl.SYSTEM_LIMITS_ENABLED,
      iconName: 'dashboard',
      labelKey: 'NAVIGATION.SYSTEM_LIMITS',
      route: `/${ROUTE_NAMES.SETTINGS.SYSTEM_LIMITS}`,
      scopes: [UserScopes.SYSTEM_LIMIT],
    },
  ],
  [NavigationMenuItem.NOTIFICATION]: [
    {
      labelKey: 'NAVIGATION.NOTIFICATION_CENTER',
      route: `${ROUTE_NAMES.NOTIFICATION.CENTER}`,
    },
    {
      labelKey: 'NAVIGATION.SETTINGS',
      featureControlKey: FeatureControl.NOTIFICATION_RULES_ENABLED,
      route: `${ROUTE_NAMES.NOTIFICATION.SETTINGS}`,
    },
  ],

  // WORKSPACE ---------------------------------------------------------------------------------------------
  [NavigationMenuItem.WORKSPACE]: [
    {
      labelKey: 'NAVIGATION.MY_WORKSPACE',
      route: `/${ROUTE_NAMES.WORKSPACE.MY_WORKSPACE}`,
      iconName: 'user',
      bold: true,
    },
    {
      featureControlKey: FeatureControl.UI_DATA_EXPLORER_ENABLED,
      labelKey: 'NAVIGATION.DATA_EXPLORER',
      route: `/${ROUTE_NAMES.WORKSPACE.HOME}/${ROUTE_NAMES.WORKSPACE.DATA_EXPLORER}`,
      iconName: 'compass',
      bold: true,
      hasBetaFlag: true,
    },
    {
      featureControlKey: FeatureControl.DASHBOARDS_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS',
      route: ROUTE_NAMES.DASHBOARD.MY_DASHBOARDS,
      scopes: [UserScopes.DASHBOARD_READONLY, UserScopes.DASHBOARD_ADMIN],
      iconName: 'dashboard',
      bold: true,
    },
    {
      featureControlKey: FeatureControl.AUTOMATION_ENABLED,
      labelKey: 'NAVIGATION.FREESTYLE',
      route: ROUTE_NAMES.AUTOMATION.HOME,
      scopes: [UserScopes.WORKFLOW_READONLY, UserScopes.WORKFLOW_ADMIN],
      iconName: 'dpa-workflow',
      bold: true,
    },
    {
      featureControlKey: FeatureControl.REPORT_ENABLED,
      labelKey: 'NAVIGATION.REPORTS',
      route: ROUTE_NAMES.REPORT.HOME,
      scopes: [UserScopes.REPORT_READONLY, UserScopes.REPORT_ADMIN],
      iconName: 'file',
      bold: true,
    },
  ],
  [NavigationMenuItem.WORKSPACE_UEM]: [
    {
      featureControlKey: FeatureControl.DASHBOARD_APP_ADOPTION_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_ENGAGEMENT',
      route: ROUTE_NAMES.DASHBOARD.APP,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.DASHBOARD_DEVICES_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_DEVICES',
      route: ROUTE_NAMES.DASHBOARD.DEVICES,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.DASHBOARD_ENROLLMENT_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_UPDATES',
      route: ROUTE_NAMES.DASHBOARD.OS,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
  ],
  [NavigationMenuItem.WORKSPACE_HORIZON]: [
    {
      featureControlKey: FeatureControl.DASHBOARD_HORIZON_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_HORIZON_CLOUD_V1',
      route: ROUTE_NAMES.DASHBOARD.HORIZON_MONITORING,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.DASHBOARD_HORIZON_TITAN_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_HORIZON_CLOUD_TITAN',
      route: ROUTE_NAMES.DASHBOARD.HORIZON_MONITORING_TITAN,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
  ],
  [NavigationMenuItem.WORKSPACE_EXPERIENCE_MANAGEMENT]: [
    {
      featureGroup: {
        features: [Features.SOLUTION_DEEM_DASHBOARD_PHYSICAL, Features.SOLUTION_DEEM_DASHBOARD_VIRTUAL],
        operator: FeatureOperator.OR,
      },
      labelKey: 'NAVIGATION.EXPERIENCE_SCORE',
      route: ROUTE_NAMES.SOLUTIONS_DEEM,
    },
    {
      featureControlKey: FeatureControl.SOLUTIONS_DEEM_INSIGHTS_ENABLED,
      labelKey: 'COMMON_MESSAGES.INSIGHTS',
      route: ROUTE_NAMES.SOLUTIONS_DEEM_INSIGHTS,
      scopes: [UserScopes.DASHBOARD_READONLY, UserScopes.INSIGHT_READONLY],
    },
    {
      featureControlKey: FeatureControl.SOLUTIONS_DEEM_INCIDENT_ENABLED,
      labelKey: 'NAVIGATION.INCIDENTS',
      route: ROUTE_NAMES.SOLUTIONS_DEEM_INCIDENTS,
      scopes: [UserScopes.DASHBOARD_READONLY, UserScopes.INCIDENT_READONLY],
    },
    {
      featureControlKey: FeatureControl.SOLUTIONS_DEEM_SAM_ENABLED,
      labelKey: 'NAVIGATION.SOFTWARE_ASSET_MANAGEMENT',
      route: ROUTE_NAMES.SOLUTIONS_DEEM_SAM,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.SOLUTIONS_DEEM_HUB_NOTIFICATIONS,
      labelKey: 'NAVIGATION.HUB_NOTIFICATIONS',
      route: ROUTE_NAMES.SOLUTIONS_DEEM_HUB_NOTIFICATIONS,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.SOLUTIONS_ORGANIZATION_OVERVIEW_ENABLED,
      labelKey: 'NAVIGATION.ORGANIZATION_OVERVIEW',
      route: ROUTE_NAMES.SOLUTIONS_DEEM_ORGANIZATION_OVERVIEW,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.SOLUTIONS_PLAYBOOKS_ENABLED,
      labelKey: 'NAVIGATION.PLAYBOOKS',
      route: ROUTE_NAMES.SOLUTIONS_DEEM_PLAYBOOKS,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.SOLUTIONS_DEEM_SURVEYS_ENABLED,
      labelKey: 'NAVIGATION.SURVEYS',
      route: ROUTE_NAMES.SOLUTIONS_DEEM_SURVEYS,
      scopes: [UserScopes.DASHBOARD_READONLY, UserScopes.SURVEYS_READONLY],
    },
    {
      featureControlKey: FeatureControl.SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING_ENABLED,
      labelKey: 'NAVIGATION.SYNTHETIC_URL_MONITORING',
      route: ROUTE_NAMES.SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureGroup: {
        features: [Features.INTEGRATION_STATISTICS, Features.SOLUTIONS_SETTING_METRIC_READONLY],
        operator: FeatureOperator.OR,
      },
      labelKey: 'NAVIGATION.SETTINGS',
      route: ROUTE_NAMES.SOLUTIONS_DEEM_SETTINGS,
    },
  ],
  [NavigationMenuItem.WORKSPACE_WORKSPACE_SECURITY_NO_CVE]: [
    {
      featureControlKey: FeatureControl.CARBONBLACK_ENABLED,
      labelKey: 'NAVIGATION.CARBON_BLACK',
      route: ROUTE_NAMES.DASHBOARD.CARBON_BLACK,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.WS_ONE_MTD_ENABLED,
      labelKey: 'NAVIGATION.WORKSPACE_ONE_MTD',
      route: ROUTE_NAMES.DASHBOARD.WORKSPACE_ONE_MTD,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.INTEGRATION_UAG_ENABLED,
      labelKey: 'NAVIGATION.UAG_TUNNEL',
      route: ROUTE_NAMES.DASHBOARD.UAG_TUNNEL,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.DASHBOARD_SECURITY_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_SECURITY_RISK',
      route: ROUTE_NAMES.DASHBOARD.SECURITY,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.DASHBOARD_USER_RISK_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_USER_RISK',
      route: ROUTE_NAMES.DASHBOARD.USER_RISK,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.DASHBOARD_LOGIN_RISK_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_LOGIN_RISK',
      route: ROUTE_NAMES.DASHBOARD.LOGIN_RISK,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
  ],
  [NavigationMenuItem.WORKSPACE_WORKSPACE_SECURITY]: [
    {
      featureControlKey: FeatureControl.SOLUTIONS_CVE_ENABLED,
      labelKey: 'NAVIGATION.INTELLIGENCE_HEADER.CVE',
      route: ROUTE_NAMES.SOLUTIONS_CVE,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.CARBONBLACK_ENABLED,
      labelKey: 'NAVIGATION.CARBON_BLACK',
      route: ROUTE_NAMES.DASHBOARD.CARBON_BLACK,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.WS_ONE_MTD_ENABLED,
      labelKey: 'NAVIGATION.WORKSPACE_ONE_MTD',
      route: ROUTE_NAMES.DASHBOARD.WORKSPACE_ONE_MTD,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.INTEGRATION_UAG_ENABLED,
      labelKey: 'NAVIGATION.UAG_TUNNEL',
      route: ROUTE_NAMES.DASHBOARD.UAG_TUNNEL,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.DASHBOARD_SECURITY_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_SECURITY_RISK',
      route: ROUTE_NAMES.DASHBOARD.SECURITY,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.DASHBOARD_USER_RISK_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_USER_RISK',
      route: ROUTE_NAMES.DASHBOARD.USER_RISK,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.DASHBOARD_LOGIN_RISK_ENABLED,
      labelKey: 'NAVIGATION.DASHBOARDS_LOGIN_RISK',
      route: ROUTE_NAMES.DASHBOARD.LOGIN_RISK,
      scopes: [UserScopes.DASHBOARD_READONLY],
    },
    {
      featureControlKey: FeatureControl.SOLUTIONS_CVE_ENABLED,
      labelKey: 'NAVIGATION.SETTINGS',
      route: ROUTE_NAMES.SOLUTIONS_CVE_SETTINGS,
      scopes: [UserScopes.DASHBOARD_SETTINGS],
    },
  ],

  // MARKETPLACE -----------------------------------------------------------------------------------
  [NavigationMenuItem.MARKETPLACE]: [
    {
      labelKey: 'NAVIGATION.EXPLORE',
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.EXPLORE}`,
      iconName: 'compass',
    },
  ],
  [NavigationMenuItem.MARKETPLACE_INTEGRATIONS]: [
    {
      labelKey: 'NAVIGATION.WORKSPACE_ONE_INTEGRATIONS',
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.INTEGRATIONS}/${ROUTE_NAMES.MARKETPLACE.WORKSPACE_ONE}`,
    },
    {
      labelKey: 'NAVIGATION.THIRD_PARTY_INTEGRATIONS',
      featureControlKey: FeatureControl.TRUST_NETWORK_ENABLED,
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.INTEGRATIONS}/${ROUTE_NAMES.MARKETPLACE.THIRD_PARTY}`,
    },
  ],
  [NavigationMenuItem.MARKETPLACE_SOLUTIONS]: [
    {
      labelKey: 'NAVIGATION.INTELLIGENCE_HEADER.DEEM',
      route: ROUTE_NAMES.MARKETPLACE.SOLUTIONS_DEEM,
    },
    {
      labelKey: 'NAVIGATION.INTELLIGENCE_HEADER.CVE',
      route: ROUTE_NAMES.MARKETPLACE.SOLUTIONS_CVE,
    },
  ],
  [NavigationMenuItem.MARKETPLACE_TEMPLATES]: [
    {
      labelKey: 'NAVIGATION.DASHBOARDS',
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.TEMPLATES}/${ROUTE_NAMES.MARKETPLACE.DASHBOARDS}`,
      scopes: [UserScopes.DASHBOARD_READONLY, UserScopes.WIDGET_READONLY],
    },
    {
      labelKey: 'NAVIGATION.WIDGETS',
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.TEMPLATES}/${ROUTE_NAMES.MARKETPLACE.WIDGETS}`,
      scopes: [UserScopes.WIDGET_READONLY],
    },
    {
      labelKey: 'NAVIGATION.REPORTS',
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.TEMPLATES}/${ROUTE_NAMES.MARKETPLACE.REPORTS}`,
      scopes: [UserScopes.REPORT_READONLY, UserScopes.REPORT_TEMPLATE],
    },
    {
      labelKey: 'NAVIGATION.WORKFLOWS',
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.TEMPLATES}/${ROUTE_NAMES.MARKETPLACE.AUTOMATIONS}`,
      scopes: [UserScopes.WORKFLOW_READONLY],
    },
    {
      featureControlKey: FeatureControl.MARKETPLACE_UEM_FREESTYLE_WORKFLOWS_ENABLED,
      labelKey: 'NAVIGATION.UEM_WORKFLOWS',
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.TEMPLATES}/${ROUTE_NAMES.MARKETPLACE.UEM_FREESTYLE_WORKFLOWS}`,
      scopes: [UserScopes.UEM_FREESTYLE_WORKFLOWS_READONLY],
    },
    {
      featureControlKey: FeatureControl.MARKETPLACE_SCRIPTS_ENABLED,
      labelKey: 'NAVIGATION.SCRIPTS',
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.TEMPLATES}/${ROUTE_NAMES.MARKETPLACE.SCRIPTS}`,
      scopes: [UserScopes.SCRIPTS_READONLY],
    },
    {
      featureControlKey: FeatureControl.MARKETPLACE_SENSORS_ENABLED,
      labelKey: 'NAVIGATION.SENSORS',
      route: `${ROUTE_NAMES.MARKETPLACE.HOME}/${ROUTE_NAMES.MARKETPLACE.TEMPLATES}/${ROUTE_NAMES.MARKETPLACE.SENSORS}`,
      scopes: [UserScopes.SENSORS_READONLY],
    },
  ],
};

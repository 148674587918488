/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { TemplateRef } from '@angular/core';
import { DataDecorator, MetricCardLegendStyle, MetricCardOrientation } from '@dpa/ui-common';

import { ZoomNetworkTab } from '@ws1c/intelligence-core/const/zoom-network-tab.enum';
import { MetricTab, StandardWidgetSubtype } from '@ws1c/intelligence-models';
import { ZoomContributor, ZoomMeetingQuality } from './deem-zoom-dashboard.enum';

/**
 * DeemZoomConfig
 * @export
 * @class DeemZoomConfig
 */
export class DeemZoomConfig {
  public static readonly ZOOM_BAD_SCORE_THRESHOLD = 2;
  public static readonly ZOOM_POOR_SCORE_THRESHOLD = 3;
  public static readonly ZOOM_FAIR_SCORE_THRESHOLD = 4;
  public static readonly ZOOM_GOOD_SCORE_THRESHOLD = 5;

  public static readonly fourScoreQualityToLabelMap: Record<ZoomMeetingQuality, string> = {
    [ZoomMeetingQuality.BAD]: 'ZOOM_INTEGRATION.BAD',
    [ZoomMeetingQuality.POOR]: 'ZOOM_INTEGRATION.POOR',
    [ZoomMeetingQuality.FAIR]: 'ZOOM_INTEGRATION.FAIR',
    [ZoomMeetingQuality.GOOD]: 'ZOOM_INTEGRATION.GOOD',
  };

  public static readonly zoomQualityColors: Record<string, string> = {
    [ZoomMeetingQuality.BAD]: '#C92100',
    [ZoomMeetingQuality.POOR]: '#A36500',
    [ZoomMeetingQuality.FAIR]: '#FAC400',
    [ZoomMeetingQuality.GOOD]: '#209C52',
  };

  public static readonly zoomContributorToInputColumn: Record<string, ZoomContributor> = {
    [ZoomContributor.AUDIO_QUALITY]: ZoomContributor.AUDIO_INPUT,
    [ZoomContributor.SCREEN_SHARE_QUALITY]: ZoomContributor.SCREEN_SHARE_INPUT,
    [ZoomContributor.VIDEO_QUALITY]: ZoomContributor.VIDEO_INPUT,
  };

  public static readonly zoomContributorToOutputColumn: Record<string, ZoomContributor> = {
    [ZoomContributor.AUDIO_QUALITY]: ZoomContributor.AUDIO_OUTPUT,
    [ZoomContributor.SCREEN_SHARE_QUALITY]: ZoomContributor.SCREEN_SHARE_OUTPUT,
    [ZoomContributor.VIDEO_QUALITY]: ZoomContributor.VIDEO_OUTPUT,
  };

  public static readonly zoomAttributeCounterToChartMap: Record<string, StandardWidgetSubtype[]> = {
    [StandardWidgetSubtype.DEEM_ZOOM_AVG_BITRATE_INPUT]: [StandardWidgetSubtype.DEEM_ZOOM_AVG_BITRATE_INPUT_CHART],
    [StandardWidgetSubtype.DEEM_ZOOM_AVG_BITRATE_OUTPUT]: [StandardWidgetSubtype.DEEM_ZOOM_AVG_BITRATE_OUTPUT_CHART],
    [StandardWidgetSubtype.DEEM_ZOOM_AVG_JITTER_INPUT]: [StandardWidgetSubtype.DEEM_ZOOM_AVG_JITTER_INPUT_CHART],
    [StandardWidgetSubtype.DEEM_ZOOM_AVG_JITTER_OUTPUT]: [StandardWidgetSubtype.DEEM_ZOOM_AVG_JITTER_OUTPUT_CHART],
    [StandardWidgetSubtype.DEEM_ZOOM_AVG_LATENCY_INPUT]: [StandardWidgetSubtype.DEEM_ZOOM_AVG_LATENCY_INPUT_CHART],
    [StandardWidgetSubtype.DEEM_ZOOM_AVG_LATENCY_OUTPUT]: [StandardWidgetSubtype.DEEM_ZOOM_AVG_LATENCY_OUTPUT_CHART],
    [StandardWidgetSubtype.DEEM_ZOOM_AVG_PACKET_LOSS_INPUT]: [StandardWidgetSubtype.DEEM_ZOOM_AVG_PACKET_LOSS_INPUT_CHART],
    [StandardWidgetSubtype.DEEM_ZOOM_AVG_PACKET_LOSS_OUTPUT]: [StandardWidgetSubtype.DEEM_ZOOM_AVG_PACKET_LOSS_OUTPUT_CHART],
    [StandardWidgetSubtype.DEEM_ZOOM_MAX_PACKET_LOSS_INPUT]: [StandardWidgetSubtype.DEEM_ZOOM_MAX_PACKET_LOSS_INPUT_CHART],
    [StandardWidgetSubtype.DEEM_ZOOM_MAX_PACKET_LOSS_OUTPUT]: [StandardWidgetSubtype.DEEM_ZOOM_MAX_PACKET_LOSS_OUTPUT_CHART],
  };

  public static readonly zoomNetworkTabToInputWidgetSubtypeMap: Record<ZoomNetworkTab, StandardWidgetSubtype> = {
    [ZoomNetworkTab.BITRATE]: StandardWidgetSubtype.DEEM_ZOOM_AVG_BITRATE_INPUT,
    [ZoomNetworkTab.JITTER]: StandardWidgetSubtype.DEEM_ZOOM_AVG_JITTER_INPUT,
    [ZoomNetworkTab.LATENCY]: StandardWidgetSubtype.DEEM_ZOOM_AVG_LATENCY_INPUT,
    [ZoomNetworkTab.AVG_PACKET_LOSS]: StandardWidgetSubtype.DEEM_ZOOM_AVG_PACKET_LOSS_INPUT,
    [ZoomNetworkTab.MAX_PACKET_LOSS]: StandardWidgetSubtype.DEEM_ZOOM_MAX_PACKET_LOSS_INPUT,
  };

  public static readonly zoomNetworkTabToOutputWidgetSubtypeMap: Record<ZoomNetworkTab, StandardWidgetSubtype> = {
    [ZoomNetworkTab.BITRATE]: StandardWidgetSubtype.DEEM_ZOOM_AVG_BITRATE_OUTPUT,
    [ZoomNetworkTab.JITTER]: StandardWidgetSubtype.DEEM_ZOOM_AVG_JITTER_OUTPUT,
    [ZoomNetworkTab.LATENCY]: StandardWidgetSubtype.DEEM_ZOOM_AVG_LATENCY_OUTPUT,
    [ZoomNetworkTab.AVG_PACKET_LOSS]: StandardWidgetSubtype.DEEM_ZOOM_AVG_PACKET_LOSS_OUTPUT,
    [ZoomNetworkTab.MAX_PACKET_LOSS]: StandardWidgetSubtype.DEEM_ZOOM_MAX_PACKET_LOSS_OUTPUT,
  };

  public static readonly networkTabYAxisLabelMap: Record<ZoomNetworkTab, string> = {
    [ZoomNetworkTab.BITRATE]: 'ZOOM_INTEGRATION.AVG_BITRATE',
    [ZoomNetworkTab.JITTER]: 'ZOOM_INTEGRATION.AVG_JITTER',
    [ZoomNetworkTab.LATENCY]: 'ZOOM_INTEGRATION.AVG_LATENCY',
    [ZoomNetworkTab.AVG_PACKET_LOSS]: 'ZOOM_INTEGRATION.AVG_PACKET_LOSS',
    [ZoomNetworkTab.MAX_PACKET_LOSS]: 'ZOOM_INTEGRATION.MAX_PACKET_LOSS',
  };

  /**
   * getFourScoreQualityDescriptor
   * @static
   * @param {(number | string)} meetingQualityScore
   * @returns {ZoomMeetingQuality}
   * @memberof DeemZoomConfig
   */
  public static getFourScoreQualityDescriptor(meetingQualityScore: number | string): ZoomMeetingQuality {
    if (!meetingQualityScore) {
      return;
    }
    const meetingQualityScoreNum: number = Number(meetingQualityScore);
    if (meetingQualityScoreNum < this.ZOOM_BAD_SCORE_THRESHOLD) {
      return ZoomMeetingQuality.BAD;
    }
    if (meetingQualityScoreNum < this.ZOOM_POOR_SCORE_THRESHOLD) {
      return ZoomMeetingQuality.POOR;
    }
    if (meetingQualityScoreNum < this.ZOOM_FAIR_SCORE_THRESHOLD) {
      return ZoomMeetingQuality.FAIR;
    }
    if (meetingQualityScoreNum <= this.ZOOM_GOOD_SCORE_THRESHOLD) {
      return ZoomMeetingQuality.GOOD;
    }
  }

  /**
   * getZoomMetricTabs
   * @static
   * @param {number} totalCount
   * @param {string} totalLabelKey
   * @param {Record<ZoomMeetingQuality, number>} countByQuality
   * @param {Record<ZoomMeetingQuality, string>} labelkeyByQuality
   * @param {Partial<Record<ZoomMeetingQuality, TemplateRef<any>>>} [templateByQuality]
   * @param {DataDecorator} [formatType]
   * @returns {MetricTab[]}
   * @memberof DeemZoomConfig
   */
  public static getZoomMetricTabs(
    totalCount: number,
    totalLabelKey: string,
    countByQuality: Record<ZoomMeetingQuality, number>,
    labelkeyByQuality: Record<ZoomMeetingQuality, string>,
    templateByQuality?: Partial<Record<ZoomMeetingQuality, TemplateRef<any>>>,
    formatType?: DataDecorator,
  ): MetricTab[] {
    return [
      new MetricTab({
        labelKey: totalLabelKey,
        value: totalCount,
        orientation: MetricCardOrientation.VERTICAL,
        formatType: DataDecorator.INTEGER,
      }),
      new MetricTab({
        labelKey: labelkeyByQuality.bad,
        value: countByQuality.bad,
        formatType,
        orientation: MetricCardOrientation.VERTICAL,
        legendStyle: MetricCardLegendStyle.ICON,
        iconColor: DeemZoomConfig.zoomQualityColors[ZoomMeetingQuality.BAD],
        sparkLineTemplate: templateByQuality.bad,
      }),
      new MetricTab({
        labelKey: labelkeyByQuality.poor,
        value: countByQuality.poor,
        formatType,
        orientation: MetricCardOrientation.VERTICAL,
        legendStyle: MetricCardLegendStyle.ICON,
        iconColor: DeemZoomConfig.zoomQualityColors[ZoomMeetingQuality.POOR],
        sparkLineTemplate: templateByQuality.poor,
      }),
      new MetricTab({
        labelKey: labelkeyByQuality.fair,
        value: countByQuality.fair,
        formatType,
        orientation: MetricCardOrientation.VERTICAL,
        legendStyle: MetricCardLegendStyle.ICON,
        iconColor: DeemZoomConfig.zoomQualityColors[ZoomMeetingQuality.FAIR],
        sparkLineTemplate: templateByQuality.fair,
      }),
      new MetricTab({
        labelKey: labelkeyByQuality.good,
        value: countByQuality.good,
        formatType,
        orientation: MetricCardOrientation.VERTICAL,
        legendStyle: MetricCardLegendStyle.ICON,
        iconColor: DeemZoomConfig.zoomQualityColors[ZoomMeetingQuality.GOOD],
        sparkLineTemplate: templateByQuality.good,
      }),
    ];
  }
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { COLUMN_NAMES, Entity, getFQNFunction, getUniqueId, Integration } from '@ws1c/intelligence-models';

/**
 * Export helpers to support spy on functions
 */
export const dashboardSelectorHelpersV2 = {
  getWidgetsDefaultTableColumnNamesV2,
  getWidgetsTableHiddenColumnNames,
};

/**
 * getWidgetsDefaultTableColumnNamesV2
 * @param {string} categoryId
 * @returns {string[]}
 */
// eslint-disable-next-line
export function getWidgetsDefaultTableColumnNamesV2(categoryId: string): string[] {
  switch (categoryId) {
    case getUniqueId(Integration.AIRWATCH, Entity.APPLICATION):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_application_app_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_application_app_modification_date,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_application_app_package_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_application_app_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_application_app_is_installed,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_application_app_install_status,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_last_seen,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICE_CHANGE_EVENT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_change_event_device_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_change_event_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_change_event_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_change_event_event_type,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_change_event_device_model,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_change_event_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_change_event_device_os_version,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICE_CUSTOM_ATTRIBUTES):
      return [COLUMN_NAMES.byFullyQualifiedName.airwatch_devicecustomattributes_device_guid];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICESENSORS):
      return [COLUMN_NAMES.byFullyQualifiedName.airwatch_devicesensors_device_guid];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICE_RISK_SCORE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_udid,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_deviceriskscore_score_severity,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_deviceriskscore_score_type_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_deviceriskscore_score_calculated_at,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.IOSUPDATE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_iosupdate_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_iosupdate_ios_update_status,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.MACOSUPDATE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version_build_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_macosupdate_macos_update_available_os_update_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_macosupdate_macos_update_product_key,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_macosupdate_macos_update_update_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_macosupdate_macos_update_build_version,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.PRODUCT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_product_product_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_product_product_type,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_product_product_job_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_product_product_job_log,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_product_product_active,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_location_group_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.PROFILE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_profile_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_device_enrollment_user_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_profile_assigned_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_profile_installed_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_profile_installed_status,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_profile_status,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_profile_is_managed,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_profile_is_active,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_device_enrollment_status,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_profile_adp_modified_at,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.WINDOWSPATCH):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_winpatch_kb_number,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_winpatch_revision_id,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_winpatch_kb_subject,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_winpatch_update_status,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_winpatch_approval_status,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_winpatch_update_classification,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.APPLOAD):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_platform,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_system_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_carrier,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.USER_FLOW):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_state_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_active_time_millis,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_user_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_device_model,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_platform,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_device_uuid,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_app_id,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_start_time,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_end_time,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_event_id,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.NET_EVENT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_url,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_event_received_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_http_status_code,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_http_status_message,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_latency,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_bytes_received,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_bytes_sent,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_system_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_carrier,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_event_device_model,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.NET_ERROR):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_url,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_event_received_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_net_error_msg,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_bytes_sent,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_system_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_carrier,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_device_model,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.GROUPED_CRASH_ANDROID):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_android_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_android_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_android_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_android_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_android_event_timestamp,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.GROUPED_CRASH_IOS):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_event_timestamp,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.CRASH_ANDROID):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_event_timestamp,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.CRASH_IOS):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_event_timestamp,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.HANDLED_EXCEPTION_IOS):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_event_timestamp,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.HANDLED_EXCEPTION_ANDROID):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_event_timestamp,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.PLUGIN_EXCEPTION):
      return [
        COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_error_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_error_reason,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_app_version,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.apteligent_plugin_exception_event_timestamp,
      ];
    case getUniqueId(Integration.CARBONBLACK, Entity.THREAT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_alert_category,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threatinfo_threatcause_causeeventid,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_deviceinfo_deviceid,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_deviceinfo_devicename,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_deviceinfo_deviceversion,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_eventdescription,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_eventtime,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_deviceinfo_externalipaddress,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_deviceinfo_internalipaddress,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threatinfo_threatcause_actorprocessppid,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threatinfo_score,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threatinfo_threatcause_threatcategory,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threatinfo_threatcause_actorname,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threatinfo_threatcause_reason,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threatinfo_summary,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_deviceinfo_uemid,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_event_id_normalized,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_event_type,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threat_id,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threat_action,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_airwatch_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threatinfo_threatcause_actor,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threatinfo_threatcause_reputation,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_partner_name,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_vmware_threat_type,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threat_family,
        COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_threat_severity,
      ];
    case getUniqueId(Integration.CHECKPOINT, Entity.THREAT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.checkpoint_threat_partner_name,
        COLUMN_NAMES.byFullyQualifiedName.checkpoint_threat_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.checkpoint_threat_vmware_threat_type,
        COLUMN_NAMES.byFullyQualifiedName.checkpoint_threat_threat_family,
        COLUMN_NAMES.byFullyQualifiedName.checkpoint_threat_threat_severity,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.APPS):
      return [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_code,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_offset,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_make,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_model,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_user,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_airwatch_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_status,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.ASSET_INFO):
      return [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_processor,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_physical_memory_bytes,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_bios_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_bios_timestamp,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.DEVICES):
      return [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_user,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_device_make,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_device_model,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_device_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_module,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_error,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_received_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_status,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_airwatch_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_pnp_load_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_main_pathload_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_perf_degradation,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_load_degradation_time_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_load_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_winlogon_waiting_duration_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_system_session_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_services_millis,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_user_session_millis,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.USER_ACTIONS):
      return [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_airwatch_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_status,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_device_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_os_version,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_user,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.SERVICES):
      return [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_airwatch_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_event_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_event_status,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_device_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_os_version,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.OS_UPDATES):
      return [
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_airwatch_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_status,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_device_name,
        COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_os_version,
      ];
    case getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_healthy_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_user,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_login_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_deployment_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_pool,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_pool_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_host,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_logon_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_shell_load_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_profile_load_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_interactive_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_client_ip,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_client_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_packet_loss,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_bandwidth,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol_frame_rate,
      ];
    case getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT_TITAN):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_session_health,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_user,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_s_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_login_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_template_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_template_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_host,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_logon_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_shell_load_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_profile_load_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_interactive_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_client_ip,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_client_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_packet_loss,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol_frame_rate,
      ];
    case getUniqueId(Integration.HORIZON, Entity.VM):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_health_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_desktop_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_deployment_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pool_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_cpu_percent,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_memory_percent,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_disk_iops,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_disk_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_network_bandwidth,
      ];
    case getUniqueId(Integration.HORIZON, Entity.VM_TITAN):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_health_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_desktop_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_pool_template_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_cpu_percent_processor_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_memory_percent,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_disk_iops,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_disk_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_network_bandwidth,
      ];
    case getUniqueId(Integration.HUB, Entity.SURVEY):
      return [
        COLUMN_NAMES.byFullyQualifiedName.hub_survey_hub_user_guid,
        COLUMN_NAMES.byFullyQualifiedName.hub_survey_username,
        COLUMN_NAMES.byFullyQualifiedName.hub_survey_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.hub_survey_responses,
      ];
    case getUniqueId(Integration.IDM, Entity.LAUNCH):
      return [
        COLUMN_NAMES.byFullyQualifiedName.idm_launch_actorusername,
        COLUMN_NAMES.byFullyQualifiedName.idm_launch_actordomain,
        COLUMN_NAMES.byFullyQualifiedName.idm_launch_objectname,
        COLUMN_NAMES.byFullyQualifiedName.idm_launch_values_success,
        COLUMN_NAMES.byFullyQualifiedName.idm_launch_values_failuremessage,
        COLUMN_NAMES.byFullyQualifiedName.idm_launch_sourceip,
        COLUMN_NAMES.byFullyQualifiedName.idm_launch_event_type,
        COLUMN_NAMES.byFullyQualifiedName.idm_launch_event_timestamp,
      ];
    case getUniqueId(Integration.IDM, Entity.LOGIN):
      return [
        COLUMN_NAMES.byFullyQualifiedName.idm_login_actorusername,
        COLUMN_NAMES.byFullyQualifiedName.idm_login_actordomain,
        COLUMN_NAMES.byFullyQualifiedName.idm_login_values_success,
        COLUMN_NAMES.byFullyQualifiedName.idm_login_values_authmethods,
        COLUMN_NAMES.byFullyQualifiedName.idm_login_values_failuremessage,
        COLUMN_NAMES.byFullyQualifiedName.idm_login_sourceip,
        COLUMN_NAMES.byFullyQualifiedName.idm_login_event_type,
        COLUMN_NAMES.byFullyQualifiedName.idm_login_event_timestamp,
      ];
    case getUniqueId(Integration.IDM, Entity.LOGOUT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.idm_logout_actorusername,
        COLUMN_NAMES.byFullyQualifiedName.idm_logout_actordomain,
        COLUMN_NAMES.byFullyQualifiedName.idm_logout_values_success,
        COLUMN_NAMES.byFullyQualifiedName.idm_logout_values_authmethods,
        COLUMN_NAMES.byFullyQualifiedName.idm_logout_sourceip,
        COLUMN_NAMES.byFullyQualifiedName.idm_logout_event_type,
        COLUMN_NAMES.byFullyQualifiedName.idm_logout_event_timestamp,
      ];
    case getUniqueId(Integration.INTELLIGENCE, Entity.DEVICE_PATCH_STATUS):
      return [
        COLUMN_NAMES.byFullyQualifiedName.internal_device_patch_status_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.internal_device_patch_status_cvss_score_v2,
        COLUMN_NAMES.byFullyQualifiedName.internal_device_patch_status_patch_status,
      ];
    case getUniqueId(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_USER):
      return [
        COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_username,
        COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_user_overall_score,
        COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_score_calculation_timestamp,
      ];
    case getUniqueId(Integration.INTELLIGENCE, Entity.VULNERABILITY_SUMMARY):
      return [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_macosupdate_macos_update_available_os_update_name,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_macosupdate_macos_update_product_key,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_macosupdate_macos_update_update_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_macosupdate_macos_update_is_critical,
      ];
    case getUniqueId(Integration.LOOKOUT, Entity.THREAT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.lookout_threat_partner_name,
        COLUMN_NAMES.byFullyQualifiedName.lookout_threat_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.lookout_threat_vmware_threat_type,
        COLUMN_NAMES.byFullyQualifiedName.lookout_threat_threat_family,
        COLUMN_NAMES.byFullyQualifiedName.lookout_threat_threat_severity,
      ];
    case getUniqueId(Integration.NETSKOPE, Entity.THREAT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.netskope_threat_partner_name,
        COLUMN_NAMES.byFullyQualifiedName.netskope_threat_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.netskope_threat_vmware_threat_type,
        COLUMN_NAMES.byFullyQualifiedName.netskope_threat_threat_family,
        COLUMN_NAMES.byFullyQualifiedName.netskope_threat_threat_severity,
      ];
    case getUniqueId(Integration.PRADEO, Entity.THREAT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.pradeo_threat_partner_name,
        COLUMN_NAMES.byFullyQualifiedName.pradeo_threat_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.pradeo_threat_vmware_threat_type,
        COLUMN_NAMES.byFullyQualifiedName.pradeo_threat_threat_family,
        COLUMN_NAMES.byFullyQualifiedName.pradeo_threat_threat_severity,
      ];
    case getUniqueId(Integration.UAG, Entity.TUNNEL):
      return [
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_device_name,
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_user_name,
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_dest_port,
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_host_name,
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_dest_ip,
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_down_bytes,
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_up_bytes,
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_uag_name,
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_protocol,
        COLUMN_NAMES.byFullyQualifiedName.uag_tunnel_app_bundle_id,
      ];
    case getUniqueId(Integration.WANDERA, Entity.THREAT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.wandera_threat_partner_name,
        COLUMN_NAMES.byFullyQualifiedName.wandera_threat_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.wandera_threat_vmware_threat_type,
        COLUMN_NAMES.byFullyQualifiedName.wandera_threat_threat_family,
        COLUMN_NAMES.byFullyQualifiedName.wandera_threat_threat_severity,
      ];
    case getUniqueId(Integration.ZIMPERIUM, Entity.THREAT):
      return [
        COLUMN_NAMES.byFullyQualifiedName.zimperium_threat_partner_name,
        COLUMN_NAMES.byFullyQualifiedName.zimperium_threat_event_timestamp,
        COLUMN_NAMES.byFullyQualifiedName.zimperium_threat_vmware_threat_type,
        COLUMN_NAMES.byFullyQualifiedName.zimperium_threat_threat_family,
        COLUMN_NAMES.byFullyQualifiedName.zimperium_threat_threat_severity,
      ];
    default:
      return [];
  }
}

/**
 * getWidgetsTableHiddenColumnNames
 * @param {string} categoryId
 * @returns {string[]}
 */
export function getWidgetsTableHiddenColumnNames(categoryId: string = ''): string[] {
  let getFQN;
  switch (categoryId) {
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICE_RISK_SCORE):
      getFQN = getFQNFunction(Integration.AIRWATCH, Entity.DEVICE_RISK_SCORE);
      return [
        getFQN(COLUMN_NAMES.byName.device_guid),
        getFQN(COLUMN_NAMES.byName.score_type),
        getFQN(COLUMN_NAMES.byName.score_calculated_at),
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.USER_RISK_SCORE):
      getFQN = getFQNFunction(Integration.AIRWATCH, Entity.USER_RISK_SCORE);
      return [getFQN(COLUMN_NAMES.byName.device_enrollment_user_email), getFQN(COLUMN_NAMES.byName.score_calculated_at)];
    case getUniqueId(Integration.APTELIGENT, Entity.USER_FLOW):
      getFQN = getFQNFunction(Integration.APTELIGENT, Entity.USER_FLOW);
      return [
        getFQN(COLUMN_NAMES.byName.device_uuid),
        getFQN(COLUMN_NAMES.byName.app_id),
        getFQN(COLUMN_NAMES.byName.start_time),
        getFQN(COLUMN_NAMES.byName.end_time),
        getFQN(COLUMN_NAMES.byName._state_name),
        getFQN(COLUMN_NAMES.byName.platform),
        getFQN(COLUMN_NAMES.byName.crash_id),
        getFQN(COLUMN_NAMES.byName.event_id),
      ];
    case getUniqueId(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_USER):
      return [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_user_guid];
  }
  return [];
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SkeletonClassName } from '@dpa/ui-common';

import { RouterExtensions } from '@ws1c/intelligence-common';

/**
 * StatusComponent
 * @export
 * @class StatusComponent
 */
@Component({
  selector: 'dpa-status',
  templateUrl: 'status.component.html',
  styleUrls: ['status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  @Input() public title: string;
  @Input() public subtitle?: string;
  @Input() public tooltip?: string;
  @Input() public status?: string = '';
  @Input() public viewLink?: string = '';

  public SKELETON_CLASS_NAME = SkeletonClassName;

  /**
   * Creates an instance of StatusComponent.
   * @param {RouterExtensions} routerext
   * @memberof StatusComponent
   */
  constructor(public routerext: RouterExtensions) {}

  /**
   * goToTargetLink
   * @memberof StatusComponent
   */
  public goToTargetLink() {
    if (this.viewLink) {
      this.routerext.navigateByUrl(this.viewLink);
    }
  }
}

/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { Action, createReducer, on } from '@ngrx/store';

import { IntegrationDetails, IntroModalMode, LOAD_STATE, Org, UserAccount, UserPreference } from '@ws1c/intelligence-models';
import { getIntegrationInstancesWithActiveStateUpdated } from './user-preference-reducer-helper';
import { UserPreferenceActions } from './user-preference.actions';
import { initialUserPreferenceState, UserPreferenceState } from './user-preference.state';

const _userPreferenceReducer = createReducer(
  initialUserPreferenceState,
  on(
    UserPreferenceActions.activateIntegrationInstance,
    (state: UserPreferenceState, { integrationDetails }: { integrationDetails: IntegrationDetails }) => {
      const existingIntegrationsDetails: GenericObject = state.integrationsDetails || {};
      return {
        ...state,
        integrationsDetails: {
          ...existingIntegrationsDetails,
          [integrationDetails.integration]: getIntegrationInstancesWithActiveStateUpdated(
            existingIntegrationsDetails,
            integrationDetails,
            true,
          ),
        },
      };
    },
  ),
  on(
    UserPreferenceActions.deactivateIntegrationInstance,
    (state: UserPreferenceState, { integrationDetails }: { integrationDetails: IntegrationDetails }) => {
      const existingIntegrationsDetails: GenericObject = state.integrationsDetails || {};
      return {
        ...state,
        integrationsDetails: {
          ...existingIntegrationsDetails,
          [integrationDetails.integration]: getIntegrationInstancesWithActiveStateUpdated(
            existingIntegrationsDetails,
            integrationDetails,
            false,
          ),
        },
      };
    },
  ),
  on(
    UserPreferenceActions.updateOptinFeatureFlag,
    (state: UserPreferenceState, props: { flagName: string; value: boolean }): UserPreferenceState => {
      const org = new Org({
        ...state.userPreference.org,
        optInFeatures: {
          ...state.userPreference.org?.optInFeatures,
          [props.flagName]: props.value,
        },
      });
      return {
        ...state,
        userPreference: {
          ...state.userPreference,
          org,
        },
      };
    },
  ),
  on(
    UserPreferenceActions.refreshUserAccountSuccess,
    (state: UserPreferenceState, { userAccount }: { userAccount: UserAccount }): UserPreferenceState => {
      return {
        ...state,
        userPreference: new UserPreference({
          ...state.userPreference,
          userAccount,
        }),
      };
    },
  ),
  on(
    UserPreferenceActions.refreshMyAccountInfoSuccess,
    (state: UserPreferenceState, { user }: ReturnType<typeof UserPreferenceActions.refreshMyAccountInfoSuccess>): UserPreferenceState => {
      return {
        ...state,
        userPreference: new UserPreference({
          ...state.userPreference,
          userAccount: new UserAccount({
            ...state.userPreference.userAccount,
            ...user,
          }),
        }),
      };
    },
  ),
  on(
    UserPreferenceActions.getEulaSuccess,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.getEulaSuccess>): UserPreferenceState => ({
      ...state,
      eulaHtml: action.eulaHtml,
    }),
  ),
  on(
    UserPreferenceActions.getTrialUserContactDetails,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isFetchingTrialUserContactDetails: true,
    }),
  ),
  on(
    UserPreferenceActions.getTrialUserContactDetailsSuccess,
    (
      state: UserPreferenceState,
      action: ReturnType<typeof UserPreferenceActions.getTrialUserContactDetailsSuccess>,
    ): UserPreferenceState => ({
      ...state,
      isFetchingTrialUserContactDetails: false,
      trialUserContactDetails: {
        ...action.userContactDetails,
      },
    }),
  ),
  on(
    UserPreferenceActions.getTrialUserContactDetailsFailure,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isFetchingTrialUserContactDetails: false,
    }),
  ),
  on(
    UserPreferenceActions.updateUISettingsSuccess,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.updateUISettingsSuccess>): UserPreferenceState => ({
      ...state,
      uiSettings: {
        preferences: {
          ...state.uiSettings.preferences,
          ...action.uiSettings.preferences,
        },
      },
    }),
  ),
  on(
    UserPreferenceActions.cancelEulaSuccess,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isEulaShowing: false,
    }),
  ),
  on(
    UserPreferenceActions.acceptEula,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isAcceptingEula: true,
    }),
  ),
  on(
    UserPreferenceActions.acceptEulaSuccess,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isAcceptingEula: false,
      isEulaShowing: false,
      eulaAccepted: true,
    }),
  ),
  on(
    UserPreferenceActions.acceptEulaFailure,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isAcceptingEula: false,
    }),
  ),
  on(
    UserPreferenceActions.acceptTrial,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      introModalMode: IntroModalMode.ACTIVATING_TRIAL,
    }),
  ),
  on(
    UserPreferenceActions.acceptTrialSuccess,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      introModalMode: IntroModalMode.ACTIVATED_TRIAL,
    }),
  ),
  on(
    UserPreferenceActions.acceptTrialFailure,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      introModalMode: IntroModalMode.ACTIVATE_TRIAL,
    }),
  ),
  on(
    UserPreferenceActions.declineTrialFailure,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      introModalMode: IntroModalMode.CLOSE,
    }),
  ),
  on(
    UserPreferenceActions.setTrialBannerState,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.setTrialBannerState>): UserPreferenceState => ({
      ...state,
      trialBannerState: action.trialBannerState,
    }),
  ),
  on(
    UserPreferenceActions.loadUserPreference,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isRefreshingUserPreference: false,
    }),
  ),
  on(
    UserPreferenceActions.loadUserPreferenceSuccess,
    (
      state: UserPreferenceState,
      { userPreference }: ReturnType<typeof UserPreferenceActions.loadUserPreferenceSuccess>,
    ): UserPreferenceState => ({
      ...state,
      loadState: LOAD_STATE.SUCCESS,
      userPreference,
      eulaAccepted: userPreference.org.eulaAccepted,
      isEulaShowing: !userPreference.org.eulaAccepted,
      integrationsDetails: userPreference.org.integrationsDetails,
      uiSettings: userPreference.uiSettings,
    }),
  ),
  on(
    UserPreferenceActions.refreshUserPreference,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isRefreshingUserPreference: true,
    }),
  ),
  on(
    UserPreferenceActions.setUserPreferenceLoadState,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.setUserPreferenceLoadState>): UserPreferenceState => ({
      ...state,
      loadState: action.loadState,
    }),
  ),
  on(
    UserPreferenceActions.setIntroModalMode,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.setIntroModalMode>): UserPreferenceState => ({
      ...state,
      introModalMode: action.introModalMode,
    }),
  ),
  on(
    UserPreferenceActions.removeActiveInstances,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.removeActiveInstances>): UserPreferenceState => {
      const integration: string = action.integration;
      const existingIntegrationsDetails: GenericObject = state.integrationsDetails || {};
      const currentIntegrationInstances: GenericObject[] = existingIntegrationsDetails[integration]
        ? [...existingIntegrationsDetails[integration]]
        : [];
      const activeIntegrationInstanceIndex: number = currentIntegrationInstances.findIndex((integrationInstance) => {
        return integrationInstance.active;
      });
      if (activeIntegrationInstanceIndex > -1) {
        currentIntegrationInstances[activeIntegrationInstanceIndex] = {
          ...currentIntegrationInstances[activeIntegrationInstanceIndex],
          active: false,
        };
      }
      return {
        ...state,
        integrationsDetails: {
          ...existingIntegrationsDetails,
          [integration]: [...currentIntegrationInstances],
        },
      };
    },
  ),
  on(
    UserPreferenceActions.insertActiveInstances,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.insertActiveInstances>): UserPreferenceState => {
      const newIntegration: string = action.integration;
      const newDetails: GenericObject = action.details;
      const currentIntegrationsDetails: GenericObject = state.integrationsDetails || {};
      const newIntegrationInstances: GenericObject[] = currentIntegrationsDetails[newIntegration]
        ? [...currentIntegrationsDetails[newIntegration]]
        : [];
      return {
        ...state,
        integrationsDetails: {
          ...currentIntegrationsDetails,
          [newIntegration]: [...newIntegrationInstances, newDetails],
        },
      };
    },
  ),
  on(
    UserPreferenceActions.setInitialUrlQueryParams,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.setInitialUrlQueryParams>): UserPreferenceState => ({
      ...state,
      initialUrlQueryParams: action.initialUrlQueryParams,
    }),
  ),
  on(
    UserPreferenceActions.setNonAdminMode,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.setNonAdminMode>): UserPreferenceState => ({
      ...state,
      isNonAdminMode: action.isNonAdminMode,
    }),
  ),
  on(
    UserPreferenceActions.setIsRemediationMode,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.setIsRemediationMode>): UserPreferenceState => ({
      ...state,
      isRemediationMode: action.isRemediationMode,
    }),
  ),
  on(
    UserPreferenceActions.uiPreferencesChangeSuccess,
    UserPreferenceActions.uiPreferencesChangeFailure,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isUpdatingUISettings: false,
    }),
  ),
  on(
    UserPreferenceActions.updateUISettings,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.updateUISettings>): UserPreferenceState => ({
      ...state,
      isUpdatingUISettings: true,
      currentUISettingsKeys: Object.keys(action.uiSettings),
    }),
  ),
  on(
    UserPreferenceActions.getPendoMetadata,
    (state: UserPreferenceState): UserPreferenceState => ({
      ...state,
      isPendoMetadataLoading: true,
    }),
  ),
  on(
    UserPreferenceActions.setPendoMetadata,
    (state: UserPreferenceState, action: ReturnType<typeof UserPreferenceActions.setPendoMetadata>): UserPreferenceState => ({
      ...state,
      pendo: action.metadata,
      isPendoMetadataLoading: false,
    }),
  ),
  on(
    UserPreferenceActions.updateFeatureFlag,
    (state: UserPreferenceState, props: { flagName: string; value: boolean }): UserPreferenceState => {
      const org = new Org({
        ...state.userPreference.org,
        featureControls: {
          ...state.userPreference.org?.featureControls,
          [props.flagName]: props.value,
        },
      });
      return {
        ...state,
        userPreference: {
          ...state.userPreference,
          org,
        },
      };
    },
  ),

  on(
    UserPreferenceActions.setBrownfieldNotificationModalOpenState,
    (
      state: UserPreferenceState,
      { isOpen }: ReturnType<typeof UserPreferenceActions.setBrownfieldNotificationModalOpenState>,
    ): UserPreferenceState => ({
      ...state,
      isBrownfieldNotificationModalOpen: isOpen,
    }),
  ),

  on(
    UserPreferenceActions.setRequestCloudAccountModalOpenState,
    (
      state: UserPreferenceState,
      { isOpen }: ReturnType<typeof UserPreferenceActions.setRequestCloudAccountModalOpenState>,
    ): UserPreferenceState => ({
      ...state,
      isRequestCloudAccountModalOpen: isOpen,
    }),
  ),

  on(
    UserPreferenceActions.setCurrentIntroPage,
    (state: UserPreferenceState, { introType }: ReturnType<typeof UserPreferenceActions.setCurrentIntroPage>): UserPreferenceState => ({
      ...state,
      currentIntroPage: introType,
    }),
  ),

  on(
    UserPreferenceActions.getUserOrgsSuccess,
    (state: UserPreferenceState, { userOrgs }: ReturnType<typeof UserPreferenceActions.getUserOrgsSuccess>): UserPreferenceState => ({
      ...state,
      userOrgs,
    }),
  ),
);

/**
 * User State Reducer
 * @param {UserPreferenceState} state
 * @param {Action} action
 * @returns {UserPreferenceState}
 */
export function userPreferenceState(state: UserPreferenceState, action: Action) {
  return _userPreferenceReducer(state, action);
}

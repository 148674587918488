/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

/**
 * RbacActions
 * @export
 * @class RbacActions
 */
export class RbacActions {
  public static readonly CATEGORY = '[RBAC]';

  public static getUserScopes = createAction(`${RbacActions.CATEGORY} GET_USER_SCOPES`);
  public static getUserScopesSuccess = createAction(
    `${RbacActions.CATEGORY} GET_USER_SCOPES_SUCCESS`,
    props<{ userSessionScopes: string[] }>(),
  );
  public static setUserScopes = createAction(`${RbacActions.CATEGORY} SET_USER_SCOPES`, props<{ userSessionScopes: string[] }>());

  public static goToAccessDeniedPage = createAction(`${RbacActions.CATEGORY} GO_TO_ACCESS_DENIED_PAGE`);
  public static goToAccessRestrictedPage = createAction(`${RbacActions.CATEGORY} GO_TO_ACCESS_RESTRICTED_PAGE`);
  public static goToCloudAccountRestrictedPage = createAction(`${RbacActions.CATEGORY} GO_TO_CLOUD_ACCOUNT_RESTRICTED_PAGE`);
  public static goToResourceNotFoundPage = createAction(`${RbacActions.CATEGORY} GO_TO_RESOURCE_NOT_FOUND_PAGE`);

  public static requestAccess = createAction(`${RbacActions.CATEGORY} REQUEST_ACCESS`);
  public static requestAccessSuccess = createAction(
    `${RbacActions.CATEGORY} REQUEST_ACCESS_SUCCESS`,
    props<{ adminCountForAccessRequest: number }>(),
  );
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { gql } from 'apollo-angular';

export const DASHBOARD_SHARED_ACCOUNTS = gql`
  query DashboardSharedAccounts($id: UUID!) {
    dashboardSharedAccounts(id: $id) {
      details {
        id
        email
        first_name
        last_name
        display_name
        access_level
      }
    }
  }
`;

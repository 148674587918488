/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { MenuItem } from '@ws1c/intelligence-models';

/**
 * selectDefaultMenuItemRoute
 *
 * @export
 * @param {boolean} loadDefaultRoute
 * @param {MenuItem[]} [menuItems]
 * @returns {string}
 */
export function selectDefaultMenuItemRoute(loadDefaultRoute: boolean, menuItems?: MenuItem[]): string {
  return loadDefaultRoute && menuItems?.length > 0 ? menuItems[0].route : null;
}

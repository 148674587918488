/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { PagedRequest } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  AggregationWidget,
  Column,
  CustomReport,
  CustomReportPreviewSearchResponse,
  QueryBuilder,
  ReportTemplate,
  Trend,
  WidgetDetailDefinition,
  WidgetDetailPageSkinType,
  WizardDialogMode,
} from '@ws1c/intelligence-models';

/**
 * ReportCommonActions
 *
 * @export
 * @class ReportCommonActions
 */
@Injectable()
export class ReportCommonActions {
  public static readonly CATEGORY = '[REPORT_COMMON]';

  public static createReportFromWidgetDetails = createAction(
    `${ReportCommonActions.CATEGORY} CREATE_REPORT_FROM_WIDGET_DETAILS`,
    props<{
      detail: WidgetDetailDefinition;
      widget: AggregationWidget;
      trend: Trend;
      returnPath: string;
      skinType: WidgetDetailPageSkinType;
      columns: Column[];
    }>(),
  );

  public static setReportWizardMode = createAction(
    `${ReportCommonActions.CATEGORY} SET_REPORT_WIZARD_MODE`,
    props<{ wizardDialogMode: WizardDialogMode }>(),
  );

  public static resetReportWidget = createAction(`${ReportCommonActions.CATEGORY} RESET_REPORT_WIDGET`);

  public static goToReportDetailsPage = createAction(
    `${ReportCommonActions.CATEGORY} GO_TO_REPORT_DETAILS_PAGE`,
    props<{ reportId: string }>(),
  );

  public static updateReportFilter = createAction(
    `${ReportCommonActions.CATEGORY} UPDATE_REPORT_FILTER`,
    props<{ queryBuilder: QueryBuilder }>(),
  );

  public static setReportColumns = createAction(`${ReportCommonActions.CATEGORY} SET_REPORT_COLUMNS`, props<{ columns: Column[] }>());

  public static loadPreview = createAction(
    `${ReportCommonActions.CATEGORY} LOAD_PREVIEW`,
    props<{
      pagedRequest: PagedRequest;
      queryString: string;
      report?: CustomReport;
    }>(),
  );

  public static loadPreviewSuccess = createAction(
    `${ReportCommonActions.CATEGORY} LOAD_PREVIEW_SUCCESS`,
    props<{
      searchResponse: CustomReportPreviewSearchResponse;
    }>(),
  );

  public static loadPreviewFailure = createAction(`${ReportCommonActions.CATEGORY} LOAD_PREVIEW_FAILURE`, props<{ isTimedout: boolean }>());

  public static updateFilterValidity = createAction(
    `${ReportCommonActions.CATEGORY} UPDATE_FILTER_VALIDITY`,
    props<{ isValidFilter: boolean }>(),
  );

  public static updateReportSettings = createAction(
    `${ReportCommonActions.CATEGORY} UPDATE_REPORT_SETTINGS`,
    props<Partial<CustomReport>>(),
  );

  public static toggleUseFriendlyColumnLabelsForCSV = createAction(
    `${ReportCommonActions.CATEGORY} TOGGLE_USE_FRIENDLY_COLUMN_LABELS_FOR_CSV`,
    props<{ useFriendlyColumnLabelsForCSV: boolean }>(),
  );

  public static resetCustomReport = createAction(`${ReportCommonActions.CATEGORY} RESET_CUSTOM_REPORT`);

  public static selectReportTemplate = createAction(
    `${ReportCommonActions.CATEGORY} SELECT_REPORT_TEMPLATE`,
    props<{ template: ReportTemplate }>(),
  );

  public static setCustomReportDetails = createAction(
    `${ReportCommonActions.CATEGORY} SET_CUSTOM_REPORT_DETAILS`,
    props<{ report: CustomReport }>(),
  );

  public static initCustomReportSidePanel = createAction(
    `${ReportCommonActions.CATEGORY} INIT_CUSTOM_REPORT_SIDE_PANEL`,
    props<{ columns: Column[] }>(),
  );
}

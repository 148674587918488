/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Application Model Object for Email
 *
 * @export
 * @class Email
 */
@Serializable
export class Email {
  @JsonProperty('to')
  public to: string[] = undefined;

  @JsonProperty('subject')
  public subject: string = undefined;

  @JsonProperty('body')
  public body: string = undefined;
}

/**
 * Request data format for sending email
 *
 * @export
 * @class EmailRequest
 */
@Serializable
export class EmailRequest {
  @JsonProperty('properties')
  public properties: Email = undefined;

  @JsonProperty('locale')
  public locale: string = undefined;

  /**
   * Creates an instance of EmailRequest.
   * @param {Partial<EmailRequest>} args
   * @memberof EmailRequest
   */
  constructor(...args: Array<Partial<EmailRequest>>) {
    Object.assign(this, ...args);
  }
}

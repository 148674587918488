<div
  *ngIf="ngxChart?.rootNode.value; else noData"
  (resized)="onResize()"
  class="chart-container"
>
  <ngx-charts-pie-chart
    #chart
    [scheme]="ngxChart.defaultColorScheme"
    [results]="ngxChart.chartData"
    [legend]="false"
    [animations]="true"
    [doughnut]="true"
    [labels]="showLabel()"
    [trimLabels]="true"
    [customColors]="ngxChart.customColors"
    [labelFormatting]="labelFormatting"
    [maxLabelLength]="maxLabelVisible"
    (select)="onSelect($event)"
  >
    <ng-template
      *ngIf="!ngxChart.usingFakeGroupBy"
      #tooltipTemplate
      let-model="model"
    >
      <ng-template
        *ngIf="model"
        [ngTemplateOutlet]="stackedTooltip"
        [ngTemplateOutletContext]="{
          ngxModel: model,
          tooltip: ngxChart.getTooltip(model),
          total: ngxChart?.rootNode.value
        }"
      ></ng-template>
    </ng-template>
  </ngx-charts-pie-chart>
  <div
    [ngClass]="totalHeaderClass"
    class="overlay-total"
  >
    <!-- linthtml-configure attr-bans="false" -->
    <svg
      viewBox="0 0 100 100"
      width="100%"
      height="100%"
    >
      <text
        class="label"
        text-anchor="middle"
      >
        <!--
          indent-style="false" not working
          utilizing &nbsp; to offset the trailing space
        -->
        <tspan
          class="overlay-total-value"
          x="50"
          y="60"
        >
          &nbsp;{{ totalHeaderValue !== undefined ? totalHeaderValue : (ngxChart?.rootNode.value | shorthandNumber) }}
        </tspan>
        <tspan
          class="overlay-total-desc"
          x="50"
          y="80"
        >
          &nbsp;{{ totalHeaderKey | translate }}
        </tspan>
      </text>
    </svg>
  </div>
</div>

<ng-template #noData>
  <div class="no-data-container">
    {{ 'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate }}
  </div>
</ng-template>

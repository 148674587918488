/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { withCache } from '@ngneat/cashew';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, EndpointV2, HttpService } from '@ws1c/intelligence-common';
import {
  AutomationTemplate,
  AutomationTemplateSearchResponse,
  DashboardTemplate,
  DashboardTemplateImport,
  DashboardView,
  GenericSearchRequest,
  ReportTemplate,
  ReportTemplateRequest,
  ReportTemplateSearchRequest,
  ReportTemplateSearchResponse,
  ResourceDeployRequest,
  ResourceDeployResponse,
  ResourceDeployValidationResponse,
  ScriptTemplateSearchResponse,
  SensorTemplateSearchResponse,
  Tag,
  TEMPLATE_TYPE,
  WidgetTemplate,
  WidgetTemplateSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * TemplateService
 * @export
 * @class TemplateService
 */
@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  /**
   * constructor
   * @param {HttpService} httpService
   * @memberof TemplateService
   */
  constructor(private httpService: HttpService) {}

  /**
   * getTags
   *
   * @returns {Observable<Tag[]>}
   * @memberof TemplateService
   */
  public getTags(): Observable<Tag[]> {
    return this.httpService
      .get(Endpoint.TEMPLATES_TAGS, {
        context: withCache(),
      })
      .pipe(
        map((response: GenericObject) => response.data.map((tag: GenericObject) => deserialize(Tag, tag))),
        catchError(requestErrorHandler),
      );
  }

  /**
   * addBookmark
   * @param {string} id
   * @param {string} type
   * @returns {Observable<WidgetTemplate>}
   * @memberof TemplateService
   */
  public addWidgetTemplateBookmark(id: string, type: string): Observable<WidgetTemplate> {
    return this.httpService.post(Endpoint.TEMPLATES_BOOKMARK_ID(type, id), {}).pipe(
      map((response: GenericObject) => deserialize(WidgetTemplate, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteBookmark
   * @param {string} id
   * @param {string} type
   * @returns {Observable<WidgetTemplate>}
   * @memberof TemplateService
   */
  public deleteWidgetTemplateBookmark(id: string, type: string): Observable<WidgetTemplate> {
    return this.httpService.delete(Endpoint.TEMPLATES_BOOKMARK_ID(type, id)).pipe(
      map((response: GenericObject) => deserialize(WidgetTemplate, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getWidgetTemplateByName
   * @param {string} integration
   * @param {string} entity
   * @param {string} name
   * @returns {Observable<WidgetTemplate>}
   * @memberof TemplateService
   */
  public getWidgetTemplateByName(integration: string, entity: string, name: string): Observable<WidgetTemplate> {
    return this.httpService.get(EndpointV2.WIDGET_TEMPLATE_BY_INTEGRATION_ENTITY_NAME(integration, entity, name)).pipe(
      map((response: GenericObject) => deserialize(WidgetTemplate, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getWidgetTemplates
   * @returns {Observable<WidgetTemplateSearchResponse>}
   * @memberof TemplateService
   */
  public getWidgetTemplates(): Observable<WidgetTemplate[]> {
    return this.httpService.get(EndpointV2.WIDGET_TEMPLATE_ROOT).pipe(
      map((response: GenericObject) => deserialize(WidgetTemplateSearchResponse, response).data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAutomationTemplates
   * @returns {Observable<AutomationTemplate[]>}
   * @memberof TemplateService
   */
  public getAutomationTemplates(): Observable<AutomationTemplate[]> {
    return this.httpService.get(EndpointV2.AUTOMATION_TEMPLATES).pipe(
      map((response: GenericObject) => deserialize(AutomationTemplateSearchResponse, response).data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAutomationTemplateByNameV3
   * @param {string} integration
   * @param {string} entity
   * @param {string} name
   * @returns {Observable<AutomationTemplate>}
   * @memberof TemplateService
   */
  public getAutomationTemplateByNameV3(integration: string, entity: string, name: string): Observable<AutomationTemplate> {
    return this.httpService
      .get(EndpointV2.AUTOMATION_TEMPLATE_DETAILS_V3(integration, entity, name), {
        context: withCache(),
      })
      .pipe(
        map((response: GenericObject) => deserialize(AutomationTemplate, response.data)),
        catchError(requestErrorHandler),
      );
  }

  /**
   * importDashboardTemplates
   * @param {DashboardTemplateImport[]} templateImports
   * @returns {Observable<DashboardView[]>}
   * @memberof TemplateService
   */
  public importDashboardTemplates(templateImports: DashboardTemplateImport[]): Observable<DashboardView[]> {
    return this.httpService.post(EndpointV2.TEMPLATE_DASHBOARD_IMPORT, templateImports).pipe(
      map((response: GenericObject) => response.data.map((dashboard: GenericObject) => deserialize(DashboardView, dashboard))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getDashboardTemplateByName
   * @param {string} name
   * @returns {Observable<DashboardTemplate>}
   * @memberof TemplateService
   */
  public getDashboardTemplateByName(name: string): Observable<DashboardTemplate> {
    return this.httpService.get(EndpointV2.TEMPLATE_DASHBOARD_BY_NAME(name)).pipe(
      map((response: GenericObject) => deserialize(DashboardTemplate, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getDashboardTemplates
   * @returns {Observable<DashboardTemplate[]>}
   * @memberof TemplateService
   */
  public getDashboardTemplates(): Observable<DashboardTemplate[]> {
    return this.httpService.get(EndpointV2.TEMPLATE_DASHBOARD).pipe(
      map((response: GenericObject) =>
        response.data.map((dashboardTemplate: GenericObject) => deserialize(DashboardTemplate, dashboardTemplate)),
      ),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getReportTemplates
   *
   * @returns {Observable<ReportTemplateSearchResponse>}
   * @memberof TemplateService
   */
  public getReportTemplates(): Observable<ReportTemplate[]> {
    const request = new ReportTemplateSearchRequest({ size: 500 });
    return this.httpService.post(Endpoint.TEMPLATES_REPORTS_SEARCH, request).pipe(
      map((response: GenericObject) => deserialize(ReportTemplateSearchResponse, response.data).results),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getReportTemplateByName
   * @param {string} integration
   * @param {string} entity
   * @param {string} name
   * @returns {Observable<ReportTemplate>}
   * @memberof TemplateService
   */
  public getReportTemplateByName(integration: string, entity: string, name: string): Observable<ReportTemplate> {
    return this.httpService.get(Endpoint.TEMPLATES_REPORTS_INTEGRATION_ENTITY_NAME(integration, entity, name)).pipe(
      map((response: GenericObject) => deserialize(ReportTemplate, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * searchReportTemplates
   * @param {ReportTemplateSearchRequest} reportTemplateSearchRequest
   * @returns {Observable<ReportTemplateSearchResponse>}
   * @memberof TemplateService
   */
  public searchReportTemplates(reportTemplateSearchRequest: ReportTemplateSearchRequest): Observable<ReportTemplateSearchResponse> {
    return this.httpService.post(Endpoint.TEMPLATES_REPORTS_SEARCH, reportTemplateSearchRequest).pipe(
      map((response: GenericObject) => deserialize(ReportTemplateSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getReportTemplate
   *
   * @param {string} reportTemplateId
   * @returns {Observable<ReportTemplate> }
   * @memberof TemplateService
   */
  public getReportTemplate(reportTemplateId: string): Observable<ReportTemplate> {
    return this.httpService.get(Endpoint.TEMPLATES_REPORTS_ID(reportTemplateId)).pipe(
      map((response: GenericObject) => deserialize(ReportTemplate, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * createReportTemplate
   *
   * @param {ReportTemplateRequest} reportTemplateRequest
   * @returns {Observable<boolean>}
   * @memberof TemplateService
   */
  public createReportTemplate(reportTemplateRequest: ReportTemplateRequest): Observable<boolean> {
    return this.httpService.post(Endpoint.TEMPLATES_REPORTS, reportTemplateRequest).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateReportTemplate
   *
   * @param {ReportTemplateRequest} reportTemplateRequest
   * @returns {Observable<boolean>}
   * @memberof TemplateService
   */
  public updateReportTemplate(reportTemplateRequest: ReportTemplateRequest): Observable<boolean> {
    return this.httpService.put(Endpoint.TEMPLATES_REPORTS_ID(reportTemplateRequest.id), reportTemplateRequest).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteReportTemplate
   *
   * @param {string} reportTemplateId
   * @returns {Observable<boolean>}
   * @memberof TemplateService
   */
  public deleteReportTemplate(reportTemplateId: string): Observable<boolean> {
    return this.httpService.delete(Endpoint.TEMPLATES_REPORTS_ID(reportTemplateId)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getScriptTemplates
   * @param {GenericSearchRequest} searchRequest
   * @returns {Observable<ScriptTemplateSearchResponse>}
   * @memberof TemplateService
   */
  public getScriptTemplates(searchRequest: GenericSearchRequest): Observable<ScriptTemplateSearchResponse> {
    return this.httpService.post(Endpoint.TEMPLATES_SCRIPTS_SEARCH, searchRequest).pipe(
      map((response: GenericObject) => deserialize(ScriptTemplateSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getSensorTemplates
   * @param {GenericSearchRequest} searchRequest
   * @returns {Observable<SensorTemplateSearchResponse>}
   * @memberof TemplateService
   */
  public getSensorTemplates(searchRequest: GenericSearchRequest): Observable<SensorTemplateSearchResponse> {
    return this.httpService.post(Endpoint.TEMPLATES_SENSORS_SEARCH, searchRequest).pipe(
      map((response: GenericObject) => deserialize(SensorTemplateSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deployTemplate
   * @param {TEMPLATE_TYPE} type
   * @param {string} templateId
   * @param {string} integrationId
   * @param {ResourceDeployRequest} request
   * @returns {Observable<ResourceDeployResponse>}
   * @memberof TemplateService
   */
  public deployTemplate(
    type: TEMPLATE_TYPE,
    templateId: string,
    integrationId: string,
    request: ResourceDeployRequest,
  ): Observable<ResourceDeployResponse> {
    return this.httpService.post(Endpoint.TEMPLATES_DEPLOY_BY_ID(integrationId, type, templateId), request).pipe(
      map((response: GenericObject) => deserialize(ResourceDeployResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * loadTemplateDeployValidations
   * @param {TEMPLATE_TYPE} type
   * @param {string} templateId
   * @param {string} integrationId
   * @returns {Observable<ResourceDeployValidationResponse>}
   * @memberof TemplateService
   */
  public loadTemplateDeployValidations(
    type: TEMPLATE_TYPE,
    templateId: string,
    integrationId: string,
  ): Observable<ResourceDeployValidationResponse> {
    return this.httpService.get(Endpoint.TEMPLATES_DEPLOYMENT_VALIDATIONS_BY_ID(integrationId, type, templateId)).pipe(
      map((response: GenericObject) => deserialize(ResourceDeployValidationResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { SolutionStepConfig } from '@ws1c/intelligence-models';

/**
 * SolutionSetupService
 * @export
 * @class SolutionSetupService
 */
@Injectable({
  providedIn: 'root',
})
export class SolutionSetupService {
  /**
   * constructor
   * @param {HttpService} httpService
   * @memberof SolutionSetupService
   */
  constructor(private httpService: HttpService) {}

  /**
   * getSolutionStepConfig
   * @param {string} solutionStepId
   * @returns {Observable<SolutionStepConfig>}
   * @memberof SolutionSetupService
   */
  public getSolutionStepConfig(solutionStepId: string): Observable<SolutionStepConfig> {
    return this.httpService.get(Endpoint.SOLUTIONS_CONFIG_ID(solutionStepId)).pipe(
      map((response: any) => deserialize(SolutionStepConfig, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * setSolutionStepConfig
   * @param {SolutionStepConfig} solutionStepConfig
   * @returns {Observable<SolutionStepConfig>}
   * @memberof SolutionSetupService
   */
  public setSolutionStepConfig(solutionStepConfig: SolutionStepConfig): Observable<SolutionStepConfig> {
    return this.httpService.put(Endpoint.SOLUTIONS_CONFIG, solutionStepConfig).pipe(
      map((response: any) => deserialize(SolutionStepConfig, response.data)),
      catchError(requestErrorHandler),
    );
  }
}

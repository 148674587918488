/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { AccordionWizardConfig } from '@ws1c/intelligence-common';
import { LOAD_STATE, UserTouchDetails } from '@ws1c/intelligence-models';

/**
 * SolutionSetupState
 * @export
 * @interface SolutionSetupState
 */
export interface SolutionSetupState {
  accordionWizardConfigsById: Record<string, AccordionWizardConfig>;
  userTouchDetailsByConfigType: Record<string, UserTouchDetails>;
  setupConfigLoadStatusByConfigType: Record<string, LOAD_STATE>;
}

/**
 * initialSolutionSetupState
 * @export
 */
export const initialSolutionSetupState: SolutionSetupState = {
  accordionWizardConfigsById: {},
  userTouchDetailsByConfigType: {},
  setupConfigLoadStatusByConfigType: {},
};

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { each } from 'lodash-es';

import { IntegrationDetails, ObjectById, OrgPreference, OrgTreeNode } from '@ws1c/intelligence-models';

/**
 * setOrgsById
 * @export
 * @param {OrgTreeNode} orgTreeNode
 * @param {ObjectById<OrgTreeNode>} orgsById
 */
export function setOrgsById(orgTreeNode: OrgTreeNode, orgsById: ObjectById<OrgTreeNode>) {
  if (!orgTreeNode) {
    return;
  }
  each(orgTreeNode.children, (childNode: OrgTreeNode) => {
    setOrgsById(childNode, orgsById);
  });
  orgsById[orgTreeNode.value] = orgTreeNode;
}

/**
 * preRequisiteForOnboardingStep
 * @export
 * @param {OrgPreference} orgPreference
 * @param {IntegrationDetails} latestGemProvisionedAccessTenant
 * @returns {boolean}
 */
export function preRequisiteForOnboardingStep(orgPreference: OrgPreference, latestGemProvisionedAccessTenant: IntegrationDetails): boolean {
  return orgPreference !== OrgPreference.ACCESS_TENANT_REPROVISION_STEP_COMPLETED || latestGemProvisionedAccessTenant !== null;
}

/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { LOAD_STATE, OptInFeatureFlag, OrgDisabledConfig, OrgSettings, OrgTreeNode } from '@ws1c/intelligence-models';

/**
 * Defines UI State for Org Status Feature
 *
 * @export
 * @interface OrgState
 */
export interface OrgState {
  currentOrgPreferenceKeys: string[];
  isAbortingOnboardingStep: boolean;
  isCompletingOnboardingStep: boolean;
  isServiceOptInModalOpen: boolean;
  orgDisabledConfig: OrgDisabledConfig;
  orgHierachy: OrgTreeNode;
  orgPreferenceUpdateState: LOAD_STATE;
  orgSettings: OrgSettings;
  showConfirmEnableFeatureModal: boolean;
  showConfirmDisableFeatureModal: boolean;
  currentOperationInProgress: boolean;
  currentOptingFeature: OptInFeatureFlag;
}

/**
 * initialOrgState
 */
export const initialOrgState: OrgState = {
  currentOrgPreferenceKeys: [],
  isAbortingOnboardingStep: false,
  isCompletingOnboardingStep: false,
  isServiceOptInModalOpen: false,
  orgDisabledConfig: null,
  orgHierachy: null,
  orgPreferenceUpdateState: LOAD_STATE.NONE,
  orgSettings: new OrgSettings({
    preferences: {},
  }),
  showConfirmEnableFeatureModal: false,
  showConfirmDisableFeatureModal: false,
  currentOperationInProgress: false,
  currentOptingFeature: undefined,
};

/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { GroupedBaseChartComponent } from '@ws1c/dashboard-common/chart/grouped-chart/grouped-base-chart.component';

/**
 * GroupedVerticalChartComponent
 * @export
 * @class GroupedVerticalChartComponent
 * @extends {GroupedBaseChartComponent}
 */
@Component({
  selector: 'dpa-grouped-vertical-chart',
  templateUrl: 'grouped-vertical-chart.component.html',
  styleUrls: ['grouped-vertical-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedVerticalChartComponent extends GroupedBaseChartComponent {}

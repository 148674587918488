/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BubbleChartComponent as NgxBubbleChart } from '@dpa/ui-common';

import { BaseChartComponent } from '@ws1c/dashboard-common/chart/base-chart.component';
import { ChartDrilldownEvent, NgxChart } from '@ws1c/intelligence-models';

/**
 * BubbleChartComponent
 * @export
 * @class BubbleChartComponent
 */
@Component({
  selector: 'dpa-bubble-chart',
  templateUrl: 'bubble-chart.component.html',
  styleUrls: ['bubble-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BubbleChartComponent extends BaseChartComponent {
  @Input() public ngxChart: NgxChart;
  @Input() public showDetailsLink?: boolean = false;
  @Input() public isPercentage?: boolean = false;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public seriesTooltip?: TemplateRef<any>;
  @Input() public yAxisLabel?: string;
  @Input() public customColors?: any[];
  @Output() public onViewDetails = new EventEmitter<ChartDrilldownEvent>();

  @ViewChild(NgxBubbleChart, { static: true }) public chart: NgxBubbleChart;

  public readonly maxRadius: number = 25;
  public readonly minRadius: number = 5;

  /**
   * onSelect
   * @param {any} ngxSelectEvent
   * @memberof BubbleChartComponent
   */
  public onSelect(ngxSelectEvent: any) {
    if (this.showDetailsLink) {
      ngxSelectEvent.name = this.ngxChart.dateFormatter(ngxSelectEvent.x);
      const drilldownEvent: ChartDrilldownEvent = this.ngxChart.drilldownEventBuilder.getEvent(ngxSelectEvent);
      this.onViewDetails.emit(drilldownEvent);
    }
  }

  /**
   * onResize
   * @memberof BubbleChartComponent
   */
  public onResize() {
    if (!this.chart) {
      return;
    }
    this.chart.update();
  }
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, EndpointV2, HttpService } from '@ws1c/intelligence-common';
import { TrialActivationDetail, TrialDetail, TrialUserContactDetails } from '@ws1c/intelligence-models';

/**
 * Provides REST interface for Trial Services
 *
 * @export
 * @class TrialService
 */
@Injectable({
  providedIn: 'root',
})
export class TrialService {
  /**
   * Creates an instance of TrialService
   * @param {HttpService} httpService - Instance of HttpService
   * @memberof TrialService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Retrieves trial services
   * @returns {Observable<TrialDetail[]>} - Returns observable of trial service list
   * @memberof TrialService
   */
  public getTrialDetails(): Observable<TrialDetail[]> {
    return this.httpService.get(Endpoint.TRIAL_ROOT).pipe(
      map((response: GenericObject) => response.data.map((trialDetail: GenericObject) => deserialize(TrialDetail, trialDetail))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Initiates in-product trial activation request for specified service type
   * @param {string} serviceType
   * @param {TrialUserContactDetails} userContactDetails
   * @returns {Observable<TrialActivationDetails>}
   * @memberof TrialService
   */
  public startTrialV2(serviceType: string, userContactDetails: TrialUserContactDetails): Observable<TrialActivationDetail> {
    return this.httpService.post(EndpointV2.START_IN_PRODUCT_TRIAL(serviceType), userContactDetails).pipe(
      map((response: GenericObject) => deserialize(TrialActivationDetail, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Retrieves trial user contact details
   * @returns {Observable<TrialUserContactDetails>} - Returns observable of trial user contact details
   * @memberof TrialService
   */
  public getTrialUserContactDetailsV2(): Observable<TrialUserContactDetails> {
    return this.httpService.get(EndpointV2.TRIAL_USER_CONTACT_DETAILS_IN_PRODUCT_TRIAL).pipe(
      map((response: GenericObject) => deserialize(TrialUserContactDetails, response.data)),
      catchError(requestErrorHandler),
    );
  }
}

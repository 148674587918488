/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { IntegrationDetails, UserDetails } from '@ws1c/intelligence-models';

/**
 * getIntegrationTenants
 * @param {IntegrationDetails[]} availableGemIntegrationDetailsList - GEM integrations list
 * @param {IntegrationDetails[]} availableAdminIntegrationList - Admin integrations list
 * @param {string} integration - Integration type
 * @returns {IntegrationDetails[]}
 */
export const getIntegrationTenants = (
  availableGemIntegrationDetailsList: IntegrationDetails[],
  availableAdminIntegrationList: IntegrationDetails[],
  integration: string,
): IntegrationDetails[] => {
  const currentAdminIntegrationDetailsList: IntegrationDetails[] = availableAdminIntegrationList?.filter(
    (integrationDetails: IntegrationDetails) => {
      return integrationDetails.integration === integration;
    },
  );
  // Below default integration object will be used to render a tenant card
  // to allow user to set up an admin integration if doesn't exist already
  const defaultIntegrationDetails: IntegrationDetails = new IntegrationDetails({
    integration,
    createdByDetails: new UserDetails(),
    modifiedByDetails: new UserDetails(),
  });
  const adminIntegrationDetails: IntegrationDetails =
    currentAdminIntegrationDetailsList?.find((integrationDetails: IntegrationDetails) => {
      return integrationDetails.active;
    }) || defaultIntegrationDetails;
  const currentGemIntegrationDetailsList: IntegrationDetails[] = availableGemIntegrationDetailsList?.filter(
    (integrationDetails: IntegrationDetails) => {
      return integrationDetails.integration === integration;
    },
  );
  return [
    ...currentGemIntegrationDetailsList,
    new IntegrationDetails({
      ...adminIntegrationDetails,
      // Possible values:
      // INTEGRATIONS.AIRWATCH.TITLE
      // INTEGRATIONS.IDM.TITLE
      // INTEGRATIONS.HORIZON.TITLE
      tenantName: `INTEGRATIONS.${integration.toUpperCase()}.TITLE`,
    }),
  ];
};

/**
 * isSidePanelAllowedForIntegration
 * @param {boolean} isMultiSubscriptionsEnabled - Flag to indicate if multi subscription is enabled
 * @param {IntegrationDetails[]} availableGemProvisionedIntegrationsForOptIn - GEM provisioned integrations list
 * @param {string} integration - Integration type
 * @returns {boolean}
 */
export const isSidePanelAllowedForIntegration = (
  isMultiSubscriptionsEnabled: boolean,
  availableGemProvisionedIntegrationsForOptIn: IntegrationDetails[],
  integration: string,
): boolean => {
  if (!isMultiSubscriptionsEnabled) {
    return false;
  }
  const availableGemProvisionedIntegrations: IntegrationDetails[] = availableGemProvisionedIntegrationsForOptIn.filter(
    (integrationDetails: IntegrationDetails) => {
      return integration === integrationDetails.integration;
    },
  );
  // Show preview button only if there is more than one GEM integration
  return availableGemProvisionedIntegrations.length > 1;
};

/**
 * Returns first active instance from integrations instances list
 * @param {IntegrationDetails[]} integrationDetailsList - Integration details list
 * @param {string} integration - Integration type
 * @returns {IntegrationDetails}
 */
export function getFirstActiveIntegrationInstance(integrationDetailsList: IntegrationDetails[], integration: string): IntegrationDetails {
  return (integrationDetailsList || []).find((integrationDetails: IntegrationDetails) => {
    return integrationDetails.integration === integration && integrationDetails.active;
  });
}

/**
 * Returns first integration instance from integrations instances list
 * @param {IntegrationDetails[]} integrationDetailsList - Integration details list
 * @param {string} integration - Integration type
 * @returns {IntegrationDetails}
 */
export function getFirstIntegrationInstance(integrationDetailsList: IntegrationDetails[], integration: string): IntegrationDetails {
  return (integrationDetailsList || []).find((integrationDetails: IntegrationDetails) => integrationDetails.integration === integration);
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { IntegratedServicesResponse } from '@ws1c/intelligence-models';

/**
 * Provides REST interface for Integrated Services
 *
 * @export
 * @class IntegratedServicesService
 */
@Injectable({
  providedIn: 'root',
})
export class IntegratedServicesService {
  /**
   * Creates an instance of IntegratedServicesService
   * @param {HttpService} httpService - Instance of HttpService
   * @memberof IntegratedServicesService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Retrieves integrated services
   * @returns {Observable<IntegratedServicesResponse>} - Returns observable of integrated services
   * @memberof IntegratedServicesService
   */
  public getIntegratedServices(): Observable<IntegratedServicesResponse> {
    return this.httpService.get(Endpoint.INTEGRATED_SERVICES).pipe(
      map((response: any) => deserialize(IntegratedServicesResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }
}

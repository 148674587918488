/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * MissingSymbolicationFile
 * @export
 * @interface MissingSymbolicationFile
 */
export interface MissingSymbolicationFile {
  fileName: string;
  uuid: string;
}

/**
 * AppCrashThread
 * @export
 * @class AppCrashThread
 */
@Serializable
export class AppCrashThread {
  @JsonProperty('name')
  public name: string;

  @JsonProperty('error_reason')
  public reason: string;

  @JsonProperty('_app_version')
  public appVersion: string;

  @JsonProperty('lines')
  public lines: string[];

  @JsonProperty('partially_symbolicated')
  public partiallySymbolicated: boolean;

  @JsonProperty('missing_symbolication_files')
  public missingSymbolicationFiles: string[];

  /**
   * Creates an instance of AppCrashThread.
   * @param {...Array<Partial<AppCrashThread>>} args
   * @memberof AppCrashThread
   */
  constructor(...args: Array<Partial<AppCrashThread>>) {
    Object.assign(this, ...args);
  }

  /**
   * cleanedMissingSymbolicationFiles
   * @readonly
   * @type {MissingSymbolicationFile[]}
   * @memberof AppCrashThread
   */
  public get cleanedMissingSymbolicationFiles(): MissingSymbolicationFile[] {
    const results: MissingSymbolicationFile[] = [];
    this.missingSymbolicationFiles?.forEach((line: string = '', index: number) => {
      // eslint-disable-next-line no-regex-spaces
      const data = line.replace(/  +/g, ' ').replace(/</g, '').replace(/>/g, '').split(' ');
      results[index] = {
        fileName: data[3],
        uuid: data[5],
      } as MissingSymbolicationFile;
    });
    return results;
  }
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { FeatureControl } from '@ws1c/intelligence-common';
import { Feature } from '@ws1c/intelligence-core/models/feature.interface';
import { OrgPreference } from '@ws1c/intelligence-models';

const ROUTE_NAMES: { [key: string]: string } = {
  ONBOARDING_PAGE_TRIAL_QUESTIONNAIRE: 'onboarding/intelligence-onboarding/trial-questionnaire',
  ONBOARDING_PAGE_TENANT_URL_CONFIGURATION_SETUP: 'onboarding/intelligence-onboarding/tenant-url-configuration/setup',
};

export const ONBOARDING_PREFERENCE_TO_FEATURE_MAP: Map<OrgPreference, Feature> = new Map([
  [
    OrgPreference.TRIAL_CUSTOMER_QUESTIONNAIRE_STEP_COMPLETED,
    {
      featureControlKey: FeatureControl.PRODUCT_TRIAL_QUESTIONNAIRE_ENABLED,
      route: ROUTE_NAMES.ONBOARDING_PAGE_TRIAL_QUESTIONNAIRE,
      isTrialOnly: true,
    },
  ],
  [
    OrgPreference.ACCESS_TENANT_REPROVISION_STEP_COMPLETED,
    {
      featureControlKey: FeatureControl.ACCESS_RENAME_TENANT_ENABLED,
      route: ROUTE_NAMES.ONBOARDING_PAGE_TENANT_URL_CONFIGURATION_SETUP,
      isTrialOnly: false,
    },
  ],
]);

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSurveyQuestionFormat
 * @export
 * @enum {string}
 */
export enum DeemSurveyQuestionFormat {
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT',
  RATING = 'RATING',
}

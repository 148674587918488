/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSyntheticURLMonitoringTestType
 * @enum {string}
 * @export
 */
export enum DeemSyntheticURLMonitoringTestType {
  HTTPS = 'HTTPS',
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * InsightGroupBy
 * @export
 * @enum {string}
 */
export enum InsightGroupBy {
  APP_VERSION = 'App Version',
  OS_VERSION = 'OS Version',
}

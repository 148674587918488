/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, EndpointV2, HttpService } from '@ws1c/intelligence-common';
import { CustomReportPreviewSearchResponse, PreviewReportContentRequest, TrendMode } from '@ws1c/intelligence-models';

/**
 * Provide access to Report Meta and Templates Information
 * These are SYSTEM, READ ONLY information
 * @link: ReportMetaController.java
 * @link TemplateController.java
 * @link ReportController#getFilterSuggestions
 * @export
 * @class ReportMetaService
 */
@Injectable({
  providedIn: 'root',
})
export class ReportMetaService {
  /**
   * Creates an instance of ReportMetaService
   * @param {HttpService} http
   * @memberof ReportMetaService
   */
  constructor(private http: HttpService) {}

  /**
   * previewReport
   * @param {string} entity
   * @param {*} searchRequest
   * @returns {Observable<CustomReportPreviewSearchResponse>}
   * @memberof ReportMetaService
   */
  public previewReport(entity: string, searchRequest: any): Observable<CustomReportPreviewSearchResponse> {
    return this.http.post(Endpoint.REPORT_PREVIEW_FOR_CATEGORY(entity.toLowerCase()), searchRequest).pipe(
      map((response: any) => deserialize(CustomReportPreviewSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * previewReportV2
   * @param {PreviewReportContentRequest} searchRequest
   * @returns {Observable<CustomReportPreviewSearchResponse>}
   * @memberof ReportMetaService
   */
  public previewReportV2(searchRequest: PreviewReportContentRequest): Observable<CustomReportPreviewSearchResponse> {
    return this.http.post(EndpointV2.REPORTS_PREVIEW, searchRequest).pipe(
      map((response: GenericObject) => deserialize(CustomReportPreviewSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * previewReportContentMultiV2
   * @param {PreviewReportContentRequest[]} searchRequests
   * @returns {Observable<CustomReportPreviewSearchResponse>}
   * @memberof ReportMetaService
   */
  public previewReportContentMultiV2(searchRequests: PreviewReportContentRequest[]): Observable<CustomReportPreviewSearchResponse[]> {
    return this.http.post(EndpointV2.CONTENT_MULTI_SEARCH, searchRequests).pipe(
      map((multiResponse: any) => multiResponse.data.map((responseData) => deserialize(CustomReportPreviewSearchResponse, responseData))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * previewReportAny
   *
   * There are currently two APIs for getting the report table data
   * The Historical data endpoint is: previewReportContentForMultiIntegrationV2 or previewReportContent
   * The Snapshot data endpoint is: previewReportV2
   *
   * @param {PreviewReportContentRequest} request
   * @param {boolean} [isV2=false]
   * @returns  {Observable<CustomReportPreviewSearchResponse>}
   * @memberof ReportMetaService
   */
  public previewReportAny(request: PreviewReportContentRequest, isV2: boolean = false): Observable<CustomReportPreviewSearchResponse> {
    if (request.trendMode === TrendMode.HISTORICAL) {
      return isV2 ? this.previewReportContentForMultiIntegrationV2(request) : this.previewReportContent(request);
    }
    return this.previewReportV2(request);
  }

  /**
   * previewReportContent
   * @param {PreviewReportContentRequest} request
   * @returns {Observable<CustomReportPreviewSearchResponse>}
   * @memberof ReportMetaService
   */
  public previewReportContent(request: PreviewReportContentRequest): Observable<CustomReportPreviewSearchResponse> {
    return this.http.post(Endpoint.CONTENT_SEARCH, request).pipe(
      map((response: any) => deserialize(CustomReportPreviewSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * previewReportContentForMultiIntegrationV2
   * For historical data with fully qualified names in PreviewReportContentRequest
   * @param {PreviewReportContentRequest} request
   * @returns {Observable<CustomReportPreviewSearchResponse>}
   * @memberof ReportMetaService
   */
  public previewReportContentForMultiIntegrationV2(request: PreviewReportContentRequest): Observable<CustomReportPreviewSearchResponse> {
    return this.http.post(EndpointV2.CONTENT_SEARCH, request).pipe(
      map((response: any) => deserialize(CustomReportPreviewSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }
}

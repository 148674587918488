/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { QuickFilterActions } from './quick-filter.actions';
import { initialQuickFilterState, QuickFilterState } from './quick-filter.store';

const _reducer: ActionReducer<QuickFilterState, Action> = createReducer(
  initialQuickFilterState,

  on(
    QuickFilterActions.setTags,
    (state: QuickFilterState, { tags }: ReturnType<typeof QuickFilterActions.setTags>): QuickFilterState => ({
      ...state,
      tags,
    }),
  ),
);

/**
 * quickFilterState
 * @export
 * @param {QuickFilterState} state
 * @param {Action} action
 * @returns {QuickFilterState}
 */
export function quickFilterState(state: QuickFilterState, action: Action): QuickFilterState {
  return _reducer(state, action);
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTimeFormat } from '@dpa/ui-common';

/**
 * LastUpdatedComponent
 * @export
 * @class LastUpdatedComponent
 */
@Component({
  selector: 'dpa-last-updated',
  templateUrl: 'last-updated.component.html',
  styleUrls: ['last-updated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastUpdatedComponent {
  @Input() public lastUpdated: number;
  @Input() public showDivider?: boolean = true;
  @Output() public onRefresh: EventEmitter<void> = new EventEmitter();

  public readonly DateTimeFormat = DateTimeFormat;

  /**
   * refresh
   * @memberof LastUpdatedComponent
   */
  public refresh() {
    this.onRefresh.emit();
  }
}

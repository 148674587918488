/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

/**
 * LegendItemComponent
 * @export
 * @class LegendItemComponent
 */
@Component({
  selector: 'dpa-legend-item',
  templateUrl: 'legend-item.component.html',
  styleUrls: ['legend-item.component.scss'],
})
export class LegendItemComponent {
  @Input() public selected: boolean;
  @Input() public active: boolean = false;
  @Input() public color: string;
  @Input() public name: string;
}

<div
  *ngIf="ngxChart"
  (resized)="onResize()"
  class="chart-container"
>
  <ng-container *ngIf="!overlayChart; else overlayCharts">
    <dpa-grouped-vertical-chart
      *ngIf="ngxChart.isMultiDataSet"
      [ngxChart]="ngxChart"
      [scheme]="ngxChart.defaultColorScheme"
      [results]="data"
      [legend]="false"
      [xAxisLabel]="ngxChart.labels.firstGroupBy"
      [yAxisLabel]="yAxisLabel"
      [gradient]="false"
      [showXAxis]="showXAxis"
      [showYAxis]="showYAxis"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="!!yAxisLabel"
      [animations]="true"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [maxXAxisTickLength]="maxXAxisTickLength"
      [trimXAxisTicks]="true"
      [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
      [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
      [customColors]="ngxChart.customColors"
      [showDataLabel]="showDataLabel"
      [dataLabelFormatting]="ngxChart.labels.counterFormatter"
      [yScaleMax]="yScaleMax"
      [isStacked]="chartType !== AggregationWidgetChartType.VERTICAL_GROUP"
      [stackedTooltip]="stackedTooltip"
      [showDetailsLink]="showDetailsLink"
      (viewDetails)="onDrilldown($event)"
    ></dpa-grouped-vertical-chart>

    <ngx-charts-bar-vertical
      *ngIf="!ngxChart.isMultiDataSet"
      #chart
      [scheme]="ngxChart.defaultColorScheme"
      [results]="data"
      [legend]="false"
      [xAxisLabel]="ngxChart.labels.firstGroupBy"
      [yAxisLabel]="yAxisLabel"
      [gradient]="false"
      [xAxis]="showXAxis"
      [yAxis]="true"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="!!yAxisLabel"
      [animations]="true"
      [roundEdges]="false"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [trimXAxisTicks]="true"
      [maxXAxisTickLength]="maxXAxisTickLength"
      [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
      [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
      [customColors]="ngxChart.customColors"
      [showDataLabel]="showDataLabel"
      [dataLabelFormatting]="ngxChart.labels.counterFormatter"
      [yScaleMax]="yScaleMax"
      (select)="onSelect($event)"
    >
      <ng-template
        #tooltipTemplate
        let-model="model"
      >
        <ng-template
          *ngIf="model"
          [ngTemplateOutlet]="stackedTooltip"
          [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
        ></ng-template>
      </ng-template>
    </ngx-charts-bar-vertical>
  </ng-container>
  <ng-template #overlayCharts>
    <dpa-bar-vertical-overlay-chart
      #chart
      [isStacked]="ngxChart.isMultiDataSet"
      [scheme]="ngxChart.defaultColorScheme"
      [schemeForLine]="overlayChart.defaultColorScheme"
      [results]="data"
      [overlayResults]="overlayData"
      [customColorsForLine]="overlayChart.customColors"
      [xAxisLabel]="ngxChart.labels.firstGroupBy"
      [yAxisLabel]="yAxisLabel"
      [yAxisRightLabel]="overlayChart.labels.counter"
      [gradient]="false"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="!!yAxisLabel"
      [animations]="true"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [maxXAxisTickLength]="maxXAxisTickLength"
      [trimXAxisTicks]="true"
      [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
      [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
      [customColors]="ngxChart.customColors"
      [showDataLabel]="showDataLabel"
      [dataLabelFormatting]="ngxChart.labels.counterFormatter"
      [yScaleMax]="yScaleMax"
      [focussedSequence]="focussedSequence"
      [tooltipTemplate]="overlayTooltipTemplate"
      (select)="onOverlayDrilldown($event)"
    ></dpa-bar-vertical-overlay-chart>
  </ng-template>
</div>

<ngx-charts-chart
  [view]="[width - 100, height]"
  [showLegend]="false"
  [activeEntries]="activeEntries"
  [animations]="true"
>
  <svg:g
    [attr.transform]="transform"
    class="bar-chart chart"
  >
    <svg:g
      *ngIf="xAxis"
      [xScale]="xScale"
      [dims]="dims"
      [showLabel]="showXAxisLabel"
      [labelText]="xAxisLabel"
      [tickFormatting]="xAxisTickFormatting"
      [wrapTicks]="wrapTicks"
      [trimTicks]="trimXAxisTicks"
      [rotateTicks]="rotateXAxisTicks"
      [maxTickLength]="maxXAxisTickLength"
      [ticks]="xAxisTicks"
      (dimensionsChanged)="updateXAxisHeight($event)"
      ngx-charts-x-axis
    ></svg:g>
    <!-- Y Axis Left -->
    <svg:g
      *ngIf="yAxis"
      [yScale]="yScale"
      [dims]="dims"
      [yOrient]="ORIENTATION.Left"
      [showGridLines]="showGridLines"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [tickFormatting]="yAxisTickFormatting"
      [wrapTicks]="wrapTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"
      ngx-charts-y-axis
    ></svg:g>
    <!-- Y Axis Right -->
    <svg:g
      *ngIf="yAxis"
      [yScale]="yScaleLine"
      [dims]="dims"
      [yOrient]="yOrientRight"
      [showGridLines]="false"
      [showLabel]="showYAxisLineLabel"
      [labelText]="yAxisRightLabel"
      [tickFormatting]="yAxisTickFormatting"
      [wrapTicks]="wrapTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"
      ngx-charts-y-axis
    ></svg:g>
    <!-- Bar Vertical -->
    <svg:g
      *ngIf="!isStacked"
      [dims]="dims"
      [xScale]="xScale"
      [yScale]="yScale"
      [colors]="colors"
      [series]="results"
      [gradient]="gradient"
      [tooltipDisabled]="tooltipDisabled"
      [tooltipTemplate]="overlayTooltip"
      [showDataLabel]="showDataLabel"
      [dataLabelFormatting]="dataLabelFormatting"
      [activeEntries]="activeEntries"
      [roundEdges]="false"
      [animations]="true"
      [noBarWhenZero]="noBarWhenZero"
      [attr.opacity]="mainChartOpacity"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
      (select)="onClick($event)"
      (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event)"
      ngx-charts-series-vertical
    ></svg:g>
    <svg:g *ngIf="isStacked">
      <svg:g
        *ngFor="let group of results; let index = index; trackBy: trackBy"
        [@animationState]="'active'"
        [attr.transform]="groupTransform(group)"
      >
        <svg:g
          [type]="BAR_CHART_TYPE.Stacked"
          [xScale]="xScale"
          [yScale]="yScale"
          [activeEntries]="activeEntries"
          [colors]="colors"
          [series]="group.series"
          [dims]="dims"
          [gradient]="gradient"
          [tooltipDisabled]="tooltipDisabled"
          [tooltipTemplate]="overlayTooltip"
          [showDataLabel]="showDataLabel"
          [dataLabelFormatting]="dataLabelFormatting"
          [seriesName]="group.name"
          [animations]="true"
          [noBarWhenZero]="noBarWhenZero"
          [attr.opacity]="mainChartOpacity"
          (select)="onClick($event, group)"
          (activate)="onActivate($event, group)"
          (deactivate)="onDeactivate($event, group)"
          (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)"
          ngx-charts-series-vertical
        ></svg:g>
      </svg:g>
    </svg:g>
  </svg:g>
  <!-- Line overlay -->
  <svg:g
    [attr.transform]="transform"
    class="line-chart chart"
  >
    <svg:g *ngIf="!isSSR">
      <svg:g *ngFor="let series of overlayResults; trackBy: trackBy">
        <svg:g
          [xScale]="xScaleLine"
          [yScale]="yScaleLine"
          [colors]="colorsForOverlay"
          [data]="series"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [hasRange]="hasRange"
          [animations]="true"
          [rangeFillOpacity]="rangeFillOpacity"
          [attr.opacity]="overlayOpacity"
          (select)="onClick($event)"
          ngx-charts-line-series
        ></svg:g>
      </svg:g>
    </svg:g>
    <svg:g *ngIf="isSSR">
      <svg:g *ngFor="let series of overlayResults; trackBy: trackBy">
        <svg:g
          [xScale]="xScaleLine"
          [yScale]="yScaleLine"
          [colors]="colorsForOverlay"
          [data]="series"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [rangeFillOpacity]="rangeFillOpacity"
          [hasRange]="hasRange"
          [animations]="true"
          (select)="onClick($event)"
          ngx-charts-line-series
        ></svg:g>
      </svg:g>
    </svg:g>
    <!-- Toolip -->
    <svg:g
      *ngIf="!tooltipDisabled"
      (mouseleave)="hideCircles()"
    >
      <svg:g
        [dims]="dims"
        [xSet]="xLineSet"
        [xScale]="xScaleLine"
        [yScale]="yScaleLine"
        [results]="combinedSeries"
        [colors]="colorsForOverlay"
        [tooltipDisabled]="false"
        [tooltipTemplate]="overlayTooltip"
        (hover)="onHover($event)"
        ngx-charts-tooltip-area
      ></svg:g>
      <svg:g *ngFor="let series of overlayResults; trackBy: trackBy">
        <svg:g
          [xScale]="xScaleLine"
          [yScale]="yScaleLine"
          [colors]="colorsForOverlay"
          [data]="series"
          [scaleType]="scaleType"
          [visibleValue]="!!hoveredSeriesName"
          [activeEntries]="activeEntries"
          [tooltipDisabled]="tooltipDisabled"
          [tooltipTemplate]="overlayTooltip"
          (select)="onClick($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          ngx-charts-circle-series
        ></svg:g>
      </svg:g>
    </svg:g>
  </svg:g>
</ngx-charts-chart>
<ng-template #overlayTooltip>
  <ng-template
    [ngTemplateOutlet]="tooltipTemplate"
    [ngTemplateOutletContext]="{ tooltipItems: widgetHoverInfo, seriesName: hoveredSeriesName }"
  ></ng-template>
</ng-template>

/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { each } from 'lodash-es';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Endpoint, GraphqlService, HttpService } from '@ws1c/intelligence-common';
import {
  Account,
  AccountByUserDescriptor,
  AccountRole,
  AccountSearchResponse,
  GenericSearchRequest,
  IdentifiersRequest,
  RolesSearchResponse,
  UserAdminAccount,
  UserAdminAccountRoles,
  UserDescriptor,
  UserDescriptorsRoles,
} from '@ws1c/intelligence-models';
import { ACCOUNT_SEARCH } from './account-search.graphql';

/**
 * Provides REST Interface for Accounts API's
 *
 * @export
 * @class AccountService
 */
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  /**
   * Creates an instance of AccountService.
   * @param {HttpService} httpService
   * @param {GraphqlService} graphqlService
   * @memberof AccountService
   */
  constructor(
    private httpService: HttpService,
    private graphqlService: GraphqlService,
  ) {}

  /**
   * getAccounts
   *
   * @param {GenericSearchRequest} searchRequest
   * @returns {Observable<AccountSearchResponse>}
   * @memberof AccountService
   */
  public getAccounts(searchRequest: GenericSearchRequest): Observable<AccountSearchResponse> {
    return this.graphqlService
      .query(ACCOUNT_SEARCH, {
        pagedSearchRequestInput: searchRequest,
      })
      .pipe(
        map((response: GenericObject) => {
          // GraphQL search calls return little different response structure, thus massaging data to same format
          response = {
            ...response?.accountDetailsV3?.paged_response,
            results: response?.accountDetailsV3?.account_details,
          };
          return deserialize(AccountSearchResponse, response);
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getAccountById
   * @param {string} id
   * @returns {Observable<Account>}
   * @memberof AccountService
   */
  public getAccountById(id: string): Observable<Account> {
    return this.httpService.get(Endpoint.ACCOUNT_ID(id)).pipe(
      map((response: GenericObject) => deserialize(Account, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Delete account
   * @param {string} id
   * @returns {Observable<boolean>}
   * @memberof AccountService
   */
  public deleteAccount(id: string): Observable<boolean> {
    return this.httpService.delete(Endpoint.ACCOUNT_ID(id)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * delete accounts
   * @param {string[]} identifiers
   * @returns {Observable<boolean>}
   * @memberof AccountService
   */
  public deleteAccounts(identifiers: string[]): Observable<boolean> {
    return this.httpService.delete(Endpoint.ACCOUNT, { body: { identifiers } }).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getIamRoles
   * @returns {Observable<AccountRole[]>}
   * @memberof AccountService
   */
  public getIamRoles(): Observable<AccountRole[]> {
    return this.httpService.get(Endpoint.IAM_ROLES).pipe(
      map((response: GenericObject) => {
        return response.data.map((accountRoleData: GenericObject) => deserialize(AccountRole, accountRoleData));
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * searchIamRoles
   * @param {GenericSearchRequest} request
   * @returns {Observable<RolesSearchResponse>}
   * @memberof AccountService
   */
  public searchIamRoles(request: GenericSearchRequest): Observable<RolesSearchResponse> {
    return this.httpService.post(Endpoint.IAM_ROLES_SEARCH, request).pipe(
      map((response: GenericObject) => deserialize(RolesSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getUsersForAddAccount
   *
   * @param {string} searchTerm
   * @returns {Observable<UserAdminAccount[]>}
   * @memberof AccountService
   */
  public getUsersForAddAccount(searchTerm: string): Observable<UserAdminAccount[]> {
    return this.httpService.post(Endpoint.USER_ADMIN_SEARCH_FOR_ROLE_ASSIGNMENT(searchTerm), {}).pipe(
      map((response: GenericObject) => {
        return response.data.map((account: GenericObject) => deserialize(UserAdminAccount, account));
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * addUserAndUpdateStatus
   * @param {UserAdminAccountRoles} userAdminAccountRoles
   * @returns {Observable<Account>}
   * @memberof AccountService
   */
  public addUserAndUpdateStatus(userAdminAccountRoles: UserAdminAccountRoles): Observable<Account> {
    return this.addUser(userAdminAccountRoles).pipe(
      mergeMap((account: Account) => {
        return this.updateAccountStatus(account.id, userAdminAccountRoles.active).pipe(
          map(() => account),
          catchError(requestErrorHandler),
        );
      }),
    );
  }

  /**
   * addUser
   * @param {UserAdminAccountRoles} userAdminAccountRoles
   * @returns {Observable<Account>}
   * @memberof AccountService
   */
  public addUser(userAdminAccountRoles: UserAdminAccountRoles): Observable<Account> {
    return this.httpService.post(Endpoint.IAM_ACCOUNT, userAdminAccountRoles).pipe(
      map((response: GenericObject) => deserialize(Account, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateUser
   * @param {Account} account
   * @returns {Observable<boolean>}
   * @memberof AccountService
   */
  public updateUser(account: Account): Observable<boolean> {
    const accountUpdate = this.httpService.put(Endpoint.IAM_ACCOUNT_ID(account.id), account.roleIds).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
    const statusUpdate = this.updateAccountStatus(account.id, account.active);
    return forkJoin([accountUpdate, statusUpdate]).pipe(map(() => true));
  }

  /**
   * updateUserByUserDescriptor
   * @param {UserAdminAccountRoles} userAdminAccountRoles
   * @returns {Observable<boolean>}
   * @memberof AccountService
   */
  public updateUserByUserDescriptor(userAdminAccountRoles: UserAdminAccountRoles): Observable<boolean> {
    return this.httpService.put(Endpoint.ACCOUNTS_USER_DESCRIPTORS, userAdminAccountRoles).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateUsers
   * @param {Account[]} accounts
   * @returns {Observable<boolean>}
   * @memberof AccountService
   */
  public updateUsers(accounts: Account[]): Observable<boolean> {
    const userDescriptors = accounts.map((account) => {
      return new UserDescriptor({
        id: account.id,
      });
    });
    const userDescriptorsRoles = new UserDescriptorsRoles({
      userDescriptors,
      roleIds: accounts[0].roleIds,
    });
    const updateAccounts = this.httpService.put(Endpoint.IAM_ACCOUNT, userDescriptorsRoles).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
    const accountIdentifiers = accounts.map((account) => account.id);
    const accountStatus = this.bulkUpdateAccountStatus(accountIdentifiers, accounts[0].active);
    return forkJoin([updateAccounts, accountStatus]).pipe(map(() => true));
  }

  /**
   * getAccountByExternalId
   * @param {string} azureOnPremImmutableId
   * @returns {Observable<Account>}
   * @memberof AccountService
   */
  public getAccountByExternalId(azureOnPremImmutableId: string): Observable<Account> {
    return this.httpService.get(Endpoint.IAM_ACCOUNT_EXTERNAL_ID(azureOnPremImmutableId)).pipe(
      map((response: GenericObject) => deserialize(Account, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAccountsById
   * retrieves a list of accounts for the given list of account identifiers
   * used in retrieving shared account details in the share dashboard modal
   * @param {IdentifiersRequest} accountIdentifiersRequest
   * @returns {Observable<Account[]>}
   * @memberof AccountService
   */
  public getAccountsById(accountIdentifiersRequest: IdentifiersRequest): Observable<Account[]> {
    return this.httpService.post(Endpoint.ACCOUNT, accountIdentifiersRequest).pipe(
      map((response: GenericObject) => response.data.map((account: Account) => deserialize(Account, account))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAccountsByUserDescriptors
   * @param {UserDescriptor[]} userDescriptors
   * @returns {Observable<AccountByUserDescriptor>}
   * @memberof AccountService
   */
  public getAccountsByUserDescriptors(userDescriptors: UserDescriptor[]): Observable<AccountByUserDescriptor> {
    return this.httpService.post(Endpoint.ACCOUNTS_SEARCH_BY_USER_DESCRIPTORS, userDescriptors).pipe(
      map((response: GenericObject) => {
        const accountByUserDescriptor = {};
        each(response.data, (account, userDescriptor) => {
          accountByUserDescriptor[userDescriptor] = deserialize(Account, account);
        });
        return accountByUserDescriptor;
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAccountAssignmentsByIds
   * @param {IdentifiersRequest} accountIdentifiersRequest
   * @returns {Observable<Account[]>}
   * @memberof AccountService
   */
  public getAccountAssignmentsByIds(accountIdentifiersRequest: IdentifiersRequest): Observable<Account[]> {
    return this.httpService.post(Endpoint.ACCOUNT_ASSIGNMENTS, accountIdentifiersRequest).pipe(
      map((response: GenericObject) => response.data.map((account: Account) => deserialize(Account, account))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateAccountStatus
   * @param {string} id
   * @param {boolean} status
   * @returns {Observable<boolean>}
   * @memberof AccountService
   */
  public updateAccountStatus(id: string, status: boolean): Observable<boolean> {
    return this.httpService.put(Endpoint.ACCOUNT_ID_STATUS(id, status), {}).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * bulkUpdateAccountStatus
   * @param {string[]} identifiers
   * @param {boolean} status
   * @returns {Observable<boolean>}
   * @memberof AccountService
   */
  public bulkUpdateAccountStatus(identifiers: string[], status: boolean): Observable<boolean> {
    return this.httpService.put(Endpoint.ACCOUNT_STATUS(status), { identifiers }).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * InsightImpactTablesTab
 * @export
 * @enum {string}
 */
export enum InsightImpactTablesTab {
  DEVICES = 'DEVICES',
  IMPACT = 'IMPACT',
}

/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { FeatureControl } from '@ws1c/intelligence-common';
import { Feature, FeatureControls } from '@ws1c/intelligence-models';

export const RBAC_HELPERS = {
  hasScope,
  hasAnyScope,
  hasAllScope,
  hasScopeOrParentScope,
};

/**
 * hasScope
 * @export
 * @param {FeatureControls} featureControls
 * @param {string[]} scopes
 * @param {Feature} feature
 * @param {boolean} [tolerateUndefinedScope=false] When set tolerateUndefinedScope to false, if scope is empty, it would return false.
 * @returns {boolean}
 */
export function hasScope(featureControls: FeatureControls, scopes: string[], feature: Feature, tolerateUndefinedScope = false): boolean {
  // returns false if feature flag is off
  if (feature.flag && !featureControls?.[feature.flag]) {
    return false;
  }
  // if RBAC is not enabled, return true
  if (featureControls?.[FeatureControl.RBAC_ENABLED]) {
    const scopeUndefinedCheck: boolean = tolerateUndefinedScope && !feature.scope;
    return scopeUndefinedCheck || RBAC_HELPERS.hasScopeOrParentScope(scopes, feature.scope);
  }
  return true;
}

/**
 * hasAnyScope
 * @export
 * @param {*} featureControls
 * @param {string[]} scopes
 * @param {Feature[]} features
 * @param {boolean} [tolerateUndefinedScope=false]
 * @returns {boolean}
 */
export function hasAnyScope(featureControls, scopes: string[], features: Feature[], tolerateUndefinedScope = false): boolean {
  return features.some((feature: Feature) => RBAC_HELPERS.hasScope(featureControls, scopes, feature, tolerateUndefinedScope));
}

/**
 * hasAllScope
 * @export
 * @param {*} featureControls
 * @param {string[]} scopes
 * @param {Feature[]} features
 * @returns {boolean}
 */
export function hasAllScope(featureControls, scopes: string[], features: Feature[]): boolean {
  return features.every((feature: Feature) => RBAC_HELPERS.hasScope(featureControls, scopes, feature));
}

/**
 * hasScopeOrParentScope
 * @export
 * @param {scopeList[]} scopeList
 * @param {string} scope
 * @returns {boolean}
 */
export function hasScopeOrParentScope(scopeList: string[], scope: string): boolean {
  if (!scope) {
    return false;
  }
  return scopeList.includes(scope) || RBAC_HELPERS.hasScopeOrParentScope(scopeList, scope.substring(0, scope.lastIndexOf('.')));
}

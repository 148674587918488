/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * ZoomNetworkTab
 * @export
 * @enum {string}
 */
export enum ZoomNetworkTab {
  BITRATE = 'BITRATE',
  JITTER = 'JITTER',
  LATENCY = 'LATENCY',
  AVG_PACKET_LOSS = 'AVG_PACKET_LOSS',
  MAX_PACKET_LOSS = 'MAX_PACKET_LOSS',
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, LabelValue } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppConfig } from '@ws1c/intelligence-common';
import { KeySelectorView } from '@ws1c/intelligence-core/const';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { FeatureSelectors } from '@ws1c/intelligence-core/store/rbac/feature.selectors';
import { HomePageSectionType, UIPreference } from '@ws1c/intelligence-models';
import { UserPreferenceCommonSelectors } from './user-preference-common.selectors';
import { UserPreferenceFeatureControlsSelectors } from './user-preference-feature-controls.selectors';
import { UserPreferenceState } from './user-preference.state';

/**
 * UserPreferenceUIPreferenceSelectors
 *
 * @export
 * @class UserPreferenceUIPreferenceSelectors
 */
export class UserPreferenceUIPreferenceSelectors {
  /**
   * uiSettingsPreferences
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static uiSettingsPreferences: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.uiSettings?.preferences ?? {},
  );

  /**
   * getDashboardViewSortsDetails
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject[]>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getDashboardViewSortsDetails: MemoizedSelector<CoreAppState, GenericObject[]> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => preferences?.[UIPreference.DASHBOARD_VIEW_SORT],
  );

  /**
   * getWorkflowViewSortsDetails
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject[]>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getWorkflowViewSortsDetails: MemoizedSelector<CoreAppState, GenericObject[]> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => preferences?.[UIPreference.WORKFLOW_VIEW_SORT],
  );

  /**
   * getReportViewSortsDetails
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject[]>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getReportViewSortsDetails: MemoizedSelector<CoreAppState, GenericObject[]> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => preferences?.[UIPreference.REPORT_VIEW_SORT],
  );

  /**
   * isReportIntroPageVisited
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isReportIntroPageVisited: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => !!(preferences && preferences[UIPreference.IS_REPORT_INTRO_PAGE_VISITED]),
  );

  /**
   * isAutomationIntroPageVisited
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isAutomationIntroPageVisited: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => !!(preferences && preferences[UIPreference.IS_AUTOMATION_INTRO_PAGE_VISITED]),
  );

  /**
   * isDashboardIntroPageVisited
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isDashboardIntroPageVisited: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => !!(preferences && preferences[UIPreference.IS_DASHBOARD_INTRO_PAGE_VISITED]),
  );

  /**
   * isDeemPlaybooksIntroVisited
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isDeemPlaybooksIntroVisited: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => !!preferences?.[UIPreference.IS_DEEM_PLAYBOOKS_INTRO_PAGE_VISITED],
  );

  /**
   * isCookiePolicyBannerVisited
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isCookiePolicyBannerVisited: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => !!(preferences && preferences[UIPreference.IS_UI_TELEMETRY_BANNER_VISITED]),
  );

  /**
   * isCookiePolicyBannerClosed
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isCookiePolicyBannerClosed: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => !!(preferences && preferences[UIPreference.IS_UI_TELEMETRY_BANNER_CLOSED]),
  );

  /**
   * showCookiePolicyBanner
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static showCookiePolicyBanner: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isPendoTelemetryEnabled,
    UserPreferenceUIPreferenceSelectors.isCookiePolicyBannerVisited,
    UserPreferenceUIPreferenceSelectors.isCookiePolicyBannerClosed,
    (isPendoTelemetryEnabled: boolean, isCookiePolicyBannerVisited: boolean, isCookiePolicyBannerClosed: boolean) => {
      // show cookie-policy-banner when telemetry is enabled and cookie-policy-banner is not visited
      return isPendoTelemetryEnabled && !isCookiePolicyBannerVisited && !isCookiePolicyBannerClosed;
    },
  );

  /**
   * getUiSettingHomePageSectionOrder
   * @static
   * @type {MemoizedSelector<CoreAppState, HomePageSectionType[]>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getUiSettingHomePageSectionOrder: MemoizedSelector<CoreAppState, HomePageSectionType[]> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (uiPreferences: GenericObject) => {
      const homePageSectionPreference = uiPreferences?.[UIPreference.HOME_PAGE_SECTION_ORDER];
      const isUiSettingsHomePageSectionTypeCorrect =
        homePageSectionPreference?.every((property: HomePageSectionType) => property in HomePageSectionType) ?? false;
      const defaultPreference = AppConfig.HOME_PAGE_SECTION_PREFERENCE_V1;
      return isUiSettingsHomePageSectionTypeCorrect ? homePageSectionPreference : defaultPreference;
    },
  );

  /**
   * getUiSettingHomePageSectionOrderV2
   * @static
   * @type {MemoizedSelector<CoreAppState, HomePageSectionType[]>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getUiSettingHomePageSectionOrderV2: MemoizedSelector<CoreAppState, HomePageSectionType[]> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (uiPreferences: GenericObject) => {
      const defaultPreference = AppConfig.HOME_PAGE_SECTION_PREFERENCE_V2;
      const homePageSectionPreference = uiPreferences?.[UIPreference.HOME_PAGE_SECTION_ORDER];
      //  when section order preference is not set, return new section config that has active trial section
      if (!homePageSectionPreference) {
        return defaultPreference;
      }

      // when section preference exists w/o new trial section, add trial after services section in existing section order preference
      const trialsSectionIndex = homePageSectionPreference.indexOf(HomePageSectionType.TRIALS);
      if (trialsSectionIndex === -1) {
        const servicesSectionIndex = homePageSectionPreference.indexOf(HomePageSectionType.SERVICES);
        homePageSectionPreference.splice(servicesSectionIndex + 1, 0, HomePageSectionType.TRIALS);
      }

      // otherwise, return section order preference set by user
      const isUiSettingsHomePageSectionTypeCorrect = homePageSectionPreference.every(
        (property: HomePageSectionType) => property in HomePageSectionType,
      );
      return isUiSettingsHomePageSectionTypeCorrect ? homePageSectionPreference : defaultPreference;
    },
  );

  /**
   * getHiddenHomePageSections
   * @static
   * @type {MemoizedSelector<CoreAppState, HomePageSectionType[]>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getHiddenHomePageSections: MemoizedSelector<CoreAppState, HomePageSectionType[]> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (uiPreferences: GenericObject) => uiPreferences?.[UIPreference.HOME_PAGE_SECTION_HIDDEN] ?? [],
  );

  /**
   * isReportRetentionIntroModalBeShown
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isReportRetentionIntroModalBeShown: MemoizedSelector<CoreAppState, boolean> = createSelector(
    FeatureSelectors.hasReportRetentionPerm,
    UserPreferenceUIPreferenceSelectors.isReportIntroPageVisited,
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (hasReportRetentionPerm: boolean, isReportIntroPageVisited: boolean, uiPreferences: GenericObject) => {
      return (
        hasReportRetentionPerm &&
        isReportIntroPageVisited &&
        !(uiPreferences && uiPreferences[UIPreference.IS_REPORT_RETENTION_INTRO_MODAL_VISITED])
      );
    },
  );

  /**
   * getKeySelectorView
   * @static
   * @type {MemoizedSelector<CoreAppState, KeySelectorView>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getKeySelectorView: MemoizedSelector<CoreAppState, KeySelectorView> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (uiPreferences: GenericObject) => {
      return uiPreferences[UIPreference.LAST_SELECTED_KEY_SELECTOR_VIEW] || KeySelectorView.COLUMN;
    },
  );

  /**
   * isNotificationBellTrayAlertDismissed
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isNotificationBellTrayAlertDismissed: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => !!(preferences && preferences[UIPreference.IS_NOTIFICATION_BELL_TRAY_ALERT_DISMISSED]),
  );

  /**
   * getHubNotificationsListFilter
   * @static
   * @type {MemoizedSelector<CoreAppState, LabelValue>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getHubNotificationsListFilter: MemoizedSelector<CoreAppState, LabelValue> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => preferences?.[UIPreference.HUB_NOTIFICATIONS_LIST_FILTER],
  );

  /**
   * getHubNotificationsListSort
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getHubNotificationsListSort: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => preferences?.[UIPreference.HUB_NOTIFICATIONS_LIST_SORT],
  );

  /**
   * isApteligentBannerHidden
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isApteligentBannerHidden: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => preferences?.[UIPreference.HIDE_APTELIGENT_BANNER],
  );

  /**
   * getDeemSurveysListSort
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static getDeemSurveysListSort: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => preferences?.[UIPreference.DEEM_SURVEYS_LIST_SORT],
  );

  /**
   * isUemV2DashboardToggleEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUIPreferenceSelectors
   */
  public static isUemV2DashboardToggleEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (preferences: GenericObject) => !!preferences?.[UIPreference.UI_UEM_V2_DASHBOARD_TOGGLE_ENABLED],
  );
}

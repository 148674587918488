/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, TrendSpan } from '@dpa/ui-common';

import { DashboardActions } from '@ws1c/intelligence-core/store/dashboard/dashboard.actions';
import {
  ChronoUnit,
  COLUMN_NAMES,
  Insight,
  InsightsConfig,
  InsightType,
  NestedTrendConfig,
  NgxTrendResultFlattener,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
  TrendResult,
} from '@ws1c/intelligence-models';
import { InsightGroupBy } from './insight-group-by.enum';
import { InsightImpactProperties } from './insight-impact-properties.interface';

/**
 * InsightImpactConfig
 * @export
 * @class InsightImpactConfig
 */
export class InsightImpactConfig {
  public static readonly insightImpactPropertiesMap: Record<InsightType, InsightImpactProperties> = {
    [InsightType.APP_CRASHES]: {
      showImpactTable: false,
      impactTabTitle: null,
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.APP_VERSION,
      impactTableVisibleWidgetSubtype: null,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_WITH_APP',
    },
    [InsightType.APP_HANGS]: {
      showImpactTable: false,
      impactTabTitle: null,
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.APP_VERSION,
      impactTableVisibleWidgetSubtype: null,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_WITH_APP',
    },
    [InsightType.APP_INSTALL_FAILED]: {
      showImpactTable: false,
      impactTabTitle: null,
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.APP_VERSION,
      impactTableVisibleWidgetSubtype: null,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_WITH_APP',
    },
    [InsightType.APP_INSTALL_PENDING]: {
      showImpactTable: false,
      impactTabTitle: null,
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.APP_VERSION,
      impactTableVisibleWidgetSubtype: null,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_WITH_APP',
    },
    [InsightType.APP_USAGE]: {
      showImpactTable: false,
      impactTabTitle: null,
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.APP_VERSION,
      impactTableVisibleWidgetSubtype: null,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_WITH_APP',
    },
    [InsightType.APTELIGENT_CRASH_ANDROID]: {
      showImpactTable: false,
      impactTabTitle: null,
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.APP_VERSION,
      impactTableVisibleWidgetSubtype: null,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_WITH_APP',
    },
    [InsightType.APTELIGENT_CRASH_IOS]: {
      showImpactTable: false,
      impactTabTitle: null,
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.APP_VERSION,
      impactTableVisibleWidgetSubtype: null,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_WITH_APP',
    },
    [InsightType.APTELIGENT_NET_ERROR]: {
      showImpactTable: false,
      impactTabTitle: null,
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.APP_VERSION,
      impactTableVisibleWidgetSubtype: null,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_WITH_APP',
    },
    [InsightType.BOOT_DURATION]: {
      showImpactTable: true,
      impactTabTitle: 'INSIGHT_IMPACT.IMPACT_BY_OS_VERSION',
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.OS_VERSION,
      impactTableVisibleWidgetSubtype: StandardWidgetSubtype.__INSIGHT_IMPACT_DURATION,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_ON_IMPACTED_VERSIONS',
    },
    [InsightType.SHUTDOWN_DURATION]: {
      showImpactTable: true,
      impactTabTitle: 'INSIGHT_IMPACT.IMPACT_BY_OS_VERSION',
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.OS_VERSION,
      impactTableVisibleWidgetSubtype: StandardWidgetSubtype.__INSIGHT_IMPACT_DURATION,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_ON_IMPACTED_VERSIONS',
    },
    [InsightType.OS_CRASHES]: {
      showImpactTable: true,
      impactTabTitle: 'INSIGHT_IMPACT.IMPACT_BY_OS_VERSION',
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.OS_VERSION,
      impactTableVisibleWidgetSubtype: StandardWidgetSubtype.__INSIGHT_IMPACT_OS_CRASHES,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_ON_IMPACTED_VERSIONS',
    },
    [InsightType.CPU_UTILIZATION]: {
      showImpactTable: true,
      impactTabTitle: 'INSIGHT_IMPACT.IMPACT_BY_OS_VERSION',
      deviceTabLabel: 'INSIGHTS_COMMON.DEVICES',
      groupBy: InsightGroupBy.OS_VERSION,
      impactTableVisibleWidgetSubtype: StandardWidgetSubtype.__INSIGHT_IMPACT_CPU,
      impactedDevicesLabel: 'INSIGHTS_MESSAGES.IMPACTED_DEVICES',
      totalDevicesLabel: 'INSIGHTS_MESSAGES.DEVICES_ON_IMPACTED_VERSIONS',
    },
    [InsightType.FAILED_SSO_LOGIN]: {
      showImpactTable: false,
      impactTabTitle: null,
      deviceTabLabel: 'INSIGHT_IMPACT.IMPACT_BY_AUTH_METHOD',
      groupBy: InsightGroupBy.OS_VERSION,
      impactTableVisibleWidgetSubtype: StandardWidgetSubtype.__INSIGHT_IMPACT_FAILED_SSO_LOGIN,
      impactedDevicesLabel: 'INSIGHT_DEVICES_IMPACTED.FAILED_LOGINS',
      totalDevicesLabel: 'INSIGHT_DEVICES_IMPACTED.TOTAL_LOGINS',
    },
    [InsightType.HORIZON_SESSION_LOGON_DURATION]: {
      showImpactTable: true,
      impactTabTitle: 'INSIGHT_IMPACT.IMPACT_BY_HORIZON_SESSION',
      deviceTabLabel: 'INSIGHTS_MESSAGES.SESSIONS',
      groupBy: InsightGroupBy.OS_VERSION,
      impactTableVisibleWidgetSubtype: StandardWidgetSubtype.__INSIGHT_IMPACT_HORIZON_SESSION,
      impactedDevicesLabel: 'INSIGHT_DEVICES_IMPACTED.IMPACTED_SESSIONS',
      totalDevicesLabel: 'INSIGHT_DEVICES_IMPACTED.TOTAL_SESSIONS',
    },
  };

  /**
   * getTrendDateRange
   * @static
   * @param {Insight} insight
   * @returns {TrendDateRange}
   * @memberOf InsightImpactConfig
   */
  public static getTrendDateRange(insight: Insight) {
    // when job runs at 8:15pm, it runs on data from 7pm-8pm but lastTriggeredAt is set to 8:15pm
    return new TrendDateRange({
      endDateMillis: insight.lastTriggeredAt - TrendSpan.timeUnitsByName[ChronoUnit[ChronoUnit.MINUTES]].value * 15,
      startDateMillis: insight.lastTriggeredAt - TrendSpan.timeUnitsByName[ChronoUnit[ChronoUnit.MINUTES]].value * 75,
    });
  }

  /**
   * getImpactDashboardName
   * @static
   * @param {InsightType} insightType
   * @returns {string}
   * @memberOf InsightImpactConfig
   */
  public static getImpactDashboardName(insightType: InsightType): string {
    switch (insightType) {
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
        return StandardDashboardType.INSIGHT_IMPACT_DURATION;
      case InsightType.OS_CRASHES:
        return StandardDashboardType.INSIGHT_IMPACT_OS_CRASHES;
      case InsightType.CPU_UTILIZATION:
        return StandardDashboardType.INSIGHT_IMPACT_CPU;
      case InsightType.HORIZON_SESSION_LOGON_DURATION:
        return StandardDashboardType.INSIGHT_IMPACT_HORIZON_SESSION;
    }
  }

  /**
   * InsightImpactConfig
   * @static
   * @param {Insight} insight
   * @returns {GenericObject}
   * @memberOf InsightImpactConfig
   */
  public static getAdditionalParams(insight: Insight): GenericObject {
    switch (insight.insightType) {
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
      case InsightType.OS_CRASHES:
        return {
          [COLUMN_NAMES.byName._device_platform]: insight.platform,
          [COLUMN_NAMES.byName.duration_millis]: insight.observationValue.value,
          [COLUMN_NAMES.byName.event_name]: InsightsConfig.insightsPropertiesMap[insight.insightType].eventName,
        };
      case InsightType.CPU_UTILIZATION:
        return {
          [COLUMN_NAMES.byName._device_platform]: insight.platform,
          [COLUMN_NAMES.byName.processor_total_processor_totaltime]: insight.observationValue.value,
          [COLUMN_NAMES.byName.event_name]: InsightsConfig.insightsPropertiesMap[insight.insightType].eventName,
        };
      case InsightType.HORIZON_SESSION_LOGON_DURATION:
        return {
          [COLUMN_NAMES.byName.logon_time_in_second]: insight.observationValue.value,
        };
    }
  }

  /**
   * getNestedTrendConfig
   * @static
   * @param {Insight} insight
   * @returns {NestedTrendConfig}
   * @memberOf InsightImpactConfig
   */
  public static getNestedTrendConfig(insight: Insight): NestedTrendConfig {
    const trendDateRange = InsightImpactConfig.getTrendDateRange(insight);
    switch (insight.insightType) {
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
        return {
          widgetSubtype: StandardWidgetSubtype.INSIGHT_IMPACT_DURATION_BY_OS_VERSION,
          getNextAction: (trend: Trend) => {
            const osVersions = trend?.trendResults?.map((trendResult: TrendResult) => {
              return trendResult.bucketingAttributes[0].value;
            });
            if (!osVersions?.length) {
              return;
            }
            return DashboardActions.loadStandardDashboard({
              request: new StandardDashboardRequest(
                StandardDashboardType[`${InsightImpactConfig.getImpactDashboardName(insight.insightType)}_TOTAL`],
                new RuleGroup(),
                trendDateRange,
                {
                  [COLUMN_NAMES.byName._device_platform]: insight.platform,
                  // prettier-ignore
                  [`${COLUMN_NAMES.byName.os_version}s`]: `'${osVersions.join('\',\'')}'`,
                  [COLUMN_NAMES.byName.event_name]: InsightsConfig.insightsPropertiesMap[insight.insightType].eventName,
                },
              ),
            });
          },
        };
      case InsightType.CPU_UTILIZATION:
        return {
          widgetSubtype: StandardWidgetSubtype.INSIGHT_IMPACT_CPU_BY_OS_VERSION,
          getNextAction: (trend: Trend) => {
            const osVersions = trend?.trendResults?.map((trendResult: TrendResult) => {
              return trendResult.bucketingAttributes[0].value;
            });
            if (!osVersions?.length) {
              return;
            }
            return DashboardActions.loadStandardDashboard({
              request: new StandardDashboardRequest(
                StandardDashboardType[`${InsightImpactConfig.getImpactDashboardName(insight.insightType)}_TOTAL`],
                new RuleGroup(),
                trendDateRange,
                {
                  [COLUMN_NAMES.byName._device_platform]: insight.platform,
                  // prettier-ignore
                  [`${COLUMN_NAMES.byName.os_version}s`]: `'${osVersions.join('\',\'')}'`,
                },
              ),
            });
          },
        };
      case InsightType.OS_CRASHES:
        return {
          widgetSubtype: StandardWidgetSubtype.INSIGHT_IMPACT_OS_CRASHES_BY_OS_VERSION,
          getNextAction: (trend: Trend) => {
            const osVersions = trend?.trendResults?.map((trendResult: TrendResult) => {
              return trendResult.bucketingAttributes[0].value;
            });
            if (!osVersions?.length) {
              return;
            }
            return DashboardActions.loadStandardDashboard({
              request: new StandardDashboardRequest(
                StandardDashboardType[`${InsightImpactConfig.getImpactDashboardName(insight.insightType)}_TOTAL`],
                new RuleGroup(),
                trendDateRange,
                {
                  [COLUMN_NAMES.byName._device_platform]: insight.platform,
                  // prettier-ignore
                  [`${COLUMN_NAMES.byName.os_version}s`]: `'${osVersions.join('\',\'')}'`,
                },
              ),
            });
          },
        };
      case InsightType.HORIZON_SESSION_LOGON_DURATION:
        return {
          widgetSubtype: StandardWidgetSubtype.INSIGHT_IMPACT_HORIZON_SESSION_BY_POOL_ID,
          getNextAction: (trend: Trend) => {
            const poolIds = trend?.trendResults?.map((trendResult: TrendResult) => {
              return trendResult.bucketingAttributes[1].value;
            });
            if (!poolIds?.length) {
              return;
            }
            return DashboardActions.loadStandardDashboard({
              request: new StandardDashboardRequest(
                StandardDashboardType[`${InsightImpactConfig.getImpactDashboardName(insight.insightType)}_TOTAL`],
                new RuleGroup(),
                trendDateRange,
                {
                  // prettier-ignore
                  [`${COLUMN_NAMES.byName.horizon_pool_id}s`]: `'${poolIds.join('\',\'')}'`,
                },
              ),
            });
          },
        };
    }
  }

  /**
   * getVisibleColumns
   * @static
   * @param {InsightType} insightType
   * @returns {string[]}
   * @memberOf InsightImpactConfig
   */
  public static getVisibleColumns(insightType: InsightType) {
    switch (insightType) {
      case InsightType.APP_CRASHES:
      case InsightType.APP_HANGS:
      case InsightType.APP_USAGE:
        return [
          NgxTrendResultFlattener.getCounterKey(0),
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform,
          NgxTrendResultFlattener.getCounterKey(2),
          NgxTrendResultFlattener.getCounterKey(1),
        ];
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
        return [
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_device_platform,
          NgxTrendResultFlattener.getCounterKey(1),
          NgxTrendResultFlattener.getCounterKey(0),
        ];
      case InsightType.OS_CRASHES:
        return [
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version,
          NgxTrendResultFlattener.getCounterKey(0),
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_device_platform,
          NgxTrendResultFlattener.getCounterKey(2),
          NgxTrendResultFlattener.getCounterKey(1),
        ];
      case InsightType.CPU_UTILIZATION:
        return [
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_os_version,
          COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_device_platform,
          NgxTrendResultFlattener.getCounterKey(1),
          NgxTrendResultFlattener.getCounterKey(0),
        ];
      case InsightType.HORIZON_SESSION_LOGON_DURATION:
        return [
          COLUMN_NAMES.byFullyQualifiedName.horizon_session_historical_pool,
          NgxTrendResultFlattener.getCounterKey(1),
          NgxTrendResultFlattener.getCounterKey(0),
        ];
    }
  }

  /**
   * getColumnLabels
   * @static
   * @param {InsightType} insightType
   * @returns {Record<string, string>}
   * @memberOf InsightImpactConfig
   */
  public static getColumnLabels(insightType: InsightType): Record<string, string> {
    switch (insightType) {
      case InsightType.APP_CRASHES:
      case InsightType.APP_HANGS:
        return {
          [NgxTrendResultFlattener.getCounterKey(0)]: `INSIGHT_DASHBOARD.${insightType}.TITLE`,
          [NgxTrendResultFlattener.getCounterKey(2)]: 'INSIGHT_IMPACT.DEVICES_WITH_APP',
          [NgxTrendResultFlattener.getCounterKey(1)]: 'INSIGHT_IMPACT.DEVICES_IMPACTED',
        };
      case InsightType.BOOT_DURATION:
      case InsightType.SHUTDOWN_DURATION:
        return {
          [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version]: 'INSIGHT_IMPACT.OS_VERSION',
          [NgxTrendResultFlattener.getCounterKey(1)]: 'INSIGHT_IMPACT.DEVICES_ON_OS_VERSION',
          [NgxTrendResultFlattener.getCounterKey(0)]: 'INSIGHT_IMPACT.DEVICES_IMPACTED',
        };
      case InsightType.OS_CRASHES:
        return {
          [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_os_version]: 'INSIGHT_IMPACT.OS_VERSION',
          [NgxTrendResultFlattener.getCounterKey(0)]: `INSIGHT_DASHBOARD.${insightType}.TITLE`,
          [NgxTrendResultFlattener.getCounterKey(2)]: 'INSIGHT_IMPACT.DEVICES_ON_OS_VERSION',
          [NgxTrendResultFlattener.getCounterKey(1)]: 'INSIGHT_IMPACT.DEVICES_IMPACTED',
        };
      case InsightType.CPU_UTILIZATION:
        return {
          [COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_os_version]: 'INSIGHT_IMPACT.OS_VERSION',
          [NgxTrendResultFlattener.getCounterKey(1)]: 'INSIGHT_IMPACT.DEVICES_ON_OS_VERSION',
          [NgxTrendResultFlattener.getCounterKey(0)]: 'INSIGHT_IMPACT.DEVICES_IMPACTED',
        };
      case InsightType.APP_USAGE:
        return {
          [COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_app_version]: 'INSIGHT_IMPACT.APP_VERSION',
          [NgxTrendResultFlattener.getCounterKey(0)]: `INSIGHT_IMPACT.APP_FOREGROUND_EVENTS`,
          [NgxTrendResultFlattener.getCounterKey(2)]: 'INSIGHT_IMPACT.DEVICES_WITH_APP',
          [NgxTrendResultFlattener.getCounterKey(1)]: 'INSIGHT_IMPACT.DEVICES_IMPACTED',
        };
      case InsightType.HORIZON_SESSION_LOGON_DURATION:
        return {
          [COLUMN_NAMES.byFullyQualifiedName.horizon_session_historical_pool]: 'INSIGHT_IMPACT.POOL',
          [NgxTrendResultFlattener.getCounterKey(1)]: 'INSIGHT_DEVICES_IMPACTED.TOTAL_SESSIONS',
          [NgxTrendResultFlattener.getCounterKey(0)]: 'INSIGHT_IMPACT.SESSIONS_IMPACTED',
        };
      default:
        return {};
    }
  }
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DrilldownTrail } from '@ws1c/intelligence-models';

/**
 * FlexibleDrilldownNavigatorComponent
 *
 * @export
 * @class FlexibleDrilldownNavigatorComponent
 */
@Component({
  selector: 'dpa-flexible-drilldown-navigator',
  templateUrl: 'flexible-drilldown-navigator.component.html',
  styleUrls: ['flexible-drilldown-navigator.component.scss'],
})
export class FlexibleDrilldownNavigatorComponent {
  @Input() public drilldownList: DrilldownTrail[];
  @Output() public onNavigateTo = new EventEmitter<DrilldownTrail>();

  /**
   * navigateTo
   * @param {DrilldownTrail} drilldownTrail
   * @memberof FlexibleDrilldownNavigatorComponent
   */
  public navigateTo(drilldownTrail: DrilldownTrail) {
    this.onNavigateTo.emit(drilldownTrail);
  }
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { keyBy } from 'lodash-es';

import { AutomationCommonSelectors } from '@ws1c/intelligence-core/store/automation-common/automation-common.selectors';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import {
  Automation,
  AutomationAction,
  AutomationRunHistoryResponse,
  AutomationTestConnectionResult,
  Connector,
  ConnectorAction,
  ConnectorConfig,
  ConnectorModalType,
  ConnectorSchema,
  ConnectorsSearchResponse,
  GenericSearchRequest,
  LOAD_STATE,
  ManagedConnector,
  ManagedConnectorsSearchResponse,
  WS1_CONNECTOR_NAMES,
} from '@ws1c/intelligence-models';
import { getConnectorsSearchRequest, mergeConnectorsByName } from './connector-common-selector-helpers';
import { ConnectorCommonState } from './connector-common.state';

/**
 * ConnectorCommonSelectors
 * @export
 * @class ConnectorCommonSelectors
 */
export class ConnectorCommonSelectors {
  public static selectConnectorCommonState = (state: CoreAppState) => state.connectorCommonState;

  /**
   * getConnectorsSearchRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericSearchRequest>}
   * @memberof ConnectionCommonSelectors
   */
  public static getConnectorsSearchRequest: MemoizedSelector<CoreAppState, GenericSearchRequest> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    getConnectorsSearchRequest,
  );

  /**
   * getConnectors
   * @static
   * @type {MemoizedSelector<CoreAppState, Connector[]>}
   * @memberof ConnectorCommonSelectors
   */
  public static getConnectors: MemoizedSelector<CoreAppState, Connector[]> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.connectorsSearchResponse?.results,
  );

  /**
   * isConnectorsLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ConnectorCommonSelectors
   */
  public static isConnectorsLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.connectorsSearchLoadStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * getConnectorsSearchResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorsSearchResponse>}
   * @memberof ConnectorCommonSelectors
   */
  public static getConnectorsSearchResponse: MemoizedSelector<CoreAppState, ConnectorsSearchResponse> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.connectorsSearchResponse,
  );

  /**
   * getModalType
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorModalType>}
   * @memberof ConnectorCommonSelectors
   */
  public static getModalType: MemoizedSelector<CoreAppState, ConnectorModalType> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.modal.type,
  );

  /**
   * getModalConnector
   * @static
   * @type {MemoizedSelector<CoreAppState, Connector>}
   * @memberof ConnectorCommonSelectors
   */
  public static getModalConnector: MemoizedSelector<CoreAppState, Connector> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.modal.connector,
  );

  /**
   * isModalConfirmLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ConnectorCommonSelectors
   */
  public static isModalConfirmLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.modal.confirmLoadStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * getConnectorConfig
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorConfig>}
   * @memberof ConnectorCommonSelectors
   */
  public static getConnectorConfig: MemoizedSelector<CoreAppState, ConnectorConfig> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.modal.connectorConfig,
  );

  /**
   * getConnectorConfigSchema
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorSchema>}
   * @memberof ConnectorCommonState
   */
  public static getConnectorConfigSchema: MemoizedSelector<CoreAppState, ConnectorSchema> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.modal.connectorConfigSchema,
  );

  /**
   * getModalActions
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorAction[]>}
   * @memberof ConnectorCommonSelectors
   */
  public static getModalActions: MemoizedSelector<CoreAppState, ConnectorAction[]> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.modal.actions,
  );

  /**
   * getConnectorsUsageResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationRunHistoryResponse>}
   * @memberof ConnectorCommonSelectors
   */
  public static getConnectorsUsageResponse: MemoizedSelector<CoreAppState, AutomationRunHistoryResponse> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.connectorsUsageResponse,
  );

  /**
   * getConnectorsUsageRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericSearchRequest>}
   * @memberof ConnectorCommonSelectors
   */
  public static getConnectorsUsageRequest: MemoizedSelector<CoreAppState, GenericSearchRequest> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.connectorsUsageRequest,
  );

  /**
   * isConnectorsUsageLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ConnectorCommonSelectors
   */
  public static isConnectorsUsageLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => {
      return state.connectorsUsageLoadStatus === LOAD_STATE.IN_FLIGHT || state.connectorsSearchLoadStatus === LOAD_STATE.IN_FLIGHT;
    },
  );

  /**
   * getConnectorModalState
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof ConnectorCommonSelectors
   */
  public static getConnectorModalState: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    ConnectorCommonSelectors.getModalType,
    ConnectorCommonSelectors.getModalConnector,
    ConnectorCommonSelectors.isModalConfirmLoading,
    ConnectorCommonSelectors.getConnectorConfigSchema,
    ConnectorCommonSelectors.getConnectorConfig,
    ConnectorCommonSelectors.getConnectorsUsageResponse,
    (
      type: ConnectorModalType,
      connector: Connector,
      isConfirmLoading: boolean,
      connectorConfigSchema: ConnectorSchema,
      connectorConfig: ConnectorConfig,
      connectorUsage: AutomationRunHistoryResponse,
    ) => ({
      type,
      connector,
      isConfirmLoading,
      connectorConfigSchema,
      connectorConfig,
      connectorUsageCount: connectorUsage?.total,
    }),
  );

  /**
   * getActionModalState
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof ConnectorCommonSelectors
   */
  public static getActionModalState: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    ConnectorCommonSelectors.getModalType,
    ConnectorCommonSelectors.getModalConnector,
    ConnectorCommonSelectors.getModalActions,
    ConnectorCommonSelectors.isModalConfirmLoading,
    AutomationCommonSelectors.isConnectionTestRunning,
    AutomationCommonSelectors.getAutomationTestResult,
    (
      type: ConnectorModalType,
      connector: Connector,
      actions: ConnectorAction[],
      isConfirmLoading: boolean,
      isModalActionTesting: boolean,
      actionTestResult: AutomationTestConnectionResult,
    ) => ({
      type,
      connector,
      actions,
      isConfirmLoading,
      isModalActionTesting,
      actionTestResult,
    }),
  );

  /**
   * isManagedConnectorsLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ConnectorCommonSelectors
   */
  public static isManagedConnectorsLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.managedConnectorsSearchLoadStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * getManagedConnectorsSearchResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, ManagedConnectorsSearchResponse>}
   * @memberof ConnectorCommonSelectors
   */
  public static getManagedConnectorsSearchResponse: MemoizedSelector<CoreAppState, ManagedConnectorsSearchResponse> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.managedConnectorsSearchResponse,
  );

  /**
   * getAllManagedConnectors
   * @static
   * @type {MemoizedSelector<CoreAppState, ManagedConnector[]>}
   * @memberof ConnectorCommonSelectors
   */
  public static getAllManagedConnectors: MemoizedSelector<CoreAppState, ManagedConnector[]> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.allManagedConnectorsResponse?.results,
  );

  /**
   * getAvailableConnectorIconsById
   *
   * @static
   * @param {boolean} isTemplate
   * @type {(boolean) => MemoizedSelector<CoreAppState, Record<string, string>>}
   * @memberof ConnectionCommonSelectors
   */
  public static getAvailableConnectorIconsById: (isTemplate: boolean) => MemoizedSelector<CoreAppState, Record<string, string>> = (
    isTemplate: boolean,
  ) =>
    createSelector(
      ConnectorCommonSelectors.getConnectors,
      ConnectorCommonSelectors.getAllManagedConnectors,
      (connectors: Connector[], managedConnectors: ManagedConnector[]) => {
        const availableConnectors = isTemplate ? managedConnectors : connectors;
        return availableConnectors?.reduce((accum, { id, iconLink }) => ({ ...accum, [id]: iconLink }), {});
      },
    );

  /**
   * getWS1Connectors
   * @static
   * @type {MemoizedSelector<CoreAppState, Connector[]>}
   * @memberof ConnectorCommonSelectors
   */
  public static getWS1Connectors: MemoizedSelector<CoreAppState, Connector[]> = createSelector(
    ConnectorCommonSelectors.getConnectors,
    ConnectorCommonSelectors.getManagedConnectorsSearchResponse,
    (connectors: Connector[], response: ManagedConnectorsSearchResponse) =>
      mergeConnectorsByName(response?.results, connectors, WS1_CONNECTOR_NAMES),
  );

  /**
   * getThirdPartyConnectors
   * @static
   * @type {MemoizedSelector<CoreAppState, Connector[]>}
   * @memberof ConnectorCommonSelectors
   */
  public static getThirdPartyConnectors: MemoizedSelector<CoreAppState, Connector[]> = createSelector(
    ConnectorCommonSelectors.getConnectors,
    ConnectorCommonSelectors.getManagedConnectorsSearchResponse,
    (connectors: Connector[], response: ManagedConnectorsSearchResponse) => {
      const managedConnectorNames = response?.results.map((connector: ManagedConnector) => connector.name) ?? [];
      const setUpConnectorNames =
        connectors?.filter((connector: Connector) => connector.managed).map((connector: Connector) => connector.name) ?? [];
      const thirdPartyConnectorNames = new Set([...managedConnectorNames, ...setUpConnectorNames]);
      WS1_CONNECTOR_NAMES.forEach((ws1ConnectorName: string) => {
        thirdPartyConnectorNames.delete(ws1ConnectorName);
      });
      return mergeConnectorsByName(response?.results, connectors, [...thirdPartyConnectorNames]);
    },
  );

  /**
   * loadingConnectorsWithActions
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ConnectorCommonSelectors
   */
  public static loadingConnectorsWithActions: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state.loadingConnectorsWithActions,
  );

  /**
   * isAutomationActionsLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ConnectorCommonSelectors
   */
  public static isAutomationActionsLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.loadingActions,
    ConnectorCommonSelectors.loadingConnectorsWithActions,
    (loadingActions: boolean, loadingConnectorsWithActions: boolean) => loadingActions || loadingConnectorsWithActions,
  );

  /**
   * geтConnectorsWithFilteredActions
   * @static
   * @type {MemoizedSelector<CoreAppState, Connector[]>}
   * @memberof ConnectorCommonSelectors
   */
  public static getConnectorsWithFilteredActions: MemoizedSelector<CoreAppState, Connector[]> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) =>
      state.connectorsWithActionsResponse?.data
        .filter((connector: Connector) => connector.actions.length)
        .map(
          (connector: Connector) =>
            new Connector({
              ...connector,
              actions: connector.actions.filter((action: ConnectorAction) => !action.metadata.isUiHidden),
            }),
        ),
  );

  /**
   * getConnectorsWithActions
   * @static
   * @type {MemoizedSelector<CoreAppState, Connector[]>}
   * @memberof ConnectorCommonSelectors
   */
  public static getConnectorsWithActions: MemoizedSelector<CoreAppState, Connector[]> = createSelector(
    ConnectorCommonSelectors.selectConnectorCommonState,
    (state: ConnectorCommonState) => state?.connectorsWithActionsResponse?.data,
  );

  /**
   * isAutomationUsingDeauthorizedConnector
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf ConnectorCommonSelectors
   */
  public static isAutomationUsingDeauthorizedConnector: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationWizardModel,
    ConnectorCommonSelectors.getConnectorsWithActions,
    (automation: Automation, connectors: Connector[]) => {
      return automation?.allActions.some((action: AutomationAction) => {
        return !connectors?.find((connector: Connector) => connector.configId === action?.actionData?.connectorConfigId)?.isAuthorized;
      });
    },
  );

  /**
   * getConnectorsByConfigId
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Connector>>}
   * @returns {Record<string, Connector>}
   * @memberOf ConnectorCommonSelectors
   */
  public static getConnectorsByConfigId: MemoizedSelector<CoreAppState, Record<string, Connector>> = createSelector(
    ConnectorCommonSelectors.getConnectorsWithActions,
    (connectors: Connector[]) => {
      return keyBy(connectors, 'configId');
    },
  );
}

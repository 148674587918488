/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * LocaleBundleId
 *
 * @export
 * @enum {string}
 */
export enum LocaleBundleId {
  ADMIN_MANAGEMENT = 'ADMIN_MANAGEMENT',
  CVE_SOLUTION = 'CVE_SOLUTION',
  DEEM_SOLUTION = 'DEEM_SOLUTION',
  INTELLIGENCE_CONNECTOR = 'INTELLIGENCE_CONNECTOR',
  INTELLIGENCE_ONBOARDING = 'INTELLIGENCE_ONBOARDING',
  INTELLIGENCE_REPORT = 'INTELLIGENCE_REPORT',
  INTELLIGENCE_WORKFLOW = 'INTELLIGENCE_WORKFLOW',
  NOTIFICATION = 'NOTIFICATION',
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { ConnectorCommonState, initialConnectorCommonState } from '@ws1c/intelligence-core/store/connector-common/connector-common.state';
import { GenericSearchRequest, LOAD_STATE } from '@ws1c/intelligence-models';
import { ConnectorCommonActions } from './connector-common.actions';

const _reducer: ActionReducer<ConnectorCommonState> = createReducer(
  initialConnectorCommonState,
  on(
    ConnectorCommonActions.loadConnectors,
    (state: ConnectorCommonState, { request }: ReturnType<typeof ConnectorCommonActions.loadConnectors>): ConnectorCommonState => ({
      ...state,
      connectorsSearchRequest: request ?? state.connectorsSearchRequest,
      connectorsSearchQuery: initialConnectorCommonState.connectorsSearchQuery,
      connectorsSearchLoadStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    ConnectorCommonActions.loadConnectorsWithSort,
    (state: ConnectorCommonState, { sortOns }: ReturnType<typeof ConnectorCommonActions.loadConnectorsWithSort>): ConnectorCommonState => ({
      ...state,
      connectorsSearchRequest: new GenericSearchRequest({
        ...state.connectorsSearchRequest,
        sortOns,
      }),
      connectorsSearchLoadStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    ConnectorCommonActions.loadConnectorsWithSearch,
    (
      state: ConnectorCommonState,
      { searchQuery }: ReturnType<typeof ConnectorCommonActions.loadConnectorsWithSearch>,
    ): ConnectorCommonState => {
      return {
        ...state,
        connectorsSearchQuery: searchQuery,
        connectorsSearchLoadStatus: LOAD_STATE.IN_FLIGHT,
      };
    },
  ),

  on(
    ConnectorCommonActions.loadConnectorsWithQuickFilters,
    (
      state: ConnectorCommonState,
      { status }: ReturnType<typeof ConnectorCommonActions.loadConnectorsWithQuickFilters>,
    ): ConnectorCommonState => {
      return {
        ...state,
        connectorsQuickFilter: status,
        connectorsSearchLoadStatus: LOAD_STATE.IN_FLIGHT,
      };
    },
  ),

  on(
    ConnectorCommonActions.changePagination,
    (state: ConnectorCommonState, { pagedRequest }: ReturnType<typeof ConnectorCommonActions.changePagination>): ConnectorCommonState => {
      return {
        ...state,
        connectorsSearchRequest: new GenericSearchRequest({
          ...pagedRequest,
        }),
        connectorsSearchLoadStatus: LOAD_STATE.IN_FLIGHT,
      };
    },
  ),

  on(
    ConnectorCommonActions.loadConnectorsSuccess,
    (state: ConnectorCommonState, { response }: ReturnType<typeof ConnectorCommonActions.loadConnectorsSuccess>): ConnectorCommonState => ({
      ...state,
      connectorsSearchResponse: response,
      connectorsSearchLoadStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    ConnectorCommonActions.loadConnectorsFailure,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      connectorsSearchLoadStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    ConnectorCommonActions.listConnectorsWithActions,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      loadingConnectorsWithActions: true,
    }),
  ),

  on(
    ConnectorCommonActions.listConnectorsWithActionsSuccess,
    (
      state: ConnectorCommonState,
      { connectorsWithActionsResponse }: ReturnType<typeof ConnectorCommonActions.listConnectorsWithActionsSuccess>,
    ): ConnectorCommonState => ({
      ...state,
      connectorsWithActionsResponse,
      loadingConnectorsWithActions: false,
    }),
  ),

  on(
    ConnectorCommonActions.listConnectorsWithActionsFailure,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      loadingConnectorsWithActions: false,
    }),
  ),

  on(
    ConnectorCommonActions.showModal,
    (
      state: ConnectorCommonState,
      { modalType, connector, actions }: ReturnType<typeof ConnectorCommonActions.showModal>,
    ): ConnectorCommonState => ({
      ...state,
      modal: {
        ...state.modal,
        type: modalType,
        connector,
        actions,
      },
    }),
  ),

  on(
    ConnectorCommonActions.closeModal,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      modal: {
        ...state.modal,
        type: undefined,
        connector: undefined,
        actions: undefined,
        connectorConfigSchema: undefined,
        connectorConfig: undefined,
      },
    }),
  ),

  on(
    ConnectorCommonActions.loadConnectorConfigSuccess,
    (
      state: ConnectorCommonState,
      { config }: ReturnType<typeof ConnectorCommonActions.loadConnectorConfigSuccess>,
    ): ConnectorCommonState => ({
      ...state,
      modal: {
        ...state.modal,
        connectorConfig: config,
      },
    }),
  ),

  on(
    ConnectorCommonActions.getConnectorConfigSchemaSuccess,
    (
      state: ConnectorCommonState,
      { connectorConfigSchema }: ReturnType<typeof ConnectorCommonActions.getConnectorConfigSchemaSuccess>,
    ): ConnectorCommonState => ({
      ...state,
      modal: {
        ...state.modal,
        connectorConfigSchema,
      },
    }),
  ),

  on(
    ConnectorCommonActions.updateConnector,
    ConnectorCommonActions.createCustomConnector,
    ConnectorCommonActions.deleteConnectorConfig,
    ConnectorCommonActions.deleteConnector,
    ConnectorCommonActions.createConnectorConfig,
    ConnectorCommonActions.updateConnectorConfig,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      modal: {
        ...state.modal,
        confirmLoadStatus: LOAD_STATE.IN_FLIGHT,
      },
    }),
  ),

  on(
    ConnectorCommonActions.updateConnectorSuccess,
    ConnectorCommonActions.createCustomConnectorSuccess,
    ConnectorCommonActions.deleteConnectorConfigSuccess,
    ConnectorCommonActions.deleteConnectorSuccess,
    ConnectorCommonActions.createConnectorConfigSuccess,
    ConnectorCommonActions.updateConnectorConfigSuccess,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      modal: {
        ...state.modal,
        confirmLoadStatus: LOAD_STATE.SUCCESS,
        type: undefined,
        connector: undefined,
        connectorConfigSchema: undefined,
        connectorConfig: undefined,
      },
    }),
  ),

  on(
    ConnectorCommonActions.updateConnectorFailure,
    ConnectorCommonActions.createCustomConnectorFailure,
    ConnectorCommonActions.deleteConnectorConfigFailure,
    ConnectorCommonActions.deleteConnectorFailure,
    ConnectorCommonActions.createConnectorConfigFailure,
    ConnectorCommonActions.updateConnectorConfigFailure,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      modal: {
        ...state.modal,
        confirmLoadStatus: LOAD_STATE.FAILURE,
      },
    }),
  ),

  on(
    ConnectorCommonActions.loadManagedConnectors,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      managedConnectorsSearchLoadStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    ConnectorCommonActions.loadManagedConnectorsSuccess,
    (
      state: ConnectorCommonState,
      { response }: ReturnType<typeof ConnectorCommonActions.loadManagedConnectorsSuccess>,
    ): ConnectorCommonState => ({
      ...state,
      managedConnectorsSearchResponse: response,
      managedConnectorsSearchLoadStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    ConnectorCommonActions.loadManagedConnectorsFailure,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      managedConnectorsSearchLoadStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    ConnectorCommonActions.loadAllManagedConnectors,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      allManagedConnectorsLoadStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    ConnectorCommonActions.loadAllManagedConnectorsSuccess,
    (
      state: ConnectorCommonState,
      { response }: ReturnType<typeof ConnectorCommonActions.loadAllManagedConnectorsSuccess>,
    ): ConnectorCommonState => ({
      ...state,
      allManagedConnectorsResponse: response,
      allManagedConnectorsLoadStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    ConnectorCommonActions.loadAllManagedConnectorsFailure,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      allManagedConnectorsLoadStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    ConnectorCommonActions.loadConnectorsUsage,
    (state: ConnectorCommonState, { request }: ReturnType<typeof ConnectorCommonActions.loadConnectorsUsage>): ConnectorCommonState => ({
      ...state,
      connectorsUsageRequest: request,
      connectorsUsageLoadStatus: LOAD_STATE.IN_FLIGHT,
      modal: {
        ...state.modal,
        confirmLoadStatus: LOAD_STATE.IN_FLIGHT,
      },
    }),
  ),

  on(
    ConnectorCommonActions.loadConnectorsUsageSuccess,
    (
      state: ConnectorCommonState,
      { response }: ReturnType<typeof ConnectorCommonActions.loadConnectorsUsageSuccess>,
    ): ConnectorCommonState => ({
      ...state,
      connectorsUsageResponse: response,
      connectorsUsageLoadStatus: LOAD_STATE.SUCCESS,
      modal: {
        ...state.modal,
        confirmLoadStatus: LOAD_STATE.SUCCESS,
      },
    }),
  ),

  on(
    ConnectorCommonActions.loadConnectorsUsageFailure,
    (state: ConnectorCommonState): ConnectorCommonState => ({
      ...state,
      connectorsUsageLoadStatus: LOAD_STATE.FAILURE,
      modal: {
        ...state.modal,
        confirmLoadStatus: LOAD_STATE.FAILURE,
      },
    }),
  ),

  on(
    ConnectorCommonActions.loadConnectorsUsageWithSort,
    (
      state: ConnectorCommonState,
      { sortOns }: ReturnType<typeof ConnectorCommonActions.loadConnectorsUsageWithSort>,
    ): ConnectorCommonState => ({
      ...state,
      connectorsUsageRequest: new GenericSearchRequest({
        ...state.connectorsUsageRequest,
        sortOns,
      }),
      connectorsUsageLoadStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),
);

/**
 * connectorCommonReducer
 * @param {ConnectorCommonState} state
 * @param {Action} action
 * @returns {ConnectorCommonState}
 */
export function connectorCommonReducer(state: ConnectorCommonState, action: Action): ConnectorCommonState {
  return _reducer(state, action);
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { RouterSelectors } from '@ws1c/intelligence-core/store/router';
import { UserPreferenceTrialInfoSelectors } from '@ws1c/intelligence-core/store/user-preference/user-preference-trial-info.selectors';
import { Bookmark, BookmarkCategory, LabelValue } from '@ws1c/intelligence-models';
import { getBookmarksByCategory } from './bookmarks-selectors-helpers';
import { BookmarksState } from './bookmarks.store';

/**
 * BookmarksSelectors
 * @export
 * @class BookmarksSelectors
 */
export class BookmarksSelectors {
  public static selectBookmarksState = (state: CoreAppState) => state.bookmarksState;

  /**
   * getBookmarks
   * @static
   * @type {MemoizedSelector<CoreAppState, Bookmark[]>}
   * @memberof BookmarksSelectors
   */
  public static getBookmarks: MemoizedSelector<CoreAppState, Bookmark[]> = createSelector(
    BookmarksSelectors.selectBookmarksState,
    (state: BookmarksState) => state.bookmarks,
  );

  /**
   * getCurrentBookmarkPath
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof BookmarksSelectors
   */
  public static getCurrentBookmarkPath: MemoizedSelector<CoreAppState, string> = createSelector(
    RouterSelectors.currentUrl$,
    decodeURIComponent,
  );

  /**
   * getActiveBookmarkIdsSet
   * @static
   * @type {MemoizedSelector<CoreAppState, Set<string>>}
   * @memberof BookmarksSelectors
   */
  public static getActiveBookmarkIdsSet: MemoizedSelector<CoreAppState, Set<string>> = createSelector(
    BookmarksSelectors.getBookmarks,
    (bookmarks: Bookmark[]) => new Set(bookmarks.map((bookmark: Bookmark) => bookmark.resourceId)),
  );

  /**
   * getActiveBookmarkPathsSet
   * @static
   * @type {MemoizedSelector<CoreAppState, Set<string>>}
   * @memberof BookmarksSelectors
   */
  public static getActiveBookmarkPathsSet: MemoizedSelector<CoreAppState, Set<string>> = createSelector(
    BookmarksSelectors.getBookmarks,
    (bookmarks: Bookmark[]) => new Set(bookmarks.map((bookmark: Bookmark) => bookmark.resourcePath)),
  );

  /**
   * getActiveBookmarksMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, Bookmark>>}
   * @memberof BookmarksSelectors
   */
  public static getActiveBookmarksMap: MemoizedSelector<CoreAppState, Map<string, Bookmark>> = createSelector(
    BookmarksSelectors.getBookmarks,
    (bookmarks: Bookmark[]) => new Map(bookmarks.map((bookmark: Bookmark) => [bookmark.resourceId, bookmark])),
  );

  /**
   * getAvailableBookmarkCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof BookmarksSelectors
   */
  public static getAvailableBookmarkCategories: MemoizedSelector<CoreAppState, string[]> = createSelector(
    BookmarksSelectors.getBookmarks,
    (bookmarks: Bookmark[]) => {
      const availableBookmarkCategories: Set<string> = new Set<BookmarkCategory>();
      bookmarks.forEach((bookmark: Bookmark) => {
        const bookmarkCategory: string = [BookmarkCategory.DASHBOARD_WIDGET, BookmarkCategory.APP].includes(
          bookmark.category as BookmarkCategory,
        )
          ? BookmarkCategory.DASHBOARD
          : bookmark.category;
        availableBookmarkCategories.add(bookmarkCategory);
      });
      return Array.from(availableBookmarkCategories);
    },
  );

  /**
   * getDisabledBookmarks
   * @static
   * @type {MemoizedSelector<CoreAppState, Bookmark[]>}
   * @memberof BookmarksSelectors
   */
  public static getDisabledBookmarks: MemoizedSelector<CoreAppState, Bookmark[]> = createSelector(
    UserPreferenceTrialInfoSelectors.isTrialNotActive,
    BookmarksSelectors.getBookmarks,
    BookmarksSelectors.getAvailableBookmarkCategories,
    (isTrialNotActive: boolean, bookmarks: Bookmark[], availableBookmarkCategories: string[]) => {
      if (!isTrialNotActive) {
        return [];
      }
      return availableBookmarkCategories.reduce(
        (disabledBookmarks: Bookmark[], availableBookmarkCategory: string) =>
          availableBookmarkCategory === BookmarkCategory.REPORT
            ? disabledBookmarks
            : [...disabledBookmarks, ...getBookmarksByCategory(bookmarks, availableBookmarkCategory)],
        [],
      );
    },
  );

  /**
   *
   * hasBookmarkByPath
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof BookmarksSelectors
   */
  public static hasBookmarkByPath: MemoizedSelector<CoreAppState, boolean> = createSelector(
    BookmarksSelectors.getBookmarks,
    BookmarksSelectors.getCurrentBookmarkPath,
    (bookmarks: Bookmark[], bookmarkPath: string) => {
      return bookmarks.some((bookmark: Bookmark) => bookmark.resourcePath.toLowerCase() === bookmarkPath.toLowerCase());
    },
  );

  /**
   * isBookmarkActionHiddenFn
   * @static
   * @param {boolean} isAddBookmarkAction
   * @type {
   *  MemoizedSelector<CoreAppState,
   *  (resourceObject: { id: string }) => boolean,
   *  DefaultProjectorFn<(resourceObject: { id: string }) => boolean>>
   * }
   * @memberof BookmarksSelectors
   */
  public static isBookmarkActionHiddenFn: (
    isAddBookmarkAction: boolean,
  ) => MemoizedSelector<
    CoreAppState,
    (resourceObject: { id: string }) => boolean,
    DefaultProjectorFn<(resourceObject: { id: string }) => boolean>
  > = (
    isAddBookmarkAction: boolean,
  ): MemoizedSelector<
    CoreAppState,
    (resourceObject: { id: string }) => boolean,
    DefaultProjectorFn<(resourceObject: { id: string }) => boolean>
  > => {
    return createSelector(
      BookmarksSelectors.getActiveBookmarkIdsSet,
      (activeBookmarkIdsSet: Set<string>) =>
        (resourceObject: { id: string }): boolean => {
          const hasBookmark: boolean = activeBookmarkIdsSet.has(resourceObject.id);
          // Hide Add to Bookmarks action if bookmark exists for a resource object.
          // Hide Remove from Bookmarks action if bookmark doesn't exit for a resource object.
          return isAddBookmarkAction ? hasBookmark : !hasBookmark;
        },
    );
  };

  /**
   * isBookmarkActionAppsHiddenFn
   * @static
   * @param {boolean} isAddBookmarkAction
   * @type {
   *  MemoizedSelector<CoreAppState,
   *  (resourceObject: { apteligentAppId: string }) => boolean,
   *  DefaultProjectorFn<(resourceObject: { apteligentAppId: string }) => boolean>>
   * }
   * @memberof BookmarksSelectors
   */
  public static isBookmarkActionAppsHiddenFn: (
    isAddBookmarkAction: boolean,
  ) => MemoizedSelector<
    CoreAppState,
    (resourceObject: { apteligentAppId: string }) => boolean,
    DefaultProjectorFn<(resourceObject: { apteligentAppId: string }) => boolean>
  > = (
    isAddBookmarkAction: boolean,
  ): MemoizedSelector<
    CoreAppState,
    (resourceObject: { apteligentAppId: string }) => boolean,
    DefaultProjectorFn<(resourceObject: { apteligentAppId: string }) => boolean>
  > => {
    return createSelector(
      BookmarksSelectors.getActiveBookmarkIdsSet,
      (activeBookmarkIdsSet: Set<string>) =>
        (resourceObject: { apteligentAppId: string }): boolean => {
          const hasBookmark: boolean = activeBookmarkIdsSet.has(resourceObject.apteligentAppId);
          return isAddBookmarkAction ? hasBookmark : !hasBookmark;
        },
    );
  };

  /**
   * isBookmarkFilterEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof BookmarksSelectors
   */
  public static isBookmarkFilterEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    BookmarksSelectors.selectBookmarksState,
    (state: BookmarksState) => state.isBookmarkFilterEnabled,
  );

  /**
   * getSelectedBookmarkFilterOption
   * @static
   * @type {MemoizedSelector<CoreAppState, LabelValue>}
   * @memberof BookmarksSelectors
   */
  public static getSelectedBookmarkFilterOption: MemoizedSelector<CoreAppState, LabelValue> = createSelector(
    BookmarksSelectors.selectBookmarksState,
    (state: BookmarksState) => state.selectedBookmarkFilterOption,
  );

  /**
   * getHomePageBookmarks
   * @static
   * @type {MemoizedSelector<CoreAppState, Bookmark[]>}
   * @memberof BookmarksSelectors
   */
  public static getHomePageBookmarks: MemoizedSelector<CoreAppState, Bookmark[]> = createSelector(
    UserPreferenceTrialInfoSelectors.isTrialNotActive,
    BookmarksSelectors.getBookmarks,
    BookmarksSelectors.getSelectedBookmarkFilterOption,
    (isTrialNotActive: boolean, bookmarks: Bookmark[], bookmarkFilterOption: LabelValue) =>
      isTrialNotActive
        ? getBookmarksByCategory(bookmarks, BookmarkCategory.REPORT)
        : getBookmarksByCategory(bookmarks, bookmarkFilterOption && bookmarkFilterOption.value),
  );
}

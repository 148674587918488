/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Category, Trend, TrendMode } from '@ws1c/intelligence-models';

/**
 * hasReportAccessHelper
 * @export
 * @param {Category} activeCategory
 * @param {Trend} trend
 * @param {boolean} isTimeseriesReportingEnabled
 * @returns {boolean}
 */
export function hasReportAccessHelper(activeCategory: Category, trend: Trend, isTimeseriesReportingEnabled: boolean): boolean {
  const trendDefinition = trend?.trendDefinition;
  if (!trendDefinition?.isV2) {
    return false;
  }
  const hasSnapShotAccess = !!(
    activeCategory?.supportsReporting &&
    activeCategory?.supportedTrendModes.includes(TrendMode[TrendMode.SNAPSHOT]) &&
    trendDefinition?.trendMode === TrendMode[TrendMode.SNAPSHOT]
  );
  const hasHistoricalAccess = !!(
    isTimeseriesReportingEnabled &&
    activeCategory?.supportsReporting &&
    activeCategory?.supportedTrendModes.includes(TrendMode[TrendMode.HISTORICAL]) &&
    trendDefinition?.trendMode === TrendMode[TrendMode.HISTORICAL]
  );
  return hasSnapShotAccess || hasHistoricalAccess;
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Feature, FeatureControls, FeatureGroup, FeatureOperator, MenuItem } from '@ws1c/intelligence-models';
import { hasScope, hasScopeOrParentScope } from './rbac-selector-helper';

/**
 * hasMenuItemAccess
 * @export
 * @param {FeatureControls} featureControls
 * @param {string[]} userScopes
 * @param {MenuItem} menuItem
 * @returns {boolean}
 */
export function hasMenuItemAccess(featureControls: FeatureControls, userScopes: string[], menuItem: MenuItem): boolean {
  if (menuItem.scopes || menuItem.featureControlKey) {
    return (
      (!menuItem.featureControlKey || featureControls[menuItem.featureControlKey]) &&
      (!menuItem.scopes || menuItem.scopes.some((scope: string) => hasScopeOrParentScope(userScopes, scope)))
    );
  }
  return !menuItem.featureGroup || hasFeatureAndScope(featureControls, userScopes, menuItem.featureGroup);
}

/**
 * hasFeatureAndScope
 * @export
 * @param {FeatureControls} featureControls
 * @param {string[]} userScopes
 * @param {FeatureGroup} featureGroup
 * @returns {boolean}
 */
export function hasFeatureAndScope(featureControls: FeatureControls, userScopes: string[], featureGroup: FeatureGroup): boolean {
  if (!featureGroup?.operator) {
    return false;
  }
  switch (featureGroup.operator) {
    case FeatureOperator.AND:
      return featureGroup.features.reduce((previousValue: boolean, featureItem: Feature | FeatureGroup) => {
        if ((featureItem as FeatureGroup).features) {
          return previousValue && hasFeatureAndScope(featureControls, userScopes, featureItem as FeatureGroup);
        }
        return previousValue && hasScope(featureControls, userScopes, featureItem as Feature, true);
      }, true);
    case FeatureOperator.OR:
      return featureGroup.features.reduce((previousValue: boolean, featureItem: Feature | FeatureGroup) => {
        if ((featureItem as FeatureGroup).features) {
          return previousValue || hasFeatureAndScope(featureControls, userScopes, featureItem as FeatureGroup);
        }
        return previousValue || hasScope(featureControls, userScopes, featureItem as Feature, true);
      }, false);
    default:
      return false;
  }
}

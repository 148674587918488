/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Action, createReducer, on } from '@ngrx/store';

import { RbacActions } from './rbac.actions';
import { initialRbacState, RbacState } from './rbac.state';

const _reducer = createReducer(
  initialRbacState,
  on(
    RbacActions.setUserScopes,
    (state: RbacState, action: ReturnType<typeof RbacActions.setUserScopes>): RbacState => ({
      ...state,
      userSessionScopes: action.userSessionScopes,
    }),
  ),
  on(
    RbacActions.requestAccessSuccess,
    (state: RbacState, action: ReturnType<typeof RbacActions.requestAccessSuccess>): RbacState => ({
      ...state,
      isEmailSentForAccessRequest: true,
      adminCountForAccessRequest: action.adminCountForAccessRequest,
    }),
  ),
);

/**
 * User State Reducer
 * @param {RbacState} state
 * @param {Action} action
 * @returns {RbacState}
 */
export function rbacState(state: RbacState, action: Action) {
  return _reducer(state, action);
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { TrendDateRange } from '@ws1c/intelligence-models';

/**
 * MarkerShape
 * @export
 * @enum {string}
 */
export enum MarkerShape {
  CIRCLE = 'circle',
  CROSS = 'cross',
  DIAMOND = 'diamond',
  SQUARE = 'square',
  STAR = 'star',
  TRIANGLE = 'triangle',
  WYE = 'wye',
}

/**
 * MarkerFocusEventType
 * @export
 * @enum {string}
 */
export enum MarkerFocusEventType {
  ALERT = 'alert',
  INFO = 'info',
}

/**
 * Marker
 * @export
 * @interface Marker
 */
export interface Marker {
  [k: string]: any; // allows any custom properties to be used in marker tooltip
  x: any;
  y: any;
  label?: string;
  hideMarker?: boolean;
  showXReferenceLine?: boolean;
  showYReferenceLine?: boolean;
  shape?: MarkerShape;
  color?: string;
  date?: number;
  yLabel?: string;
}

/**
 * MarkerFocusEvent
 * @export
 * @interface MarkerFocusEvent
 */
export interface MarkerFocusEvent {
  count: number;
  color?: string;
  name: string;
  selected: boolean;
  type?: string;
}

/**
 * MarkerTrendDateRange
 * @export
 * @interface MarkerTrendDateRange
 */
export interface MarkerTrendDateRange {
  marker: MarkerContext;
  trendDateRange: TrendDateRange;
}

/**
 * MarkerContext
 * @export
 * @class MarkerContext
 */
export class MarkerContext {
  public chartHeight: number;
  public chartWidth: number;
  public xPos: number;
  public yPos: number;
  public path: string;
  public label: string;
  public showXReferenceLine: boolean;
  public showYReferenceLine: boolean;
  public hideMarker: boolean;
  public transformText: string;

  public tooltipContext: Marker;
  public color: string;
  public date: number;
  public yLabel: string;

  /**
   * constructor
   * @param {Array<Partial<MarkerContext>>} args
   * @memberof MarkerContext
   */
  constructor(...args: Array<Partial<MarkerContext>>) {
    Object.assign(this, ...args);
  }
}

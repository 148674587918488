/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges } from '@angular/core';

import { UNIT_DECORATOR_CONFIG } from '@ws1c/intelligence-common';
import { DeemUxScoreThreshold, ScoreUnit, ThresholdLevel } from '@ws1c/intelligence-models';

/**
 * ThresholdRangeIndicatorComponent
 * @export
 * @class ThresholdRangeIndicatorComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-threshold-range-indicator',
  templateUrl: 'threshold-range-indicator.component.html',
  styleUrls: ['threshold-range-indicator.component.scss'],
})
export class ThresholdRangeIndicatorComponent implements OnChanges {
  @Input() public key: string;
  @Input() public threshold: DeemUxScoreThreshold;

  public goodOrNeutral: ThresholdLevel;
  public neutralOrBad: ThresholdLevel;
  public formattedValueGN: string;
  public formattedValueNB: string;

  /**
   * ngOnChanges
   * @memberof ThresholdRangeIndicatorComponent
   */
  public ngOnChanges() {
    if (!this.threshold) {
      return;
    }
    this.goodOrNeutral = this.threshold.isReversed ? ThresholdLevel.GOOD : ThresholdLevel.NEUTRAL;
    this.neutralOrBad = this.threshold.isReversed ? ThresholdLevel.NEUTRAL : ThresholdLevel.BAD;
    this.formattedValueGN = this.getFormattedValue(this.threshold, this.goodOrNeutral);
    this.formattedValueNB = this.getFormattedValue(this.threshold, this.neutralOrBad);
  }

  /**
   * getFormattedValue
   * @param {DeemUxScoreThreshold} threshold
   * @param {ThresholdLevel} level
   * @returns {string}
   * @memberof ThresholdRangeIndicatorComponent
   */
  public getFormattedValue(threshold: DeemUxScoreThreshold, level: ThresholdLevel): string {
    if (threshold.unit === ScoreUnit.SECONDS) {
      return `${threshold.values[level]}${UNIT_DECORATOR_CONFIG[ScoreUnit.SECONDS]}`;
    }
    if (threshold.unit === ScoreUnit.PERCENTAGE) {
      return `${threshold.values[level]}${UNIT_DECORATOR_CONFIG[ScoreUnit.PERCENTAGE]}`;
    }
    if (threshold.unit === ScoreUnit.MBPS) {
      return `${threshold.values[level]}${UNIT_DECORATOR_CONFIG[ScoreUnit.MBPS]}`;
    }
    return `${threshold.values[level]}`;
  }
}

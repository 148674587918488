/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { QUICK_FILTER_TAG_TYPE, Tag } from '@ws1c/intelligence-models';

/**
 * QuickFilterActions
 *
 * @export
 * @class QuickFilterActions
 */
export class QuickFilterActions {
  public static readonly CATEGORY = '[QUICK_FILTER]';
  private static readonly type = (actionName: string): string => `${QuickFilterActions.CATEGORY} ${actionName}`;

  /**
   * getTags
   * @static
   * @memberof QuickFilterActions
   */
  public static getTags = createAction(QuickFilterActions.type('GET_TAGS'), props<{ quickFilterTagType: QUICK_FILTER_TAG_TYPE }>());

  /**
   * setTags
   * @static
   * @memberof QuickFilterActions
   */
  public static setTags = createAction(QuickFilterActions.type('SET_TAGS'), props<{ tags: Tag[] }>());
}

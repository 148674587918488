/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KEY_CODES } from '@dpa/ui-common';

import { NgxChart } from '@ws1c/intelligence-models';

/**
 * LegendHorizontalComponent
 * @export
 * @class LegendHorizontalComponent
 */
@Component({
  selector: 'dpa-legend-horizontal',
  templateUrl: 'legend-horizontal.component.html',
  styleUrls: ['legend-horizontal.component.scss'],
})
export class LegendHorizontalComponent {
  @Input() public ngxChart: NgxChart;
  @Input() public activeSeries: Set<string>;
  @Input() public focusedSeries = [];
  @Output() public selectSeries = new EventEmitter<string>();
  @Output() public activateSeries = new EventEmitter<string>();
  @Output() public deactivateSeries = new EventEmitter<string>();

  /**
   * onKeyDown
   * if user selects a series using tab, pressing enter should select it
   * @param {KeyboardEvent} keyboardEvent
   * @param {string} seriesName
   */
  public onKeyDown(keyboardEvent: KeyboardEvent, seriesName: string) {
    if (keyboardEvent.key === KEY_CODES.ENTER) {
      this.selectSeries.emit(seriesName);
    }
  }

  /**
   * isFocused
   * @param {string} attributeValue
   * @memberof LegendHorizontalComponent
   * @returns {boolean}
   */
  public isFocused(attributeValue: string): boolean {
    return !this.focusedSeries.length || this.focusedSeries.includes(attributeValue);
  }

  /**
   * getBackgroundColor
   * @param  {string} attributeValue
   * @returns {string}
   * @memberof LegendHorizontalComponent
   */
  public getBackgroundColor(attributeValue: string): string {
    return this.isFocused(attributeValue) ? this.ngxChart.colorsByAttribute[attributeValue] : null;
  }
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { each } from 'lodash-es';

import { AggregationWidget, StandardDashboard, WidgetPreference } from '@ws1c/intelligence-models';

/**
 * getWidgetAttributePreferencesForWidgets
 * @export
 * @param {AggregationWidget[]} widgets
 * @returns {Record<string, WidgetPreference[]>}
 */
export function getWidgetAttributePreferencesForWidgets(widgets: AggregationWidget[]): Record<string, WidgetPreference[]> {
  const widgetAttributePreferencesById: Record<string, WidgetPreference[]> = {};
  widgets.forEach((widget: AggregationWidget) => {
    if (widget?.widgetAttributePreferences) {
      widgetAttributePreferencesById[widget.id] = widget.widgetAttributePreferences;
    }
  });
  return widgetAttributePreferencesById;
}

/**
 * getWidgetAttributePreferencesForStandardDashboard
 * @export
 * @param {StandardDashboard} standardDashboard
 * @returns {Record<string, WidgetPreference[]>}
 */
export function getWidgetAttributePreferencesForStandardDashboard(
  standardDashboard: StandardDashboard,
): Record<string, WidgetPreference[]> {
  const widgetAttributePreferencesById: Record<string, WidgetPreference[]> = {};
  each(standardDashboard.widgetAttributePreferences, (widgetPreferences: WidgetPreference[], widgetId: string) => {
    if (widgetPreferences?.length !== 0) {
      widgetAttributePreferencesById[widgetId] = widgetPreferences;
    }
  });
  return widgetAttributePreferencesById;
}

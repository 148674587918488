/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { IntegratedServicesResponse, ServiceMode } from '@ws1c/intelligence-models';

/**
 * IntegratedServicesActions
 *
 * @export
 * @class IntegratedServicesActions
 */
export class IntegratedServicesActions {
  public static readonly CATEGORY = '[INTEGRATED_SERVICES]';

  public static readonly GET_INTEGRATED_SERVICES = `${IntegratedServicesActions.CATEGORY} GET_INTEGRATED_SERVICES`;
  public static readonly GET_INTEGRATED_SERVICES_SUCCESS = `${IntegratedServicesActions.CATEGORY} GET_INTEGRATED_SERVICES_SUCCESS`;
  public static readonly GET_INTEGRATED_SERVICES_FAILURE = `${IntegratedServicesActions.CATEGORY} GET_INTEGRATED_SERVICES_FAILURE`;

  public static readonly SET_ACTIVE_SERVICE_MODE = `${IntegratedServicesActions.CATEGORY} SET_ACTIVE_SERVICE_MODE`;

  public static getIntegratedServices = createAction(IntegratedServicesActions.GET_INTEGRATED_SERVICES);

  public static getIntegratedServicesSuccess = createAction(
    IntegratedServicesActions.GET_INTEGRATED_SERVICES_SUCCESS,
    props<{ integratedServicesData: IntegratedServicesResponse }>(),
  );

  public static getIntegratedServicesFailure = createAction(
    IntegratedServicesActions.GET_INTEGRATED_SERVICES_FAILURE,
    props<{ error: WebError }>(),
  );

  public static setActiveServiceMode = createAction(
    IntegratedServicesActions.SET_ACTIVE_SERVICE_MODE,
    props<{ activeServiceMode: ServiceMode }>(),
  );
}

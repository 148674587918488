/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { FeatureControl, UserScopes } from '@ws1c/intelligence-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { IntegratedServicesSelectors } from '@ws1c/intelligence-core/store/integrated-services';
import { OrgSelectors } from '@ws1c/intelligence-core/store/org';
import { FeatureSelectors, hasMenuItemAccess, RbacSelectors } from '@ws1c/intelligence-core/store/rbac';
import { SolutionSetupSelectors } from '@ws1c/intelligence-core/store/solution-setup';
import {
  UserPreferenceCommonSelectors,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
  UserPreferenceTrialInfoSelectors,
} from '@ws1c/intelligence-core/store/user-preference';
import { FeatureControls, IntroType, MenuItem, NavigationMenuGroup, NavigationMenuItem, ROUTE_NAMES } from '@ws1c/intelligence-models';
import { selectDefaultMenuItemRoute } from './navigation-menu-selector-helpers';
import { NavigationMenuState } from './navigation-menu.store';

/**
 * NavigationMenuSelectors
 *
 * @export
 * @class NavigationMenuSelectors
 */
export class NavigationMenuSelectors {
  /**
   * selectNavigationMenuState
   *
   * @param {CoreAppState} state
   * @static
   * @returns {NavigationMenuState}
   * @memberof NavigationMenuSelectors
   */
  public static selectNavigationMenuState = (state: CoreAppState): NavigationMenuState => state.navigationMenuState;

  /**
   * activatedNavMenuItemIndex
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof NavigationMenuSelectors
   */
  public static activatedNavMenuItemIndex: MemoizedSelector<CoreAppState, number> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    (state: NavigationMenuState) => state.activatedNavMenuItemIndex,
  );

  /**
   * showSecondaryNavigation
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static showSecondaryNavigation: MemoizedSelector<CoreAppState, boolean> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    (state: NavigationMenuState) => !!state.navigationMenu?.[state.activatedNavMenuItemIndex]?.length,
  );

  /**
   * loadDefaultRoute
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static loadDefaultRoute: MemoizedSelector<CoreAppState, boolean> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    (state: NavigationMenuState) => state.loadDefaultRoute,
  );

  /**
   * hasRequiredIntegregationsPerms
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static hasRequiredIntegregationsPerms: MemoizedSelector<CoreAppState, boolean> = createSelector(
    FeatureSelectors.hasIntegrationHomePerm,
    FeatureSelectors.hasPartnerReadOnlyPerm,
    FeatureSelectors.hasEventForwarderReadOnlyPerm,
    FeatureSelectors.hasIntegrationRegistrationReadOnlyPerm,
    FeatureSelectors.hasWorkflowReadOnlyPerm,
    (
      hasIntegrationHomePerm: boolean,
      hasPartnerReadOnlyPerm: boolean,
      hasEventForwarderReadOnlyPerm: boolean,
      hasIntegrationRegistrationReadOnlyPerm: boolean,
      hasWorkflowReadOnlyPerm: boolean,
    ) => {
      return (
        hasIntegrationHomePerm ||
        hasPartnerReadOnlyPerm ||
        hasEventForwarderReadOnlyPerm ||
        hasIntegrationRegistrationReadOnlyPerm ||
        hasWorkflowReadOnlyPerm
      );
    },
  );

  /**
   * availableIntegrationsMenuItems
   * @export
   * @param {Observable<CoreAppState>} $state - Merlot state observable
   * @returns {Observable<MenuItem[]>}
   */
  public static availableIntegrationsMenuItems: MemoizedSelector<CoreAppState, MenuItem[]> = createSelector(
    NavigationMenuSelectors.hasRequiredIntegregationsPerms,
    NavigationMenuSelectors.selectNavigationMenuState,
    UserPreferenceCommonSelectors.getFeatureControls,
    RbacSelectors.getUserScopes,
    (
      hasRequiredIntegregationsPerms: boolean,
      navigationMenuState: NavigationMenuState,
      featureControls: FeatureControls,
      userScopes: string[],
    ) => {
      if (!hasRequiredIntegregationsPerms) {
        return [];
      }
      const navigationMenuItem: NavigationMenuItem = NavigationMenuItem.INTEGRATIONS_CMS;
      const menuItems: MenuItem[] = navigationMenuState.navigationMenu[navigationMenuItem];
      return menuItems.filter((menuItem: MenuItem) => {
        return hasMenuItemAccess(featureControls, userScopes, menuItem);
      });
    },
  );

  /**
   * getVisibleMenuItems
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, MenuItem[]>}
   * @memberof NavigationMenuSelectors
   */
  public static getVisibleMenuItems = (menuName: NavigationMenuItem): MemoizedSelector<CoreAppState, MenuItem[]> =>
    createSelector(
      NavigationMenuSelectors.selectNavigationMenuState,
      UserPreferenceCommonSelectors.getFeatureControls,
      RbacSelectors.getUserScopes,
      (state: NavigationMenuState, featureControls: FeatureControls, userScopes: string[]) => {
        const menuItems: MenuItem[] = state.navigationMenu[menuName];
        return menuItems.filter((menuItem: MenuItem) => {
          return hasMenuItemAccess(featureControls, userScopes, menuItem);
        });
      },
    );

  /**
   * getWorkspaceSecurityMenuItems
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, MenuItem[]>>}
   * @memberof NavigationMenuSelectors
   */
  public static getWorkspaceSecurityMenuItems: MemoizedSelector<CoreAppState, MenuItem[]> = createSelector(
    NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.WORKSPACE_WORKSPACE_SECURITY),
    NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.WORKSPACE_WORKSPACE_SECURITY_NO_CVE),
    SolutionSetupSelectors.isCveReady,
    (allMenuItems: MenuItem[], menuItemsNoCve: MenuItem[], isCveReady: boolean) => (isCveReady ? allMenuItems : menuItemsNoCve),
  );

  /**
   * availableAdministratorsMenuItems
   * @static
   * @type {MemoizedSelector<CoreAppState, MenuItem[]>}
   * @memberof NavigationMenuSelectors
   */
  public static availableAdministratorsMenuItems: MemoizedSelector<CoreAppState, MenuItem[]> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    UserPreferenceCommonSelectors.getFeatureControls,
    RbacSelectors.getUserScopes,
    UserPreferenceSelectors.isBrownfieldWorkspaceOneCloudCustomer,
    UserPreferenceSelectors.isWSOneRbacEnabled,
    (
      state: NavigationMenuState,
      featureControls: FeatureControls,
      userScopes: string[],
      isBrownfieldWorkspaceOneCloudCustomer: boolean,
      isWSOneRbacEnabled: boolean,
    ) => {
      const administratorsMenuItems: MenuItem[] = [];
      const intelRbacMenuItem: MenuItem = {
        featureControlKey: FeatureControl.RBAC_ENABLED,
        labelKey: 'NAVIGATION.LOCAL_ADMINS',
        route: `/${ROUTE_NAMES.SETTINGS.ADMIN_ACCOUNT}`,
        scopes: [UserScopes.ACCOUNT_READONLY, UserScopes.ACCOUNT_GROUP_READONLY],
      };
      const cloudRbacMenuItem: MenuItem = {
        featureControlKey: FeatureControl.WORKSPACE_ONE_RBAC_ENABLED,
        labelKey: 'NAVIGATION.CLOUD_ADMINS',
        route: `/${ROUTE_NAMES.ADMIN_MANAGEMENT.HOME}`,
        scopes: [UserScopes.IAM_GROUPS, UserScopes.IAM_USERS, UserScopes.IAM_USERS_GANGSTER_READONLY],
      };
      if (isBrownfieldWorkspaceOneCloudCustomer && isWSOneRbacEnabled) {
        administratorsMenuItems.push(cloudRbacMenuItem);
      }
      if (isBrownfieldWorkspaceOneCloudCustomer) {
        administratorsMenuItems.push(intelRbacMenuItem);
      } else {
        administratorsMenuItems.push(isWSOneRbacEnabled ? cloudRbacMenuItem : intelRbacMenuItem);
      }
      state.navigationMenu[NavigationMenuItem.ADMINISTRATORS] = administratorsMenuItems;
      const menuItems: MenuItem[] = state.navigationMenu[NavigationMenuItem.ADMINISTRATORS];
      return menuItems.filter((menuItem: MenuItem) => hasMenuItemAccess(featureControls, userScopes, menuItem));
    },
  );

  /**
   * showAvailableAdministratorsMenuItems
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static showAvailableAdministratorsMenuItems: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isWorkspaceOneBrownfieldRbacEnabled,
    NavigationMenuSelectors.availableAdministratorsMenuItems,
    (isWorkspaceOneBrownfieldRbacEnabled: boolean, availableAdministratorsMenuItems: MenuItem[]) => {
      return isWorkspaceOneBrownfieldRbacEnabled && availableAdministratorsMenuItems?.length > 1;
    },
  );

  /**
   * availableSettingsMenuItems
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, MenuItem[]>}
   * @memberof NavigationMenuSelectors
   */
  public static availableSettingsMenuItems: MemoizedSelector<CoreAppState, MenuItem[]> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    UserPreferenceCommonSelectors.getFeatureControls,
    RbacSelectors.getUserScopes,
    UserPreferenceFeatureControlsSelectors.isWorkspaceOneBrownfieldRbacEnabled,
    UserPreferenceSelectors.isBrownfieldWorkspaceOneCloudCustomer,
    NavigationMenuSelectors.availableAdministratorsMenuItems,
    OrgSelectors.isBrokerAccessBrownFieldCustomer,
    UserPreferenceSelectors.isAccessAndUemInstanceAvailable,
    UserPreferenceFeatureControlsSelectors.isBrownFieldMigrationEnabled,
    IntegratedServicesSelectors.isAccessIntegrationServiceAvailable,
    (
      state: NavigationMenuState,
      featureControls: FeatureControls,
      scopes: string[],
      isWorkspaceOneBrownfieldRbacEnabled: boolean,
      isBrownfieldWorkspaceOneCloudCustomer: boolean,
      availableAdministratorsMenuItems: MenuItem[],
      isBrokerAccessBrownFieldCustomer: boolean,
      isAccessAndUemInstanceAvailable: boolean,
      isBrownFieldMigrationEnabled: boolean,
      isAccessIntegrationServiceAvailable: boolean,
    ) => {
      let menuItems: MenuItem[] = [];
      menuItems = state.navigationMenu[NavigationMenuItem.SETTINGS];

      if (!isWorkspaceOneBrownfieldRbacEnabled || availableAdministratorsMenuItems.length <= 1) {
        const isWSOneRbacEnabled: boolean =
          !isBrownfieldWorkspaceOneCloudCustomer &&
          featureControls[FeatureControl.WORKSPACE_ONE_RBAC_ENABLED] &&
          featureControls[FeatureControl.WORKSPACE_ONE_CLOUD_ENABLED];
        const administratorsMenu: MenuItem = {
          featureControlKey: isWSOneRbacEnabled ? FeatureControl.WORKSPACE_ONE_RBAC_ENABLED : FeatureControl.RBAC_ENABLED,
          iconName: 'administrator',
          labelKey: 'NAVIGATION.ADMINISTRATORS',
          route: isWSOneRbacEnabled ? `/${ROUTE_NAMES.ADMIN_MANAGEMENT.HOME}` : `/${ROUTE_NAMES.SETTINGS.ADMIN_ACCOUNT}`,
          scopes: isWSOneRbacEnabled
            ? [UserScopes.IAM_GROUPS, UserScopes.IAM_USERS]
            : [UserScopes.ACCOUNT_READONLY, UserScopes.ACCOUNT_GROUP_READONLY],
        };
        menuItems = [administratorsMenu, ...state.navigationMenu[NavigationMenuItem.SETTINGS]];
      }
      const isBrownfieldAvailable = isBrokerAccessBrownFieldCustomer && isBrownFieldMigrationEnabled;
      if (
        isAccessAndUemInstanceAvailable &&
        isAccessIntegrationServiceAvailable &&
        (isBrownfieldAvailable || !isBrokerAccessBrownFieldCustomer)
      ) {
        const endUserManagmentMenuItem: MenuItem = {
          featureControlKey: FeatureControl.WS1_BROKER_SERVICE_OPTIN_FLOW_ENABLED,
          iconName: 'users',
          labelKey: 'NAVIGATION.END_USER_MANAGEMENT',
          route: `/${ROUTE_NAMES.SETTINGS.END_USER_MANAGEMENT}`,
        };
        menuItems = [...menuItems, endUserManagmentMenuItem];
      }
      return menuItems.filter((menuItem: MenuItem) => hasMenuItemAccess(featureControls, scopes, menuItem));
    },
  );

  /**
   * availableNotificationMenuItems
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, MenuItem[]>}
   * @memberof NavigationMenuSelectors
   */
  public static availableNotificationMenuItems: MemoizedSelector<CoreAppState, MenuItem[]> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    UserPreferenceCommonSelectors.getFeatureControls,
    (state: NavigationMenuState, featureControls: FeatureControls) => {
      return state.navigationMenu[NavigationMenuItem.NOTIFICATION].filter((menuItem: MenuItem) => {
        return !menuItem.featureControlKey || featureControls[menuItem.featureControlKey];
      });
    },
  );

  /**
   * defaultIntegrationsMenuItemRoute
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof NavigationMenuSelectors
   */
  public static defaultIntegrationsMenuItemRoute: MemoizedSelector<CoreAppState, string> = createSelector(
    NavigationMenuSelectors.loadDefaultRoute,
    NavigationMenuSelectors.availableIntegrationsMenuItems,
    selectDefaultMenuItemRoute,
  );

  /**
   * availableWorkspaceMenuItems
   * @static
   * @type {MemoizedSelector<CoreAppState, MenuItem[]>}
   * @memberof NavigationMenuSelectors
   */
  public static availableWorkspaceMenuItems: MemoizedSelector<CoreAppState, MenuItem[]> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    (navigationMenuState: NavigationMenuState) => navigationMenuState.navigationMenu[NavigationMenuItem.WORKSPACE],
  );

  /**
   * availableMarketplaceMenuItems
   * @static
   * @type {MemoizedSelector<CoreAppState, MenuItem[]>}
   * @memberof NavigationMenuSelectors
   */
  public static availableMarketplaceMenuItems: MemoizedSelector<CoreAppState, MenuItem[]> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    (navigationMenuState: NavigationMenuState) => navigationMenuState.navigationMenu[NavigationMenuItem.MARKETPLACE],
  );

  /**
   * defaultWorkspaceMenuItemRoute
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof NavigationMenuSelectors
   */
  public static defaultWorkspaceMenuItemRoute: MemoizedSelector<CoreAppState, string> = createSelector(
    NavigationMenuSelectors.loadDefaultRoute,
    NavigationMenuSelectors.availableWorkspaceMenuItems,
    selectDefaultMenuItemRoute,
  );

  /**
   * defaultMarketplaceMenuItemRoute
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof NavigationMenuSelectors
   */
  public static defaultMarketplaceMenuItemRoute: MemoizedSelector<CoreAppState, string> = createSelector(
    NavigationMenuSelectors.loadDefaultRoute,
    NavigationMenuSelectors.availableMarketplaceMenuItems,
    selectDefaultMenuItemRoute,
  );

  /**
   * isSecondaryNavigationMenuCollapsed
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static isSecondaryNavigationMenuCollapsed: MemoizedSelector<CoreAppState, boolean> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    (state: NavigationMenuState) => state.isSecondaryNavigationMenuCollapsed,
  );

  /**
   * saveToUserPreference
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static saveToUserPreference: MemoizedSelector<CoreAppState, boolean> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    (state: NavigationMenuState) => state.saveToUserPreference,
  );

  /**
   * defaultSettingsMenuItemRoute
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof NavigationMenuSelectors
   */
  public static defaultSettingsMenuItemRoute: MemoizedSelector<CoreAppState, string> = createSelector(
    NavigationMenuSelectors.loadDefaultRoute,
    NavigationMenuSelectors.availableAdministratorsMenuItems,
    NavigationMenuSelectors.availableSettingsMenuItems,
    (loadDefaultRoute: boolean, availableAdministratorsMenuItems: MenuItem[], availableSettingsMenuItems: MenuItem[]) => {
      return selectDefaultMenuItemRoute(loadDefaultRoute, [...availableAdministratorsMenuItems, ...availableSettingsMenuItems]);
    },
  );

  /**
   * showAutomationNavigationMenu
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static showAutomationNavigationMenu: MemoizedSelector<CoreAppState, boolean> = createSelector(
    FeatureSelectors.hasAutomationReadOnlyPerm,
    UserPreferenceTrialInfoSelectors.isTrialApplicable,
    (hasAutomationReadOnlyPerm: boolean, isTrialApplicable: boolean) => {
      return hasAutomationReadOnlyPerm || isTrialApplicable;
    },
  );

  /**
   * showDashboardNavigationMenu
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static showDashboardNavigationMenu: MemoizedSelector<CoreAppState, boolean> = createSelector(
    FeatureSelectors.hasDashboardReadOnlyPerm,
    FeatureSelectors.hasDashboardManagePerm,
    UserPreferenceTrialInfoSelectors.isTrialApplicable,
    (hasDashboardReadOnlyPerm: boolean, hasDashboardManagePerm: boolean, isTrialApplicable: boolean) => {
      return hasDashboardReadOnlyPerm || hasDashboardManagePerm || isTrialApplicable;
    },
  );

  /**
   * showSolutionNavigationMenu
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static showSolutionNavigationMenu: MemoizedSelector<CoreAppState, boolean> = createSelector(
    FeatureSelectors.hasDashboardReadOnlyPerm,
    UserPreferenceTrialInfoSelectors.isTrialApplicable,
    UserPreferenceFeatureControlsSelectors.isSolutionsEnabled,
    (hasDashboardReadOnlyPerm: boolean, isTrialApplicable: boolean, isSolutionsEnabled: boolean) => {
      return !!(hasDashboardReadOnlyPerm || isTrialApplicable) && !!isSolutionsEnabled;
    },
  );

  /**
   * showWorkspaceSecurityMenu
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static showWorkspaceSecurityMenu: MemoizedSelector<CoreAppState, boolean> = createSelector(
    FeatureSelectors.hasDashboardReadOnlyPerm,
    UserPreferenceFeatureControlsSelectors.isUEMEnabled,
    UserPreferenceFeatureControlsSelectors.isSolutionsCveEnabled,
    (hasDashboardReadOnlyPerm: boolean, isUEMEnabled: boolean, isSolutionsCveEnabled: boolean) => {
      return !!(hasDashboardReadOnlyPerm && (isUEMEnabled || isSolutionsCveEnabled));
    },
  );

  /**
   * showIntegrationsNavigationMenu
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static showIntegrationsNavigationMenu: MemoizedSelector<CoreAppState, boolean> = createSelector(
    NavigationMenuSelectors.availableIntegrationsMenuItems,
    (menuItems: MenuItem[]) => !!menuItems.length,
  );

  /**
   * showSettingsNavigationMenu
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static showSettingsNavigationMenu: MemoizedSelector<CoreAppState, boolean> = createSelector(
    NavigationMenuSelectors.availableSettingsMenuItems,
    (menuItems: MenuItem[]) => !!menuItems.length,
  );

  /**
   * getVisibleNavigationMenuItems
   * @static
   * @type {MemoizedSelector<CoreAppState, NavigationMenuItem[]>}
   * @memberof NavigationMenuSelectors
   */
  public static getVisibleNavigationMenuItems: MemoizedSelector<CoreAppState, NavigationMenuItem[]> = createSelector(
    NavigationMenuSelectors.showIntegrationsNavigationMenu,
    NavigationMenuSelectors.showSettingsNavigationMenu,
    (showIntegrations: boolean, showSettings: boolean) => [
      NavigationMenuItem.WORKSPACE,
      NavigationMenuItem.MARKETPLACE,
      ...(showIntegrations ? [NavigationMenuItem.INTEGRATIONS] : []),
      ...(showSettings ? [NavigationMenuItem.SETTINGS] : []),
    ],
  );

  /**
   * showBaseHeaderActions
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static showBaseHeaderActions: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isNonAdminMode,
    UserPreferenceSelectors.isRemediationMode,
    (isNonAdminMode: boolean, isRemediationMode: boolean): boolean => !isNonAdminMode && !isRemediationMode,
  );

  /**
   * isTrialNotActiveForIntroType
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof NavigationMenuSelectors
   */
  public static isTrialNotActiveForIntroType = (introType: IntroType): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      UserPreferenceTrialInfoSelectors.isTrialNotActive,
      UserPreferenceFeatureControlsSelectors.isDashboardsEnabled,
      UserPreferenceFeatureControlsSelectors.isAutomationEnabled,
      (isTrialNotActive: boolean, isDashboardsEnabled: boolean, isAutomationEnabled: boolean) => {
        switch (introType) {
          case IntroType.REPORT:
            return false;
          case IntroType.AUTOMATION:
            return isTrialNotActive && !isAutomationEnabled;
          case IntroType.DASHBOARD:
            return isTrialNotActive && !isDashboardsEnabled;
          default:
            return isTrialNotActive;
        }
      },
    );

  /**
   * getExpandedNavMenuGroups
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, NavigationMenuGroup[]>}
   * @memberof NavigationMenuSelectors
   */
  public static getExpandedNavMenuGroups: MemoizedSelector<CoreAppState, NavigationMenuGroup[]> = createSelector(
    NavigationMenuSelectors.selectNavigationMenuState,
    (state: NavigationMenuState) => state.expandedNavGroups,
  );
}

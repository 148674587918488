/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChartDrilldownEvent, Trend, TrendResult } from '@ws1c/intelligence-models';

/**
 * isEditRangeAvailable
 * @export
 * @param {boolean} isEnabled
 * @param {ChartDrilldownEvent[]} drilldownEvents
 * @param {Trend} trend
 * @returns {boolean}
 */
export function isEditRangeAvailable(isEnabled: boolean, drilldownEvents: ChartDrilldownEvent[], trend: Trend): boolean {
  return !!(
    isEnabled &&
    !drilldownEvents?.length &&
    trend?.trendDefinition?.bucketingAttributes?.length &&
    trend?.trendDefinition.hasV2Attributes &&
    trend?.trendResults?.some((trendResult: TrendResult) => trendResult.counters[0].result.value > 0)
  );
}

<div
  *ngIf="ngxChart?.chartData?.length; else noData"
  (resized)="onResize()"
  class="chart-container"
>
  <!--
    Tree Map valueFormatting can only work on Angular 9 with disable animations. Refer to:
    https://github.com/swimlane/ngx-charts/issues/1514
  -->
  <ngx-charts-tree-map
    [scheme]="colorScheme"
    [results]="ngxChart.chartData"
    [animations]="true"
    [labelFormatting]="labelFormatting"
    [valueFormatting]="valueFormatting"
    [tooltipDisabled]="showSameSizeCell"
    [customColors]="ngxChart.customColors"
    (select)="onSelect($event)"
  >
    <ng-template
      #tooltipTemplate
      let-model="model"
    >
      <ng-template
        *ngIf="model"
        [ngTemplateOutlet]="stackedTooltip"
        [ngTemplateOutletContext]="{
          ngxModel: model,
          tooltip: ngxChart.getTooltip(model),
          total: ngxChart?.rootNode.value
        }"
      ></ng-template>
    </ng-template>
  </ngx-charts-tree-map>
</div>

<ng-template #noData>
  <div class="no-data-container">
    <dpa-no-search-results
      [message]="noDataMessage || 'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"
      [tooltipText]="noDataTooltip"
    ></dpa-no-search-results>
  </div>
</ng-template>

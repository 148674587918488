/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { IntegratedServicesResponse, LOAD_STATE, ServiceMode } from '@ws1c/intelligence-models';
import { IntegratedServicesActions } from './integrated-services.actions';
import { initialIntegratedServicesState, IntegratedServicesState } from './integrated-services.store';

/**
 * _reducer
 * @type {ActionReducer<IntegratedServicesState, Action>}
 */
const _reducer: ActionReducer<IntegratedServicesState, Action> = createReducer(
  initialIntegratedServicesState,

  on(IntegratedServicesActions.getIntegratedServices, (state: IntegratedServicesState) => {
    return Object.assign({}, state, {
      ...initialIntegratedServicesState,
      isLoadingSKUServices: true,
      integratedServicesLoadState: LOAD_STATE.IN_FLIGHT,
    } as IntegratedServicesState);
  }),

  on(
    IntegratedServicesActions.getIntegratedServicesSuccess,
    (state: IntegratedServicesState, props: { integratedServicesData: IntegratedServicesResponse }) => {
      return Object.assign({}, state, {
        integratedServicesData: props.integratedServicesData,
        isLoadingSKUServices: false,
        integratedServicesLoadState: LOAD_STATE.SUCCESS,
      } as IntegratedServicesState);
    },
  ),

  on(IntegratedServicesActions.getIntegratedServicesFailure, (state: IntegratedServicesState) => {
    return Object.assign({}, state, {
      isLoadingSKUServices: false,
      integratedServicesLoadState: LOAD_STATE.FAILURE,
    } as IntegratedServicesState);
  }),

  on(IntegratedServicesActions.setActiveServiceMode, (state: IntegratedServicesState, props: { activeServiceMode: ServiceMode }) => {
    return Object.assign({}, state, {
      activeServiceMode: props.activeServiceMode,
    } as IntegratedServicesState);
  }),
);

/**
 * Creates and returns IntegratedServicesState
 * @export
 * @param {IntegratedServicesState} state - Holds state object of type IntegratedServicesState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {IntegratedServicesState}
 */
export function integratedServicesState(state: IntegratedServicesState, action: Action): IntegratedServicesState {
  return _reducer(state, action);
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { FuzzySubgroupConfig, PagedRequest } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import {
  getSubgroupConfig,
  groupCategoriesByIntegration,
} from '@ws1c/intelligence-core/store/integration-meta/integration-meta-selector-helpers';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta/integration-meta.selectors';
import { UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store/user-preference';
import {
  Category,
  Column,
  CustomReport,
  CustomReportPreviewSearchResponse,
  IntegrationCategories,
  ReportTemplate,
  ROUTE_NAMES,
  Trend,
  TrendMode,
  WidgetDetailDefinition,
  WizardDialogMode,
} from '@ws1c/intelligence-models';
import { getReportColumnsFromNames, isValidCustomReportData } from './report-common-selector-helper';
import { ReportCommonState } from './report-common.store';

/**
 * Get only categories that support Reporting
 *
 * @export
 * @param {Category[]} categories
 * @returns {Category[]}
 */
function getAvailableReportEntities(categories: Category[]): Category[] {
  return categories.filter((category: Category) => category.supportsReporting);
}

/**
 * ReportCommonSelectors
 *
 * @export
 * @class ReportCommonSelectors
 */
export class ReportCommonSelectors {
  public static selectReportCommonState = (state: CoreAppState) => state.reportCommonState;

  /**
   * getSelectedTemplate
   * @static
   * @type {MemoizedSelector<CoreAppState, ReportTemplate>}
   * @memberof ReportCommonSelectors
   */
  public static getSelectedTemplate: MemoizedSelector<CoreAppState, ReportTemplate> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.selectedTemplate,
  );

  /**
   * getReportCategoriesByIntegration
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberof ReportCommonSelectors
   */
  public static getReportCategoriesByIntegration: MemoizedSelector<CoreAppState, IntegrationCategories[]> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    (categories: Category[]) => {
      const reportEntities = getAvailableReportEntities(categories);
      return groupCategoriesByIntegration(reportEntities);
    },
  );

  /**
   * getReportSubgroupConfig
   * @static
   * @type {MemoizedSelector<CoreAppState, FuzzySubgroupConfig>}
   * @memberof ReportCommonSelectors
   */
  public static getReportSubgroupConfig: MemoizedSelector<CoreAppState, FuzzySubgroupConfig> = createSelector(
    ReportCommonSelectors.getReportCategoriesByIntegration,
    getSubgroupConfig,
  );

  /**
   * isSinglePageWizard
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ReportCommonSelectors
   */
  public static isSinglePageWizard: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state?.reportWizardMode === WizardDialogMode.CREATE_FROM_OTHER_SOURCE,
  );

  /**
   * getReportWidgetTrend
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof ReportCommonSelectors
   */
  public static getReportWidgetTrend: MemoizedSelector<CoreAppState, Trend> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.reportWidgetTrend,
  );

  /**
   * getReportWidgetDetail
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDetailDefinition>}
   * @memberof ReportCommonSelectors
   */
  public static getReportWidgetDetail: MemoizedSelector<CoreAppState, WidgetDetailDefinition> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.reportWidgetDetail,
  );

  /**
   * getReportWidgetReturnPath
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof ReportCommonSelectors
   */
  public static getReportWidgetReturnPath: MemoizedSelector<CoreAppState, string> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.reportWidgetReturnPath,
  );

  /**
   * getCustomReportDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReport>}
   * @memberof ReportCommonSelectors
   */
  public static getCustomReportDetails: MemoizedSelector<CoreAppState, CustomReport> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.customReportDetails,
  );

  /**
   * isReportFilterValid
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ReportCommonSelectors
   */
  public static isReportFilterValid: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.isValidFilter,
  );

  /**
   * getPreviewPageRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, PagedRequest>}
   * @memberof ReportCommonSelectors
   */
  public static getPreviewPageRequest: MemoizedSelector<CoreAppState, PagedRequest> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.previewPageRequest,
  );

  /**
   * getPreviewRefreshedAt
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof ReportCommonSelectors
   */
  public static getPreviewRefreshedAt: MemoizedSelector<CoreAppState, number> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.refreshedAt,
  );

  /**
   * getCustomReportPreview
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof ReportCommonSelectors
   */
  public static getCustomReportPreview: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.preview,
  );

  /**
   * getSelectedReportCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof ReportCommonSelectors
   */
  public static getSelectedReportCategoryId: MemoizedSelector<CoreAppState, string> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.selectedCategoryId,
  );

  /**
   * getDefaultCustomReportDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReport>}
   * @memberof ReportCommonSelectors
   */
  public static getDefaultCustomReportDetails: MemoizedSelector<CoreAppState, CustomReport> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.defaultCustomReportDetails,
  );

  /**
   * getCustomReportColumnNames
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof ReportCommonSelectors
   */
  public static getCustomReportColumnNames: MemoizedSelector<CoreAppState, string[]> = createSelector(
    ReportCommonSelectors.getCustomReportDetails,
    (customReportDetails: CustomReport) => customReportDetails?.columnNames ?? [],
  );

  /**
   * getReportColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof ReportCommonSelectors
   */
  public static getReportColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    ReportCommonSelectors.getCustomReportColumnNames,
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    getReportColumnsFromNames,
  );

  /**
   * isLoadingReportPreview
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ReportCommonSelectors
   */
  public static isLoadingReportPreview: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ReportCommonSelectors.selectReportCommonState,
    (state: ReportCommonState) => state.loadingPreview,
  );

  /**
   * snapshotOptionEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ReportCommonSelectors
   */
  public static snapshotOptionEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.getActiveCategory,
    (category: Category) => (category ? category.supportedTrendModes.includes(TrendMode[TrendMode.SNAPSHOT]) : false),
  );

  /**
   * historicalOptionEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ReportCommonSelectors
   */
  public static historicalOptionEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.getActiveCategory,
    (category: Category) => (category ? !category.supportedTrendModes.includes(TrendMode[TrendMode.SNAPSHOT]) : false),
  );

  /**
   * isPredefinedReport
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ReportCommonSelectors
   */
  public static isPredefinedReport: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ReportCommonSelectors.getCustomReportDetails,
    (customReportDetails: CustomReport) => customReportDetails?.isPredefinedReport,
  );

  /**
   * isValidReportState
   * @static
   * @type {MemoizedSelector<ReportState, boolean>}
   * @memberof ReportCommonSelectors
   */
  public static isValidReportState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ReportCommonSelectors.getCustomReportDetails,
    isValidCustomReportData,
  );

  /**
   * getAddRoute
   * @static
   * @type {MemoizedSelector<ReportState, string>}
   * @memberof ReportCommonSelectors
   */
  public static getAddRoute: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceFeatureControlsSelectors.isReportBuilderV2Enabled,
    (isReportBuilderV2Enabled: boolean) =>
      isReportBuilderV2Enabled ? `/${ROUTE_NAMES.REPORT.HOME}/${ROUTE_NAMES.REPORT.ADD_REPORT_V2}` : `/${ROUTE_NAMES.REPORT.ADD_REPORT}`,
  );
}

/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Application Model Object for SuggestedApp
 *
 * @export
 * @class SuggestedApp
 */
@Serializable
export class SuggestedApp {
  @JsonProperty('package_id')
  public packageId: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('platform')
  public platform: string = undefined;

  @JsonProperty('apteligent_app_id')
  public apteligentAppId?: string = undefined;
}

/**
 * Model Object for suggested unregistered apps for Apteligent
 *
 * @export
 * @class suggestedAppsList
 */
@Serializable
export class SuggestedAppsList {
  @JsonProperty({ name: 'data', cls: SuggestedApp })
  public data: SuggestedApp[] = undefined;
}

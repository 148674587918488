/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { DataDecorator, DateTimeFormat, FromNowPipe, GenericObject, TrendSpan } from '@dpa/ui-common';
import moment from 'moment';

import { filterEmptyRules } from '@ws1c/intelligence-common';
import {
  AppPlatform,
  COLUMN_NAMES,
  CustomReportPreviewSearchResponse,
  CustomReportSearchResponse,
  DashboardConfig,
  deepClone,
  DevicesDesktopErrorDetailsPage,
  EdiType,
  FilterRule,
  PropertyValuePair,
  RawThresholdAttribute,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  ThresholdLevel,
  ThresholdsByAttribute,
  TrendDateRange,
  TrendResultValuePairType,
} from '@ws1c/intelligence-models';

/**
 * buildDrilldownMergedDashboardRequest
 * @export
 * @param {StandardDashboardType} dashboardType
 * @param {RuleGroup} filterRuleGroup
 * @param {TrendDateRange} trendDateRange
 * @returns {StandardDashboardRequest}
 */
export function buildDrilldownMergedDashboardRequest(
  dashboardType: StandardDashboardType,
  filterRuleGroup: RuleGroup,
  trendDateRange: TrendDateRange,
): StandardDashboardRequest {
  const request = new StandardDashboardRequest(dashboardType, filterRuleGroup, trendDateRange);
  filterEmptyRules(filterRuleGroup);
  return request;
}

/**
 * getPerformanceThresholdsAdditionalParams
 * @param {ThresholdsByAttribute} thresholdsByAttribute
 * @returns {GenericObject}
 */
export function getPerformanceThresholdsAdditionalParams(thresholdsByAttribute: ThresholdsByAttribute): GenericObject {
  if (!thresholdsByAttribute) {
    return;
  }
  return {
    boot_warning_threshold: getThresholdValue(thresholdsByAttribute, RawThresholdAttribute.BOOT_TIME, ThresholdLevel.WARNING),
    boot_critical_threshold: getThresholdValue(thresholdsByAttribute, RawThresholdAttribute.BOOT_TIME, ThresholdLevel.CRITICAL),
    login_warning_threshold: getThresholdValue(thresholdsByAttribute, RawThresholdAttribute.LOGIN_TIME, ThresholdLevel.WARNING),
    login_critical_threshold: getThresholdValue(thresholdsByAttribute, RawThresholdAttribute.LOGIN_TIME, ThresholdLevel.CRITICAL),
    performance_warning_threshold: getThresholdValue(thresholdsByAttribute, RawThresholdAttribute.SHUTDOWN_TIME, ThresholdLevel.WARNING),
    performance_critical_threshold: getThresholdValue(thresholdsByAttribute, RawThresholdAttribute.SHUTDOWN_TIME, ThresholdLevel.CRITICAL),
  };
}

/**
 * getThresholdValue
 * @param  {ThresholdsByAttribute} thresholdsByAttribute
 * @param  {RawThresholdAttribute}    thresholdAttribute
 * @param  {ThresholdLevel}        thresholdLevel
 * @returns {number}
 */
export function getThresholdValue(
  thresholdsByAttribute: ThresholdsByAttribute,
  thresholdAttribute: RawThresholdAttribute,
  thresholdLevel: ThresholdLevel,
): number {
  const value = thresholdsByAttribute[thresholdAttribute].values[thresholdLevel];
  const unit = thresholdsByAttribute[thresholdAttribute].unit;
  return value * TrendSpan.timeUnitsByName[unit].value;
}

/**
 * getDesktopErrorDetailsStandardDashboardRequest
 * @export
 * @param {DevicesDesktopErrorDetailsPage} devicesDesktopErrorDetailsPage
 * @param {TrendDateRange} trendDateRange
 * @returns {StandardDashboardRequest}
 */
export function getDesktopErrorDetailsStandardDashboardRequest(
  devicesDesktopErrorDetailsPage: DevicesDesktopErrorDetailsPage,
  trendDateRange: TrendDateRange,
): StandardDashboardRequest {
  const filterRules =
    devicesDesktopErrorDetailsPage.platform === AppPlatform.WINDOWS_DESKTOP
      ? FilterRule.listFromKeyValue({
          [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_module]: devicesDesktopErrorDetailsPage.module,
          [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_error]: devicesDesktopErrorDetailsPage.error,
          [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_process]: devicesDesktopErrorDetailsPage.process,
        })
      : FilterRule.listFromKeyValue({
          [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_process]: devicesDesktopErrorDetailsPage.process,
          [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_crash_path]: devicesDesktopErrorDetailsPage.crashPath,
        });
  return new StandardDashboardRequest(StandardDashboardType.DEVICES_DESKTOP_ERROR_DETAILS, new RuleGroup(filterRules), trendDateRange);
}

/**
 * getFormattedDate
 * @exports
 * @param {number} epoch
 * @returns {string}
 */
export function getFormattedDate(epoch: number): string {
  let formatted = 'COMMON_MESSAGES.UNKNOWN_VALUE';
  if (epoch) {
    formatted = moment(epoch).format(DateTimeFormat.MOMENT_DATE_FORMAT);
  }
  return formatted;
}

/**
 * getFormattedValue
 * @exports
 * @param {string} value
 * @param {TrendResultValuePairType} type
 * @returns {string}
 */
export function getFormattedValue(value: any, type: DataDecorator): string {
  let valueFormatted: string;
  switch (type) {
    case DataDecorator.ACTIVE_INACTIVE:
      valueFormatted = value ? 'COMMON_MESSAGES.ACTIVE' : 'COMMON_MESSAGES.INACTIVE';
      break;
    case DataDecorator.DATETIME:
      valueFormatted = getFormattedDate(value);
      break;
    case DataDecorator.SIZE_BYTE:
    case DataDecorator.STRING_PERCENT:
      valueFormatted = value || undefined;
      break;
    case DataDecorator.YES_NO:
      valueFormatted = value ? 'COMMON_MESSAGES.YES' : 'COMMON_MESSAGES.NO';
      break;
    default:
      valueFormatted = value || 'COMMON_MESSAGES.UNKNOWN_VALUE';
  }
  return valueFormatted;
}

/**
 * getPropertiesColumnMap
 * @exports
 * @param {CustomReportPreviewSearchResponse} response
 * @param {GenericObject} columnMap
 * @returns {CustomReportSearchResponse}
 */
export function getPropertiesColumnMap(response: CustomReportPreviewSearchResponse, columnMap: GenericObject): CustomReportSearchResponse {
  const keys = Object.keys(columnMap);
  const formattedResponse = new CustomReportPreviewSearchResponse({
    from: 0,
    size: 10,
    total: keys.length,
  });
  if (response?.results.length) {
    keys.forEach((item: string) => {
      let value: string;
      let type: string;
      columnMap[item].forEach((col: GenericObject) => {
        value = response.results[0][col.attribute];
        type = col.type;
      });
      formattedResponse.results.push({ item: getFullDeviceProfilePropertyNameI18nKey(item), value, type } as PropertyValuePair);
    });
  }
  return formattedResponse;
}

/**
 * getEdiFormattedValue
 * @exports
 * @param {string} item
 * @param {string} value
 * @returns {string|number}
 */
export function getEdiFormattedValue(item: string, value: string): string | number {
  let rawValue: any;
  switch (item) {
    case EdiType.BIOS:
      const bios = value.trim().split(DashboardConfig.EdiLineItemDelimitor);
      const version = bios[0];
      const time = moment(Number(bios[1])).format(DateTimeFormat.MOMENT_DATE_FORMAT);
      rawValue = bios[1] === 'undefined' ? version : version + DashboardConfig.EdiLineItemSeparator + time;
      break;
    case EdiType.CPU_USAGE:
    case EdiType.MEMORY:
      rawValue = Number(value) || 0;
      break;
    default:
      rawValue = value;
  }
  return rawValue;
}

/**
 * convertDeviceHygieneToAttrObject
 * @param {Array<Array<string | number>>} info
 * @returns {PropertyValuePair[]}
 */
export function convertDeviceHygieneToAttrObject(info: Array<Array<string | number>>): PropertyValuePair[] {
  let copy = deepClone(info);
  [
    { key: EdiType.LAST_BOOT_EVENT, key2: EdiType.TIME_SINCE_LAST_BOOT },
    { key: EdiType.ENROLLMENT_DATE, key2: EdiType.ENROLLMENT_AGE },
    { key: EdiType.LAST_LOGON_DATE, key2: EdiType.DAYS_SINCE_LAST_LOGON },
    { key: EdiType.LAST_OS_UPDATE_DATE, key2: EdiType.DAYS_SINCE_LAST_OS_UPDATE },
  ].forEach((duration: GenericObject) => {
    copy = addFromNowByDate(copy, duration);
  });
  return copy.map((item: any[]) => {
    const key = item[0].split('.')[2];
    return {
      item: getFullDeviceProfilePropertyNameI18nKey(key),
      value: key === EdiType.AVAILABLE_LOGICAL_DISK_SPACE.toUpperCase() ? item[1] * 1024 * 1024 : item[1],
      type: item[3],
    } as PropertyValuePair;
  });
}

/**
 * addFromNowByDate
 * @param {Array<Array<string | number>>} info
 * @param {GenericObject} duration
 * @returns {any[]}
 */
export function addFromNowByDate(info: Array<Array<string | number>>, duration: GenericObject): Array<Array<string | number>> {
  info.forEach((each: any[], index: number) => {
    if (each[0].split('.')[2] === duration.key.toUpperCase()) {
      const fromNow = new FromNowPipe();
      const dateDiffItem = [
        `STANDARD_DASHBOARD.DEVICES_DETAILS.${duration.key2.toUpperCase()}`,
        each[2] > 0 ? fromNow.transform(each[2]) : 'COMMON_MESSAGES.UNKNOWN_VALUE',
        each[2],
        TrendResultValuePairType.STRING,
      ];
      info.splice(index + 2, 0, dateDiffItem);
    }
  });
  return info;
}

/**
 * getDeviceProfilePropertyName
 * @param {string} devicePropertyNameI18nKey
 * @returns {string}
 */
function getFullDeviceProfilePropertyNameI18nKey(devicePropertyNameI18nKey: string): string {
  if (!devicePropertyNameI18nKey) {
    return;
  }
  return 'STANDARD_DASHBOARD.DEVICES_DETAILS.' + devicePropertyNameI18nKey.toUpperCase();
}

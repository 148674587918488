/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { FormControl, FormGroup, FormRecord, Validators } from '@angular/forms';
import { GenericObject } from '@dpa/ui-common';
import { CustomValidators } from '@ws1c/intelligence-common';

import { DeemPlaybookStepItemType } from '@ws1c/intelligence-models/deem/deem-playbook-step-item-type.enum';
import {
  DeemPlaybookStepItemDeviceTimeline,
  DeemPlaybookStepItemImage,
  DeemPlaybookStepItemLink,
  DeemPlaybookStepItemRca,
  DeemPlaybookStepItemReport,
  DeemPlaybookStepItemVideo,
  DeemPlaybookStepItemWidget,
} from '@ws1c/intelligence-models/deem/deem-playbook-step-items';
import { DeemPlaybookStep } from '@ws1c/intelligence-models/deem/deem-playbook-step.model';

/**
 * DeemPlaybooksConfig
 * @export
 * @class DeemPlaybooksConfig
 */
export class DeemPlaybooksConfig {
  public static readonly MAX_PLAYBOOK_STEPS = 20;
  public static readonly MAX_PLAYBOOK_STEP_ITEMS = 20;

  public static readonly iconShapeByStepItem: Record<DeemPlaybookStepItemType, string> = {
    [DeemPlaybookStepItemType.WIDGET]: 'layers',
    [DeemPlaybookStepItemType.REPORT]: 'tasks',
    [DeemPlaybookStepItemType.LINK]: 'link',
    [DeemPlaybookStepItemType.IMAGE]: 'image',
    [DeemPlaybookStepItemType.VIDEO]: 'play',
    [DeemPlaybookStepItemType.QUICK_ACTION]: 'dpa-workflow',
    [DeemPlaybookStepItemType.RCA]: 'target',
    [DeemPlaybookStepItemType.DEVICE_TIMELINE]: 'devices',
  } as const;

  /**
   * getEmptyStepItemForm
   * @static
   * @param {DeemPlaybookStepItemType} stepItemType
   * @returns {FormRecord}
   * @memberof DeemPlaybooksConfig
   */
  public static getEmptyStepItemForm = (stepItemType: DeemPlaybookStepItemType): FormRecord =>
    new FormRecord({
      type: new FormControl<DeemPlaybookStepItemType>(stepItemType),
    });

  /**
   * formatStepFormData
   * @static
   * @param {GenericObject} stepFormData
   * @param {number} stepOrder
   * @memberof DeemPlaybooksConfig
   * @returns {DeemPlaybookStep}
   */
  public static formatStepFormData(stepFormData: GenericObject, stepOrder?: number): DeemPlaybookStep {
    const components = stepFormData.components.map((component: GenericObject) => {
      switch (component.type) {
        case DeemPlaybookStepItemType.LINK:
          return new DeemPlaybookStepItemLink(component);
        case DeemPlaybookStepItemType.VIDEO:
          return new DeemPlaybookStepItemVideo(component);
        case DeemPlaybookStepItemType.IMAGE:
          return new DeemPlaybookStepItemImage(component);
        case DeemPlaybookStepItemType.DEVICE_TIMELINE:
          return new DeemPlaybookStepItemDeviceTimeline(component);
        case DeemPlaybookStepItemType.RCA:
          return new DeemPlaybookStepItemRca(component);
        case DeemPlaybookStepItemType.WIDGET:
          return new DeemPlaybookStepItemWidget({
            ...component.componentDetails,
            name: component.name,
            description: component.description,
            type: component.type,
            useIncidentFilter: component.useIncidentFilter,
          });
        case DeemPlaybookStepItemType.REPORT:
          return new DeemPlaybookStepItemReport({
            ...component.componentDetails,
            name: component.name,
            description: component.description,
            type: component.type,
            useIncidentFilter: component.useIncidentFilter,
          });
      }
    });

    return new DeemPlaybookStep({
      ...stepFormData,
      components,
      stepOrder,
    });
  }

  /**
   * getComponentForm
   * @static
   * @param {DeemPlaybookStepItemType} stepItemType
   * @returns {FormGroup}
   * @memberof DeemPlaybooksConfig
   */
  public static getComponentForm(stepItemType: DeemPlaybookStepItemType): FormGroup {
    switch (stepItemType) {
      case DeemPlaybookStepItemType.LINK:
        return new FormGroup({
          type: new FormControl<DeemPlaybookStepItemType>(stepItemType),
          description: new FormControl(''),
          displayText: new FormControl(''),
          url: new FormControl('', [Validators.required, CustomValidators.urlValidator()]),
        });
      case DeemPlaybookStepItemType.IMAGE:
        return new FormGroup({
          type: new FormControl<DeemPlaybookStepItemType>(stepItemType),
          description: new FormControl(''),
          url: new FormControl('', [Validators.required, CustomValidators.urlValidator()]),
        });

      case DeemPlaybookStepItemType.VIDEO:
        return new FormGroup({
          type: new FormControl<DeemPlaybookStepItemType>(stepItemType),
          description: new FormControl(''),
          displayText: new FormControl(''),
          url: new FormControl('', [Validators.required, CustomValidators.urlValidator()]),
          embeddedIframe: new FormControl('', CustomValidators.iframeValidator()),
        });

      case DeemPlaybookStepItemType.WIDGET:
      case DeemPlaybookStepItemType.REPORT:
        return new FormGroup({
          type: new FormControl<DeemPlaybookStepItemType>(stepItemType),
          name: new FormControl('', Validators.required),
          description: new FormControl(''),
          useIncidentFilter: new FormControl(false, Validators.required),
          componentDetails: new FormControl({}, Validators.required),
        });

      case DeemPlaybookStepItemType.DEVICE_TIMELINE:
      case DeemPlaybookStepItemType.RCA:
        return new FormGroup({
          type: new FormControl<DeemPlaybookStepItemType>(stepItemType),
          description: new FormControl(''),
        });
    }
  }
}

/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  AcceptEula,
  AcceptTrial,
  IntegrationDetails,
  IntelOrg,
  IntroModalMode,
  IntroType,
  LOAD_STATE,
  PendoMetadata,
  TrialBannerState,
  UISettings,
  User,
  UserAccount,
  UserPreference,
} from '@ws1c/intelligence-models';

/**
 * UserPreferenceActions
 * @export
 * @class UserPreferenceActions
 */
export class UserPreferenceActions {
  public static readonly CATEGORY = '[USER_PREFERENCE]';

  private static type = (actionName: string): string => `${UserPreferenceActions.CATEGORY} ${actionName}`;

  public static acknowledgeIntroPageVisitPreference = createAction(
    `${UserPreferenceActions.CATEGORY} ACKNOWLEDGE_INTRO_PAGE_VISIT_PREFERENCE`,
  );

  public static setCurrentIntroPage = createAction(
    `${UserPreferenceActions.CATEGORY} SET_CURRENT_INTRO_PAGE`,
    props<{ introType: IntroType }>(),
  );

  public static getEula = createAction(`${UserPreferenceActions.CATEGORY} GET_EULA`);
  public static getEulaSuccess = createAction(`${UserPreferenceActions.CATEGORY} GET_EULA_SUCCESS`, props<{ eulaHtml: string }>());
  public static getEulaFailure = createAction(`${UserPreferenceActions.CATEGORY} GET_EULA_FAILURE`, props<{ error: WebError }>());

  public static getTrialUserContactDetails = createAction(`${UserPreferenceActions.CATEGORY} GET_TRIAL_USER_CONTACT_DETAILS`);
  public static getTrialUserContactDetailsSuccess = createAction(
    `${UserPreferenceActions.CATEGORY} GET_TRIAL_USER_CONTACT_DETAILS_SUCCESS`,
    props<{ userContactDetails: AcceptTrial }>(),
  );
  public static getTrialUserContactDetailsFailure = createAction(
    `${UserPreferenceActions.CATEGORY} GET_TRIAL_USER_CONTACT_DETAILS_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static getUISettingsSuccess = createAction(
    `${UserPreferenceActions.CATEGORY} GET_UI_SETTINGS_SUCCESS`,
    props<{ uiSettings: UISettings }>(),
  );
  public static getUISettingsFailure = createAction(`${UserPreferenceActions.CATEGORY} GET_UI_SETTINGS_FAILURE`);

  public static cancelEula = createAction(`${UserPreferenceActions.CATEGORY} CANCEL_EULA`);
  public static cancelEulaSuccess = createAction(`${UserPreferenceActions.CATEGORY} CANCEL_EULA_SUCCESS`);
  public static acceptEula = createAction(`${UserPreferenceActions.CATEGORY} ACCEPT_EULA`, props<{ formData: AcceptEula }>());
  public static acceptEulaSuccess = createAction(`${UserPreferenceActions.CATEGORY} ACCEPT_EULA_SUCCESS`);
  public static acceptEulaFailure = createAction(`${UserPreferenceActions.CATEGORY} ACCEPT_EULA_FAILURE`, props<{ error: WebError }>());
  public static acceptEulaRedirect = createAction(`${UserPreferenceActions.CATEGORY} ACCEPT_EULA_REDIRECT`);

  public static acceptTrial = createAction(`${UserPreferenceActions.CATEGORY} ACCEPT_TRIAL`, props<{ formData: AcceptTrial }>());
  public static acceptTrialSuccess = createAction(`${UserPreferenceActions.CATEGORY} ACCEPT_TRIAL_SUCCESS`);
  public static acceptTrialFailure = createAction(`${UserPreferenceActions.CATEGORY} ACCEPT_TRIAL_FAILURE`, props<{ error: WebError }>());

  public static declineTrialFailure = createAction(`${UserPreferenceActions.CATEGORY} DECLINE_TRIAL_FAILURE`, props<{ error: WebError }>());
  public static setTrialBannerState = createAction(
    `${UserPreferenceActions.CATEGORY} SET_TRIAL_BANNER_STATE`,
    props<{ trialBannerState: TrialBannerState }>(),
  );

  public static loadUserPreference = createAction(`${UserPreferenceActions.CATEGORY} LOAD_USER_PREFERENCE`);
  public static loadUserPreferenceSuccess = createAction(
    `${UserPreferenceActions.CATEGORY} LOAD_USER_PREFERENCE_SUCCESS`,
    props<{ userPreference: UserPreference }>(),
  );
  public static refreshUserPreference = createAction(
    `${UserPreferenceActions.CATEGORY} REFRESH_USER_PREFERENCE`,
    props<{ redirectRoute: string }>(),
  );

  public static setUserPreferenceLoadState = createAction(
    `${UserPreferenceActions.CATEGORY} SET_USER_PREFERENCE_LOAD_STATE`,
    props<{ loadState: LOAD_STATE }>(),
  );

  public static setIntroModalMode = createAction(
    `${UserPreferenceActions.CATEGORY} SET_INTRO_MODAL_MODE`,
    props<{ introModalMode: IntroModalMode }>(),
  );
  public static closeIntroModal = createAction(`${UserPreferenceActions.CATEGORY} CLOSE_INTRO_MODAL`);

  public static removeActiveInstances = createAction(
    `${UserPreferenceActions.CATEGORY} REMOVE_ACTIVE_INSTANCES`,
    props<{ integration: string }>(),
  );
  public static insertActiveInstances = createAction(
    `${UserPreferenceActions.CATEGORY} INSERT_ACTIVE_INSTANCES`,
    props<{ integration: string; details: GenericObject }>(),
  );

  public static setInitialUrlQueryParams = createAction(
    `${UserPreferenceActions.CATEGORY} SET_INITIAL_URL_QUERY_PARAMS`,
    props<{ initialUrlQueryParams: GenericObject }>(),
  );

  public static setNonAdminMode = createAction(
    `${UserPreferenceActions.CATEGORY} SET_NON_ADMIN_MODE`,
    props<{ isNonAdminMode: boolean }>(),
  );
  public static setIsRemediationMode = createAction(
    `${UserPreferenceActions.CATEGORY} SET_IS_REMEDIATION_MODE`,
    props<{ isRemediationMode: boolean }>(),
  );

  public static startFeature = createAction(`${UserPreferenceActions.CATEGORY} START_FEATURE`);

  public static uiPreferencesChangeSuccess = createAction(
    `${UserPreferenceActions.CATEGORY} UI_PREFERENCES_CHANGE_SUCCESS`,
    props<{ preferenceKeys: string[] }>(),
  );
  public static uiPreferencesChangeFailure = createAction(
    `${UserPreferenceActions.CATEGORY} UI_PREFERENCES_CHANGE_FAILURE`,
    props<{ preferenceKeys: string[]; error: WebError }>(),
  );

  public static updateUISettings = createAction(
    `${UserPreferenceActions.CATEGORY} UPDATE_UI_SETTINGS`,
    props<{ uiSettings: GenericObject }>(),
  );
  public static updateUISettingsSuccess = createAction(
    `${UserPreferenceActions.CATEGORY} UPDATE_UI_SETTINGS_SUCCESS`,
    props<{ uiSettings: UISettings }>(),
  );

  public static navigateToCookiePolicy = createAction(`${UserPreferenceActions.CATEGORY} NAVIGATE_TO_COOKIE_POLICY`);

  // Pendo
  public static getPendoMetadata = createAction(`${UserPreferenceActions.CATEGORY} GET_PENDO_METADATA`);
  public static setPendoMetadata = createAction(
    `${UserPreferenceActions.CATEGORY} SET_PENDO_METADATA`,
    props<{ metadata: PendoMetadata }>(),
  );
  public static updatePendoMetadata = createAction(
    `${UserPreferenceActions.CATEGORY} UPDATE_PENDO_METADATA`,
    props<{ metadata: PendoMetadata }>(),
  );

  public static activateIntegrationInstance = createAction(
    `${UserPreferenceActions.CATEGORY} ACTIVATE_INTEGRATION_INSTANCE`,
    props<{ integrationDetails: IntegrationDetails }>(),
  );

  public static deactivateIntegrationInstance = createAction(
    `${UserPreferenceActions.CATEGORY} DEACTIVATE_INTEGRATION_INSTANCE`,
    props<{ integrationDetails: IntegrationDetails }>(),
  );

  public static updateOptinFeatureFlag = createAction(
    `${UserPreferenceActions.CATEGORY} UPDATE_OPTIN_FEATURE_FLAG`,
    props<{ flagName: string; value: boolean }>(),
  );

  public static refreshUserAccount = createAction(`${UserPreferenceActions.CATEGORY} REFRESH_USER_ACCOUNT`);

  public static refreshUserAccountSuccess = createAction(
    `${UserPreferenceActions.CATEGORY} REFRESH_USER_ACCOUNT_SUCCESS`,
    props<{ userAccount: UserAccount }>(),
  );

  public static refreshMyAccountInfo = createAction(`${UserPreferenceActions.CATEGORY} REFRESH_MY_ACCOUNT_INFO`);

  public static refreshMyAccountInfoSuccess = createAction(
    `${UserPreferenceActions.CATEGORY} REFRESH_MY_ACCOUNT_INFO_SUCCESS`,
    props<{ user: User }>(),
  );

  public static addBasicInfoToProfile = createAction(
    `${UserPreferenceActions.CATEGORY} ADD_BASIC_INFO_TO_PROFILE`,
    props<{ userAccount: UserAccount }>(),
  );

  public static navigateToHomePage = createAction(`${UserPreferenceActions.CATEGORY} NAVIGATE_TO_HOME_PAGE`);

  public static updateFeatureFlag = createAction(
    `${UserPreferenceActions.CATEGORY} UPDATE_FEATURE_FLAG`,
    props<{
      flagName: string;
      value: boolean;
    }>(),
  );

  public static readonly setBrownfieldNotificationModalOpenState = createAction(
    `${UserPreferenceActions.CATEGORY} SET_BROWNFIELD_NOTIFICATION_MODAL_OPEN_STATE`,
    props<{ isOpen: boolean }>(),
  );

  public static readonly setRequestCloudAccountModalOpenState = createAction(
    `${UserPreferenceActions.CATEGORY} SET_REQUEST_CLOUD_ACCOUNT_MODAL_OPEN_STATE`,
    props<{ isOpen: boolean }>(),
  );

  public static readonly updateBrownfieldNotificationModalOpenStateBasedOnSessionStorage = createAction(
    `${UserPreferenceActions.CATEGORY} UPDATE_BROWNFIELD_NOTIFICATION_MODAL_OPEN_STATE_BASED_ON_SESSION_STORAGE`,
  );

  public static getUserOrgs = createAction(UserPreferenceActions.type('GET_USER_ORGS'));
  public static getUserOrgsSuccess = createAction(UserPreferenceActions.type('GET_USER_ORGS_SUCCESS'), props<{ userOrgs: IntelOrg[] }>());
  public static getUserOrgsFailure = createAction(UserPreferenceActions.type('GET_USER_ORGS_FAILURE'));
}

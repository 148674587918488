/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Action, createReducer, on } from '@ngrx/store';

import { LOAD_STATE, OptInFeatureFlag } from '@ws1c/intelligence-models';
import { OrgActions } from './org.actions';
import { initialOrgState, OrgState } from './org.state';

const _orgReducer = createReducer(
  initialOrgState,
  on(
    OrgActions.openConfirmEnableFeatureModal,
    (state: OrgState, props: { feature: OptInFeatureFlag }): OrgState => ({
      ...state,
      currentOptingFeature: props.feature,
      showConfirmEnableFeatureModal: true,
    }),
  ),
  on(
    OrgActions.closeConfirmEnableFeatureModal,
    (state: OrgState): OrgState => ({
      ...state,
      currentOptingFeature: undefined,
      showConfirmEnableFeatureModal: false,
    }),
  ),
  on(
    OrgActions.completeOnboardingStep,
    (state: OrgState): OrgState => ({
      ...state,
      isCompletingOnboardingStep: true,
    }),
  ),
  on(
    OrgActions.completeOnboardingStepSuccess,
    (state: OrgState): OrgState => ({
      ...state,
      isCompletingOnboardingStep: false,
    }),
  ),
  on(
    OrgActions.abortOnboardingStep,
    (state: OrgState): OrgState => ({
      ...state,
      isAbortingOnboardingStep: true,
    }),
  ),
  on(
    OrgActions.abortOnboardingStepSuccess,
    (state: OrgState): OrgState => ({
      ...state,
      isAbortingOnboardingStep: false,
    }),
  ),
  on(
    OrgActions.enableFeature,
    OrgActions.disableFeature,
    (state: OrgState): OrgState => ({
      ...state,
      currentOperationInProgress: true,
    }),
  ),
  on(
    OrgActions.enableFeatureSuccess,
    OrgActions.disableFeatureSuccess,
    OrgActions.enableFeatureFailure,
    OrgActions.disableFeatureFailure,
    (state: OrgState): OrgState => ({
      ...state,
      currentOperationInProgress: false,
    }),
  ),
  on(
    OrgActions.openConfirmDisableFeatureModal,
    (state: OrgState, props: { feature: OptInFeatureFlag }): OrgState => ({
      ...state,
      currentOptingFeature: props.feature,
      showConfirmDisableFeatureModal: true,
    }),
  ),
  on(
    OrgActions.closeConfirmDisableFeatureModal,
    (state: OrgState): OrgState => ({
      ...state,
      currentOptingFeature: undefined,
      showConfirmDisableFeatureModal: false,
    }),
  ),
  on(
    OrgActions.setCurrentFeature,
    (state: OrgState, props: { feature: OptInFeatureFlag }): OrgState => ({
      ...state,
      currentOptingFeature: props.feature,
    }),
  ),
  on(
    OrgActions.getOrgSettingsSuccess,
    (state: OrgState, action: ReturnType<typeof OrgActions.getOrgSettingsSuccess>): OrgState => ({
      ...state,
      orgSettings: {
        ...action.orgSettings,
      },
    }),
  ),
  on(
    OrgActions.orgPreferencesChangeSuccess,
    (state: OrgState): OrgState => ({
      ...state,
      orgPreferenceUpdateState: LOAD_STATE.SUCCESS,
    }),
  ),
  on(
    OrgActions.orgPreferencesChangeFailure,
    (state: OrgState): OrgState => ({
      ...state,
      orgPreferenceUpdateState: LOAD_STATE.FAILURE,
    }),
  ),
  on(
    OrgActions.updateOrgSettings,
    (state: OrgState, action: ReturnType<typeof OrgActions.updateOrgSettings>): OrgState => ({
      ...state,
      currentOrgPreferenceKeys: Object.keys(action.orgPreferences),
      orgPreferenceUpdateState: LOAD_STATE.IN_FLIGHT,
    }),
  ),
  on(
    OrgActions.loadOrgHierarchySuccess,
    (state: OrgState, action: ReturnType<typeof OrgActions.loadOrgHierarchySuccess>): OrgState => ({
      ...state,
      orgHierachy: action.orgTreeNode,
    }),
  ),
  on(
    OrgActions.loadOrgHierarchyFailure,
    (state: OrgState): OrgState => ({
      ...state,
      orgHierachy: null,
    }),
  ),
  on(
    OrgActions.setOrgDisabledConfig,
    (state: OrgState, { orgDisabledConfig }: ReturnType<typeof OrgActions.setOrgDisabledConfig>): OrgState => ({
      ...state,
      orgDisabledConfig,
    }),
  ),
  on(
    OrgActions.toggleServiceOptInModal,
    (state: OrgState, action: ReturnType<typeof OrgActions.toggleServiceOptInModal>): OrgState => ({
      ...state,
      isServiceOptInModalOpen: action.isOpen,
    }),
  ),
);

/**
 * Org State Reducer
 * @param {OrgState} state
 * @param {Action} action
 * @returns {OrgState}
 */
export function orgState(state: OrgState, action: Action) {
  return _orgReducer(state, action);
}

/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { App, COLUMN_NAMES, Integration, SuggestionFilterBy } from '@ws1c/intelligence-models';

export const helpers = {
  getSuggestionFilterBys,
};

/**
 * getSuggestionFilterBys
 * @param {App} app
 * @param {string} integration
 * @returns {SuggestionFilterBy[]}
 */
export function getSuggestionFilterBys(app: App, integration: string): SuggestionFilterBy[] {
  if (integration === Integration.AIRWATCH) {
    return [
      new SuggestionFilterBy({ fieldName: COLUMN_NAMES.byFullyQualifiedName.airwatch_application_app_package_id, values: [app.id] }),
      new SuggestionFilterBy({ fieldName: COLUMN_NAMES.byFullyQualifiedName.airwatch_application_device_platform, values: [app.platform] }),
    ];
  }
  if (integration === Integration.APTELIGENT) {
    return [
      new SuggestionFilterBy({ fieldName: COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_app_id, values: [app.apteligentAppId] }),
    ];
  }
  return [];
}

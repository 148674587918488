<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="false"
  [activeEntries]="activeEntries"
  [animations]="true"
>
  <svg:g [attr.transform]="transform">
    <svg:g
      *ngIf="xAxis"
      [xScale]="xScale"
      [dims]="dims"
      [showGridLines]="showGridLines"
      [showLabel]="showXAxisLabel"
      [trimTicks]="trimXAxisTicks"
      [rotateTicks]="rotateXAxisTicks"
      [maxTickLength]="maxXAxisTickLength"
      [tickFormatting]="xAxisTickFormatting"
      [ticks]="xAxisTicks"
      [xAxisOffset]="dataLabelMaxHeight.negative"
      [wrapTicks]="wrapTicks"
      (dimensionsChanged)="updateXAxisHeight($event)"
      ngx-charts-x-axis
    ></svg:g>
    <!-- Y Axis Left -->
    <svg:g
      *ngIf="yAxis"
      [yScale]="yScale"
      [dims]="dims"
      [showGridLines]="showGridLines"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength"
      [tickFormatting]="yAxisTickFormatting"
      [ticks]="yAxisTicks"
      [wrapTicks]="wrapTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"
      ngx-charts-y-axis
    ></svg:g>
    <!-- Y Axis Right -->
    <svg:g
      *ngIf="yAxis"
      [yScale]="yScaleOverlay"
      [dims]="dims"
      [yOrient]="yOrientRight"
      [showGridLines]="false"
      [showLabel]="showYAxisOverlayLabel"
      [labelText]="yAxisRightLabel"
      [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength"
      [tickFormatting]="yAxisTickFormatting"
      [ticks]="yAxisTicks"
      [wrapTicks]="wrapTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"
      ngx-charts-y-axis
    ></svg:g>
    <!-- Line main -->
    <svg:g [attr.clip-path]="clipPath">
      <svg:g *ngIf="!isSSR">
        <svg:g *ngFor="let series of results; trackBy: trackBy">
          <svg:g
            [xScale]="xScale"
            [yScale]="yScale"
            [colors]="colors"
            [data]="series"
            [activeEntries]="activeEntries"
            [scaleType]="scaleType"
            [curve]="curve"
            [hasRange]="hasRange"
            [animations]="true"
            [rangeFillOpacity]="rangeFillOpacity"
            [attr.opacity]="mainChartOpacity"
            ngx-charts-line-series
          ></svg:g>
        </svg:g>
      </svg:g>
      <svg:g *ngIf="isSSR">
        <svg:g *ngFor="let series of results; trackBy: trackBy">
          <svg:g
            [xScale]="xScaleOverlay"
            [yScale]="yScaleOverlay"
            [colors]="colors"
            [data]="series"
            [activeEntries]="activeEntries"
            [scaleType]="scaleType"
            [curve]="curve"
            [rangeFillOpacity]="rangeFillOpacity"
            [hasRange]="hasRange"
            [animations]="true"
            ngx-charts-line-series
          ></svg:g>
        </svg:g>
      </svg:g>
    </svg:g>
  </svg:g>
  <!-- Line overlay -->
  <svg:g
    [attr.clip-path]="clipPath"
    [attr.transform]="transform"
  >
    <svg:g *ngIf="!isSSR">
      <svg:g *ngFor="let series of overlayResults; trackBy: trackBy">
        <svg:g
          [xScale]="xScaleOverlay"
          [yScale]="yScaleOverlay"
          [colors]="colorsForOverlay"
          [data]="series"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [hasRange]="hasRange"
          [animations]="true"
          [rangeFillOpacity]="rangeFillOpacity"
          [attr.opacity]="overlayOpacity"
          ngx-charts-line-series
        ></svg:g>
      </svg:g>
    </svg:g>
    <svg:g *ngIf="isSSR">
      <svg:g *ngFor="let series of overlayResults; trackBy: trackBy">
        <svg:g
          [xScale]="xScaleOverlay"
          [yScale]="yScaleOverlay"
          [colors]="colorsForOverlay"
          [data]="series"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [rangeFillOpacity]="rangeFillOpacity"
          [hasRange]="hasRange"
          [animations]="true"
          ngx-charts-line-series
        ></svg:g>
      </svg:g>
    </svg:g>
    <!-- Line overlay chart tooltip -->
    <svg:g
      *ngIf="!tooltipDisabled"
      (mouseleave)="hideCircles()"
    >
      <svg:g
        [dims]="dims"
        [xSet]="xOverlaySet"
        [xScale]="xScaleOverlay"
        [yScale]="yScaleOverlay"
        [results]="combinedSeries"
        [colors]="colorsForOverlay"
        [tooltipDisabled]="false"
        [tooltipTemplate]="overlayTooltip"
        (hover)="onHover($event)"
        ngx-charts-tooltip-area
      ></svg:g>
      <svg:g *ngFor="let series of overlayResults; trackBy: trackBy">
        <svg:g
          [xScale]="xScaleOverlay"
          [yScale]="yScaleOverlay"
          [colors]="colorsForOverlay"
          [data]="series"
          [scaleType]="scaleType"
          [visibleValue]="!!hoveredSeriesName"
          [activeEntries]="activeEntries"
          [tooltipDisabled]="tooltipDisabled"
          [tooltipTemplate]="overlayTooltip"
          (select)="onClick($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          ngx-charts-circle-series
        ></svg:g>
      </svg:g>
    </svg:g>
  </svg:g>
</ngx-charts-chart>

<ng-template #overlayTooltip>
  <ng-template
    [ngTemplateOutlet]="tooltipTemplate"
    [ngTemplateOutletContext]="{ tooltipItems: widgetHoverInfo, seriesName: hoveredSeriesName }"
  ></ng-template>
</ng-template>

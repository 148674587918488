<div
  *ngIf="ngxChart"
  (resized)="onResize()"
  class="chart-container"
>
  <ngx-charts-bar-vertical-stacked
    *ngIf="isStacked"
    #chart
    [scheme]="ngxChart.defaultColorScheme"
    [results]="data"
    [legend]="false"
    [xAxisLabel]="ngxChart.labels.firstGroupBy"
    [yAxisLabel]="yAxisLabel"
    [gradient]="false"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="!!yAxisLabel"
    [animations]="true"
    [rotateXAxisTicks]="rotateXAxisTicks"
    [maxXAxisTickLength]="maxXAxisTickLength"
    [trimXAxisTicks]="true"
    [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
    [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
    [customColors]="ngxChart.customColors"
    [showDataLabel]="showDataLabel"
    [dataLabelFormatting]="ngxChart.labels.counterFormatter"
    [yScaleMax]="yScaleMax"
    (select)="onSelect($event)"
  >
    <ng-template
      #tooltipTemplate
      let-model="model"
    >
      <ng-template
        *ngIf="model"
        [ngTemplateOutlet]="stackedTooltip"
        [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
      ></ng-template>
    </ng-template>
  </ngx-charts-bar-vertical-stacked>
  <ngx-charts-bar-vertical-2d
    *ngIf="!isStacked"
    #chart
    [scheme]="ngxChart.defaultColorScheme"
    [results]="data"
    [legend]="false"
    [xAxisLabel]="ngxChart.labels.firstGroupBy"
    [yAxisLabel]="yAxisLabel"
    [gradient]="false"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="!!yAxisLabel"
    [animations]="true"
    [rotateXAxisTicks]="rotateXAxisTicks"
    [maxXAxisTickLength]="maxXAxisTickLength"
    [trimXAxisTicks]="true"
    [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
    [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
    [customColors]="ngxChart.customColors"
    [showDataLabel]="showDataLabel"
    [dataLabelFormatting]="ngxChart.labels.counterFormatter"
    [yScaleMax]="yScaleMax"
    [groupPadding]="8"
    [barPadding]="8"
    (select)="onSelect($event)"
  >
    <ng-template
      #tooltipTemplate
      let-model="model"
    >
      <ng-template
        *ngIf="model"
        [ngTemplateOutlet]="stackedTooltip"
        [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
      ></ng-template>
    </ng-template>
  </ngx-charts-bar-vertical-2d>
</div>

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { MetaForm, metaFormConverter, ServicePermission } from '@ws1c/intelligence-models';

/**
 * EventForwarderTemplate
 * @export
 * @class EventForwarderTemplate
 */
@Serializable
export class EventForwarderTemplate {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('setup_info_url')
  public setupInfoUrl: string = undefined;

  @JsonProperty({ name: 'service_permissions', cls: ServicePermission })
  public servicePermissions: ServicePermission[] = [];

  @JsonProperty({ name: 'event_forwarder_config_metadata', customConverter: metaFormConverter })
  public metaForm: MetaForm = undefined;

  /**
   * Creates an instance of EventForwarderTemplate
   * @param {Array<Partial<EventForwarderTemplate>>} args
   */
  constructor(...args: Array<Partial<EventForwarderTemplate>>) {
    Object.assign(this, ...args);
  }
}

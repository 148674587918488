/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { IntroModalMode } from '@ws1c/intelligence-models';
import { UserPreferenceCommonSelectors } from './user-preference-common.selectors';
import { UserPreferenceState } from './user-preference.state';

/**
 * UserPreferenceUserConfigSelectors
 *
 * @export
 * @class UserPreferenceUserConfigSelectors
 */
export class UserPreferenceUserConfigSelectors {
  /**
   * uiSettingsPreferences
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof UserPreferenceSelectors
   */
  public static uiSettingsPreferences: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.uiSettings?.preferences ?? {},
  );

  /**
   * currentUISettingsKeys
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof UserPreferenceUserConfigSelectors
   */
  public static currentUISettingsKeys: MemoizedSelector<CoreAppState, string[]> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.currentUISettingsKeys,
  );

  /**
   * isUpdatingUISettings
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUserConfigSelectors
   */
  public static isUpdatingUISettings: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isUpdatingUISettings,
  );

  /**
   * getIntroModalMode
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, IntroModalMode>}
   * @memberof UserPreferenceUserConfigSelectors
   */
  public static getIntroModalMode: MemoizedSelector<CoreAppState, IntroModalMode> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.introModalMode,
  );

  /**
   * getIntroModalOpen
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUserConfigSelectors
   */
  public static getIntroModalOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceUserConfigSelectors.getIntroModalMode,
    (modalMode: IntroModalMode) => {
      return [IntroModalMode.OPEN, IntroModalMode.ACTIVATE_TRIAL, IntroModalMode.ACTIVATING_TRIAL, IntroModalMode.ACTIVATED_TRIAL].includes(
        modalMode,
      );
    },
  );

  /**
   * isStartingFeature
   *
   * @param {string} uiSettingKey
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceUserConfigSelectors
   */
  public static isStartingFeature = (uiSettingKey: string): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      UserPreferenceUserConfigSelectors.currentUISettingsKeys,
      UserPreferenceUserConfigSelectors.isUpdatingUISettings,
      (currentUISettingsKeys: string[], isUpdatingUISettings: boolean) => {
        return isUpdatingUISettings && currentUISettingsKeys.includes(uiSettingKey);
      },
    );
}

/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, PagedResponse, SortOn, WebError } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { keyBy } from 'lodash-es';

import { AppLoadsSelectors } from '@dpa-shared-merlot/store/app-loads';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import {
  getDefaultedPagedRequest,
  getFilterSortPagedResponse,
  getFirstCounterValue,
  getWidgetsDefaultTableColumnNames,
} from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import {
  AllHostTab,
  AppPlatform,
  Category,
  Column,
  COLUMN_NAMES,
  ColumnIndex,
  CustomReportPreviewSearchResponse,
  DashboardConfig,
  Entity,
  FilterRule,
  getFQN,
  getUniqueId,
  Integration,
  NetEventsLogTabName,
  NetworkInsightsErrorTab,
  NetworkInsightsSearchRequest,
  NetworkInsightsSearchResponse,
  PreviewReportContentRequest,
  QueryBuilder,
  RuleGroup,
  StandardDashboardRequest,
  StandardWidgetSubtype,
  TrendDateRange,
} from '@ws1c/intelligence-models';
import { AppsDashboardSelectors } from './apps-dashboard.selectors';
import { AppsDashboardState } from './apps-dashboard.state';
import * as helpers from './network-insights-selector-helpers';

export const networkInsightHelpers = {
  ...helpers,
  getDefaultedPagedRequest,
  getFilterSortPagedResponse,
  getWidgetsDefaultTableColumnNames,
  getFirstCounterValue,
};

/**
 * NetworkInsightsSelectors
 * @export
 * @class NetworkInsightsSelectors
 */
export class NetworkInsightsSelectors {
  /**
   * getNetworkInsightsViewingHistorical
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsViewingHistorical: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.viewingHistorical,
  );

  /**
   * getNetworkInsightsSortOns
   * @static
   * @type {MemoizedSelector<MerlotState, SortOn[]>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsSortOns: MemoizedSelector<MerlotState, SortOn[]> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) =>
      state.networkInsights.sortOns || [
        new SortOn({
          by: COLUMN_NAMES.byField.totalErrorCount,
          reverse: true,
        }),
      ],
  );

  /**
   * getNetworkInsightsFilter
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsFilter: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.filter || '',
  );

  /**
   * getNetworkInsightsPagedRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PagedRequest>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsPagedRequest: MemoizedSelector<MerlotState, PagedRequest> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => networkInsightHelpers.getDefaultedPagedRequest(state.networkInsights.tablePagedRequest),
  );

  /**
   * getNetworkInsightsTableSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, NetworkInsightsSearchResponse>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsTableSearchResponse: MemoizedSelector<MerlotState, NetworkInsightsSearchResponse> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.tableSearchResponse,
  );

  /**
   * getNetworkInsightsTableSearchError
   * @static
   * @type {MemoizedSelector<MerlotState, WebError>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsTableSearchError: MemoizedSelector<MerlotState, WebError> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.tableSearchError,
  );

  /**
   * getNetworkInsightsPageSize
   * @static
   * @type {MemoizedSelector<MerlotState, number>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsPageSize: MemoizedSelector<MerlotState, number> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => {
      const request = networkInsightHelpers.getDefaultedPagedRequest(state.networkInsights.tablePagedRequest);
      return request.size;
    },
  );

  /**
   * getNetworkInsightsUrlsResponse
   * @static
   * @type {MemoizedSelector<MerlotState, PagedResponse>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsUrlsResponse: MemoizedSelector<MerlotState, PagedResponse> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => {
      const response = state.networkInsights.urlsResponse;
      return { results: response && response.getSortedUrlResponse().results } as PagedResponse;
    },
  );

  /**
   * getNetworkInsightsLogModalIsOpen
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogModalIsOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.logModalIsOpen,
  );

  /**
   * getNetworkInsightsLogSelectedTabName
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogSelectedTabName: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.logSelectedTabName,
  );

  /**
   * getNetworkInsightsLogSortOns
   * @static
   * @type {MemoizedSelector<MerlotState, SortOn[]>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogSortOns: MemoizedSelector<MerlotState, SortOn[]> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.logSortOns,
  );

  /**
   * getNetworkInsightsLogPagedRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PagedRequest>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogPagedRequest: MemoizedSelector<MerlotState, PagedRequest> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) =>
      state.networkInsights.logPagedRequest ||
      new PagedRequest({
        from: 0,
        size: 10,
      }),
  );

  /**
   * getNetworkInsightsLogSearchQuery
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogSearchQuery: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.logSearchQuery,
  );

  /**
   * getNetworkInsightsLogResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.logResponse,
  );

  /**
   * getNetworkInsightsLogError
   * @static
   * @type {MemoizedSelector<MerlotState, WebError>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogError: MemoizedSelector<MerlotState, WebError> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.logError,
  );

  /**
   * getFilterSortedNetworkInsightsTableSearch
   * @static
   * @type {MemoizedSelector<MerlotState, PagedResponse>}
   * @memberof NetworkInsightsSelectors
   */
  public static getFilterSortedNetworkInsightsTableSearch: MemoizedSelector<MerlotState, PagedResponse> = createSelector(
    NetworkInsightsSelectors.getNetworkInsightsTableSearchResponse,
    NetworkInsightsSelectors.getNetworkInsightsFilter,
    NetworkInsightsSelectors.getNetworkInsightsSortOns,
    NetworkInsightsSelectors.getNetworkInsightsPagedRequest,
    (response: NetworkInsightsSearchResponse, filterString: string, sortOns: SortOn[], request: NetworkInsightsSearchRequest) => {
      return networkInsightHelpers.getFilterSortPagedResponse(response, filterString, sortOns, request, 'requestUrlHost');
    },
  );

  /**
   * getNetworkInsightsUrlsModalIsOpen
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsUrlsModalIsOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.getNetworkInsightsUrlsModalHostUrl,
    (url: string) => !!url,
  );

  /**
   *
   * getNetworkInsightsViewingHistoricalWithoutGroupbyOrErrortab
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsViewingHistoricalWithoutGroupbyOrErrortab: MemoizedSelector<MerlotState, boolean> = createSelector(
    NetworkInsightsSelectors.getNetworkInsightsViewingHistorical,
    AppsDashboardSelectors.getNetworkInsightsGroupBy,
    AppsDashboardSelectors.getNetworkInsightsErrorTab,
    AppsDashboardSelectors.getNetworkInsightsAllHostTab,
    (isViewingHistorical: boolean, groupBy: string, errorTab: string, allHostTab: AllHostTab) => {
      const isViewingByErrorTab = allHostTab === AllHostTab.HTTP_ERRORS && errorTab === NetworkInsightsErrorTab.BY_ERROR;
      return !groupBy && !isViewingByErrorTab && isViewingHistorical;
    },
  );

  /**
   * getAllHostsOverviewTitleKey
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof NetworkInsightsSelectors
   */
  public static getAllHostsOverviewTitleKey: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.getNetworkInsightsAllHostTab,
    (allHostTab: AllHostTab) => DashboardConfig.networkInsightsChartTitleKeysByAllHostTab[AllHostTab[allHostTab]],
  );

  /**
   * getVisibleGroupBySubtypes
   * @static
   * @type {MemoizedSelector<MerlotState, StandardWidgetSubtype[]>}
   * @memberof NetworkInsightsSelectors
   */
  public static getVisibleGroupBySubtypes: MemoizedSelector<MerlotState, StandardWidgetSubtype[]> = createSelector(
    AppsDashboardSelectors.getNetworkInsightsAllHostTab,
    (allHostTab: AllHostTab) => {
      const visibleTrendsByTab = {
        [AllHostTab.HTTP_ERRORS]: [
          StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_ERROR_BY_APP_VERSION,
          StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_ERROR_BY_SYSTEM_VERSION,
          StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_ERROR_BY_CARRIER,
          StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_ERROR_BY_DEVICE_MODEL,
        ],
        [AllHostTab.NUMBER_OF_CALLS]: [
          StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_COUNT_BY_APP_VERSION,
          StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_COUNT_BY_SYSTEM_VERSION,
          StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_COUNT_BY_CARRIER,
          StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_COUNT_BY_DEVICE_MODEL,
        ],
        [AllHostTab.ERROR_RATE]: [
          StandardWidgetSubtype.__NETWORK_INSIGHTS_ERROR_RATE_BY_APP_VERSION,
          StandardWidgetSubtype.__NETWORK_INSIGHTS_ERROR_RATE_BY_SYSTEM_VERSION,
          StandardWidgetSubtype.__NETWORK_INSIGHTS_ERROR_RATE_BY_CARRIER,
          StandardWidgetSubtype.__NETWORK_INSIGHTS_ERROR_RATE_BY_DEVICE_MODEL,
        ],
        [AllHostTab.AVG_LATENCY]: [
          StandardWidgetSubtype.NETWORK_INSIGHTS_AVG_LATENCY_BY_APP_VERSION,
          StandardWidgetSubtype.NETWORK_INSIGHTS_AVG_LATENCY_BY_SYSTEM_VERSION,
          StandardWidgetSubtype.NETWORK_INSIGHTS_AVG_LATENCY_BY_CARRIER,
          StandardWidgetSubtype.NETWORK_INSIGHTS_AVG_LATENCY_BY_DEVICE_MODEL,
        ],
        [AllHostTab.BYTES_RECEIVED]: [
          StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_RECEIVED_BY_APP_VERSION,
          StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_RECEIVED_BY_SYSTEM_VERSION,
          StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_RECEIVED_BY_CARRIER,
          StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_RECEIVED_BY_DEVICE_MODEL,
        ],
        [AllHostTab.BYTES_SENT]: [
          StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_SENT_BY_APP_VERSION,
          StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_SENT_BY_SYSTEM_VERSION,
          StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_SENT_BY_CARRIER,
          StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_SENT_BY_DEVICE_MODEL,
        ],
      };
      return visibleTrendsByTab[allHostTab];
    },
  );

  /**
   * getNetworkInsightsErrorTabSubtype
   * @static
   * @type {MemoizedSelector<MerlotState, StandardWidgetSubtype>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsErrorTabSubtype: MemoizedSelector<MerlotState, StandardWidgetSubtype> = createSelector(
    AppsDashboardSelectors.getNetworkInsightsErrorTab,
    NetworkInsightsSelectors.getNetworkInsightsViewingHistoricalWithoutGroupbyOrErrortab,
    AppLoadsSelectors.getSelectedAppPlatform,
    (errorTab: string, isViewingHistorical: boolean, appPlatform: string) => {
      if (errorTab === NetworkInsightsErrorTab.BY_TOTAL) {
        return isViewingHistorical
          ? StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_ERROR_BOTH_PERIODS
          : StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_ERROR;
      }
      return appPlatform === AppPlatform.ANDROID
        ? StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT_ANDROID
        : StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT_IOS;
    },
  );

  /**
   * getAllHostsOverviewTrendSubtype
   * @static
   * @type {MemoizedSelector<MerlotState, StandardWidgetSubtype>}
   * @memberof NetworkInsightsSelectors
   */
  public static getAllHostsOverviewTrendSubtype: MemoizedSelector<MerlotState, StandardWidgetSubtype> = createSelector(
    AppsDashboardSelectors.getNetworkInsightsAllHostTab,
    NetworkInsightsSelectors.getNetworkInsightsViewingHistoricalWithoutGroupbyOrErrortab,
    NetworkInsightsSelectors.getNetworkInsightsErrorTabSubtype,
    (allHostTab: AllHostTab, isViewingHistorical: boolean, networkInsightsErrorTabSubtype: StandardWidgetSubtype) => {
      if (allHostTab === AllHostTab.HTTP_ERRORS) {
        return networkInsightsErrorTabSubtype;
      }
      const standardWidgetSubtypeByAllHostTab = {
        [AllHostTab.NUMBER_OF_CALLS]: StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL,
        [AllHostTab.ERROR_RATE]: StandardWidgetSubtype.__NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL,
        [AllHostTab.AVG_LATENCY]: StandardWidgetSubtype.NETWORK_INSIGHTS_AVG_LATENCY,
        [AllHostTab.BYTES_RECEIVED]: StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_RECEIVED,
        [AllHostTab.BYTES_SENT]: StandardWidgetSubtype.NETWORK_INSIGHTS_BYTES_SENT,
      };
      const historicalStandardWidgetSubtypeIndex = {
        [AllHostTab.NUMBER_OF_CALLS]: StandardWidgetSubtype.__NETWORK_INSIGHTS_TOTAL_BOTH_PERIODS,
        [AllHostTab.ERROR_RATE]: StandardWidgetSubtype.__NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL_BOTH_PERIODS,
        [AllHostTab.AVG_LATENCY]: StandardWidgetSubtype.__NETWORK_INSIGHTS_AVG_LATENCY_BOTH_PERIODS,
        [AllHostTab.BYTES_RECEIVED]: StandardWidgetSubtype.__NETWORK_INSIGHTS_BYTES_RECEIVED_BOTH_PERIODS,
        [AllHostTab.BYTES_SENT]: StandardWidgetSubtype.__NETWORK_INSIGHTS_BYTES_SENT_BOTH_PERIODS,
      };
      return isViewingHistorical ? historicalStandardWidgetSubtypeIndex[allHostTab] : standardWidgetSubtypeByAllHostTab[allHostTab];
    },
  );

  /**
   * getNetworkInsightsEventsCategory
   * @static
   * @type {MemoizedSelector<MerlotState, Category>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsEventsCategory: MemoizedSelector<MerlotState, Category> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    (availableCategories: Category[]) => {
      const targetCategoryId = getUniqueId(Integration.APTELIGENT, Entity.NET_EVENT);
      return availableCategories.find((category: Category) => category.categoryId === targetCategoryId);
    },
  );

  /**
   * getNetworkInsightsErrorsCategory
   * @static
   * @type {MemoizedSelector<MerlotState, Category>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsErrorsCategory: MemoizedSelector<MerlotState, Category> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    (availableCategories: Category[]) => {
      const targetCategoryId = getUniqueId(Integration.APTELIGENT, Entity.NET_ERROR);
      return availableCategories.find((category: Category) => category.categoryId === targetCategoryId);
    },
  );

  /**
   * getNetworkInsightsSubFilterColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsSubFilterColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    AppsDashboardSelectors.getNetworkInsightsHost,
    IntegrationMetaSelectors.getColumnsByCategory,
    NetworkInsightsSelectors.getNetworkInsightsEventsCategory,
    (networkInsightsHost: string, columnsByCategory: Map<Category, Column[]>, networkInsightsCategory: Category) => {
      return networkInsightHelpers.getNetworkInsightsSubFilterColumns(networkInsightsHost, columnsByCategory, networkInsightsCategory);
    },
  );

  /**
   * getStandardAppNetworkInsightsDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof NetworkInsightsSelectors
   */
  public static getStandardAppNetworkInsightsDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getNetworkInsightsHost,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    AppsDashboardSelectors.getNetworkInsightsSubFilterRuleGroup,
    (
      appId: string,
      apteligentId: string,
      appPlatform: string,
      hostName: string,
      appDashboardFilterRuleGroup: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
      networkInsightsSubFilterRuleGroup: RuleGroup,
    ) => {
      return networkInsightHelpers.buildStandardAppNetworkInsightsDashboardRequest(
        appId,
        apteligentId,
        appPlatform,
        hostName,
        appDashboardFilterRuleGroup,
        appDashboardTrendDateRange,
        networkInsightsSubFilterRuleGroup,
      );
    },
  );

  /**
   * getNetworkInsightsTrendDefinitionOverridesByStandardWidgetSubtype
   * @static
   * @type {MemoizedSelector<MerlotState, any>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsTrendDefinitionOverridesByStandardWidgetSubtype: MemoizedSelector<MerlotState, any> = createSelector(
    AppsDashboardSelectors.getNetworkInsightsGroupBy,
    (subtype: string) => networkInsightHelpers.getNetworkInsightsTrendDefinitionOverridesByStandardWidgetSubtype(subtype),
  );

  /**
   * getSelectedApteligentIdFilterString
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof NetworkInsightsSelectors
   */
  public static getSelectedApteligentIdFilterString: MemoizedSelector<MerlotState, string> = createSelector(
    AppLoadsSelectors.getSelectedApteligentId,
    (apteligentId: string) => {
      const appIdFilterRule = new FilterRule({
        attribute: getFQN(Integration.APTELIGENT, Entity.NET_EVENT, COLUMN_NAMES.byName.app_id),
        condition: '=',
        data: [apteligentId],
      });
      const queryBuilder = new QueryBuilder();
      queryBuilder.group.rules = [appIdFilterRule];
      return queryBuilder.getQueryString();
    },
  );

  /**
   * getNetworkInsightsTableSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, NetworkInsightsSearchRequest>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsTableSearchRequest: MemoizedSelector<MerlotState, NetworkInsightsSearchRequest> = createSelector(
    NetworkInsightsSelectors.getSelectedApteligentIdFilterString,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (apteligentIdFilterString: string, trendDateRange: TrendDateRange) => {
      return networkInsightHelpers.getNetworkInsightsTableSearchRequest(apteligentIdFilterString, trendDateRange);
    },
  );

  /**
   * getNetworkInsightsSearchableColumnNames
   * @static
   * @type {MemoizedSelector<MerlotState, string[]>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsSearchableColumnNames: MemoizedSelector<MerlotState, string[]> = createSelector(
    NetworkInsightsSelectors.getNetworkInsightsLogSelectedTabName,
    networkInsightHelpers.getNetworkInsightsSearchableColumnNames,
  );

  /**
   * getNetworkLogsTableColumnsByName
   * @static
   * @type {MemoizedSelector<MerlotState, ColumnIndex>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkLogsTableColumnsByName: MemoizedSelector<MerlotState, ColumnIndex> = createSelector(
    NetworkInsightsSelectors.getNetworkInsightsLogSelectedTabName,
    NetworkInsightsSelectors.getNetworkInsightsEventsCategory,
    NetworkInsightsSelectors.getNetworkInsightsErrorsCategory,
    IntegrationMetaSelectors.getColumnsByCategory,
    (selectedTabName: string, eventsCategory: Category, errorsCategory: Category, columnsByCategory: Map<Category, Column[]>) => {
      const categorySelectorByTabName = {
        [NetEventsLogTabName.NET_EVENT]: eventsCategory,
        [NetEventsLogTabName.NET_ERROR]: errorsCategory,
      };
      const category = categorySelectorByTabName[selectedTabName];
      const columns = columnsByCategory.get(category);
      return keyBy(columns, 'name');
    },
  );

  /**
   * getNetworkInsightsSearchableColumnLabels
   * @static
   * @type {MemoizedSelector<MerlotState, string[]>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsSearchableColumnLabels: MemoizedSelector<MerlotState, string[]> = createSelector(
    NetworkInsightsSelectors.getNetworkInsightsSearchableColumnNames,
    NetworkInsightsSelectors.getNetworkLogsTableColumnsByName,
    (columnNames: string[], columnIndex: ColumnIndex) => {
      return columnNames.map((columnName: string) => (columnIndex[columnName] ? columnIndex[columnName].label : columnName));
    },
  );

  /**
   * getNetworkInsightsLogDefaultTableColumnNames
   * @static
   * @type {MemoizedSelector<MerlotState, string[]>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogDefaultTableColumnNames: MemoizedSelector<MerlotState, string[]> = createSelector(
    NetworkInsightsSelectors.getNetworkInsightsLogSelectedTabName,
    (selectedTabName: string) => {
      const fieldsByTabName = {
        [NetEventsLogTabName.NET_EVENT]: networkInsightHelpers.getWidgetsDefaultTableColumnNames(
          getUniqueId(Integration.APTELIGENT, Entity.NET_EVENT),
        ),
        [NetEventsLogTabName.NET_ERROR]: networkInsightHelpers.getWidgetsDefaultTableColumnNames(
          getUniqueId(Integration.APTELIGENT, Entity.NET_ERROR),
        ),
      };
      return fieldsByTabName[selectedTabName];
    },
  );

  /**
   * getNetworkInsights
   * @static
   * @type {MemoizedSelector<MerlotState, any>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsights: MemoizedSelector<MerlotState, any> = createSelector(
    NetworkInsightsSelectors.getNetworkInsightsLogDefaultTableColumnNames,
    NetworkInsightsSelectors.getNetworkInsightsLogSortOns,
    NetworkInsightsSelectors.getNetworkInsightsLogPagedRequest,
    NetworkInsightsSelectors.getNetworkInsightsSearchableColumnNames,
    NetworkInsightsSelectors.getNetworkInsightsLogSearchQuery,
    (
      columnNamesForSearch: string[],
      sortOns: SortOn[],
      pagedRequest: PagedRequest,
      searchableColumnNames: string[],
      searchQuery: string,
    ) => {
      return {
        columnNamesForSearch,
        sortOns,
        pagedRequest,
        searchableColumnNames,
        searchQuery,
      };
    },
  );

  /**
   * getNetworkInsightsLogSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PreviewReportContentRequest>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogSearchRequest: MemoizedSelector<MerlotState, PreviewReportContentRequest> = createSelector(
    NetworkInsightsSelectors.getNetworkInsightsLogSelectedTabName,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    AppLoadsSelectors.getSelectedApteligentId,
    AppsDashboardSelectors.getNetworkInsightsHost,
    NetworkInsightsSelectors.getNetworkInsights,
    (selectedTabName: string, trendDateRange: TrendDateRange, apteligentId: string, networkInsightsHost: string, networkInsights: any) => {
      const { columnNamesForSearch, sortOns, pagedRequest, searchableColumnNames, searchQuery } = networkInsights;
      return networkInsightHelpers.getNetworkInsightsLogSearchRequest(
        selectedTabName,
        trendDateRange,
        apteligentId,
        networkInsightsHost,
        columnNamesForSearch,
        sortOns,
        pagedRequest,
        searchableColumnNames,
        searchQuery,
      );
    },
  );

  /**
   * getNetworkInsightsLogDefaultTableColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof NetworkInsightsSelectors
   */
  public static getNetworkInsightsLogDefaultTableColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    NetworkInsightsSelectors.getNetworkInsightsLogDefaultTableColumnNames,
    NetworkInsightsSelectors.getNetworkLogsTableColumnsByName,
    (columnNames: string[], columnsByName: ColumnIndex) => {
      return columnNames.map((columnName: string) => columnsByName[columnName]).filter(Boolean);
    },
  );
}

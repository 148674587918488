/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataDecorator, MetricCardSize } from '@dpa/ui-common';

import { NgxChart, NgxTrendResultFlattener } from '@ws1c/intelligence-models';

/**
 * Metric (Counter) Chart Component
 * @export
 * @class MetricChartComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-metric-chart',
  templateUrl: 'metric-chart.component.html',
  styleUrls: ['metric-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricChartComponent implements OnChanges {
  @Input() public ngxChart: NgxChart;

  public metricValue: string;
  public formatType: DataDecorator;
  public readonly MetricCardSize = MetricCardSize;

  /**
   * Load Widget
   * @param {SimpleChanges} changes
   * @memberof MetricChartComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.ngxChart) {
      this.metricValue = this.ngxChart.labels.counterFormatter(this.ngxChart.rootNode.value);
      this.formatType = this.ngxChart.indices.dataDecoratorsByCounterKey[
        NgxTrendResultFlattener.getCounterKey(0)
      ] as unknown as DataDecorator;
    }
  }
}

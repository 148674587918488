/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { SuggestedApp } from '@dpa-shared-merlot/model';
import {
  AirwatchAppSearchItem,
  App,
  AppDetailTabType,
  AppErrorDetailTabType,
  AppErrorType,
  AppRegistration,
  AppSearchRequest,
  AppSearchResponse,
  RegisteredAppSearchResponse,
  WizardDialogMode,
} from '@ws1c/intelligence-models';

/**
 * Defines UI State for App Loads (Integration with Apteligent)
 *
 * @export
 * @interface AppLoadsState
 */
export interface AppLoadsState {
  appSearchQuery: string;
  appSearchList: AirwatchAppSearchItem[];

  apps: App[];
  appErrorDetailTabType: AppErrorDetailTabType;
  appDetailTabType: AppDetailTabType;
  appErrorDetailType: AppErrorType;
  currentAppVersions: string[];
  appErrorCrashId: string;
  appErrorHeParams: any;
  selectedApp: App;
  selectedApps: App[];
  selectedApteligentApps: AppRegistration[];
  selectedApteligentApp: AppRegistration;
  registeredApps: AppRegistration[];
  loading: boolean;
  request: AppSearchRequest;
  response: AppSearchResponse;
  suggestedApps: SuggestedApp[];
  nonDeployedApps: AppRegistration[];
  error: any;
  wizardDialogMode: WizardDialogMode;
  showAddAppModal: boolean;
  showSendEmailModal: boolean;
  sendEmailAppId: string;
  sendingEmail: boolean;
  allRegisteredApps: AppRegistration[];
  registeredAppRequest: AppSearchRequest;
  registeredAppResponse: RegisteredAppSearchResponse;
  allProductivityApps: AppRegistration[];
  productivityAppRequest: AppSearchRequest;
  productivityAppResponse: RegisteredAppSearchResponse;
  userAndAppRegistrationDone: boolean;
  isDeleteModalOpen: boolean;
  isMultiDeleteModalOpen: boolean;
  isDeletingApps: boolean;
  selectedAppPluginExceptionEventCount: number;
}

/**
 * Initial state for AppLoadsState
 */
export const initialAppLoadsState: AppLoadsState = {
  appSearchQuery: '',
  appSearchList: [],
  apps: [],
  appErrorDetailTabType: undefined,
  appDetailTabType: undefined,
  appErrorDetailType: undefined,
  currentAppVersions: [],
  selectedApp: null,
  appErrorCrashId: undefined,
  appErrorHeParams: undefined,
  selectedApps: [],
  registeredApps: [],
  selectedApteligentApps: [],
  selectedApteligentApp: undefined,
  loading: false,
  request: new AppSearchRequest(),
  response: new AppSearchResponse(),
  suggestedApps: [],
  nonDeployedApps: [],
  error: undefined,
  wizardDialogMode: WizardDialogMode.CLOSE,
  showAddAppModal: false,
  showSendEmailModal: false,
  sendEmailAppId: undefined,
  sendingEmail: false,
  allRegisteredApps: [],
  registeredAppRequest: undefined,
  registeredAppResponse: undefined,
  allProductivityApps: [],
  productivityAppRequest: undefined,
  productivityAppResponse: undefined,
  userAndAppRegistrationDone: false,
  isDeleteModalOpen: false,
  isMultiDeleteModalOpen: false,
  isDeletingApps: false,
  selectedAppPluginExceptionEventCount: undefined,
};

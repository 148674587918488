/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChronoUnit } from '@dpa/ui-common';

interface TimeUnitRange {
  unit: string;
  range: number;
}

export const CHRONO_UNIT_RANGE_MAP: Record<string, TimeUnitRange> = {
  [ChronoUnit[ChronoUnit.MONTHS]]: {
    unit: ChronoUnit[ChronoUnit.WEEKS],
    range: 4,
  },
  [ChronoUnit[ChronoUnit.WEEKS]]: {
    unit: ChronoUnit[ChronoUnit.DAYS],
    range: 7,
  },
  [ChronoUnit[ChronoUnit.DAYS]]: {
    unit: ChronoUnit[ChronoUnit.HOURS],
    range: 24,
  },
  [ChronoUnit[ChronoUnit.HOURS]]: {
    unit: ChronoUnit[ChronoUnit.MINUTES],
    range: 60,
  },
  [ChronoUnit[ChronoUnit.MINUTES]]: {
    unit: ChronoUnit[ChronoUnit.SECONDS],
    range: 120,
  },
  [ChronoUnit[ChronoUnit.SECONDS]]: {
    unit: ChronoUnit[ChronoUnit.SECONDS],
    range: 30,
  },
};

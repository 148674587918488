/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { Email, SuggestedAppsList } from '@dpa-shared-merlot/model';
import {
  AirwatchAppSearchItem,
  App,
  AppDetailTabType,
  AppErrorType,
  AppRegistration,
  AppSearchRequest,
  ApteligentCrumb,
  RegisteredAppSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * AppLoadsActions
 * @export
 * @class AppLoadsActions
 */
export class AppLoadsActions {
  public static readonly CATEGORY = '[APPLOADS]';

  public static loadPluginExceptionEventCount = createAction(
    `${AppLoadsActions.CATEGORY} LOAD_PLUGIN_EXCEPTION_EVENT_COUNT`,
    props<{
      app: App;
    }>(),
  );
  public static loadPluginExceptionEventCountSuccess = createAction(
    `${AppLoadsActions.CATEGORY} LOAD_PLUGIN_EXCEPTION_EVENT_COUNT_SUCCESS`,
    props<{
      pluginExceptionEventCountByApp: number;
    }>(),
  );
  public static loadPluginExceptionEventCountFailure = createAction(
    `${AppLoadsActions.CATEGORY} LOAD_PLUGIN_EXCEPTION_EVENT_COUNT_FAILURE`,
  );
  public static getNonDeployedApps = createAction(`${AppLoadsActions.CATEGORY} GET_NON_DEPLOYED_APPS`);
  public static getNonDeployedAppsSuccess = createAction(
    `${AppLoadsActions.CATEGORY} GET_NON_DEPLOYED_APPS_SUCCESS`,
    props<{
      response: AppRegistration[];
    }>(),
  );
  public static getNonDeployedAppsFailure = createAction(
    `${AppLoadsActions.CATEGORY} GET_NON_DEPLOYED_APPS_FAILURE`,
    props<{
      error: WebError;
    }>(),
  );
  public static refreshAllRegisteredApps = createAction(`${AppLoadsActions.CATEGORY} REFRESH_ALL_REGISTERED_APPS`);
  public static refreshAllProductivityApps = createAction(`${AppLoadsActions.CATEGORY} REFRESH_ALL_PRODUCTIVITY_APPS`);
  public static showSetupAppLoads = createAction(`${AppLoadsActions.CATEGORY} SHOW_SETUP_APP_LOADS`);
  public static searchAllRegisteredApps = createAction(
    `${AppLoadsActions.CATEGORY} SEARCH_ALL_REGISTERED_APPS`,
    props<{
      query: string;
    }>(),
  );
  public static searchAllProductivityApps = createAction(
    `${AppLoadsActions.CATEGORY} SEARCH_ALL_PRODUCTIVITY_APPS`,
    props<{
      query: string;
    }>(),
  );
  public static toggleDeleteModal = createAction(
    `${AppLoadsActions.CATEGORY} TOGGLE_DELETE_MODAL`,
    props<{
      isOpen: boolean;
    }>(),
  );
  public static toggleMultiDeleteModal = createAction(
    `${AppLoadsActions.CATEGORY} TOGGLE_MULTI_DELETE_MODAL`,
    props<{
      isOpen: boolean;
    }>(),
  );
  public static deleteRegisteredApp = createAction(`${AppLoadsActions.CATEGORY} DELETE_REGISTERED_APP`);
  public static deleteRegisteredAppSuccess = createAction(`${AppLoadsActions.CATEGORY} DELETE_REGISTERED_APP_SUCCESS`);
  public static deleteRegisteredAppFailure = createAction(`${AppLoadsActions.CATEGORY} DELETE_REGISTERED_APP_FAILURE`);
  public static deleteRegisteredApps = createAction(`${AppLoadsActions.CATEGORY} DELETE_REGISTERED_APPS`);
  public static deleteRegisteredAppsSuccess = createAction(`${AppLoadsActions.CATEGORY} DELETE_REGISTERED_APPS_SUCCESS`);
  public static deleteRegisteredAppsFailure = createAction(`${AppLoadsActions.CATEGORY} DELETE_REGISTERED_APPS_FAILURE`);
  public static copySuccess = createAction(`${AppLoadsActions.CATEGORY} COPY_SUCCESS`);
  public static copyFailure = createAction(`${AppLoadsActions.CATEGORY} COPY_FAILURE`);
  public static goToAppDetailPage = createAction(
    `${AppLoadsActions.CATEGORY} GO_TO_APP_DETAIL_PAGE`,
    props<{
      app: App;
    }>(),
  );
  public static goToAppDetailPageSuccess = createAction(`${AppLoadsActions.CATEGORY} GO_TO_APP_DETAIL_PAGE_SUCCESS`);
  public static selectRegisteredApp = createAction(
    `${AppLoadsActions.CATEGORY} SELECT_REGISTERED_APP`,
    props<{
      app: AppRegistration;
    }>(),
  );
  public static selectRegisteredApps = createAction(
    `${AppLoadsActions.CATEGORY} SELECT_REGISTERED_APPS`,
    props<{
      apps: AppRegistration[];
    }>(),
  );
  public static searchAppAndGoToDetails = createAction(
    `${AppLoadsActions.CATEGORY} SEARCH_APP_AND_GO_TO_DETAILS`,
    props<{
      app: App;
      urlSegment?: string;
    }>(),
  );
  public static sortAllRegisteredApps = createAction(
    `${AppLoadsActions.CATEGORY} SORT_ALL_REGISTERED_APPS`,
    props<{
      sortOns: SortOn[];
    }>(),
  );
  public static changePaginationAllRegisteredApps = createAction(
    `${AppLoadsActions.CATEGORY} CHANGE_PAGINATION_ALL_REGISTERED_APPS`,
    props<{
      pagedRequest: PagedRequest;
    }>(),
  );
  public static showSendEmailModal = createAction(
    `${AppLoadsActions.CATEGORY} SHOW_SEND_EMAIL_MODAL`,
    props<{
      sendEmailAppId: string;
    }>(),
  );
  public static getAllRegisteredApps = createAction(
    `${AppLoadsActions.CATEGORY} GET_ALL_REGISTERED_APPS`,
    props<{
      appSearchRequest: AppSearchRequest;
    }>(),
  );
  public static getAllRegisteredAppsSuccess = createAction(
    `${AppLoadsActions.CATEGORY} GET_ALL_REGISTERED_APPS_SUCCESS`,
    props<{
      response: RegisteredAppSearchResponse;
    }>(),
  );
  public static getAllRegisteredAppsFailure = createAction(
    `${AppLoadsActions.CATEGORY} GET_ALL_REGISTERED_APPS_FAILURE`,
    props<{
      error: WebError;
    }>(),
  );
  public static getAllProductivityApps = createAction(
    `${AppLoadsActions.CATEGORY} GET_ALL_PRODUCTIVITY_APPS`,
    props<{
      appSearchRequest: AppSearchRequest;
    }>(),
  );
  public static getAllProductivityAppsSuccess = createAction(
    `${AppLoadsActions.CATEGORY} GET_ALL_PRODUCTIVITY_APPS_SUCCESS`,
    props<{
      response: RegisteredAppSearchResponse;
    }>(),
  );
  public static getAllProductivityAppsFailure = createAction(
    `${AppLoadsActions.CATEGORY} GET_ALL_PRODUCTIVITY_APPS_FAILURE`,
    props<{
      error: WebError;
    }>(),
  );
  public static sortAllProductivityApps = createAction(
    `${AppLoadsActions.CATEGORY} SORT_ALL_PRODUCTIVITY_APPS`,
    props<{
      sortOns: SortOn[];
    }>(),
  );
  public static changePaginationAllProductivityApps = createAction(
    `${AppLoadsActions.CATEGORY} CHANGE_PAGINATION_ALL_PRODUCTIVITY_APPS`,
    props<{
      pagedRequest: PagedRequest;
    }>(),
  );
  public static searchApps = createAction(
    `${AppLoadsActions.CATEGORY} SEARCH_APPS`,
    props<{
      query: string;
    }>(),
  );
  public static searchAppsSuccess = createAction(
    `${AppLoadsActions.CATEGORY} SEARCH_APPS_SUCCESS`,
    props<{
      apps: AirwatchAppSearchItem[];
    }>(),
  );
  public static searchAppsFailure = createAction(
    `${AppLoadsActions.CATEGORY} SEARCH_APPS_FAILURE`,
    props<{
      error: WebError;
    }>(),
  );
  public static getAppTypeIntegration = createAction(
    `${AppLoadsActions.CATEGORY} GET_APP_TYPE_INTEGRATION`,
    props<{
      query: string;
    }>(),
  );
  public static getAppTypeIntegrationSuccess = createAction(
    `${AppLoadsActions.CATEGORY} GET_APP_TYPE_INTEGRATION_SUCCESS`,
    props<{
      apps: AirwatchAppSearchItem[];
    }>(),
  );
  public static getAppTypeIntegrationFailure = createAction(
    `${AppLoadsActions.CATEGORY} GET_APP_TYPE_INTEGRATION_FAILURE`,
    props<{
      error: WebError;
    }>(),
  );
  public static getSuggestedApps = createAction(
    `${AppLoadsActions.CATEGORY} GET_SUGGESTED_APPS`,
    props<{
      query: string;
    }>(),
  );
  public static getSuggestedAppsSuccess = createAction(
    `${AppLoadsActions.CATEGORY} GET_SUGGESTED_APPS_SUCCESS`,
    props<{
      apps: SuggestedAppsList;
    }>(),
  );
  public static getSuggestedAppsFailure = createAction(
    `${AppLoadsActions.CATEGORY} GET_SUGGESTED_APPS_FAILURE`,
    props<{
      error: WebError;
    }>(),
  );
  public static saveAppRegistrations = createAction(`${AppLoadsActions.CATEGORY} SAVE_APP_REGISTRATIONS`);
  public static saveAppRegistrationsSuccess = createAction(
    `${AppLoadsActions.CATEGORY} SAVE_APP_REGISTRATIONS_SUCCESS`,
    props<{
      apps: AppRegistration[];
    }>(),
  );
  public static saveAppRegistrationsFailure = createAction(
    `${AppLoadsActions.CATEGORY} SAVE_APP_REGISTRATIONS_FAILURE`,
    props<{
      error: WebError;
    }>(),
  );
  public static emptySuggestedApps = createAction(`${AppLoadsActions.CATEGORY} EMPTY_SUGGESTED_APPS`);
  public static hideSetupAppLoads = createAction(`${AppLoadsActions.CATEGORY} HIDE_SETUP_APP_LOADS`);
  public static goToAppDetailCrumbPage = createAction(
    `${AppLoadsActions.CATEGORY} GO_TO_APP_DETAIL_CRUMB_PAGE`,
    props<{
      crumb: ApteligentCrumb;
    }>(),
  );
  public static setAppDetailTab = createAction(
    `${AppLoadsActions.CATEGORY} SET_APP_DETAIL_TAB`,
    props<{
      appDetailTabType: AppDetailTabType;
    }>(),
  );
  public static setAppErrorDetailType = createAction(
    `${AppLoadsActions.CATEGORY} SET_APP_ERROR_DETAIL_TYPE`,
    props<{
      appErrorType: AppErrorType;
    }>(),
  );
  public static setAppErrorCrashId = createAction(
    `${AppLoadsActions.CATEGORY} SET_APP_ERROR_CRASH_ID`,
    props<{
      id: string;
    }>(),
  );
  public static setAppErrorHeParams = createAction(
    `${AppLoadsActions.CATEGORY} SET_APP_ERROR_HE_PARAMS`,
    props<{
      errorName: string;
      errorReason: string;
    }>(),
  );
  public static selectApp = createAction(
    `${AppLoadsActions.CATEGORY} SELECT_APP`,
    props<{
      app: App;
    }>(),
  );
  public static selectApps = createAction(
    `${AppLoadsActions.CATEGORY} SELECT_APPS`,
    props<{
      apps: App[];
    }>(),
  );
  public static clearAllApps = createAction(`${AppLoadsActions.CATEGORY} CLEAR_ALL_APPS`);
  public static removeSelectedAppByIndex = createAction(
    `${AppLoadsActions.CATEGORY} REMOVE_SELECTED_APP_BY_INDEX`,
    props<{
      index: number;
    }>(),
  );
  public static updateOptout = createAction(
    `${AppLoadsActions.CATEGORY} UPDATE_OPTOUT`,
    props<{
      optOut: boolean;
    }>(),
  );
  public static finishAppRegistration = createAction(`${AppLoadsActions.CATEGORY} FINISH_APP_REGISTRATION`);
  public static showAddAppModal = createAction(
    `${AppLoadsActions.CATEGORY} SHOW_ADD_APP_MODAL`,
    props<{
      show: boolean;
    }>(),
  );
  public static addNewAppToSelectedApps = createAction(
    `${AppLoadsActions.CATEGORY} ADD_NEW_APP_TO_SELECTED_APPS`,
    props<{
      app: App;
    }>(),
  );
  public static sendEmail = createAction(
    `${AppLoadsActions.CATEGORY} SEND_EMAIL`,
    props<{
      email: Email;
    }>(),
  );
  public static sendEmailSuccess = createAction(`${AppLoadsActions.CATEGORY} SEND_EMAIL_SUCCESS`);
  public static sendEmailFailure = createAction(`${AppLoadsActions.CATEGORY} SEND_EMAIL_FAILURE`);
  public static hideSendEmailModal = createAction(`${AppLoadsActions.CATEGORY} HIDE_SEND_EMAIL_MODAL`);
  public static registerApps = createAction(`${AppLoadsActions.CATEGORY} REGISTER_APPS`);
}

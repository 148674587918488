/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSyntheticURLMonitoringConfigType
 * @enum {string}
 * @export
 */
export enum DeemSyntheticURLMonitoringConfigType {
  SYNTHETIC_URL_MONITORING = 'SYNTHETIC_URL_MONITORING',
}

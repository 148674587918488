/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { remove } from 'lodash-es';

import { SuggestedApp } from '@dpa-shared-merlot/model';
import {
  App,
  AppSearchRequest,
  AppType,
  Integration,
  RegisteredAppSearchResponse,
  SearchTerm,
  WizardDialogMode,
} from '@ws1c/intelligence-models';
import { AppLoadsActions } from './app-loads.actions';
import { AppLoadsState, initialAppLoadsState } from './app-loads.state';

const _reducer: ActionReducer<AppLoadsState> = createReducer(
  initialAppLoadsState,
  on(
    AppLoadsActions.loadPluginExceptionEventCountSuccess,
    (state: AppLoadsState, props: ReturnType<typeof AppLoadsActions.loadPluginExceptionEventCountSuccess>): AppLoadsState => ({
      ...state,
      selectedAppPluginExceptionEventCount: props.pluginExceptionEventCountByApp,
    }),
  ),
  on(
    AppLoadsActions.loadPluginExceptionEventCountFailure,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      selectedAppPluginExceptionEventCount: 0,
    }),
  ),
  on(
    AppLoadsActions.getNonDeployedAppsSuccess,
    (state: AppLoadsState, props: ReturnType<typeof AppLoadsActions.getNonDeployedAppsSuccess>): AppLoadsState => ({
      ...state,
      nonDeployedApps: props.response,
    }),
  ),
  on(
    AppLoadsActions.getNonDeployedAppsFailure,
    (state: AppLoadsState, props: ReturnType<typeof AppLoadsActions.getNonDeployedAppsFailure>): AppLoadsState => ({
      ...state,
      error: props.error,
    }),
  ),
  on(
    AppLoadsActions.refreshAllRegisteredApps,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      loading: true,
      registeredAppRequest: new AppSearchRequest(state.request, {
        from: 0,
      }),
    }),
  ),
  on(
    AppLoadsActions.refreshAllProductivityApps,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      loading: true,
      productivityAppRequest: new AppSearchRequest(state.request, {
        from: 0,
      }),
    }),
  ),
  on(
    AppLoadsActions.showSetupAppLoads,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      wizardDialogMode: WizardDialogMode.ADD,
      selectedApps: [],
      userAndAppRegistrationDone: false,
    }),
  ),
  on(
    AppLoadsActions.searchAllRegisteredApps,
    (state: AppLoadsState, { query }: ReturnType<typeof AppLoadsActions.searchAllRegisteredApps>): AppLoadsState => ({
      ...state,
      loading: true,
      registeredAppRequest: new AppSearchRequest(state.request, {
        searchTerm: new SearchTerm({
          value: query,
        }),
        from: 0,
      }),
    }),
  ),
  on(
    AppLoadsActions.searchAllProductivityApps,
    (state: AppLoadsState, { query }: ReturnType<typeof AppLoadsActions.searchAllProductivityApps>): AppLoadsState => ({
      ...state,
      loading: true,
      productivityAppRequest: new AppSearchRequest(state.request, {
        searchTerm: new SearchTerm({
          value: query,
        }),
        from: 0,
      }),
    }),
  ),
  on(
    AppLoadsActions.toggleDeleteModal,
    (state: AppLoadsState, { isOpen }: ReturnType<typeof AppLoadsActions.toggleDeleteModal>): AppLoadsState => ({
      ...state,
      isDeleteModalOpen: isOpen,
    }),
  ),
  on(
    AppLoadsActions.toggleMultiDeleteModal,
    (state: AppLoadsState, { isOpen }: ReturnType<typeof AppLoadsActions.toggleMultiDeleteModal>): AppLoadsState => ({
      ...state,
      isMultiDeleteModalOpen: isOpen,
    }),
  ),
  on(
    AppLoadsActions.deleteRegisteredApp,
    AppLoadsActions.deleteRegisteredApps,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      isDeletingApps: true,
    }),
  ),
  on(
    AppLoadsActions.deleteRegisteredAppSuccess,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      isDeletingApps: false,
      isDeleteModalOpen: false,
      selectedApteligentApp: undefined,
    }),
  ),
  on(
    AppLoadsActions.deleteRegisteredAppFailure,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      isDeletingApps: false,
      selectedApteligentApp: undefined,
    }),
  ),
  on(
    AppLoadsActions.deleteRegisteredAppsSuccess,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      selectedApteligentApps: [],
      isDeletingApps: false,
      isMultiDeleteModalOpen: false,
    }),
  ),
  on(
    AppLoadsActions.deleteRegisteredAppsFailure,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      isDeletingApps: false,
    }),
  ),
  on(
    AppLoadsActions.selectRegisteredApp,
    (state: AppLoadsState, { app }: ReturnType<typeof AppLoadsActions.selectRegisteredApp>): AppLoadsState => ({
      ...state,
      selectedApteligentApp: app,
    }),
  ),
  on(
    AppLoadsActions.selectRegisteredApps,
    (state: AppLoadsState, { apps }: ReturnType<typeof AppLoadsActions.selectRegisteredApps>): AppLoadsState => ({
      ...state,
      selectedApteligentApps: apps,
    }),
  ),
  on(
    AppLoadsActions.sortAllRegisteredApps,
    (state: AppLoadsState, { sortOns }: ReturnType<typeof AppLoadsActions.sortAllRegisteredApps>): AppLoadsState => ({
      ...state,
      loading: true,
      registeredAppRequest: new AppSearchRequest(state.request, { sortOns }),
    }),
  ),
  on(
    AppLoadsActions.changePaginationAllRegisteredApps,
    (state: AppLoadsState, { pagedRequest }: ReturnType<typeof AppLoadsActions.changePaginationAllRegisteredApps>): AppLoadsState => ({
      ...state,
      loading: true,
      registeredAppRequest: new AppSearchRequest(state.request, pagedRequest),
    }),
  ),
  on(
    AppLoadsActions.showSendEmailModal,
    (state: AppLoadsState, { sendEmailAppId }: ReturnType<typeof AppLoadsActions.showSendEmailModal>): AppLoadsState => ({
      ...state,
      showSendEmailModal: true,
      sendEmailAppId,
    }),
  ),
  on(
    AppLoadsActions.getAllRegisteredApps,
    AppLoadsActions.getAllProductivityApps,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    AppLoadsActions.getAllRegisteredAppsSuccess,
    (state: AppLoadsState, props: ReturnType<typeof AppLoadsActions.getAllRegisteredAppsSuccess>): AppLoadsState => ({
      ...state,
      registeredAppResponse: props.response,
      allRegisteredApps: props.response.results,
      loading: false,
      selectedApteligentApps: [],
    }),
  ),
  on(
    AppLoadsActions.getAllProductivityAppsSuccess,
    (state: AppLoadsState, props: ReturnType<typeof AppLoadsActions.getAllProductivityAppsSuccess>): AppLoadsState => ({
      ...state,
      productivityAppResponse: props.response,
      allRegisteredApps: props.response.results,
      loading: false,
    }),
  ),
  on(
    AppLoadsActions.getAllRegisteredAppsFailure,
    AppLoadsActions.getAllProductivityAppsFailure,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    AppLoadsActions.sortAllProductivityApps,
    (state: AppLoadsState, { sortOns }: ReturnType<typeof AppLoadsActions.sortAllProductivityApps>): AppLoadsState => ({
      ...state,
      loading: true,
      productivityAppRequest: new AppSearchRequest(state.request, { sortOns }),
    }),
  ),
  on(
    AppLoadsActions.changePaginationAllProductivityApps,
    (state: AppLoadsState, { pagedRequest }: ReturnType<typeof AppLoadsActions.changePaginationAllProductivityApps>): AppLoadsState => ({
      ...state,
      loading: true,
      productivityAppRequest: new AppSearchRequest(state.request, pagedRequest),
    }),
  ),
  on(
    AppLoadsActions.searchApps,
    (state: AppLoadsState, { query }: ReturnType<typeof AppLoadsActions.searchApps>): AppLoadsState => ({
      ...state,
      loading: true,
      appSearchQuery: query,
      appSearchList: [],
    }),
  ),
  on(
    AppLoadsActions.searchAppsSuccess,
    (state: AppLoadsState, { apps }: ReturnType<typeof AppLoadsActions.searchAppsSuccess>): AppLoadsState => ({
      ...state,
      loading: false,
      appSearchList: apps,
    }),
  ),
  on(
    AppLoadsActions.searchAppsFailure,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      loading: false,
      appSearchList: [],
    }),
  ),
  on(
    AppLoadsActions.getAppTypeIntegrationSuccess,
    (state: AppLoadsState, { apps }: ReturnType<typeof AppLoadsActions.getAppTypeIntegrationSuccess>): AppLoadsState => ({
      ...state,
      selectedApp: new App({
        ...state.selectedApp,
        appType: apps.length ? apps[0].appType : AppType.PUBLIC,
        integration: apps.length ? apps[0].integration : Integration.APTELIGENT,
      }),
    }),
  ),
  on(
    AppLoadsActions.getSuggestedAppsSuccess,
    (state: AppLoadsState, { apps }: ReturnType<typeof AppLoadsActions.getSuggestedAppsSuccess>): AppLoadsState => ({
      ...state,
      suggestedApps: apps.data,
    }),
  ),
  on(
    AppLoadsActions.getSuggestedAppsFailure,
    (state: AppLoadsState, { error }: ReturnType<typeof AppLoadsActions.getSuggestedAppsFailure>): AppLoadsState => ({
      ...state,
      error,
    }),
  ),
  on(
    AppLoadsActions.emptySuggestedApps,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      suggestedApps: [],
    }),
  ),
  on(
    AppLoadsActions.hideSetupAppLoads,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      wizardDialogMode: WizardDialogMode.CLOSE,
      selectedApps: [],
    }),
  ),
  on(
    AppLoadsActions.saveAppRegistrations,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      registeredAppResponse: undefined,
      userAndAppRegistrationDone: false,
    }),
  ),
  on(
    AppLoadsActions.saveAppRegistrationsSuccess,
    (state: AppLoadsState, { apps }: ReturnType<typeof AppLoadsActions.saveAppRegistrationsSuccess>): AppLoadsState => ({
      ...state,
      registeredApps: apps,
      registeredAppResponse: new RegisteredAppSearchResponse({ results: apps }),
      userAndAppRegistrationDone: true,
    }),
  ),
  on(
    AppLoadsActions.setAppDetailTab,
    (state: AppLoadsState, { appDetailTabType }: ReturnType<typeof AppLoadsActions.setAppDetailTab>): AppLoadsState => ({
      ...state,
      appDetailTabType,
    }),
  ),
  on(
    AppLoadsActions.setAppErrorDetailType,
    (state: AppLoadsState, { appErrorType }: ReturnType<typeof AppLoadsActions.setAppErrorDetailType>): AppLoadsState => ({
      ...state,
      appErrorDetailType: appErrorType,
    }),
  ),
  on(
    AppLoadsActions.setAppErrorCrashId,
    (state: AppLoadsState, { id }: ReturnType<typeof AppLoadsActions.setAppErrorCrashId>): AppLoadsState => ({
      ...state,
      appErrorCrashId: id,
    }),
  ),
  on(
    AppLoadsActions.setAppErrorHeParams,
    (state: AppLoadsState, { errorName, errorReason }: ReturnType<typeof AppLoadsActions.setAppErrorHeParams>): AppLoadsState => ({
      ...state,
      appErrorHeParams: {
        errorName,
        errorReason,
      },
    }),
  ),
  on(
    AppLoadsActions.selectApp,
    (state: AppLoadsState, { app }: ReturnType<typeof AppLoadsActions.selectApp>): AppLoadsState => ({
      ...state,
      selectedApp: app,
    }),
  ),
  on(AppLoadsActions.selectApps, (state: AppLoadsState, { apps }: ReturnType<typeof AppLoadsActions.selectApps>): AppLoadsState => {
    let selectedApps = [];
    if (apps.length === 0) {
      selectedApps = [...state.selectedApps];
      remove(selectedApps, (app) => {
        return state.suggestedApps.some((suggestedApp: SuggestedApp) => {
          return app.packageId === suggestedApp.packageId && app.platform === suggestedApp.platform;
        });
      });
    } else {
      selectedApps = apps;
    }
    return {
      ...state,
      selectedApps,
      selectedApp: selectedApps[0] as App,
    };
  }),
  on(
    AppLoadsActions.clearAllApps,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      selectedApps: [],
      selectedApp: undefined,
    }),
  ),
  on(
    AppLoadsActions.removeSelectedAppByIndex,
    (state: AppLoadsState, { index }: ReturnType<typeof AppLoadsActions.removeSelectedAppByIndex>): AppLoadsState => ({
      ...state,
      selectedApps: [...state.selectedApps.slice(0, index), ...state.selectedApps.slice(index + 1)],
    }),
  ),
  on(
    AppLoadsActions.finishAppRegistration,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      wizardDialogMode: WizardDialogMode.CLOSE,
    }),
  ),
  on(
    AppLoadsActions.showAddAppModal,
    (state: AppLoadsState, { show }: ReturnType<typeof AppLoadsActions.showAddAppModal>): AppLoadsState => ({
      ...state,
      showAddAppModal: show,
    }),
  ),
  on(
    AppLoadsActions.addNewAppToSelectedApps,
    (state: AppLoadsState, { app }: ReturnType<typeof AppLoadsActions.addNewAppToSelectedApps>): AppLoadsState => {
      const selectedApps = [...state.selectedApps, app];
      return {
        ...state,
        selectedApps,
        selectedApp: selectedApps[0],
        showAddAppModal: false,
      };
    },
  ),
  on(
    AppLoadsActions.sendEmail,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      sendingEmail: true,
    }),
  ),
  on(
    AppLoadsActions.sendEmailSuccess,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      sendingEmail: false,
      showSendEmailModal: false,
      sendEmailAppId: '',
    }),
  ),
  on(
    AppLoadsActions.sendEmailFailure,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      sendingEmail: false,
    }),
  ),
  on(
    AppLoadsActions.hideSendEmailModal,
    (state: AppLoadsState): AppLoadsState => ({
      ...state,
      showSendEmailModal: false,
      sendEmailAppId: '',
    }),
  ),
);

/**
 * AppLoadsState Reducer
 * @param {AppLoadsState} state
 * @param {Action} action
 * @returns {AppLoadsState}
 */
export function appLoadsState(state: AppLoadsState, action: Action) {
  return _reducer(state, action);
}

<span class="drilldown-navigator">
  <ng-container *ngFor="let drilldownItem of drilldownList; let i = index">
    <ng-container *ngIf="i > 0">
      <cds-icon
        shape="angle"
        direction="right"
        size="16"
      ></cds-icon>
    </ng-container>
    <a
      (click)="navigateTo(drilldownItem)"
      (keyup.enter)="navigateTo(drilldownItem)"
      class="clickable text-ellipsis"
    >
      {{ drilldownItem.drilldownKey }}
    </a>
  </ng-container>
</span>

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { AvailableService, AvailableServiceTenant, Connection, ConnectionModalSection } from '@ws1c/intelligence-models';

/**
 * ConnectionCommonActions
 *
 * @export
 * @class ConnectionCommonActions
 */
export class ConnectionCommonActions {
  public static readonly CATEGORY = '[CONNECTION_COMMON]';

  /**
   * updateServiceWithConfig
   *
   * @static
   * @memberof ConnectionCommonActions
   */
  public static updateServiceWithConfig = createAction(
    `${ConnectionCommonActions.CATEGORY} UPDATE_SERVICE_WITH_CONFIG`,
    props<{ connection: Connection }>(),
  );

  /**
   * setTestConnectionModalSection
   *
   * @static
   * @memberof ConnectionCommonActions
   */
  public static setTestConnectionModalSection = createAction(
    `${ConnectionCommonActions.CATEGORY} SET_TEST_CONNECTION_MODAL_SECTION`,
    props<{ connectionModalSection: ConnectionModalSection }>(),
  );

  /**
   * toggleModalSection
   *
   * @static
   * @memberof ConnectionCommonActions
   */
  public static toggleModalSection = createAction(
    `${ConnectionCommonActions.CATEGORY} TOGGLE_MODAL_SECTION`,
    props<{ connectionModalSection?: ConnectionModalSection }>(),
  );

  /**
   * requestEditConnection
   *
   * @static
   * @memberof ConnectionCommonActions
   */
  public static requestEditConnection = createAction(
    `${ConnectionCommonActions.CATEGORY} REQUEST_EDIT_CONNECTION`,
    props<{
      availableService: AvailableService;
      availableServiceTenant?: AvailableServiceTenant;
    }>(),
  );
}

/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * RbacState
 * @export
 * @interface RbacState
 */
export interface RbacState {
  userSessionScopes: string[];
  isEmailSentForAccessRequest: boolean;
  adminCountForAccessRequest: number;
}

export const initialRbacState: RbacState = {
  userSessionScopes: undefined,
  isEmailSentForAccessRequest: false,
  adminCountForAccessRequest: 0,
};

/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { each, every, fromPairs, isEqual, mapValues, sortBy, zip } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';

import { CoreAppState, DashboardSelectors } from '@ws1c/intelligence-core/store';
import { getBucketingAttributeValue, getCounterValue } from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import {
  ComposeConfigIndex,
  CompositeTrendDefinition,
  Counter,
  Leaderboard,
  MetricTab,
  NestedTrendConfig,
  StandardWidgetSubtype,
  Trend,
  TrendComposer,
  TrendDefinition,
  TrendResult,
} from '@ws1c/intelligence-models';

type TrendSelector = ($state: Observable<CoreAppState>) => Observable<Trend>;

/**
 * TrendComposerService
 * @export
 * @class TrendComposerService
 */
@Injectable({
  providedIn: 'root',
})
export class TrendComposerService {
  /**
   * constructor
   * @param {Store<CoreAppState>} store
   * @memberof TrendComposerService
   */
  constructor(private store: Store<CoreAppState>) {}

  /**
   * getLeaderboard$
   * @param {StandardWidgetSubtype} subtype
   * @param {string} titleKey
   * @param {string[]} columnTitleKeys
   * @param {string} tooltipKey
   * @param {boolean} [isRollup=false]
   * @param {number} [maxLength=5]
   * @returns {Observable<Leaderboard>}
   * @memberof TrendComposerService
   */
  public getLeaderboard$(
    subtype: StandardWidgetSubtype,
    titleKey?: string,
    columnTitleKeys?: string[],
    tooltipKey?: string,
    isRollup: boolean = false,
    maxLength: number = 5,
  ): Observable<Leaderboard> {
    return this.store.pipe(
      this.getTrendSelector(subtype),
      map((trend: Trend) => {
        if (!trend) {
          return;
        }

        const rows = [];
        const rowTotalsByRow = new Map<any[], number>();
        trend.trendResults?.forEach((trendResult: TrendResult) => {
          if (!trendResult.bucketingAttributes[0]) {
            return;
          }
          const rowName = trendResult.bucketingAttributes[0].value;
          const bucketName = isRollup ? trendResult.bucketingAttributes?.[1]?.value : undefined;
          const counterValues = trendResult.counters.map((counter: Counter) => counter.result?.value);
          const rowTotal = counterValues.reduce((sum: number, cell: number) => sum + cell);
          const row = bucketName ? [rowName, bucketName, ...counterValues] : [rowName, ...counterValues];
          rows.push(row);
          rowTotalsByRow.set(row, rowTotal);
        });
        const sortedRows = sortBy(rows, (row: any[]) => -rowTotalsByRow.get(row));
        const sortedClippedRows = sortedRows.slice(0, maxLength);
        const rowTotals = sortedClippedRows.map((row: any[]) => rowTotalsByRow.get(row));
        if (!isRollup) {
          return {
            titleKey,
            columnTitleKeys,
            tooltipKey,
            rows: sortedClippedRows,
            rowTotals,
            maxRowTotal: Math.max(...rowTotals),
            cols: zip(...sortedClippedRows),
            rollup: undefined,
          } as Leaderboard;
        }

        // rollup
        const rollup = new Map<string, number>();
        const rollupDetails = new Map<string, any>();
        sortedRows.forEach((row: any) => {
          if (rollup.has(row[0])) {
            rollup.set(row[0], rollup.get(row[0]) + row[2]);
            rollupDetails.set(row[0], { ...rollupDetails.get(row[0]), [row[1]]: row[2] });
          } else {
            rollup.set(row[0], row[2]);
            rollupDetails.set(row[0], { [row[1]]: row[2] });
          }
        });
        const rollupRows: any[] = Array.from(rollup)
          .sort((a, b) => b[1] - a[1])
          .slice(0, maxLength);
        const rollupCols: any[] = zip(...rollupRows);
        const rollupRowTotals: number[] = rollupCols?.[1];
        const rollupMaxRowTotal: number = rollupRowTotals?.[0];
        return {
          titleKey,
          columnTitleKeys,
          tooltipKey,
          rows: rollupRows,
          rowTotals: rollupRowTotals,
          maxRowTotal: rollupMaxRowTotal,
          cols: rollupCols,
          rollup: rollupDetails,
        } as Leaderboard;
      }),
    );
  }

  /**
   * getLeaderboards$
   * @param {Array<Partial<Leaderboard>>>} leaderboards
   * @returns {Observable<Leaderboard[]>}
   * @memberof TrendComposerService
   */
  public getLeaderboards$(leaderboards: Array<Partial<Leaderboard>>): Observable<Leaderboard[]> {
    const leaderboards$ = leaderboards.map((leaderboard: Partial<Leaderboard>) => {
      return this.getLeaderboard$(
        leaderboard.subtype,
        leaderboard.titleKey,
        leaderboard.columnTitleKeys,
        leaderboard.tooltipKey,
        leaderboard.isRollup,
      );
    });
    return combineLatest(leaderboards$).pipe(
      // index is needed to prevent tslint from shortening this to "filter(every)"
      filter((resolvedLeaderboards: Leaderboard[]) => every(resolvedLeaderboards)),
    );
  }

  /**
   * getTrendCount$
   * @param {StandardWidgetSubtype} subtype
   * @param {number} counterIndex
   * @param {number} resultIndex
   * @returns {Observable<T>}
   * @memberof TrendComposerService
   */
  public getTrendCount$<T = number>(subtype: StandardWidgetSubtype, counterIndex: number = 0, resultIndex: number = 0): Observable<T> {
    return this.store.pipe(
      this.getTrendSelector(subtype),
      map((trend: Trend) => getCounterValue<T>(trend, counterIndex, resultIndex)),
    );
  }

  /**
   * getBucketingAttributeValue$
   * @param {StandardWidgetSubtype} subtype
   * @param {number} [bucketingAttributeIndex=0]
   * @returns {T}
   * @memberof TrendComposerService
   */
  public getBucketingAttributeValue$<T = string>(subtype: StandardWidgetSubtype, bucketingAttributeIndex: number = 0): Observable<T> {
    return this.store.pipe(
      this.getTrendSelector(subtype),
      map((trend: Trend) => getBucketingAttributeValue(trend, bucketingAttributeIndex)),
    );
  }

  /**
   * getTrendMetricTabs$
   * @param {StandardWidgetSubtype} subtype
   * @param {Array<Partial<MetricTab>>} partialMetricTabs
   * @returns {Observable<MetricTab[]>}
   * @memberof TrendComposerService
   */
  public getTrendMetricTabs$(subtype: StandardWidgetSubtype, partialMetricTabs: Array<Partial<MetricTab>>): Observable<MetricTab[]> {
    return this.store.pipe(
      this.getTrendSelector(subtype),
      map((trend: Trend) => {
        return partialMetricTabs.map((partialMetricTab: Partial<MetricTab>, counterIndex: number) => {
          return new MetricTab({
            ...partialMetricTab,
            value: getCounterValue(trend, counterIndex, 0),
          });
        });
      }),
    );
  }

  /**
   * getTrendChangeRatio$
   * @param {StandardWidgetSubtype} currentWidgetType
   * @param {StandardWidgetSubtype} previousWidgetType
   * @returns {Observable<number>}
   * @memberof TrendComposerService
   */
  public getTrendChangeRatio$(currentWidgetType: StandardWidgetSubtype, previousWidgetType: StandardWidgetSubtype): Observable<number> {
    return combineLatest([this.getTrendCount$(currentWidgetType), this.getTrendCount$(previousWidgetType)]).pipe(
      map(([count1, count2]: [number, number]) => (isNaN(count1 / count2) ? -1 : count1 / count2 - 1)),
    );
  }

  /**
   * getTrendSum$
   * @param {StandardWidgetSubtype} subtype
   * @returns {Observable<number>}
   * @memberof TrendComposerService
   */
  public getTrendSum$(subtype: StandardWidgetSubtype): Observable<number> {
    return this.getTrend$(subtype).pipe(
      map((trend: Trend) => {
        if (!trend) {
          return 0;
        }
        return trend.trendResults?.reduce((sum: number, trendResult: TrendResult) => {
          return sum + (trendResult.counters[0].result.value || 0);
        }, 0);
      }),
    );
  }

  /**
   * isLoadingState$
   * @param {StandardWidgetSubtype} subtype
   * @returns {Observable<boolean>}
   * @memberof TrendComposerService
   */
  public isLoadingState$(subtype: StandardWidgetSubtype): Observable<boolean> {
    return this.getTrend$(subtype).pipe(map((trend: Trend) => !trend?.trendResults));
  }

  /**
   * getTrend$
   * @param {StandardWidgetSubtype} subtype
   * @returns {Observable<Trend>}
   * @memberof TrendComposerService
   */
  public getTrend$(subtype: StandardWidgetSubtype): Observable<Trend> {
    return this.store.pipe(this.getTrendSelector(subtype));
  }

  /**
   * getTrendAvg$
   * @param {StandardWidgetSubtype} subtype
   * @returns {Observable<number>}
   * @memberof TrendComposerService
   */
  public getTrendAvg$(subtype: StandardWidgetSubtype): Observable<number> {
    return this.getTrend$(subtype).pipe(
      map((trend: Trend) => {
        if (!trend?.trendResults?.length) {
          return 0;
        }
        const { count, total } = trend.trendResults?.reduce(
          (agg: { total: number; count: number }, trendResult: TrendResult) => {
            const counterValue = trendResult.counters[0].result.value || 0;
            agg.count += counterValue ? 1 : 0;
            agg.total += counterValue;
            return agg;
          },
          { count: 0, total: 0 },
        );
        return count !== 0 ? total / count : 0;
      }),
    );
  }

  /**
   * getTrendSelector
   * @param {StandardWidgetSubtype} subtype
   * @param {ComposeConfigIndex} composeConfigIndex
   * @returns {TrendSelector}
   * @memberof TrendComposerService
   */
  public getTrendSelector(
    subtype: StandardWidgetSubtype,
    composeConfigIndex: ComposeConfigIndex = TrendComposer.defaultConfigIndex,
  ): TrendSelector {
    const trendComposer = new TrendComposer(composeConfigIndex);
    return ($state: Observable<CoreAppState>): Observable<Trend> => {
      return $state.pipe(
        select(DashboardSelectors.getStandardDashboardData),
        map((standardDashboardData: Map<string, Trend>) => {
          return trendComposer.getTrendForSubtype(StandardWidgetSubtype[subtype], standardDashboardData);
        }),
        // only emit if subtype trend has changed, since this selector fires for every trend when any standard dashboard data is changed
        distinctUntilChanged(isEqual),
      );
    };
  }

  /**
   * getWidgetDetailCompositeTrend$
   * @returns {Observable<Trend>}
   * @memberof TrendComposerService
   */
  public getWidgetDetailCompositeTrend$(): Observable<Trend> {
    return combineLatest([
      this.store.select(DashboardSelectors.getWidgetDetailInitialCompositeTrendDefinition),
      this.store.select(DashboardSelectors.getWidgetDetailCompositeTrendData),
    ]).pipe(
      filter(([compositeTrendDefinition, trendData]: [CompositeTrendDefinition, Map<string, Trend>]) => {
        return !!(compositeTrendDefinition && trendData);
      }),
      map(([compositeTrendDefinition, trendData]: [CompositeTrendDefinition, Map<string, Trend>]) => {
        const trendComposer = new TrendComposer(compositeTrendDefinition.composeConfigs);
        return trendComposer.getTrendForSubtype(compositeTrendDefinition.mainName, trendData);
      }),
    );
  }

  /**
   * getCompositeTrendDefinition$
   * @param {StandardWidgetSubtype} subtype
   * @param {ComposeConfigIndex} composeConfigIndex
   * @returns {Observable<CompositeTrendDefinition>}
   * @memberof TrendComposerService
   */
  public getCompositeTrendDefinition$(
    subtype: StandardWidgetSubtype,
    composeConfigIndex: ComposeConfigIndex = TrendComposer.defaultConfigIndex,
  ): Observable<CompositeTrendDefinition> {
    return this.store.pipe(
      select(DashboardSelectors.getStandardDashboardData),
      map((trendsBySubtypeMap: Map<string, Trend>) => {
        const trendsBySubtype = fromPairs(Array.from(trendsBySubtypeMap));
        const trendDefinitionsBySubtype = mapValues(trendsBySubtype, (trend: Trend) => trend.trendDefinition);
        return this.getCompositeTrendDefinition(subtype, trendDefinitionsBySubtype, composeConfigIndex);
      }),
    );
  }

  /**
   * getCompositeTrendDefinition
   * @param {StandardWidgetSubtype} subtype
   * @param {Record<string, TrendDefinition>} trendDefinitionsBySubtype
   * @param {ComposeConfigIndex} composeConfigIndex
   * @returns {CompositeTrendDefinition}
   */
  public getCompositeTrendDefinition(
    subtype: StandardWidgetSubtype,
    trendDefinitionsBySubtype: Record<string, TrendDefinition>,
    composeConfigIndex: ComposeConfigIndex = TrendComposer.defaultConfigIndex,
  ): CompositeTrendDefinition {
    const trendComposer = new TrendComposer(composeConfigIndex);

    const dependencies = trendComposer.getSubtypeComposeConfigDependencies(StandardWidgetSubtype[subtype]);
    const nextComposeConfigs = dependencies.reduce((configs: any, subtypeStr: string) => {
      configs[subtypeStr] = composeConfigIndex[subtypeStr];
      return configs;
    }, {});

    const nextTrendDefinitions = {};
    const subtypeDependencies = new Set(trendComposer.getTrendDefinitionDependencies(StandardWidgetSubtype[subtype]));
    each(trendDefinitionsBySubtype, (trendDefinition: TrendDefinition, key: string) => {
      if (subtypeDependencies.has(key)) {
        nextTrendDefinitions[key] = trendDefinition;
      }
    });

    return new CompositeTrendDefinition({
      mainName: StandardWidgetSubtype[subtype],
      composeConfigs: nextComposeConfigs,
      trendDefinitions: nextTrendDefinitions,
    });
  }

  /**
   * loadNestedTrend$
   * @param {NestedTrendConfig} config
   * @returns {Observable<Action>}
   * @memberof TrendComposerService
   */
  public loadNestedTrend$(config: NestedTrendConfig): Observable<Action> {
    const { widgetSubtype, getNextAction } = config;
    return this.getTrend$(widgetSubtype).pipe(
      map((trend: Trend) => getNextAction(trend)),
      filter((action: Action) => !!action),
      tap((action: Action) => this.store.dispatch(action)),
      take(1),
    );
  }
}

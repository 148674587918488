/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Action, createReducer, on } from '@ngrx/store';

import { LOAD_STATE, MarketplaceResourceType, MarketplaceSearchResponse } from '@ws1c/intelligence-models';
import { getNextResourcePreviewRequest, getPrevResourcePreviewRequest, getResourcePreviewRequest } from './marketplace-reducer-helper';
import { MarketplaceActions } from './marketplace.actions';
import { initialMarketplaceState, MarketplaceState } from './marketplace.state';

const _reducer = createReducer(
  initialMarketplaceState,

  on(
    MarketplaceActions.setResourcePreviewVisibility,
    (state: MarketplaceState, { isVisible }: ReturnType<typeof MarketplaceActions.setResourcePreviewVisibility>): MarketplaceState => ({
      ...state,
      resourcePreview: {
        ...state.resourcePreview,
        isVisible,
      },
    }),
  ),

  on(
    MarketplaceActions.searchMarketplace,
    (state: MarketplaceState, { request, previewResource }: ReturnType<typeof MarketplaceActions.searchMarketplace>): MarketplaceState => {
      if (previewResource) {
        return {
          ...state,
          resourcePreview: {
            ...state.resourcePreview,
            request,
            previewResource,
          },
        };
      }
      return {
        ...state,
        searchRequest: request,
        searchLoadStatus: LOAD_STATE.IN_FLIGHT,
        resourcePreview: {
          ...state.resourcePreview,
          request,
        },
      };
    },
  ),

  on(
    MarketplaceActions.searchMarketplaceSuccess,
    (
      state: MarketplaceState,
      { response, previewResource, resourceType }: ReturnType<typeof MarketplaceActions.searchMarketplaceSuccess>,
    ): MarketplaceState => {
      if (previewResource) {
        return {
          ...state,
          resourcePreview: {
            ...state.resourcePreview,
            response,
          },
        };
      }
      let searchResponseByType: Partial<Record<MarketplaceResourceType, MarketplaceSearchResponse>>;
      if (resourceType) {
        searchResponseByType = {
          ...state.searchResponseByType,
          [resourceType]: response,
        };
      }
      return {
        ...state,
        searchResponse: response,
        searchLoadStatus: LOAD_STATE.SUCCESS,
        resourcePreview: {
          ...state.resourcePreview,
          response,
        },
        searchResponseByType,
      };
    },
  ),

  on(
    MarketplaceActions.searchMarketplaceFailure,
    (state: MarketplaceState): MarketplaceState => ({
      ...state,
      searchLoadStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    MarketplaceActions.clearMarketplaceSearchResponse,
    (state: MarketplaceState): MarketplaceState => ({
      ...state,
      searchResponse: undefined,
    }),
  ),

  on(
    MarketplaceActions.setFeaturedResource,
    (state: MarketplaceState, { resource }: ReturnType<typeof MarketplaceActions.setFeaturedResource>): MarketplaceState => {
      return {
        ...state,
        featuredResource: resource,
      };
    },
  ),

  on(
    MarketplaceActions.setPreviewResource,
    (
      state: MarketplaceState,
      { resource, request, response }: ReturnType<typeof MarketplaceActions.setPreviewResource>,
    ): MarketplaceState => {
      return {
        ...state,
        resourcePreview: {
          ...state.resourcePreview,
          previewResource: resource,
          request: request && response ? getResourcePreviewRequest(resource, request, response) : undefined,
          response: response ? state.resourcePreview.response : undefined,
        },
      };
    },
  ),

  on(
    MarketplaceActions.selectNextPreviewResource,
    (state: MarketplaceState, { resource }: ReturnType<typeof MarketplaceActions.selectNextPreviewResource>): MarketplaceState => {
      return {
        ...state,
        resourcePreview: {
          ...state.resourcePreview,
          previewResource: resource,
          request: getNextResourcePreviewRequest(state.resourcePreview.request),
        },
      };
    },
  ),

  on(
    MarketplaceActions.selectPrevPreviewResource,
    (state: MarketplaceState, { resource }: ReturnType<typeof MarketplaceActions.selectPrevPreviewResource>): MarketplaceState => {
      return {
        ...state,
        resourcePreview: {
          ...state.resourcePreview,
          previewResource: resource,
          request: getPrevResourcePreviewRequest(state.resourcePreview.request),
        },
      };
    },
  ),

  on(MarketplaceActions.addToWorkspace, (state: MarketplaceState): MarketplaceState => {
    return {
      ...state,
      resourcePreview: {
        ...state.resourcePreview,
        isVisible: false,
      },
      addToWorkspaceLoadStatus: LOAD_STATE.IN_FLIGHT,
    };
  }),

  on(MarketplaceActions.addToWorkspaceSuccess, (state: MarketplaceState): MarketplaceState => {
    return {
      ...state,
      addToWorkspaceLoadStatus: LOAD_STATE.SUCCESS,
    };
  }),

  on(MarketplaceActions.addToWorkspaceFailure, (state: MarketplaceState): MarketplaceState => {
    return {
      ...state,
      addToWorkspaceLoadStatus: LOAD_STATE.FAILURE,
    };
  }),

  on(
    MarketplaceActions.setFrontlineEnabledModalVisibility,
    (
      state: MarketplaceState,
      { isVisible }: ReturnType<typeof MarketplaceActions.setFrontlineEnabledModalVisibility>,
    ): MarketplaceState => ({
      ...state,
      isFrontlineEnabledModalVisible: isVisible,
    }),
  ),

  on(
    MarketplaceActions.fetchMarketplaceResourceCounts,
    (state: MarketplaceState): MarketplaceState => ({
      ...state,
      resourceCountLoadStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    MarketplaceActions.fetchMarketplaceResourceCountsSuccess,
    (
      state: MarketplaceState,
      { resourceCountByType }: ReturnType<typeof MarketplaceActions.fetchMarketplaceResourceCountsSuccess>,
    ): MarketplaceState => ({
      ...state,
      resourceCountByType,
      resourceCountLoadStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    MarketplaceActions.fetchMarketplaceResourceCountsFailure,
    (state: MarketplaceState): MarketplaceState => ({
      ...state,
      resourceCountByType: undefined,
      resourceCountLoadStatus: LOAD_STATE.FAILURE,
    }),
  ),
);

/**
 * MarketplaceState Reducer
 * @param {MarketplaceState} state
 * @param {Action} action
 * @returns {MarketplaceState}
 */
export function marketplaceState(state: MarketplaceState, action: Action) {
  return _reducer(state, action);
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { ROUTE_NAMES } from '@ws1c/intelligence-models';

const ERROR_CODES = {
  UEM_AUTH_SERVICE_ISSUE: 'UEM-AUTH-SERVICE-ISSUE',
  UEM_DEPLOY_SCRIPT_FAILED_INSUFFICIENT_ACCESS: 'UEM-DEPLOY-SCRIPT-FAILED-INSUFFICIENT-ACCESS',
  UEM_DEPLOY_SCRIPT_FAILED_NAME_EXISTS: 'UEM-DEPLOY-SCRIPT-FAILED-NAME-EXISTS',
  UEM_DEPLOY_SCRIPT_FAILED_UNKNOWN_ERROR: 'UEM-DEPLOY-SCRIPT-FAILED-UNKNOWN-ERROR',
  UEM_DEPLOY_SENSOR_FAILED_INSUFFICIENT_ACCESS: 'UEM-DEPLOY-SENSOR-FAILED-INSUFFICIENT-ACCESS',
  UEM_DEPLOY_SENSOR_FAILED_NAME_EXISTS: 'UEM-DEPLOY-SENSOR-FAILED-NAME-EXISTS',
  UEM_DEPLOY_SENSOR_FAILED_UNKNOWN_ERROR: 'UEM-DEPLOY-SENSOR-FAILED-UNKNOWN-ERROR',
  UEM_SCRIPT_METADATA_ISSUE: 'UEM-SCRIPT-METADATA-ISSUE',
  UEM_SENSOR_METADATA_ISSUE: 'UEM-SENSOR-METADATA-ISSUE',
  UEM_INTEGRATION_DISABLED_FOR_SENSOR_DEPLOY: 'UEM-INTEGRATION-DISABLED-FOR-SENSOR-DEPLOY',
  UEM_TENANT_CONFIG_ISSUE_FOR_SCRIPT_DEPLOY: 'UEM-TENANT-CONFIG-ISSUE-FOR-SCRIPT-DEPLOY',
  UEM_TENANT_CONFIG_ISSUE_FOR_SENSOR_DEPLOY: 'UEM-TENANT-CONFIG-ISSUE-FOR-SENSOR-DEPLOY',
  WS1C_CLOUD_LICENSE_MISSING: 'WS1C-CLOUD-LICENSE-MISSING',
};

export const ERROR_I18N_KEY_BY_ERROR_CODE: Record<string, string> = {
  [ERROR_CODES.UEM_AUTH_SERVICE_ISSUE]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.UEM_AUTH_ERROR',
  [ERROR_CODES.UEM_DEPLOY_SCRIPT_FAILED_INSUFFICIENT_ACCESS]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SCRIPT_PERMISSION_ERROR',
  [ERROR_CODES.UEM_DEPLOY_SCRIPT_FAILED_NAME_EXISTS]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SCRIPT_EXISTS_ERROR',
  [ERROR_CODES.UEM_DEPLOY_SCRIPT_FAILED_UNKNOWN_ERROR]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SCRIPT_DEPLOY_ERROR',
  [ERROR_CODES.UEM_DEPLOY_SENSOR_FAILED_INSUFFICIENT_ACCESS]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SENSOR_PERMISSION_ERROR',
  [ERROR_CODES.UEM_DEPLOY_SENSOR_FAILED_NAME_EXISTS]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SENSOR_EXISTS_ERROR',
  [ERROR_CODES.UEM_DEPLOY_SENSOR_FAILED_UNKNOWN_ERROR]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SENSOR_DEPLOY_ERROR',
  [ERROR_CODES.UEM_SCRIPT_METADATA_ISSUE]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SCRIPT_METADATA_ERROR',
  [ERROR_CODES.UEM_SENSOR_METADATA_ISSUE]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SENSOR_METADATA_ERROR',
  [ERROR_CODES.UEM_INTEGRATION_DISABLED_FOR_SENSOR_DEPLOY]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SENSOR_UEM_INTEGRATION_DISABLED_ERROR',
  [ERROR_CODES.UEM_TENANT_CONFIG_ISSUE_FOR_SCRIPT_DEPLOY]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SCRIPT_UEM_CONFIG_ERROR',
  [ERROR_CODES.UEM_TENANT_CONFIG_ISSUE_FOR_SENSOR_DEPLOY]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.SENSOR_UEM_CONFIG_ERROR',
  [ERROR_CODES.WS1C_CLOUD_LICENSE_MISSING]: 'MARKETPLACE.SCRIPTS_AND_SENSORS.WS1_CLOUD_LICENSE_MISSING_ERROR',
};

export const URL_LINK_BY_ERROR_CODE: Record<string, string> = {
  [ERROR_CODES.UEM_INTEGRATION_DISABLED_FOR_SENSOR_DEPLOY]: `/#/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS}`,
};

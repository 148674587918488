/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';

import { DeemUxScoreThreshold, EntityThresholds } from '@ws1c/intelligence-models';

/**
 * ThresholdRangeDisplayComponent
 * @export
 * @class ThresholdRangeDisplayComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-threshold-range-display',
  templateUrl: 'threshold-range-display.component.html',
  styleUrls: ['threshold-range-display.component.scss'],
})
export class ThresholdRangeDisplayComponent implements OnChanges {
  @Input() public dataValue: string;
  @Input() public experienceScoreType: string;
  @Input() public tableColumnMap: GenericObject;
  @Input() public thresholdsV2: EntityThresholds;

  public threshold: DeemUxScoreThreshold;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof ThresholdRangeDisplayComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (!(changes.thresholdsV2 || changes.dataValue)) {
      return;
    }

    this.threshold = this.thresholdsV2?.[this.dataValue]?.[0]?.metricHolder.threshold;
  }
}

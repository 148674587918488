/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { NavigationMenuGroup, UIPreference } from '@ws1c/intelligence-models';

/**
 * NavigationMenuActions
 *
 * @export
 * @class NavigationMenuActions
 */
export class NavigationMenuActions {
  public static readonly CATEGORY = '[NAVIGATION_MENU]';

  public static readonly ACTIVATE_NAVIGATION_MENU_ITEM = `${NavigationMenuActions.CATEGORY} ACTIVATE_NAVIGATION_MENU_ITEM`;
  // eslint-disable-next-line max-len
  public static readonly LOAD_DEFAULT_MENU_ITEM_ON_INTRO_ACKNOWLEDGEMENT = `${NavigationMenuActions.CATEGORY} LOAD_DEFAULT_MENU_ITEM_ON_INTRO_ACKNOWLEDGEMENT`;
  // eslint-disable-next-line max-len
  public static readonly SET_SECONDARY_NAVIGATION_COLLAPSED_FLAG = `${NavigationMenuActions.CATEGORY} SET_SECONDARY_NAVIGATION_COLLAPSED_FLAG`;
  // eslint-disable-next-line max-len
  public static readonly TOGGLE_SECONDARY_NAVIGATION_MENU_GROUP = `${NavigationMenuActions.CATEGORY} TOGGLE_SECONDARY_NAVIGATION_MENU_GROUP`;

  public static activateNavigationMenuItem = createAction(
    NavigationMenuActions.ACTIVATE_NAVIGATION_MENU_ITEM,
    props<{
      activatedNavMenuItemIndex: number;
      loadDefaultRoute: boolean;
    }>(),
  );

  public static navigateOnIntroAcknowledgement = createAction(
    NavigationMenuActions.LOAD_DEFAULT_MENU_ITEM_ON_INTRO_ACKNOWLEDGEMENT,
    props<{ preference: UIPreference }>(),
  );

  public static setSecondaryNavigationCollapsedFlag = createAction(
    NavigationMenuActions.SET_SECONDARY_NAVIGATION_COLLAPSED_FLAG,
    props<{
      isCollapsed: boolean;
      saveToUserPreference?: boolean;
    }>(),
  );

  public static toggleSecondaryNavigationMenuGroup = createAction(
    NavigationMenuActions.TOGGLE_SECONDARY_NAVIGATION_MENU_GROUP,
    props<{ navMenuGroup: NavigationMenuGroup }>(),
  );
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Automation, CustomReportPreviewSearchResponse, LOAD_STATE, Trend } from '@ws1c/intelligence-models';

/**
 * PreviewCommonState
 *
 * @export
 * @interface PreviewCommonState
 */
export interface PreviewCommonState {
  widgetPreview: Trend;
  widgetPreviewLoadStatus: LOAD_STATE;
  reportPreview: CustomReportPreviewSearchResponse;
  reportPreviewLoadStatus: LOAD_STATE;
  reportPreviewColumnNames: string[];
  automationPreview: Automation;
  automationPreviewLoadStatus: LOAD_STATE;
}

/**
 * Defines initial state for ReportCommon
 *
 * @export
 * @type {PreviewCommonState}
 */
export const initialPreviewCommonState: PreviewCommonState = {
  widgetPreview: undefined,
  widgetPreviewLoadStatus: LOAD_STATE.NONE,
  reportPreview: undefined,
  reportPreviewLoadStatus: LOAD_STATE.NONE,
  reportPreviewColumnNames: [],
  automationPreview: undefined,
  automationPreviewLoadStatus: LOAD_STATE.NONE,
};

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { TitleCasePipe } from '@angular/common';

import { ChartMarkerService } from './chart-marker.service';
import { NgxChartFactoryService } from './ngx-chart-factory.service';

export const DASHBOARD_COMMON_PROVIDERS: any[] = [ChartMarkerService, NgxChartFactoryService, TitleCasePipe];

export * from './chart-marker.service';
export * from './ngx-chart-factory.service';

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { SortOn } from '@dpa/ui-common';

import {
  AutomationTestConnectionResult,
  AvailableService,
  AvailableServiceSearchRequest,
  AvailableServiceSearchResponse,
  ConnectionModalSection,
} from '@ws1c/intelligence-models';

/**
 * ConnectionCommonState
 *
 * @export
 * @interface ConnectionCommonState
 */
export interface ConnectionCommonState {
  automationServicesRequest: AvailableServiceSearchRequest;
  automationServicesResponse: AvailableServiceSearchResponse;
  automationServicesPageResults: AvailableService[];
  connectionModalSection: ConnectionModalSection;
  testConnectionResult: AutomationTestConnectionResult;
}

/**
 * Defines initial state for ConnectionCommon
 *
 * @export
 * @type {ConnectionCommonState}
 */
export const initialConnectionCommonState: ConnectionCommonState = {
  automationServicesRequest: new AvailableServiceSearchRequest({
    automationSupported: true,
    sortOns: [
      new SortOn({
        by: 'managed',
        reverse: true,
      }),
      new SortOn({
        by: 'service_type',
        reverse: false,
      }),
      new SortOn({
        by: 'label',
        reverse: false,
      }),
    ],
    from: 0,
    size: 10,
  }),
  automationServicesResponse: undefined,
  automationServicesPageResults: [],
  connectionModalSection: ConnectionModalSection.NONE,
  testConnectionResult: undefined,
};

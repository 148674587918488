/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationEnd, Route, Router, UrlSegment } from '@angular/router';
import { BreadCrumb, Tab } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { keys } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';

import { FeatureControl, I18NService, RouteItem, RoutePage } from '@ws1c/intelligence-common';
import { UserPreferenceCommonSelectors } from '@ws1c/intelligence-core/store';
import { FeatureControls } from '@ws1c/intelligence-models';

/**
 * PageService
 *
 * Service for managing page context (bread crumbs, page title, tabs, etc.)
 * Doc: https://confluence-euc.eng.vmware.com/pages/viewpage.action?pageId=244211934
 * @export
 * @class PageService
 */
@Injectable({
  providedIn: 'root',
})
export class PageService {
  public static readonly pageVariablePattern = /\{\S+}/g;

  public breadCrumbs$: BehaviorSubject<BreadCrumb[]> = new BehaviorSubject([]);
  public returnBreadCrumbs$: BehaviorSubject<BreadCrumb[]> = new BehaviorSubject([]);
  public pageTitle$: BehaviorSubject<string> = new BehaviorSubject('');
  public tabs$: BehaviorSubject<Tab[]> = new BehaviorSubject([]);
  public rawTabsMap$: BehaviorSubject<Map<string | number, Tab>> = new BehaviorSubject(new Map<string | number, Tab>());
  public currentTabId$: BehaviorSubject<string | number> = new BehaviorSubject(undefined);

  private routeItems: RouteItem[] = [];
  private rawBreadCrumbs: BreadCrumb[] = [];
  private currentTabId: any;
  private rawTabs: Tab[] = [];
  private rawTabsMap: Map<string | number, Tab> = new Map<string | number, Tab>();
  private labelsByVar: Record<string, string> = {};
  private enabledFeatureFlags: Set<string> = new Set();

  /**
   * Constructor
   * @param {I18NService} i18NService
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @param {Store} store
   * @memberOf PageService
   */
  constructor(
    private i18NService: I18NService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        startWith(true),
        switchMap(() => this.store.select(UserPreferenceCommonSelectors.getFeatureControls)),
      )
      .subscribe((featureControls: FeatureControls) => this.onNavigationChange(this.route.snapshot, featureControls));
  }

  /**
   * onNavigationChange
   * @param {ActivatedRouteSnapshot} route
   * @param {FeatureControls} featureControls
   * @memberOf PageService
   */
  public onNavigationChange(route: ActivatedRouteSnapshot, featureControls: FeatureControls) {
    this.enabledFeatureFlags = new Set(keys(featureControls).filter((featureKey: string) => featureControls[featureKey]));
    const routes = this.getFullRoutePathFromRoot(route);
    this.setRouteItems(routes);
    this.buildTabs();
    this.buildBreadCrumbs();
    this.emitPageContext();
  }

  /**
   * setPageLabels
   * @param {Record<string, string>} labels
   * @memberOf PageService
   */
  public setPageLabels(labels: Record<string, string>) {
    this.labelsByVar = {
      ...this.labelsByVar,
      ...labels,
    };
    this.emitPageContext();
  }

  /**
   * setRouteItems
   * @param {ActivatedRouteSnapshot[]} routes
   * @memberOf PageService
   */
  private setRouteItems(routes: ActivatedRouteSnapshot[]) {
    this.routeItems = routes
      .map((route: ActivatedRouteSnapshot) => {
        const page: RoutePage = route.routeConfig?.data?.page;

        const labelVars = page?.titleKey?.match(PageService.pageVariablePattern)?.map((labelVar: string) => labelVar.slice(1, -1));
        labelVars?.forEach((labelVar: string) => {
          if (labelVar.startsWith(':')) {
            this.labelsByVar[labelVar] = route.params[labelVar.slice(1)];
          } else {
            this.labelsByVar[labelVar] ??= '----';
          }
        });

        const routeItem: RouteItem = {
          page,
          urlSegments: route.url,
          path: route.routeConfig?.path,
          queryParams: route.queryParams,
        };
        if (!page) {
          return routeItem;
        }
        routeItem.page = this.getRoutePage(page);
        if (page.associatedTab !== undefined && route.parent?.component) {
          routeItem.siblings = route.parent?.routeConfig?.children
            ?.filter((routeConfig: Route) => {
              const childPage = routeConfig?.data?.page;
              if (childPage?.associatedTab === undefined) {
                return false;
              }
              return !!this.getRoutePage(childPage);
            })
            .map((childRoute: Route) => ({
              path: childRoute.path,
              page: this.getRoutePage(childRoute.data.page),
            }));
        }
        return routeItem;
      })
      .filter((item: RouteItem) => !!item.path || !!item.page);
  }

  /**
   * getRoutePage
   * @param {RoutePage} page
   * @returns {RoutePage}
   * @memberOf PageService
   */
  private getRoutePage(page: RoutePage): RoutePage {
    let newPage = page;
    if (Array.isArray(page)) {
      page.forEach((routePage: RoutePage) => {
        newPage = this.isPageEnabled(routePage) ? routePage : newPage;
      });
    }
    return this.isPageEnabled(newPage) ? newPage : undefined;
  }

  /**
   * isPageEnabled
   * @param {RoutePage} page
   * @returns {boolean}
   * @memberOf PageService
   */
  private isPageEnabled(page: RoutePage): boolean {
    if (!page.featureControls) {
      return true;
    }
    if (page.hideOnAnyFeatureControl) {
      const isAnyFeaturesEnabled = page.featureControls.some((featureControl: FeatureControl) =>
        this.enabledFeatureFlags.has(featureControl),
      );
      return !isAnyFeaturesEnabled;
    }
    return page.featureControls.every((featureControl: FeatureControl) => this.enabledFeatureFlags.has(featureControl));
  }

  /**
   * buildTabs
   * @memberOf PageService
   */
  private buildTabs() {
    this.rawTabsMap.clear();
    this.rawTabs.length = 0;
    this.routeItems.forEach((routeItem: RouteItem) => {
      if (!routeItem.siblings) {
        return;
      }
      this.rawTabsMap.clear();
      this.rawTabs.length = 0;
      routeItem.siblings.forEach((childRouteItem: RouteItem) => {
        if (!childRouteItem.page) {
          return;
        }
        const newTab = {
          name: this.i18NService.translate(childRouteItem.page.titleKey),
          link: childRouteItem.path,
          isActive: childRouteItem.page.associatedTab === routeItem?.page?.associatedTab,
        };
        if (newTab.isActive) {
          this.currentTabId = routeItem.page.associatedTab;
        }
        this.rawTabsMap.set(childRouteItem.page.associatedTab, newTab);
        this.rawTabs.push(newTab);
      });
    });
  }

  /**
   * getFullRoutePathFromRoot
   * @param {ActivatedRouteSnapshot} route
   * @returns {ActivatedRouteSnapshot[]}
   * @memberOf PageService
   */
  private getFullRoutePathFromRoot(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot[] {
    if (!route.firstChild) {
      return route.pathFromRoot;
    }
    return this.getFullRoutePathFromRoot(route.firstChild);
  }

  /**
   * buildBreadCrumbs
   * @memberOf PageService
   */
  private buildBreadCrumbs() {
    const breadCrumbs = [];
    let currentRoute = '';
    this.routeItems.forEach((routeItem: RouteItem) => {
      currentRoute = [currentRoute, ...routeItem.urlSegments.map((segment: UrlSegment) => segment.path)].join('/');
      if (!routeItem.page) {
        return;
      }
      const { titleKey, overrideParent, disableLink, associatedTab }: RoutePage = routeItem.page;
      const newBreadCrumb = new BreadCrumb({
        pathUrl: disableLink ? undefined : currentRoute,
        pathLabelKey: titleKey,
        navOptions: routeItem.queryParams ? { queryParams: routeItem.queryParams } : undefined,
      });
      if (!breadCrumbs.length) {
        breadCrumbs.push(newBreadCrumb);
        return;
      }
      if (overrideParent) {
        const parent = breadCrumbs.pop();
        parent.pathLabelKey = overrideParent;
        breadCrumbs.push(parent);
      }
      if (associatedTab !== undefined) {
        const parent = breadCrumbs.pop();
        parent.pathLabelKey = `${this.i18NService.translate(parent.pathLabelKey)}: ${this.i18NService.translate(titleKey)}`;
        parent.pathUrl = currentRoute;
        breadCrumbs.push(parent);
        return;
      }
      breadCrumbs.push(newBreadCrumb);
    });
    this.rawBreadCrumbs = breadCrumbs;
  }

  /**
   * resolveLabelVars
   * @param {string} variableString
   * @returns {string}
   * @memberOf PageService
   */
  private resolveLabelVars(variableString: string): string {
    return variableString.replace(PageService.pageVariablePattern, (value: string) => this.labelsByVar[value.slice(1, -1)] ?? '----');
  }

  /**
   * emitPageContext
   * @memberOf PageService
   */
  private emitPageContext() {
    const returnBreadCrumbs = this.rawBreadCrumbs.map(
      (breadCrumb: BreadCrumb) =>
        new BreadCrumb({
          ...breadCrumb,
          pathLabelKey: this.resolveLabelVars(breadCrumb.pathLabelKey),
        }),
    );
    this.returnBreadCrumbs$.next(returnBreadCrumbs);
    this.breadCrumbs$.next(returnBreadCrumbs.slice(0, -1));
    this.rawTabsMap$.next(this.rawTabsMap);
    this.tabs$.next(
      this.rawTabs.map((tab: Tab) => ({
        ...tab,
        name: this.resolveLabelVars(tab.name),
      })),
    );
    this.currentTabId$.next(this.currentTabId);
    let pageTitle = returnBreadCrumbs.slice(-1)[0]?.pathLabelKey;
    if (this.routeItems.slice(-1)[0]?.page?.associatedTab) {
      const pageTitleSegments = pageTitle?.split(':');
      pageTitle = pageTitleSegments?.length > 1 ? pageTitleSegments.slice(0, -1).join(':') : pageTitleSegments?.[0];
    }
    if (pageTitle) {
      this.pageTitle$.next(this.i18NService.translate(pageTitle));
    }
  }
}

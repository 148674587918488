/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { CspAccount, Directory, LOAD_STATE, RequestStatusType, UserAdminAccount } from '@ws1c/intelligence-models';
import { UserAdminAccountState } from './user-admin-account.state';

/**
 * UserAdminAccountSelector
 * @export
 * @class UserAdminAccountSelector
 */
export class UserAdminAccountSelector {
  /**
   * selectUserAdminAccountState
   *
   * @static
   * @param {CoreAppState} state
   * @returns {UserAdminAccountState}
   * @memberof UserAdminAccountSelector
   */
  public static selectUserAdminAccountState: (state: CoreAppState) => UserAdminAccountState = (
    state: CoreAppState,
  ): UserAdminAccountState => {
    return state.userAdminAccountState;
  };

  /**
   * isAzureDirectoryLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberof UserAdminAccountSelector
   */
  public static isAzureDirectoryLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserAdminAccountSelector.selectUserAdminAccountState,
    (state: UserAdminAccountState) => state.loadingAzure,
  );

  /**
   * getAzureDirectory
   * @static
   * @type {MemoizedSelector<CoreAppState, Directory>}
   * @returns {Directory}
   * @memberof UserAdminAccountSelector
   */
  public static getAzureDirectory: MemoizedSelector<CoreAppState, Directory> = createSelector(
    UserAdminAccountSelector.selectUserAdminAccountState,
    (state: UserAdminAccountState) => state.azureDirectory,
  );

  /**
   * isUserSearchLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserAdminAccountSelector
   */
  public static isUserSearchLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserAdminAccountSelector.selectUserAdminAccountState,
    (state: UserAdminAccountState) => state.isUserSearchLoading,
  );

  /**
   * getUserSearchResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, UserAdminAccount[]>}
   * @memberof UserAdminAccountSelector
   */
  public static getUserSearchResponse: MemoizedSelector<CoreAppState, UserAdminAccount[]> = createSelector(
    UserAdminAccountSelector.selectUserAdminAccountState,
    (state: UserAdminAccountState) => state.userSearchResponse.filter((user: UserAdminAccount) => user.id !== state.currentOwnerAccountId),
  );

  /**
   * getCspAccountRequestDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, CspAccount>}
   * @memberof UserAdminAccountSelector
   */
  public static getCspAccountRequestDetails: MemoizedSelector<CoreAppState, CspAccount> = createSelector(
    UserAdminAccountSelector.selectUserAdminAccountState,
    (state: UserAdminAccountState) => state.cspAccountRequestDetails,
  );

  /**
   * getCspAccountRequestStatus
   * @static
   * @type {MemoizedSelector<CoreAppState, RequestStatusType>}
   * @memberof UserAdminAccountSelector
   */
  public static getCspAccountRequestStatus: MemoizedSelector<CoreAppState, RequestStatusType> = createSelector(
    UserAdminAccountSelector.selectUserAdminAccountState,
    (state: UserAdminAccountState) => state.cspAccountRequestDetails?.cspMigrationStatus,
  );

  /**
   * getCspAccountRequestEmail
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof UserAdminAccountSelector
   */
  public static getCspAccountRequestEmail: MemoizedSelector<CoreAppState, string> = createSelector(
    UserAdminAccountSelector.selectUserAdminAccountState,
    (state: UserAdminAccountState) => state.cspAccountRequestDetails?.email,
  );

  /**
   * isCspAccountWithEmailNotExists
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserAdminAccountSelector
   */
  public static isCspAccountWithEmailNotExists: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserAdminAccountSelector.selectUserAdminAccountState,
    (state: UserAdminAccountState) => !state.isCspAccountWithEmailAlreadyExists,
  );

  /**
   * isRequestForCspAccountInProgress
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserAdminAccountSelector
   */
  public static isRequestForCspAccountInProgress: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserAdminAccountSelector.selectUserAdminAccountState,
    (state: UserAdminAccountState) => state.cspAccountRequestSubmitState === LOAD_STATE.IN_FLIGHT,
  );
}

/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { RbacState } from './rbac.state';

/**
 * RbacSelectors
 *
 * @export
 * @class RbacSelectors
 */
export class RbacSelectors {
  public static selectRbacState: Selector<CoreAppState, RbacState> = (state: CoreAppState) => state.rbacState;

  /**
   * getUserScopes returns empty array if userSessionScopes is undefined
   * This selector is used in RoleGuard to make sure that userSessionScopes are loaded.
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @returns {string[]}
   * @memberOf RbacSelectors
   */
  public static getUserScopesUnmodified: MemoizedSelector<CoreAppState, string[]> = createSelector(
    RbacSelectors.selectRbacState,
    (state: RbacState) => state?.userSessionScopes,
  );

  /**
   * isEmailSentForAccessRequest
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf RbacSelectors
   */
  public static isEmailSentForAccessRequest: MemoizedSelector<CoreAppState, boolean> = createSelector(
    RbacSelectors.selectRbacState,
    (state: RbacState) => state.isEmailSentForAccessRequest,
  );

  /**
   * getAdminCountForAccessRequest
   * @type {MemoizedSelector<CoreAppState, number>}
   * @returns {number}
   * @memberOf RbacSelectors
   */
  public static getAdminCountForAccessRequest: MemoizedSelector<CoreAppState, number> = createSelector(
    RbacSelectors.selectRbacState,
    (state: RbacState) => state.adminCountForAccessRequest,
  );

  /**
   * getUserScopes
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @returns {string[]}
   * @memberOf RbacSelectors
   */
  public static getUserScopes: MemoizedSelector<CoreAppState, string[]> = createSelector(
    RbacSelectors.getUserScopesUnmodified,
    (userSessionScopes: string[]) => userSessionScopes || [],
  );

  /**
   * hasUserScopes
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {Observable<boolean>}
   * @memberOf RbacSelectors
   */
  public static hasUserScopes: MemoizedSelector<CoreAppState, boolean> = createSelector(
    RbacSelectors.getUserScopes,
    (userScopes: string[]) => !!userScopes && userScopes.length !== 0,
  );
}

/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, JsonProperty, Serializable } from '@dpa/ui-common';
import { flatten, values } from 'lodash-es';

import { AirwatchAppSearchItem } from '@ws1c/intelligence-models';

/**
 * appIndexFlattener
 * @export
 * @type {CustomConverter}
 */
export const appIndexFlattener: CustomConverter = {
  fromJson(data: object): AirwatchAppSearchItem[] {
    const flattenedData = flatten(values(data));
    return flattenedData.map((appData: any) => deserialize(AirwatchAppSearchItem, appData));
  },
  toJson(): object {
    return undefined;
  },
};

/**
 * AirwatchAppSearchResponse
 * @export
 * @class AirwatchAppSearchResponse
 */
@Serializable
export class AirwatchAppSearchResponse {
  @JsonProperty({ name: 'data', customConverter: appIndexFlattener })
  public data: AirwatchAppSearchItem[] = undefined;
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { BaseChartComponent, calculateViewDimensions, ColorHelper, ViewDimensions } from '@dpa/ui-common';

import { WidgetConfig, WidgetHoverInfo, WidgetHoverSeries, WidgetSequence } from '@ws1c/intelligence-models';

/**
 * BaseOverlayChartComponent
 * @export
 * @class BaseOverlayChartComponent
 * @extends {BaseChartComponent}
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-base-overlay-chart',
  template: '',
})
export class BaseOverlayChartComponent extends BaseChartComponent implements OnChanges {
  @Input() public focussedSequence: WidgetSequence;
  @Input() public showDataLabel: boolean = false;
  @Input() public xAxisLabel: string;
  @Input() public yAxisLabel: string;
  @Input() public yAxisRightLabel: string;
  @Input() public showXAxisLabel: boolean = true;
  @Input() public showYAxisLabel: boolean = true;
  @Input() public tooltipTemplate: TemplateRef<any>;
  @Input() public overlayResults = [];

  public colors: ColorHelper;
  public colorsForOverlay: ColorHelper;
  public dims: ViewDimensions;
  public dataLabelMaxHeight: any = { negative: 0, positive: 0 };
  public margin: number[] = [10, 20, 10, 20];
  public xAxisHeight: number = 0;
  public yAxisWidth: number = 0;
  public transform: string;
  public hoveredSeriesName: string;
  public widgetHoverInfo: WidgetHoverInfo[];

  public mainChartOpacity = 1;
  public overlayOpacity = 1;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof BaseOverlayChartComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.focussedSequence) {
      this.mainChartOpacity = this.focussedSequence === WidgetSequence.OVERLAY ? WidgetConfig.OUT_OF_FOCUS_OPACITY : 1;
      this.overlayOpacity = this.focussedSequence === WidgetSequence.MAIN ? WidgetConfig.OUT_OF_FOCUS_OPACITY : 1;
    }
  }

  /**
   * update
   * @memberof BaseOverlayChartComponent
   */
  public update() {
    super.update();
    if (!this.showDataLabel) {
      this.dataLabelMaxHeight = { negative: 0, positive: 0 };
    }
    this.margin = [10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.showXAxisLabel,
      showYAxis: this.showYAxisLabel,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth * 2,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: false,
    });
    this.formatDates();
    if (this.showDataLabel) {
      this.dims.height -= this.dataLabelMaxHeight.negative;
    }
    this.transform = `translate(${Math.max(60, this.dims.xOffset / 2)} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
  }

  /**
   * onHover
   * @param {any} item
   * @memberof BaseOverlayChartComponent
   */
  public onHover(item: any) {
    this.hoveredSeriesName = item.value;
    this.widgetHoverInfo = [];
    const matchingResult = this.results.find((result: any) => result.name === this.hoveredSeriesName);
    const isBarOverlay = !!matchingResult;
    if (isBarOverlay) {
      this.addBarOverlayResult(matchingResult);
    } else {
      this.addToHover(this.results, this.colors, item.value, this.yAxisLabel);
    }
    this.addToHover(this.overlayResults, this.colorsForOverlay, item.value, this.yAxisRightLabel);
  }

  /**
   * addBarOverlayResult
   * @private
   * @param {any} result
   * @memberof BaseOverlayChartComponent
   */
  private addBarOverlayResult(result) {
    const hoverSeries: WidgetHoverSeries[] = [];
    if (result.series?.length) {
      result.series.forEach((series: any) => {
        const color = this.colors?.customColors.find((customColor: any) => customColor.name === series.name)?.value;
        hoverSeries.push({
          name: series.name,
          value: series.value,
          color,
        });
      });
    } else {
      hoverSeries.push({
        name: this.yAxisLabel,
        value: result.value,
        color: this.colors.colorDomain[0],
      });
    }

    this.widgetHoverInfo.push({
      name: this.yAxisLabel,
      series: hoverSeries,
      total: result.value,
    });
  }

  /**
   * addToHover
   * @private
   * @param {any[]} results
   * @param {ColorHelper} colors
   * @param {string} hoverName
   * @param {string} label
   * @memberof BaseOverlayChartComponent
   */
  private addToHover(results: any[], colors: ColorHelper, hoverName: string, label: string) {
    const hoverSeries: WidgetHoverSeries[] = [];
    let total = 0;
    results.forEach((result: any) => {
      const hoveredSeries = result.series.find((seriesValue: any) => seriesValue.name === hoverName);
      if (!hoveredSeries) {
        return;
      }
      total += hoveredSeries.value;
      const color = colors?.customColors.find((customColor: any) => customColor.name === result.name)?.value ?? colors.colorDomain[0];
      hoverSeries.push({
        name: result.name,
        value: hoveredSeries.value,
        color,
      });
    });
    this.widgetHoverInfo.push({
      name: label,
      series: hoverSeries,
      total,
    });
  }
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { DeemSyntheticURLMonitoringConfig } from './deem-synthetic-url-monitoring-config.model';

/**
 * DeemSyntheticURLMonitoringSearchResponse
 * @export
 * @class DeemSyntheticURLMonitoringSearchResponse
 * @implements {PagedResponse}
 */
@Serializable
export class DeemSyntheticURLMonitoringSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: DeemSyntheticURLMonitoringConfig })
  public results: DeemSyntheticURLMonitoringConfig[] = undefined;

  /**
   * Initializes instance of DeemSyntheticURLMonitoringSearchResponse
   * @param {Array<Partial<DeemSyntheticURLMonitoringSearchResponse>>} args - object initialization arguments
   * @memberof DeemSyntheticURLMonitoringSearchResponse
   */
  constructor(...args: Array<Partial<DeemSyntheticURLMonitoringSearchResponse>>) {
    Object.assign(this, ...args);
  }
}

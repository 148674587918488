/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import { Automation, Column, ColumnIndex, CustomReportPreviewSearchResponse, LOAD_STATE, Trend } from '@ws1c/intelligence-models';
import { PreviewCommonState } from './preview-common.store';

/**
 * PreviewCommonSelectors
 *
 * @export
 * @class PreviewCommonSelectors
 */
export class PreviewCommonSelectors {
  /**
   * selectPreviewCommonState
   * @static
   * @param {CoreAppState} state
   * @returns {PreviewCommonState}
   * @memberof PreviewCommonSelectors
   */
  public static selectPreviewCommonState = (state: CoreAppState) => state.previewCommonState;

  /**
   * getWidgetPreview
   * @static
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof PreviewCommonSelectors
   */
  public static getWidgetPreview: MemoizedSelector<CoreAppState, Trend> = createSelector(
    PreviewCommonSelectors.selectPreviewCommonState,
    (state: PreviewCommonState) => state.widgetPreview,
  );

  /**
   * isWidgetPreviewLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof PreviewCommonSelectors
   */
  public static isWidgetPreviewLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    PreviewCommonSelectors.selectPreviewCommonState,
    (state: PreviewCommonState) => state.widgetPreviewLoadStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * getReportPreview
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof PreviewCommonSelectors
   */
  public static getReportPreview: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    PreviewCommonSelectors.selectPreviewCommonState,
    (state: PreviewCommonState) => state.reportPreview,
  );

  /**
   * getReportPreviewColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof PreviewCommonSelectors
   */
  public static getReportPreviewColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    PreviewCommonSelectors.selectPreviewCommonState,
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    (state: PreviewCommonState, columnsByName: ColumnIndex) => {
      return state.reportPreviewColumnNames?.map((columnName: string) => columnsByName?.[columnName]).filter((column: Column) => !!column);
    },
  );

  /**
   * isReportPreviewLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof PreviewCommonSelectors
   */
  public static isReportPreviewLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    PreviewCommonSelectors.selectPreviewCommonState,
    (state: PreviewCommonState) => state.reportPreviewLoadStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * getAutomationPreview
   * @static
   * @type {MemoizedSelector<CoreAppState, Automation>}
   * @memberof PreviewCommonSelectors
   */
  public static getAutomationPreview: MemoizedSelector<CoreAppState, Automation> = createSelector(
    PreviewCommonSelectors.selectPreviewCommonState,
    (state: PreviewCommonState) => state.automationPreview,
  );

  /**
   * isAutomationPreviewLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof PreviewCommonSelectors
   */
  public static isAutomationPreviewLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    PreviewCommonSelectors.selectPreviewCommonState,
    (state: PreviewCommonState) => state.automationPreviewLoadStatus === LOAD_STATE.IN_FLIGHT,
  );
}

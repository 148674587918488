/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { AlertBannerTarget } from '@ws1c/intelligence-models';
import { AlertBanner } from './alert-banner.state';

/**
 * Actions for the alert banner
 * @export
 * @class AlertBannerActions
 */
export class AlertBannerActions {
  public static CATEGORY = '[ALERT_BANNER]';

  public static showAlertBanner = createAction(`${AlertBannerActions.CATEGORY} SHOW_ALERT_BANNER`, props<AlertBanner>());

  public static dismissAlertBanner = createAction(
    `${AlertBannerActions.CATEGORY} DISMISS_ALERT_BANNER`,
    props<{ target: AlertBannerTarget }>(),
  );

  public static doNothing = createAction(`${AlertBannerActions.CATEGORY} DO_NOTHING`);
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * TableChartConfig
 * @export
 * @class TableChartConfig
 */
export class TableChartConfig {
  public static readonly selectableDefault = {
    enabled: false,
    single: false,
  };
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import {
  AllManagedConnectorsResponse,
  AutomationRunHistoryResponse,
  Connector,
  ConnectorAction,
  ConnectorConfig,
  ConnectorConfigStatus,
  ConnectorModalType,
  ConnectorSchema,
  ConnectorsSearchResponse,
  ConnectorsWithActionsResponse,
  GenericSearchRequest,
  LOAD_STATE,
  ManagedConnectorsSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * ConnectorCommonState
 *
 * @export
 * @interface ConnectorCommonState
 */
export interface ConnectorCommonState {
  connectorsQuickFilter: ConnectorConfigStatus;
  connectorsSearchQuery: string;
  connectorsSearchResponse: ConnectorsSearchResponse;
  connectorsSearchRequest: GenericSearchRequest;
  connectorsSearchLoadStatus: LOAD_STATE;
  loadingConnectorsWithActions: boolean;
  connectorsWithActionsResponse: ConnectorsWithActionsResponse;

  managedConnectorsSearchResponse: ManagedConnectorsSearchResponse;
  managedConnectorsSearchLoadStatus: LOAD_STATE;

  allManagedConnectorsResponse: AllManagedConnectorsResponse;
  allManagedConnectorsLoadStatus: LOAD_STATE;

  connectorsUsageLoadStatus: LOAD_STATE;
  connectorsUsageRequest: GenericSearchRequest;
  connectorsUsageResponse: AutomationRunHistoryResponse;

  modal: {
    type: ConnectorModalType;
    confirmLoadStatus: LOAD_STATE;
    connectorConfig: ConnectorConfig;
    connectorConfigSchema: ConnectorSchema;
    connector: Connector;
    actions: ConnectorAction[];
  };
}

/**
 * Defines initial state for ConnectorCommon
 *
 * @export
 * @type {ConnectorCommonState}
 */
export const initialConnectorCommonState: ConnectorCommonState = {
  connectorsQuickFilter: undefined,
  connectorsSearchQuery: '',
  connectorsSearchResponse: undefined,
  connectorsSearchRequest: undefined,
  connectorsSearchLoadStatus: LOAD_STATE.NONE,
  loadingConnectorsWithActions: false,
  connectorsWithActionsResponse: undefined,

  managedConnectorsSearchResponse: undefined,
  managedConnectorsSearchLoadStatus: LOAD_STATE.NONE,

  allManagedConnectorsResponse: undefined,
  allManagedConnectorsLoadStatus: LOAD_STATE.NONE,

  connectorsUsageLoadStatus: LOAD_STATE.NONE,
  connectorsUsageRequest: undefined,
  connectorsUsageResponse: undefined,

  modal: {
    type: undefined,
    confirmLoadStatus: LOAD_STATE.NONE,
    connectorConfig: undefined,
    connectorConfigSchema: undefined,
    connector: undefined,
    actions: undefined,
  },
};

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';
import { keyBy } from 'lodash-es';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import {
  AutomationTestConnectionResult,
  AvailableService,
  AvailableServicesById,
  AvailableServiceSearchRequest,
  ConnectionModalSection,
  ServiceType,
} from '@ws1c/intelligence-models';
import { ConnectionCommonState } from './connection-common.store';

/**
 * ConnectionCommonSelectors
 *
 * @export
 * @class ConnectionCommonSelectors
 */
export class ConnectionCommonSelectors {
  public static selectConnectionCommonState = (state: CoreAppState) => state.connectionCommonState;

  /**
   * getAutomationServicesPageResults
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AvailableService[]>}
   * @memberof ConnectionCommonSelectors
   */
  public static getAutomationServicesPageResults: MemoizedSelector<CoreAppState, AvailableService[]> = createSelector(
    ConnectionCommonSelectors.selectConnectionCommonState,
    (state: ConnectionCommonState) => state.automationServicesPageResults,
  );

  /**
   * hasMultipleUEMInstances
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof ConnectionCommonSelectors
   */
  public static hasMultipleUEMInstances: MemoizedSelector<CoreAppState, boolean> = createSelector(
    ConnectionCommonSelectors.getAutomationServicesPageResults,
    (services: AvailableService[]) => {
      return services.filter((service: AvailableService) => service.serviceSubtype === ServiceType[ServiceType.AIRWATCH_API_V1]).length > 1;
    },
  );

  /**
   * getAutomationServicesRequest
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AvailableServiceSearchRequest>}
   * @memberof ConnectionCommonSelectors
   */
  public static getAutomationServicesRequest: MemoizedSelector<CoreAppState, AvailableServiceSearchRequest> = createSelector(
    ConnectionCommonSelectors.selectConnectionCommonState,
    (state: ConnectionCommonState) => state.automationServicesRequest,
  );

  /**
   * getAvailableAutomationServicesById
   *
   * @type {MemoizedSelector<CoreAppState, AvailableServicesById>}
   * @returns {AvailableServicesById}
   * @memberOf ConnectionCommonSelectors
   */
  public static getAvailableAutomationServicesById: MemoizedSelector<CoreAppState, AvailableServicesById> = createSelector(
    ConnectionCommonSelectors.getAutomationServicesPageResults,
    (services: AvailableService[]) => keyBy(services, 'id'),
  );

  /**
   * getAvailableAutomationServicesIconsById
   *
   * @type {MemoizedSelector<CoreAppState, Record<string,string>>}
   * @returns {MemoizedSelector<CoreAppState, Record<string, string>>}
   * @memberOf ConnectionCommonSelectors
   */
  public static getAvailableAutomationServicesIconsById: (isTemplate: boolean) => MemoizedSelector<CoreAppState, Record<string, string>> = (
    isTemplate: boolean,
  ) =>
    createSelector(ConnectionCommonSelectors.getAvailableAutomationServicesById, (services: AvailableServicesById) => {
      return Object.values(services).reduce((accum, { id, serviceSubtype, iconLink }) => {
        return isTemplate ? { ...accum, [serviceSubtype]: iconLink } : { ...accum, [id]: iconLink };
      }, {});
    });

  /**
   * getConnectionModalSection
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectionModalSection>}
   * @memberof ConnectionCommonSelectors
   */
  public static getConnectionModalSection: MemoizedSelector<CoreAppState, ConnectionModalSection> = createSelector(
    ConnectionCommonSelectors.selectConnectionCommonState,
    (state: ConnectionCommonState) => state.connectionModalSection,
  );

  /**
   * getConnectionModalSection
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationTestConnectionResult>}
   * @memberof ConnectionCommonSelectors
   */
  public static getConnectionTestResult: MemoizedSelector<CoreAppState, AutomationTestConnectionResult> = createSelector(
    ConnectionCommonSelectors.selectConnectionCommonState,
    (state: ConnectionCommonState) => state.testConnectionResult,
  );
}

/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * SessionGenerationVersion
 * @enum {string}
 * @exporte
 */
export enum DeemVirtualMgtComponentType {
  CONNECTION_SERVER = 'CONNECTION_SERVER',
  UAG_APPLIANCE = 'UAG_APPLIANCE',
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { SolutionSettingMetricDetails } from '@ws1c/intelligence-models';
import { mergeSolutionSettingMetrics } from './deem-common-reducer-helper';
import { DeemCommonActions } from './deem-common.actions';
import { DeemCommonState, initialDeemCommonState } from './deem-common.store';

const _reducer: ActionReducer<DeemCommonState, Action> = createReducer(
  initialDeemCommonState,
  on(
    DeemCommonActions.navigateToSurveyCreate,
    (
      state: DeemCommonState,
      { detail, trend, returnPath }: ReturnType<typeof DeemCommonActions.navigateToSurveyCreate>,
    ): DeemCommonState => ({
      ...state,
      deemWidgetDetail: detail,
      deemWidgetTrend: trend,
      deemWidgetReturnPath: returnPath,
    }),
  ),
  on(
    DeemCommonActions.resetSurveyWidget,
    (state: DeemCommonState): DeemCommonState => ({
      ...state,
      deemWidgetDetail: undefined,
      deemWidgetTrend: undefined,
      deemWidgetReturnPath: undefined,
    }),
  ),
  on(
    DeemCommonActions.setActiveSettingMetrics,
    (state: DeemCommonState, { activeSettingMetrics }: { activeSettingMetrics: SolutionSettingMetricDetails[] }) => ({
      ...state,
      activeSettingMetrics: mergeSolutionSettingMetrics(state.activeSettingMetrics, activeSettingMetrics),
    }),
  ),
  on(
    DeemCommonActions.setUpdatingActiveSettingMetrics,
    (state: DeemCommonState, { updatingSettingMetrics }: ReturnType<typeof DeemCommonActions.setUpdatingActiveSettingMetrics>) => ({
      ...state,
      updatingActiveSettingMetrics: mergeSolutionSettingMetrics(state.updatingActiveSettingMetrics, updatingSettingMetrics),
    }),
  ),
  on(DeemCommonActions.showDeemThresholdEditModal, (state: DeemCommonState): DeemCommonState => {
    return {
      ...state,
      isDeemThresholdEditModalOpen: true,
    };
  }),

  on(DeemCommonActions.closeDeemThresholdEditModal, (state: DeemCommonState): DeemCommonState => {
    return {
      ...state,
      isDeemThresholdEditModalOpen: false,
      activeSettingMetrics: undefined,
      updatingActiveSettingMetrics: undefined,
    };
  }),
);

/**
 * Creates and returns DeemCommonState
 * @export
 * @param {DeemCommonState} state - Holds state object of type DeemCommonState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {DeemCommonState}
 */
export function deemCommonState(state: DeemCommonState, action: Action): DeemCommonState {
  return _reducer(state, action);
}

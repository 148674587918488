/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory, WebRequest } from '@ws1c/intelligence-models';

/**
 * ConnectionSetupRequest
 * @export
 * @class ConnectionSetupRequest
 */
@Serializable
export class ConnectionSetupRequest extends WebRequest {
  @JsonProperty('service_type')
  public key: string = undefined;

  @JsonProperty({ name: 'service_id', excludeToJson: true })
  public serviceId: string = undefined;

  @JsonProperty({ name: 'config_data', customConverter: defaultValueConverterFactory() })
  public settings: GenericObject = undefined;

  public isValid: boolean = undefined;

  /**
   * Creates an instance of ConnectionSetupRequest
   * @param {Array<Partial<ConnectionSetupRequest>>} args
   */
  constructor(...args: Array<Partial<ConnectionSetupRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}

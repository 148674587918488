/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { AppCrashThread } from '@dpa-shared-merlot/model';
import { AppLoadsSelectors } from '@dpa-shared-merlot/store/app-loads';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { getDefaultedPagedRequestMedium } from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import {
  AllHostTab,
  AppCrashGroupResponse,
  AppErrorCrashUploadsTableResponse,
  AppErrorType,
  AppHeGroupResponse,
  COLUMN_NAMES,
  CustomReportPreviewSearchResponse,
  getFQN,
  Integration,
  LocalDataGridSettings,
  NetworkInsightsErrorTab,
  RuleGroup,
  TrendDateRange,
} from '@ws1c/intelligence-models';
import { getAppErrorThreads } from './app-deployment-dashboard-selector-helpers';
import { AppsDashboardState } from './apps-dashboard.state';

/**
 * AppsDashboardSelectors
 * @export
 * @class AppsDashboardSelectors
 */
export class AppsDashboardSelectors {
  /**
   * selectAppsDashboardState
   * @param {MerlotState} state
   * @returns {AppsDashboardState}
   * @memberOf AppsDashboardSelectors
   */
  public static selectAppsDashboardState: Selector<MerlotState, AppsDashboardState> = (state: MerlotState) =>
    state.standardDashboardState.appsDashboardState;

  /**
   * getAppErrorBreadcrumbsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppErrorBreadcrumbsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appError.breadcrumbsLoading,
  );

  /**
   * getBreadcrumbTablePagedRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PagedRequest>}
   * @memberof AppsDashboardSelectors
   */
  public static getBreadcrumbTablePagedRequest: MemoizedSelector<MerlotState, PagedRequest> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => {
      return getDefaultedPagedRequestMedium(state.appError.breadcrumbsPagedRequest);
    },
  );

  /**
   * getAppErrorBreadcrumbResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppErrorBreadcrumbResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appError.breadcrumbsResponse,
  );

  /**
   * getBreadcrumbSortOn
   * @static
   * @type {MemoizedSelector<MerlotState, SortOn[]>}
   * @memberof AppsDashboardSelectors
   */
  public static getBreadcrumbSortOn: MemoizedSelector<MerlotState, SortOn[]> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    AppLoadsSelectors.getAppErrorDetailsGroupEntity,
    (state: AppsDashboardState, entity: string) =>
      state.appError.breadcrumbsSortOns || [
        new SortOn({
          by: getFQN(Integration.APTELIGENT, entity, COLUMN_NAMES.byName.event_timestamp),
          reverse: true,
        }),
      ],
  );

  /**
   * getAppErrorBreadcrumbsVisible
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppErrorBreadcrumbsVisible: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appError.breadcrumbsVisible,
  );

  /**
   * getAppCrashGroupWebError
   * @static
   * @type {MemoizedSelector<MerlotState, WebError>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppCrashGroupWebError: MemoizedSelector<MerlotState, WebError> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.groupError,
  );

  /**
   * getAppCrashGroupLocalDataGridSettings
   * @static
   * @type {MemoizedSelector<MerlotState, LocalDataGridSettings>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppCrashGroupLocalDataGridSettings: MemoizedSelector<MerlotState, LocalDataGridSettings> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.groupLocalDataGridSettings,
  );

  /**
   * getAppCrashGroupResponse
   * @static
   * @type {MemoizedSelector<MerlotState, AppCrashGroupResponse>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppCrashGroupResponse: MemoizedSelector<MerlotState, AppCrashGroupResponse> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.groupResponse,
  );

  /**
   * getAppErrorStackTraceSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppErrorStackTraceSearchResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appError.stackTraceSearchResponse,
  );

  /**
   * getAppDetailHealthStatus
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppDetailHealthStatus: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appHealth,
  );

  /**
   * isAppCrashGroupLoadingState
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppsDashboardSelectors
   */
  public static isAppCrashGroupLoadingState: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.isGroupLoading,
  );

  /**
   * getNetworkInsightsAllHostTab
   * @static
   * @type {MemoizedSelector<MerlotState, AllHostTab>}
   * @memberof AppsDashboardSelectors
   */
  public static getNetworkInsightsAllHostTab: MemoizedSelector<MerlotState, AllHostTab> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.allHostTab || AllHostTab.HTTP_ERRORS,
  );

  /**
   * getNetworkInsightsDetailsGroupBy
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppsDashboardSelectors
   */
  public static getNetworkInsightsDetailsGroupBy: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.detailsGroupBy,
  );

  /**
   * getNetworkInsightsErrorTab
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppsDashboardSelectors
   */
  public static getNetworkInsightsErrorTab: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.errorTab || NetworkInsightsErrorTab.BY_TOTAL,
  );

  /**
   * getNetworkInsightsHost
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppsDashboardSelectors
   */
  public static getNetworkInsightsHost: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.host,
  );

  /**
   * getNetworkInsightsGroupBy
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppsDashboardSelectors
   */
  public static getNetworkInsightsGroupBy: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.groupBy,
  );

  /**
   * getNetworkInsightsSubFilterRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof AppsDashboardSelectors
   */
  public static getNetworkInsightsSubFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.subFilterRuleGroup || new RuleGroup(),
  );

  /**
   * getNetworkInsightsUrlsModalHostUrl
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppsDashboardSelectors
   */
  public static getNetworkInsightsUrlsModalHostUrl: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.networkInsights.urlsModalHostUrl,
  );

  /**
   * getUserFlowsCardsSortOn
   * @static
   * @type {MemoizedSelector<MerlotState, SortOn>}
   * @memberof AppsDashboardSelectors
   */
  public static getUserFlowsCardsSortOn: MemoizedSelector<MerlotState, SortOn> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) =>
      state.userFlowsCardsSortOn ||
      new SortOn({
        by: COLUMN_NAMES.byName.name,
        reverse: false,
      }),
  );

  /**
   * getAppCrashThreads
   * @static
   * @type {MemoizedSelector<MerlotState, AppCrashThread[]>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppCrashThreads: MemoizedSelector<MerlotState, AppCrashThread[]> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    AppLoadsSelectors.getAppErrorDetailType,
    AppLoadsSelectors.getSelectedAppPlatform,
    (state: AppsDashboardState, appErrorType: AppErrorType, platform: string) =>
      getAppErrorThreads(state.appError.stackTraceSearchResponse, appErrorType, platform),
  );

  /**
   * getAppHeGroupLocalDataGridSettings
   * @static
   * @type {MemoizedSelector<MerlotState, LocalDataGridSettings>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppHeGroupLocalDataGridSettings: MemoizedSelector<MerlotState, LocalDataGridSettings> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appHeGroupLocalDataGridSettings,
  );

  /**
   * isAppHeGroupLoadingState
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppsDashboardSelectors
   */
  public static isAppHeGroupLoadingState: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.isHeGroupLoading,
  );

  /**
   * getAppHeGroupResponse
   * @static
   * @type {MemoizedSelector<MerlotState, AppHeGroupResponse>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppHeGroupResponse: MemoizedSelector<MerlotState, AppHeGroupResponse> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.heGroupResponse,
  );

  /**
   * getAppHeGroupWebError
   * @static
   * @type {MemoizedSelector<MerlotState, WebError>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppHeGroupWebError: MemoizedSelector<MerlotState, WebError> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.heGroupError,
  );

  /**
   * isAppCrashUploadsLoadingState
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppsDashboardSelectors
   */
  public static isAppCrashUploadsLoadingState: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.isUploadsLoading,
  );

  /**
   * getAppCrashUploadsResponse
   * @static
   * @type {MemoizedSelector<MerlotState, AppErrorCrashUploadsTableResponse>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppCrashUploadsResponse: MemoizedSelector<MerlotState, AppErrorCrashUploadsTableResponse> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.uploadsResponse,
  );

  /**
   * getAppCrashUploadsError
   * @static
   * @type {MemoizedSelector<MerlotState, WebError>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppCrashUploadsError: MemoizedSelector<MerlotState, WebError> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.uploadsError,
  );

  /**
   * getAppErrorCrashUploadsFilterString
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppErrorCrashUploadsFilterString: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.uploadsTableFilterString,
  );

  /**
   * getAppErrorCrashUploadsTablePagedRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PagedRequest>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppErrorCrashUploadsTablePagedRequest: MemoizedSelector<MerlotState, PagedRequest> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.uploadsTablePagedRequest,
  );

  /**
   * getAppErrorCrashUploadsTableSortOns
   * @static
   * @type {MemoizedSelector<MerlotState, SortOn[]>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppErrorCrashUploadsTableSortOns: MemoizedSelector<MerlotState, SortOn[]> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.uploadsTableSortOns,
  );

  /**
   * isAppCrashUploadingFileState
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppsDashboardSelectors
   */
  public static isAppCrashUploadingFileState: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.isUploadingFile,
  );

  /**
   * getAppCrashUploadFileError
   * @static
   * @type {MemoizedSelector<MerlotState, WebError>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppCrashUploadFileError: MemoizedSelector<MerlotState, WebError> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.uploadFileError,
  );

  /**
   * getMappingUploadAppVersion
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppsDashboardSelectors
   */
  public static getMappingUploadAppVersion: MemoizedSelector<MerlotState, string> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.appCrash.mappingUploadAppVersion,
  );

  /**
   * getAppDashboardFilterRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppDashboardFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => state.filterRuleGroup || new RuleGroup(),
  );

  /**
   * getAppDashboardModifiedFilterRuleGroup
   * Replace all integration/entity with generic name to fix join issue on v2 dashboard (INTEL-44708)
   *
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppDashboardModifiedFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => RuleGroup.modifyRuleForStandardDashboardV2(state.filterRuleGroup ?? new RuleGroup()),
  );

  /**
   * getAppDashboardTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof AppsDashboardSelectors
   */
  public static getAppDashboardTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    AppsDashboardSelectors.selectAppsDashboardState,
    (state: AppsDashboardState) => getDefaultedTrendDate(state.trendDateRange),
  );
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { TrialServicesActions } from './trial-services.actions';
import { initialTrialServicesState, TrialServicesState } from './trial-services.store';

/**
 * _reducer
 * @type {ActionReducer<TrialServicesState, Action>}
 */
const _reducer: ActionReducer<TrialServicesState, Action> = createReducer(
  initialTrialServicesState,

  on(
    TrialServicesActions.getTrialDetails,
    TrialServicesActions.refreshTrialStatus,
    (state: TrialServicesState, _action: ReturnType<typeof TrialServicesActions.getTrialDetails>): TrialServicesState => ({
      ...state,
      isLoadingTrialDetails: true,
    }),
  ),

  on(
    TrialServicesActions.getTrialDetailsSuccess,
    (state: TrialServicesState, { trialDetails }: ReturnType<typeof TrialServicesActions.getTrialDetailsSuccess>): TrialServicesState => ({
      ...state,
      trialDetails,
      isLoadingTrialDetails: false,
    }),
  ),

  on(
    TrialServicesActions.getTrialDetailsFailure,
    (state: TrialServicesState, _action: ReturnType<typeof TrialServicesActions.getTrialDetailsFailure>): TrialServicesState => ({
      ...state,
      isLoadingTrialDetails: false,
    }),
  ),

  on(
    TrialServicesActions.getTrialDetailsForServiceType,
    (state: TrialServicesState, _action: ReturnType<typeof TrialServicesActions.getTrialDetailsForServiceType>): TrialServicesState => ({
      ...state,
      isLoadingTrialDetails: true,
    }),
  ),

  on(
    TrialServicesActions.getTrialDetailsForServiceTypeSuccess,
    (
      state: TrialServicesState,
      { trialDetails, serviceType }: ReturnType<typeof TrialServicesActions.getTrialDetailsForServiceTypeSuccess>,
    ): TrialServicesState => ({
      ...state,
      trialDetails,
      isLoadingTrialDetails: false,
      startTrialTriggerStatusByServiceType: {
        ...state.startTrialTriggerStatusByServiceType,
        [serviceType]: false,
      },
    }),
  ),

  on(
    TrialServicesActions.getTrialDetailsForServiceTypeFailure,
    (
      state: TrialServicesState,
      { serviceType }: ReturnType<typeof TrialServicesActions.getTrialDetailsForServiceTypeFailure>,
    ): TrialServicesState => ({
      ...state,
      isLoadingTrialDetails: false,
      startTrialTriggerStatusByServiceType: {
        ...state.startTrialTriggerStatusByServiceType,
        [serviceType]: false,
      },
    }),
  ),

  on(
    TrialServicesActions.startTrialV2,
    (state: TrialServicesState, { serviceType }: ReturnType<typeof TrialServicesActions.startTrialV2>): TrialServicesState => ({
      ...state,
      startTrialTriggerStatusByServiceType: {
        ...state.startTrialTriggerStatusByServiceType,
        [serviceType]: true,
      },
    }),
  ),

  on(
    TrialServicesActions.startTrialV2Success,
    (
      state: TrialServicesState,
      { trialActivationDetail }: ReturnType<typeof TrialServicesActions.startTrialV2Success>,
    ): TrialServicesState => ({
      ...state,
      trialActivationDetail,
    }),
  ),

  on(
    TrialServicesActions.startTrialV2Failure,
    (state: TrialServicesState, { serviceType }: ReturnType<typeof TrialServicesActions.startTrialV2Failure>): TrialServicesState => ({
      ...state,
      startTrialTriggerStatusByServiceType: {
        ...state.startTrialTriggerStatusByServiceType,
        [serviceType]: false,
      },
    }),
  ),

  on(
    TrialServicesActions.getTrialUserContactDetailsV2,
    (state: TrialServicesState, _action: ReturnType<typeof TrialServicesActions.getTrialUserContactDetailsV2>): TrialServicesState => ({
      ...state,
      isFetchingTrialUserContactDetails: true,
    }),
  ),
  on(
    TrialServicesActions.getTrialUserContactDetailsV2Success,
    (
      state: TrialServicesState,
      { trialUserContactDetails }: ReturnType<typeof TrialServicesActions.getTrialUserContactDetailsV2Success>,
    ): TrialServicesState => ({
      ...state,
      trialUserContactDetails,
      isFetchingTrialUserContactDetails: false,
    }),
  ),
  on(
    TrialServicesActions.getTrialUserContactDetailsV2Failure,
    (state: TrialServicesState, _action: ReturnType<typeof TrialServicesActions.getTrialDetailsFailure>): TrialServicesState => ({
      ...state,
      isFetchingTrialUserContactDetails: false,
    }),
  ),
  on(
    TrialServicesActions.setTrialServicesModalOpenState,
    (
      state: TrialServicesState,
      { isOpen }: ReturnType<typeof TrialServicesActions.setTrialServicesModalOpenState>,
    ): TrialServicesState => ({
      ...state,
      isTrialServicesModalOpen: isOpen,
    }),
  ),
  on(
    TrialServicesActions.showTrialUserContactDetailsForm,
    (
      state: TrialServicesState,
      { showTrialUserContactDetailsForm }: ReturnType<typeof TrialServicesActions.showTrialUserContactDetailsForm>,
    ): TrialServicesState => ({
      ...state,
      showTrialUserContactDetailsForm,
    }),
  ),
  on(
    TrialServicesActions.acceptTrialUserContactDetails,
    (
      state: TrialServicesState,
      { isAccepted }: ReturnType<typeof TrialServicesActions.acceptTrialUserContactDetails>,
    ): TrialServicesState => ({
      ...state,
      isTrialUserContactDetailsAccepted: isAccepted,
    }),
  ),
);

/**
 * Creates and returns TrialServicesState
 * @export
 * @param {TrialServicesState} state - Holds state object of type TrialServicesState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {TrialServicesState}
 */
export function trialServicesState(state: TrialServicesState, action: Action): TrialServicesState {
  return _reducer(state, action);
}

<div
  *ngIf="ngxChart"
  class="legend-body p-x1"
>
  <div
    *ngFor="let colorizedAttributeValue of ngxChart.colorizedAttributeValues"
    [attr.aria-checked]="isFocused(colorizedAttributeValue)"
    [class.active]="activeSeries.has(colorizedAttributeValue)"
    (click)="selectSeries.emit(colorizedAttributeValue)"
    (keydown)="onKeyDown($event, colorizedAttributeValue)"
    (mouseenter)="activateSeries.emit(colorizedAttributeValue)"
    (focus)="activateSeries.emit(colorizedAttributeValue)"
    (mouseleave)="deactivateSeries.emit(colorizedAttributeValue)"
    (blur)="deactivateSeries.emit(colorizedAttributeValue)"
    class="legend-pair mv-x0 mh-x1"
    role="checkbox"
    tabindex="0"
  >
    <div
      [class.selected]="isFocused(colorizedAttributeValue)"
      [style.background-color]="getBackgroundColor(colorizedAttributeValue)"
      class="legend-item-color mr-x1"
    ></div>
    <dpa-ellipsis-with-tooltip class="legend-item-label">
      {{ colorizedAttributeValue }}
    </dpa-ellipsis-with-tooltip>
  </div>
</div>

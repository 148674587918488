/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { deserialize, GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { mapValues } from 'lodash-es';

import { AppDeploymentDashboardSelectors } from '@dpa-shared-merlot/store/dashboard/apps/app-deployment-dashboard.selectors';
import { NetworkInsightsSelectors } from '@dpa-shared-merlot/store/dashboard/apps/network-insights.selectors';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import { AggregationWidget, TrendDefinition } from '@ws1c/intelligence-models';
import * as dashboardSelectorHelpers from './dashboard-selector-helpers';
import { DashboardState } from './dashboard.state';

export const trendDefinitionOverrideHelpers = {
  ...dashboardSelectorHelpers,
};

/**
 * DashboardTrendDefinitionOverridesSelectors
 *
 * @export
 * @class DashboardTrendDefinitionOverridesSelectors
 */
export class DashboardTrendDefinitionOverridesSelectors {
  /**
   *
   * getTrendDefinitionsByStandardWidgetSubtype
   * dashboardState.trendDefinitionsByStandardWidgetSubtype is in raw JSON format
   * no time, but should deserialize before entering the store instead of as UI pulls it out
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof DashboardTrendDefinitionOverridesSelectors
   */
  public static getTrendDefinitionsByStandardWidgetSubtype: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => {
      const trendDefinitionsIndexData = state.trendDefinitionsByStandardWidgetSubtype;
      return mapValues(trendDefinitionsIndexData, (trendDefinitionsData: any) => {
        return deserialize(TrendDefinition, trendDefinitionsData);
      });
    },
  );

  /**
   * getTrendDefinitionsByStandardWidgetSubtypeWithOverrides
   * DAU and MAU widgetSubTypes needs its trendDateRange overwritten
   * The one provided by backend doesn't account for rollingWindow
   * @export
   * @param {Observable<MerlotState>} $state
   * @returns {Observable<Record<string, TrendDefinition>>}
   */
  public static getTrendDefinitionsByStandardWidgetSubtypeWithOverrides: MemoizedSelector<MerlotState, Record<string, TrendDefinition>> =
    createSelector(
      AppDeploymentDashboardSelectors.getAppDashboardTrendDefinitionOverridesByStandardWidgetSubtype,
      NetworkInsightsSelectors.getNetworkInsightsTrendDefinitionOverridesByStandardWidgetSubtype,
      DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsByStandardWidgetSubtype,
      (appDashboardOverrides: GenericObject, networkInsightsOverrides: GenericObject, trendDefinitionsBySubtypes: GenericObject) => {
        return trendDefinitionOverrideHelpers.getTrendDefinitionsByStandardWidgetSubtypeWithOverrides(
          appDashboardOverrides,
          networkInsightsOverrides,
          trendDefinitionsBySubtypes,
        );
      },
    );

  /**
   * getTrendDefinitionsById
   * @param {Observable<MerlotState>} $state
   * @returns {Observable<Record<string, TrendDefinition>>}
   */
  public static getTrendDefinitionsById: MemoizedSelector<MerlotState, Record<string, TrendDefinition>> = createSelector(
    DashboardSelectors.getWidgetsById,
    DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsByStandardWidgetSubtypeWithOverrides,
    (widgetsById: Record<string, AggregationWidget>, standardTdsByType: Record<string, TrendDefinition>) => {
      const widgetTdsById = mapValues(widgetsById, (widget: AggregationWidget) => widget.trend.trendDefinition);
      return {
        ...widgetTdsById,
        ...standardTdsByType,
      };
    },
  );
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { Tag } from '@ws1c/intelligence-models';
import { QuickFilterState } from './quick-filter.store';

/**
 * QuickFilterSelectors
 * @export
 * @class QuickFilterSelectors
 */
export class QuickFilterSelectors {
  public static selectQuickFilterState = (state: CoreAppState) => state.quickFilterState;

  /**
   * getTags
   * @static
   * @type {MemoizedSelector<CoreAppState, Tag[]>}
   * @memberof QuickFilterSelectors
   */
  public static getTags: MemoizedSelector<CoreAppState, Tag[]> = createSelector(
    QuickFilterSelectors.selectQuickFilterState,
    (state: QuickFilterState) => state.tags,
  );
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

import { MetricTab } from '@ws1c/intelligence-models';

/**
 * WidgetSubheaderComponent
 * @export
 * @class WidgetSubheaderComponent
 */
@Component({
  selector: 'dpa-widget-subheader',
  templateUrl: 'widget-subheader.component.html',
  styleUrls: ['widget-subheader.component.scss'],
})
export class WidgetSubheaderComponent {
  @Input() public metricTabs?: MetricTab[] = [];
  @Input() public showTooltip?: boolean = false;
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { TrialActivationDetail, TrialDetail, TrialUserContactDetails } from '@ws1c/intelligence-models';

/**
 * State for Trial Services
 *
 * @export
 * @interface
 */
export interface TrialServicesState {
  isFetchingTrialUserContactDetails: boolean;
  isLoadingTrialDetails: boolean;
  startTrialTriggerStatusByServiceType: Record<string, boolean>;
  trialActivationDetail: TrialActivationDetail;
  trialDetails: TrialDetail[];
  trialUserContactDetails: TrialUserContactDetails;
  showTrialUserContactDetailsForm: boolean;
  isTrialServicesModalOpen: boolean;
  isTrialUserContactDetailsAccepted: boolean;
}

/**
 * Defines initial state for Trial Services
 *
 * @export
 * @type {TrialServicesState}
 */
export const initialTrialServicesState: TrialServicesState = {
  isFetchingTrialUserContactDetails: false,
  isLoadingTrialDetails: false,
  startTrialTriggerStatusByServiceType: {},
  trialActivationDetail: undefined,
  trialDetails: [],
  trialUserContactDetails: undefined,
  showTrialUserContactDetailsForm: false,
  isTrialServicesModalOpen: false,
  isTrialUserContactDetailsAccepted: false,
};

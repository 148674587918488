/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

/**
 * SimpleDrilldownNavigatorComponent
 * @export
 * @class SimpleDrilldownNavigatorComponent
 */
@Component({
  selector: 'dpa-simple-drilldown-navigator',
  templateUrl: 'simple-drilldown-navigator.component.html',
  styleUrls: ['simple-drilldown-navigator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleDrilldownNavigatorComponent {
  @Output() public clearDrilldown = new EventEmitter();
  @Output() public popDrilldown = new EventEmitter();

  /**
   * onClearDrilldown
   * @memberof SimpleDrilldownNavigatorComponent
   */
  public onClearDrilldown() {
    this.clearDrilldown.emit();
  }

  /**
   * onPopDrilldown
   * @memberof SimpleDrilldownNavigatorComponent
   */
  public onPopDrilldown() {
    this.popDrilldown.emit();
  }
}

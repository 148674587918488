/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { IntegratedServicesResponse, IntegratedServiceType, Integration, LOAD_STATE, ServiceMode } from '@ws1c/intelligence-models';

/**
 * State for Integrated Service
 *
 * @export
 * @interface
 */
export interface IntegratedServicesState {
  activeServiceMode: ServiceMode;
  integratedServicesData: IntegratedServicesResponse;
  integratedServicesLoadState: LOAD_STATE;
  integratedServiceTypeDetails: Record<IntegratedServiceType, Integration>;
  isLoadingSKUServices: boolean;
}

/**
 * Defines initial state for Integrated Services
 *
 * @export
 * @type {IntegratedServicesState}
 */
export const initialIntegratedServicesState: IntegratedServicesState = {
  activeServiceMode: null,
  integratedServicesData: {
    availableSKUServices: [],
    basicFeatures: [],
    services: [],
  },
  integratedServicesLoadState: LOAD_STATE.NONE,
  integratedServiceTypeDetails: {
    [IntegratedServiceType.INTELLIGENCE]: null,
    [IntegratedServiceType.WS1_ACCESS]: Integration.IDM,
    [IntegratedServiceType.WS1_UEM]: Integration.AIRWATCH,
    [IntegratedServiceType.HUB_SERVICES]: Integration.HUB,
    [IntegratedServiceType.HORIZON]: Integration.HORIZON,
  },
  isLoadingSKUServices: false,
};

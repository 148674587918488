/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { AvailableService, Connection, ConnectionModalSection } from '@ws1c/intelligence-models';
import { ConnectionCommonActions } from './connection-common.actions';
import { ConnectionCommonState, initialConnectionCommonState } from './connection-common.store';

/**
 * updateServiceConnection
 *
 * Updates service with config connection status
 * @param {Connection} config
 * @returns {(service: AvailableService) => AvailableService}
 */
function updateServiceConnection(config: Connection): (service: AvailableService) => AvailableService {
  return (service: AvailableService): AvailableService => {
    if (service.id === config.serviceId) {
      return new AvailableService({
        ...service,
        serviceConfigId: config.id,
        statusCheckSuccess: config.statusCheckSuccess,
      });
    }
    return service;
  };
}

const _reducer: ActionReducer<ConnectionCommonState, Action> = createReducer(
  initialConnectionCommonState,
  on(
    ConnectionCommonActions.updateServiceWithConfig,
    (
      state: ConnectionCommonState,
      { connection }: ReturnType<typeof ConnectionCommonActions.updateServiceWithConfig>,
    ): ConnectionCommonState => ({
      ...state,
      automationServicesPageResults: state.automationServicesPageResults.map(updateServiceConnection(connection)),
    }),
  ),
  on(
    ConnectionCommonActions.setTestConnectionModalSection,
    (
      state: ConnectionCommonState,
      { connectionModalSection }: ReturnType<typeof ConnectionCommonActions.setTestConnectionModalSection>,
    ): ConnectionCommonState => ({
      ...state,
      connectionModalSection,
    }),
  ),
  on(
    ConnectionCommonActions.toggleModalSection,
    (
      state: ConnectionCommonState,
      { connectionModalSection }: ReturnType<typeof ConnectionCommonActions.toggleModalSection>,
    ): ConnectionCommonState => ({
      ...state,
      connectionModalSection:
        state.connectionModalSection === connectionModalSection ? ConnectionModalSection.NONE : connectionModalSection,
    }),
  ),
);

/**
 * Creates and returns ConnectionCommonState
 * @export
 * @param {ConnectionCommonState} state - Holds state object of type ConnectionCommon
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {ConnectionCommonState}
 */
export function connectionCommonState(state: ConnectionCommonState, action: Action): ConnectionCommonState {
  return _reducer(state, action);
}

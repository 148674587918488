/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { ScheduledReport, ScheduledReportSearchRequest, ScheduledReportSearchResponse } from '@ws1c/intelligence-models';

/**
 * Support basic CRUD actions to schedule reports and track status of scheduled reports
 * @link: ReportScheduleController.java
 * @link: ReportDownloadController.java
 * @export
 * @class ScheduledReportService
 */
@Injectable({
  providedIn: 'root',
})
export class ScheduledReportService {
  /**
   * Creates an instance of ScheduledReportService.
   * @param {HttpService} http
   * @memberof ScheduledReportService
   */
  constructor(private http: HttpService) {}

  /**
   * Get Schedule Report Details
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof ScheduledReportService
   */
  public getScheduledReportDetails(id: string): Observable<ScheduledReport | any> {
    return this.http.get(Endpoint.SCHEDULE_DETAILS(id)).pipe(
      map((response: any) => deserialize(ScheduledReport, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Delete Schedules
   * @param {string[]} ids
   * @returns {Observable<any>}
   * @memberof ScheduledReportService
   */
  public deleteScheduledReports(ids: string[]): Observable<any> {
    const identifiers = { identifiers: ids };
    return this.http.delete(Endpoint.REPORTS_SCHEDULES, { body: identifiers }).pipe(
      map((response: any) => response),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Update Schedule Report
   * @param {any} id
   * @param {*} reportSchedule
   * @returns {Observable<any>}
   * @memberof ScheduledReportService
   */
  public updateScheduledReport(id, reportSchedule: any): Observable<any> {
    return this.http.put(Endpoint.SCHEDULE_DETAILS(id), reportSchedule).pipe(
      map((response: any) => response),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get Schedules For Report
   * @param {ScheduledReportSearchRequest} searchRequest
   * @returns {Observable<ScheduledReportSearchResponse>}
   * @memberof ScheduledReportService
   */
  public getSchedulesForReport(searchRequest: ScheduledReportSearchRequest): Observable<ScheduledReportSearchResponse | any> {
    return this.http.post(Endpoint.SCHEDULES_FOR_REPORT(searchRequest.reportId), searchRequest).pipe(
      map((response: any) => deserialize(ScheduledReportSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, SortOn } from '@dpa/ui-common';

import {
  COLUMN_NAMES,
  DevicesDashboardConfig,
  Entity,
  FilterRule,
  Integration,
  PreviewReportContentRequest,
  QueryBuilder,
  TrendMode,
} from '@ws1c/intelligence-models';

/**
 * getDeviceEventsRequest
 * @param {GenericObject} params
 * @returns {PreviewReportContentRequest}
 */
export function getDeviceEventsRequest(params: GenericObject): PreviewReportContentRequest {
  const { id, entity, event, startDateMillis, endDateMillis, request } = params;
  let integration = Integration.EMPLOYEE_EXPERIENCE;
  let sortColumn: string = COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp;
  let eventNameMap: GenericObject = {
    [event]: [event],
  };
  const filterRuleConfig: FilterRule[] = [];
  switch (entity) {
    case Entity.APPS:
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_name,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: eventNameMap[event],
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_status,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: DevicesDashboardConfig.completeStatus,
        }),
      );
      break;
    case Entity.DEVICES:
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_name,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: eventNameMap[event],
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_status,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: DevicesDashboardConfig.completeStatus,
        }),
      );
      break;
    case Entity.DEVICE_NET_EVENT:
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_friendly_name,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: eventNameMap[event],
        }),
      );
      break;
    case Entity.OS_UPDATES:
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_name,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: eventNameMap[event],
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_status,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: DevicesDashboardConfig.completeStatus,
        }),
      );
      break;
    case Entity.USER_ACTIONS:
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_name,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: eventNameMap[event],
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_status,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: DevicesDashboardConfig.completeStatus,
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_logon_type,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: DevicesDashboardConfig.logonTypes,
        }),
      );
      break;
    case Entity.USER_FLOW:
      eventNameMap = {
        [DevicesDashboardConfig.deviceEvents.USER_FLOW_FAILURE]: [
          DevicesDashboardConfig.eventStatusNames.CRASH,
          DevicesDashboardConfig.eventStatusNames.TIMEOUT,
          DevicesDashboardConfig.eventStatusNames.USER_DEFINED_FAILURE,
        ],
        [DevicesDashboardConfig.deviceEvents.USER_FLOW_SUCCESS]: [DevicesDashboardConfig.eventStatusNames.SUCCESS],
      };
      integration = Integration.APTELIGENT;
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_state_name,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: eventNameMap[event],
        }),
      );
      break;
    case Entity.CRASH_ANDROID:
      integration = Integration.APTELIGENT;
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_android_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
      );
      break;
    case Entity.CRASH_IOS:
      integration = Integration.APTELIGENT;
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_crash_ios_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
      );
      break;
    case Entity.APPLOAD:
      integration = Integration.APTELIGENT;
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
      );
      break;
    case Entity.APPLICATION_CHANGE_EVENT:
      integration = Integration.AIRWATCH;
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id.toUpperCase(),
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_change_event_type_list,
          condition: FilterRule.FILTER_CONDITION.constainsAnyOf,
          data: DevicesDashboardConfig.appInstallsEventNames,
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.airwatch_applicationchangeevent_app_is_installed,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: true,
        }),
      );
      break;
    case Entity.HANDLED_EXCEPTION_ANDROID:
      integration = Integration.APTELIGENT;
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_android_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
      );
      break;
    case Entity.HANDLED_EXCEPTION_IOS:
      integration = Integration.APTELIGENT;
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_handled_exception_ios_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
      );
      break;
    case Entity.NET_ERROR:
      integration = Integration.APTELIGENT;
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_event_timestamp;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.apteligent_net_error_airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
      );
      break;
    case Entity.QUALITY_OF_SERVICE:
      integration = Integration.ZOOM;
      sortColumn = COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_start_time;
      filterRuleConfig.push(
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.zoom_qos__airwatch_device_guid,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: id,
        }),
      );
      break;
  }
  return new PreviewReportContentRequest({
    trendMode: TrendMode.HISTORICAL,
    entity,
    integration,
    entitiesByIntegration: {
      [integration]: [entity],
    },
    fields: DevicesDashboardConfig.deviceProfileSearchFieldsByEntityAndEvent[entity][event],
    filter: QueryBuilder.queryStringFromFilterRules(filterRuleConfig),
    endDateMillis,
    startDateMillis,
    offset: request?.offset || 0,
    pageSize: request?.pageSize || 10,
    sortOns: [
      new SortOn({
        by: sortColumn,
        reverse: true,
      }),
    ],
  });
}

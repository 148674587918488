/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { PagedRequest, SortOn } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  AllManagedConnectorsResponse,
  AutomationRunHistoryResponse,
  Connector,
  ConnectorAction,
  ConnectorConfig,
  ConnectorConfigData,
  ConnectorConfigStatus,
  ConnectorConfigUpdateParams,
  ConnectorCreateParams,
  ConnectorModalType,
  ConnectorsAllActionsRequest,
  ConnectorSchema,
  ConnectorsSearchResponse,
  ConnectorsWithActionsResponse,
  ConnectorUpdateParams,
  GenericSearchRequest,
  ManagedConnector,
  ManagedConnectorsSearchResponse,
  SourceObjectType,
  WorkflowConnectorsTab,
} from '@ws1c/intelligence-models';

/**
 * ConnectorCommonActions
 *
 * @export
 * @class ConnectorCommonActions
 */
@Injectable()
export class ConnectorCommonActions {
  public static readonly CATEGORY = '[CONNECTOR_COMMON]';

  private static type = (actionName: string): string => `${ConnectorCommonActions.CATEGORY} ${actionName}`;

  /**
   * loadConnectors
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectors = createAction(ConnectorCommonActions.type('LOAD_CONNECTORS'), props<{ request?: GenericSearchRequest }>());

  /**
   * changePagination
   * @static
   * @memberof ConnectorCommonActions
   */
  public static changePagination = createAction(ConnectorCommonActions.type('CHANGE_PAGINATION'), props<{ pagedRequest: PagedRequest }>());

  /**
   * loadConnectorsWithSort
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorsWithSort = createAction(
    ConnectorCommonActions.type('LOAD_CONNECTORS_WITH_SORT'),
    props<{ sortOns: SortOn[] }>(),
  );

  /**
   * loadConnectorsWithSearch
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorsWithSearch = createAction(
    ConnectorCommonActions.type('LOAD_CONNECTORS_WITH_SEARCH'),
    props<{ searchQuery: string }>(),
  );

  /**
   * loadConnectorsWithQuickFilters
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorsWithQuickFilters = createAction(
    ConnectorCommonActions.type('LOAD_CONNECTORS_WITH_QUICK_FILTERS'),
    props<{ status: ConnectorConfigStatus }>(),
  );

  /**
   * loadConnectorsSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorsSuccess = createAction(
    ConnectorCommonActions.type('LOAD_CONNECTORS_SUCCESS'),
    props<{ response: ConnectorsSearchResponse }>(),
  );

  /**
   * loadConnectorsFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorsFailure = createAction(ConnectorCommonActions.type('LOAD_CONNECTORS_FAILURE'));
  /**
   * showModal
   * @static
   * @memberof ConnectorCommonActions
   */
  public static showModal = createAction(
    ConnectorCommonActions.type('SHOW_MODAL'),
    props<{
      modalType: ConnectorModalType;
      connector?: Connector;
      actions?: ConnectorAction[];
    }>(),
  );

  /**
   * closeModal
   * @static
   * @memberof ConnectorCommonActions
   */
  public static closeModal = createAction(ConnectorCommonActions.type('CLOSE_MODAL'));

  /**
   * loadConnectorConfig
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorConfig = createAction(ConnectorCommonActions.type('LOAD_CONNECTOR_CONFIG'), props<{ id: string }>());

  /**
   * loadConnectorConfigSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorConfigSuccess = createAction(
    ConnectorCommonActions.type('LOAD_CONNECTOR_CONFIG_SUCCESS'),
    props<{ config: ConnectorConfig }>(),
  );

  /**
   * getConnectorConfigSchema
   * @static
   * @memberof ConnectorCommonActions
   */
  public static getConnectorConfigSchema = createAction(
    ConnectorCommonActions.type('GET_CONNECTOR_CONFIG_SCHEMA'),
    props<{ configSchemaId: string }>(),
  );

  /**
   * getConnectorConfigSchemaSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static getConnectorConfigSchemaSuccess = createAction(
    ConnectorCommonActions.type('GET_CONNECTOR_CONFIG_SCHEMA_SUCCESS'),
    props<{ connectorConfigSchema: ConnectorSchema }>(),
  );

  /**
   * createCustomConnector
   * @static
   * @memberof ConnectorCommonActions
   */
  public static createCustomConnector = createAction(
    ConnectorCommonActions.type('CREATE_CUSTOM_CONNECTOR'),
    props<{ params: ConnectorCreateParams }>(),
  );

  /**
   * createCustomConnectorSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static createCustomConnectorSuccess = createAction(ConnectorCommonActions.type('CREATE_CUSTOM_CONNECTOR_SUCCESS'));

  /**
   * createCustomConnectorFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static createCustomConnectorFailure = createAction(ConnectorCommonActions.type('CREATE_CUSTOM_CONNECTOR_FAILURE'));

  /**
   * updateConnector
   * @static
   * @memberof ConnectorCommonActions
   */
  public static updateConnector = createAction(
    ConnectorCommonActions.type('UPDATE_CONNECTOR'),
    props<{
      id: string;
      params: ConnectorUpdateParams;
    }>(),
  );

  /**
   * updateConnectorSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static updateConnectorSuccess = createAction(ConnectorCommonActions.type('UPDATE_CONNECTOR_SUCCESS'));

  /**
   * updateConnectorFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static updateConnectorFailure = createAction(ConnectorCommonActions.type('UPDATE_CONNECTOR_FAILURE'));

  /**
   * deleteConnectorConfig
   * @static
   * @memberof ConnectorCommonActions
   */
  public static deleteConnectorConfig = createAction(
    ConnectorCommonActions.type('DELETE_CONNECTOR_CONFIG'),
    props<{ connector: Connector }>(),
  );

  /**
   * deleteConnectorConfigSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static deleteConnectorConfigSuccess = createAction(ConnectorCommonActions.type('DELETE_CONNECTOR_CONFIG_SUCCESS'));

  /**
   * deleteConnectorConfigFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static deleteConnectorConfigFailure = createAction(ConnectorCommonActions.type('DELETE_CONNECTOR_CONFIG_FAILURE'));

  /**
   * deleteConnector
   * @static
   * @memberof ConnectorCommonActions
   */
  public static deleteConnector = createAction(ConnectorCommonActions.type('DELETE_CONNECTOR'), props<{ id: string }>());

  /**
   * deleteConnectorSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static deleteConnectorSuccess = createAction(ConnectorCommonActions.type('DELETE_CONNECTOR_SUCCESS'));

  /**
   * deleteConnectorFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static deleteConnectorFailure = createAction(ConnectorCommonActions.type('DELETE_CONNECTOR_FAILURE'));

  /**
   * loadConnectorConfigFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorConfigFailure = createAction(ConnectorCommonActions.type('LOAD_CONNECTOR_CONFIG_FAILURE'));

  /**
   * createConnectorConfig
   * @static
   * @memberof ConnectorCommonActions
   */
  public static createConnectorConfig = createAction(
    ConnectorCommonActions.type('CREATE_CONNECTOR_CONFIG'),
    props<{
      id: string;
      configData: ConnectorConfigData;
    }>(),
  );

  /**
   * createConnectorConfigSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static createConnectorConfigSuccess = createAction(
    ConnectorCommonActions.type('CREATE_CONNECTOR_CONFIG_SUCCESS'),
    props<{ config: ConnectorConfig }>(),
  );

  /**
   * createConnectorConfigFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static createConnectorConfigFailure = createAction(ConnectorCommonActions.type('CREATE_CONNECTOR_CONFIG_FAILURE'));

  /**
   * updateConnectorConfig
   * @static
   * @memberof ConnectorCommonActions
   */
  public static updateConnectorConfig = createAction(
    ConnectorCommonActions.type('UPDATE_CONNECTOR_CONFIG'),
    props<{
      id: string;
      params: ConnectorConfigUpdateParams;
    }>(),
  );

  /**
   * updateConnectorConfigSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static updateConnectorConfigSuccess = createAction(ConnectorCommonActions.type('UPDATE_CONNECTOR_CONFIG_SUCCESS'));

  /**
   * updateConnectorConfigFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static updateConnectorConfigFailure = createAction(ConnectorCommonActions.type('UPDATE_CONNECTOR_CONFIG_FAILURE'));

  /**
   * createManagedConnector
   * @static
   * @memberof ConnectorCommonActions
   */
  public static createManagedConnector = createAction(
    ConnectorCommonActions.type('CREATE_MANAGED_CONNECTOR'),
    props<{ managedConnector: ManagedConnector }>(),
  );

  /**
   * createManagedConnectorSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static createManagedConnectorSuccess = createAction(ConnectorCommonActions.type('CREATE_MANAGED_CONNECTOR_SUCCESS'));

  /**
   * loadManagedConnectors
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadManagedConnectors = createAction(
    ConnectorCommonActions.type('LOAD_MANAGED_CONNECTORS'),
    props<{ request?: GenericSearchRequest }>(),
  );

  /**
   * loadManagedConnectorsSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadManagedConnectorsSuccess = createAction(
    ConnectorCommonActions.type('LOAD_MANAGED_CONNECTORS_SUCCESS'),
    props<{ response: ManagedConnectorsSearchResponse }>(),
  );

  /**
   * loadManagedConnectorsFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadManagedConnectorsFailure = createAction(ConnectorCommonActions.type('LOAD_MANAGED_CONNECTORS_FAILURE'));

  /**
   * loadAllManagedConnectors
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadAllManagedConnectors = createAction(ConnectorCommonActions.type('LOAD_ALL_MANAGED_CONNECTORS'));

  /**
   * loadAllManagedConnectorsSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadAllManagedConnectorsSuccess = createAction(
    ConnectorCommonActions.type('LOAD_ALL_MANAGED_CONNECTORS_SUCCESS'),
    props<{ response: AllManagedConnectorsResponse }>(),
  );

  /**
   * loadAllManagedConnectorsFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadAllManagedConnectorsFailure = createAction(ConnectorCommonActions.type('LOAD_ALL_MANAGED_CONNECTORS_FAILURE'));

  /**
   * navigateToConnectorDetails
   * @static
   * @memberof ConnectorCommonActions
   */
  public static navigateToConnectorDetails = createAction(
    ConnectorCommonActions.type('NAVIGATE_TO_CONNECTOR_DETAILS'),
    props<{
      id: string;
      tab?: WorkflowConnectorsTab;
    }>(),
  );

  /**
   * listConnectorsWithActions
   * @static
   * @memberof ConnectorCommonActions
   */
  public static listConnectorsWithActions = createAction(
    ConnectorCommonActions.type('LOAD_CONNECTORS_WITH_ACTIONS'),
    props<{ request?: ConnectorsAllActionsRequest; sourceObjectType?: SourceObjectType }>(),
  );

  /**
   * listConnectorsWithActionsSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static listConnectorsWithActionsSuccess = createAction(
    ConnectorCommonActions.type('LOAD_CONNECTORS_WITH_ACTIONS_SUCCESS'),
    props<{ connectorsWithActionsResponse?: ConnectorsWithActionsResponse }>(),
  );

  /**
   * listConnectorsWithActionsFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static listConnectorsWithActionsFailure = createAction(ConnectorCommonActions.type('LOAD_CONNECTORS_WITH_ACTIONS_FAILURE'));

  /**
   * loadConnectorsUsage
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorsUsage = createAction(
    `${ConnectorCommonActions.CATEGORY} LOAD_CONNECTORS_USAGE`,
    props<{
      connectorId: string;
      request: GenericSearchRequest;
    }>(),
  );

  /**
   * loadConnectorsUsageWithSort
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorsUsageWithSort = createAction(
    `${ConnectorCommonActions.CATEGORY} LOAD_CONNECTORS_USAGE_WITH_SORT`,
    props<{ sortOns: SortOn[] }>(),
  );

  /**
   * loadConnectorsUsageSuccess
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorsUsageSuccess = createAction(
    `${ConnectorCommonActions.CATEGORY} LOAD_CONNECTORS_USAGE_SUCCESS`,
    props<{ response: AutomationRunHistoryResponse }>(),
  );

  /**
   * loadConnectorsUsageFailure
   * @static
   * @memberof ConnectorCommonActions
   */
  public static loadConnectorsUsageFailure = createAction(`${ConnectorCommonActions.CATEGORY} LOAD_CONNECTORS_USAGE_FAILURE`);
}

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { UserPreferenceAssets } from '@ws1c/intelligence-models';
import { UserPreferenceCommonSelectors } from './user-preference-common.selectors';

/**
 * UserPreferenceAssetsSelectors
 *
 * @export
 * @class UserPreferenceAssetsSelectors
 */
export class UserPreferenceAssetsSelectors {
  public static customerConnectSupportUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CUSTOMER_CONNECT_SUPPORT_LINK);
  public static customerConnectDownloadsUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CUSTOMER_CONNECT_DOWNLOADS_LINK);
  public static customerConnectUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CUSTOMER_CONNECT_LINK);
  public static getAutomationsLearnMoreLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.AUTOMATIONS_LEARN_MORE_LINK);
  public static contactSalesUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CONTACT_SALES_LINK);
  public static getCustomConnectorsSamplesUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CUSTOM_CONNECTORS_SAMPLES_LINK);
  public static getCustomConnectorsUserGuideUrl = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.CUSTOM_CONNECTORS_USER_GUIDE_LINK,
  );
  public static getDashboardsLearnMoreLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.DASHBOARDS_LEARN_MORE_LINK);
  public static getDigitalWorkspaceTechzoneUrl = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.DIGITAL_WORKSPACE_TECHZONE_LINK,
  );
  public static getFilterDescriptionsUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.FILTER_DESCRIPTIONS_LINK);
  public static getHorizonCloudOverviewUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.HORIZON_CLOUD_OVERVIEW_LINK);
  public static getReportsLearnMoreLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.REPORTS_LEARN_MORE_LINK);
  public static getUserGuideUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.INSIGHTS_USER_GUIDE_LINK);
  public static getOmnissaPrivacyPolicyUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.OMNISSA_PRIVACY_POLICY_LINK);
  public static getWorkspaceOneAssistOverviewUrl = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_ASSIST_OVERVIEW_LINK,
  );
  public static getWorkspaceOneDocumentationUrl = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_DOCUMENTATION_LINK,
  );
  public static getWorkspaceOneIntelligenceDocUrl = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_INTELLIGENCE_DOCUMENTATION_LINK,
  );
  public static getWorkspaceOneIntelligenceOverviewUrl = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_INTELLIGENCE_OVERVIEW_LINK,
  );
  public static getWorkspaceOneProductivityAppsOverviewUrl = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_PRODUCTIVITY_APPS_OVERVIEW_LINK,
  );
  public static getWorkspaceOneSupportUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.WORKSPACE_ONE_SUPPORT_LINK);
  public static workspaceOneIdeasLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.WORKSPACE_ONE_IDEAS_LINK);
  public static getConsumerAppsUserGuideUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CONSUMER_APPS_USER_GUIDE_LINK);
  public static getConsumerAppsSDKGuideUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CONSUMER_APPS_SDK_GUIDE_LINK);
  public static getConsumerAppsProductPageUrl = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.CONSUMER_APPS_PRODUCT_PAGE_LINK,
  );
  public static intelligenceHubDocUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.INTELLIGENT_HUB_DOC_URL);
  public static reportSharingDapHelpLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.REPORTS_SHARING_DAP_HELP_LINK);
  public static vanityUrlTechzoneLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.VANITY_URL_TECHZONE_LINK);
  public static workspaceOneAccessDocUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.WORKSPACE_ONE_ACCESS_DOC_URL);
  public static workspaceOneCloudGettingStartedAdminHubLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_CLOUD_GETTING_STARTED_ADMIN_HUB_LINK,
  );
  public static workspaceOneCloudRbacLearnMoreLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_CLOUD_RBAC_LEARN_MORE_LINK,
  );
  public static workspaceOneIntelligenceRegionsUrl = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_INTELLIGENCE_REGIONS_LINK,
  );
  public static workspaceOneUemDocUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.WORKSPACE_ONE_UEM_DOC_URL);
  public static solutionsUemLearnMoreLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.WORKSPACE_ONE_UEM_LEARN_MORE_LINK);
  public static solutionsDeemDesktopTelemetryLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_DEEM_DESKTOP_TELEMETRY_LINK,
  );
  public static solutionsDeemMobileTelemetryLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_DEEM_MOBILE_TELEMETRY_LINK,
  );
  public static solutionsDeemLearnMoreLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_DEEM_LEARN_MORE_LINK,
  );
  public static solutionsDeemRequirementLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_DEEM_REQUIREMENT_LINK,
  );
  public static airwatchHistoricalWidgetDeprecationLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.AIRWATCH_HISTORICAL_WIDGETS_DEPRECATION_LINK,
  );
  public static getDeemOverviewLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.DEEM_OVERVIEW_LINK);
  public static getInsightsNowAvailableLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.INSIGHTS_NOW_AVAILABLE_LINK);
  public static getIncidentsOverviewLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.INCIDENTS_OVERVIEW_LINK);
  public static getSurveysOverviewLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.SURVEYS_OVERVIEW_LINK);
  public static getSurveysUseCasesLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.SURVEYS_USE_CASES_LINK);
  public static getWS1IntelligenceReleaseNotesLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_INTELLIGENCE_RELEASE_NOTES_LINK,
  );
  public static getCveOverviewLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CVE_SOLUTION_OVERVIEW_LINK);
  public static getCveDataDefinitionsLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CVE_SOLUTION_DATA_DEFINITIONS_LINK);
  public static getCveUseCaseLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.CVE_SOLUTION_USE_CASE_LINK);
  public static getReportsDashboardsTutorialLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.REPORTS_AND_DASHBOARDS_TUTORIAL_LINK,
  );
  public static getWS1AccessTenantRenameLearnMoreLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_ACCESS_TENANT_RENAME_LEARN_MORE_URL,
  );
  public static getMarketplaceResourceDeployLearnMoreLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.MARKETPLACE_RESOURCE_DEPLOY_LEARN_MORE_LINK,
  );
  public static getMarketplaceResourceDownloadLearnMoreLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.MARKETPLACE_RESOURCE_DOWNLOAD_LEARN_MORE_LINK,
  );
  public static getDeemOverviewLearnMoreVideoLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.DEEM_OVERVIEW_LEARN_MORE_VIDEO_LINK,
  );
  public static getDeemOverviewSolutionArchitectureLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.DEEM_OVERVIEW_SOLUTION_ARCHITECTURE_LINK,
  );
  public static getIntelligentHubOverviewVideoLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.INTELLIGENT_HUB_OVERVIEW_VIDEO_LINK,
  );
  public static getDeemInsightsOverviewVideoLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.DEEM_INSIGHTS_OVERVIEW_VIDEO_LINK,
  );
  public static getExperienceScoreDashboardOverviewVideoLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.EXPERIENCE_SCORE_DASHBOARD_OVERVIEW_VIDEO_LINK,
  );
  public static getSurveysOverviewWatchVideoLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.SURVEYS_OVERVIEW_WATCH_VIDEO_LINK,
  );
  public static getSurveysMicroSentimentLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.SURVEYS_MICO_SENTIMENT_LINK);
  public static getIncidentsOverviewWatchVideoLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.INCIDENTS_OVERVIEW_WATCH_VIDEO_LINK,
  );
  public static getAssistOverviewVideoLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.ASSIST_OVERVIEW_VIDEO_LINK);
  public static getAssistRemoteSetupVideoLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.ASSIST_REMOTE_SETUP_VIDEO_LINK);
  public static getAssistArchitectureLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.ASSIST_ARCHITECTURE_GUIDE_LINK);
  public static getAssistDistributedWorkforceLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.ASSIST_DISTRIBUTED_WORKFORCE_GUIDE_LINK,
  );
  public static getItsmConnectorOverviewVideoLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.ITSM_CONNECTOR_OVERVIEW_VIDEO_LINK,
  );
  public static getItsmConnectorServicenowGuideLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.ITSM_CONNECTOR_SERVICENOW_GUIDE_LINK,
  );
  public static getItsmConnectorServicenowWorkflowGuideLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.ITSM_CONNECTOR_SERVICENOW_WORKFLOW_GUIDE_LINK,
  );
  public static getHorizonEmployeeEngagementGuideLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.HORIZON_EMPLOYEE_ENGAGEMENT_GUIDE_LINK,
  );
  public static getWorkspaceOneAssistHorizonVideoLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_ASSIST_HORIZON_VIDEO_LINK,
  );
  public static getWorkspaceOneAssistHorizonBlogLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_ASSIST_HORIZON_BLOG_LINK,
  );
  public static getMobileAnalyticsVideoLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.MOBILE_APPS_ANALYTICS_VIDEO_LINK);
  public static getItsmConnectorServicenowLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.ITSM_CONNECTOR_SERVICENOW_LINK,
  );
  public static getWorkspaceOneAssistLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.WORKSPACE_ONE_ASSIST_LINK);
  public static getWorkspaceOneContentAndroidLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_CONTENT_ANDROID_LINK,
  );
  public static getWorkspaceOneContentIosLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.WORKSPACE_ONE_CONTENT_IOS_LINK);
  public static getWorkspaceOneIntelligentHubLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKSPACE_ONE_INTELLIGENT_HUB_LINK,
  );
  public static getSDKDownloadLink = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.SDK_DOWNLOAD_LINK);
  public static getSDKSetupInstructionUrl = UserPreferenceCommonSelectors.getAsset(UserPreferenceAssets.SDK_SETUP_INSTRUCTIONS_URL);
  public static getWorkflowConditionEntityWarningLearnMoreLink = UserPreferenceCommonSelectors.getAsset(
    UserPreferenceAssets.WORKFLOW_CONDITION_ENTITY_WARNING_LEARN_MORE_LINK,
  );
}

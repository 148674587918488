/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { createAction, props } from '@ngrx/store';

import { AccountByUserDescriptor, CspAccount, Directory, UserAdminAccount, UserAdminAccountSearchRequest } from '@ws1c/intelligence-models';

/**
 * UserAdminAccountActions
 * @exports
 * @class UserAdminAccountActions
 */
@Injectable()
export class UserAdminAccountActions {
  public static readonly CATEGORY = '[USER_ADMIN_ACCOUNT]';

  public static searchUserAdminAccounts = createAction(
    `${UserAdminAccountActions.CATEGORY} SEARCH_USER_ADMIN_ACCOUNTS`,
    props<{
      userAdminAccountSearchRequest: UserAdminAccountSearchRequest;
      addAccountDetails: boolean;
    }>(),
  );

  public static searchUserAdminAccountsSuccess = createAction(
    `${UserAdminAccountActions.CATEGORY} SEARCH_USER_ADMIN_ACCOUNTS_SUCCESS`,
    props<{ userAdminAccounts: UserAdminAccount[] }>(),
  );

  public static addAccountDetailsToUser = createAction(
    `${UserAdminAccountActions.CATEGORY} ADD_ACCOUNT_DETAILS_TO_USER`,
    props<{ userAdminAccounts: UserAdminAccount[] }>(),
  );

  public static addAccountDetailsToUserSuccess = createAction(
    `${UserAdminAccountActions.CATEGORY} ADD_ACCOUNT_DETAILS_TO_USER_SUCCESS`,
    props<{
      userAdminAccounts: UserAdminAccount[];
      accountByUserDescriptor: AccountByUserDescriptor;
    }>(),
  );

  public static setOwnerAccountId = createAction(
    `${UserAdminAccountActions.CATEGORY} SET_OWNER_ACCOUNT_ID`,
    props<{ accountId: string }>(),
  );

  public static getAzureDirectory = createAction(`${UserAdminAccountActions.CATEGORY} GET_AZURE_DIRECTORY`);

  public static getAzureDirectorySuccess = createAction(
    `${UserAdminAccountActions.CATEGORY} GET_AZURE_DIRECTORY_SUCCESS`,
    props<{ azureDirectory: Directory }>(),
  );

  public static getAzureDirectoryFailure = createAction(`${UserAdminAccountActions.CATEGORY} GET_AZURE_DIRECTORY_FAILURE`);

  public static deleteAzureDirectory = createAction(`${UserAdminAccountActions.CATEGORY} DELETE_AZURE_DIRECTORY`, props<{ id: string }>());

  public static getCspAccountRequest = createAction(`${UserAdminAccountActions.CATEGORY} GET_CSP_ACCOUNT_REQUEST`);

  public static getCspAccountRequestSuccess = createAction(
    `${UserAdminAccountActions.CATEGORY} GET_CSP_ACCOUNT_REQUEST_SUCCESS`,
    props<{ cspAccountRequestDetails: CspAccount }>(),
  );

  public static getCspAccountRequestFailure = createAction(`${UserAdminAccountActions.CATEGORY} GET_CSP_ACCOUNT_REQUEST_FAILURE`);

  public static submitRequestForCspAccount = createAction(
    `${UserAdminAccountActions.CATEGORY} SUBMIT_REQUEST_FOR_CSP_ACCOUNT`,
    props<{ email: string }>(),
  );

  public static submitRequestForCspAccountSuccess = createAction(
    `${UserAdminAccountActions.CATEGORY} SUBMIT_REQUEST_FOR_CSP_ACCOUNT_SUCCESS`,
  );

  public static submitRequestForCspAccountFailure = createAction(
    `${UserAdminAccountActions.CATEGORY} SUBMIT_REQUEST_FOR_CSP_ACCOUNT_FAILURE`,
  );

  public static setCspAccountWithEmailExistenceState = createAction(
    `${UserAdminAccountActions.CATEGORY} SET_CSP_ACCOUNT_WITH_EMAIL_EXISTENCE_STATE`,
    props<{ isCspAccountWithEmailAlreadyExists: boolean }>(),
  );
}

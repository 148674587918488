/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { GenericObject, PieChartComponent } from '@dpa/ui-common';

import { BaseChartComponent } from '@ws1c/dashboard-common/chart/base-chart.component';
import { ChartDrilldownEvent, DashboardConfig, NgxChart } from '@ws1c/intelligence-models';

/**
 * Donut Chart Component
 * @export
 * @class DonutChartComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-donut-chart',
  templateUrl: 'donut-chart.component.html',
  styleUrls: ['donut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartComponent extends BaseChartComponent implements OnChanges {
  @Input() public ngxChart: NgxChart;
  @Input() public showDetailsLink?: boolean = false;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public showAllAvailableLabels?: boolean = false;
  @Input() public showLabels?: boolean = true;
  @Input() public totalHeaderKey?: string = 'COMMON_MESSAGES.TOTAL';
  @Input() public totalHeaderValue?: number;
  @Input() public totalHeaderClass?: string;
  @Output() public onViewDetails = new EventEmitter<ChartDrilldownEvent>();

  @ViewChild(PieChartComponent) public chart: PieChartComponent;

  public maxLabelVisible: number = DashboardConfig.MAX_VISIBLE_DONUT_LABELS;
  public total: number;
  public nameValue: Record<string, number> = {};

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof DonutChartComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.ngxChart && changes.ngxChart.currentValue) {
      this.maxLabelVisible = this.showAllAvailableLabels ? this.ngxChart.chartData.length + 1 : this.maxLabelVisible;
      this.total = this.ngxChart.rootNode.value;
      this.ngxChart.chartData?.forEach((chartData: GenericObject) => {
        this.nameValue[chartData.name] = chartData.value;
      });
    }
  }

  /**
   * onSelect
   * @param {any} ngxSelectEvent
   * @memberof DonutChartComponent
   */
  public onSelect(ngxSelectEvent: any) {
    if (this.showDetailsLink) {
      const drilldownEvent: ChartDrilldownEvent = this.ngxChart.drilldownEventBuilder.getEvent(ngxSelectEvent, false);
      this.onViewDetails.emit(drilldownEvent);
    }
  }

  /**
   * onResize
   *
   * @memberof DonutChartComponent
   */
  public onResize() {
    if (!this.chart) {
      return;
    }
    this.chart.update();
  }

  /**
   * showLabel
   *
   * @returns {boolean}
   * @memberof DonutChartComponent
   */
  public showLabel(): boolean {
    return this.showLabels && !this.ngxChart.usingFakeGroupBy && this.ngxChart.chartData.length < this.maxLabelVisible;
  }

  /**
   * labelFormatting
   * @param {string} label
   * @returns {boolean}
   * @memberof DonutChartComponent
   */
  public labelFormatting = (label: string): string => {
    return this.total ? `${label} (${Math.round((100 * this.nameValue[label]) / this.total)}%)` : label;
  };
}

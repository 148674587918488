/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import {
  LOAD_STATE,
  MarketplaceResource,
  MarketplaceResourceType,
  MarketplaceSearchRequest,
  MarketplaceSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * MarketplaceState
 * @export
 * @interface MarketplaceState
 */
export interface MarketplaceState {
  searchResponseByType: Partial<Record<MarketplaceResourceType, MarketplaceSearchResponse>>;
  searchRequest: MarketplaceSearchRequest;
  searchResponse: MarketplaceSearchResponse;
  searchLoadStatus: LOAD_STATE;
  resourcePreview: {
    request: MarketplaceSearchRequest;
    response: MarketplaceSearchResponse;
    isVisible: boolean;
    previewResource: MarketplaceResource;
  };
  resourceCountByType: Partial<Record<MarketplaceResourceType, number>>;
  resourceCountLoadStatus: LOAD_STATE;
  featuredResource: MarketplaceResource;
  addToWorkspaceLoadStatus: LOAD_STATE;
  isFrontlineEnabledModalVisible: boolean;
}

/**
 * initialMarketplaceState
 */
export const initialMarketplaceState: MarketplaceState = {
  searchResponseByType: undefined,
  searchRequest: undefined,
  searchResponse: undefined,
  searchLoadStatus: LOAD_STATE.NONE,
  resourcePreview: {
    request: undefined,
    response: undefined,
    isVisible: false,
    previewResource: undefined,
  },
  resourceCountByType: undefined,
  resourceCountLoadStatus: LOAD_STATE.NONE,
  featuredResource: undefined,
  addToWorkspaceLoadStatus: LOAD_STATE.NONE,
  isFrontlineEnabledModalVisible: false,
};

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { cloneDeep } from 'lodash-es';

import { DeemDashboardConfig } from '@ws1c/deem-solution/const';
import { DeemThresholdSection, SolutionFilterTag, Tag, TAGS_TYPE, ThresholdSettingType } from '@ws1c/intelligence-models';

/**
 * filterDeemSettingTags
 * @export
 * @param {boolean} isDeemPhysicalEnabled
 * @param {boolean} isDeemVirtualEnabled
 * @param {Tag[]} [tags=[]]
 * @returns {Tag[]}
 */
export function filterDeemSettingTags(isDeemPhysicalEnabled: boolean, isDeemVirtualEnabled: boolean, tags: Tag[] = []): Tag[] {
  if (!tags.length) {
    return [];
  }
  const _tags: Tag[] = cloneDeep(tags);
  return _tags.filter((tag: Tag) => {
    if (!tag.subtags?.length) {
      tag.type = TAGS_TYPE.BOOLEAN;
      return isValidTag(tag, isDeemPhysicalEnabled, isDeemVirtualEnabled);
    }
    tag.subtags = tag.subtags.filter((subtag: Tag) => {
      return isValidTag(subtag, isDeemPhysicalEnabled, isDeemVirtualEnabled);
    });
    return tag.subtags.length;
  });
}

/**
 * isValidTag
 * @export
 * @param {Tag} tag
 * @param {boolean} isDeemPhysicalEnabled
 * @param {boolean} isDeemVirtualEnabled
 * @returns {boolean}
 */
export function isValidTag(tag: Tag, isDeemPhysicalEnabled: boolean, isDeemVirtualEnabled: boolean): boolean {
  // When only DEEM virtual is enabled, there is only one kind of platform - virtual.
  // In this case the "virtual" tag is invalid because it's a tag for every threshold.
  if (!isDeemPhysicalEnabled && tag.name === SolutionFilterTag.VIRTUAL) {
    return false;
  }
  return (
    (isDeemPhysicalEnabled && DeemDashboardConfig.PHYSICAL_FILTER_TAGS.includes(tag.name)) ||
    (isDeemVirtualEnabled && DeemDashboardConfig.VIRTUAL_FILTER_TAGS.includes(tag.name))
  );
}

/**
 * filterThresholdSections
 * @export
 * @param {boolean} isDeemPhysicalEnabled
 * @param {boolean} isDeemVirtualEnabled
 * @param {DeemThresholdSection[]} [sections=[]]
 * @returns {DeemThresholdSection[]}
 */
export function filterThresholdSections(
  isDeemPhysicalEnabled: boolean,
  isDeemVirtualEnabled: boolean,
  sections: DeemThresholdSection[] = [],
): DeemThresholdSection[] {
  return sections.filter((section: DeemThresholdSection) => {
    return (
      (isDeemPhysicalEnabled && section.settingsType === ThresholdSettingType.DEEM_PHYSICAL_EXPERIENCE_SCORE) ||
      (isDeemVirtualEnabled && section.settingsType === ThresholdSettingType.DEEM_VIRTUAL_EXPERIENCE_SCORE)
    );
  });
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { SolutionSettingMetricDetails, SolutionSettingMetricKey } from '@ws1c/intelligence-models';

/**
 * SolutionSettingMetricService
 * @export
 * @class SolutionSettingMetricService
 */
@Injectable({
  providedIn: 'root',
})
export class SolutionSettingMetricService {
  /**
   * Creates an instance of SolutionSettingMetricService.
   * @param {HttpService} httpService
   * @memberof SolutionSettingMetricService
   */
  constructor(private httpService: HttpService) {}

  /**
   * getDefaultSolutionSettingMetrics
   * @param {SolutionSettingMetricKey} settingMetricKey
   * @returns {Observable<SolutionSettingMetricDetails[]>}
   * @memberof SolutionSettingMetricService
   */
  public getDefaultSolutionSettingMetrics(settingMetricKey: SolutionSettingMetricKey): Observable<SolutionSettingMetricDetails[]> {
    return this.httpService.post(Endpoint.SOLUTION_SETTING_METRIC_DEFAULTS, settingMetricKey).pipe(
      map((response: GenericObject) =>
        response.data.map((responseItem: GenericObject) => deserialize(SolutionSettingMetricDetails, responseItem)),
      ),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getSolutionSettingMetrics
   * @param {SolutionSettingMetricKey} metricKey
   * @returns {Observable<SolutionSettingMetricDetails[]>}
   * @memberof SolutionSettingMetricService
   */
  public getSolutionSettingMetrics(metricKey: SolutionSettingMetricKey): Observable<SolutionSettingMetricDetails[]> {
    return this.httpService.post(Endpoint.SOLUTION_SETTING_METRIC, metricKey).pipe(
      map((response: GenericObject) =>
        response.data.map((responseItem: GenericObject) => deserialize(SolutionSettingMetricDetails, responseItem)),
      ),
      catchError(requestErrorHandler),
    );
  }

  /**
   * setSolutionSettingMetrics
   * @param {SolutionSettingMetricDetails[]} data
   * @returns {Observable<SolutionSettingMetricDetails[]>}
   * @memberof SolutionSettingMetricService
   */
  public setSolutionSettingMetrics(data: SolutionSettingMetricDetails[]): Observable<SolutionSettingMetricDetails[]> {
    return this.httpService.post(Endpoint.SOLUTION_SETTING_METRIC_UPDATE, data).pipe(
      map((response: GenericObject) =>
        response.data.map((responseItem: GenericObject) => deserialize(SolutionSettingMetricDetails, responseItem)),
      ),
      catchError(requestErrorHandler),
    );
  }

  /**
   * resetSolutionSettingMetrics
   * @param {SolutionSettingMetricKey} metricKey
   * @returns {Observable<SolutionSettingMetricDetails[]>}
   * @memberof SolutionSettingMetricService
   */
  public resetSolutionSettingMetrics(metricKey: SolutionSettingMetricKey): Observable<SolutionSettingMetricDetails[]> {
    return this.httpService.post(Endpoint.SOLUTION_SETTING_METRIC_RESET, metricKey).pipe(
      map((response: GenericObject) =>
        response.data.map((responseItem: GenericObject) => deserialize(SolutionSettingMetricDetails, responseItem)),
      ),
      catchError(requestErrorHandler),
    );
  }
}

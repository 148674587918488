/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { uniqueId } from 'lodash-es';

/**
 * Display List of Charts as button group so user can select on
 *
 * @export
 * @implements {OnInit}
 * @class ChartSelectorComponent
 */
@Component({
  selector: 'dpa-chart-selector',
  templateUrl: 'chart-selector.component.html',
  styleUrls: ['chart-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSelectorComponent implements OnInit {
  @Input() public isCompact?: boolean = false;
  @Input() public chartTypes: string[];
  @Input() public selectedChartType: string;
  @Input() public allowedChartTypes: string[];
  @Output() public chartTypeSelected = new EventEmitter<string>();

  public componentId: string = uniqueId();
  public showChartTypesList = false;

  private elRef: ElementRef = inject(ElementRef);
  private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  /**
   * ngOnInit
   *
   * @memberof ChartSelectorComponent
   */
  public ngOnInit() {
    this.selectChartType(this.selectedChartType);
  }

  /**
   * Get unique id for chart selector to fix Clarity issue
   *
   * @param {string} chartType
   * @returns {string}
   * @memberof ChartSelectorComponent
   */
  public getChartTypeId(chartType: string): string {
    return `chart-selector-chart-type-${chartType.toLowerCase()}-${this.componentId}`;
  }

  /**
   * showChartSelectionOptions
   * @memberof ChartSelectorComponent
   */
  public showChartSelectionOptions() {
    this.showChartTypesList = true;
    this.registerOutsideClickEventHandler();
  }

  /**
   * selectChartType
   *
   * @param {string} chartType
   * @param {MouseEvent} event
   * @memberof ChartSelectorComponent
   */
  public selectChartType = (chartType: string, event?: MouseEvent) => {
    this.showChartTypesList = false;
    event?.stopPropagation();
    this.chartTypeSelected.emit(chartType);
  };

  /**
   * toggleList
   * @memberof ChartSelectorComponent
   */
  public toggleList = () => {
    this.showChartTypesList = !this.showChartTypesList;
    if (this.showChartTypesList) {
      this.showChartSelectionOptions();
    }
  };

  /**
   * registerOutsideClickEventHandler
   * @private
   * @memberof ChartSelectorComponent
   */
  private registerOutsideClickEventHandler() {
    document.body.addEventListener(
      'click',
      (e) => {
        if (!this.elRef.nativeElement.contains(e.target)) {
          this.showChartTypesList = false;
          this.cdRef.detectChanges();
        }
      },
      {
        capture: true,
        once: true,
      },
    );
  }
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { createAction, props } from '@ngrx/store';

import {
  DeemThresholds,
  SolutionSettingMetricDetails,
  SolutionSettingMetricKey,
  Trend,
  WidgetDetailDefinition,
} from '@ws1c/intelligence-models';

/**
 * DeemCommonActions
 *
 * @export
 * @class DeemCommonActions
 */
@Injectable()
export class DeemCommonActions {
  public static readonly CATEGORY = '[DEEM_COMMON]';

  public static navigateToSurveyCreate = createAction(
    `${DeemCommonActions.CATEGORY} NAVIGATE_TO_SURVEY_CREATE`,
    props<{
      detail: WidgetDetailDefinition;
      trend: Trend;
      returnPath: string;
    }>(),
  );

  public static resetSurveyWidget = createAction(`${DeemCommonActions.CATEGORY} RESET_SURVEY_WIDGET`);

  public static returnToUrl = createAction(
    `${DeemCommonActions.CATEGORY} RETURN_TO_URL`,
    props<{
      url: string;
    }>(),
  );

  public static getDefaultThresholdV2 = createAction(
    `${DeemCommonActions.CATEGORY} GET_DEFAULT_THRESHOLD_V2`,
    props<{ settingMetricKey: SolutionSettingMetricKey }>(),
  );

  public static resetThresholdV2 = createAction(
    `${DeemCommonActions.CATEGORY} RESET_THRESHOLD_V2`,
    props<{ settingMetricKey: SolutionSettingMetricKey }>(),
  );

  public static setThresholdV2 = createAction(
    `${DeemCommonActions.CATEGORY} SET_THRESHOLD_V2`,
    props<{ metrics: SolutionSettingMetricDetails[] }>(),
  );

  public static setThresholdV2Success = createAction(
    `${DeemCommonActions.CATEGORY} SET_THRESHOLD_V2_SUCCESS`,
    props<{ thresholdsByEntity: DeemThresholds; entity: string; metric?: string; platform?: string }>(),
  );

  public static getActiveSettingMetrics = createAction(
    `${DeemCommonActions.CATEGORY} GET_ACTIVE_SETTING_METRICS`,
    props<{ settingMetricKey: SolutionSettingMetricKey }>(),
  );

  public static setActiveSettingMetrics = createAction(
    `${DeemCommonActions.CATEGORY} SET_ACTIVE_SETTING_METRICS`,
    props<{ activeSettingMetrics: SolutionSettingMetricDetails[] }>(),
  );

  public static setUpdatingActiveSettingMetrics = createAction(
    `${DeemCommonActions.CATEGORY} SET_UPDATING_ACTIVE_SETTING_METRICS`,
    props<{ updatingSettingMetrics: SolutionSettingMetricDetails[] }>(),
  );

  public static showDeemThresholdEditModal = createAction(`${DeemCommonActions.CATEGORY} SHOW_DEEM_THRESHOLD_EDIT_MODAL`);
  public static closeDeemThresholdEditModal = createAction(`${DeemCommonActions.CATEGORY} CLOSE_DEEM_THRESHOLD_EDIT_MODAL`);
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { MARKETPLACE_PREVIEW_REQUEST_SIZE } from '@ws1c/intelligence-core/const';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { MarketplaceState } from '@ws1c/intelligence-core/store/marketplace/marketplace.state';
import { UserPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference/user-preference.selectors';
import {
  LOAD_STATE,
  MarketplaceResource,
  MarketplaceResourceType,
  MarketplaceSearchRequest,
  MarketplaceSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * MarketplaceSelectors
 *
 * @export
 * @class MarketplaceSelectors
 */
export class MarketplaceSelectors {
  /**
   * selectMarketplaceState
   *
   * @static
   * @param {CoreAppState} state
   * @returns {MarketplaceState}
   * @memberof MarketplaceSelectors
   */
  public static selectMarketplaceState = (state: CoreAppState) => state.marketplaceState;

  /**
   * getSearchRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, MarketplaceSearchRequest>}
   * @memberof MarketplaceSelectors
   */
  public static getSearchRequest: MemoizedSelector<CoreAppState, MarketplaceSearchRequest> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.searchRequest,
  );

  /**
   * getSearchResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, MarketplaceSearchResponse>}
   * @memberof MarketplaceSelectors
   */
  public static getSearchResponse: MemoizedSelector<CoreAppState, MarketplaceSearchResponse> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.searchResponse,
  );

  /**
   * isSearchLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof MarketplaceSelectors
   */
  public static isSearchLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.searchLoadStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * isResourcePreviewVisible
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof MarketplaceSelectors
   */
  public static isResourcePreviewVisible: MemoizedSelector<CoreAppState, boolean> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.resourcePreview.isVisible,
  );

  /**
   * getResourcePreviewRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, MarketplaceSearchRequest>}
   * @memberof MarketplaceSelectors
   */
  public static getResourcePreviewRequest: MemoizedSelector<CoreAppState, MarketplaceSearchRequest> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.resourcePreview.request,
  );

  /**
   * getPreviewResource
   * @static
   * @type {MemoizedSelector<CoreAppState, MarketplaceResource>}
   * @memberof MarketplaceSelectors
   */
  public static getPreviewResource: MemoizedSelector<CoreAppState, MarketplaceResource> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.resourcePreview.previewResource,
  );

  /**
   * getPreviousPreviewResource
   * @static
   * @type {MemoizedSelector<CoreAppState, MarketplaceResource>}
   * @memberof MarketplaceSelectors
   */
  public static getPreviousPreviewResource: MemoizedSelector<CoreAppState, MarketplaceResource> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => {
      const resources = state.resourcePreview.response?.searchResults.results ?? [];
      if (resources.length === MARKETPLACE_PREVIEW_REQUEST_SIZE) {
        return resources[0];
      }
      const selectedResource = state.resourcePreview.previewResource;
      const selectedResourceIndex = resources.findIndex((resource: MarketplaceResource) => resource.id === selectedResource.id);
      if (selectedResourceIndex === 1) {
        return resources[0];
      }
    },
  );

  /**
   * getNextPreviewResource
   * @static
   * @type {MemoizedSelector<CoreAppState, MarketplaceResource>}
   * @memberof MarketplaceSelectors
   */
  public static getNextPreviewResource: MemoizedSelector<CoreAppState, MarketplaceResource> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => {
      const resources = state.resourcePreview.response?.searchResults.results ?? [];
      if (resources.length === MARKETPLACE_PREVIEW_REQUEST_SIZE) {
        return resources[2];
      }
      const selectedResource = state.resourcePreview.previewResource;
      const selectedResourceIndex = resources.findIndex((resource: MarketplaceResource) => resource.id === selectedResource.id);
      if (selectedResourceIndex === 0) {
        return resources[1];
      }
    },
  );

  /**
   * getAddToWorkspaceLoadStatus
   * @static
   * @type {MemoizedSelector<CoreAppState, LOAD_STATE>}
   * @memberof MarketplaceSelectors
   */
  public static getAddToWorkspaceLoadStatus: MemoizedSelector<CoreAppState, LOAD_STATE> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.addToWorkspaceLoadStatus,
  );

  /**
   * getFeaturedResource
   * @static
   * @type {MemoizedSelector<CoreAppState, MarketplaceResource>}
   * @memberof MarketplaceSelectors
   */
  public static getFeaturedResource: MemoizedSelector<CoreAppState, MarketplaceResource> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.featuredResource,
  );

  /**
   * isFrontlineEnabledModalVisible
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberof MarketplaceSelectors
   */
  public static isFrontlineEnabledModalVisible: MemoizedSelector<CoreAppState, boolean> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.isFrontlineEnabledModalVisible,
  );

  /**
   * isResourceCountNotLoaded
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof MarketplaceSelectors
   */
  public static isResourceCountNotLoaded: MemoizedSelector<CoreAppState, boolean> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => [LOAD_STATE.NONE, LOAD_STATE.FAILURE].includes(state.resourceCountLoadStatus),
  );

  /**
   * getResourceCountByType
   * @static
   * @type {MemoizedSelector<CoreAppState, Partial<Record<MarketplaceResourceType, number>>>}
   * @memberof MarketplaceSelectors
   */
  public static getResourceCountByType: MemoizedSelector<CoreAppState, Partial<Record<MarketplaceResourceType, number>>> = createSelector(
    MarketplaceSelectors.selectMarketplaceState,
    (state: MarketplaceState) => state.resourceCountByType,
  );

  /**
   * getSearchResponseByType
   * @static
   * @param {MarketplaceResourceType} type
   * @type {(type: MarketplaceResourceType) => MemoizedSelector<CoreAppState, MarketplaceSearchResponse>}
   * @memberof MarketplaceSelectors
   */
  public static getSearchResponseByType = (type: MarketplaceResourceType): MemoizedSelector<CoreAppState, MarketplaceSearchResponse> =>
    createSelector(MarketplaceSelectors.selectMarketplaceState, (state: MarketplaceState) => {
      return state.searchResponseByType?.[type];
    });

  /**
   * getDeployDisabledMessageKey
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf MarketplaceSelectors
   */
  public static getDeployDisabledMessageKey: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceSelectors.isCspLoggedInUser,
    UserPreferenceSelectors.isCspLinkedOrg,
    (isCspLoggedInUser: boolean, isCspLinkedOrg: boolean) => {
      if (isCspLoggedInUser) {
        return 'DEPLOY_DISABLED_FOR_CSP_IDENTITY';
      } else if (isCspLinkedOrg) {
        return 'DEPLOY_DISABLED_FOR_UEM_IDENTITY_IN_WS1C_ORG';
      }
      return 'DEPLOY_DISABLED_FOR_UEM_IDENTITY';
    },
  );
}

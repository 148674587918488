/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CspAccount, Directory, LOAD_STATE, UserAdminAccount } from '@ws1c/intelligence-models';

/**
 * Defines UI State for User Admin Account
 *
 * @exports
 * @interface UserAdminAccountState
 */
export interface UserAdminAccountState {
  userSearchResponse: UserAdminAccount[];
  isUserSearchLoading: boolean;
  currentOwnerAccountId: string;
  azureDirectory: Directory;
  loadingAzure: boolean;
  cspAccountRequestDetails: CspAccount;
  cspAccountRequestSubmitState: LOAD_STATE;
  isCspAccountWithEmailAlreadyExists: boolean;
}

export const initialUserAdminAccountState: UserAdminAccountState = {
  userSearchResponse: [],
  isUserSearchLoading: false,
  currentOwnerAccountId: undefined,
  azureDirectory: undefined,
  loadingAzure: false,
  cspAccountRequestDetails: undefined,
  cspAccountRequestSubmitState: LOAD_STATE.NONE,
  isCspAccountWithEmailAlreadyExists: undefined,
};

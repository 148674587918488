/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { FeatureControl } from '@ws1c/intelligence-common';
import { ONBOARDING_PREFERENCE_TO_FEATURE_MAP } from '@ws1c/intelligence-core/const/onboarding-survey';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { SolutionSetupSelectors } from '@ws1c/intelligence-core/store/solution-setup';
import {
  UserPreferenceCommonSelectors,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core/store/user-preference';
import {
  FeatureControls,
  Integration,
  IntegrationDetails,
  INTELLIGENCE_FEATURES,
  IntelligenceFeature,
  LOAD_STATE,
  ObjectById,
  ONBOARDING_PREFERENCES,
  OptInFeatureFlag,
  OrgDisabledConfig,
  OrgPreference,
  OrgTreeNode,
  Solution,
} from '@ws1c/intelligence-models';
import { preRequisiteForOnboardingStep, setOrgsById } from './org-selector-helper';
import { OrgState } from './org.state';

/**
 * OrgSelectors
 *
 * @export
 * @class OrgSelectors
 */
export class OrgSelectors {
  /**
   * selectOrgState$
   * @type {(state: CoreAppState) => OrgState}
   * @returns {OrgState}
   */
  public static selectOrgState$ = (state: CoreAppState) => state.orgState;

  /**
   * getCurrentOptingFeature
   * @type {MemoizedSelector<CoreAppState, OptInFeatureFlag>}
   * @returns {OptInFeatureFlag}
   */
  public static getCurrentOptingFeature: MemoizedSelector<CoreAppState, OptInFeatureFlag> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.currentOptingFeature,
  );

  /**
   * showOptInFeatureConfirmEnableModal
   * @param {OptInFeatureFlag} optInFeatureFlag
   * @returns {MemoizedSelector<CoreAppState, boolean>}
   */
  public static showOptInFeatureConfirmEnableModal = (optInFeatureFlag: OptInFeatureFlag): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      OrgSelectors.selectOrgState$,
      (state: OrgState) => state.showConfirmEnableFeatureModal && state.currentOptingFeature === optInFeatureFlag,
    );

  /**
   * showOptInFeatureConfirmDisableModal
   * @param {OptInFeatureFlag} optInFeatureFlag
   * @returns {MemoizedSelector<CoreAppState, boolean>}
   */
  public static showOptInFeatureConfirmDisableModal = (optInFeatureFlag: OptInFeatureFlag): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      OrgSelectors.selectOrgState$,
      (state: OrgState) => state.showConfirmDisableFeatureModal && state.currentOptingFeature === optInFeatureFlag,
    );

  /**
   * isCompletingOnboardingStep
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isCompletingOnboardingStep: MemoizedSelector<CoreAppState, boolean> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.isCompletingOnboardingStep,
  );

  /**
   * isAbortingOnboardingStep
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isAbortingOnboardingStep: MemoizedSelector<CoreAppState, boolean> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.isAbortingOnboardingStep,
  );

  /**
   * showConfirmEnableFeatureModal
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static showConfirmEnableFeatureModal: MemoizedSelector<CoreAppState, boolean> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.showConfirmEnableFeatureModal,
  );

  /**
   * showConfirmDisableFeatureModal
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static showConfirmDisableFeatureModal: MemoizedSelector<CoreAppState, boolean> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.showConfirmDisableFeatureModal,
  );

  /**
   * isCurrentFeatureOperationInProgress
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isCurrentFeatureOperationInProgress: MemoizedSelector<CoreAppState, boolean> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.currentOperationInProgress,
  );

  /**
   * getOrgPreferences
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @returns {GenericObject}
   */
  public static getOrgPreferences: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state?.orgSettings?.preferences ?? {},
  );

  /**
   * isOrgPreferenceEnabled
   * @param {OrgPreference} preferenceKey
   * @returns {MemoizedSelector<CoreAppState, boolean>}
   */
  public static isOrgPreferenceEnabled = (preferenceKey: OrgPreference): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(OrgSelectors.getOrgPreferences, (orgPreferences: GenericObject) => orgPreferences[preferenceKey]);

  /**
   * isAccessTenantReprovisionStepCompleted
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isAccessTenantReprovisionStepCompleted: MemoizedSelector<CoreAppState, boolean> = OrgSelectors.isOrgPreferenceEnabled(
    OrgPreference.ACCESS_TENANT_REPROVISION_STEP_COMPLETED,
  );

  /**
   * isBrokerAccessBrownFieldCustomer
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isBrokerAccessBrownFieldCustomer: MemoizedSelector<CoreAppState, boolean> = OrgSelectors.isOrgPreferenceEnabled(
    OrgPreference.BROKER_ACCESS_BROWN_FIELD_CUSTOMER,
  );

  /**
   * isCspFederationAccessRequested
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isCspFederationAccessRequested = OrgSelectors.isOrgPreferenceEnabled(OrgPreference.CSP_FEDERATION_ACCESS_REQUESTED);

  /**
   * isServiceManualOptInPerformed
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isServiceManualOptInPerformed = OrgSelectors.isOrgPreferenceEnabled(OrgPreference.SERVICE_MANUAL_OPT_IN_PERFORMED);

  /**
   * isServiceOptInAcknowledged
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isServiceOptInAcknowledged = createSelector(
    OrgSelectors.getOrgPreferences,
    (preferences: GenericObject) =>
      preferences[OrgPreference.SERVICE_AUTO_OPT_IN_ACKNOWLEDGED] || preferences[OrgPreference.SERVICE_MANUAL_OPT_IN_PERFORMED],
  );

  /**
   * currentOrgPreferenceKeys
   *
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @returns {string[]}
   */
  public static currentOrgPreferenceKeys: MemoizedSelector<CoreAppState, string[]> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.currentOrgPreferenceKeys,
  );

  /**
   * isServiceOptInModalOpen
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isServiceOptInModalOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.isServiceOptInModalOpen,
  );

  /**
   * Getter to retrieve orgHierachy state
   *
   * @type {MemoizedSelector<CoreAppState, OrgTreeNode>}
   * @returns {OrgTreeNode}
   */
  public static getOrgHierarchy: MemoizedSelector<CoreAppState, OrgTreeNode> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.orgHierachy,
  );

  /**
   * orgDisabledConfig
   * @type {MemoizedSelector<CoreAppState, OrgDisabledConfig>}
   * @returns {OrgDisabledConfig}
   */
  public static orgDisabledConfig: MemoizedSelector<CoreAppState, OrgDisabledConfig> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.orgDisabledConfig,
  );

  /**
   * hasOrgDisabledConfig
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static hasOrgDisabledConfig: MemoizedSelector<CoreAppState, boolean> = createSelector(
    OrgSelectors.orgDisabledConfig,
    (orgDisabledConfig: OrgDisabledConfig) => !!orgDisabledConfig,
  );

  /**
   * isWorkspaceOneCloudOrg
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isWorkspaceOneCloudOrg: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.showWorkspaceOneCloud,
    OrgSelectors.orgDisabledConfig,
    (showWorkspaceOneCloud: boolean, orgDisabledConfig: OrgDisabledConfig) => {
      return showWorkspaceOneCloud || orgDisabledConfig?.isWs1cOrg;
    },
  );

  /**
   * Getter to retrieve orgPreferenceUpdate state
   *
   * @type {MemoizedSelector<CoreAppState, LOAD_STATE>}
   * @returns {LOAD_STATE}
   */
  public static orgPreferenceUpdateState: MemoizedSelector<CoreAppState, LOAD_STATE> = createSelector(
    OrgSelectors.selectOrgState$,
    (state: OrgState) => state.orgPreferenceUpdateState,
  );

  /**
   * getOrgsById
   * @type {MemoizedSelector<CoreAppState, ObjectById<OrgTreeNode>>}
   * @returns {ObjectById<OrgTreeNode>}
   */
  public static getOrgsById: MemoizedSelector<CoreAppState, ObjectById<OrgTreeNode>> = createSelector(
    OrgSelectors.getOrgHierarchy,
    (orgHierarchy: OrgTreeNode) => {
      const orgsById = {};
      setOrgsById(orgHierarchy, orgsById);
      return orgsById;
    },
  );

  /**
   * allowedOnboardingPreferences
   * @static
   * @type {MemoizedSelector<CoreAppState, OrgPreference[]>}
   * @memberof OrgSelectors
   */
  public static allowedOnboardingPreferences: MemoizedSelector<CoreAppState, OrgPreference[]> = createSelector(
    UserPreferenceSelectors.isTrialQuestionnaireEnabled,
    (isTrialQuestionnaireEnabled: boolean) => {
      if (isTrialQuestionnaireEnabled) {
        return ONBOARDING_PREFERENCES;
      }
      return ONBOARDING_PREFERENCES.filter((orgPreference: OrgPreference) => {
        return !ONBOARDING_PREFERENCE_TO_FEATURE_MAP.get(orgPreference).isTrialOnly;
      });
    },
  );

  /**
   * allowedNextOnboardingStepRoute
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof OrgSelectors
   */
  public static allowedNextOnboardingStepRoute: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceCommonSelectors.getFeatureControls,
    OrgSelectors.getOrgPreferences,
    OrgSelectors.allowedOnboardingPreferences,
    UserPreferenceSelectors.latestGemProvisionedAccessTenant,
    (
      featureControls: FeatureControls,
      preferences: GenericObject,
      allowedOnboardingPreferences: OrgPreference[],
      latestGemProvisionedAccessTenant: IntegrationDetails,
    ) => {
      if (!featureControls?.[FeatureControl.WORKSPACE_ONE_CLOUD_ENABLED]) {
        return '';
      }
      const allowedNextOnboardingStepOrgPreference: OrgPreference = allowedOnboardingPreferences.find((orgPreference: OrgPreference) => {
        const onboardingPreferenceFeatureFlag: FeatureControl = ONBOARDING_PREFERENCE_TO_FEATURE_MAP.get(orgPreference).featureControlKey;
        return (
          featureControls?.[onboardingPreferenceFeatureFlag] &&
          preRequisiteForOnboardingStep(orgPreference, latestGemProvisionedAccessTenant) &&
          !(preferences[orgPreference] || preferences[OrgPreference.ABORTED_ONBOARDING_PREFERENCES]?.includes(orgPreference))
        );
      });
      return allowedNextOnboardingStepOrgPreference
        ? ONBOARDING_PREFERENCE_TO_FEATURE_MAP.get(allowedNextOnboardingStepOrgPreference).route
        : '';
    },
  );

  /**
   * isOnboardingComplete
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof OrgSelectors
   */
  public static isOnboardingComplete: MemoizedSelector<CoreAppState, boolean> = createSelector(
    OrgSelectors.allowedNextOnboardingStepRoute,
    (allowedNextOnboardingStepRoute: string) => !allowedNextOnboardingStepRoute,
  );

  /**
   * isGlobalSearchAllowed
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isGlobalSearchAllowed: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isGlobalSearchEnabled,
    OrgSelectors.isOnboardingComplete,
    (isGlobalSearchEnabled: boolean, isOnboardingComplete: boolean) => {
      return isGlobalSearchEnabled && isOnboardingComplete;
    },
  );

  /**
   * isUpdatingOrgPreferenceSelector$
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   */
  public static isUpdatingOptInPreference: MemoizedSelector<CoreAppState, boolean> = createSelector(
    OrgSelectors.currentOrgPreferenceKeys,
    OrgSelectors.orgPreferenceUpdateState,
    (currentOrgPreferenceKeys: string[], orgPreferenceUpdateState: LOAD_STATE) => {
      return (
        orgPreferenceUpdateState === LOAD_STATE.IN_FLIGHT &&
        (currentOrgPreferenceKeys.includes(OrgPreference.SERVICE_AUTO_OPT_IN_ACKNOWLEDGED) ||
          currentOrgPreferenceKeys.includes(OrgPreference.SERVICE_MANUAL_OPT_IN_PERFORMED))
      );
    },
  );

  /**
   * updatingOrgPreferenceKeys
   *
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @returns {string[]>}
   */
  public static updatingOrgPreferenceKeys: MemoizedSelector<CoreAppState, string[]> = createSelector(
    OrgSelectors.orgPreferenceUpdateState,
    OrgSelectors.currentOrgPreferenceKeys,
    (orgPreferenceUpdateState: LOAD_STATE, currentOrgPreferenceKeys: string[]) => {
      return orgPreferenceUpdateState === LOAD_STATE.IN_FLIGHT ? currentOrgPreferenceKeys : [];
    },
  );

  /**
   * disabledFeatures
   * @static
   * @type {MemoizedSelector<CoreAppState, IntelligenceFeature[]>}
   * @memberof OrgSelectors
   */
  public static disabledFeatures: MemoizedSelector<CoreAppState, IntelligenceFeature[]> = createSelector(
    UserPreferenceSelectors.hasPremiumLicense,
    UserPreferenceFeatureControlsSelectors.isDashboardsEnabled,
    UserPreferenceFeatureControlsSelectors.isAutomationEnabled,
    SolutionSetupSelectors.isSolutionEnabled(Solution.DEEM),
    UserPreferenceFeatureControlsSelectors.isDashboardUserRiskEnabled,
    (
      hasPremiumLicense: boolean,
      isDashboardsEnabled: boolean,
      isAutomationEnabled: boolean,
      isDeemEnabled: boolean,
      isDashboardUserRiskEnabled: boolean,
    ) => {
      if (hasPremiumLicense) {
        return [];
      }
      return INTELLIGENCE_FEATURES.filter((feature: IntelligenceFeature) => {
        return (
          (feature === IntelligenceFeature.DASHBOARD && !isDashboardsEnabled) ||
          (feature === IntelligenceFeature.AUTOMATION && !isAutomationEnabled) ||
          (feature === IntelligenceFeature.DEEM && !isDeemEnabled) ||
          (feature === IntelligenceFeature.RISK_ANALYTICS && !isDashboardUserRiskEnabled) ||
          false
        );
      });
    },
  );

  /**
   * enabledFeatures
   * @static
   * @type {MemoizedSelector<CoreAppState, IntelligenceFeature[]>}
   * @memberof OrgSelectors
   */
  public static enabledFeatures: MemoizedSelector<CoreAppState, IntelligenceFeature[]> = createSelector(
    OrgSelectors.disabledFeatures,
    (disabledFeatures: IntelligenceFeature[]) => {
      return INTELLIGENCE_FEATURES.filter((feature: IntelligenceFeature) => !disabledFeatures.includes(feature));
    },
  );

  /**
   * areServicesAutoOptedIn
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof OrgSelectors
   */
  public static areServicesAutoOptedIn: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.allOptedInGemProvisionedIntegrations,
    OrgSelectors.isServiceManualOptInPerformed,
    (allOptedInGemProvisionedIntegrations: IntegrationDetails[], isServiceManualOptInPerformed: boolean) => {
      return !!allOptedInGemProvisionedIntegrations.length && !isServiceManualOptInPerformed;
    },
  );

  /**
   * showServicesOptInModal
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof OrgSelectors
   */
  public static showServicesOptInModal: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isIntegrationAutoOptinForCSPEnabled,
    OrgSelectors.isServiceOptInAcknowledged,
    (isIntegrationAutoOptinEnabled: boolean, isServiceOptInAcknowledged: boolean) => {
      return isIntegrationAutoOptinEnabled && !isServiceOptInAcknowledged;
    },
  );

  /**
   * showSilentOptInModal
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof OrgSelectors
   */
  public static showSilentOptInModal: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isSilentOptInAllowed,
    UserPreferenceSelectors.availableGemProvisionedIntegrations,
    (isSilentOptInAllowed: boolean, availableGemProvisionedIntegrations: IntegrationDetails[]) => {
      const isHorizonOnlyAvailableGemIntegration: boolean =
        availableGemProvisionedIntegrations?.length === 1 && availableGemProvisionedIntegrations?.[0].integration === Integration.HORIZON;
      return isSilentOptInAllowed && !isHorizonOnlyAvailableGemIntegration;
    },
  );
}

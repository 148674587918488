/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { EventForwarderTemplate } from './event-forwarder-template.model';

/**
 * EventForwardersTemplatesResponse
 * @export
 * @class EventForwardersTemplatesResponse
 */
@Serializable
export class EventForwardersTemplatesResponse {
  @JsonProperty({ name: 'data', cls: EventForwarderTemplate })
  public data: EventForwarderTemplate[] = [];
}

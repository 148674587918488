/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { NavigationMenu, NavigationMenuGroup } from '@ws1c/intelligence-models';
import { NAVIGATION_MENU_CONFIG } from './navigation-menu.config';

/**
 * NavigationMenuState
 * @export
 * @interface NavigationMenuState
 */
export interface NavigationMenuState {
  activatedNavMenuItemIndex: number;
  isSecondaryNavigationMenuCollapsed: boolean;
  loadDefaultRoute: boolean;
  navigationMenu: NavigationMenu;
  expandedNavGroups: NavigationMenuGroup[];
  saveToUserPreference: boolean;
}

/**
 * initialNavigationMenuState
 * @export
 * @type {NavigationMenuState}
 */
export const initialNavigationMenuState: NavigationMenuState = {
  activatedNavMenuItemIndex: 0,
  isSecondaryNavigationMenuCollapsed: undefined,
  loadDefaultRoute: false,
  navigationMenu: NAVIGATION_MENU_CONFIG,
  expandedNavGroups: undefined,
  saveToUserPreference: undefined,
};

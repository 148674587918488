/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';
import { orderBy } from 'lodash-es';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { SolutionPlatform, SolutionSettingMetricDetails, WidgetDetailDefinition } from '@ws1c/intelligence-models';
import { DeemCommonState } from './deem-common.store';

/**
 * DeemCommonSelectors
 *
 * @export
 * @class DeemCommonSelectors
 */
export class DeemCommonSelectors {
  public static selectDeemCommonState = (state: CoreAppState) => state.deemCommonState;

  /**
   * getDeemWidgetDetail
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDetailDefinition>}
   * @returns {WidgetDetailDefinition}
   * @memberof DeemCommonSelectors
   */
  public static getDeemWidgetDetail: MemoizedSelector<CoreAppState, WidgetDetailDefinition> = createSelector(
    DeemCommonSelectors.selectDeemCommonState,
    (state: DeemCommonState) => state.deemWidgetDetail,
  );

  /**
   * getDeemWidgetReturnPath
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @returns {string}
   * @memberof DeemCommonSelectors
   */
  public static getDeemWidgetReturnPath: MemoizedSelector<CoreAppState, string> = createSelector(
    DeemCommonSelectors.selectDeemCommonState,
    (state: DeemCommonState) => state.deemWidgetReturnPath,
  );

  /**
   * selectActiveSettingMetrics
   * @static
   * @type {MemoizedSelector<CoreAppState, SolutionSettingMetricDetails[]>}
   * @memberof DeemCommonSelectors
   */
  public static selectActiveSettingMetrics: MemoizedSelector<CoreAppState, SolutionSettingMetricDetails[]> = createSelector(
    DeemCommonSelectors.selectDeemCommonState,
    (state: DeemCommonState) => orderBy(state.activeSettingMetrics, ['metricKey.platform']),
  );

  /**
   * selectUpdatingActiveSettingMetrics
   * @static
   * @type {MemoizedSelector<CoreAppState, SolutionSettingMetricDetails[]>}
   * @memberof DeemCommonSelectors
   */
  public static selectUpdatingActiveSettingMetrics: MemoizedSelector<CoreAppState, SolutionSettingMetricDetails[]> = createSelector(
    DeemCommonSelectors.selectDeemCommonState,
    (state: DeemCommonState) => orderBy(state.updatingActiveSettingMetrics, ['metricKey.platform']),
  );

  /**
   * isSetByPlatformAllowed
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DeemCommonSelectors
   */
  public static isSetByPlatformAllowed: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DeemCommonSelectors.selectActiveSettingMetrics,
    (activeSettingMetrics: SolutionSettingMetricDetails[]) => {
      return (
        activeSettingMetrics.length > 1 &&
        !!activeSettingMetrics.find(
          (item: SolutionSettingMetricDetails) => item.metricKey.platform?.toLowerCase() === SolutionPlatform.MACOS,
        )
      );
    },
  );

  /**
   * isDeemThresholdEditModalOpen
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DeemCommonSelectors
   */
  public static isDeemThresholdEditModalOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DeemCommonSelectors.selectDeemCommonState,
    (state: DeemCommonState) => state.isDeemThresholdEditModalOpen,
  );
}

/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { FuzzySubgroupConfig, GenericObject, PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { GridsterItem } from 'angular-gridster2';
import {
  each,
  findLast,
  flatten,
  get,
  intersection,
  isEmpty,
  isEqual,
  keyBy,
  last,
  mapValues,
  orderBy,
  size,
  sortBy,
  uniq,
  uniqBy,
  uniqWith,
} from 'lodash-es';

import { BookmarksSelectors } from '@ws1c/intelligence-core/store/bookmarks';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import {
  getFilterColumns,
  getGroupByColumns,
  integrationMetaHelpers,
  IntegrationMetaSelectors,
} from '@ws1c/intelligence-core/store/integration-meta';
import { FeatureSelectors } from '@ws1c/intelligence-core/store/rbac';
import { UserPreferenceFeatureControlsSelectors, UserPreferenceUIPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import {
  AggregationAttributesResponse,
  AggregationWidget,
  AggregationWidgetChartType,
  Attribute,
  BucketSelection,
  Category,
  CategoryIndex,
  ChartDrilldownEvent,
  Column,
  COLUMN_NAMES,
  ColumnIndex,
  ComparisonQueryResponse,
  CompositeTrendDefinition,
  CompositeTrendDrilldownApplier,
  CustomReportPreviewSearchResponse,
  Dashboard,
  DashboardActionPermission,
  DashboardConfig,
  DashboardSearchRequest,
  DashboardSearchResponse,
  DashboardTagFilter,
  DashboardType,
  DashboardView,
  DrilldownTrail,
  Entity,
  FocusedSeries,
  getUniqueId,
  IncrementalLoadingResponseTrendStatus,
  IncrementalLoadingWidgetDetails,
  Integration,
  IntegrationCategories,
  LayoutType,
  LOAD_STATE,
  LocalDataGridSettings,
  LodashSortOrder,
  OperatingSystem,
  PrecomputedAggregation,
  PreviewReportContentRequest,
  QueryBuilder,
  RuleGroup,
  SearchTagFilterField,
  SearchTagFilterType,
  StandardWidgetSubtype,
  TransferOwnershipActionOrigin,
  Trend,
  TrendComposer,
  TrendDateRange,
  TrendDefinition,
  TrendDefinitionDrilldownApplier,
  TrendDefinitionIndex,
  TrendMode,
  UIPreference,
  UserAdminAccount,
  WidgetColorSchema,
  WidgetDataRequest,
  WidgetDetailCompositeTableTab,
  WidgetDetailDefinition,
  WidgetDetailPage,
  WidgetDetailPageSkinType,
  WidgetPreference,
  WidgetRangeFilter,
  WidgetTemplate,
  WidgetUpdateDataRequest,
  WidgetWizardDialogMode,
} from '@ws1c/intelligence-models';
import * as dashboardSelectorHelpers from './dashboard-selector-helpers';
import * as dashboardSelectorHelpersV2 from './dashboard-selector-helpers-v2';
import { ActiveWidgetDialogMode, DashboardActionOrigin, DashboardDialogMode, DashboardState } from './dashboard.state';

/**
 * helpers - used for test stubbing
 * @type {Object}
 */
export const commonHelpers = {
  ...integrationMetaHelpers,
};

export const helpers = {
  ...dashboardSelectorHelpers,
  ...dashboardSelectorHelpersV2,
};

/**
 * DashboardSelectors
 * @exports
 * @class DashboardSelectors
 */
export class DashboardSelectors {
  /**
   * dashboardState
   * @type {MemoizedSelector<CoreAppState, DashboardState>}
   * @memberOf DashboardSelectors
   */
  public static dashboardState: MemoizedSelector<CoreAppState, DashboardState> = createFeatureSelector<DashboardState>('dashboardState');

  /**
   * getRootUrl
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf DashboardSelectors
   */
  public static getRootUrl: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.rootUrl,
  );

  /**
   * getIncidentId
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf DashboardSelectors
   */
  public static getIncidentId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.incident.incidentId,
  );

  /**
   * isIncidentSubPage
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf DashboardSelectors
   */
  public static isIncidentSubPage: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getIncidentId,
    (incidentId: string) => !!incidentId,
  );

  /**
   * getIncidentDashboard
   * @type {MemoizedSelector<CoreAppState, Dashboard>}
   * @memberOf DashboardSelectors
   */
  public static getIncidentDashboard: MemoizedSelector<CoreAppState, Dashboard> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.incident.customDashboard,
  );

  /**
   * getWidgetDetailTrend
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTrend: MemoizedSelector<CoreAppState, Trend> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailTrend,
  );

  /**
   * getWidgetDetailOverlayTrend
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailOverlayTrend: MemoizedSelector<CoreAppState, Trend> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailOverlayTrend,
  );

  /**
   * getCurrentDashboard
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getCurrentDashboard: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.currentDashboard,
  );

  /**
   * getWidgetIdToDashboardIdMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, string>>}
   * @memberof DashboardSelectors
   */
  public static getWidgetIdToDashboardIdMap: MemoizedSelector<CoreAppState, Record<string, string>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetIdToDashboardIdMap,
  );

  /**
   * isWidgetDetailV2
   * @type {MemoizedSelector<CoreAppState, Dashboard>}
   * @memberOf DashboardSelectors
   */
  public static isWidgetDetailV2: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailDefinition?.isV2 || state.widgetDetailTrend?.trendDefinition.isV2,
  );

  /**
   * isWidgetDetailCrossCategory
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf DashboardSelectors
   */
  public static isWidgetDetailCrossCategory: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailDefinition?.isCrossCategory,
  );

  /**
   * isWidgetDetailCrossCategoryOrWidgetJoinEnabled
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf DashboardSelectors
   */
  public static isWidgetDetailCrossCategoryOrWidgetJoinEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    UserPreferenceFeatureControlsSelectors.isWidgetJoinEnabled,
    (state: DashboardState, isWidgetJoinEnabled: boolean) => state.widgetDetailDefinition?.isCrossCategory || isWidgetJoinEnabled,
  );

  /**
   * isCrossCategoryActive
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isCrossCategoryActive: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.isCrossCategoryActive,
  );

  /**
   * getActiveCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getActiveCategoryId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.activeCategoryId,
  );

  /**
   * getDefaultColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, string[]>>}
   * @memberof DashboardSelectors
   */
  public static getDefaultColumns: MemoizedSelector<CoreAppState, Record<string, string[]>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.defaultColumns,
  );

  /**
   * getDefaultNonJoinColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, string[]>>}
   * @memberof DashboardSelectors
   */
  public static getDefaultNonJoinColumns: MemoizedSelector<CoreAppState, Record<string, string[]>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.defaultNonJoinColumns,
  );

  /**
   * getVisibleWidgetSubtypes
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getVisibleWidgetSubtypes: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => helpers.getVisibleWidgetSubtypes(state.visibleWidgetCountBySubtypes),
  );

  /**
   * getPendingDashboardRequests
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof DashboardSelectors
   */
  public static getPendingDashboardRequests: MemoizedSelector<CoreAppState, number> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state?.pendingStandardDashboardRequests + state?.pendingPreviewDashboardRequests,
  );

  /**
   * getPendingPreviewWidgetSubtypes
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getPendingPreviewWidgetSubtypes: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.pendingPreviewWidgetSubtypes,
  );

  /**
   * isLoadingWidgetPreviews
   * @static
   * @param {string[]} widgetSubtypes
   * @type {(widgetSubtypes: StandardWidgetSubtype[]) => MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isLoadingWidgetPreviews: (widgetSubtypes?: string[]) => MemoizedSelector<CoreAppState, boolean> = (
    widgetSubtypes: StandardWidgetSubtype[] = [],
  ): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(DashboardSelectors.getPendingPreviewWidgetSubtypes, (pendingSubtypes: string[]) => {
      return intersection(pendingSubtypes, widgetSubtypes).length > 0;
    });

  /**
   * getDefaultTrendsWithNoData
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Trend>>}
   * @memberof DashboardSelectors
   */
  public static getDefaultTrendsWithNoData: MemoizedSelector<CoreAppState, Record<string, Trend>> = createSelector(
    DashboardSelectors.getVisibleWidgetSubtypes,
    DashboardSelectors.getPendingDashboardRequests,
    (visibleWidgetSubtypes: string[], pendingRequests: number) => {
      if (!pendingRequests) {
        const defaultTrends = {};
        (visibleWidgetSubtypes || []).forEach((key: string) => {
          defaultTrends[key] = new Trend();
        });
        return defaultTrends;
      }
      return {};
    },
  );

  /**
   * getRawStandardDashboardData
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, Trend>>}
   * @memberof DashboardSelectors
   */
  public static getRawStandardDashboardData: MemoizedSelector<CoreAppState, Map<string, Trend>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.standardDashboardData,
  );

  /**
   * getStandardDashboardData
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, Trend>>}
   * @memberof DashboardSelectors
   */
  public static getStandardDashboardData: MemoizedSelector<CoreAppState, Map<string, Trend>> = createSelector(
    DashboardSelectors.getRawStandardDashboardData,
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    UserPreferenceFeatureControlsSelectors.isAutomationEnabled,
    DashboardSelectors.getDefaultTrendsWithNoData,
    (
      dashboardData: Map<string, Trend>,
      categoryMap: CategoryIndex,
      isAutomationEnabled: boolean,
      defaultedTrends: Record<string, Trend>,
    ) => {
      const dashboardDataMap = new Map<string, Trend>();
      dashboardData?.forEach((trend: Trend, key: string) => {
        const updatedTrend = helpers.injectAutomationFlagsIntoTrend(trend, categoryMap, isAutomationEnabled);
        dashboardDataMap.set(key, updatedTrend);
      });
      each(defaultedTrends, (trend: Trend, key: string) => {
        if (!dashboardDataMap.get(key)) {
          dashboardDataMap.set(key, trend);
        }
      });
      return dashboardDataMap;
    },
  );

  /**
   * getStandardDashboardByType
   * @static
   * @param {StandardWidgetSubtype} widgetSubtype
   * @type {(widgetSubtype: StandardWidgetSubtype) => MemoizedSelector<CoreAppState, Trend>}
   * @memberof DashboardSelectors
   */
  public static getStandardDashboardByType = (widgetSubtype: StandardWidgetSubtype): MemoizedSelector<CoreAppState, Trend> => {
    return createSelector(DashboardSelectors.getStandardDashboardData, (standardDashboardData: Map<string, Trend>) =>
      standardDashboardData.get(StandardWidgetSubtype[widgetSubtype]),
    );
  };

  /**
   * getPotentialImpactByWidgetSubtype
   * @static
   * @param {StandardWidgetSubtype} widgetSubtype
   * @type {(widgetSubtype: StandardWidgetSubtype) => MemoizedSelector<CoreAppState, ComparisonQueryResponse>}
   * @memberof DashboardSelectors
   */
  public static getPotentialImpactByWidgetSubtype = (
    widgetSubtype: StandardWidgetSubtype,
  ): MemoizedSelector<CoreAppState, ComparisonQueryResponse> => {
    return createSelector(DashboardSelectors.getStandardDashboardData, (standardDashboardData: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(standardDashboardData, null, widgetSubtype);
    });
  };

  /**
   * getWidgetDrilldownTrail
   * @static
   * @type {MemoizedSelector<CoreAppState, DrilldownTrail[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDrilldownTrail: MemoizedSelector<CoreAppState, DrilldownTrail[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDrilldownTrails,
  );

  /**
   * getVisibleWidgetDrilldownTrail
   * @static
   * @type {MemoizedSelector<CoreAppState, DrilldownTrail[]>}
   * @memberof DashboardSelectors
   */
  public static getVisibleWidgetDrilldownTrail: MemoizedSelector<CoreAppState, DrilldownTrail[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDrilldownTrails.filter((item) => !item.hidden),
  );

  /**
   * getLastVisibleWidgetDrilldownTrail
   * @static
   * @type {MemoizedSelector<CoreAppState, DrilldownTrail>}
   * @memberof DashboardSelectors
   */
  public static getLastVisibleWidgetDrilldownTrail: MemoizedSelector<CoreAppState, DrilldownTrail> = createSelector(
    DashboardSelectors.getVisibleWidgetDrilldownTrail,
    last,
  );

  /**
   * getDashboardLastUpdated
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof DashboardSelectors
   */
  public static getDashboardLastUpdated: MemoizedSelector<CoreAppState, number> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardLastUpdated,
  );

  /**
   * getDashboardDialogMode
   * @static
   * @type {MemoizedSelector<CoreAppState, DashboardDialogMode>}
   * @memberof DashboardSelectors
   */
  public static getDashboardDialogMode: MemoizedSelector<CoreAppState, DashboardDialogMode> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardDialogMode,
  );

  /**
   * isDashboardTransferOwnershipDialogOpen
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isDashboardTransferOwnershipDialogOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getDashboardDialogMode,
    (dashboardDialogMode: DashboardDialogMode) => dashboardDialogMode === DashboardDialogMode.TRANSFER_OWNERSHIP,
  );

  /**
   * getDashboardsDialogModel
   * @static
   * @type {MemoizedSelector<CoreAppState, Dashboard[]>}
   * @memberof DashboardSelectors
   */
  public static getDashboardsDialogModel: MemoizedSelector<CoreAppState, Dashboard[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardsDialogModel,
  );

  /**
   * getSelectedDashboards
   * @static
   * @type {MemoizedSelector<CoreAppState, Dashboard[]>}
   * @memberof DashboardSelectors
   */
  public static getSelectedDashboards: MemoizedSelector<CoreAppState, Dashboard[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.selectedDashboards,
  );

  /**
   * getMyDashboardViewFilterRuleGroup
   * @static
   * @type {MemoizedSelector<CoreAppState, RuleGroup>}
   * @memberof DashboardSelectors
   */
  public static getMyDashboardViewFilterRuleGroup: MemoizedSelector<CoreAppState, RuleGroup> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.myDashboardViewFilterRuleGroup,
  );

  /**
   * getMyDashboardViewTrendDateRange
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDateRange>}
   * @memberof DashboardSelectors
   */
  public static getMyDashboardViewTrendDateRange: MemoizedSelector<CoreAppState, TrendDateRange> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.myDashboardViewTrendDateRange,
  );

  /**
   * getDashboardActionPermission
   * @static
   * @type {MemoizedSelector<CoreAppState, DashboardActionPermission>}
   * @memberof DashboardSelectors
   */
  public static getDashboardActionPermission: MemoizedSelector<CoreAppState, DashboardActionPermission> = createSelector(
    FeatureSelectors.hasDashboardPerm,
    FeatureSelectors.hasDashboardManagePerm,
    (hasDashboardWritePerm: boolean, hasDashboardManagePerm: boolean) => {
      return new DashboardActionPermission({
        hasDashboardWritePerm,
        hasDashboardManagePerm,
      });
    },
  );

  /**
   * getColumnsByCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Column[]>>}
   * @memberof DashboardSelectors
   */
  public static getColumnsByCategoryId: MemoizedSelector<CoreAppState, Record<string, Column[]>> =
    IntegrationMetaSelectors.columnsByCategoryId(DashboardSelectors.isWidgetDetailV2);

  /*
   * getSelectedDashboardOwnersDAPIds
   * @static
   * @type {MemoizedSelector<CoreAppState, Set<string>>}
   * @memberof DashboardSelectors
   */
  public static getSelectedDashboardOwnersDAPIds: MemoizedSelector<CoreAppState, Set<string>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dapInfo.selectedDashboardOwnersDAPIds,
  );

  /**
   * getTransferToOwnerDAPId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getTransferToOwnerDAPId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dapInfo.transferToOwnerDAPId,
  );

  /**
   * isTransferOwnershipDAPIdDifferent
   * check whether the new owner DAP Id is different from selected dashaboards owners
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isTransferOwnershipDAPIdDifferent: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getSelectedDashboardOwnersDAPIds,
    DashboardSelectors.getTransferToOwnerDAPId,
    (selectedDashboardOwnersDAPIds: Set<string>, transferToOwnerDAPId: string) => {
      // if we get multiple DAP Ids for selected dashboards means atleast one will be different from new owner
      if (selectedDashboardOwnersDAPIds?.size > 1) {
        return true;
      }
      // if we get single DAP Id for selected dashboards, return false if it's same as new owner otherwise true
      if (selectedDashboardOwnersDAPIds?.size === 1) {
        return !selectedDashboardOwnersDAPIds.has(transferToOwnerDAPId);
      }
      // if no DAP Id for selected dashboards, return true if new owner has DAP Id otherwise false
      return !!transferToOwnerDAPId;
    },
  );

  /**
   * getRiskIndicatorDetailsByTimestampByGuid
   * @static
   * @param {string} guid
   * @param {number} timestamp
   * @returns {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof DashboardSelectors
   */
  public static getRiskIndicatorDetailsByTimestampByGuid = (
    guid: string,
    timestamp: number,
  ): MemoizedSelector<CoreAppState, GenericObject> =>
    createSelector(
      DashboardSelectors.dashboardState,
      (state: DashboardState) => state.riskIndicatorDetailsByTimestampByGuid?.[guid]?.[timestamp],
    );

  /**
   * getSummaryCounterBySubtype
   * @static
   * @param {StandardWidgetSubtype} subtype
   * @returns {MemoizedSelector<CoreAppState, ComparisonQueryResponse>}
   * @memberof DashboardSelectors
   */
  public static getSummaryCounterBySubtype = (subtype: StandardWidgetSubtype): MemoizedSelector<CoreAppState, ComparisonQueryResponse> =>
    createSelector(DashboardSelectors.getStandardDashboardData, (standardDashboardData: Map<string, Trend>) =>
      helpers.getSummaryCounter(standardDashboardData, subtype),
    );

  /**
   * getDevicesProfileColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getDevicesProfileColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getVisibleColumnsSortedByName,
    (allColumns: Column[]) => commonHelpers.filterColumnsByAttributeNames(allColumns, [COLUMN_NAMES.byName.device_location_group_name]),
  );

  /**
   * isLoadingWidgetPreviewDataState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isLoadingWidgetPreviewDataState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.isLoadingWidgetPreviewData,
  );

  /**
   * isDashboardLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isDashboardLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.isDashboardLoading,
  );

  /**
   * getWidgetDataPreviewState
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDataRequest>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDataPreviewState: MemoizedSelector<CoreAppState, WidgetDataRequest> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDataPreview,
  );

  /**
   * overlayDataPreview
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDataRequest>}
   * @memberof DashboardSelectors
   */
  public static overlayDataPreview: MemoizedSelector<CoreAppState, WidgetDataRequest> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.overlayDataPreview,
  );

  /**
   * isValidOverlayPreviewState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isValidOverlayPreviewState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.overlayDataPreview,
    (widgetDataPreview: WidgetDataRequest) => widgetDataPreview?.success,
  );

  /**
   * getWidgetWizardDialogModeState
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetWizardDialogMode>}
   * @memberof DashboardSelectors
   */
  public static getWidgetWizardDialogModeState: MemoizedSelector<CoreAppState, WidgetWizardDialogMode> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetWizardDialogMode,
  );

  /**
   * getWidgetUpdateDataState
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetUpdateDataRequest>}
   * @memberof DashboardSelectors
   */
  public static getWidgetUpdateDataState: MemoizedSelector<CoreAppState, WidgetUpdateDataRequest> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetUpdateData,
  );

  /**
   * getActiveWidgetDialogModeState
   * @static
   * @type {MemoizedSelector<CoreAppState, ActiveWidgetDialogMode>}
   * @memberof DashboardSelectors
   */
  public static getActiveWidgetDialogModeState: MemoizedSelector<CoreAppState, ActiveWidgetDialogMode> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.activeWidgetDialogMode,
  );

  /**
   * getWidgetDataPreviewTrend
   * @static
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDataPreviewTrend: MemoizedSelector<CoreAppState, Trend> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDataPreview && state.widgetDataPreview.result,
  );

  /**
   * getOverlayDataPreviewTrend
   * @static
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof DashboardSelectors
   */
  public static getOverlayDataPreviewTrend: MemoizedSelector<CoreAppState, Trend> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.overlayDataPreview?.result,
  );

  /**
   * getWebErrorsByWidgetId
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, WebError>>}
   * @memberof DashboardSelectors
   */
  public static getWebErrorsByWidgetId: MemoizedSelector<CoreAppState, Record<string, WebError>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.webErrorsByWidgetId,
  );

  /**
   * getDashboardSearchRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, DashboardSearchRequest>}
   * @memberof DashboardSelectors
   */
  public static getDashboardSearchRequest: MemoizedSelector<CoreAppState, DashboardSearchRequest> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardSearchRequest,
  );

  /**
   * isDashboardSearchInProgress
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isDashboardSearchInProgress: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.isDashboardSearchInProgress,
  );

  /**
   * getDashboardSearchResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, DashboardSearchResponse>}
   * @memberof DashboardSelectors
   */
  public static getDashboardSearchResponse: MemoizedSelector<CoreAppState, DashboardSearchResponse> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardSearchResponse,
  );

  /**
   * getDashboardListFilter
   * @static
   * @type {MemoizedSelector<CoreAppState, DashboardTagFilter>}
   * @memberof DashboardSelectors
   */
  public static getDashboardListFilter: MemoizedSelector<CoreAppState, DashboardTagFilter> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardListFilter,
  );

  /**
   * getSearchDashboardType
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getSearchDashboardType: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardSearchType,
  );

  /**
   * isDashboardListloading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isDashboardListloading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.isDashboardListloading,
  );

  /**
   * isDashboardImportInProgress
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isDashboardImportInProgress: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.isDashboardImportInProgress,
  );

  /**
   * getGlobalSearchResults
   * @static
   * @type {MemoizedSelector<CoreAppState, Dashboard[]>}
   * @memberof DashboardSelectors
   */
  public static getGlobalSearchResults: MemoizedSelector<CoreAppState, Dashboard[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.globalSearchResults,
  );

  /**
   * getDashboardSearchResults
   * @static
   * @type {MemoizedSelector<CoreAppState, Dashboard[]>}
   * @memberof DashboardSelectors
   */
  public static getDashboardSearchResults: MemoizedSelector<CoreAppState, Dashboard[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => {
      return state.globalSearchResults.filter((dashboard) => {
        return dashboard && dashboard.id !== state.activeDashboard?.id;
      });
    },
  );

  /**
   * getOperatingSystemsForDashboard
   * @static
   * @type {MemoizedSelector<CoreAppState, OperatingSystem[]>}
   * @memberof DashboardSelectors
   */
  public static getOperatingSystemsForDashboard: MemoizedSelector<CoreAppState, OperatingSystem[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.operatingSystems,
  );

  /**
   * dashboardScrollToBottom
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static dashboardScrollToBottom: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.scrollToBottom,
  );

  /**
   * getDashboardAddedOrDuplicated
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static getDashboardAddedOrDuplicated: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.addedOrDuplicated,
  );

  /**
   * getDashboardLayoutEditConfirmOpen
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static getDashboardLayoutEditConfirmOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.layoutEditConfirm,
  );

  /**
   * getDrilldownEventsById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, ChartDrilldownEvent[]>>}
   * @memberof DashboardSelectors
   */
  public static getDrilldownEventsById: MemoizedSelector<CoreAppState, Record<string, ChartDrilldownEvent[]>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.drilldownEventsById,
  );

  /**
   * getWidgetDetailDrilldownEvents
   * @static
   * @type {MemoizedSelector<CoreAppState, ChartDrilldownEvent[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailDrilldownEvents: MemoizedSelector<CoreAppState, ChartDrilldownEvent[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailDrilldownEvents || [],
  );

  /**
   * getWidgetDetailCompositeTrendData
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, Trend>>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailCompositeTrendData: MemoizedSelector<CoreAppState, Map<string, Trend>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailCompositeTrendData,
  );

  /**
   * getWidgetDetailPageSkinType
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDetailPageSkinType>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailPageSkinType: MemoizedSelector<CoreAppState, WidgetDetailPageSkinType> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailPageSkinType,
  );

  /**
   * getWidgetDetailPageUrl
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailPageUrl: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailUrl,
  );

  /**
   * getWidgetDetailWidgetId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailWidgetId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailWidgetId,
  );

  /**
   * getWidgetDetailPagedRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, PagedRequest>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailPagedRequest: MemoizedSelector<CoreAppState, PagedRequest> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => helpers.getDefaultedPagedRequestMedium(state.widgetDetailPagedRequest),
  );

  /**
   * getOverlayTablePagedRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, PagedRequest>}
   * @memberof DashboardSelectors
   */
  public static getOverlayTablePagedRequest: MemoizedSelector<CoreAppState, PagedRequest> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => helpers.getDefaultedPagedRequestMedium(state.overlayTablePagedRequest),
  );

  /**
   * getWidgetDetailPagedRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, PagedRequest>}
   * @memberof DashboardSelectors
   */
  public static getWidgetPreviewPagedRequest: MemoizedSelector<CoreAppState, PagedRequest> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => helpers.getDefaultedPagedRequest(state.widgetPreviewPagedRequest),
  );

  /**
   * getWidgetDetailTablePageSize
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTablePageSize: MemoizedSelector<CoreAppState, number> = createSelector(
    DashboardSelectors.getWidgetDetailPagedRequest,
    (pagedRequest: PagedRequest) => pagedRequest.size,
  );

  /**
   * getWidgetDetailLoadingTrend
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailLoadingTrend: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailLoadingTrend,
  );

  /**
   * getWidgetDetailTrendError
   * @static
   * @type {MemoizedSelector<CoreAppState, WebError>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTrendError: MemoizedSelector<CoreAppState, WebError> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailTrendError,
  );

  /**
   * getWidgetPreviewRefreshedAt
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof DashboardSelectors
   */
  public static getWidgetPreviewRefreshedAt: MemoizedSelector<CoreAppState, number> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.previewRefreshedAt,
  );

  /**
   * getWidgetDetailTableSelectedColumnNames
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableSelectedColumnNames: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailTableSelectedColumnNames,
  );

  /**
   * getWidgetDetailSortOns
   * @static
   * @type {MemoizedSelector<CoreAppState, SortOn[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailSortOns: MemoizedSelector<CoreAppState, SortOn[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetTableSortOns,
  );

  /**
   * overlayTableSortOns
   * @static
   * @type {MemoizedSelector<CoreAppState, SortOn[]>}
   * @memberof DashboardSelectors
   */
  public static overlayTableSortOns: MemoizedSelector<CoreAppState, SortOn[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.overlayTableSortOns,
  );

  /**
   * getWidgetPreviewTableSortOns
   * @static
   * @type {MemoizedSelector<CoreAppState, SortOn[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetPreviewTableSortOns: MemoizedSelector<CoreAppState, SortOn[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetPreviewTableSortOns,
  );

  /**
   * getWidgetDetailLoadingTablePreview
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailLoadingTablePreview: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailLoadingTablePreview,
  );

  /**
   * isOverlayDetailTableLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isOverlayDetailTableLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.isOverlayDetailTableLoading,
  );

  /**
   * getWidgetDetailTablePreviewError
   * @static
   * @type {MemoizedSelector<CoreAppState, WebError>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTablePreviewError: MemoizedSelector<CoreAppState, WebError> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailTablePreviewError,
  );

  /**
   * getActiveDashboardState
   * @static
   * @type {MemoizedSelector<CoreAppState, DashboardView>}
   * @memberof DashboardSelectors
   */
  public static getActiveDashboardState: MemoizedSelector<CoreAppState, DashboardView> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.activeDashboard,
  );

  /**
   * getDashboardActionOrigin
   * @static
   * @type {MemoizedSelector<CoreAppState, DashboardActionOrigin>}
   * @memberof DashboardSelectors
   */
  public static getDashboardActionOrigin: MemoizedSelector<CoreAppState, DashboardActionOrigin> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.actionOrigin,
  );

  /**
   * getDashboardDialogModel
   * @static
   * @type {MemoizedSelector<CoreAppState, Dashboard | DashboardView>}
   * @memberof DashboardSelectors
   */
  public static getDashboardDialogModel: MemoizedSelector<CoreAppState, Dashboard | DashboardView> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardDialogModel,
  );

  /**
   * getWidgetList
   * @static
   * @type {MemoizedSelector<CoreAppState, GridsterItem[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetList: MemoizedSelector<CoreAppState, GridsterItem[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetList,
  );

  /**
   * getWidgetListFilterState
   * @static
   * @type {MemoizedSelector<CoreAppState, any>}
   * @memberof DashboardSelectors
   */
  public static getWidgetListFilterState: MemoizedSelector<CoreAppState, any> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetListFilter,
  );

  /**
   * getLastRefreshTimeState
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof DashboardSelectors
   */
  public static getLastRefreshTimeState: MemoizedSelector<CoreAppState, number> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.lastRefreshTime,
  );

  /**
   * getWidgetTemplatesState
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetTemplate[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetTemplatesState: MemoizedSelector<CoreAppState, WidgetTemplate[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetTemplates,
  );

  /**
   * getWidgetTemplatesCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Category>}
   * @memberof DashboardSelectors
   */
  public static getWidgetTemplatesCategory: MemoizedSelector<CoreAppState, Category> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetTemplatesCategory,
  );

  /**
   * getActiveWidgetTemplateState
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetTemplate>}
   * @memberof DashboardSelectors
   */
  public static getActiveWidgetTemplateState: MemoizedSelector<CoreAppState, WidgetTemplate> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.activeWidgetTemplate,
  );

  /**
   * getActiveWidget
   * @static
   * @type {MemoizedSelector<CoreAppState, AggregationWidget>}
   * @memberof DashboardSelectors
   */
  public static getActiveWidget: MemoizedSelector<CoreAppState, AggregationWidget> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.activeWidget,
  );

  /**
   * getActiveWidgetTheme
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof DashboardSelectors
   */
  public static getActiveWidgetTheme: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.activeWidgetTheme,
  );

  /**
   * getStandardDashboardDataState
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, Trend>>}
   * @memberof DashboardSelectors
   */
  public static getStandardDashboardDataState: MemoizedSelector<CoreAppState, Map<string, Trend>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.standardDashboardData,
  );

  /**
   * getLocalDataGridSettingsById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, LocalDataGridSettings>}
   * @memberof DashboardSelectors
   */
  public static getLocalDataGridSettingsById: MemoizedSelector<CoreAppState, Record<string, LocalDataGridSettings>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state?.localDataGridSettingsById || {},
  );

  /**
   * getDashboardRecipients
   * @static
   * @type {MemoizedSelector<CoreAppState, UserAdminAccount[]>}
   * @memberof DashboardSelectors
   */
  public static getDashboardRecipients: MemoizedSelector<CoreAppState, UserAdminAccount[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardRecipients,
  );

  /**
   * isDashboardRecipientsLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isDashboardRecipientsLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.isDashboardRecipientsLoading,
  );

  /**
   * getTableTrendDefinitionsById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, TrendDefinition>>}
   * @memberof DashboardSelectors
   */
  public static getTableTrendDefinitionsById: MemoizedSelector<CoreAppState, Record<string, TrendDefinition>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.tableTrendDefinitionsById,
  );

  /**
   * getTableTrendsById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Trend>>}
   * @memberof DashboardSelectors
   */
  public static getTableTrendsById: MemoizedSelector<CoreAppState, Record<string, Trend>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.tableTrendsById,
  );

  /**
   * getNoDashboardsMessage
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getNoDashboardsMessage: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getDashboardListFilter,
    (dashboardListFilters: DashboardTagFilter) => {
      return Object.keys(dashboardListFilters).find((key: string) => key.includes(SearchTagFilterField.IS_SHARED_DASHBOARD))
        ? 'DASHBOARD_ACTIONS.NO_SHARED_DASHBOARD'
        : 'DASHBOARD_ACTIONS.CREATE_YOUR_FIRST_DASHBOARD';
    },
  );

  /**
   * hasFullAccessToDashboard
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static hasFullAccessToDashboard: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getActiveDashboardState,
    (dashboard: DashboardView) => {
      if (!dashboard) {
        return true;
      }
      return dashboard.isOwnerOrHasFullAccess;
    },
  );

  /**
   * getFocusedSeriesById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, FocusedSeries>>}
   * @memberof DashboardSelectors
   */
  public static getFocusedSeriesById: MemoizedSelector<CoreAppState, Record<string, FocusedSeries>> = createSelector(
    DashboardSelectors.getDrilldownEventsById,
    (drilldownEventsById: Record<string, ChartDrilldownEvent[]>) => {
      const tdda = new TrendDefinitionDrilldownApplier();
      return mapValues(drilldownEventsById, (drilldownEvents: ChartDrilldownEvent[]) => tdda.getDrilldownFocusedSeries(drilldownEvents));
    },
  );

  /**
   * isValidWidgetState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isValidActiveWidgetTrendDefinition: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getActiveWidget,
    IntegrationMetaSelectors.getAggregationColumns,
    (widget: AggregationWidget, aggregationColumns: ColumnIndex) => {
      return helpers.isValidTrendDefinition(widget?.trend?.trendDefinition, aggregationColumns, widget?.chartType);
    },
  );

  /**
   * isValidWidgetState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isValidWidgetState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetUpdateDataState,
    DashboardSelectors.getWidgetDataPreviewState,
    (widgetUpdateData: WidgetUpdateDataRequest, widgetDataPreview: WidgetDataRequest) => {
      return helpers.isValidWigetData(widgetUpdateData, widgetDataPreview);
    },
  );

  /**
   * isValidPreviewState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isValidPreviewState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetDataPreviewState,
    (widgetDataPreview: WidgetDataRequest) => widgetDataPreview?.success,
  );

  /**
   * isValidActiveWidget
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isValidActiveWidget: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getActiveWidget,
    DashboardSelectors.getWidgetDataPreviewState,
    (widget: AggregationWidget, widgetDataPreview: WidgetDataRequest) => helpers.isValidWiget(widget, widgetDataPreview),
  );

  /**
   * getWidgetsById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, AggregationWidget>>}
   * @memberof DashboardSelectors
   */
  public static getWidgetsById: MemoizedSelector<CoreAppState, Record<string, AggregationWidget>> = createSelector(
    DashboardSelectors.getWidgetList,
    (gridsterList: GridsterItem[]) => {
      const gridsterItemsByWidgetId = keyBy(gridsterList, (gridsterItem: GridsterItem) => gridsterItem.widget.id);
      return mapValues(gridsterItemsByWidgetId, (gridsterItem: GridsterItem) => gridsterItem.widget);
    },
  );

  /**
   * getChartTypesById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, AggregationWidgetChartType>>}
   * @memberof DashboardSelectors
   */
  public static getChartTypesById: MemoizedSelector<CoreAppState, Record<string, AggregationWidgetChartType>> = createSelector(
    DashboardSelectors.getWidgetsById,
    DashboardSelectors.getDrilldownEventsById,
    (widgetsById: Record<string, AggregationWidget>, drilldownEventsById: Record<string, ChartDrilldownEvent[]>) =>
      helpers.getChartTypesById(widgetsById, drilldownEventsById),
  );

  /**
   * getChartTypesById
   * @static
   * @type {MemoizedSelector<CoreAppState, DashboardSearchResponse>}
   * @memberof DashboardSelectors
   */
  public static getBookmarkedDashboardSearchResponse: MemoizedSelector<CoreAppState, DashboardSearchResponse> = createSelector(
    BookmarksSelectors.isBookmarkFilterEnabled,
    BookmarksSelectors.getActiveBookmarkIdsSet,
    DashboardSelectors.getDashboardSearchResponse,
    (isBookmarkFilterEnabled: boolean, bookmarks: Set<string>, response: DashboardSearchResponse) => {
      let bookmarkedDashboardSearchResponse = response;
      if (isBookmarkFilterEnabled) {
        const results = get(response, 'results', []).filter((item: Dashboard) => bookmarks && bookmarks.has(item.id));
        bookmarkedDashboardSearchResponse = {
          ...response,
          total: results.length,
          results,
        };
      }
      return bookmarkedDashboardSearchResponse;
    },
  );

  /**
   * isFrontlineDashboardsImported
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isFrontlineDashboardsImported: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getBookmarkedDashboardSearchResponse,
    (response: DashboardSearchResponse) =>
      !!response?.results?.some((dashboard: Dashboard) => dashboard.dashboardType === DashboardType.FRONTLINE),
  );

  /**
   * getDashboardBookmarkFilterEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static getDashboardBookmarkFilterEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getDashboardListFilter,
    UserPreferenceFeatureControlsSelectors.isUserBookmarksEnabled,
    (listFilter: GenericObject, isUserBookmarksEnabled: boolean) => {
      return Boolean(isUserBookmarksEnabled && listFilter[SearchTagFilterType.BOOKMARKED]);
    },
  );

  /**
   * isLoadingOrHasDashboards
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isLoadingOrHasDashboards: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.isDashboardListloading,
    DashboardSelectors.getDashboardSearchRequest,
    DashboardSelectors.getDashboardSearchResponse,
    (isLoading: boolean, request: DashboardSearchRequest, response: DashboardSearchResponse) => {
      return isLoading || !isEmpty(request.searchTerm) || response.results.length > 0;
    },
  );

  /**
   * getWidgetDetailDrilldownEventIndex
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailDrilldownEventIndex: MemoizedSelector<CoreAppState, number> = createSelector(
    DashboardSelectors.dashboardState,
    DashboardSelectors.getWidgetDetailDrilldownEvents,
    (state: DashboardState, chartDrilldownEvents: ChartDrilldownEvent[]) => {
      const indexInState = state.widgetDetailDrilldownEventIndex;
      return indexInState === undefined ? chartDrilldownEvents.length : indexInState;
    },
  );

  /**
   * getActiveWidgetDrilldownEvents
   * @static
   * @type {MemoizedSelector<CoreAppState, ChartDrilldownEvent[]>}
   * @memberof DashboardSelectors
   */
  public static getActiveWidgetDrilldownEvents: MemoizedSelector<CoreAppState, ChartDrilldownEvent[]> = createSelector(
    DashboardSelectors.getWidgetDetailDrilldownEvents,
    DashboardSelectors.getWidgetDetailDrilldownEventIndex,
    (widgetDetailDrilldownEvents: ChartDrilldownEvent[], widgetDetailDrilldownEventIndex: number) => {
      return widgetDetailDrilldownEvents.slice(0, widgetDetailDrilldownEventIndex + 1);
    },
  );

  /**
   * getWidgetDetailFocusedSeries
   * @static
   * @type {MemoizedSelector<CoreAppState, FocusedSeries>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailFocusedSeries: MemoizedSelector<CoreAppState, FocusedSeries> = createSelector(
    DashboardSelectors.getActiveWidgetDrilldownEvents,
    (activeDrilldownEvents: ChartDrilldownEvent[]) => {
      const tdda = new TrendDefinitionDrilldownApplier();
      return tdda.getDrilldownFocusedSeries(activeDrilldownEvents);
    },
  );

  /**
   * Compose method to get trend data by widget id
   * @param {string} widgetId
   * @returns {MemoizedSelector<CoreAppState, WidgetDataRequest>}
   */
  public static getWidgetDataByIdState = (widgetId: string): MemoizedSelector<CoreAppState, WidgetDataRequest> =>
    createSelector(DashboardSelectors.dashboardState, (state: DashboardState) => state.widgetData[widgetId]);

  /**
   * getWidgetDataById
   * @param {string} widgetId
   * @returns {MemoizedSelector<CoreAppState, WidgetDataRequest>}
   */
  public static getWidgetById = (widgetId: string): MemoizedSelector<CoreAppState, AggregationWidget> =>
    createSelector(DashboardSelectors.dashboardState, (state: DashboardState) => state.widgetsById[widgetId]);

  /**
   * getInvertModeById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, boolean>>}
   * @memberof DashboardSelectors
   */
  public static getInvertModeById: MemoizedSelector<CoreAppState, Record<string, boolean>> = createSelector(
    DashboardSelectors.getWidgetsById,
    DashboardSelectors.getDrilldownEventsById,
    (widgetsById: Record<string, AggregationWidget>, drilldownEventsById: Record<string, ChartDrilldownEvent[]>) => {
      return helpers.getInvertModeById(widgetsById, drilldownEventsById);
    },
  );

  /**
   * getMyDashboardViewColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getMyDashboardViewColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getColumnsByCategoryId,
    IntegrationMetaSelectors.getCrossCategoryColumns,
    UserPreferenceFeatureControlsSelectors.isWidgetJoinEnabled,
    (
      columnsByCategory: Record<string, Column[]>,
      crossCategoryColumnsByCategory: Record<string, Column[]>,
      isWidgetJoinEnabled: boolean,
    ) => {
      // Using AIRWATCH_DEVICE columns to show the dashboard filter.
      const deviceCategoryId = getUniqueId(Integration.AIRWATCH, Entity.DEVICE);
      const columns = isWidgetJoinEnabled ? crossCategoryColumnsByCategory[deviceCategoryId] : columnsByCategory[deviceCategoryId];
      return commonHelpers.filterColumnsByNames(columns, [
        COLUMN_NAMES.byName.device_location_group_name,
        COLUMN_NAMES.byName.device_location_group_lineage_list,
      ]);
    },
  );

  /**
   * getAppsDashboardColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getAppsDashboardColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getVisibleColumnsSortedByName,
    (allColumns: Column[]) =>
      commonHelpers.filterColumnsByAttributeNames(allColumns, [
        COLUMN_NAMES.byName._device_platform,
        COLUMN_NAMES.byName.device_os_version,
        COLUMN_NAMES.byName.device_model,
      ]),
  );

  /**
   * getDashboardCategoriesState - Retrieve available dashboard categories
   * @static
   * @type {MemoizedSelector<CoreAppState, Category[]>}
   * @memberof DashboardSelectors
   */
  public static getDashboardCategoriesState: MemoizedSelector<CoreAppState, Category[]> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    helpers.getAvailableDashboardEntities,
  );

  /**
   * getDashboardCategoriesByIntegration - Retrieve available dashboard categories, grouped by integration
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberof DashboardSelectors
   */
  public static getDashboardCategoriesByIntegration: MemoizedSelector<CoreAppState, IntegrationCategories[]> = createSelector(
    DashboardSelectors.getDashboardCategoriesState,
    commonHelpers.groupCategoriesByIntegration,
  );

  /**
   * getDashboardSubgroupConfig - Retrieve FuzzySubgroupConfig for dashboard
   * @static
   * @type {MemoizedSelector<CoreAppState, FuzzySubgroupConfig>}
   * @memberof DashboardSelectors
   */
  public static getDashboardSubgroupConfig: MemoizedSelector<CoreAppState, FuzzySubgroupConfig> = createSelector(
    DashboardSelectors.getDashboardCategoriesByIntegration,
    commonHelpers.getSubgroupConfig,
  );

  /**
   * getAppsSubgroupConfig
   * @static
   * @type {MemoizedSelector<CoreAppState, FuzzySubgroupConfig>}
   * @memberof DashboardSelectors
   */
  public static getAppsSubgroupConfig: MemoizedSelector<CoreAppState, FuzzySubgroupConfig> = createSelector(
    DashboardSelectors.getDashboardCategoriesByIntegration,
    UserPreferenceFeatureControlsSelectors.isEmployeeExperienceAppDetailsDashboardEnabled,
    commonHelpers.getAppSearchSubgroupConfig,
  );

  /**
   * getWidgetColumnsByCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Column[]>>}
   * @memberof DashboardSelectors
   */
  public static getWidgetColumnsByCategoryId: MemoizedSelector<CoreAppState, Record<string, Column[]>> = createSelector(
    IntegrationMetaSelectors.getColumnsByCategoryId,
    IntegrationMetaSelectors.getCrossCategoryColumns,
    UserPreferenceFeatureControlsSelectors.isWidgetJoinEnabled,
    (
      columnByCategoryId: Record<string, Column[]>,
      crossCategoryColumnsByCategoryId: Record<string, Column[]>,
      isWidgetJoinEnabled: boolean,
    ) => {
      return isWidgetJoinEnabled ? crossCategoryColumnsByCategoryId : columnByCategoryId;
    },
  );

  /**
   * getWidgetListState
   * @static
   * @type {MemoizedSelector<CoreAppState, GridsterItem[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetListState: MemoizedSelector<CoreAppState, GridsterItem[]> = createSelector(
    DashboardSelectors.getWidgetList,
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    UserPreferenceFeatureControlsSelectors.isAutomationEnabled,
    DashboardSelectors.getWidgetColumnsByCategoryId,
    DashboardSelectors.getMyDashboardViewFilterRuleGroup,
    DashboardSelectors.getMyDashboardViewTrendDateRange,
    (
      widgetList: GridsterItem[],
      categoryMap: CategoryIndex,
      isAutomationEnabled: boolean,
      columnsByCategoryId: Record<string, Column[]>,
      ruleGroup: RuleGroup,
      trendDateRange: TrendDateRange,
    ) => {
      return sortBy(
        widgetList.map((item: GridsterItem) =>
          helpers.getWidgetItem(item, categoryMap, isAutomationEnabled, columnsByCategoryId, ruleGroup, trendDateRange),
        ),
        ['y', 'x'],
      );
    },
  );

  /**
   * getActiveWidgetState
   * @static
   * @type {MemoizedSelector<CoreAppState, AggregationWidget>}
   * @memberof DashboardSelectors
   */
  public static getActiveWidgetState: MemoizedSelector<CoreAppState, AggregationWidget> = createSelector(
    DashboardSelectors.getActiveWidget,
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    UserPreferenceFeatureControlsSelectors.isAutomationEnabled,
    (widget: AggregationWidget, categoryMap: CategoryIndex, isAutomationEnabled: boolean) => {
      if (!widget) {
        return;
      }
      if (!widget.trend) {
        return widget;
      }
      return Object.assign(new AggregationWidget(), widget, {
        trend: helpers.injectAutomationFlagsIntoTrend(widget.trend, categoryMap, isAutomationEnabled),
      });
    },
  );

  /**
   * getHistoricalChartOptionVisible
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static getHistoricalChartOptionVisible: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isHistoricalChartsSupported,
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    DashboardSelectors.getActiveWidgetState,
    DashboardSelectors.getActiveWidgetTemplateState,
    helpers.getHistoricalChartOptionVisible,
  );

  /**
   * getActiveWidgetCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Category>}
   * @memberof DashboardSelectors
   */
  public static getActiveWidgetCategory: MemoizedSelector<CoreAppState, Category> = createSelector(
    DashboardSelectors.getDashboardCategoriesState,
    DashboardSelectors.getActiveWidgetState,
    (categories: Category[], widget: AggregationWidget) => {
      if (!widget || !widget.trend || !widget.trend.trendDefinition) {
        return;
      }
      return categories.find(
        (integrationCategories: Category) => integrationCategories.categoryId === widget.trend.trendDefinition.categoryId,
      );
    },
  );

  /**
   * getSupportedCounters
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getSupportedCounters: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.getActiveWidgetCategory,
    IntegrationMetaSelectors.getActiveCategory,
    (activeWidgetCategory: Category, activeCategory: Category) => {
      return activeCategory?.supportedCounters || activeWidgetCategory?.supportedCounters;
    },
  );

  /**
   * getWidgetCategoryLabel
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetCategoryLabel: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getActiveWidgetCategory,
    IntegrationMetaSelectors.getActiveCategory,
    (activeWidgetCategory: Category, activeCategory: Category) => {
      return commonHelpers.getCategoryLabelWithIntegration(activeCategory || activeWidgetCategory);
    },
  );

  /**
   * getWidgetDetailDefinitionFromParams
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDetailDefinition>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailDefinitionFromParams: MemoizedSelector<CoreAppState, WidgetDetailDefinition> = createSelector(
    DashboardSelectors.dashboardState,
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    UserPreferenceFeatureControlsSelectors.isAutomationEnabled,
    (state: DashboardState, categoryMap: CategoryIndex, isAutomationEnabled: boolean) => {
      if (!state.widgetDetailDefinition) {
        return;
      }
      if (state.widgetDetailDefinition.compositeTrendDefinition) {
        return state.widgetDetailDefinition;
      }
      return new WidgetDetailDefinition({
        ...state.widgetDetailDefinition,
        trendDefinition: helpers.injectAutomationFlagsIntoTrendDefinition(
          state.widgetDetailDefinition.trendDefinition,
          categoryMap,
          isAutomationEnabled,
        ),
      });
    },
  );

  /**
   * getWidgetDetailPage
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDetailPage>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailPage: MemoizedSelector<CoreAppState, WidgetDetailPage> = createSelector(
    DashboardSelectors.getWidgetDetailWidgetId,
    DashboardSelectors.getWidgetDetailDefinitionFromParams,
    DashboardSelectors.getActiveWidgetDrilldownEvents,
    DashboardSelectors.getWidgetDetailPageSkinType,
    DashboardSelectors.getWidgetDetailDrilldownEventIndex,
    (
      widgetId: string,
      widgetDetailDefinition: WidgetDetailDefinition,
      activeDrilldownEvents: ChartDrilldownEvent[],
      widgetDetailPageSkinType: WidgetDetailPageSkinType,
      widgetDetailDrilldownEventIndex: number,
    ) => {
      const allDrilldownEvents = [...(activeDrilldownEvents || [])];

      // When the user tries to drill down to sub-second times, the drilldown events will be the same time period
      // This removes all duplicate drilldown events
      const uniqueDrilldownEvents = uniqWith(allDrilldownEvents, isEqual);
      return {
        widgetId,
        widgetDetailDefinition,
        drilldownEvents: uniqueDrilldownEvents,
        drilldownIndex: widgetDetailDrilldownEventIndex,
        skinType: widgetDetailPageSkinType,
      } as WidgetDetailPage;
    },
  );

  /**
   * getWidgetDetailCurrentInvertMode
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailCurrentInvertMode: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getActiveWidgetDrilldownEvents,
    (activeDrilldownEvents: ChartDrilldownEvent[]) => {
      return findLast(activeDrilldownEvents)?.isInvertMode;
    },
  );

  /**
   * isEditRangeDialogOpen
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isEditRangeDialogOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetDetailIsEditRangeDialogOpen,
  );

  /**
   * getEditRangeWidgetId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getEditRangeWidgetId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetEditRangeWidgetId,
  );

  /**
   * getWidgetEditRangeGroupByLabel
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetEditRangeGroupByLabel: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetEditRangeGroupByLabel,
  );

  /**
   * getWidgetAttributePreferencesById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, WidgetPreference[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetAttributePreferencesById: MemoizedSelector<CoreAppState, Record<string, WidgetPreference[]>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.widgetAttributePreferencesById,
  );

  /**
   * getWidgetRangeFiltersById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, WidgetRangeFilter>}
   * @memberof DashboardSelectors
   */
  public static getWidgetRangeFiltersById: MemoizedSelector<CoreAppState, Record<string, WidgetRangeFilter>> = createSelector(
    DashboardSelectors.getWidgetAttributePreferencesById,
    UserPreferenceFeatureControlsSelectors.isWidgetEditRangeEnabled,
    (widgetAttributePreferencesById: Record<string, WidgetPreference[]>, isEditRangeEnabled: boolean) => {
      if (!isEditRangeEnabled) {
        return;
      }
      const widgetRangeFiltersById: Record<string, WidgetRangeFilter> = {};
      Object.keys(widgetAttributePreferencesById).forEach((key: string) => {
        widgetRangeFiltersById[key] = widgetAttributePreferencesById[key]?.[0].rangeFilter;
      });
      return widgetRangeFiltersById;
    },
  );

  /**
   * getWidgetColorSchemasById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, WidgetColorSchema[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetColorSchemasById: MemoizedSelector<CoreAppState, Record<string, WidgetColorSchema[]>> = createSelector(
    DashboardSelectors.getWidgetAttributePreferencesById,
    UserPreferenceFeatureControlsSelectors.isWidgetEditThemeEnabled,
    (widgetAttributePreferencesById: Record<string, WidgetPreference[]>, isWidgetEditThemeEnabled: boolean) => {
      if (!isWidgetEditThemeEnabled) {
        return;
      }
      const widgetColorSchemasById: Record<string, WidgetColorSchema[]> = {};
      Object.keys(widgetAttributePreferencesById).forEach((key: string) => {
        widgetColorSchemasById[key] = widgetAttributePreferencesById[key]?.[0].colorSchemas;
      });
      return widgetColorSchemasById;
    },
  );

  /**
   * getWidgetAttributePreferencesForEditRange
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetPreference}
   * @memberof DashboardSelectors
   */
  public static getWidgetAttributePreferencesForEditRange: MemoizedSelector<CoreAppState, WidgetPreference> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) =>
      state.widgetEditRangeWidgetId &&
      state.widgetAttributePreferencesById &&
      state.widgetAttributePreferencesById[state.widgetEditRangeWidgetId]?.[0],
  );

  /**
   * getWidgetDetailDefinitionWithLoadedWidget
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDetailDefinition>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailDefinitionWithLoadedWidget: MemoizedSelector<CoreAppState, WidgetDetailDefinition> = createSelector(
    DashboardSelectors.getWidgetDetailDefinitionFromParams,
    DashboardSelectors.getActiveWidgetState,
    helpers.overrideWidgetDetailDefinition,
  );

  /**
   * getWidgetDetailDefinition
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDetailDefinition>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailDefinition: MemoizedSelector<CoreAppState, WidgetDetailDefinition> = createSelector(
    DashboardSelectors.getWidgetDetailWidgetId,
    DashboardSelectors.getWidgetDetailDefinitionWithLoadedWidget,
    DashboardSelectors.getWidgetDetailDefinitionFromParams,
    (
      widgetId: string,
      widgetDetailDefinitionWithLoadedWidget: WidgetDetailDefinition,
      widgetDetailDefinitionFromParams: WidgetDetailDefinition,
    ) => {
      return widgetId ? widgetDetailDefinitionWithLoadedWidget : widgetDetailDefinitionFromParams;
    },
  );

  /**
   * getWidgetRangeFiltersForEditRange
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetColorSchema[]}
   * @memberof DashboardSelectors
   */
  public static getWidgetColorSchemasForWidgetDetailDefinition: MemoizedSelector<CoreAppState, WidgetColorSchema[]> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    DashboardSelectors.getWidgetColorSchemasById,
    (widgetDetailDefinition: WidgetDetailDefinition, widgetColorSchemasById: Record<string, WidgetColorSchema[]>) =>
      widgetDetailDefinition && widgetColorSchemasById && widgetColorSchemasById[widgetDetailDefinition?.widgetId],
  );

  /**
   * isWidgetDetailCompositeState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isWidgetDetailCompositeState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    (widgetDetailDefinition: WidgetDetailDefinition) => Boolean(widgetDetailDefinition?.compositeTrendDefinition),
  );

  /**
   * isWidgetDetailMultipleTrendDefinitionState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isWidgetDetailMultipleTrendDefinitionState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    (widgetDetailDefinition: WidgetDetailDefinition) => {
      const trendDefinitions = widgetDetailDefinition?.compositeTrendDefinition?.trendDefinitions;
      return Boolean(trendDefinitions) && Object.keys(trendDefinitions).length > 1;
    },
  );

  /**
   * getWidgetDetailCustomColors
   * @static
   * @type {MemoizedSelector<CoreAppState, any[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailCustomColors: MemoizedSelector<CoreAppState, any[]> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    (widgetDetailDefinition: WidgetDetailDefinition) => widgetDetailDefinition && widgetDetailDefinition.customColors,
  );

  /**
   * getNoBucketingColor
   * drilldownEvents come with bucketColors
   * This gets the active bucketColor
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getNoBucketingColor: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    DashboardSelectors.getActiveWidgetDrilldownEvents,
    (widgetDetailDefinition: WidgetDetailDefinition, drilldownEvents: ChartDrilldownEvent[]) => {
      const selectedBuckets = flatten(
        drilldownEvents.map((drilldownEvent: ChartDrilldownEvent) => {
          return drilldownEvent.selectedBuckets || [];
        }),
      );
      const bucketColors = selectedBuckets.map((bucket: BucketSelection) => bucket.bucketColor).filter(Boolean);
      return last(bucketColors) || widgetDetailDefinition?.noBucketingColor;
    },
  );

  /**
   * getWidgetDetailInitialTrendDefinition
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDefinition>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailInitialTrendDefinition: MemoizedSelector<CoreAppState, TrendDefinition> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    DashboardSelectors.getActiveWidgetDrilldownEvents,
    (widgetDetailDefinition: WidgetDetailDefinition, activeWidgetDetailDrilldownEvents: ChartDrilldownEvent[]) => {
      return helpers.applyDrilldownEventsToTrendDefinition(widgetDetailDefinition?.trendDefinition, activeWidgetDetailDrilldownEvents);
    },
  );

  /**
   * getWidgetDetailOverlayInitialTrendDefinition
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDefinition>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailOverlayInitialTrendDefinition: MemoizedSelector<CoreAppState, TrendDefinition> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    DashboardSelectors.getActiveWidgetDrilldownEvents,
    (widgetDetailDefinition: WidgetDetailDefinition, activeWidgetDetailDrilldownEvents: ChartDrilldownEvent[]) => {
      const overlayDrilldownEvents = activeWidgetDetailDrilldownEvents.map((chartDrilldownEvent: ChartDrilldownEvent) => {
        return { ...chartDrilldownEvent, setFilters: [], setRuleGroup: undefined };
      });
      const drilldownTrendDefinition = widgetDetailDefinition?.overlayTrendDefinition
        ? helpers.applyDrilldownEventsToTrendDefinition(widgetDetailDefinition?.overlayTrendDefinition, overlayDrilldownEvents)
        : null;
      if (drilldownTrendDefinition) {
        drilldownTrendDefinition.filter = widgetDetailDefinition?.overlayTrendDefinition.filter;
      }
      return drilldownTrendDefinition;
    },
  );

  /**
   * getWidgetDetailTrendDefinition
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDefinition>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTrendDefinition: MemoizedSelector<CoreAppState, TrendDefinition> = createSelector(
    DashboardSelectors.getWidgetDetailInitialTrendDefinition,
    helpers.getWidgetDetailTrendDefinition,
  );

  /**
   * getWidgetDetailOverlayTrendDefinition
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDefinition>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailOverlayTrendDefinition: MemoizedSelector<CoreAppState, TrendDefinition> = createSelector(
    DashboardSelectors.getWidgetDetailOverlayInitialTrendDefinition,
    (overlayTrendDefinition: TrendDefinition) =>
      overlayTrendDefinition ? helpers.getWidgetDetailTrendDefinition(overlayTrendDefinition) : null,
  );

  /**
   * isUserFlowsWidgetDetailState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isUserFlowsWidgetDetailState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.isWidgetDetailCompositeState,
    DashboardSelectors.getWidgetDetailTrendDefinition,
    (isComposite: boolean, trendDefinition: TrendDefinition) => {
      if (isComposite) {
        return false;
      }
      return trendDefinition && trendDefinition.categoryId === getUniqueId(Integration.APTELIGENT, Entity.USER_FLOW);
    },
  );

  /**
   * isCveWidgetDetailState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isCveWidgetDetailState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetDetailTrendDefinition,
    (trendDefinition: TrendDefinition) => {
      return trendDefinition && trendDefinition.categoryId === getUniqueId(Integration.AIRWATCH, Entity.WINDOWSPATCH);
    },
  );

  /**
   * getWidgetDetailInitialCompositeTrendDefinition
   * @static
   * @type {MemoizedSelector<CoreAppState, CompositeTrendDefinition>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailInitialCompositeTrendDefinition: MemoizedSelector<CoreAppState, CompositeTrendDefinition> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    DashboardSelectors.getActiveWidgetDrilldownEvents,
    (widgetDetailDefinition: WidgetDetailDefinition, activeWidgetDetailDrilldownEvents: ChartDrilldownEvent[]) => {
      if (!widgetDetailDefinition?.compositeTrendDefinition) {
        return;
      }
      const compositeTrendDrilldownApplier = new CompositeTrendDrilldownApplier();
      return compositeTrendDrilldownApplier.applyDrilldownEvents(
        widgetDetailDefinition.compositeTrendDefinition,
        activeWidgetDetailDrilldownEvents,
      );
    },
  );

  /**
   * getWidgetDetailCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailCategoryId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getWidgetDetailTrendDefinition,
    DashboardSelectors.getWidgetDetailInitialCompositeTrendDefinition,
    (trendDefinition: TrendDefinition, compositeTrendDefinition: CompositeTrendDefinition) => {
      if (Object.keys(compositeTrendDefinition?.trendDefinitions ?? {}).length === 1) {
        return Object.values(compositeTrendDefinition?.trendDefinitions)[0]?.categoryId;
      }
      return trendDefinition?.categoryId;
    },
  );

  /**
   * getVisibleActiveColumnsBasedOnJoin
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getVisibleActiveColumnsBasedOnJoin: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    IntegrationMetaSelectors.getVisibleActiveCrossCategoryColumnsSortedByName,
    IntegrationMetaSelectors.getVisibleColumnsSortedByName,
    (
      widgetDetailDefinition: WidgetDetailDefinition,
      visibleActiveCrossCategoryColumnsSortedByName: Column[],
      visibleColumnsSortedByName: Column[],
    ) => {
      return widgetDetailDefinition?.trendDefinition?.isCrossEntityOrIntegration
        ? visibleActiveCrossCategoryColumnsSortedByName
        : visibleColumnsSortedByName;
    },
  );

  /**
   * getWidgetDetailShowDateRange
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailShowDateRange: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    (widgetDetailDefinition: WidgetDetailDefinition) => {
      return (
        !widgetDetailDefinition?.hideDateRange && widgetDetailDefinition?.trendDefinition?.trendMode === TrendMode[TrendMode.HISTORICAL]
      );
    },
  );

  /**
   * getWidgetDetailTrendDateRange
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDateRange>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTrendDateRange: MemoizedSelector<CoreAppState, TrendDateRange> = createSelector(
    DashboardSelectors.getWidgetDetailTrendDefinition,
    DashboardSelectors.getMyDashboardViewTrendDateRange,
    (trendDefinition: TrendDefinition, trendDateRange: TrendDateRange) => trendDateRange || trendDefinition.dateRange,
  );

  /**
   * getWidgetDetailColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    DashboardSelectors.getWidgetDetailCategoryId,
    IntegrationMetaSelectors.getColumnsByCategoryId,
    IntegrationMetaSelectors.getCrossCategoryColumns,
    DashboardSelectors.isCrossCategoryActive,
    (
      categoryId: string,
      columnsByCategoryId: Record<string, Column[]>,
      fullyQualifiedColumnsByCategoryId: Record<string, Column[]>,
      isCrossCategory: boolean,
    ) => {
      return (isCrossCategory ? fullyQualifiedColumnsByCategoryId[categoryId] : columnsByCategoryId[categoryId]) ?? [];
    },
  );

  /**
   * getWidgetDetailColumnsByName
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Column>>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailColumnsByName: MemoizedSelector<CoreAppState, Record<string, Column>> = createSelector(
    DashboardSelectors.getWidgetDetailColumns,
    (columns: Column[]) => keyBy(columns, 'name'),
  );

  /**
   * getWidgetDetailCompositeTableTabs
   * @static
   * @type {MemoizedSelector<CoreAppState, WidgetDetailCompositeTableTab[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailCompositeTableTabs: MemoizedSelector<CoreAppState, WidgetDetailCompositeTableTab[]> = createSelector(
    DashboardSelectors.isWidgetDetailCompositeState,
    DashboardSelectors.getWidgetDetailInitialCompositeTrendDefinition,
    (isWidgetDetailComposite: boolean, compositeTrendDefinition: CompositeTrendDefinition) => {
      if (!isWidgetDetailComposite || !compositeTrendDefinition) {
        return [];
      }

      const compositeDependencies = new Set(
        TrendComposer.getAllTrendDefinitionDependencies([compositeTrendDefinition.mainName], compositeTrendDefinition.composeConfigs),
      );
      const compositeTableTabs = [];
      each(compositeTrendDefinition.trendDefinitions, (trendDefinition: TrendDefinition, widgetSubtype: string) => {
        if (compositeDependencies.has(widgetSubtype)) {
          compositeTableTabs.push({
            widgetSubtype,
            categoryId: trendDefinition.categoryId,
          } as WidgetDetailCompositeTableTab);
        }
      });

      return orderBy(
        compositeTableTabs,
        [
          (tab: WidgetDetailCompositeTableTab) => DashboardConfig.preferredWidgetTabOrderByCategoryId[tab.categoryId],
          (tab: WidgetDetailCompositeTableTab) => tab.widgetSubtype,
        ],
        [LodashSortOrder.ASC, LodashSortOrder.ASC],
      );
    },
  );

  /**
   * getWidgetDetailSelectedCompositeTableSubtype
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailSelectedCompositeTableSubtype: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.dashboardState,
    DashboardSelectors.getWidgetDetailCompositeTableTabs,
    (state: DashboardState, tableTabs: WidgetDetailCompositeTableTab[]) => {
      if (state.widgetDetailSelectedCompositeTableSubtype) {
        return state.widgetDetailSelectedCompositeTableSubtype;
      }
      return tableTabs && tableTabs[0] ? tableTabs[0].widgetSubtype : undefined;
    },
  );

  /**
   * isWidgetDetailTrendDefinitionThreatEntity
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isWidgetDetailTrendDefinitionThreatEntity: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.isWidgetDetailCompositeState,
    DashboardSelectors.getWidgetDetailTrendDefinition,
    (isWidgetDetailCompositeState: boolean, trendDefinition: TrendDefinition) => {
      if (isWidgetDetailCompositeState) {
        return false;
      }
      return trendDefinition.entity === Entity.THREAT;
    },
  );

  /**
   * getDefaultSortOnsForEntity
   * @static
   * @type {MemoizedSelector<CoreAppState, SortOn[]>}
   * @memberof DashboardSelectors
   */
  public static getDefaultSortOnsForEntity: MemoizedSelector<CoreAppState, SortOn[]> = createSelector(
    DashboardSelectors.isWidgetDetailCompositeState,
    DashboardSelectors.isWidgetDetailV2,
    DashboardSelectors.getWidgetDetailTrendDefinition,
    (isWidgetDetailCompositeState: boolean, isV2: boolean, trendDefinition: TrendDefinition) => {
      if (isWidgetDetailCompositeState) {
        return [];
      }
      const defaultSortOns: SortOn[] = isV2
        ? DashboardConfig.entityDefaultSortBy[trendDefinition?.categoryId]?.V2
        : DashboardConfig.entityDefaultSortBy[trendDefinition?.categoryId]?.V1;
      return defaultSortOns || [];
    },
  );

  /**
   * getWidgetDetailSortOnsWithDefaults
   * @static
   * @type {MemoizedSelector<CoreAppState, SortOn[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailSortOnsWithDefaults: MemoizedSelector<CoreAppState, SortOn[]> = createSelector(
    DashboardSelectors.getWidgetDetailSortOns,
    DashboardSelectors.getDefaultSortOnsForEntity,
    (selectedSortOns: SortOn[], defaultSortOnsForEntity: SortOn[]) => {
      if (selectedSortOns) {
        return uniqBy([...selectedSortOns, ...defaultSortOnsForEntity], 'by');
      }
      if (defaultSortOnsForEntity) {
        return defaultSortOnsForEntity;
      }
      return [];
    },
  );

  /**
   * getOverlayTableSortOnsWithDefaults
   * @static
   * @type {MemoizedSelector<CoreAppState, SortOn[]>}
   * @memberof DashboardSelectors
   */
  public static getOverlayTableSortOnsWithDefaults: MemoizedSelector<CoreAppState, SortOn[]> = createSelector(
    DashboardSelectors.overlayTableSortOns,
    DashboardSelectors.getWidgetDetailOverlayTrendDefinition,
    (selectedSortOns: SortOn[], trendDefinition: TrendDefinition) => {
      const defaultSortOns = DashboardConfig.entityDefaultSortBy[trendDefinition?.categoryId]?.V2;
      if (selectedSortOns) {
        return uniqBy([...selectedSortOns, ...defaultSortOns], 'by');
      }
      if (defaultSortOns) {
        return defaultSortOns;
      }

      return [];
    },
  );

  /**
   * isAutomateDisabledForDetailDefinition
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isAutomateDisabledForDetailDefinition: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    (detail: WidgetDetailDefinition) => !detail?.trendDefinition?.isCategoryAutomationSupported || !detail?.trendDefinition?.isV2,
  );

  /**
   * isSelectedWidgetDeprecated
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isSelectedWidgetDeprecated: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.getSupportedTrendModesByCategoryId,
    DashboardSelectors.getWidgetDetailDefinition,
    (supportedTrendModesByCategoryId: Record<string, string[]>, detail: WidgetDetailDefinition) => {
      const trendDefinition = detail?.trendDefinition;
      return !supportedTrendModesByCategoryId[trendDefinition?.categoryId]?.includes(trendDefinition?.trendMode);
    },
  );

  /**
   * getCompositeWidgetDetailTableTrendDefinition
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDefinition>}
   * @memberof DashboardSelectors
   */
  public static getCompositeWidgetDetailTableTrendDefinition: MemoizedSelector<CoreAppState, TrendDefinition> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    DashboardSelectors.getActiveWidgetDrilldownEvents,
    DashboardSelectors.getWidgetDetailSelectedCompositeTableSubtype,
    (widgetDetailDefinition: WidgetDetailDefinition, activeWidgetDetailDrilldownEvents: ChartDrilldownEvent[], subtype: string) => {
      if (!widgetDetailDefinition?.compositeTrendDefinition) {
        return;
      }
      const compositeTrendDrilldownApplier = new CompositeTrendDrilldownApplier();
      const compositeTrendDefinition = compositeTrendDrilldownApplier.applyDrilldownEvents(
        widgetDetailDefinition.compositeTrendDefinition,
        activeWidgetDetailDrilldownEvents,
        true,
      );
      if (!compositeTrendDefinition) {
        return;
      }
      return compositeTrendDefinition.trendDefinitions[subtype];
    },
  );

  /**
   * getCompositeWidgetDetailTableFilterString
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getCompositeWidgetDetailTableFilterString: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getCompositeWidgetDetailTableTrendDefinition,
    (trendDefinition: TrendDefinition) => trendDefinition?.filter,
  );

  /**
   * getResolvedWidgetDetailTrendDateRange$
   * After preview call, trend spans are resolved into start/end times
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDateRange>}
   * @memberof DashboardSelectors
   */
  public static getResolvedWidgetDetailTrendDateRange: MemoizedSelector<CoreAppState, TrendDateRange> = createSelector(
    DashboardSelectors.getWidgetDetailTrend,
    (trend: Trend) => trend?.trendDefinition?.dateRange,
  );

  /**
   * getWidgetDetailCompositeTrendDefinitionDependencies
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDefinitionIndex>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailCompositeTrendDefinitionDependencies: MemoizedSelector<CoreAppState, TrendDefinitionIndex> = createSelector(
    DashboardSelectors.getWidgetDetailInitialCompositeTrendDefinition,
    (compositeTrendDefinition: CompositeTrendDefinition) => {
      if (compositeTrendDefinition) {
        const trendDefinitionsIndex = {};
        TrendComposer.getAllTrendDefinitionDependencies(
          [compositeTrendDefinition.mainName],
          compositeTrendDefinition.composeConfigs,
        ).forEach((subtype: string) => {
          const trendDefinition = compositeTrendDefinition.trendDefinitions[subtype];
          if (trendDefinition.dateRange && trendDefinition.dateRange.endDateMillis !== undefined) {
            delete trendDefinition.dateRange.trendSpan;
          }
          trendDefinitionsIndex[subtype] = trendDefinition;
        });
        return trendDefinitionsIndex;
      }
    },
  );

  /**
   * getWidgetDetailTableTrend
   * @static
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableTrend: MemoizedSelector<CoreAppState, Trend> = createSelector(
    DashboardSelectors.isWidgetDetailCompositeState,
    DashboardSelectors.getWidgetDetailCompositeTrendData,
    DashboardSelectors.getWidgetDetailSelectedCompositeTableSubtype,
    DashboardSelectors.getWidgetDetailTrend,
    (isComposite: boolean, compositeTrendData: Map<string, Trend>, selectedSubtype: string, widgetDetailTrend: Trend) => {
      if (isComposite) {
        return compositeTrendData?.get(selectedSubtype);
      }
      return widgetDetailTrend;
    },
  );

  /**
   * isWidgetDetailRuleGroupEditable
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isWidgetDetailRuleGroupEditable: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetDetailTrend,
    (widgetDetailTrend: Trend) => !widgetDetailTrend?.trendDefinition?.isSnapshotPeriodical,
  );

  /**
   * getWidgetDetailTableFilterString
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableFilterString: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.isWidgetDetailCompositeState,
    DashboardSelectors.getCompositeWidgetDetailTableFilterString,
    DashboardSelectors.getWidgetDetailTableTrend,
    (isComposite: boolean, filterString: string, trend: Trend) => {
      if (isComposite) {
        return filterString;
      }
      return trend?.trendDefinition?.filter;
    },
  );

  /**
   * getWidgetDetailRuleGroup
   * Must get the trendDefinition from Trend returned by preview API call
   * Trend definition passed in through query param is incomplete
   * @static
   * @type {MemoizedSelector<CoreAppState, RuleGroup>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailRuleGroup: MemoizedSelector<CoreAppState, RuleGroup> = createSelector(
    DashboardSelectors.getWidgetDetailTableTrend,
    DashboardSelectors.getWidgetDetailFocusedSeries,
    (widgetDetailTableTrend: Trend, widgetDetailFocusedSeries: FocusedSeries) => {
      return dashboardSelectorHelpers.getWidgetDetailRuleGroup(widgetDetailTableTrend, widgetDetailFocusedSeries);
    },
  );

  /**
   * getWidgetDetailUserFlowName
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailUserFlowName: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.isUserFlowsWidgetDetailState,
    DashboardSelectors.getWidgetDetailRuleGroup,
    (isUserFlowsWidgetDetail: boolean, ruleGroup: RuleGroup) => {
      if (isUserFlowsWidgetDetail && ruleGroup) {
        return helpers.comprehensiveSpecifierAttributeValue(ruleGroup, DashboardConfig.LOOK_UP_ATTRIBUTE_FOR_WIDGET_SKIN.name);
      }
    },
  );

  /**
   * getWidgetDetailUserFlowAppId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailUserFlowAppId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.isUserFlowsWidgetDetailState,
    DashboardSelectors.getWidgetDetailRuleGroup,
    (isUserFlowsWidgetDetail: boolean, ruleGroup: RuleGroup) => {
      if (isUserFlowsWidgetDetail && ruleGroup) {
        return helpers.comprehensiveSpecifierAttributeValue(ruleGroup, DashboardConfig.LOOK_UP_ATTRIBUTE_FOR_WIDGET_SKIN.appId);
      }
    },
  );

  /**
   * getWidgetDetailUserFlowInfo
   * @static
   * @type {MemoizedSelector<CoreAppState, { name: string; appId: string }>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailUserFlowInfo: MemoizedSelector<CoreAppState, { name: string; appId: string }> = createSelector(
    DashboardSelectors.getWidgetDetailUserFlowName,
    DashboardSelectors.getWidgetDetailUserFlowAppId,
    (userFlowName: string, appId: string) => {
      return {
        name: userFlowName,
        appId,
      };
    },
  );

  /**
   * isWidgetDetailUserFlowInfoAvailableState
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isWidgetDetailUserFlowInfoAvailableState: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetDetailUserFlowInfo,
    (widgetDetailUserFlowInfo: { name: string; appId: string }) => {
      return Boolean(widgetDetailUserFlowInfo.name && widgetDetailUserFlowInfo.appId);
    },
  );

  /**
   * getWidgetDetailTableCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableCategoryId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.isWidgetDetailCompositeState,
    DashboardSelectors.getWidgetDetailTableTrend,
    DashboardSelectors.getWidgetDetailCategoryId,
    (isComposite: boolean, trend: Trend, categoryId: string) => {
      if (isComposite) {
        return trend?.trendDefinition?.categoryId;
      }
      return categoryId;
    },
  );

  /**
   * getWidgetPreviewTableCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetPreviewTableCategoryId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getWidgetDataPreviewTrend,
    (trend: Trend) => trend?.trendDefinition?.categoryId,
  );

  /**
   * getStandardWidgetColumnsMapping
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, string[]>>}
   * @memberof DashboardSelectors
   */
  public static getStandardWidgetColumnsMapping: MemoizedSelector<CoreAppState, Record<string, string[]>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.standardWidgetColumnsMapping,
  );

  /**
   * getStandardWidgetColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getStandardWidgetColumns: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.getStandardWidgetColumnsMapping,
    DashboardSelectors.getWidgetDetailDefinition,
    (standardWidgetColumns: Record<string, string[]>, widgetDetailDefinition: WidgetDetailDefinition) =>
      standardWidgetColumns?.[widgetDetailDefinition?.widgetId],
  );

  /**
   * getWidgetsPreviewTableDefaultColumnNames
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetsPreviewTableDefaultColumnNames: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.getWidgetPreviewTableCategoryId,
    DashboardSelectors.getActiveCategoryId,
    DashboardSelectors.isCrossCategoryActive,
    DashboardSelectors.getDefaultColumns,
    DashboardSelectors.getDefaultNonJoinColumns,
    DashboardSelectors.getWidgetDetailDefinition,
    (
      categoryId: string,
      activeCategoryId: string,
      isCrossCategory: boolean,
      defaultColumns: Record<string, string[]>,
      defaultNonJoinColumns: Record<string, string[]>,
      widgetDetails: WidgetDetailDefinition,
    ) => {
      if (!categoryId && !activeCategoryId) {
        return;
      }
      categoryId = categoryId || activeCategoryId;
      // This is a hack for Security Threat dashboard which still requires the attributes in v1 format.
      // For widget side panel widgetDetails will be undefined
      if (widgetDetails && !widgetDetails.isV2) {
        return helpers.getWidgetsDefaultTableColumnNames(categoryId);
      }
      return isCrossCategory ? defaultColumns[categoryId] : defaultNonJoinColumns[categoryId];
    },
  );

  /**
   * getWidgetBuilderTableHiddenColumnNames
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetBuilderTableHiddenColumnNames: MemoizedSelector<CoreAppState, string[]> = createSelector(
    IntegrationMetaSelectors.getActiveCategories,
    (categories: Category[]) => {
      if (!isEmpty(categories)) {
        return helpers.getWidgetsTableHiddenColumnNames(categories[0]?.categoryId);
      }
      return [];
    },
  );

  /**
   * getWidgetsPreviewTableColumnNames
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetsPreviewTableColumnNames: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.getActiveWidget,
    DashboardSelectors.getStandardWidgetColumns,
    DashboardSelectors.getWidgetBuilderTableHiddenColumnNames,
    DashboardSelectors.getWidgetsPreviewTableDefaultColumnNames,
    (activeWidget: AggregationWidget, standardDashboardWidgetColumns: string[], hiddenColumnNames: string[], defaultColumns: string[]) => {
      // For custom widgets check for saved column preference
      if (activeWidget?.widgetColumns?.length > 0) {
        return activeWidget?.widgetColumns;
      }
      // For standard widget check standardWidgetColumnsMapping
      // This mapping will have saved column preference for all widgets in the dashboard
      // If there are no saved preference, default columns will be retrieved
      if (standardDashboardWidgetColumns?.length > 0) {
        return uniq([...standardDashboardWidgetColumns, ...hiddenColumnNames]);
      }
      return defaultColumns;
    },
  );

  /**
   * getWidgetsOverlayPreviewTableColumnNames
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetsOverlayPreviewTableColumnNames: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.getActiveWidget,
    DashboardSelectors.getDefaultNonJoinColumns,
    (activeWidget: AggregationWidget, defaultColumns: Record<string, string[]>) => {
      if (activeWidget?.overlayWidgetColumns?.length > 0) {
        return activeWidget?.overlayWidgetColumns;
      }
      return defaultColumns[activeWidget?.overlayCategoryId];
    },
  );

  /**
   * getCustomWidgetColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getCustomWidgetColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getColumnsByName,
    DashboardSelectors.getWidgetsPreviewTableColumnNames,
    (columnsByName: ColumnIndex, columnNames: string[]) => {
      return columnNames?.map((columnName: string) => columnsByName[columnName]).filter((column: Column) => !!column);
    },
  );

  /**
   * getWidgetPreviewTableRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, PreviewReportContentRequest>}
   * @memberof DashboardSelectors
   */
  public static getWidgetPreviewTableRequest: MemoizedSelector<CoreAppState, PreviewReportContentRequest> = createSelector(
    DashboardSelectors.getWidgetsPreviewTableColumnNames,
    DashboardSelectors.getWidgetPreviewPagedRequest,
    DashboardSelectors.getWidgetDataPreviewTrend,
    DashboardSelectors.getWidgetPreviewTableSortOns,
    (
      widgetsPreviewTableColumnNames: string[],
      widgetPreviewPagedRequest: PagedRequest,
      widgetDataPreviewTrend: Trend,
      widgetPreviewTableSortOns: SortOn[],
    ) => {
      if (!widgetDataPreviewTrend || !widgetPreviewPagedRequest) {
        return;
      }
      return helpers.getWidgetPreviewTablePreviewRequest(
        widgetsPreviewTableColumnNames,
        widgetPreviewPagedRequest,
        widgetDataPreviewTrend,
        widgetPreviewTableSortOns,
      );
    },
  );

  /**
   * getWidgetPreviewTableRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, PreviewReportContentRequest>}
   * @memberof DashboardSelectors
   */
  public static getWidgetOverlayPreviewTableRequest: MemoizedSelector<CoreAppState, PreviewReportContentRequest> = createSelector(
    DashboardSelectors.getWidgetsOverlayPreviewTableColumnNames,
    DashboardSelectors.getOverlayTablePagedRequest,
    DashboardSelectors.getOverlayDataPreviewTrend,
    DashboardSelectors.getOverlayTableSortOnsWithDefaults,
    (
      widgetsPreviewTableColumnNames: string[],
      widgetPreviewPagedRequest: PagedRequest,
      widgetDataPreviewTrend: Trend,
      widgetPreviewTableSortOns: SortOn[],
    ) => {
      if (!widgetDataPreviewTrend || !widgetPreviewPagedRequest) {
        return;
      }
      return helpers.getWidgetPreviewTablePreviewRequest(
        widgetsPreviewTableColumnNames,
        widgetPreviewPagedRequest,
        widgetDataPreviewTrend,
        widgetPreviewTableSortOns,
      );
    },
  );

  /**
   * getWidgetDetailTableTrendDateRange
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDateRange>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableTrendDateRange: MemoizedSelector<CoreAppState, TrendDateRange> = createSelector(
    DashboardSelectors.getWidgetDetailTableTrend,
    (trend: Trend) => trend?.trendDefinition?.dateRange,
  );

  /**
   * getWidgetDetailTableColumnNames
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableColumnNames: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.getWidgetsPreviewTableColumnNames,
    DashboardSelectors.getWidgetDetailTableSelectedColumnNames,
    (defaultColumnNames: string[], selectedColumnNames: string[]) => {
      return selectedColumnNames || defaultColumnNames;
    },
  );

  /**
   * getPreviewWidgetColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getPreviewWidgetColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getColumnsByCategory,
    IntegrationMetaSelectors.getCrossCategoryColumns,
    IntegrationMetaSelectors.getActiveCategory,
    IntegrationMetaSelectors.isCrossCategory,
    DashboardSelectors.getWidgetDetailTableColumnNames,
    (
      columnsByCategory: Map<Category, Column[]>,
      crossCategoryColumnsByCategory: Record<string, Column[]>,
      category: Category,
      isCrossCategory: boolean,
      columnNames: string[],
    ) => {
      if (columnsByCategory.has(category) || crossCategoryColumnsByCategory[category?.categoryId]) {
        const columnIndex = isCrossCategory
          ? keyBy(crossCategoryColumnsByCategory[category.categoryId], 'attributeName')
          : keyBy(columnsByCategory.get(category), 'attributeName');
        return columnNames?.map((columnName: string) => columnIndex[columnName]).filter((column: Column) => !!column);
      }
      return [];
    },
  );

  /**
   * getDeemV2ReportPreviewColumns
   * @static
   * @param {StandardWidgetSubtype} widgetSubtype
   * @type {(widgetSubtype: StandardWidgetSubtype) => MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getDeemV2ReportPreviewColumns = (widgetSubtype: StandardWidgetSubtype): MemoizedSelector<CoreAppState, Column[]> => {
    return createSelector(
      IntegrationMetaSelectors.getColumnsByCategory,
      IntegrationMetaSelectors.getActiveCategory,
      DashboardSelectors.getStandardWidgetColumnsMapping,
      (columnsByCategory: Map<Category, Column[]>, category: Category, columnNamesMapping: Record<string, string[]>) => {
        if (!columnsByCategory.has(category)) {
          return [];
        }
        const columnNames: Set<string> = new Set(columnNamesMapping?.[widgetSubtype] ?? []);
        const columns: Column[] = columnsByCategory.get(category);
        return columns?.filter((column: Column) => {
          const attrName: string = column.attribute.name;
          const fullyQualifiedName: string = column.attribute.fullyQualifiedName;
          return columnNames?.has(fullyQualifiedName) || columnNames?.has(attrName);
        });
      },
    );
  };

  /**
   * getWidgetDetailTableColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    DashboardSelectors.getWidgetDetailTableColumnNames,
    DashboardSelectors.isWidgetDetailV2,
    IntegrationMetaSelectors.getColumnsByName,
    (columnNames: string[], isV2: boolean, columnsByName: ColumnIndex) => {
      if (!columnNames || !columnsByName) {
        return;
      }
      if (isV2) {
        return columnNames.map((columnName: string) => (columnsByName[columnName] ? columnsByName[columnName] : undefined)).filter(Boolean);
      }
      if (columnNames?.length && columnNames[0].split('.').length === 3) {
        columnNames = columnNames.map((columnName: string) => columnName.split('.')[2]);
      }
      return columnNames
        .map((columnName: string) =>
          columnsByName[columnName]
            ? new Column({
                ...columnsByName[columnName],
                name: columnName,
                attribute: new Attribute(),
              })
            : undefined,
        )
        .filter(Boolean);
    },
  );

  /**
   * getWidgetDetailTableCrossCategoryColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableCrossCategoryColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    DashboardSelectors.getWidgetDetailTableColumnNames,
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    (columnNames: string[], columnsByName: ColumnIndex) => {
      if (!columnNames || !columnsByName) {
        return;
      }
      return columnNames.map((columnName: string) => columnsByName[columnName]).filter(Boolean);
    },
  );

  /**
   * getWidgetDetailTablePreviewRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, any>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTablePreviewRequest: MemoizedSelector<CoreAppState, any> = createSelector(
    DashboardSelectors.getWidgetDetailTableColumnNames,
    DashboardSelectors.getWidgetBuilderTableHiddenColumnNames,
    DashboardSelectors.getWidgetDetailPagedRequest,
    DashboardSelectors.getWidgetDetailTableTrend,
    DashboardSelectors.getWidgetDetailSortOnsWithDefaults,
    DashboardSelectors.getWidgetDetailPageSkinType,
    DashboardSelectors.getWidgetDetailFocusedSeries,
    DashboardSelectors.getWidgetDetailTableFilterString,
    IntegrationMetaSelectors.getColumnsByName,
    (
      widgetDetailTableColumnNames: string[],
      widgetBuilderTableHiddenColumnNames: string[],
      widgetDetailPagedRequest: PagedRequest,
      widgetDetailTableTrend: Trend,
      widgetDetailSortOnsWithDefaults: SortOn[],
      widgetDetailPageSkinType: WidgetDetailPageSkinType,
      widgetDetailFocusedSeries: FocusedSeries,
      widgetDetailTableFilterString: string,
      columnsByName: ColumnIndex,
    ) => {
      return helpers.getWidgetDetailTablePreviewRequest(
        widgetDetailPagedRequest,
        widgetDetailTableTrend,
        widgetDetailSortOnsWithDefaults,
        widgetDetailPageSkinType,
        widgetDetailFocusedSeries,
        widgetDetailTableFilterString,
        widgetDetailTableColumnNames,
        widgetBuilderTableHiddenColumnNames,
        columnsByName,
      );
    },
  );

  /**
   * getWidgetDetailTablePreviewRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, any>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailOverlayTablePreviewRequest: MemoizedSelector<CoreAppState, any> = createSelector(
    DashboardSelectors.getWidgetsOverlayPreviewTableColumnNames,
    DashboardSelectors.getOverlayTablePagedRequest,
    DashboardSelectors.getWidgetDetailOverlayTrend,
    DashboardSelectors.getOverlayTableSortOnsWithDefaults,
    DashboardSelectors.getWidgetDetailPageSkinType,
    DashboardSelectors.getWidgetDetailFocusedSeries,
    (
      widgetDetailTableColumnNames: string[],
      widgetDetailPagedRequest: PagedRequest,
      widgetDetailTableTrend: Trend,
      widgetDetailSortOnsWithDefaults: SortOn[],
      widgetDetailPageSkinType: WidgetDetailPageSkinType,
      widgetDetailFocusedSeries: FocusedSeries,
    ) => {
      return helpers.getWidgetDetailTablePreviewRequest(
        widgetDetailPagedRequest,
        widgetDetailTableTrend,
        widgetDetailSortOnsWithDefaults,
        widgetDetailPageSkinType,
        widgetDetailFocusedSeries,
        widgetDetailTableTrend?.trendDefinition?.filter,
        widgetDetailTableColumnNames,
        [],
      );
    },
  );

  /**
   * getWidgetDetailTablePreview
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTablePreview: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state?.widgetDetailTablePreview,
  );

  /**
   * overlayDetailTablePreview
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DashboardSelectors
   */
  public static overlayDetailTablePreview: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state?.overlayDetailTablePreview,
  );

  /**
   * isWidgetPreviewTableVisible
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isWidgetPreviewTableVisible: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getWidgetDetailTablePreview,
    DashboardSelectors.getWidgetDetailTablePreviewError,
    (response: CustomReportPreviewSearchResponse, error: WebError) => {
      return Boolean(response?.results?.length) && !error;
    },
  );

  /**
   * getWidgetTemplateName
   * Getter function to retrieve Widget Template Name
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetTemplateName: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getActiveWidgetTemplateState,
    (template: WidgetTemplate) => template?.name,
  );

  /**
   * getInitialWizardWidget
   * @static
   * @type {MemoizedSelector<CoreAppState, AggregationWidget>}
   * @memberof DashboardSelectors
   */
  public static getInitialWizardWidget: MemoizedSelector<CoreAppState, AggregationWidget> = createSelector(
    DashboardSelectors.getActiveWidgetState,
    DashboardSelectors.getActiveWidgetTemplateState,
    DashboardSelectors.getWidgetWizardDialogModeState,
    (activeWidget: AggregationWidget, activeWidgetTemplate: WidgetTemplate, wizardDialogMode: WidgetWizardDialogMode) => {
      return helpers.getInitialWizardWidget(activeWidget, activeWidgetTemplate, wizardDialogMode);
    },
  );

  /**
   * getSnapshotChartOptionVisible
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static getSnapshotChartOptionVisible: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    DashboardSelectors.getActiveWidgetState,
    DashboardSelectors.getActiveWidgetTemplateState,
    (categoryIndex: CategoryIndex, activeWidget: AggregationWidget, activeWidgetTemplate: WidgetTemplate) => {
      return helpers.isSnapshotChartOptionVisible(categoryIndex, activeWidget, activeWidgetTemplate);
    },
  );

  /**
   * getSnapshotChartOptionVisible
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableColumnsBasedOnJoin: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    DashboardSelectors.getWidgetDetailDefinition,
    DashboardSelectors.getWidgetDetailTableCrossCategoryColumns,
    DashboardSelectors.getWidgetDetailTableColumns,
    (widgetDetailDefinition: WidgetDetailDefinition, crossCategoryColumns: Column[], columns: Column[]) => {
      const returnColumns = widgetDetailDefinition?.isV2 && widgetDetailDefinition?.isCrossCategory ? crossCategoryColumns : columns;
      return returnColumns ?? [];
    },
  );

  /**
   * getDeemWidgetDetailIncident
   * @static
   * @type {MemoizedSelector<CoreAppState, {filter: string; targetEntity: string}>}
   * @memberof DashboardSelectors
   */
  public static getDeemWidgetDetailIncident: MemoizedSelector<CoreAppState, { filter: string; targetEntity: string }> = createSelector(
    DashboardSelectors.getWidgetDetailRuleGroup,
    DashboardSelectors.getWidgetDetailTableTrend,
    (ruleGroup: RuleGroup, trend: Trend) => {
      if (!ruleGroup || !trend) {
        return;
      }
      return {
        filter: new QueryBuilder(ruleGroup).getQueryString(),
        targetEntity: trend?.trendDefinition?.entity,
      };
    },
  );

  /**
   * getWidgetDetailTableColumnsBasedOnJoinForIssues
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailTableColumnsBasedOnJoinForIssues: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    DashboardSelectors.getWidgetDetailTableCategoryId,
    DashboardSelectors.getWidgetDetailSelectedCompositeTableSubtype,
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    (categoryId: string, subtype: string, columnsByName: ColumnIndex) => {
      const columnNames = helpers.getCustomeTableColumnNamesByCategoryIdAndSubtype(subtype, categoryId);
      if (!columnNames || !columnsByName) {
        return;
      }
      return columnNames.map((columnName: string) => columnsByName[columnName]).filter(Boolean);
    },
  );

  /**
   * getWidgetIssuesDetailTableColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getWidgetIssuesDetailTableColumns: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.getWidgetDetailTableCategoryId,
    DashboardSelectors.getWidgetDetailSelectedCompositeTableSubtype,
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    (categoryId: string, subtype: string, columnsByName: ColumnIndex) => {
      const columnNames = helpers.getCustomeTableColumnNamesByCategoryIdAndSubtype(subtype, categoryId);
      if (!columnNames || !columnsByName) {
        return;
      }
      return columnNames;
    },
  );

  /**
   * isTransferOwnershipActionOriginFromSystemLimitsPage
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf DashboardSelectors
   */
  public static isTransferOwnershipActionOriginFromSystemLimitsPage: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.transferOwnershipActionOrigin === TransferOwnershipActionOrigin.SYSTEM_LIMITS_PAGE,
  );

  /**
   * selectedViewLayout
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, LayoutType>}
   * @memberof DashboardSelectors
   */
  public static selectedViewLayout: MemoizedSelector<CoreAppState, LayoutType> = createSelector(
    DashboardSelectors.dashboardState,
    UserPreferenceUIPreferenceSelectors.uiSettingsPreferences,
    (state: DashboardState, preferences: GenericObject) => {
      return state.selectedViewLayout || preferences?.[UIPreference.DASHBOARD_VIEW_TYPE];
    },
  );

  /**
   * getUnMergeWidgetSubTypeList
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static getUnMergeWidgetSubTypeList: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.unMergeWidgetSubTypeList,
  );

  /**
   * isDashboardTemplateImportInProgress
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isDashboardTemplateImportInProgress: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardTemplateImportLoadStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * isDashboardTemplateImportComplete
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isDashboardTemplateImportComplete: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.dashboardTemplateImportLoadStatus === LOAD_STATE.SUCCESS,
  );

  /**
   * getPrecomputedAggregationAttribute
   * @static
   * @type {MemoizedSelector<CoreAppState, AggregationAttributesResponse>}
   * @memberof DashboardSelectors
   */
  public static getPrecomputedAggregationAttribute: MemoizedSelector<CoreAppState, AggregationAttributesResponse> = createSelector(
    DashboardSelectors.getActiveWidget,
    IntegrationMetaSelectors.getPrecomputedAggregationAttributeById,
    (widget: AggregationWidget, precomputedAggregationAttributeById: Record<string, AggregationAttributesResponse>) => {
      return precomputedAggregationAttributeById[widget?.trend?.precomputedAggregationId];
    },
  );

  /**
   * overlayPrecomputedAggregations
   * @static
   * @type {MemoizedSelector<CoreAppState, PrecomputedAggregation[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static overlayPrecomputedAggregations: MemoizedSelector<CoreAppState, PrecomputedAggregation[]> = createSelector(
    IntegrationMetaSelectors.precomputedAggregationsByCategory,
    DashboardSelectors.getActiveWidget,
    (precomputedAggregationsByCategory: Record<string, PrecomputedAggregation[]>, widget: AggregationWidget) => {
      return precomputedAggregationsByCategory[widget.overlayCategoryId] ?? [];
    },
  );

  /**
   * getOverlayPrecomputedAggregationAttribute
   * @static
   * @type {MemoizedSelector<CoreAppState, AggregationAttributesResponse>}
   * @memberof DashboardSelectors
   */
  public static getOverlayPrecomputedAggregationAttribute: MemoizedSelector<CoreAppState, AggregationAttributesResponse> = createSelector(
    DashboardSelectors.getActiveWidget,
    IntegrationMetaSelectors.getPrecomputedAggregationAttributeById,
    (widget: AggregationWidget, precomputedAggregationAttributeById: Record<string, AggregationAttributesResponse>) => {
      return precomputedAggregationAttributeById[widget?.overlayTrend?.precomputedAggregationId];
    },
  );

  /**
   * overlayCategoryId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DashboardSelectors
   */
  public static overlayCategoryId: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getActiveWidget,
    (widget: AggregationWidget) => widget?.overlayCategoryId,
  );

  /**
   * overlayColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static overlayColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    UserPreferenceFeatureControlsSelectors.isWidgetJoinEnabled,
    IntegrationMetaSelectors.getCrossCategoryColumns,
    IntegrationMetaSelectors.getAllColumnsByCategoryId,
    DashboardSelectors.overlayCategoryId,
    (
      isWidgetJoinEnabled: boolean,
      crossCategoryColumns: Record<string, Column[]>,
      columnsByCategoryId: Record<string, Column[]>,
      categoryId: string,
    ) => {
      return isWidgetJoinEnabled ? crossCategoryColumns[categoryId] : columnsByCategoryId[categoryId];
    },
  );

  /**
   * overlayColumnsByName
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @memberof DashboardSelectors
   */
  public static overlayColumnsByName: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    DashboardSelectors.overlayColumns,
    (columns) => keyBy(columns, COLUMN_NAMES.byName.attributeName),
  );

  /**
   * overlayColumnsByName
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @memberof DashboardSelectors
   */
  public static overlayAggregationColumns: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    IntegrationMetaSelectors.aggregationColumnsByCategory,
    DashboardSelectors.overlayCategoryId,
    DashboardSelectors.overlayColumnsByName,
    (columnsByCategoryId: Record<string, Column[]>, categoryId: string, columnsByName: ColumnIndex) => {
      const aggregationColumnsByName = keyBy(columnsByCategoryId[categoryId], COLUMN_NAMES.byName.attributeName);
      return size(aggregationColumnsByName) > 0 ? aggregationColumnsByName : columnsByName;
    },
  );

  /**
   * overlayCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Category>}
   * @memberof DashboardSelectors
   */
  public static overlayCategory: MemoizedSelector<CoreAppState, Category> = createSelector(
    DashboardSelectors.overlayCategoryId,
    DashboardSelectors.getDashboardCategoriesState,
    (overlayCategoryId: string, categories: Category[]) => {
      return categories.find((category: Category) => category.categoryId === overlayCategoryId);
    },
  );

  /**
   * overlayCategory
   * @static
   * @type {MemoizedSelector<CoreAppState, Category>}
   * @memberof DashboardSelectors
   */
  public static overlayCategoryLabel: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.overlayCategory,
    (category: Category) => category?.fullLabel,
  );

  /**
   * overlaySupportedCounters
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof DashboardSelectors
   */
  public static overlaySupportedCounters: MemoizedSelector<CoreAppState, string[]> = createSelector(
    DashboardSelectors.overlayCategory,
    DashboardSelectors.getDashboardCategoriesState,
    (overlayCategory: Category) => overlayCategory?.supportedCounters,
  );

  /**
   * overlayGroupByColumnsByName
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @memberof DashboardSelectors
   */
  public static overlayGroupByColumnsByName: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    DashboardSelectors.overlayColumns,
    (columns: Column[]) => keyBy(getGroupByColumns(columns), COLUMN_NAMES.byName.attributeName),
  );

  /**
   * overlayFilterColumnsByName
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @memberof DashboardSelectors
   */
  public static overlayFilterColumnsByName: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    DashboardSelectors.overlayColumns,
    (columns: Column[]) => keyBy(getFilterColumns(columns), COLUMN_NAMES.byName.attributeName),
  );

  /**
   * getCustomWidgetOverlayColumns
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @memberof DashboardSelectors
   */
  public static getCustomWidgetOverlayColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    DashboardSelectors.overlayColumnsByName,
    DashboardSelectors.getWidgetsOverlayPreviewTableColumnNames,
    (columnIndex: ColumnIndex, columnNames: string[]) => {
      if (size(columnIndex) > 0) {
        return columnNames?.map((columnName: string) => columnIndex[columnName]).filter((column: Column) => !!column);
      }
      return [];
    },
  );

  /**
   * isValidOverlayTrendDefinition
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DashboardSelectors
   */
  public static isValidOverlayTrendDefinition: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DashboardSelectors.getActiveWidget,
    DashboardSelectors.overlayColumnsByName,
    (widget: AggregationWidget, aggregationColumns: ColumnIndex) =>
      helpers.isValidTrendDefinition(widget?.overlayTrend?.trendDefinition, aggregationColumns, widget?.chartType),
  );

  /**
   * getTrackingIdDetailsMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, IncrementalLoadingWidgetDetails>>}
   * @memberof DashboardSelectors
   */
  public static getTrackingIdDetailsMap: MemoizedSelector<CoreAppState, Map<string, IncrementalLoadingWidgetDetails>> = createSelector(
    DashboardSelectors.dashboardState,
    (state: DashboardState) => state.trackingIdDetailsMap,
  );

  /**
   * getLoadingStatusByTrackingIds
   * @static
   * @type {MemoizedSelector<CoreAppState, IncrementalLoadingResponseTrendStatus[]>}
   * @memberof DashboardSelectors
   */
  public static getLoadingStatusByTrackingIds = ([mainWidgetId, overlayId]: string[]): MemoizedSelector<
    CoreAppState,
    IncrementalLoadingResponseTrendStatus[]
  > => {
    return createSelector(DashboardSelectors.dashboardState, (state: DashboardState) => {
      const map = state.loadingStatusByTrackingIdMap;
      return [map.get(mainWidgetId), map.get(overlayId)];
    });
  };
}

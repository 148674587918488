/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { AlertBannerTarget } from '@ws1c/intelligence-models';

export interface AlertItem {
  message?: string;
  actionLink?: string;
  actionText?: string;
}

export interface AlertBanner extends AlertItem {
  visible?: boolean;
  target?: AlertBannerTarget;
  alertType?: string;
  autoDismiss?: boolean;
  hideAfter?: number;
  alertItems?: AlertItem[];
}

export interface AlertBannerState {
  [key: number]: AlertBanner;
}

export const initialAlertBannerState: AlertBannerState = {};

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { AvailableServiceSearchRequest, AvailableServiceSearchResponse } from '@ws1c/intelligence-models';

/**
 * ConnectionCommonService
 *
 * @export
 * @class ConnectionCommonService
 */
@Injectable({
  providedIn: 'root',
})
export class ConnectionCommonService {
  /**
   * @param {HttpService} httpService
   * @memberof ConnectionCommonService
   */
  constructor(private httpService: HttpService) {}

  /**
   * getAvailableServicesInbound
   *
   * @param {AvailableServiceSearchRequest} request
   * @returns {Observable<AvailableServiceSearchResponse>}
   */
  public getAvailableServicesInbound(request: AvailableServiceSearchRequest): Observable<AvailableServiceSearchResponse> {
    return this.httpService.post(Endpoint.AUTOMATION_CONNECTION_SEARCH_INBOUND, request).pipe(
      map((response: any) => deserialize(AvailableServiceSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { SolutionSettingMetricDetails, Trend, WidgetDetailDefinition } from '@ws1c/intelligence-models';

/**
 * DeemCommonState
 *
 * @export
 * @interface DeemCommonState
 */
export interface DeemCommonState {
  deemWidgetDetail: WidgetDetailDefinition;
  deemWidgetTrend: Trend;
  deemWidgetReturnPath: string;
  activeSettingMetrics: SolutionSettingMetricDetails[];
  updatingActiveSettingMetrics: SolutionSettingMetricDetails[];
  isDeemThresholdEditModalOpen: boolean;
}

/**
 * Defines initial state for DeemCommon
 *
 * @export
 * @type {DeemCommonState}
 */
export const initialDeemCommonState: DeemCommonState = {
  deemWidgetDetail: undefined,
  deemWidgetTrend: undefined,
  deemWidgetReturnPath: undefined,
  activeSettingMetrics: undefined,
  updatingActiveSettingMetrics: undefined,
  isDeemThresholdEditModalOpen: false,
};

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { GenericObject, TrendSpan, TrendSpanOption } from '@dpa/ui-common';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common';
import { AppConstants, ChronoUnit, TrendDateRange } from '@ws1c/intelligence-models';

type SamplingFrequencyOption = Omit<TrendSpanOption, 'trendSpan'>;

/**
 * TrendFrequencyComponent
 * @export
 * @class TrendFrequencyComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-trend-frequency',
  templateUrl: 'trend-frequency.component.html',
  styleUrls: ['trend-frequency.component.scss'],
})
export class TrendFrequencyComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public trendDateRange: TrendDateRange;
  @Input() public isFullRangeEnabled: boolean = true;
  @Input() public readonly: boolean = false;
  @Output() public trendFrequencyUpdated = new EventEmitter<TrendSpan>();

  public samplingFrequencyOptions: SamplingFrequencyOption[] = [];
  public selectedSamplingFrequency: SamplingFrequencyOption;

  // This defines the different values of the data points (sample size) in the graph to generate frequency options.
  private readonly AVAILABLE_DATA_POINTS = [30, 20, 10];
  private readonly MAX_DATA_POINTS = this.AVAILABLE_DATA_POINTS[0];
  private changeSubject = new Subject<TrendSpan>();
  private subs = new Subscription();

  private readonly hourly: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_HOURLY'),
    defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.HOURS] }),
  };

  private readonly hours_12: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_HOURS_12'),
    defaultSamplingFrequency: new TrendSpan({ duration: 12, unit: ChronoUnit[ChronoUnit.HOURS] }),
  };

  private readonly hours_12_deprecated: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.FREQUENCY_DEPRECATED', {
      period: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_HOURS_12'),
    }),
    defaultSamplingFrequency: new TrendSpan({ duration: 12, unit: ChronoUnit[ChronoUnit.HOURS] }),
  };

  private readonly hours_24: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_HOURS_24'),
    defaultSamplingFrequency: new TrendSpan({ duration: 24, unit: ChronoUnit[ChronoUnit.HOURS] }),
  };

  private readonly hours_24_deprecated: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.FREQUENCY_DEPRECATED', {
      period: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_HOURS_24'),
    }),
    defaultSamplingFrequency: new TrendSpan({ duration: 24, unit: ChronoUnit[ChronoUnit.HOURS] }),
  };

  private readonly daily: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_DAILY'),
    defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.DAYS] }),
  };

  private readonly weekly: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_WEEKLY'),
    defaultSamplingFrequency: new TrendSpan({ duration: 7, unit: ChronoUnit[ChronoUnit.DAYS] }),
  };

  private readonly weekly_deprecated: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.FREQUENCY_DEPRECATED', {
      period: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_WEEKLY'),
    }),
    defaultSamplingFrequency: new TrendSpan({ duration: 7, unit: ChronoUnit[ChronoUnit.DAYS] }),
  };

  private readonly monthly: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_28_DAYS'),
    defaultSamplingFrequency: new TrendSpan({ duration: 28, unit: ChronoUnit[ChronoUnit.DAYS] }),
  };

  private readonly monthly_deprecated: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.FREQUENCY_DEPRECATED', {
      period: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_28_DAYS'),
    }),
    defaultSamplingFrequency: new TrendSpan({ duration: 28, unit: ChronoUnit[ChronoUnit.DAYS] }),
  };

  private readonly fullRange: SamplingFrequencyOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_ENTIRE_DATE_RANGE'),
    defaultSamplingFrequency: undefined,
  };

  /**
   * constructor
   * @param {I18NService} i18nService
   * @memberof TrendFrequencyComponent
   */
  constructor(private i18nService: I18NService) {}

  /**
   * ngOnInit
   * @memberof TrendFrequencyComponent
   */
  public ngOnInit() {
    this.subs.add(
      this.changeSubject.pipe(debounceTime(10)).subscribe((samplingFrequency: TrendSpan) => {
        this.trendFrequencyUpdated.emit(samplingFrequency);
      }),
    );
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof TrendFrequencyComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.trendDateRange?.currentValue) {
      this.populateSamplingFrequencyOptions(changes.trendDateRange.currentValue);
      this.updateSelectedSamplingFrequency();
    }
  }

  /**
   * ngOnDestroy
   * @memberof TrendFrequencyComponent
   */
  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * typeAheadFormatter
   * @param {GenericObject} result
   * @returns {string}
   * @memberof TrendFrequencyComponent
   */
  public typeAheadFormatter(result: GenericObject): string {
    return result.label || '';
  }

  /**
   * onSamplingFrequencyOptionChange
   * @param {SamplingFrequencyOption} $event
   * @memberof TrendFrequencyComponent
   */
  public onSamplingFrequencyOptionChange($event: SamplingFrequencyOption) {
    this.selectedSamplingFrequency = $event;
    this.changeSubject.next($event.defaultSamplingFrequency);
  }

  /**
   * populateSamplingFrequencyOptions
   * @param {TrendDateRange} trendDateRange
   * @memberof TrendFrequencyComponent
   */
  public populateSamplingFrequencyOptions(trendDateRange: TrendDateRange) {
    const { trendSpan } = trendDateRange;
    this.samplingFrequencyOptions.length = 0;
    if (trendSpan) {
      switch (trendSpan.unit) {
        case ChronoUnit[ChronoUnit.HOURS]:
          this.samplingFrequencyOptions.push(this.hourly);
          if (trendSpan.duration === 12) {
            this.samplingFrequencyOptions.push(this.hours_12_deprecated);
            break;
          }
          if (trendSpan.duration > 12) {
            this.samplingFrequencyOptions.push(this.hours_12);
          }
          if (trendSpan.duration === 24) {
            this.samplingFrequencyOptions.push(this.hours_24_deprecated);
            break;
          }
          if (trendSpan.duration > 24) {
            this.samplingFrequencyOptions.push(this.hours_24);
          }
          break;
        case ChronoUnit[ChronoUnit.DAYS]:
          if (trendSpan.duration <= this.MAX_DATA_POINTS) {
            this.samplingFrequencyOptions.push(this.daily);
          }
          if (trendSpan.duration === 7) {
            this.samplingFrequencyOptions.push(this.weekly_deprecated);
          }
          if (trendSpan.duration > 7 && trendSpan.duration <= 7 * this.MAX_DATA_POINTS) {
            this.samplingFrequencyOptions.push(this.weekly);
          }
          if (trendSpan.duration === 28) {
            this.samplingFrequencyOptions.push(this.monthly_deprecated);
          }
          if (trendSpan.duration > 28) {
            this.samplingFrequencyOptions.push(this.monthly);
          }
          break;
        case ChronoUnit[ChronoUnit.WEEKS]:
          if (trendSpan.duration <= this.MAX_DATA_POINTS) {
            this.samplingFrequencyOptions.push(this.weekly);
          }
          if (trendSpan.duration > 4) {
            this.samplingFrequencyOptions.push(this.monthly);
          }
          break;
        case ChronoUnit[ChronoUnit.MONTHS]:
          this.samplingFrequencyOptions.push(this.monthly);
          break;
        default:
          this.samplingFrequencyOptions.push(this.monthly);
          break;
      }
    } else {
      const duration = Math.abs(trendDateRange.endDateMillis - trendDateRange.startDateMillis);
      this.populateCustomTrendFrequencies(duration, 0);
    }
    if (this.isFullRangeEnabled) {
      this.samplingFrequencyOptions.push(this.fullRange);
    }
  }

  /**
   * updateSelectedSamplingFrequency
   * @memberof TrendFrequencyComponent
   */
  public updateSelectedSamplingFrequency() {
    let selectedOption: SamplingFrequencyOption;

    if (!this.trendDateRange?.samplingFrequency) {
      selectedOption = this.fullRange;
    } else {
      selectedOption =
        this.samplingFrequencyOptions.find(({ defaultSamplingFrequency }: SamplingFrequencyOption) => {
          if (defaultSamplingFrequency) {
            const { duration, unit } = defaultSamplingFrequency;
            const { duration: toMatchDuration, unit: toMatchUnit } = this.trendDateRange.samplingFrequency;
            return duration === toMatchDuration && unit === toMatchUnit;
          }
        }) ?? this.samplingFrequencyOptions[0];
    }

    this.onSamplingFrequencyOptionChange(selectedOption);
  }

  /**
   * Recursively generate frequencies based on the number of AVAILABLE_DATA_POINTS required in the chart
   * Maximum number of frequencies is equal to the length of the AVAILABLE_DATA_POINTS array.
   * @param {number} duration
   * @param {number} dataPointsIndex
   * @memberof TrendFrequencyComponent
   */
  private populateCustomTrendFrequencies(duration: number, dataPointsIndex: number) {
    if (dataPointsIndex >= this.AVAILABLE_DATA_POINTS.length) {
      return;
    }
    const freqDuration = duration / this.AVAILABLE_DATA_POINTS[dataPointsIndex];
    if (freqDuration > AppConstants.ONE_DAY_IN_MILLIS) {
      // Convert freqDuration to weeks. Limit max frequency to 4 weeks (28 days).
      const count = Math.min(Math.ceil(freqDuration / AppConstants.ONE_WEEK_IN_MILLIS) * 7, 28);
      this.checkAndAddFrequency({
        label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_FREQUENCY_N_DAYS', { count }),
        defaultSamplingFrequency: new TrendSpan({ duration: count, unit: ChronoUnit[ChronoUnit.DAYS] }),
      });
    } else if (freqDuration > AppConstants.ONE_HOUR_IN_MILLIS) {
      this.checkAndAddFrequency(this.daily);
    } else {
      this.checkAndAddFrequency(this.hourly);
    }
    this.populateCustomTrendFrequencies(duration, dataPointsIndex + 1);
  }

  /**
   * checkAndAddFrequency
   * @param {SamplingFrequencyOption} frequency
   * @memberof TrendFrequencyComponent
   */
  private checkAndAddFrequency(frequency: SamplingFrequencyOption) {
    const lastFrequency = this.samplingFrequencyOptions[this.samplingFrequencyOptions.length - 1]?.defaultSamplingFrequency;
    const currentFrequency = frequency.defaultSamplingFrequency;
    if (lastFrequency?.duration === currentFrequency.duration && lastFrequency.unit === currentFrequency.unit) {
      return;
    }
    this.samplingFrequencyOptions.push(frequency);
  }
}

/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemContributorWidgetComponentType
 * @export
 * @enum {number}
 */
export enum DeemContributorWidgetComponentType {
  STANDARD_WIDGET_COMPONENT,
  STANDARD_CHART_COMPONENT,
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  MarketplaceResource,
  MarketplaceResourceType,
  MarketplaceSearchRequest,
  MarketplaceSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * MarketplaceActions
 *
 * @export
 * @class MarketplaceActions
 */
@Injectable()
export class MarketplaceActions {
  public static readonly CATEGORY = '[MARKETPLACE]';

  private static type = (actionName: string): string => `${MarketplaceActions.CATEGORY} ${actionName}`;

  /**
   * searchMarketplace
   * @static
   * @memberof MarketplaceActions
   */
  public static searchMarketplace = createAction(
    MarketplaceActions.type('SEARCH_MARKETPLACE'),
    props<{ request: MarketplaceSearchRequest; previewResource?: MarketplaceResource }>(),
  );

  /**
   * searchMarketplaceSuccess
   * @static
   * @memberof MarketplaceActions
   */
  public static searchMarketplaceSuccess = createAction(
    MarketplaceActions.type('SEARCH_MARKETPLACE_SUCCESS'),
    props<{ response: MarketplaceSearchResponse; previewResource?: MarketplaceResource; resourceType?: MarketplaceResourceType }>(),
  );

  /**
   * searchMarketplaceFailure
   * @static
   * @memberof MarketplaceActions
   */
  public static searchMarketplaceFailure = createAction(
    MarketplaceActions.type('SEARCH_MARKETPLACE_FAILURE'),
    props<{ error: WebError }>(),
  );

  /**
   * fetchMarketplaceResourceCounts
   * @static
   * @memberof MarketplaceActions
   */
  public static fetchMarketplaceResourceCounts = createAction(
    MarketplaceActions.type('FETCH_MARKETPLACE_RESOURCE_COUNTS'),
    props<{ request: MarketplaceSearchRequest }>(),
  );

  /**
   * fetchMarketplaceResourceCountsSuccess
   * @static
   * @memberof MarketplaceActions
   */
  public static fetchMarketplaceResourceCountsSuccess = createAction(
    MarketplaceActions.type('FETCH_MARKETPLACE_RESOURCE_COUNTS_SUCCESS'),
    props<{ resourceCountByType?: Partial<Record<MarketplaceResourceType, number>> }>(),
  );

  /**
   * fetchMarketplaceResourceCountsFailure
   * @static
   * @memberof MarketplaceActions
   */
  public static fetchMarketplaceResourceCountsFailure = createAction(
    MarketplaceActions.type('FETCH_MARKETPLACE_RESOURCE_COUNTS_FAILURE'),
    props<{ error: WebError }>(),
  );

  /**
   * clearMarketplaceSearchResponse
   * @static
   * @memberof MarketplaceActions
   */
  public static clearMarketplaceSearchResponse = createAction(MarketplaceActions.type('CLEAR_MARKETPLACE_SEARCH_RESPONSE'));

  /**
   * setResourcePreviewVisibility
   * @static
   * @memberof MarketplaceActions
   */
  public static setResourcePreviewVisibility = createAction(
    MarketplaceActions.type('SET_RESOURCE_PREVIEW_VISIBILITY'),
    props<{ isVisible: boolean }>(),
  );

  /**
   * setFeaturedResource
   * @static
   * @memberof MarketplaceActions
   */
  public static setFeaturedResource = createAction(
    MarketplaceActions.type('SET_FEATURED_RESOURCE'),
    props<{ resource: MarketplaceResource }>(),
  );

  /**
   * setPreviewResource
   * @static
   * @memberof MarketplaceActions
   */
  public static setPreviewResource = createAction(
    MarketplaceActions.type('SET_PREVIEW_RESOURCE'),
    props<{ resource: MarketplaceResource; request?: MarketplaceSearchRequest; response?: MarketplaceSearchResponse }>(),
  );

  /**
   * selectNextPreviewResource
   * @static
   * @memberof MarketplaceActions
   */
  public static selectNextPreviewResource = createAction(
    MarketplaceActions.type('SELECT_NEXT_PREVIEW_RESOURCE'),
    props<{ resource: MarketplaceResource }>(),
  );

  /**
   * selectPrevPreviewResource
   * @static
   * @memberof MarketplaceActions
   */
  public static selectPrevPreviewResource = createAction(
    MarketplaceActions.type('SELECT_PREV_PREVIEW_RESOURCE'),
    props<{ resource: MarketplaceResource }>(),
  );

  /**
   * addToWorkspace
   * @static
   * @memberof MarketplaceActions
   */
  public static addToWorkspace = createAction(
    MarketplaceActions.type('ADD_TO_WORKSPACE'),
    props<{ resource: MarketplaceResource; name?: string; description?: string; navigateToWorkspace?: boolean }>(),
  );

  /**
   * addToWorkspaceSuccess
   * @static
   * @memberof MarketplaceActions
   */
  public static addToWorkspaceSuccess = createAction(
    MarketplaceActions.type('ADD_TO_WORKSPACE_SUCCESS'),
    props<{ resource: MarketplaceResource }>(),
  );

  /**
   * addToWorkspaceFailure
   * @static
   * @memberof MarketplaceActions
   */
  public static addToWorkspaceFailure = createAction(MarketplaceActions.type('ADD_TO_WORKSPACE_FAILURE'));

  /**
   * setFrontlineEnabledModalVisibility
   * @static
   * @memberof MarketplaceActions
   */
  public static setFrontlineEnabledModalVisibility = createAction(
    MarketplaceActions.type('SET_FRONTLINE_ENABLED_MODAL_VISIBILITY'),
    props<{ isVisible: boolean }>(),
  );
}

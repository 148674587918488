/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

import {
  AcceptTrial,
  IntelOrg,
  IntroModalMode,
  IntroType,
  LOAD_STATE,
  PendoMetadata,
  TrialBannerState,
  UISettings,
  UserPreference,
} from '@ws1c/intelligence-models';

/**
 * UserPreferenceState
 * @export
 * @interface UserPreferenceState
 */
export interface UserPreferenceState {
  loadState: LOAD_STATE;
  currentIntroPage: IntroType;
  currentUISettingsKeys: string[];
  userPreference: UserPreference; // Orginal User Preference
  introModalMode: IntroModalMode;
  isEulaShowing: boolean;
  isAcceptingEula: boolean;
  isBrownfieldNotificationModalOpen: boolean;
  isRequestCloudAccountModalOpen: boolean;
  eulaAccepted: boolean;
  eulaHtml: string;
  trialBannerState: TrialBannerState;
  initialUrlQueryParams: GenericObject;
  isFetchingTrialUserContactDetails: boolean;
  isNonAdminMode: boolean;
  isRefreshingUserPreference: boolean;
  isRemediationMode: boolean;
  isUpdatingUISettings: boolean;
  integrationsDetails: GenericObject;
  trialUserContactDetails: AcceptTrial;
  uiSettings: UISettings;
  pendo: PendoMetadata;
  isPendoMetadataLoading: boolean;
  userOrgs: IntelOrg[];
}

/**
 * Server does not have userPreference patch functionality
 * userPreferenceRecord represents the database state
 * userPreference contains the app state and can be changed (previously in localStorage)
 * uiPreferenceRequest contains the patch data
 * sending uiPreferenceRequest will use the userPreferenceRecord defaults to send a put
 */
export const initialUserPreferenceState: UserPreferenceState = {
  loadState: LOAD_STATE.NONE,
  currentIntroPage: undefined,
  currentUISettingsKeys: [],
  userPreference: new UserPreference(),
  introModalMode: IntroModalMode.CLOSE,
  isEulaShowing: false,
  isAcceptingEula: false,
  isBrownfieldNotificationModalOpen: false,
  isRequestCloudAccountModalOpen: false,
  eulaAccepted: false,
  eulaHtml: '',
  trialBannerState: TrialBannerState.HIDDEN,
  initialUrlQueryParams: undefined,
  isFetchingTrialUserContactDetails: false,
  isNonAdminMode: false,
  isRefreshingUserPreference: false,
  isRemediationMode: false,
  isUpdatingUISettings: false,
  integrationsDetails: {},
  trialUserContactDetails: {} as AcceptTrial,
  uiSettings: {} as UISettings,
  pendo: new PendoMetadata(),
  isPendoMetadataLoading: false,
  userOrgs: undefined,
};

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Bookmark, BookmarkCategory } from '@ws1c/intelligence-models';

/**
 * getBookmarksByCategory
 * @exports
 * @type {(bookmarks: Bookmark[], category: string) => Bookmark[]}
 * @returns {Bookmark[]}
 */
export const getBookmarksByCategory = (bookmarks: Bookmark[], category: string): Bookmark[] => {
  if (!category) {
    return bookmarks;
  }
  return bookmarks.filter((bookmark: Bookmark) =>
    category === BookmarkCategory.DASHBOARD
      ? [BookmarkCategory.APP, BookmarkCategory.DASHBOARD, BookmarkCategory.DASHBOARD_WIDGET].includes(
          bookmark.category as BookmarkCategory,
        )
      : bookmark.category === category,
  );
};

<div class="refresh-container">
  <span
    *ngIf="lastUpdated"
    class="mr-x2"
  >
    {{ 'COMMON_MESSAGES.LAST_UPDATED' | translate: { time: lastUpdated | formattedDate: DateTimeFormat.MOMENT_TIME_FORMAT } }}
  </span>
  <dpa-tooltip
    [tooltipText]="'COMMON_ACTIONS.REFRESH_DATA' | translate"
    [attr.aria-label]="'COMMON_ACTIONS.REFRESH_DATA' | translate"
    [focusEnabled]="true"
    (click)="refresh()"
    (keyup.enter)="refresh()"
    role="tooltip"
    class="mr-x2"
  >
    <cds-icon
      shape="refresh"
      size="18"
    ></cds-icon>
  </dpa-tooltip>
  <span
    *ngIf="showDivider"
    class="vertical-divider mr-x2"
  ></span>
</div>

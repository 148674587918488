/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { ResourceDeployRequest, Tag, Template, TEMPLATE_TYPE, TemplateBookmark } from '@ws1c/intelligence-models';

/**
 * TemplateCommonActions
 *
 * @export
 * @class TemplateCommonActions
 */
export class TemplateCommonActions {
  public static readonly CATEGORY = '[TEMPLATE_COMMON]';
  private static readonly type = (actionName: string): string => `${TemplateCommonActions.CATEGORY} ${actionName}`;

  /**
   * getTags
   *
   * @static
   * @memberof TemplateCommonActions
   */
  public static getTags = createAction(TemplateCommonActions.type('GET_TAGS'));

  /**
   * setTags
   *
   * @static
   * @memberof TemplateCommonActions
   */
  public static setTags = createAction(TemplateCommonActions.type('SET_TAGS'), props<{ tags: Tag[] }>());

  /**
   * loadTemplateByName
   * @static
   * @memberof TemplateCommonActions
   */
  public static loadTemplateByName = createAction(
    TemplateCommonActions.type('LOAD_TEMPLATE_BY_NAME'),
    props<{
      templateType: TEMPLATE_TYPE;
      integration: string;
      entity: string;
      templateName: string;
    }>(),
  );

  /**
   * loadTemplateByNameSuccess
   * @static
   * @memberof TemplateCommonActions
   */
  public static loadTemplateByNameSuccess = createAction(
    TemplateCommonActions.type('LOAD_TEMPLATE_BY_NAME_SUCCESS'),
    props<{ template: Template }>(),
  );

  /**
   * loadTemplateByNameFailure
   * @static
   * @memberof TemplateCommonActions
   */
  public static loadTemplateByNameFailure = createAction(
    TemplateCommonActions.type('LOAD_TEMPLATE_BY_NAME_FAILURE'),
    props<{ templateType: TEMPLATE_TYPE }>(),
  );

  /**
   * loadTemplates
   * @static
   * @memberof TemplateCommonActions
   */
  public static loadTemplates = createAction(TemplateCommonActions.type('LOAD_TEMPLATES'), props<{ templateType: TEMPLATE_TYPE }>());

  /**
   * loadTemplatesSuccess
   * @static
   * @memberof TemplateCommonActions
   */
  public static loadTemplatesSuccess = createAction(
    TemplateCommonActions.type('LOAD_TEMPLATES_SUCCESS'),
    props<{ templateType: TEMPLATE_TYPE; templates: Template[] }>(),
  );

  /**
   * loadTemplatesFailure
   * @static
   * @memberof TemplateCommonActions
   */
  public static loadTemplatesFailure = createAction(
    TemplateCommonActions.type('LOAD_TEMPLATES_FAILURE'),
    props<{ templateType: TEMPLATE_TYPE }>(),
  );

  /**
   * setTemplateTotalCount
   * @static
   * @memberof TemplateCommonActions
   */
  public static setTemplateTotalCount = createAction(
    TemplateCommonActions.type('SET_TEMPLATE_TOTAL_COUNT'),
    props<{ templateType: TEMPLATE_TYPE; totalCount: number }>(),
  );

  /**
   * toggleTemplateBookmark
   * @static
   * @memberof TemplateCommonActions
   */
  public static toggleTemplateBookmark = createAction(
    TemplateCommonActions.type('TOGGLE_TEMPLATE_BOOKMARK'),
    props<{ templateType: TEMPLATE_TYPE; templateBookmark: TemplateBookmark }>(),
  );

  /**
   * toggleTemplateBookmarkSuccess
   * @static
   * @memberof TemplateCommonActions
   */
  public static toggleTemplateBookmarkSuccess = createAction(
    TemplateCommonActions.type('TOGGLE_TEMPLATE_BOOKMARK_SUCCESS'),
    props<{ templateType: TEMPLATE_TYPE; template: Template }>(),
  );

  /**
   * toggleTemplateBookmarkFailure
   * @static
   * @memberof TemplateCommonActions
   */
  public static toggleTemplateBookmarkFailure = createAction(
    TemplateCommonActions.type('TOGGLE_TEMPLATE_BOOKMARK_FAILURE'),
    props<{ templateType: TEMPLATE_TYPE }>(),
  );

  /**
   * goToCreateWidgetFromTemplate
   * @static
   * @memberof TemplateCommonActions
   */
  public static goToCreateWidgetFromTemplate = createAction(
    TemplateCommonActions.type('GO_TO_CREATE_WIDGET_FROM_TEMPLATE'),
    props<{ templateId: string; dashboardId: string; sourceTemplateId: string }>(),
  );

  /**
   * goToCreateReportFromTemplate
   * @static
   * @memberof TemplateCommonActions
   */
  public static goToCreateReportFromTemplate = createAction(
    TemplateCommonActions.type('GO_TO_CREATE_REPORT_FROM_TEMPLATE'),
    props<{ templateId: string; sourceTemplateId: string }>(),
  );

  /**
   * goToCreateAutomationFromTemplate
   * @static
   * @memberof TemplateCommonActions
   */
  public static goToCreateAutomationFromTemplate = createAction(
    TemplateCommonActions.type('GO_TO_CREATE_AUTOMATION_FROM_TEMPLATE'),
    props<{ templateId: string; sourceTemplateId: string }>(),
  );

  /**
   * deployTemplate
   * @static
   * @memberof TemplateCommonActions
   */
  public static deployTemplate = createAction(
    TemplateCommonActions.type('DEPLOY_TEMPLATE'),
    props<{ templateType: TEMPLATE_TYPE; templateId: string; deployRequest: ResourceDeployRequest }>(),
  );

  /**
   * deployTemplateSuccess
   * @static
   * @memberof TemplateCommonActions
   */
  public static deployTemplateSuccess = createAction(TemplateCommonActions.type('DEPLOY_TEMPLATE_SUCCESS'));

  /**
   * deployTemplateFailure
   * @static
   * @memberof TemplateCommonActions
   */
  public static deployTemplateFailure = createAction(TemplateCommonActions.type('DEPLOY_TEMPLATE_FAILURE'));

  /**
   * updateDeploymentConfirmationModalStatus
   * @static
   * @memberof TemplateCommonActions
   */
  public static updateDeploymentConfirmationModalStatus = createAction(
    TemplateCommonActions.type('UPDATE_DEPLOYMENT_CONFIRMATION_MODAL_STATUS'),
    props<{ status: boolean }>(),
  );

  /**
   * openResourceDeploymentUrl
   * @static
   * @memberof TemplateCommonActions
   */
  public static openResourceDeploymentUrl = createAction(
    TemplateCommonActions.type('OPEN_RESOURCE_DEPLOYMENT_URL'),
    props<{ resourceUrl: string }>(),
  );

  /**
   * loadTemplateDeployValidations
   * @static
   * @memberof TemplateCommonActions
   */
  public static loadTemplateDeployValidations = createAction(
    TemplateCommonActions.type('LOAD_TEMPLATE_DEPLOY_VALIDATIONS'),
    props<{ templateType: TEMPLATE_TYPE; templateId: string; templateName: string }>(),
  );

  /**
   * deployTemplatesSuccess
   * @static
   * @memberof TemplateCommonActions
   */
  public static loadTemplateDeployValidationsSuccess = createAction(
    TemplateCommonActions.type('LOAD_TEMPLATE_DEPLOY_VALIDATIONS_SUCCESS'),
    props<{ validationMessages: string[] }>(),
  );

  /**
   * deployTemplatesSuccess
   * @static
   * @memberof TemplateCommonActions
   */
  public static loadTemplateDeployValidationsFailure = createAction(TemplateCommonActions.type('LOAD_TEMPLATE_DEPLOY_VALIDATIONS_FAILURE'));
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  Automation,
  AutomationTemplate,
  CustomReportPreviewSearchResponse,
  PreviewReportContentRequest,
  ReportTemplate,
  Trend,
  TrendDefinition,
  WidgetTemplate,
} from '@ws1c/intelligence-models';

/**
 * PreviewCommonActions
 *
 * @export
 * @class PreviewCommonActions
 */
@Injectable()
export class PreviewCommonActions {
  public static readonly CATEGORY = '[PREVIEW_COMMON]';
  private static readonly type = (actionName: string): string => `${PreviewCommonActions.CATEGORY} ${actionName}`;

  public static loadWidgetPreview = createAction(
    PreviewCommonActions.type('LOAD_WIDGET_PREVIEW'),
    props<{ template?: WidgetTemplate; trendDefinition?: TrendDefinition }>(),
  );

  public static loadWidgetPreviewSuccess = createAction(
    PreviewCommonActions.type('LOAD_WIDGET_PREVIEW_SUCCESS'),
    props<{ widgetPreview: Trend }>(),
  );

  public static loadWidgetPreviewFailure = createAction(
    PreviewCommonActions.type('LOAD_WIDGET_PREVIEW_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static clearWidgetPreview = createAction(PreviewCommonActions.type('CLEAR_WIDGET_PREVIEW'));

  public static loadReportPreview = createAction(
    PreviewCommonActions.type('LOAD_REPORT_PREVIEW'),
    props<{ template?: ReportTemplate; request?: PreviewReportContentRequest }>(),
  );

  public static loadReportPreviewSuccess = createAction(
    PreviewCommonActions.type('LOAD_REPORT_PREVIEW_SUCCESS'),
    props<{ reportPreview: CustomReportPreviewSearchResponse }>(),
  );

  public static loadReportPreviewFailure = createAction(
    PreviewCommonActions.type('LOAD_REPORT_PREVIEW_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static clearReportPreview = createAction(PreviewCommonActions.type('CLEAR_REPORT_PREVIEW'));

  public static setReportPreviewColumnNames = createAction(
    PreviewCommonActions.type('SET_REPORT_PREVIEW_COLUMN_NAMES'),
    props<{ columnNames: string[] }>(),
  );

  public static loadAutomationPreview = createAction(
    PreviewCommonActions.type('LOAD_AUTOMATION_PREVIEW'),
    props<{ template: AutomationTemplate }>(),
  );

  public static loadAutomationPreviewSuccess = createAction(
    PreviewCommonActions.type('LOAD_AUTOMATION_PREVIEW_SUCCESS'),
    props<{ automationPreview: Automation }>(),
  );

  public static loadAutomationPreviewFailure = createAction(
    PreviewCommonActions.type('LOAD_AUTOMATION_PREVIEW_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static clearAutomationPreview = createAction(PreviewCommonActions.type('CLEAR_AUTOMATION_PREVIEW'));
}

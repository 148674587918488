/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, EmbeddedViewRef, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { AreaChartComponent, dpaDebounce, dpaMemoize, GenericObject, LineChartComponent } from '@dpa/ui-common';
import { symbol, symbolCircle, symbolCross, symbolDiamond, symbolSquare, symbolStar, symbolTriangle, symbolWye } from 'd3-shape';

import { Marker, MarkerContext, MarkerShape } from '@ws1c/dashboard-common/chart/marker/types';
import { DashboardConfig } from '@ws1c/intelligence-models';

/**
 * ChartMarkersComponent
 * @export
 * @class ChartMarkersComponent
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-chart-markers',
  templateUrl: 'chart-markers.component.html',
  styleUrls: ['chart-markers.component.scss'],
})
export class ChartMarkersComponent implements OnDestroy {
  public markers: Marker[];
  public tooltipTemplate: TemplateRef<any>;
  public ngxChart: AreaChartComponent | LineChartComponent;
  public targetNode: Element;
  public activeMarker: MarkerContext;

  @ViewChild('chartMarker', { read: TemplateRef, static: true }) private markerTemplate: TemplateRef<any>;

  private markerViews: Array<EmbeddedViewRef<any>>;

  /**
   * ngOnDestroy
   * @memberof ChartMarkersComponent
   */
  public ngOnDestroy() {
    this.destroyMarkers();
  }

  /**
   * setInputs
   * @param {Pick<ChartMarkersComponent, 'markers' | 'tooltipTemplate' | 'ngxChart' | 'targetNode'>} arg
   * @memberof ChartMarkersComponent
   */
  public setInputs({
    markers,
    tooltipTemplate,
    ngxChart,
    targetNode,
  }: Pick<ChartMarkersComponent, 'markers' | 'tooltipTemplate' | 'ngxChart' | 'targetNode'>) {
    const hasChanges = this.markers !== markers;

    this.markers = markers;
    this.tooltipTemplate = tooltipTemplate;
    this.ngxChart = ngxChart;
    this.targetNode = targetNode;

    if (hasChanges) {
      this.destroyMarkers();
      this.createNewMarkers();
    }
  }

  /**
   * handleClick
   * @param {MarkerContext} marker
   * @memberof ChartMarkersComponent
   */
  public handleClick(marker: MarkerContext) {
    this.activeMarker = marker;
  }

  /**
   * redraw
   * @memberof ChartMarkersComponent
   */
  @dpaDebounce(100)
  public redraw() {
    if (!this.markerViews?.length) {
      return;
    }
    this.markers?.forEach((marker, index) => {
      const context = this.getMarkerContext(marker);
      const view = this.markerViews[index];
      view.context.$implicit = context;
      view.detectChanges();
    });
  }

  /**
   * destroyMarkers
   * @memberof ChartMarkersComponent
   */
  private destroyMarkers() {
    this.activeMarker = undefined;
    this.markerViews?.forEach((view) => view.destroy());
    this.markerViews = [];
  }

  /**
   * createNewMarkers
   * @memberof ChartMarkersComponent
   */
  private createNewMarkers() {
    if (!this.markers?.length) {
      return;
    }
    this.markerViews = this.markers.map((marker: Marker) => {
      const view = this.markerTemplate.createEmbeddedView({
        $implicit: this.getMarkerContext(marker),
      });

      const node = view.rootNodes[0].children[0];
      this.targetNode.appendChild(node);

      // remove the marker node when the view is destroyed
      view.onDestroy(() => this.targetNode?.removeChild(node));
      view.detectChanges();
      return view;
    });
  }

  /**
   * getMarkerContext
   * @private
   * @param {Marker} marker
   * @returns {MarkerContext}
   * @memberof ChartMarkersComponent
   */
  private getMarkerContext(marker: Marker): MarkerContext {
    const xPos: number = this.ngxChart.xScale(marker.x);
    const yPos: number = this.ngxChart.yScale(marker.y);
    const { width: chartWidth, height: chartHeight } = this.ngxChart.dims;

    if (xPos < 0 || xPos > chartWidth || yPos < 0 || yPos > chartHeight) {
      // invalid marker co-ordinates
      return {} as MarkerContext;
    }

    const path = ChartMarkersComponent.getShapePath(marker.shape, marker.size);
    const transformText = `translate(${xPos}, ${yPos})`;

    return new MarkerContext({
      xPos,
      yPos,
      path,
      label: marker.label,
      transformText,
      hideMarker: marker.hideMarker,
      showXReferenceLine: marker.showXReferenceLine,
      showYReferenceLine: marker.showYReferenceLine,
      chartWidth,
      chartHeight,
      tooltipContext: marker,
      color: marker.color || DashboardConfig.DEFAULT_MARKER_COLOR,
      date: marker.date,
      yLabel: marker.yLabel,
    });
  }

  /**
   * getShapePath
   * @private
   * @static
   * @param {MarkerShape} shape
   * @param {Number} size
   * @returns {string}
   * @memberof ChartMarkersComponent
   */
  @dpaMemoize()
  private static getShapePath(shape: MarkerShape, size: number = 80): string {
    let fn: GenericObject = symbolCircle;

    switch (shape) {
      case MarkerShape.CROSS:
        fn = symbolCross;
        break;
      case MarkerShape.DIAMOND:
        fn = symbolDiamond;
        break;
      case MarkerShape.SQUARE:
        fn = symbolSquare;
        break;
      case MarkerShape.STAR:
        fn = symbolStar;
        break;
      case MarkerShape.TRIANGLE:
        fn = symbolTriangle;
        break;
      case MarkerShape.WYE:
        fn = symbolWye;
        break;
    }
    return symbol(fn, size)();
  }
}

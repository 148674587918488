/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { every, flatten, intersection } from 'lodash-es';

import { ConnectorCommonSelectors } from '@ws1c/intelligence-core/store/connector-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import { MarketplaceSelectors } from '@ws1c/intelligence-core/store/marketplace';
import { filterTags } from '@ws1c/intelligence-core/store/template-common/template-common-selectors-helpers';
import {
  AutomationTemplate,
  Category,
  COLUMN_NAMES,
  Connector,
  ConnectorsSearchResponse,
  DashboardTemplate,
  DashboardType,
  getUniqueId,
  IntegrationCategories,
  LOAD_STATE,
  MarketplaceResource,
  NameLabel,
  ReportTemplate,
  Tag,
  TAGS_TYPE,
  Template,
  TEMPLATE_TYPE,
  WidgetTemplate,
} from '@ws1c/intelligence-models';
import { TemplateCommonState } from './template-common.store';

/**
 * TemplateCommonSelectors
 *
 * @export
 * @class TemplateCommonSelectors
 */
export class TemplateCommonSelectors {
  public static selectTemplateCommonState = (state: CoreAppState) => state.templateCommonState;

  /**
   * getTags
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Tag[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getTags: MemoizedSelector<CoreAppState, Tag[]> = createSelector(
    TemplateCommonSelectors.selectTemplateCommonState,
    (state: TemplateCommonState) => state.tags,
  );

  /**
   * getVisibleTemplateTags
   * @static
   * @param {TEMPLATE_TYPE} type
   * @type {(type: TEMPLATE_TYPE) => MemoizedSelector<CoreAppState, Tag[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getVisibleTemplateTags = (type: TEMPLATE_TYPE): MemoizedSelector<CoreAppState, Tag[]> =>
    createSelector(TemplateCommonSelectors.getTags, TemplateCommonSelectors.getTemplates(type), filterTags);

  /**
   * getTagLabels
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof TemplateCommonSelectors
   */
  public static getTagLabels: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    TemplateCommonSelectors.getTags,
    (tags: Tag[]) => {
      const tagLabels = {};
      tags.forEach((tag: Tag) => {
        tag.subtags.forEach((subtag: NameLabel) => {
          tagLabels[`${tag.name}_${subtag.name}`] = subtag.label;
        });
      });
      return tagLabels;
    },
  );

  /**
   * getTemplateIntegrationCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getIntegrationTag = (templateType: TEMPLATE_TYPE): MemoizedSelector<CoreAppState, Tag[]> =>
    createSelector(
      IntegrationMetaSelectors.getTemplateIntegrationCategories(templateType),
      (integrationCategories: IntegrationCategories[]) => {
        const subtags = integrationCategories?.map((item: IntegrationCategories) => item.integration);
        if (!subtags?.length) {
          return [];
        }
        return [
          new Tag({
            name: COLUMN_NAMES.byName.integration,
            label: 'SETTINGS.INTEGRATION',
            type: TAGS_TYPE.INTEGRATION,
            subtags,
          }),
        ];
      },
    );

  /**
   * getTemplateIntegrationCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getTemplateTags = (templateType: TEMPLATE_TYPE): MemoizedSelector<CoreAppState, Tag[]> =>
    createSelector(
      TemplateCommonSelectors.getVisibleTemplateTags(templateType),
      TemplateCommonSelectors.getIntegrationTag(templateType),
      (visibleTags: Tag[], integrationTags: Tag[]) => {
        return [...visibleTags, ...integrationTags];
      },
    );

  /**
   * getTemplateIntegrationCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getTemplateTagLabels = (templateType: TEMPLATE_TYPE): MemoizedSelector<CoreAppState, Record<string, string>> =>
    createSelector(TemplateCommonSelectors.getTemplateTags(templateType), (tags: Tag[]) => {
      const tagLabels = {};
      tags.forEach((tag: Tag) => {
        tag.subtags?.forEach((subtag: NameLabel) => {
          tagLabels[getUniqueId(tag.name, subtag.name)] = subtag.label;
        });
      });
      return tagLabels;
    });

  /**
   * isTemplateBookmarkingInProgress
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TemplateCommonSelectors
   */
  public static isTemplateBookmarkingInProgress: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TemplateCommonSelectors.selectTemplateCommonState,
    (state: TemplateCommonState) => state.bookmarkSaveStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * isTemplateLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TemplateCommonSelectors
   */
  public static isTemplateLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TemplateCommonSelectors.selectTemplateCommonState,
    (state: TemplateCommonState) => state.templateLoadStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * isTemplatesLoading
   * @static
   * @param {TEMPLATE_TYPE} type
   * @type {(type: TEMPLATE_TYPE) => MemoizedSelector<CoreAppState, boolean>}
   * @memberof TemplateCommonSelectors
   */
  public static isTemplatesLoading = (type: TEMPLATE_TYPE): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      TemplateCommonSelectors.selectTemplateCommonState,
      (state: TemplateCommonState) => state.templateLoadStatusByType[type] === LOAD_STATE.IN_FLIGHT,
    );

  /**
   * getTemplateByMarketplaceResourceId
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, Template>>}
   * @memberof TemplateCommonSelectors
   */
  public static getTemplateByMarketplaceResourceId: MemoizedSelector<CoreAppState, Record<string, Template>> = createSelector(
    TemplateCommonSelectors.selectTemplateCommonState,
    (state: TemplateCommonState) => state.templateByMarketplaceResourceId,
  );

  /**
   * getTemplateFromMarketplacePreviewResource
   * @static
   * @type {MemoizedSelector<CoreAppState, Template>}
   * @memberof TemplateCommonSelectors
   */
  public static getTemplateFromMarketplacePreviewResource: MemoizedSelector<CoreAppState, Template> = createSelector(
    TemplateCommonSelectors.getTemplateByMarketplaceResourceId,
    MarketplaceSelectors.getPreviewResource,
    (templateByLookupId: Record<string, Template>, resource: MarketplaceResource) => templateByLookupId[resource?.id],
  );

  /**
   * getTemplates
   * @static
   * @param {TEMPLATE_TYPE} type
   * @type {(type: TEMPLATE_TYPE) => MemoizedSelector<CoreAppState, Template[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getTemplates = (type: TEMPLATE_TYPE): MemoizedSelector<CoreAppState, Template[]> =>
    createSelector(TemplateCommonSelectors.selectTemplateCommonState, (state: TemplateCommonState) => state.templatesByType[type]);

  /**
   * getVisibleTemplates
   * @static
   * @param {TEMPLATE_TYPE} type
   * @param {boolean} isCustom
   * @type {(type: TEMPLATE_TYPE, isCustom?: boolean) => MemoizedSelector<CoreAppState, Template[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getVisibleTemplates = (type: TEMPLATE_TYPE, isCustom?: boolean): MemoizedSelector<CoreAppState, Template[]> =>
    createSelector(
      TemplateCommonSelectors.getTemplates(type),
      IntegrationMetaSelectors.getTemplateIntegrationCategories(type),
      ConnectorCommonSelectors.getConnectorsSearchResponse,
      (templates: Template[], integrationCategories: IntegrationCategories[], connectorsResponse: ConnectorsSearchResponse) => {
        let filteredTemplates: Template[];
        const catIds = flatten(
          integrationCategories?.map((item: IntegrationCategories) => item.categories.map((category: Category) => category.categoryId)),
        );
        switch (type) {
          case TEMPLATE_TYPE.AUTOMATIONS: {
            templates?.forEach((template: AutomationTemplate) => {
              template.isStartActionDisabled = !every(template.connectorIds, (connectorId: string) =>
                connectorsResponse?.results.find((connector: Connector) => connector.id === connectorId),
              );
            });
            filteredTemplates = templates?.filter(
              (template: AutomationTemplate) => !template.targetEntity || catIds.includes(template.categoryId),
            );
            break;
          }
          case TEMPLATE_TYPE.REPORTS: {
            filteredTemplates = templates?.filter((template: ReportTemplate) => {
              const isCategorySupported = catIds.includes(template.categoryId) || template.isPredefinedReportTemplate;
              return isCustom ? template.isCustomTemplate && isCategorySupported : isCategorySupported;
            });
            break;
          }
          case TEMPLATE_TYPE.WIDGETS: {
            filteredTemplates = templates?.filter(
              (template: WidgetTemplate) => intersection(template.trendDefinition?.categories, catIds).length > 0,
            );
            break;
          }
          default: {
            filteredTemplates = templates;
            break;
          }
        }
        return (filteredTemplates || []).sort((a: Template, b: Template) => (a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1));
      },
    );

  /**
   * getVisibleCustomTemplates
   * @static
   * @param {TEMPLATE_TYPE} type
   * @type {(type: TEMPLATE_TYPE) => MemoizedSelector<CoreAppState, Template[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getVisibleCustomTemplates = (type: TEMPLATE_TYPE): MemoizedSelector<CoreAppState, Template[]> =>
    createSelector(
      TemplateCommonSelectors.getTemplates(type),
      IntegrationMetaSelectors.getTemplateIntegrationCategories(type),
      (templates: Template[], integrationCategories: IntegrationCategories[]) => {
        const catIds = flatten(
          integrationCategories?.map((item: IntegrationCategories) => item.categories.map((category: Category) => category.categoryId)),
        );
        if (type === TEMPLATE_TYPE.REPORTS) {
          return templates?.filter((template: ReportTemplate) => catIds.includes(template.categoryId));
        }
        return templates;
      },
    );

  /**
   * getFrontlineDashboardTemplates
   * @static
   * @type {MemoizedSelector<CoreAppState, DashboardTemplate[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getFrontlineDashboardTemplates: MemoizedSelector<CoreAppState, DashboardTemplate[]> = createSelector(
    TemplateCommonSelectors.getVisibleTemplates(TEMPLATE_TYPE.DASHBOARDS),
    (templates: DashboardTemplate[]) =>
      templates?.filter((template: DashboardTemplate) => template.dashboardType === DashboardType.FRONTLINE) ?? [],
  );

  /**
   * isDeployConfirmationModalOpen
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TemplateCommonSelectors
   */
  public static isDeployConfirmationModalOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TemplateCommonSelectors.selectTemplateCommonState,
    (state: TemplateCommonState) => state.isDeployConfirmationModalOpen,
  );

  /**
   * isDeploymentInprogress
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TemplateCommonSelectors
   */
  public static isDeploymentInprogress: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TemplateCommonSelectors.selectTemplateCommonState,
    (state: TemplateCommonState) => state.deploymentStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * isDeploymentValidationLoading
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof TemplateCommonSelectors
   */
  public static isDeploymentValidationLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    TemplateCommonSelectors.selectTemplateCommonState,
    (state: TemplateCommonState) => state.deploymentValidationLoadingStatus === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * getDeploymentValidationMessages
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof TemplateCommonSelectors
   */
  public static getDeploymentValidationMessages: MemoizedSelector<CoreAppState, string[]> = createSelector(
    TemplateCommonSelectors.selectTemplateCommonState,
    (state: TemplateCommonState) => state.deploymentValidationMessages,
  );
}

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { TrendSpan } from '@dpa/ui-common';

import { ChronoUnit, DashboardConfig, StandardDashboardType, TrendDateRange } from '@ws1c/intelligence-models';

/**
 * getDefaultedTrendDate
 * @param {TrendDateRange} trendDateRange
 * @param {StandardDashboardType} type
 * @param {boolean} [roundEndTimestamp]
 * @returns {TrendDateRange}
 * @export
 */
export function getDefaultedTrendDate(
  trendDateRange?: TrendDateRange,
  type?: StandardDashboardType,
  roundEndTimestamp?: boolean,
): TrendDateRange {
  return trendDateRange || getDefaultTrendDateByDashboardType(type, roundEndTimestamp);
}

/**
 * getDefaultTrendDateByDashboardType
 * @param {StandardDashboardType} type
 * @param {boolean} [roundEndTimestamp]
 * @returns {TrendDateRange}
 * @export
 */
export function getDefaultTrendDateByDashboardType(type?: StandardDashboardType, roundEndTimestamp?: boolean): TrendDateRange {
  if (DashboardConfig.skipDefaultTrendDateDashboardTypes.includes(type)) {
    return;
  }
  const config = DashboardConfig.defaultTrendDateConfig.find(
    (configItem: { duration: number; chronoUnit: ChronoUnit; dashboardTypes: StandardDashboardType[] }) =>
      configItem.dashboardTypes.includes(type),
  );
  return getDefaultTrendDate(config?.duration, config?.chronoUnit, roundEndTimestamp);
}

/**
 * getDefaultTrendDate
 * @export
 * @param {number} [duration=28]
 * @param {ChronoUnit} [chronoUnit=ChronoUnit.DAYS]
 * @param {boolean} [roundEndTimestamp]
 * @param {boolean} [entireDateRange=false]
 * @returns {TrendDateRange}
 */
export function getDefaultTrendDate(
  duration: number = 28,
  chronoUnit: ChronoUnit = ChronoUnit.DAYS,
  roundEndTimestamp?: boolean,
  entireDateRange: boolean = false,
): TrendDateRange {
  return new TrendDateRange({
    trendSpan: new TrendSpan({ duration, unit: ChronoUnit[chronoUnit] }),
    samplingFrequency: entireDateRange ? undefined : new TrendSpan({ duration: 1, unit: ChronoUnit[chronoUnit] }),
    roundEndTimestamp,
  });
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { isUndefined } from 'lodash-es';

import { UserPreferenceActions } from '@ws1c/intelligence-core/store/user-preference/user-preference.actions';
import { UIPreference } from '@ws1c/intelligence-models';
import { NavigationMenuActions } from './navigation-menu.actions';
import { initialNavigationMenuState, NavigationMenuState } from './navigation-menu.store';

/**
 * _reducer
 * @type {ActionReducer<NavigationMenuState, Action>}
 */
const _reducer: ActionReducer<NavigationMenuState, Action> = createReducer(
  initialNavigationMenuState,

  on(
    NavigationMenuActions.activateNavigationMenuItem,
    (
      state: NavigationMenuState,
      props: {
        activatedNavMenuItemIndex: number;
        loadDefaultRoute: boolean;
      },
    ): NavigationMenuState => {
      const activatedNavMenuItemIndex: number = props.activatedNavMenuItemIndex;
      const loadDefaultRoute: boolean = props.loadDefaultRoute;
      return {
        ...state,
        activatedNavMenuItemIndex,
        loadDefaultRoute,
      };
    },
  ),

  on(
    UserPreferenceActions.getUISettingsSuccess,
    (state: NavigationMenuState, { uiSettings }: ReturnType<typeof UserPreferenceActions.getUISettingsSuccess>): NavigationMenuState => {
      const expandedNavGroups = uiSettings?.preferences?.[UIPreference.SECONDARY_NAVIGATION_GROUPS_EXPANDED] ?? [];
      const isSecondaryNavigationMenuCollapsed = uiSettings?.preferences?.[UIPreference.SECONDARY_NAVIGATION_COLLAPSED] ?? false;
      return {
        ...state,
        expandedNavGroups: state.expandedNavGroups ?? expandedNavGroups,
        isSecondaryNavigationMenuCollapsed: state.isSecondaryNavigationMenuCollapsed ?? isSecondaryNavigationMenuCollapsed,
      };
    },
  ),

  on(
    NavigationMenuActions.setSecondaryNavigationCollapsedFlag,
    (
      state: NavigationMenuState,
      { isCollapsed, saveToUserPreference }: ReturnType<typeof NavigationMenuActions.setSecondaryNavigationCollapsedFlag>,
    ): NavigationMenuState => {
      return {
        ...state,
        isSecondaryNavigationMenuCollapsed: isCollapsed,
        saveToUserPreference: isUndefined(saveToUserPreference) ? true : saveToUserPreference,
      };
    },
  ),

  on(
    NavigationMenuActions.toggleSecondaryNavigationMenuGroup,
    (
      state: NavigationMenuState,
      { navMenuGroup }: ReturnType<typeof NavigationMenuActions.toggleSecondaryNavigationMenuGroup>,
    ): NavigationMenuState => {
      const expandedNavGroups = new Set(state.expandedNavGroups ?? []);
      if (expandedNavGroups.has(navMenuGroup)) {
        expandedNavGroups.delete(navMenuGroup);
      } else {
        expandedNavGroups.add(navMenuGroup);
      }
      return {
        ...state,
        expandedNavGroups: [...expandedNavGroups],
      };
    },
  ),
);

/**
 * Creates and returns NavigationMenuState
 * @export
 * @param {NavigationMenuState} state - Holds state object of type NavigationMenuState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {NavigationMenuState}
 */
export function navigationMenuState(state: NavigationMenuState, action: Action): NavigationMenuState {
  return _reducer(state, action);
}

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, GraphqlService, HttpService } from '@ws1c/intelligence-common';
import {
  AccountUsageDetails,
  GenericSearchRequest,
  IdentifiersRequest,
  ServiceLimits,
  ServiceLimitsName,
  SystemLimitsFeatureTag,
  SystemLimitsSearchResponse,
  SystemLimitsUsageDetails,
} from '@ws1c/intelligence-models';
import { SYSTEM_LIMITS_SEARCH } from './system-limits-search.graphql';
import { SYSTEM_LIMITS_USAGE_SUMMARY } from './system-limits-usage-summary.graphql';

/**
 * SystemLimitsService
 * @export
 * @class SystemLimitsService
 */
@Injectable({
  providedIn: 'root',
})
export class SystemLimitsService {
  /**
   * Creates an instance of SystemLimitsService.
   *
   * @param {HttpService} httpService
   * @param {GraphqlService} graphqlService
   * @memberof SystemLimitsService
   */
  constructor(
    private httpService: HttpService,
    private graphqlService: GraphqlService,
  ) {}

  /**
   * getUsageSummary
   *
   * @param {string} orgId
   * @returns {Observable<SystemLimitsUsageDetails[]>}
   * @memberof SystemLimitsService
   */
  public getUsageSummary(orgId: string): Observable<SystemLimitsUsageDetails[]> {
    return this.graphqlService
      .query(SYSTEM_LIMITS_USAGE_SUMMARY, {
        orgId,
      })
      .pipe(
        map((response: GenericObject) => {
          return response?.serviceLimitOrgUsage?.org_summaries.map((systemLimitsUsageDetails: GenericObject) =>
            deserialize(SystemLimitsUsageDetails, systemLimitsUsageDetails),
          );
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getSystemLimits
   *
   * @param {SystemLimitsFeatureTag} systemLimitsFeatureTag
   * @param {GenericSearchRequest} searchRequest
   * @returns {Observable<SystemLimitsSearchResponse>}
   * @memberof SystemLimitsService
   */
  public getSystemLimits(
    systemLimitsFeatureTag: SystemLimitsFeatureTag,
    searchRequest: GenericSearchRequest,
  ): Observable<SystemLimitsSearchResponse> {
    return this.graphqlService
      .query(SYSTEM_LIMITS_SEARCH, {
        pagedSearchRequestInput: searchRequest,
        summaryTag: systemLimitsFeatureTag,
      })
      .pipe(
        map((response: GenericObject) => {
          // GraphQL search calls return little different response structure, thus massaging data to same format
          response = {
            ...response?.serviceLimitSummariesPagedSearch?.paged_response,
            results: response?.serviceLimitSummariesPagedSearch?.account_summaries,
          };
          return deserialize(SystemLimitsSearchResponse, response);
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getServiceLimits
   *
   * @returns {Observable<ServiceLimits[]>}
   * @memberof SystemLimitsService
   */
  public getServiceLimits(): Observable<ServiceLimits[]> {
    return this.httpService.get(Endpoint.ORG_SERVICE_LIMITS).pipe(
      map((response: GenericObject) => {
        return response?.data?.map((serviceLimit: GenericObject) => {
          return deserialize(ServiceLimits, serviceLimit);
        });
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateServiceLimits
   *
   * @param {{ [key in ServiceLimitsName]: number} | {}} serviceLimits
   * @returns {Observable<boolean>}
   * @memberof SystemLimitsService
   */
  public updateServiceLimits(serviceLimits: { [key in ServiceLimitsName]: number } | {}): Observable<boolean> {
    return this.httpService.put(Endpoint.ORG_SERVICE_LIMITS, serviceLimits).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteServiceLimits
   *
   * @param {ServiceLimitsName[]} serviceLimits
   * @returns {Observable<boolean>}
   * @memberof SystemLimitsService
   */
  public deleteServiceLimits(serviceLimits: ServiceLimitsName[]): Observable<boolean> {
    return this.httpService
      .delete(Endpoint.ORG_SERVICE_LIMITS, {
        body: serviceLimits,
      })
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getUserAutomationsSummary
   *
   * @param {string} accountId
   * @returns {Observable<AccountUsageDetails>}
   * @memberof SystemLimitsService
   */
  public getUserAutomationsSummary(accountId: string): Observable<AccountUsageDetails> {
    return this.httpService.post(Endpoint.AUTOMATIONS_ORG_SUMMARY, [accountId]).pipe(
      map((response: GenericObject) => {
        // API won't return record if the count is 0, thus defaulting to count 0 if record not found
        return new AccountUsageDetails(
          {
            accountId,
            resourceCount: 0,
          },
          deserialize(AccountUsageDetails, response?.data?.account_usage_details?.[accountId]),
        );
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getUserCustomReportsSummary
   *
   * @param {string} accountId
   * @returns {Observable<AccountUsageDetails>}
   * @memberof SystemLimitsService
   */
  public getUserCustomReportsSummary(accountId: string): Observable<AccountUsageDetails> {
    return this.httpService.post(Endpoint.REPORTS_ORG_SUMMARY, [accountId]).pipe(
      map((response: GenericObject) => {
        // API won't return record if the count is 0, thus defaulting to count 0 if record not found
        return new AccountUsageDetails(
          {
            accountId,
            resourceCount: 0,
          },
          deserialize(AccountUsageDetails, response?.data?.account_usage_details?.[accountId]),
        );
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getUserDashboardsSummary
   *
   * @param {string} accountId
   * @returns {Observable<AccountUsageDetails>}
   * @memberof SystemLimitsService
   */
  public getUserDashboardsSummary(accountId: string): Observable<AccountUsageDetails> {
    return this.httpService.post(Endpoint.DASHBOARDS_ORG_SUMMARY, [accountId]).pipe(
      map((response: GenericObject) => {
        // API won't return record if the count is 0, thus defaulting to count 0 if record not found
        return new AccountUsageDetails(
          {
            accountId,
            resourceCount: 0,
          },
          deserialize(AccountUsageDetails, response?.data?.account_usage_details?.[accountId]),
        );
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getUserServiceLimits
   *
   * @param {IdentifiersRequest} accountIdentifiersRequest
   * @returns {Observable<AccountServiceLimit[]>}
   * @memberof SystemLimitsService
   */
  public getUserServiceLimits(accountIdentifiersRequest: IdentifiersRequest): Observable<ServiceLimits[]> {
    return this.httpService.post(Endpoint.USER_SERVICE_LIMITS, accountIdentifiersRequest).pipe(
      map((response: GenericObject) => {
        const accountId: string = accountIdentifiersRequest.identifiers?.[0];
        const serviceLimits: GenericObject = response?.data?.[accountId];
        return serviceLimits.map((serviceLimit: GenericObject) => deserialize(ServiceLimits, serviceLimit));
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateUserServiceLimits
   *
   * @param {string} accountId
   * @param {{ [key in ServiceLimitsName]: number} | {}} userServiceLimits
   * @returns {Observable<boolean>}
   * @memberof SystemLimitsService
   */
  public updateUserServiceLimits(accountId: string, userServiceLimits: { [key in ServiceLimitsName]: number } | {}): Observable<boolean> {
    return this.httpService.put(Endpoint.USER_SERVICE_LIMITS_ID(accountId), userServiceLimits).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteUserServiceLimits
   *
   * @param {string} accountId
   * @param {ServiceLimitsName[]} userServiceLimits
   * @returns {Observable<boolean>}
   * @memberof SystemLimitsService
   */
  public deleteUserServiceLimits(accountId: string, userServiceLimits: ServiceLimitsName[]): Observable<boolean> {
    return this.httpService
      .delete(Endpoint.USER_SERVICE_LIMITS_ID(accountId), {
        body: userServiceLimits,
      })
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }
}

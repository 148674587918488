<div
  (resized)="onResize()"
  class="chart-container"
>
  <ng-container *ngIf="!overlayChart; else overlayCharts">
    <ngx-charts-line-chart
      [rangeFillOpacity]="isRanged ? 0.1 : undefined"
      [results]="ngxChart.chartData"
      [scheme]="ngxChart.defaultColorScheme"
      [legend]="false"
      [xAxisLabel]="ngxChart.labels.secondGroupBy"
      [yAxisLabel]="yAxisLabel"
      [xAxis]="!isSpark && showXAxis"
      [yAxis]="!isSpark && showYAxis"
      [showXAxisLabel]="!isSpark"
      [showYAxisLabel]="!isSpark && !!yAxisLabel"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
      [yAxisTickFormatting]="yAxisTickFormatting || ngxChart.labels.counterFormatter"
      [trimYAxisTicks]="!yAxisTickFormatting"
      [animations]="true"
      [tooltipDisabled]="isSpark"
      [customColors]="ngxChart.customColors"
      [referenceLines]="referenceLines"
      [showRefLabels]="showRefLabels"
      [showRefLines]="showRefLines"
      [timeline]="showTimeline"
      [dpaChartMarkers]="markers"
      [dpaChartMarkerTooltipTemplate]="markerTooltipTemplate"
      [yScaleMax]="yScaleMax"
      [yScaleMin]="yScaleMin"
      [trimXAxisTicks]="trimXAxisTicks"
      [yAxisTicks]="yAxisTicks"
      (select)="onSelect($event)"
      (onMarkerClick)="handleMarkerClick($event)"
    >
      <ng-template
        #tooltipTemplate
        let-model="model"
      >
        <ng-template
          *ngIf="model"
          [ngTemplateOutlet]="stackedTooltip"
          [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
        ></ng-template>
      </ng-template>
      <ng-template
        #seriesTooltipTemplate
        let-model="model"
      >
        <ng-template
          *ngIf="model"
          [ngTemplateOutlet]="seriesTooltip"
          [ngTemplateOutletContext]="{ usingFakeGroupBy: ngxChart.usingFakeGroupBy, tooltipItems: model }"
        ></ng-template>
      </ng-template>
    </ngx-charts-line-chart>
  </ng-container>
  <ng-template #overlayCharts>
    <dpa-line-overlay-chart
      #chart
      [scheme]="ngxChart.defaultColorScheme"
      [schemeForOverlay]="overlayChart.defaultColorScheme"
      [results]="ngxChart.chartData"
      [overlayResults]="overlayChart.chartData"
      [customcolorsForOverlay]="overlayChart.customColors"
      [xAxisLabel]="ngxChart.labels.firstGroupBy"
      [yAxisLabel]="yAxisLabel"
      [yAxisRightLabel]="overlayChart.labels.counter"
      [gradient]="false"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [showXAxisLabel]="true"
      [showYAxisLabel]="!!yAxisLabel"
      [animations]="true"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [trimXAxisTicks]="true"
      [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
      [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
      [customColors]="ngxChart.customColors"
      [showDataLabel]="true"
      [dataLabelFormatting]="ngxChart.labels.counterFormatter"
      [tooltipTemplate]="overlayTooltipTemplate"
      [yScaleMax]="yScaleMax"
      [focussedSequence]="focussedSequence"
      (select)="onSelect($event)"
    ></dpa-line-overlay-chart>
  </ng-template>
</div>

/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { Tag } from '@ws1c/intelligence-models';

/**
 * QuickFilterService
 * @export
 * @class QuickFilterService
 */
@Injectable({
  providedIn: 'root',
})
export class QuickFilterService {
  /**
   * Creates an instance of QuickFilterService.
   * @param {HttpService} httpService
   * @memberof QuickFilterService
   */
  constructor(private httpService: HttpService) {}

  /**
   * getTags
   * @param {string} quickFilterTagType
   * @returns {Observable<Tag[]>}
   * @memberof QuickFilterService
   */
  public getTags(quickFilterTagType: string): Observable<Tag[]> {
    return this.httpService.get(Endpoint.TAGS(quickFilterTagType)).pipe(
      map((response: GenericObject) => response.data.map((tag: GenericObject) => deserialize(Tag, tag))),
      catchError(requestErrorHandler),
    );
  }
}

/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { AccordionWizardConfig } from '@ws1c/intelligence-common';
import { SolutionConfigType, SolutionStepConfig } from '@ws1c/intelligence-models';

/**
 * SolutionSetupActions
 * @export
 * @class SolutionSetupActions
 */
export class SolutionSetupActions {
  public static readonly CATEGORY = '[SOLUTION_SETUP]';

  public static loadAllSolutionStepConfigs = createAction(`${SolutionSetupActions.CATEGORY} LOAD_ALL_SOLUTION_STEP_CONFIGS`);

  public static loadSolutionStepConfig = createAction(
    `${SolutionSetupActions.CATEGORY} LOAD_SOLUTION_STEP_CONFIG`,
    props<{ solutionConfigType: SolutionConfigType }>(),
  );
  public static loadSolutionStepConfigSuccess = createAction(
    `${SolutionSetupActions.CATEGORY} LOAD_SOLUTION_STEP_CONFIG_SUCCESS`,
    props<{ solutionStepConfig: SolutionStepConfig }>(),
  );
  public static loadSolutionStepConfigFailure = createAction(
    `${SolutionSetupActions.CATEGORY} LOAD_SOLUTION_STEP_CONFIG_FAILURE`,
    props<{ solutionConfigType: SolutionConfigType; error: WebError }>(),
  );

  public static setAccordionWizardConfig = createAction(
    `${SolutionSetupActions.CATEGORY} SET_ACCORDION_WIZARD_CONFIG`,
    props<{ accordionWizardId: string; accordionWizardConfig: AccordionWizardConfig }>(),
  );

  public static setSolutionStepConfigSuccess = createAction(`${SolutionSetupActions.CATEGORY} SET_SOLUTION_STEP_CONFIG_SUCCESS`);
  public static setSolutionStepConfigFailure = createAction(
    `${SolutionSetupActions.CATEGORY} SET_SOLUTION_STEP_CONFIG_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static enableDeemV2Virtual = createAction(`${SolutionSetupActions.CATEGORY} ENABLE_DEEM_V2_VIRTUAL`);
  public static navigateToDeemMarketplace = createAction(`${SolutionSetupActions.CATEGORY} NAVIGATE_TO_DEEM_MARKETPLACE`);
}

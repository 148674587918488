/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler, requestErrorHandlerWithStatus } from '@dpa/ui-common';
import { isEmpty } from 'lodash-es';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, GenericResponse, HttpService } from '@ws1c/intelligence-common';
import {
  CspAccount,
  CspAccountRequest,
  Directory,
  DirectoryType,
  UserAdminAccount,
  UserAdminAccountSearchRequest,
} from '@ws1c/intelligence-models';

/**
 * UserAdminAccountService
 *
 * @exports
 * @class UserAdminAccountService
 */
@Injectable({
  providedIn: 'root',
})
export class UserAdminAccountService {
  /**
   * Creates an instance of UserAdminAccountService.
   * @param {HttpService} http
   * @memberof UserAdminAccountService
   */
  constructor(private http: HttpService) {}

  /**
   * search
   * @param {UserAdminAccountSearchRequest} userAdminAccountSearchRequest
   * @returns {Observable<UserAdminAccount[]>}
   * @memberof UserAdminAccountService
   */
  public search(userAdminAccountSearchRequest: UserAdminAccountSearchRequest): Observable<UserAdminAccount[]> {
    return this.http.post(Endpoint.USER_ADMIN_SEARCH(userAdminAccountSearchRequest.searchTerm || 'a'), {}).pipe(
      map((response: GenericObject) => response.data.map((user: GenericObject) => deserialize(UserAdminAccount, user))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getDirectoryByType
   *
   * @param {DirectoryType} directoryType
   * @returns {Observable<Directory>}
   * @memberof UserAdminAccountService
   */
  public getDirectoryByType(directoryType: DirectoryType): Observable<Directory> {
    return this.http.get(Endpoint.DIRECTORY_TYPE(directoryType)).pipe(
      map((response: GenericObject) => (isEmpty(response.data) ? undefined : deserialize(Directory, response.data[0]))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteDirectory
   * @param {DirectoryType} directoryType
   * @param {string} id
   * @returns {Observable<boolean>}
   * @memberof UserAdminAccountService
   */
  public deleteDirectory(directoryType: DirectoryType, id: string): Observable<boolean> {
    return this.http.delete(Endpoint.DIRECTORY_ID(directoryType, id)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getCspAccountRequest
   * @returns {Observable<RequestStatus>}
   * @memberof UserAdminAccountService
   */
  public getCspAccountRequest(): Observable<CspAccount> {
    return this.http.get(Endpoint.CSP_ACCOUNT).pipe(
      map((response: GenericResponse) => deserialize(CspAccount, response.data)),
      catchError(requestErrorHandlerWithStatus),
    );
  }

  /**
   * submitRequestForCspAccount
   * @param {CspAccountRequest} request
   * @returns {Observable<boolean>}
   * @memberof UserAdminAccountService
   */
  public submitRequestForCspAccount(request: CspAccountRequest): Observable<boolean> {
    return this.http.post(Endpoint.CSP_ACCOUNT, request).pipe(
      map(() => true),
      catchError(requestErrorHandlerWithStatus),
    );
  }
}

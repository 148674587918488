/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { LOAD_STATE, Tag, Template } from '@ws1c/intelligence-models';

/**
 * TemplateCommonState
 *
 * @export
 * @interface TemplateCommonState
 */
export interface TemplateCommonState {
  tags: Tag[];
  templatesByType: Record<string, Template[]>;
  templateByMarketplaceResourceId: Record<string, Template>;
  templateLoadStatus: LOAD_STATE;
  templateLoadStatusByType: Record<string, LOAD_STATE>;
  bookmarkSaveStatus: LOAD_STATE;
  isDeployConfirmationModalOpen: boolean;
  deploymentStatus: LOAD_STATE;
  deploymentValidationLoadingStatus: LOAD_STATE;
  deploymentValidationMessages: string[];
}

/**
 * Defines initial state for TemplateCommon
 *
 * @export
 * @type {TemplateCommonState}
 */
export const initialTemplateCommonState: TemplateCommonState = {
  tags: [],
  templatesByType: {},
  templateByMarketplaceResourceId: {},
  templateLoadStatus: LOAD_STATE.NONE,
  templateLoadStatusByType: {},
  bookmarkSaveStatus: LOAD_STATE.NONE,
  isDeployConfirmationModalOpen: false,
  deploymentStatus: LOAD_STATE.NONE,
  deploymentValidationLoadingStatus: LOAD_STATE.NONE,
  deploymentValidationMessages: [],
};

<div class="fake-radio-button-container">
  <div
    (click)="onClearDrilldown()"
    (keyup.enter)="onClearDrilldown()"
    class="fake-radio-button drilldown-nav clickable"
    tabindex="0"
  >
    <cds-icon
      shape="home"
      size="20"
    ></cds-icon>
  </div>
  <div
    (click)="onPopDrilldown()"
    (keyup.enter)="onPopDrilldown()"
    class="fake-radio-button drilldown-nav clickable ml-x1"
    tabindex="0"
  >
    <cds-icon
      shape="undo"
      size="20"
    ></cds-icon>
  </div>
</div>

/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { keyBy } from 'lodash-es';

import { DeemSurveyQuestionFormat } from '@ws1c/deem-solution/models/deem-surveys/deem-survey-question-format.enum';
import { ACCORDION_WIZARD_CONFIG_STATE } from '@ws1c/intelligence-common';
import { Entity, LabelValue } from '@ws1c/intelligence-models';

/**
 * DeemCreateSurveyConfig
 * @export
 * @class DeemCreateSurveyConfig
 */
export class DeemCreateSurveyConfig {
  public static readonly stepNamesByAccordionId: GenericObject = {
    setup_survey: ['settings', 'notification', 'questions'],
    add_workflow: ['workflow'],
  };

  public static readonly categoryOptions: LabelValue[] = [
    {
      label: 'COMMON_MESSAGES.DEVICES',
      value: 'DEVICE',
    },
    {
      label: 'COMMON_MESSAGES.APPS',
      value: 'APP',
    },
  ];

  public static readonly priorityOptions: LabelValue[] = [
    {
      label: 'DEEM_SURVEYS.STANDARD',
      value: 'STANDARD',
    },
    {
      label: 'DEEM_SURVEYS.HIGH',
      value: 'HIGH',
    },
    {
      label: 'DEEM_SURVEYS.URGENT',
      value: 'URGENT',
    },
  ];

  public static readonly iconOptions: LabelValue[] = [
    {
      label: 'DEEM_SURVEYS.DEFAULT',
      value: 'DEFAULT',
    },
    {
      label: 'DEEM_SURVEYS.URL',
      value: 'URL',
    },
  ];

  public static readonly entityByCategory: Record<string, string> = {
    DEVICE: Entity.DEVICE,
    APP: Entity.APPLICATION,
  };

  public static readonly initialConfigStatus: GenericObject = {
    setup_survey: {
      settings: ACCORDION_WIZARD_CONFIG_STATE.NOT_STARTED,
      notification: ACCORDION_WIZARD_CONFIG_STATE.NOT_STARTED,
      questions: ACCORDION_WIZARD_CONFIG_STATE.NOT_STARTED,
    },
    add_workflow: {
      workflow: ACCORDION_WIZARD_CONFIG_STATE.NOT_STARTED,
    },
  };

  public static readonly questionTypeOptions: LabelValue[] = [
    {
      label: 'DEEM_SURVEYS.QUESTION_TYPES.SINGLE_CHOICE',
      value: DeemSurveyQuestionFormat.RADIO,
    },
    {
      label: 'DEEM_SURVEYS.QUESTION_TYPES.MULTIPLE_CHOICE',
      value: DeemSurveyQuestionFormat.CHECKBOX,
    },
    {
      label: 'DEEM_SURVEYS.QUESTION_TYPES.NET_PROMOTER_SCORE',
      value: DeemSurveyQuestionFormat.RATING,
    },
    {
      label: 'DEEM_SURVEYS.QUESTION_TYPES.FREE_FORM_TEXT',
      value: DeemSurveyQuestionFormat.TEXT,
    },
  ];

  public static readonly questionTypeOptionByFormat: GenericObject = keyBy(DeemCreateSurveyConfig.questionTypeOptions, 'value');

  public static readonly customAnswerQuestionTypes: DeemSurveyQuestionFormat[] = [
    DeemSurveyQuestionFormat.RADIO,
    DeemSurveyQuestionFormat.CHECKBOX,
  ];
}

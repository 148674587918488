/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { cloneDeep } from 'lodash-es';

import { IntegrationDetails } from '@ws1c/intelligence-models';

/**
 * Returns integrations list with specified integration instance updated with given active status
 * @param {GenericObject} existingIntegrationsDetails - Current integrations details
 * @param {IntegrationDetails} integrationDetails - Integration instance for which active state needs to be updated
 * @param {boolean} active - Flag to indicate if integration instance needs to be activated
 * @returns {GenericObject[]}
 */
export const getIntegrationInstancesWithActiveStateUpdated = (
  existingIntegrationsDetails: GenericObject,
  integrationDetails: IntegrationDetails,
  active: boolean,
): GenericObject[] => {
  const integrationInstances: GenericObject[] = cloneDeep(existingIntegrationsDetails[integrationDetails.integration] || []);
  const integrationInstanceIndex: number = integrationInstances.findIndex((integrationInstance: GenericObject) => {
    return integrationInstance.id === integrationDetails.id;
  });
  integrationInstances[integrationInstanceIndex] = {
    ...integrationInstances[integrationInstanceIndex],
    active,
  };
  return integrationInstances;
};

/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSyntheticURLMonitoringAction
 * @enum {string}
 * @export
 */
export enum DeemSyntheticURLMonitoringAction {
  DEACTIVATE = 'DEACTIVATE',
  DELETE = 'DELETE',
  DISCARD = 'DISCARD',
  EDIT = 'EDIT',
  PUBLISH = 'PUBLISH',
}
